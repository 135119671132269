import { Provider, createStore, useAtom } from 'jotai';
import { useAtomsDevtools, DevTools } from 'jotai-devtools';
import { atomWithStorage } from 'jotai/utils';

export const jotaiStore = createStore();

export const enableJotaiDebugAtom = atomWithStorage(
  'wsEnableJotaiDebug',
  process.env.NEXT_PUBLIC_ENABLE_JOTAI_DEVTOOLS === 'true',
  undefined,
  { getOnInit: true }
);

function JotaiUseDevtools() {
  useAtomsDevtools('dashboard-atoms', {
    enabled: true,
    store: jotaiStore,
    // we could set this to true with a command pallete action if it gets interesting to see the internals of librrary atoms
    shouldShowPrivateAtoms: false,
  });
  return null;
}

function JotaiDebug() {
  const [enableJotaiDebug] = useAtom(enableJotaiDebugAtom);

  // separately check node env here so next / webpack can treeshake and remove the code entirely when not in dev
  return enableJotaiDebug && process.env.NODE_ENV === 'development' ? (
    <>
      <DevTools store={jotaiStore} />
      <JotaiUseDevtools />
    </>
  ) : null;
}

export function JotaiProvider({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={jotaiStore}>
      <JotaiDebug />
      {children}
    </Provider>
  );
}
