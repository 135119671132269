import React, { FunctionComponent } from 'react';

/*
 * A helper that makes a helper to wrap a component with a JotaiScopeProvider
 */

export function makeWrapWithJotaiScopeProvider(
  JotaiScopeProvider: FunctionComponent<{ children: React.ReactNode }>
) {
  return function wrapWithJotaiScopeProvider<
    Props extends Record<string, unknown>,
  >(Component: FunctionComponent<Props>): FunctionComponent<Props> {
    // this function is really a component if it helps your brain
    return function JotaiScopeProviderWrapper(args: Props) {
      return (
        <JotaiScopeProvider>
          <Component {...args} />
        </JotaiScopeProvider>
      );
    };
  };
}
