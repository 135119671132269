import * as Yup from 'yup';
import sumBy from 'lodash/sumBy';

import {
  GQCompanyClimateCommitmentKind,
  GQDisclosureInitiativeType,
  GQDisclosureTargetEmissionsType,
  GQDisclosureTargetIntensityType,
  GQDisclosureTargetReductionRate,
  GQFilterExpressionGroupInput,
} from '../generated/graphql';
import { YearMonth } from '../utils/YearMonth';
import { PleeInputSchema, PleeLocationSchema } from './pleeTypes';
import isNullish from '@watershed/shared-util/isNullish';
import isNotNullish from '@watershed/shared-util/isNotNullish';

export enum TargetScope {
  Scope1 = 'Scope 1',
  Scope2 = 'Scope 2',
  Scope3 = 'Scope 3',
  Scope1and2 = 'Scope 1 & 2',
  Scope1and3 = 'Scope 1 & 3',
  Scope2and3 = 'Scope 2 & 3',
  Scope1and2and3 = 'Scope 1 & 2 & 3',
}
export enum AcceptDeclineModify {
  Accept = 'Accept',
  Decline = 'Decline',
  Modify = 'Modify',
}

export const AttachmentSchema = Yup.object({
  filename: Yup.string().required(),
  isImported: Yup.boolean().required(),
  supplierAttachmentCrossOrgId: Yup.string().nullable().defined(),
}).required();

export const SurveySitesElectricityData = Yup.object({
  electricityMWh: Yup.number().required(),
  location: Yup.string().required(),
  renewableEnergyMWh: Yup.number().required(),
  renewableEnergyType: Yup.string().required(),
});
export type Site = Yup.InferType<typeof SurveySitesElectricityData>;

export const ReductionInitiatives = Yup.object()
  .shape({
    baselineYear: Yup.number().required(),
    endYear: Yup.number().required(),
    expectedReductionTonnesCo2e: Yup.number().nullable().optional(),
    name: Yup.string().required(),
    description: Yup.string().nullable().optional(),
    type: Yup.mixed<GQDisclosureInitiativeType>()
      .oneOf(Object.values(GQDisclosureInitiativeType))
      .required(),
    initiativeScope: Yup.mixed<TargetScope>()
      .oneOf([...Object.values(TargetScope), null])
      .nullable()
      .optional(),
    baselineYearEmissions: Yup.object({
      scope1TonnesCo2e: Yup.number().nullable(),
      scope2TonnesCo2e: Yup.number().nullable(),
      scope3TonnesCo2e: Yup.number().nullable(),
      scope3ByCategoryTonnesCo2e: Yup.lazy(() => {
        return Yup.mixed();
      }),
    })
      .nullable()
      .optional(),
    reductionType: Yup.mixed()
      .oneOf([...Object.values(GQDisclosureTargetIntensityType), null])
      .nullable()
      .optional(),
    reductionPercentage: Yup.number().min(0).max(100).nullable().optional(),
  })
  .test({
    name: 'endYear',
    message: 'End year should be after start year',
    test(value) {
      if (isNullish(value)) {
        return true;
      }
      return (
        isNotNullish(value.baselineYear) &&
        isNotNullish(value.endYear) &&
        value.baselineYear < value.endYear
      );
    },
  });

export type ReductionInitiativesType = {
  name: string | null;
  description: string | null;
  baselineYear: number;
  baselineYearEmissions: {
    scope1TonnesCo2e: number | null;
    scope2TonnesCo2e: number | null;
    scope3TonnesCo2e: number | null;
    scope3ByCategoryTonnesCo2e: Record<string, number>;
  } | null;
  endYear: number | null;
  type: GQDisclosureInitiativeType;
  initiativeScope: TargetScope | null;
  expectedReductionTonnesCo2e: number | null;
  reductionPercentage: number | null;
  reductionType: GQDisclosureTargetIntensityType | null;
};

export const SustainabilityInitiatives = Yup.object().shape({
  description: Yup.string().nullable().optional(),
  type: Yup.mixed<GQDisclosureInitiativeType>()
    .oneOf(Object.values(GQDisclosureInitiativeType))
    .required(),
});

export type SustainabilityInitiativesType = {
  description: string | null;
  type: GQDisclosureInitiativeType;
};

export const INTENSITY_TYPE_TO_LABEL: Record<
  GQDisclosureTargetIntensityType,
  string
> = {
  [GQDisclosureTargetIntensityType.Absolute]: 'Absolute',
  [GQDisclosureTargetIntensityType.GrossProfit]: 'Gross profit intensity',
  [GQDisclosureTargetIntensityType.SupplierEngagement]: 'Supplier engagement',
  [GQDisclosureTargetIntensityType.Headcount]: 'Headcount intensity',
  [GQDisclosureTargetIntensityType.Revenue]: 'Revenue intensity',
  [GQDisclosureTargetIntensityType.Unit]: 'Unit intensity',
};

export const REDUCTION_RATE_TO_LABEL: Record<
  GQDisclosureTargetReductionRate,
  string
> = {
  [GQDisclosureTargetReductionRate.LinearAnnualReduction]:
    'Linear annual reduction (LAR)',
  [GQDisclosureTargetReductionRate.YearOverYear]:
    'Year-on-year reduction (YoY)',
};

export const ReductionTargets = Yup.object({
  baselineYear: Yup.number().required(),
  baselineYearEmissions: Yup.object({
    scope1TonnesCo2e: Yup.number().required(),
    scope2TonnesCo2e: Yup.number().required(),
    scope3TonnesCo2e: Yup.number().required(),
    scope3ByCategoryTonnesCo2e: Yup.lazy(() => {
      return Yup.mixed();
    }),
  }).nullable(),
  type: Yup.mixed().oneOf([Object.values(GQDisclosureTargetIntensityType)]),
  targetPercentage: Yup.number().min(0).max(100).required(),
  targetScope: Yup.string().oneOf(Object.values(TargetScope)).required(),
  targetYear: Yup.number().required(),
  unit: Yup.lazy(() => Yup.string().nullable()),
  unitDescription: Yup.lazy(() => Yup.string().nullable()),
})
  .test({
    name: 'unit',
    message: 'Unit is required for unit intensity targets',
    test(value) {
      if (isNullish(value)) {
        return true;
      }
      return (
        value.type !== GQDisclosureTargetIntensityType.Unit ||
        (isNotNullish(value.unit) &&
          isNotNullish(value.unitDescription) &&
          value.unitDescription !== '')
      );
    },
  })
  .test({
    name: 'baselineYearEmissions',
    message:
      'Missing one or more baseline emissions for the selected target scope',
    test(value) {
      if (isNullish(value)) {
        return true;
      }
      // Baseline year emissions can be redacted, but if they're not, let's check their validity
      if (value.baselineYearEmissions === null) {
        return true;
      }

      const hasScope1 = value.baselineYearEmissions.scope1TonnesCo2e !== null;
      const hasScope2 = value.baselineYearEmissions.scope2TonnesCo2e !== null;
      const hasScope3 = value.baselineYearEmissions.scope3TonnesCo2e !== null;
      switch (value.targetScope) {
        case TargetScope.Scope1:
          return hasScope1;
        case TargetScope.Scope2:
          return hasScope2;
        case TargetScope.Scope3:
          return hasScope3;
        case TargetScope.Scope1and2:
          return hasScope1 && hasScope2;
        case TargetScope.Scope1and2and3:
          return hasScope1 && hasScope2 && hasScope3;
        default:
          return true;
      }
    },
  })
  .test({
    name: 'targetYear',
    message: 'Target year should be after base year',
    test(value) {
      if (isNullish(value)) {
        return true;
      }
      return (
        isNotNullish(value.baselineYear) &&
        isNotNullish(value.targetYear) &&
        value.baselineYear < value.targetYear
      );
    },
  });

export type ReductionTargetsType = {
  baselineYear: number;
  baselineYearEmissions: {
    scope1TonnesCo2e: number;
    scope2TonnesCo2e: number;
    scope3TonnesCo2e: number;
    scope3ByCategoryTonnesCo2e: Record<string, number>;
  } | null;
  reductionRate: GQDisclosureTargetReductionRate;
  type: GQDisclosureTargetIntensityType;
  targetScope: TargetScope;
  targetPercentage: number;
  targetYear: number;

  // Populated if type is "Unit intensity"
  // Unit is from our lscript unit system.
  unit: string | null;
  unitDescription: string | null;
};

export const MaterialIssue = Yup.object({
  issue: Yup.number(),
  description: Yup.string(),
});
export type MaterialIssueType = Yup.InferType<typeof MaterialIssue>;

export const ExpenseNaics = Yup.object({
  industryCode: Yup.string().required(),
  amount: Yup.number().nullable(),
});
export type ExpenseNaicsType = Yup.InferType<typeof ExpenseNaics>;

export enum HeatingType {
  NaturalGasFurnace = 'Natural gas',
  AirSourceHeatPump = 'Air-source heat pump only',
  AirSourceHeatPumpWithBackup = 'Air-source heat pump + natural gas backup',
  DistrictHeatingSteam = 'District heating/utility steam',
}

export enum CloudProvider {
  Amazon = 'Amazon',
  Google = 'Google',
  Microsoft = 'Microsoft',
  Other = 'Other',
}

export const TmsManualInput = Yup.object({
  transportationMethod: Yup.string().required(),
  vehicleType: Yup.string().required(),
  tonnes: Yup.number().required(),
  kilometers: Yup.number().required(),
  fuelType: Yup.string(),
  fuelUsed: Yup.number(),
});
export type TmsManualInputType = Yup.InferType<typeof TmsManualInput>;

export const CeeBuildingItem = Yup.object({
  size: Yup.number().required(),
  location: Yup.string().required(),
  heating: Yup.mixed<HeatingType>().oneOf(Object.values(HeatingType)),
});
export type CeeBuildingItemType = Yup.InferType<typeof CeeBuildingItem>;

export const CeeWoodItem = Yup.object({
  country: Yup.string().required(),
  woodCubeMeters: Yup.number().required(),
});

export type CeeWoodItemType = Yup.InferType<typeof CeeWoodItem>;

export const CeeCloudItem = Yup.object({
  provider: Yup.mixed<CloudProvider>()
    .oneOf(Object.values(CloudProvider))
    .required(),
  percent: Yup.number().required(),
  annualSpend: Yup.number().nullable(),
  annualSpendCurrency: Yup.string().required(),
});
export type CeeCloudItemType = Yup.InferType<typeof CeeCloudItem>;

export type AttachmentAnswerValue = Yup.InferType<typeof AttachmentSchema>;

export type AnswerValue =
  | string
  | number
  | boolean
  | Array<string>
  | null
  | undefined
  | object
  | Array<AttachmentAnswerValue>;

export type ExplanationValue =
  | Array<string>
  | { [key: string]: string }
  | string
  | null
  | undefined;

export type AnswerValueSerialized = {
  value: AnswerValue;
  explanation?: ExplanationValue;
} | null;

export enum PostSurveyRecommendation {
  BuyCleanPower = 'BuyCleanPower',
  RunAClimateProgram = 'RunAClimateProgram',
  GetComprehensiveMeasurement = 'GetComprehensiveMeasurement',
}

export const SurveyAttachmentSchema = Yup.array(
  Yup.object({
    originalFilename: Yup.string().required(),
    questionKey: Yup.string().required(),
    signedDownloadUrl: Yup.string().nullable(),
    fileMetadata: Yup.object({
      id: Yup.string().required(),
      createdAt: Yup.date().required(),
    }),
  })
).nullable();

export const SurveyAnswerSchema = Yup.object({
  questionKey: Yup.string().defined().required(),
  answer: Yup.mixed(),
  skipped: Yup.boolean().required().defined(),
  questionIndex: Yup.number().nullable(),
});

export type SurveyAnswer = Yup.InferType<typeof SurveyAnswerSchema>;
export type SurveyAttachmentAnswer = Yup.InferType<
  typeof SurveyAttachmentSchema
>;

export type SurveyAttachmentSigningInput = {
  files: Array<{
    name: string;
    sizeBytes: number;
  }>;
};

export type SurveyAttachmentSigningData = {
  signedUrl: string;
  fileId: string;
  supplierAttachmentCrossOrgId: string;
};

export enum ShareMethod {
  AttachReport = 'AttachReport',
  ManualAdd = 'ManualAdd',
  NotMeasured = 'NotMeasured',
  Estimated = 'Estimated',
}

export enum HaveMeasuredEmissionsV2 {
  WillShare = 'WillShare',
  WillEstimate = 'WillEstimate',
  No = 'No',
  SbtHaveMeasuredButWillNotShare = 'SbtHaveMeasuredButWillNotShare',
}

export enum AllocateEmissionsMethodV2 {
  RevenueAndTotalEmissions = 'RevenueAndTotalEmissions',
  RevenueIntensity = 'RevenueIntensity',
  Allocated = 'Allocated',
}

export enum ShareMethodV2 {
  AttachReport = 'AttachReport',
  ManualAdd = 'ManualAdd',
}

export enum ExpenseCategoryShareMethodOption {
  LCA = 'LCA',
  Estimate = 'Estimate',
  No = 'No',
}

export enum CarbonMeasurementPlan {
  NoPlan = 'NoPlan',
  MeasurementInProgress = 'MeasurementInProgress',
  IntendToMeasure = 'IntendToMeasure',
  Unsure = 'Unsure',
}

export enum FacilityEnergySources {
  Coal = 'Coal',
  NaturalGas = 'Natural gas',
  Electricty = 'Electricity (purchased from grid)',
  Wood = 'Wood',
  BiomassNonWood = 'Biomass (non-wood)',
  Solar = 'Solar',
  Petrol = 'Petrol',
  Propane = 'Propane',
}

export const FacilityEnergySourceUnits: Record<FacilityEnergySources, string> =
  {
    [FacilityEnergySources.Coal]: 'kg, mmbtu',
    [FacilityEnergySources.NaturalGas]: 'mmbtu, MJ, m^3',
    [FacilityEnergySources.Electricty]: 'kwh',
    [FacilityEnergySources.Wood]: 'MJ, kg',
    [FacilityEnergySources.BiomassNonWood]: 'kg',
    [FacilityEnergySources.Solar]: 'kwh',
    [FacilityEnergySources.Petrol]: 'liters, MJ',
    [FacilityEnergySources.Propane]: 'liters, mmbtu',
  };

export enum FacilityWaterSources {
  MunicipalWater = 'Municipal water',
  Rainwater = 'Rainwater',
  Groundwater = 'Groundwater',
  Wastewater = 'Wastewater',
  BrackishSurfaceWaterOrSeawater = 'Brackish surface water/seawater',
  Other = 'Other',
}

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}

export enum YesNoMaybe {
  Yes = 'Yes',
  No = 'No',
  Maybe = 'Maybe',
}

export enum RenewableEnergyUsage {
  All = 'All',
  Some = 'Some',
  None = 'None',
}

export const YesNoBool: Record<YesNo, boolean> = {
  [YesNo.Yes]: true,
  [YesNo.No]: false,
};

export const YesNoMaybeBool: Record<YesNoMaybe, boolean> = {
  [YesNoMaybe.Yes]: true,
  [YesNoMaybe.No]: false,
  [YesNoMaybe.Maybe]: false,
};

export enum AllocateEmissionsMethod {
  RevenueAndTotalEmissions = 'RevenueAndTotalEmissions',
  RevenueIntensity = 'RevenueIntensity',
  RevenueAndEstimate = 'RevenueAndEstimate',
  No = 'No',
}

export enum ExternalReportingType {
  EnvironmentalComplianceInitiatives = 'EnvironmentalComplianceInitiatives',
  RegulatoryReporting = 'RegulatoryReporting',
  CustomerOrInvestorReports = 'CustomerOrInvestorReports',
  BrandOrMarketing = 'BrandOrMarketing',
}

export type DisclosureTargetInput = {
  publicDisclosureId?: string;
  privateDisclosureId?: string;
  name?: string;
  description?: string;
  baseYear: YearMonth;
  filters: GQFilterExpressionGroupInput;
  emissionsType: GQDisclosureTargetEmissionsType;
  targetYear: YearMonth;
  reductionTarget: number;
  intensityType: GQDisclosureTargetIntensityType;
  reductionRate: GQDisclosureTargetReductionRate;
  unit: string | null;
  unitDescription: string | null;
};

export type DisclosureCompanyFactInput = {
  privateDisclosureId?: string;
  value?: any;
  supplierTableColumnId?: string;
};

export type ReductionTimeseriesInput = {
  baseYear: YearMonth;
  targetYear: YearMonth;
  reductionTarget: number;
  reductionRate: GQDisclosureTargetReductionRate;
};

export type SustainabilityCommitment = {
  kind: GQCompanyClimateCommitmentKind;
  targetYear: number;
};

export enum SustainabilityTeamSize {
  None = 'None',
  Intern = 'Intern',
  OneToThree = 'OneToThree',
  FourToSix = 'FourToSix',
  SevenOrMore = 'SevenOrMore',
}

export const GHG_SCOPE_SCHEMA = Yup.number()
  .nullable()
  .defined()
  .min(0, 'Please enter a positive number.')
  .max(1_000_000_000, 'Please enter a number less than 1,000,000,000.');

export enum LCAScope {
  CradleToGrave = 'CradleToGrave',
  CradleToGate = 'CradleToGate',
}

export const LcaDatumSchema = Yup.object({
  reportingYear: Yup.number().default(2023),
  productName: Yup.string().required(),
  functionalUnit: Yup.string().required('This field is required'),
  kgco2ePerFunctionalUnit: Yup.number()
    .required('This field is required')
    .min(0),
  quantityFunctionalUnitsSoldToRootCustomer: Yup.number().optional(),
  lcaScope: Yup.mixed<LCAScope>()
    .oneOf([null, ...Object.values(LCAScope)])
    .required(),
  peerReviewed: Yup.mixed<YesNoMaybe>()
    .oneOf([null, ...Object.values(YesNoMaybe)])
    .required(),
  methodologyNotes: Yup.string(),
  methodologyUpload: Yup.array(AttachmentSchema).required(),
});

export type LcaDatum = Yup.InferType<typeof LcaDatumSchema>;

export const ExpenseCategoryEmissionsSchema = Yup.object({
  beaCode: Yup.string().required(),
  shareMethod: Yup.string().required(),
  lcaData: Yup.array(LcaDatumSchema).optional(),
  // if I could go back and do it all over again, I'd have put pleeLocations inside of pleeInputs
  // however, we already have answers in the DB in this format, so it's easier to just keep it as a separate field
  pleeLocations: Yup.array(PleeLocationSchema)
    .optional()
    .min(1)
    .test('test-sum-to-100', 'Must sum up to 100', (locations) => {
      if (isNullish(locations)) {
        return true;
      }
      return sumBy(locations, (loc) => loc.productionProportion ?? 0) === 100;
    }),
  pleeInputs: PleeInputSchema.optional().default(undefined),
}).required();

export type ExpenseCategoryEmissionsType = Yup.InferType<
  typeof ExpenseCategoryEmissionsSchema
>;

export type RootCompany = {
  __typename?: 'Company';
  id: string;
  name: string;
  fullLogoUrl: string | null;
};
