import Icon, { IconProps } from '../Icon';
const ArrowLeftIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M18.975 11.025H7.425L12.75 5.7c.375-.375.375-1.05 0-1.425s-1.05-.375-1.425 0L4.35 11.25c-.075.075-.15.225-.225.3a1.278 1.278 0 0 0 0 .75c.075.15.15.225.225.3l6.975 6.975c.375.375 1.05.375 1.425 0s.375-1.05 0-1.425l-5.325-5.325h11.55c.525 0 .975-.45.975-.975 0-.525-.375-.825-.975-.825Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ArrowLeftIcon;
