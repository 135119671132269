import { Box, Stack, Typography } from '@mui/material';
import { Trans, useLingui } from '@lingui/react/macro';
import { useCommandPalettePageContext } from './CommandPaletteContext';
import CommandPaletteShortcutButton from './CommandPaletteShortcutButton';

interface CommandPaletteFooterProps {
  enterActionLabel?: string;
  onEnterAction?: () => void;
}

export default function CommandPaletteFooter({
  enterActionLabel,
  onEnterAction,
}: CommandPaletteFooterProps) {
  const { t } = useLingui();
  const palette = useCommandPalettePageContext();

  return (
    <Stack
      direction="row"
      gap={0}
      boxShadow={(theme) => `0 -1px 0 0 ${theme.palette.border}`}
      px={1.5}
      py={0.5}
    >
      <Stack direction="row" gap={0} alignItems="center">
        <CommandPaletteShortcutButton
          description={t({
            message: `Exit`,
            context: 'Button to close a dialog',
          })}
          textRepresentation={t({
            message: 'esc',
            context: 'Escape key for keyboard shortcuts',
          })}
          onClick={palette.close}
        />
        {palette.location.length > 1 ? (
          <CommandPaletteShortcutButton
            description={t({
              message: `Navigate back`,
              context: 'Button to navigate back in a dialog',
            })}
            textRepresentation={t({
              message: 'del',
              context: 'Backspace/delete key for keyboard shortcuts',
            })}
            onClick={palette.pop}
          />
        ) : null}
      </Stack>

      {onEnterAction ? (
        <>
          <Box flexGrow={1} />

          <Stack
            direction="row"
            gap={0.5}
            alignItems="center"
            component="button"
            border="0"
            borderRadius="4px"
            py={0.5}
            px={1}
            color={(theme) => theme.palette.grey50}
            bgcolor="transparent"
            sx={{
              ':hover': {
                backgroundColor: (theme) => theme.palette.grey10,
                '& div': {
                  backgroundColor: (theme) => theme.palette.grey20,
                },
              },
            }}
            onClick={onEnterAction}
          >
            <Typography
              component="span"
              variant="body3"
              color={(theme) => theme.palette.grey50}
            >
              {enterActionLabel ?? (
                <Trans context="Button copy to open a dialog">Open</Trans>
              )}
            </Typography>

            <svg width="12" height="12" fill="none">
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M4.854 4.646a.5.5 0 0 1 0 .708L2.707 7.5l2.147 2.146a.5.5 0 0 1-.708.708l-2.5-2.5a.5.5 0 0 1 0-.708l2.5-2.5a.5.5 0 0 1 .708 0Z"
                clipRule="evenodd"
              />
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M10 1.5a.5.5 0 0 1 .5.5v3.5A2.5 2.5 0 0 1 8 8H2a.5.5 0 0 1 0-1h6a1.5 1.5 0 0 0 1.5-1.5V2a.5.5 0 0 1 .5-.5Z"
                clipRule="evenodd"
              />
            </svg>
          </Stack>
        </>
      ) : null}
    </Stack>
  );
}
