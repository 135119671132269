import {
  AutocompleteRenderGetTagProps,
  Chip,
  FilterOptionsState,
  MenuItem,
  Stack,
  Tooltip,
  createFilterOptions,
  useTheme,
} from '@mui/material';
import { Trans } from '@lingui/react/macro';
import { smartLowerCase } from '@watershed/shared-universal/utils/helpers';
import { coalesceNullish } from '@watershed/shared-universal/utils/coalesceNullish';

import { useFieldInfo, useTsController } from 'zod-form';
import {
  SelectFieldNonFormik,
  SelectFieldProps,
} from '@watershed/ui-core/components/Form/SelectField';
import { ReactNode } from 'react';
import * as z from 'zod';
/* need to import react-hook-form for typescript to infer the type of ZodForm below */
import 'react-hook-form';
import invariant from 'invariant';
import { ListOption, ListOptionValueTypes } from './types';
import {
  getListOptionsFromValues,
  isZodTypeArray,
  unwrapNullishZodType,
} from './utils';
import { NonEmptyArray } from '@watershed/shared-universal/utils/isNonEmptyArray';
import { AutocompleteFieldNonFormik } from '@watershed/ui-core/components/Form/AutocompleteField';
import { SelectableTileGroupFieldNonFormik } from '@watershed/ui-core/components/Form/SelectableTileGroupField';
import IndustryCodeAutocompleteNonFormik, {
  IndustryCodeAutocompleteExtraProps,
} from '@watershed/ui-core/components/Form/IndustryCodeAutocomplete';
import { CountryAutocompleteFieldNonFormik } from '@watershed/ui-core/components/Form/CountrySelectField';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import assertNever from '@watershed/shared-util/assertNever';
import { SelectCurrencyFieldNonFormik } from '@watershed/ui-core/components/Form/CurrencyField';
import { CompanyIdAutocompleteNonFormik } from '../CompanyAutocomplete';
import {
  CommonZodFieldProps,
  getInputFieldProps,
  getCommonFieldProps,
  useFieldPropsFromTsController,
  humanizeFieldId,
  CommonControllerProps,
} from './fieldUtils';
import nullifyEmptyString from '@watershed/shared-universal/utils/nullifyEmptyString';
import castArray from 'lodash/castArray';
import pick from 'lodash/pick';
import { normalizeFilterValue } from '@watershed/shared-universal/utils/analytics';
import {
  TestIdPrefix,
  getPrefixedTestId,
} from '@watershed/shared-universal/utils/testUtils';
import { SelectableTileGroupOption } from '@watershed/ui-core/components/Form/SelectableTileGroup';
import omit from 'lodash/omit';

const zodSelectFieldEditorTypeSchema = z.enum([
  'Select',
  'SelectableTileGroup',
  'SelectAutocomplete',
  'IndustryCodeAutocomplete',
  'CountryAutocomplete',
  'CurrencyAutocomplete',
  'CompanyAutocomplete',
]);
export const ZodSelectFieldEditorType = zodSelectFieldEditorTypeSchema.Enum;
// eslint-disable-next-line @typescript-eslint/no-redeclare -- make this behave like an enum
export type ZodSelectFieldEditorType = keyof typeof ZodSelectFieldEditorType;

const COMMON_SELECT_PROPS = [
  'multiple',
  'sx',
  'readOnly',
] as const satisfies ReadonlyArray<keyof SelectFieldProps>;

export interface SelectEditorsWithInputProps {
  autoFocus?: boolean;
  fsUnmask?: boolean;
  loading?: boolean;
}

export type BaseAbstractSelectProps<T extends ListOptionValueTypes> = {
  renderListOptionValue?(
    listOption: ListOption<T | null> | undefined
  ): ReactNode;
  renderListOption?(listOption: ListOption<T | null> | undefined): ReactNode;
  filterListOptions?(listOptions: Array<ListOption<T>>): Array<ListOption<T>>;
  placeholder?: string;
  name: string;
  removeNullOption?: boolean;
} & Pick<SelectFieldProps, (typeof COMMON_SELECT_PROPS)[number]> &
  CommonZodFieldProps &
  CommonControllerProps<T>;
export type AbstractSelectPropsWithoutListOptions<
  T extends ListOptionValueTypes,
> = BaseAbstractSelectProps<T> &
  (
    | ({
        editorType?: typeof ZodSelectFieldEditorType.Select;
      } & SelectEditorsWithInputProps)
    | {
        editorType: typeof ZodSelectFieldEditorType.SelectableTileGroup;
        numColumns?: number;
        hideSelectAffordance?: boolean;
        optionProps?: Array<Partial<SelectableTileGroupOption<T>>>;
      }
    | ({
        editorType: typeof ZodSelectFieldEditorType.IndustryCodeAutocomplete;
      } & Pick<
        IndustryCodeAutocompleteExtraProps,
        'hideCode' | 'includeTaxonomy'
      > &
        SelectEditorsWithInputProps)
    | ({
        editorType: typeof ZodSelectFieldEditorType.SelectAutocomplete;
        freeSolo?: boolean;
        showInvalidChips?: boolean;
      } & SelectEditorsWithInputProps)
    | ({
        // if any of theese editor types needs specific props they can be pulled out into separate union members just like the ones above
        editorType:
          | typeof ZodSelectFieldEditorType.CountryAutocomplete
          | typeof ZodSelectFieldEditorType.CurrencyAutocomplete
          | typeof ZodSelectFieldEditorType.CompanyAutocomplete;
      } & SelectEditorsWithInputProps)
  );

export type AbstractSelectProps<T extends ListOptionValueTypes> = {
  listOptions: Array<ListOption<T>>;
} & AbstractSelectPropsWithoutListOptions<T>;

function getListOptionDisplay<T extends ListOptionValueTypes>(
  lo: ListOption<T>
): string;
function getListOptionDisplay<T extends ListOptionValueTypes>(
  lo: ListOption<T | null> | undefined
): string | undefined;
function getListOptionDisplay<T extends ListOptionValueTypes>(
  lo: ListOption<T | null> | undefined
) {
  return lo?.display ?? lo?.value?.toString();
}

function useIsTouched() {
  const {
    formState,
    field: { name, value },
  } = useTsController<any>();
  return (
    formState.dirtyFields[name] ||
    (value !== undefined && !(Array.isArray(value) && value.length === 0))
  );
}

function useMaybeNullListOptions<T extends ListOptionValueTypes>(
  initialListOptions: Array<ListOption<T>>,
  opts: { disableNullOption?: boolean; removeNullOption?: boolean }
): Array<ListOption<T | null>> {
  const { zodType } = useFieldInfo();
  const isNullable = isZodTypeArray(zodType)
    ? zodType.element.isNullable()
    : zodType.isNullable();
  const isTouched = useIsTouched();
  return (isTouched && !isNullable) ||
    opts.removeNullOption ||
    initialListOptions.some((lo) => lo.value === null)
    ? initialListOptions
    : [
        {
          value: null,
          display: undefined,
          disabled: !isNullable || opts.disableNullOption,
          hidden: !isNullable || opts.disableNullOption,
        },
        ...initialListOptions,
      ];
}

// this monstrosity works around an issue with MUI in which it will
// not fire the onChange callback when the value is the same on selection
// it ALSO will not consider null a controlled value so there is no other way to distinguish undefined from null
// and therefore no way to distinguish touched from untouched
// please ping Sterling if you think of a better way cause i'd love to hear it
const NULL_STRING_VALUE = 'null-878cb63d-ade6-472e-8fb1-637334560af0';

export function ZodAbstractSelectField<T extends ListOptionValueTypes>({
  listOptions: initialListOptions,
  onChangeTransform,
  ...propsInitial
}: AbstractSelectProps<T>): JSX.Element {
  const theme = useTheme();
  const {
    onChange,
    value,
    placeholder: placeholderInitial,
    required,
    ...tsProps
  } = useFieldPropsFromTsController<T>({ onChangeTransform });
  const props = {
    editorType: ZodSelectFieldEditorType.Select,
    ...propsInitial,
  };
  const { zodType } = useFieldInfo();
  const isSchemaArray = isZodTypeArray(unwrapNullishZodType(zodType));

  //TODO: we probably should move the contents of the switches to their own components
  const filteredListOptions = props.filterListOptions
    ? props.filterListOptions(initialListOptions)
    : initialListOptions;
  const usingFamiles = filteredListOptions.some((o) => 'family' in o);
  const nullIncludedListOptions: Array<ListOption<T | null>> =
    useMaybeNullListOptions<T>(filteredListOptions, {
      disableNullOption: props.disableNullOption,
      removeNullOption: props.removeNullOption,
    });
  const isTouched = useIsTouched();
  const commonSelectProps = pick(props, COMMON_SELECT_PROPS);

  const placeholder =
    props.placeholder ||
    placeholderInitial ||
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/require-locale-argument
    `Choose ${smartLowerCase(
      humanizeFieldId(props.label?.toString() ?? tsProps.label)
    )}`;
  const dataTest = getPrefixedTestId(TestIdPrefix.ZodField, props.name);

  switch (props.editorType) {
    case ZodSelectFieldEditorType.Select:
      const renderListOptionValue =
        props.renderListOptionValue ??
        ((lo) =>
          lo?.value === null
            ? isTouched
              ? (props.nullValueLabel ?? 'None')
              : placeholder
            : getListOptionDisplay(lo));
      const renderValue = (v: T | undefined) =>
        renderListOptionValue(findListOption(v));
      const renderListOption =
        props.renderListOption ??
        ((lo) => lo?.display ?? lo?.value?.toString());

      function findListOption(targetValue: T | string | undefined) {
        return nullIncludedListOptions.find(
          (lo) =>
            lo.value === targetValue ||
            lo.display === targetValue ||
            (typeof lo.value !== 'string' &&
              typeof targetValue === 'string' &&
              (lo.value === nullifyEmptyString(targetValue) ||
                lo.value ===
                  (targetValue === NULL_STRING_VALUE ? null : targetValue)))
        );
      }

      const isBoolean = initialListOptions.some(
        (i) => typeof i.value === 'boolean'
      );

      return (
        <SelectFieldNonFormik
          {...tsProps}
          {...getCommonFieldProps(props)}
          {...getInputFieldProps(props)}
          {...commonSelectProps}
          data-test={dataTest}
          renderValue={renderValue}
          fieldChildren={
            props.readOnly ? (
              <Stack direction="row">{renderValue(value)}</Stack>
            ) : undefined
          }
          SelectDisplayProps={{
            style: {
              // color the placeholder values grey
              color:
                value || isTouched ? undefined : theme.palette.text.secondary,
            },
          }}
          required={required || props.required}
          displayEmpty={true}
          value={
            value === null ? (NULL_STRING_VALUE as T) : (value ?? ('' as T))
          }
          onChange={(e) => {
            const targetValue =
              isBoolean && e.target.value !== NULL_STRING_VALUE
                ? ((e.target.value === 'true') as T)
                : e.target.value;

            const value = findListOption(targetValue)?.value;
            invariant(
              value !== undefined,
              `Couldn't find matching select option`
            );
            onChange(value as any);
          }}
        >
          {nullIncludedListOptions.map(({ value, display, disabled }) =>
            value === null && disabled ? null : (
              <MenuItem
                key={String(value)}
                value={
                  typeof value === 'boolean'
                    ? value.toString()
                    : value === null
                      ? NULL_STRING_VALUE
                      : (value ?? '')
                }
                disabled={disabled}
              >
                {renderListOption({ value, display }) ?? 'None'}
              </MenuItem>
            )
          )}
        </SelectFieldNonFormik>
      );
    case ZodSelectFieldEditorType.SelectableTileGroup: {
      const baseProps = {
        ...tsProps,
        ...getInputFieldProps(props),
        ...getCommonFieldProps(props),
        inputId: tsProps.id,
        disabled: props.disabled,
        hideSelectAffordance: props.hideSelectAffordance ?? true,
        numColumns: props.numColumns,
        options: filteredListOptions.map(
          (lo, i): SelectableTileGroupOption<T> => {
            const option = props.optionProps?.[i] ?? {};
            // Have to do this due to the typing of image, Icon vs each other
            const imageOrIcon = (() => {
              if (option.image ?? lo.startIcon) {
                return { image: option.image ?? lo.startIcon, Icon: undefined };
              }
              return { image: undefined, Icon: option.Icon };
            })();
            return {
              ...omit(option, ['image', 'Icon']),
              ...imageOrIcon,
              value: lo.value,
              label: getListOptionDisplay(lo),
              disabled: lo.disabled,
            };
          }
        ),
      };

      return commonSelectProps.multiple ? (
        <SelectableTileGroupFieldNonFormik
          {...baseProps}
          multiple
          value={(value as unknown as Array<T>) ?? []}
          onChange={(val) => onChange(val as any)}
        />
      ) : (
        <SelectableTileGroupFieldNonFormik
          {...baseProps}
          multiple={false}
          value={(value as T) ?? null}
          onChange={(val) => onChange(val)}
        />
      );
    }
    case ZodSelectFieldEditorType.SelectAutocomplete: {
      const freeSolo = props.freeSolo;
      const listOptions: Array<ListOption<T | null>> = nullIncludedListOptions;
      const getOptionLabel = (option: string | ListOption<T | null>): string =>
        typeof option === 'string'
          ? option
          : (option.display ??
            option.value?.toString() ??
            // for autocomplete the placholder is applied to the input so we don't need to force it in the option display like we do with native select
            props.nullValueLabel ??
            'None');
      const renderTagsWithInvalidStyle = (
        tagValue: Array<ListOption<T | null>>,
        getTagProps: AutocompleteRenderGetTagProps
      ) => {
        if (props.loading) {
          return null;
        }
        return tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          const listOption = listOptions.find(
            (lo) => lo.value === option.value
          );
          const isOptionValid = isNotNullish(listOption);

          const chipComponent = (
            <Chip
              key={key}
              label={
                isOptionValid
                  ? (option.display ?? option.value)
                  : 'Invalid value'
              }
              color={isOptionValid ? undefined : 'error'}
              {...tagProps}
            />
          );
          if (isOptionValid) {
            return chipComponent;
          }
          return (
            <Tooltip
              key={key}
              title={
                <Trans context="Tooltip copy">
                  This value has been removed and is no longer valid
                </Trans>
              }
            >
              {chipComponent}
            </Tooltip>
          );
        });
      };
      const baseProps = {
        ...tsProps,
        required,
        getOptionLabel,
        disableClearable: required || props.required,
        autoCompleteOffPrettyPlease: true,
        options: listOptions,
        autoFocus: props.autoFocus,
        loading: props.loading,
        readOnly: props.readOnly,
        sx: props.sx,
        'data-test': dataTest,
        slotProps: {
          popper: {
            slotProps: {
              root: {
                'data-test': getPrefixedTestId(
                  TestIdPrefix.ZodFieldAutocompletePopper,
                  props.name
                ),
              },
            },
          },
        },
        ...getCommonFieldProps(props),
        ...(usingFamiles && {
          groupBy: (option: ListOption<T | null>) => option.family ?? 'Other',
        }),
        placeholder:
          value && !(Array.isArray(value) && value.length === 0)
            ? undefined
            : placeholder,
        isOptionEqualToValue: (
          a: ListOption<T | null>,
          b: ListOption<T | null>
        ) => a.value === b.value,
        getOptionDisabled: ({ disabled }: ListOption<T | null>) =>
          disabled ?? false,
        filterOptions: (
          options: Array<ListOption<T | null>>,
          state: FilterOptionsState<ListOption<T | null>>
        ) =>
          // ignores case and accents by default
          // maybe we ultimately want to include the value and not just the display here
          createFilterOptions<ListOption<T | null>>({
            stringify: (option) =>
              coalesceNullish(
                state.getOptionLabel(option),
                (label) => `${label} ${normalizeFilterValue(label)}`
              ),
          })(
            options.filter(({ hidden }) => !hidden),
            state
          ),
        renderTags: props.showInvalidChips
          ? renderTagsWithInvalidStyle
          : undefined,
      };

      const parsedValue =
        isSchemaArray && Array.isArray(value) ? value.at(0) : value;
      const listOption = listOptions.find((lo) => lo.value === parsedValue);
      return props.multiple ? (
        <AutocompleteFieldNonFormik
          {...baseProps}
          multiple
          value={((value as unknown as Array<T>) ?? []).map((v) => ({
            value: v,
            display: listOptions.find((lo) => lo.value === v)?.display,
          }))}
          onChange={(_, value) => {
            onChange(
              value.map((v) => (typeof v === 'string' ? v : v.value)) as any
            );
          }}
          freeSolo={freeSolo}
        />
      ) : (
        <AutocompleteFieldNonFormik
          {...baseProps}
          value={
            // we fallback to null to support showing the placeholder
            isNotNullish(parsedValue)
              ? listOption && (isTouched || listOption.display)
                ? {
                    value: parsedValue,
                    display: listOption.display,
                  }
                : null
              : null
          }
          onChange={(_, value) => {
            const newValue =
              typeof value === 'string'
                ? (value as any)
                : value && 'value' in value
                  ? value.value
                  : null;
            onChange(isSchemaArray ? castArray(newValue) : newValue);
          }}
          freeSolo={freeSolo}
        />
      );
    }
    // NOTE: It seems like there should be a better way to do this, but I haven't found one.
    case ZodSelectFieldEditorType.IndustryCodeAutocomplete:
      // TODO: Is this worth doing?
      const codes = filteredListOptions
        .map((lo) => lo.value?.toString())
        .filter(isNotNullish);
      return (
        <IndustryCodeAutocompleteNonFormik
          {...tsProps}
          {...props}
          {...commonSelectProps}
          data-test={dataTest}
          required={required}
          value={value?.toString() ?? null}
          onChange={(val) => onChange(val as any)}
          disableClearable={required || props.required}
          options={codes}
          hideCode={props.hideCode ?? true}
          includeTaxonomy={props.includeTaxonomy ?? false}
          placeholder={props.placeholder}
        />
      );
    case ZodSelectFieldEditorType.CountryAutocomplete:
      // TODO: Is this worth doing?
      const countries = new Set(
        filteredListOptions
          .map((lo) => lo.value?.toString())
          .filter(isNotNullish)
      );
      return (
        <CountryAutocompleteFieldNonFormik
          {...tsProps}
          {...props}
          {...commonSelectProps}
          data-test={dataTest}
          required={required || props.required}
          disableClearable={required || props.required}
          countryFilter={(country) => !countries.has(country.name)}
          value={value?.toString() ?? null}
          onChange={(_, val) => onChange(val as any)}
        />
      );
    case ZodSelectFieldEditorType.CurrencyAutocomplete:
      return (
        <SelectCurrencyFieldNonFormik
          {...tsProps}
          {...props}
          {...commonSelectProps}
          required={required || props.required}
          data-test={dataTest}
          disableClearable={required || props.required}
          value={value?.toString() ?? null}
          onChange={(_, val) => onChange(val as any)}
        />
      );
    case ZodSelectFieldEditorType.CompanyAutocomplete:
      return (
        <CompanyIdAutocompleteNonFormik
          {...tsProps}
          {...props}
          {...commonSelectProps}
          required={required}
          value={value?.toString() ?? null}
          onChange={(val) => onChange(val as any)}
        />
      );
    default:
      assertNever(props);
  }
}

/**
 * Single select enum field.
 */
export function ZodEnumField({
  listOptions,
  ...restProps
}: // doing this rather than DistributiveOmit because the distribution blows up the type size and results in
// `The inferred type of this node exceeds the maximum length the compiler will serialize. An explicit type annotation is needed.`
AbstractSelectPropsWithoutListOptions<string | null> & {
  listOptions?: Array<ListOption<string | null>>;
}) {
  const fieldInfo = useFieldInfo();
  const zodEnum: z.ZodEnum<NonEmptyArray<string>> = fieldInfo.type;
  return (
    <ZodAbstractSelectField<string | null>
      {...restProps}
      listOptions={listOptions ?? getListOptionsFromValues(zodEnum.options)}
    />
  );
}

/**
 * Multi select enum field.
 */
export function ZodEnumArrayField({
  listOptions,
  ...restProps
}: {
  listOptions?: Array<ListOption<string | null>>;
} & CommonZodFieldProps) {
  const fieldInfo = useFieldInfo();
  const zodEnumArray: z.ZodArray<z.ZodEnum<NonEmptyArray<string>>> =
    fieldInfo.type;
  return (
    <ZodAbstractSelectField
      editorType={ZodSelectFieldEditorType.SelectAutocomplete}
      {...restProps}
      multiple={true}
      listOptions={
        listOptions ?? getListOptionsFromValues(zodEnumArray.element.options)
      }
    />
  );
}
