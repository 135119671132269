/*
  This file is semi-automatically generated from
  cliq/taxonomy/scripts/generate_uksic_2007__ceda_5_weighted_mapping.py in the cliq repo
  Do not manually modify this file
 */

import type { WeightedBeaCodes } from './industryCodeUtils';

export const UKSIC_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['01110']: {
    label: 'Growing of cereals (except rice), leguminous crops and oil seeds',
    BEA_CODES: { '1111B0': 0.6841141473331739, '1111A0': 0.3158858526668261 },
  },
  ['01120']: { label: 'Growing of rice', BEA_CODES: { '1111B0': 1.0 } },
  ['01130']: {
    label: 'Growing of vegetables and melons, roots and tubers',
    BEA_CODES: { '111200': 0.4517470619742273, '111900': 0.5482529380257727 },
  },
  ['01140']: { label: 'Growing of sugar cane', BEA_CODES: { '111900': 1.0 } },
  ['01150']: { label: 'Growing of tobacco', BEA_CODES: { '111900': 1.0 } },
  ['01160']: { label: 'Growing of fibre crops', BEA_CODES: { '111900': 1.0 } },
  ['01190']: {
    label: 'Growing of other non-perennial crops',
    BEA_CODES: { '111900': 1.0 },
  },
  ['01210']: { label: 'Growing of grapes', BEA_CODES: { '111300': 1.0 } },
  ['01220']: {
    label: 'Growing of tropical and subtropical fruits',
    BEA_CODES: { '111300': 1.0 },
  },
  ['01230']: {
    label: 'Growing of citrus fruits',
    BEA_CODES: { '111300': 1.0 },
  },
  ['01240']: {
    label: 'Growing of pome fruits and stone fruits',
    BEA_CODES: { '111300': 1.0 },
  },
  ['01250']: {
    label: 'Growing of other tree and bush fruits and nuts',
    BEA_CODES: { '111300': 1.0 },
  },
  ['01260']: {
    label: 'Growing of oleaginous fruits',
    BEA_CODES: { '111300': 1.0 },
  },
  ['01270']: {
    label: 'Growing of beverage crops',
    BEA_CODES: { '111900': 1.0 },
  },
  ['01280']: {
    label: 'Growing of spices, aromatic, drug and pharmaceutical crops',
    BEA_CODES: { '111900': 1.0 },
  },
  ['01290']: {
    label: 'Growing of other perennial crops',
    BEA_CODES: { '111900': 1.0 },
  },
  ['01300']: { label: 'Plant propagation', BEA_CODES: { '111400': 1.0 } },
  ['01410']: { label: 'Raising of dairy cattle', BEA_CODES: { '112120': 1.0 } },
  ['01420']: {
    label: 'Raising of other cattle and buffaloes',
    BEA_CODES: { '1121A0': 1.0 },
  },
  ['01430']: {
    label: 'Raising of horses and other equines',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['01440']: {
    label: 'Raising of camels and camelids',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['01450']: {
    label: 'Raising of sheep and  goats',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['01460']: { label: 'Raising of swine/pigs', BEA_CODES: { '112A00': 1.0 } },
  ['01470']: { label: 'Raising of poultry', BEA_CODES: { '112300': 1.0 } },
  ['01490']: {
    label: 'Raising of other animals',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['01500']: {
    label: 'Mixed farming',
    BEA_CODES: { '112A00': 0.6953819760520972, '111900': 0.3046180239479028 },
  },
  ['01610']: {
    label: 'Support activities for crop production',
    BEA_CODES: { '115000': 1.0 },
  },
  ['01621']: {
    label: 'Farm animal boarding and care',
    BEA_CODES: { '115000': 1.0 },
  },
  ['01629']: {
    label:
      'Support activities for animal production (other than farm animal boarding and care) n.e.c.',
    BEA_CODES: { '115000': 1.0 },
  },
  ['01630']: {
    label: 'Post-harvest crop activities',
    BEA_CODES: { '115000': 1.0 },
  },
  ['01640']: {
    label: 'Seed processing for propagation',
    BEA_CODES: { '115000': 1.0 },
  },
  ['01700']: {
    label: 'Hunting, trapping and related service activities',
    BEA_CODES: { '114000': 1.0 },
  },
  ['02100']: {
    label: 'Silviculture and other forestry activities',
    BEA_CODES: { '113000': 1.0 },
  },
  ['02200']: { label: 'Logging', BEA_CODES: { '113000': 1.0 } },
  ['02300']: {
    label: 'Gathering of wild growing non-wood products',
    BEA_CODES: { '113000': 1.0 },
  },
  ['02400']: {
    label: 'Support services to forestry',
    BEA_CODES: { '115000': 1.0 },
  },
  ['03110']: { label: 'Marine fishing', BEA_CODES: { '114000': 1.0 } },
  ['03120']: { label: 'Freshwater fishing', BEA_CODES: { '114000': 1.0 } },
  ['03210']: { label: 'Marine aquaculture', BEA_CODES: { '112A00': 1.0 } },
  ['03220']: { label: 'Freshwater aquaculture', BEA_CODES: { '112A00': 1.0 } },
  ['05101']: { label: 'Deep coal mines', BEA_CODES: { '212100': 1.0 } },
  ['05102']: { label: 'Open cast coal working', BEA_CODES: { '212100': 1.0 } },
  ['05200']: { label: 'Mining of lignite', BEA_CODES: { '212100': 1.0 } },
  ['06100']: {
    label: 'Extraction of crude petroleum',
    BEA_CODES: { '211000': 1.0 },
  },
  ['06200']: {
    label: 'Extraction of natural gas',
    BEA_CODES: { '211000': 1.0 },
  },
  ['07100']: { label: 'Mining of iron ores', BEA_CODES: { '2122A0': 1.0 } },
  ['07210']: {
    label: 'Mining of uranium and thorium ores',
    BEA_CODES: { '2122A0': 1.0 },
  },
  ['07290']: {
    label: 'Mining of other non-ferrous metal ores',
    BEA_CODES: { '2122A0': 1.0 },
  },
  ['08110']: {
    label:
      'Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
    BEA_CODES: { '212310': 1.0 },
  },
  ['08120']: {
    label: 'Operation of gravel and sand pits; mining of clays and kaolin',
    BEA_CODES: { '2123A0': 1.0 },
  },
  ['08910']: {
    label: 'Mining of chemical and fertilizer minerals',
    BEA_CODES: { '2123A0': 1.0 },
  },
  ['08920']: { label: 'Extraction of peat', BEA_CODES: { '2123A0': 1.0 } },
  ['08930']: { label: 'Extraction of salt', BEA_CODES: { '2123A0': 1.0 } },
  ['08990']: {
    label: 'Other mining and quarrying n.e.c.',
    BEA_CODES: { '2123A0': 1.0 },
  },
  ['09100']: {
    label: 'Support activities for petroleum and natural gas extraction',
    BEA_CODES: { '21311A': 1.0 },
  },
  ['09900']: {
    label: 'Support activities for other mining and quarrying',
    BEA_CODES: { '21311A': 1.0 },
  },
  ['10110']: {
    label: 'Processing and preserving of meat',
    BEA_CODES: { '31161A': 1.0 },
  },
  ['10120']: {
    label: 'Processing and preserving of poultry meat',
    BEA_CODES: { '311615': 1.0 },
  },
  ['10130']: {
    label: 'Production of meat and poultry meat products',
    BEA_CODES: { '31161A': 0.7083969465648855, '311615': 0.2916030534351145 },
  },
  ['10200']: {
    label: 'Processing and preserving of fish, crustaceans and molluscs',
    BEA_CODES: { '311700': 1.0 },
  },
  ['10310']: {
    label: 'Processing and preserving of potatoes',
    BEA_CODES: {
      '311410': 0.30093148293273286,
      '311420': 0.3785451407144345,
      '311910': 0.3205233763528326,
    },
  },
  ['10320']: {
    label: 'Manufacture of fruit and vegetable juice',
    BEA_CODES: { '311420': 1.0 },
  },
  ['10390']: {
    label: 'Other processing and preserving of fruit and vegetables',
    BEA_CODES: { '311420': 1.0 },
  },
  ['10410']: {
    label: 'Manufacture of oils and fats',
    BEA_CODES: { '311225': 0.3385238410952031, '311224': 0.6614761589047969 },
  },
  ['10420']: {
    label: 'Manufacture of margarine and similar edible fats',
    BEA_CODES: { '311225': 1.0 },
  },
  ['10511']: {
    label: 'Liquid milk and cream production',
    BEA_CODES: { '31151A': 1.0 },
  },
  ['10512']: {
    label: 'Butter and cheese production',
    BEA_CODES: { '311513': 0.49878027647066664, '31151A': 0.5012197235293333 },
  },
  ['10519']: {
    label: 'Manufacture of other milk products',
    BEA_CODES: { '311514': 1.0 },
  },
  ['10520']: {
    label: 'Manufacture of ice cream',
    BEA_CODES: { '311520': 1.0 },
  },
  ['10611']: { label: 'Grain milling', BEA_CODES: { '311210': 1.0 } },
  ['10612']: {
    label: 'Manufacture of breakfast cereals and cereals-based food',
    BEA_CODES: { '311230': 1.0 },
  },
  ['10620']: {
    label: 'Manufacture of starches and starch products',
    BEA_CODES: { '311221': 1.0 },
  },
  ['10710']: {
    label: 'Manufacture of bread; manufacture of fresh pastry goods and cakes',
    BEA_CODES: { '311810': 1.0 },
  },
  ['10720']: {
    label:
      'Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes',
    BEA_CODES: { '3118A0': 0.3626059598554687, '311810': 0.6373940401445313 },
  },
  ['10730']: {
    label:
      'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
    BEA_CODES: { '3118A0': 1.0 },
  },
  ['10810']: { label: 'Manufacture of sugar', BEA_CODES: { '311300': 1.0 } },
  ['10821']: {
    label: 'Manufacture of cocoa and chocolate confectionery',
    BEA_CODES: { '311300': 1.0 },
  },
  ['10822']: {
    label: 'Manufacture of sugar confectionery',
    BEA_CODES: { '311300': 1.0 },
  },
  ['10831']: { label: 'Tea processing', BEA_CODES: { '311920': 1.0 } },
  ['10832']: {
    label: 'Production of coffee and coffee substitutes',
    BEA_CODES: { '311920': 1.0 },
  },
  ['10840']: {
    label: 'Manufacture of condiments and seasonings',
    BEA_CODES: { '311940': 0.4540697810835543, '311990': 0.5459302189164457 },
  },
  ['10850']: {
    label: 'Manufacture of prepared meals and dishes',
    BEA_CODES: { '311410': 0.565938473592896, '311990': 0.4340615264071041 },
  },
  ['10860']: {
    label: 'Manufacture of homogenized food preparations and dietetic food',
    BEA_CODES: { '311990': 1.0 },
  },
  ['10890']: {
    label: 'Manufacture of other food products n.e.c.',
    BEA_CODES: { '311990': 1.0 },
  },
  ['10910']: {
    label: 'Manufacture of prepared feeds for farm animals',
    BEA_CODES: { '311119': 1.0 },
  },
  ['10920']: {
    label: 'Manufacture of prepared pet foods',
    BEA_CODES: { '311111': 1.0 },
  },
  ['11010']: {
    label: 'Distilling, rectifying and blending of spirits',
    BEA_CODES: { '312140': 1.0 },
  },
  ['11020']: {
    label: 'Manufacture of wine from grape',
    BEA_CODES: { '312130': 1.0 },
  },
  ['11030']: {
    label: 'Manufacture of cider and other fruit wines',
    BEA_CODES: { '312130': 1.0 },
  },
  ['11040']: {
    label: 'Manufacture of other non-distilled fermented beverages',
    BEA_CODES: { '312130': 1.0 },
  },
  ['11050']: { label: 'Manufacture of beer', BEA_CODES: { '312120': 1.0 } },
  ['11060']: { label: 'Manufacture of malt', BEA_CODES: { '311210': 1.0 } },
  ['11070']: {
    label:
      'Manufacture of soft drinks; production of mineral waters and other bottled waters',
    BEA_CODES: { '312110': 1.0 },
  },
  ['12000']: {
    label: 'Manufacture of tobacco products',
    BEA_CODES: { '312200': 1.0 },
  },
  ['13100']: {
    label: 'Preparation and spinning of textile fibres',
    BEA_CODES: { '313100': 1.0 },
  },
  ['13200']: { label: 'Weaving of textiles', BEA_CODES: { '313200': 1.0 } },
  ['13300']: { label: 'Finishing of textiles', BEA_CODES: { '313300': 1.0 } },
  ['13910']: {
    label: 'Manufacture of knitted and crocheted fabrics',
    BEA_CODES: { '313200': 1.0 },
  },
  ['13921']: {
    label: 'Manufacture of soft furnishings',
    BEA_CODES: { '314120': 0.2627423334508283, '314900': 0.7372576665491717 },
  },
  ['13922']: {
    label: 'manufacture of canvas goods, sacks, etc.',
    BEA_CODES: { '314900': 1.0 },
  },
  ['13923']: {
    label: 'manufacture of household textiles',
    BEA_CODES: { '314120': 0.2627423334508283, '314900': 0.7372576665491717 },
  },
  ['13931']: {
    label: 'Manufacture of woven or tufted carpets and rugs',
    BEA_CODES: { '314110': 1.0 },
  },
  ['13939']: {
    label: 'Manufacture of other carpets and rugs',
    BEA_CODES: { '314110': 1.0 },
  },
  ['13940']: {
    label: 'Manufacture of cordage, rope, twine and netting',
    BEA_CODES: { '314900': 1.0 },
  },
  ['13950']: {
    label:
      'Manufacture of non-wovens and articles made from non-wovens, except apparel',
    BEA_CODES: { '314900': 1.0 },
  },
  ['13960']: {
    label: 'Manufacture of other technical and industrial textiles',
    BEA_CODES: { '314900': 0.4697058163036155, '313200': 0.5302941836963845 },
  },
  ['13990']: {
    label: 'Manufacture of other textiles n.e.c.',
    BEA_CODES: { '314900': 0.4697058163036155, '313200': 0.5302941836963845 },
  },
  ['14110']: {
    label: 'Manufacture of leather clothes',
    BEA_CODES: { '316000': 0.34801784446922845, '315000': 0.6519821555307715 },
  },
  ['14120']: { label: 'Manufacture of workwear', BEA_CODES: { '315000': 1.0 } },
  ['14131']: {
    label: "Manufacture of other men's outerwear",
    BEA_CODES: { '315000': 1.0 },
  },
  ['14132']: {
    label: "Manufacture of other women's outerwear",
    BEA_CODES: { '315000': 1.0 },
  },
  ['14141']: {
    label: "Manufacture of men's underwear",
    BEA_CODES: { '315000': 1.0 },
  },
  ['14142']: {
    label: "Manufacture of women's underwear",
    BEA_CODES: { '315000': 1.0 },
  },
  ['14190']: {
    label: 'Manufacture of other wearing apparel and accessories n.e.c.',
    BEA_CODES: { '315000': 1.0 },
  },
  ['14200']: {
    label: 'Manufacture of articles of fur',
    BEA_CODES: { '315000': 1.0 },
  },
  ['14310']: {
    label: 'Manufacture of knitted and crocheted hosiery',
    BEA_CODES: { '315000': 1.0 },
  },
  ['14390']: {
    label: 'Manufacture of other knitted and crocheted apparel',
    BEA_CODES: { '315000': 1.0 },
  },
  ['15110']: {
    label: 'Tanning and dressing of leather; dressing and dyeing of fur',
    BEA_CODES: { '316000': 1.0 },
  },
  ['15120']: {
    label:
      'Manufacture of luggage, handbags and the like, saddlery and harness',
    BEA_CODES: { '316000': 1.0 },
  },
  ['15200']: { label: 'Manufacture of footwear', BEA_CODES: { '316000': 1.0 } },
  ['16100']: {
    label: 'Sawmilling and planing of wood',
    BEA_CODES: { '321100': 1.0 },
  },
  ['16210']: {
    label: 'Manufacture of veneer sheets and wood-based panels',
    BEA_CODES: { '321200': 1.0 },
  },
  ['16220']: {
    label: 'Manufacture of assembled parquet floors',
    BEA_CODES: { '321910': 0.47701308019206357, '3219A0': 0.5229869198079364 },
  },
  ['16230']: {
    label: "Manufacture of other builders' carpentry and joinery",
    BEA_CODES: { '321910': 0.47701308019206357, '3219A0': 0.5229869198079364 },
  },
  ['16240']: {
    label: 'Manufacture of wooden containers',
    BEA_CODES: { '3219A0': 1.0 },
  },
  ['16290']: {
    label:
      'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
    BEA_CODES: { '3219A0': 1.0 },
  },
  ['17110']: { label: 'Manufacture of pulp', BEA_CODES: { '322110': 1.0 } },
  ['17120']: {
    label: 'Manufacture of paper and paperboard',
    BEA_CODES: { '322130': 0.3694213675326562, '322120': 0.6305786324673438 },
  },
  ['17211']: {
    label: 'Manufacture of corrugated paper and paperboard, sacks and bags',
    BEA_CODES: { '322210': 0.7283523497514686, '322220': 0.2716476502485314 },
  },
  ['17219']: {
    label: 'Manufacture of other paper and paperboard containers',
    BEA_CODES: { '322210': 0.9094579821560814, '322299': 0.0905420178439186 },
  },
  ['17220']: {
    label:
      'Manufacture of household and sanitary goods and of toilet requisites',
    BEA_CODES: { '322291': 0.6690726300186892, '322299': 0.33092736998131084 },
  },
  ['17230']: {
    label: 'Manufacture of paper stationery',
    BEA_CODES: { '322230': 1.0 },
  },
  ['17240']: {
    label: 'Manufacture of wallpaper',
    BEA_CODES: { '322230': 0.27107726118752606, '322220': 0.7289227388124739 },
  },
  ['17290']: {
    label: 'Manufacture of other articles of paper and paperboard n.e.c.',
    BEA_CODES: { '322299': 1.0 },
  },
  ['18110']: { label: 'Printing of newspapers', BEA_CODES: { '323110': 1.0 } },
  ['18121']: {
    label: 'Manufacture of printed labels',
    BEA_CODES: { '323110': 1.0 },
  },
  ['18129']: { label: 'Printing n.e.c.', BEA_CODES: { '323110': 1.0 } },
  ['18130']: {
    label: 'Pre-press and pre-media services',
    BEA_CODES: { '323120': 1.0 },
  },
  ['18140']: {
    label: 'Binding and related services',
    BEA_CODES: { '323120': 1.0 },
  },
  ['18201']: {
    label: 'Reproduction of sound recording',
    BEA_CODES: { '334610': 1.0 },
  },
  ['18202']: {
    label: 'Reproduction of video recording',
    BEA_CODES: { '334610': 1.0 },
  },
  ['18203']: {
    label: 'Reproduction of computer media',
    BEA_CODES: { '334610': 1.0 },
  },
  ['19100']: {
    label: 'Manufacture of coke oven products',
    BEA_CODES: { '324190': 1.0 },
  },
  ['19201']: { label: 'Mineral oil refining', BEA_CODES: { '324110': 1.0 } },
  ['19209']: {
    label:
      'Other treatment of petroleum products (excluding petrochemicals manufacture)',
    BEA_CODES: { '324190': 1.0 },
  },
  ['20110']: {
    label: 'Manufacture of industrial gases',
    BEA_CODES: { '325120': 1.0 },
  },
  ['20120']: {
    label: 'Manufacture of dyes and pigments',
    BEA_CODES: { '325130': 1.0 },
  },
  ['20130']: {
    label: 'Manufacture of other inorganic basic chemicals',
    BEA_CODES: { '325180': 1.0 },
  },
  ['20140']: {
    label: 'Manufacture of other organic basic chemicals',
    BEA_CODES: { '325190': 1.0 },
  },
  ['20150']: {
    label: 'Manufacture of fertilizers and nitrogen compounds',
    BEA_CODES: { '325310': 1.0 },
  },
  ['20160']: {
    label: 'Manufacture of plastics in primary forms',
    BEA_CODES: { '325211': 1.0 },
  },
  ['20170']: {
    label: 'Manufacture of synthetic rubber in primary forms',
    BEA_CODES: { '3252A0': 1.0 },
  },
  ['20200']: {
    label: 'Manufacture of pesticides and other agrochemical products',
    BEA_CODES: { '325320': 1.0 },
  },
  ['20301']: {
    label:
      'Manufacture of paints, varnishes and similar coatings, mastics and sealants',
    BEA_CODES: { '325510': 1.0 },
  },
  ['20302']: {
    label: 'Manufacture of printing ink',
    BEA_CODES: { '325910': 1.0 },
  },
  ['20411']: {
    label: 'Manufacture of soap and detergents',
    BEA_CODES: { '325610': 1.0 },
  },
  ['20412']: {
    label: 'Manufacture of cleaning and polishing preparations',
    BEA_CODES: { '325610': 1.0 },
  },
  ['20420']: {
    label: 'Manufacture of perfumes and toilet preparations',
    BEA_CODES: { '325620': 1.0 },
  },
  ['20510']: {
    label: 'Manufacture of explosives',
    BEA_CODES: { '33299A': 0.26050709396152816, '3259A0': 0.7394929060384718 },
  },
  ['20520']: { label: 'Manufacture of glues', BEA_CODES: { '325520': 1.0 } },
  ['20530']: {
    label: 'Manufacture of essential oils',
    BEA_CODES: { '3259A0': 1.0 },
  },
  ['20590']: {
    label: 'Manufacture of other chemical products n.e.c.',
    BEA_CODES: { '3259A0': 1.0 },
  },
  ['20600']: {
    label: 'Manufacture of man-made fibres',
    BEA_CODES: { '3252A0': 1.0 },
  },
  ['21100']: {
    label: 'Manufacture of basic pharmaceutical products',
    BEA_CODES: { '325411': 0.0918962881520585, '325412': 0.9081037118479415 },
  },
  ['21200']: {
    label: 'Manufacture of pharmaceutical preparations',
    BEA_CODES: { '325412': 0.9081037118479415, '325411': 0.0918962881520585 },
  },
  ['22110']: {
    label:
      'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
    BEA_CODES: { '326210': 1.0 },
  },
  ['22190']: {
    label: 'Manufacture of other rubber products',
    BEA_CODES: { '326290': 1.0 },
  },
  ['22210']: {
    label: 'Manufacture of plastic plates, sheets, tubes and profiles',
    BEA_CODES: { '326120': 0.27364663890541346, '326110': 0.7263533610945866 },
  },
  ['22220']: {
    label: 'Manufacture of plastic packing goods',
    BEA_CODES: { '326110': 0.3255316054808687, '326190': 0.6744683945191313 },
  },
  ['22230']: {
    label: 'Manufacture of builders  ware of plastic',
    BEA_CODES: { '326190': 0.8461429309873002, '326120': 0.1538570690126998 },
  },
  ['22290']: {
    label: 'Manufacture of other plastic products',
    BEA_CODES: { '326190': 1.0 },
  },
  ['23110']: {
    label: 'Manufacture of flat glass',
    BEA_CODES: { '327200': 1.0 },
  },
  ['23120']: {
    label: 'Shaping and processing of flat glass',
    BEA_CODES: { '327200': 1.0 },
  },
  ['23130']: {
    label: 'Manufacture of hollow glass',
    BEA_CODES: { '327200': 1.0 },
  },
  ['23140']: {
    label: 'Manufacture of glass fibres',
    BEA_CODES: { '327200': 1.0 },
  },
  ['23190']: {
    label:
      'Manufacture and processing of other glass, including technical glassware',
    BEA_CODES: { '327200': 1.0 },
  },
  ['23200']: {
    label: 'Manufacture of refractory products',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23310']: {
    label: 'Manufacture of ceramic tiles and flags',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23320']: {
    label:
      'Manufacture of bricks, tiles and construction products, in baked clay',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23410']: {
    label: 'Manufacture of ceramic household and ornamental articles',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23420']: {
    label: 'Manufacture of ceramic sanitary fixtures',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23430']: {
    label: 'Manufacture of ceramic insulators and insulating fittings',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23440']: {
    label: 'Manufacture of other technical ceramic products',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23490']: {
    label: 'Manufacture of other ceramic products n.e.c.',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23510']: { label: 'Manufacture of cement', BEA_CODES: { '327310': 1.0 } },
  ['23520']: {
    label: 'Manufacture of lime and plaster',
    BEA_CODES: { '327400': 1.0 },
  },
  ['23610']: {
    label: 'Manufacture of concrete products for construction purposes',
    BEA_CODES: { '327390': 0.6116363636363636, '327330': 0.38836363636363636 },
  },
  ['23620']: {
    label: 'Manufacture of plaster products for construction purposes',
    BEA_CODES: { '327400': 0.5992702949224689, '327999': 0.40072970507753114 },
  },
  ['23630']: {
    label: 'Manufacture of ready-mixed concrete',
    BEA_CODES: { '327320': 1.0 },
  },
  ['23640']: {
    label: 'Manufacture of mortars',
    BEA_CODES: { '327390': 0.6802005823358136, '327999': 0.31979941766418635 },
  },
  ['23650']: {
    label: 'Manufacture of fibre cement',
    BEA_CODES: { '327999': 0.31979941766418635, '327390': 0.6802005823358136 },
  },
  ['23690']: {
    label: 'Manufacture of other articles of concrete, plaster and cement',
    BEA_CODES: { '327390': 1.0 },
  },
  ['23700']: {
    label: 'Cutting, shaping and finishing of stone',
    BEA_CODES: { '327991': 1.0 },
  },
  ['23910']: {
    label: 'Production of abrasive products',
    BEA_CODES: { '327910': 1.0 },
  },
  ['23990']: {
    label: 'Manufacture of other non-metallic mineral products n.e.c.',
    BEA_CODES: { '327999': 1.0 },
  },
  ['24100']: {
    label: 'Manufacture of basic iron and steel and of ferro-alloys',
    BEA_CODES: { '331110': 1.0 },
  },
  ['24200']: {
    label:
      'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
    BEA_CODES: {
      '332996': 0.05607379624375458,
      '331200': 0.0658934577110985,
      '331110': 0.8780327460451469,
    },
  },
  ['24310']: { label: 'Cold drawing of bars', BEA_CODES: { '331200': 1.0 } },
  ['24320']: {
    label: 'Cold rolling of narrow strip',
    BEA_CODES: { '331200': 1.0 },
  },
  ['24330']: { label: 'Cold forming or folding', BEA_CODES: { '331200': 1.0 } },
  ['24340']: { label: 'Cold drawing of wire', BEA_CODES: { '331200': 1.0 } },
  ['24410']: {
    label: 'Precious metals production',
    BEA_CODES: { '331410': 0.6766479305058763, '331490': 0.32335206949412365 },
  },
  ['24420']: { label: 'Aluminium production', BEA_CODES: { '331313': 1.0 } },
  ['24430']: {
    label: 'Lead, zinc and tin production',
    BEA_CODES: { '331490': 0.32335206949412365, '331410': 0.6766479305058763 },
  },
  ['24440']: { label: 'Copper production', BEA_CODES: { '331420': 1.0 } },
  ['24450']: {
    label: 'Other non-ferrous metal production',
    BEA_CODES: { '331490': 0.32335206949412365, '331410': 0.6766479305058763 },
  },
  ['24460']: {
    label: 'Processing of nuclear fuel',
    BEA_CODES: { '331410': 1.0 },
  },
  ['24510']: { label: 'Casting of iron', BEA_CODES: { '331510': 1.0 } },
  ['24520']: { label: 'Casting of steel', BEA_CODES: { '331510': 1.0 } },
  ['24530']: { label: 'Casting of light metals', BEA_CODES: { '331520': 1.0 } },
  ['24540']: {
    label: 'Casting of other non-ferrous metals',
    BEA_CODES: { '331520': 1.0 },
  },
  ['25110']: {
    label: 'Manufacture of metal structures and parts of structures',
    BEA_CODES: { '332310': 1.0 },
  },
  ['25120']: {
    label: 'Manufacture of doors and windows of metal',
    BEA_CODES: { '332320': 0.4815716415256903, '332310': 0.5184283584743097 },
  },
  ['25210']: {
    label: 'Manufacture of central heating radiators and boilers',
    BEA_CODES: { '333414': 1.0 },
  },
  ['25290']: {
    label: 'Manufacture of other tanks, reservoirs and containers of metal',
    BEA_CODES: { '332420': 1.0 },
  },
  ['25300']: {
    label:
      'Manufacture of steam generators, except central heating hot water boilers',
    BEA_CODES: { '332410': 1.0 },
  },
  ['25400']: {
    label: 'Manufacture of weapons and ammunition',
    BEA_CODES: { '33299A': 1.0 },
  },
  ['25500']: {
    label:
      'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
    BEA_CODES: { '33211A': 0.6489084461913103, '332114': 0.3510915538086897 },
  },
  ['25610']: {
    label: 'Treatment and coating of metals',
    BEA_CODES: { '332800': 1.0 },
  },
  ['25620']: { label: 'Machining', BEA_CODES: { '332710': 1.0 } },
  ['25710']: { label: 'Manufacture of cutlery', BEA_CODES: { '332200': 1.0 } },
  ['25720']: {
    label: 'Manufacture of locks and hinges',
    BEA_CODES: { '332500': 1.0 },
  },
  ['25730']: {
    label: 'Manufacture of tools',
    BEA_CODES: { '332200': 0.5223109171308792, '333514': 0.47768908286912076 },
  },
  ['25910']: {
    label: 'Manufacture of steel drums and similar containers',
    BEA_CODES: { '332430': 0.5568119139547711, '332999': 0.4431880860452289 },
  },
  ['25920']: {
    label: 'Manufacture of light metal packaging',
    BEA_CODES: { '332430': 1.0 },
  },
  ['25930']: {
    label: 'Manufacture of wire products, chain and springs',
    BEA_CODES: { '332999': 0.2911466301634592, '332320': 0.7088533698365408 },
  },
  ['25940']: {
    label: 'Manufacture of fasteners and screw machine products',
    BEA_CODES: { '332720': 1.0 },
  },
  ['25990']: {
    label: 'Manufacture of other fabricated metal products n.e.c.',
    BEA_CODES: {
      '332999': 0.2131708107948767,
      '332320': 0.5190059987913983,
      '332430': 0.267823190413725,
    },
  },
  ['26110']: {
    label: 'Manufacture of electronic components',
    BEA_CODES: { '334413': 0.6557750865533015, '33441A': 0.3442249134466985 },
  },
  ['26120']: {
    label: 'Manufacture of loaded electronic boards',
    BEA_CODES: { '334418': 1.0 },
  },
  ['26200']: {
    label: 'Manufacture of computers and peripheral equipment',
    BEA_CODES: { '334118': 0.5805961070559611, '334111': 0.4194038929440389 },
  },
  ['26301']: {
    label: 'Manufacture of telegraph and telephone apparatus and equipment',
    BEA_CODES: { '334210': 1.0 },
  },
  ['26309']: {
    label:
      'Manufacture of communication equipment other than telegraph, and telephone apparatus and equipment',
    BEA_CODES: { '334220': 0.8580383923215357, '334290': 0.1419616076784643 },
  },
  ['26400']: {
    label: 'Manufacture of consumer electronics',
    BEA_CODES: { '334300': 0.6192389006342495, '339930': 0.38076109936575053 },
  },
  ['26511']: {
    label:
      'Manufacture of electronic measuring, testing etc. equipment, not for industrial process control',
    BEA_CODES: { '334516': 0.5238442355649339, '334515': 0.4761557644350662 },
  },
  ['26512']: {
    label: 'Manufacture of electronic industrial process control equipment',
    BEA_CODES: { '334513': 1.0 },
  },
  ['26513']: {
    label:
      'Manufacture of non-electronic measuring, testing etc. equipment, not for industrial process control',
    BEA_CODES: {
      '334516': 0.3625982579137455,
      '334515': 0.3295889101338432,
      '33451A': 0.3078128319524113,
    },
  },
  ['26514']: {
    label: 'Manufacture of non-electronic industrial process control equipment',
    BEA_CODES: { '334513': 0.474307224817452, '33451A': 0.525692775182548 },
  },
  ['26520']: {
    label: 'Manufacture of watches and clocks',
    BEA_CODES: { '33451A': 1.0 },
  },
  ['26600']: {
    label:
      'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
    BEA_CODES: { '334510': 0.762622643589461, '334517': 0.2373773564105391 },
  },
  ['26701']: {
    label: 'Manufacture of optical precision instruments',
    BEA_CODES: { '333314': 1.0 },
  },
  ['26702']: {
    label: 'Manufacture of photographic and cinematographic equipment',
    BEA_CODES: { '333316': 1.0 },
  },
  ['26800']: {
    label: 'Manufacture of magnetic and optical media',
    BEA_CODES: { '334610': 1.0 },
  },
  ['27110']: {
    label: 'Manufacture of electric motors, generators and transformers',
    BEA_CODES: { '335311': 0.3438343834383438, '335312': 0.6561656165616562 },
  },
  ['27120']: {
    label: 'Manufacture of electricity distribution and control apparatus',
    BEA_CODES: { '335313': 1.0 },
  },
  ['27200']: {
    label: 'Manufacture of batteries and accumulators',
    BEA_CODES: { '335911': 0.5874531835205993, '335912': 0.4125468164794007 },
  },
  ['27310']: {
    label: 'Manufacture of fibre optic cables',
    BEA_CODES: { '335920': 1.0 },
  },
  ['27320']: {
    label: 'Manufacture of other electronic and electric wires and cables',
    BEA_CODES: { '335920': 1.0 },
  },
  ['27330']: {
    label: 'Manufacture of wiring devices',
    BEA_CODES: { '335930': 1.0 },
  },
  ['27400']: {
    label: 'Manufacture of electric lighting equipment',
    BEA_CODES: { '335120': 1.0 },
  },
  ['27510']: {
    label: 'Manufacture of electric domestic appliances',
    BEA_CODES: {
      '335224': 0.22662818421382858,
      '335221': 0.276571715397164,
      '335222': 0.22004015560296147,
      '335228': 0.27675994478604593,
    },
  },
  ['27520']: {
    label: 'Manufacture of non-electric domestic appliances',
    BEA_CODES: { '335221': 1.0 },
  },
  ['27900']: {
    label: 'Manufacture of other electrical equipment',
    BEA_CODES: { '335999': 1.0 },
  },
  ['28110']: {
    label:
      'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
    BEA_CODES: { '333611': 0.38491569779913337, '333618': 0.6150843022008666 },
  },
  ['28120']: {
    label: 'Manufacture of fluid power equipment',
    BEA_CODES: { '33399B': 1.0 },
  },
  ['28131']: { label: 'Manufacture of pumps', BEA_CODES: { '33391A': 1.0 } },
  ['28132']: {
    label: 'Manufacture of compressors',
    BEA_CODES: { '33391A': 0.34918457214932175, '333415': 0.6508154278506783 },
  },
  ['28140']: {
    label: 'Manufacture of taps and valves',
    BEA_CODES: { '33291A': 1.0 },
  },
  ['28150']: {
    label: 'Manufacture of bearings, gears, gearing and driving elements',
    BEA_CODES: {
      '332991': 0.4342458743763592,
      '333612': 0.2895612127414609,
      '333613': 0.27619291288217984,
    },
  },
  ['28210']: {
    label: 'Manufacture of ovens, furnaces and furnace burners',
    BEA_CODES: { '333994': 1.0 },
  },
  ['28220']: {
    label: 'Manufacture of lifting and handling equipment',
    BEA_CODES: { '333920': 0.5886050447256024, '33399A': 0.4113949552743976 },
  },
  ['28230']: {
    label:
      'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
    BEA_CODES: { '333318': 0.8484789008832189, '339940': 0.15152109911678116 },
  },
  ['28240']: {
    label: 'Manufacture of power-driven hand tools',
    BEA_CODES: { '333991': 1.0 },
  },
  ['28250']: {
    label: 'Manufacture of non-domestic cooling and ventilation equipment',
    BEA_CODES: { '333415': 1.0 },
  },
  ['28290']: {
    label: 'Manufacture of other general-purpose machinery n.e.c.',
    BEA_CODES: { '33399A': 0.49386896268169395, '33329A': 0.506131037318306 },
  },
  ['28301']: {
    label: 'Manufacture of agricultural tractors',
    BEA_CODES: { '333111': 1.0 },
  },
  ['28302']: {
    label:
      'Manufacture of agricultural and forestry machinery other than tractors',
    BEA_CODES: { '333111': 0.6210524409676952, '33329A': 0.3789475590323048 },
  },
  ['28410']: {
    label: 'Manufacture of metal forming machinery',
    BEA_CODES: { '33351B': 0.5258941525263358, '333517': 0.4741058474736643 },
  },
  ['28490']: {
    label: 'Manufacture of other machine tools',
    BEA_CODES: { '333517': 0.4741058474736643, '33351B': 0.5258941525263358 },
  },
  ['28910']: {
    label: 'Manufacture of machinery for metallurgy',
    BEA_CODES: { '33329A': 0.7151107162383816, '33351B': 0.2848892837616184 },
  },
  ['28921']: {
    label: 'Manufacture of machinery for mining',
    BEA_CODES: { '333130': 1.0 },
  },
  ['28922']: {
    label: 'Manufacture of earthmoving equipment',
    BEA_CODES: { '333120': 1.0 },
  },
  ['28923']: {
    label:
      'Manufacture of equipment for concrete crushing and screening and roadworks',
    BEA_CODES: { '333120': 1.0 },
  },
  ['28930']: {
    label: 'Manufacture of machinery for food, beverage and tobacco processing',
    BEA_CODES: { '33329A': 1.0 },
  },
  ['28940']: {
    label:
      'Manufacture of machinery for textile, apparel and leather production',
    BEA_CODES: { '33329A': 0.5475548810800911, '333318': 0.45244511891990896 },
  },
  ['28950']: {
    label: 'Manufacture of machinery for paper and paperboard production',
    BEA_CODES: { '33329A': 1.0 },
  },
  ['28960']: {
    label: 'Manufacture of plastics and rubber machinery',
    BEA_CODES: { '33329A': 1.0 },
  },
  ['28990']: {
    label: 'Manufacture of other special-purpose machinery n.e.c.',
    BEA_CODES: { '33329A': 0.5475548810800911, '333318': 0.45244511891990896 },
  },
  ['29100']: {
    label: 'Manufacture of motor vehicles',
    BEA_CODES: {
      '336112': 0.6592349089366666,
      '336111': 0.21004972599575075,
      '336120': 0.13071536506758266,
    },
  },
  ['29201']: {
    label:
      'Manufacture of bodies (coachwork) for motor vehicles (except caravans)',
    BEA_CODES: { '336211': 1.0 },
  },
  ['29202']: {
    label: 'Manufacture of trailers and semi-trailers',
    BEA_CODES: { '336212': 1.0 },
  },
  ['29203']: { label: 'Manufacture of caravans', BEA_CODES: { '336214': 1.0 } },
  ['29310']: {
    label:
      'Manufacture of electrical and electronic equipment for motor vehicles and their engines',
    BEA_CODES: { '336320': 0.25725558843551594, '336390': 0.7427444115644841 },
  },
  ['29320']: {
    label: 'Manufacture of other parts and accessories for motor vehicles',
    BEA_CODES: { '336390': 0.7060486001056524, '336360': 0.2939513998943476 },
  },
  ['30110']: {
    label: 'Building of ships and floating structures',
    BEA_CODES: { '336611': 1.0 },
  },
  ['30120']: {
    label: 'Building of pleasure and sporting boats',
    BEA_CODES: { '336612': 1.0 },
  },
  ['30200']: {
    label: 'Manufacture of railway locomotives and rolling stock',
    BEA_CODES: { '336500': 1.0 },
  },
  ['30300']: {
    label: 'Manufacture of air and spacecraft and related machinery',
    BEA_CODES: {
      '336412': 0.19697239536954586,
      '336413': 0.16880874641337687,
      '336414': 0.08866132383496586,
      '336411': 0.5193677649154052,
      '33641A': 0.026189769466706245,
    },
  },
  ['30400']: {
    label: 'Manufacture of military fighting vehicles',
    BEA_CODES: { '336992': 1.0 },
  },
  ['30910']: {
    label: 'Manufacture of motorcycles',
    BEA_CODES: { '336991': 1.0 },
  },
  ['30920']: {
    label: 'Manufacture of bicycles and invalid carriages',
    BEA_CODES: { '336991': 1.0 },
  },
  ['30990']: {
    label: 'Manufacture of other transport equipment n.e.c.',
    BEA_CODES: { '336999': 1.0 },
  },
  ['31010']: {
    label: 'Manufacture of office and shop furniture',
    BEA_CODES: { '33721A': 0.7027268663388466, '337215': 0.2972731336611533 },
  },
  ['31020']: {
    label: 'Manufacture of kitchen furniture',
    BEA_CODES: { '337110': 0.8090418852035163, '33712N': 0.19095811479648372 },
  },
  ['31030']: {
    label: 'Manufacture of mattresses',
    BEA_CODES: { '337900': 1.0 },
  },
  ['31090']: {
    label: 'Manufacture of other furniture',
    BEA_CODES: {
      '337900': 0.5725917499850326,
      '33712N': 0.15476261749386339,
      '337127': 0.272645632521104,
    },
  },
  ['32110']: { label: 'Striking of coins', BEA_CODES: { '339910': 1.0 } },
  ['32120']: {
    label: 'Manufacture of jewellery and related articles',
    BEA_CODES: { '339910': 1.0 },
  },
  ['32130']: {
    label: 'Manufacture of imitation jewellery and related articles',
    BEA_CODES: { '339910': 1.0 },
  },
  ['32200']: {
    label: 'Manufacture of musical instruments',
    BEA_CODES: { '339990': 1.0 },
  },
  ['32300']: {
    label: 'Manufacture of sports goods',
    BEA_CODES: { '339920': 1.0 },
  },
  ['32401']: {
    label: 'Manufacture of professional and arcade games and toys',
    BEA_CODES: { '339930': 1.0 },
  },
  ['32409']: {
    label: 'Manufacture of other games and toys, n.e.c.',
    BEA_CODES: { '339930': 1.0 },
  },
  ['32500']: {
    label: 'Manufacture of medical and dental instruments and supplies',
    BEA_CODES: {
      '339113': 0.43649371434824835,
      '339114': 0.05828762157503796,
      '339112': 0.5052186640767137,
    },
  },
  ['32910']: {
    label: 'Manufacture of brooms and brushes',
    BEA_CODES: { '339990': 0.8917933982759829, '339940': 0.1082066017240171 },
  },
  ['32990']: {
    label: 'Other manufacturing n.e.c.',
    BEA_CODES: { '339990': 0.8917933982759829, '339940': 0.1082066017240171 },
  },
  ['33110']: {
    label: 'Repair of fabricated metal products',
    BEA_CODES: { '811300': 1.0 },
  },
  ['33120']: { label: 'Repair of machinery', BEA_CODES: { '811300': 1.0 } },
  ['33130']: {
    label: 'Repair of electronic and optical equipment',
    BEA_CODES: { '811200': 1.0 },
  },
  ['33140']: {
    label: 'Repair of electrical equipment',
    BEA_CODES: { '811300': 1.0 },
  },
  ['33150']: {
    label: 'Repair and maintenance of ships and boats',
    BEA_CODES: { '336611': 1.0 },
  },
  ['33160']: {
    label: 'Repair and maintenance of aircraft and spacecraft',
    BEA_CODES: { '811300': 1.0 },
  },
  ['33170']: {
    label: 'Repair and maintenance of other transport equipment n.e.c.',
    BEA_CODES: { '811300': 1.0 },
  },
  ['33190']: {
    label: 'Repair of other equipment',
    BEA_CODES: { '811300': 1.0 },
  },
  ['33200']: {
    label: 'Installation of industrial machinery and equipment',
    BEA_CODES: { '230301': 0.7830316983413038, '233230': 0.21696830165869616 },
  },
  ['35110']: {
    label: 'Production of electricity',
    BEA_CODES: { '221100': 1.0 },
  },
  ['35120']: {
    label: 'Transmission of electricity',
    BEA_CODES: { '221100': 1.0 },
  },
  ['35130']: {
    label: 'Distribution of electricity',
    BEA_CODES: { '221100': 1.0 },
  },
  ['35140']: { label: 'Trade of electricity', BEA_CODES: { '221100': 1.0 } },
  ['35210']: { label: 'Manufacture of gas', BEA_CODES: { '221200': 1.0 } },
  ['35220']: {
    label: 'Distribution of gaseous fuels through mains',
    BEA_CODES: { '221200': 1.0 },
  },
  ['35230']: {
    label: 'Trade of gas through mains',
    BEA_CODES: { '221200': 1.0 },
  },
  ['35300']: {
    label: 'Steam and air conditioning supply',
    BEA_CODES: { '221300': 1.0 },
  },
  ['36000']: {
    label: 'Water collection, treatment and supply',
    BEA_CODES: { '221300': 1.0 },
  },
  ['37000']: { label: 'Sewerage', BEA_CODES: { '221300': 1.0 } },
  ['38110']: {
    label: 'Collection of non-hazardous waste',
    BEA_CODES: { '562111': 1.0 },
  },
  ['38120']: {
    label: 'Collection of hazardous waste',
    BEA_CODES: { '562111': 1.0 },
  },
  ['38210']: {
    label: 'Treatment and disposal of non-hazardous waste',
    BEA_CODES: { '325310': 1.0 },
  },
  ['38220']: {
    label: 'Treatment and disposal of hazardous waste',
    BEA_CODES: { '562111': 1.0 },
  },
  ['38310']: { label: 'Dismantling of wrecks', BEA_CODES: { '48A000': 1.0 } },
  ['38320']: {
    label: 'Recovery of sorted materials',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['39000']: {
    label: 'Remediation activities and other waste management services',
    BEA_CODES: { '562111': 1.0 },
  },
  ['41100']: {
    label: 'Development of building projects',
    BEA_CODES: { '2332A0': 0.7446285556476112, '233412': 0.25537144435238884 },
  },
  ['41201']: {
    label: 'Construction of commercial buildings',
    BEA_CODES: { '2332A0': 1.0 },
  },
  ['41202']: {
    label: 'Construction of domestic buildings',
    BEA_CODES: {
      '233411': 0.4033671810549813,
      '233412': 0.08413706507497452,
      '2334A0': 0.5124957538700442,
    },
  },
  ['42110']: {
    label: 'Construction of roads and motorways',
    BEA_CODES: { '2332C0': 1.0 },
  },
  ['42120']: {
    label: 'Construction of railways and underground railways',
    BEA_CODES: { '2332C0': 1.0 },
  },
  ['42130']: {
    label: 'Construction of bridges and tunnels',
    BEA_CODES: { '2332C0': 1.0 },
  },
  ['42210']: {
    label: 'Construction of utility projects for fluids',
    BEA_CODES: { '2332D0': 1.0 },
  },
  ['42220']: {
    label:
      'Construction of utility projects for electricity and telecommunications',
    BEA_CODES: { '233240': 1.0 },
  },
  ['42910']: {
    label: 'Construction of water projects',
    BEA_CODES: { '2332D0': 1.0 },
  },
  ['42990']: {
    label: 'Construction of other civil engineering projects n.e.c.',
    BEA_CODES: { '2332D0': 1.0 },
  },
  ['43110']: { label: 'Demolition', BEA_CODES: { '2332D0': 1.0 } },
  ['43120']: {
    label: 'Site preparation',
    BEA_CODES: { '2332D0': 0.3567303555997152, '2334A0': 0.6432696444002848 },
  },
  ['43130']: {
    label: 'Test drilling and boring',
    BEA_CODES: { '21311A': 1.0 },
  },
  ['43210']: { label: 'Electrical installation', BEA_CODES: { '233240': 1.0 } },
  ['43220']: {
    label: 'Plumbing, heat and air-conditioning installation',
    BEA_CODES: { '230301': 0.7065643723981937, '230302': 0.2934356276018063 },
  },
  ['43290']: {
    label: 'Other construction installation',
    BEA_CODES: {
      '230301': 0.5135620443488323,
      '230302': 0.2132819127073659,
      '2332D0': 0.2731560429438017,
    },
  },
  ['43310']: {
    label: 'Plastering',
    BEA_CODES: { '230301': 0.7065643723981937, '230302': 0.2934356276018063 },
  },
  ['43320']: {
    label: 'Joinery installation',
    BEA_CODES: { '230301': 0.7065643723981937, '230302': 0.2934356276018063 },
  },
  ['43330']: {
    label: 'Floor and wall covering',
    BEA_CODES: { '230301': 0.7065643723981937, '230302': 0.2934356276018063 },
  },
  ['43341']: {
    label: 'Painting',
    BEA_CODES: { '230301': 0.7065643723981937, '230302': 0.2934356276018063 },
  },
  ['43342']: {
    label: 'Glazing',
    BEA_CODES: { '230301': 0.7065643723981937, '230302': 0.2934356276018063 },
  },
  ['43390']: {
    label: 'Other building completion and finishing',
    BEA_CODES: { '230301': 0.7065643723981937, '230302': 0.2934356276018063 },
  },
  ['43910']: {
    label: 'Roofing activities',
    BEA_CODES: { '230301': 0.7065643723981937, '230302': 0.2934356276018063 },
  },
  ['43991']: {
    label: 'Scaffold erection',
    BEA_CODES: { '230301': 0.6527904374439578, '2332D0': 0.3472095625560422 },
  },
  ['43999']: {
    label: 'Other specialised construction activities n.e.c.',
    BEA_CODES: {
      '230301': 0.5135620443488323,
      '230302': 0.2132819127073659,
      '2332D0': 0.2731560429438017,
    },
  },
  ['45111']: {
    label: 'Sale of new cars and light motor vehicles',
    BEA_CODES: { '441000': 1.0 },
  },
  ['45112']: {
    label: 'Sale of used cars and light motor vehicles',
    BEA_CODES: { '441000': 1.0 },
  },
  ['45190']: {
    label: 'Sale of other motor vehicles',
    BEA_CODES: { '423100': 0.4027085400735509, '441000': 0.5972914599264492 },
  },
  ['45200']: {
    label: 'Maintenance and repair of motor vehicles',
    BEA_CODES: { '811100': 1.0 },
  },
  ['45310']: {
    label: 'Wholesale trade of motor vehicle parts and accessories',
    BEA_CODES: { '423100': 1.0 },
  },
  ['45320']: {
    label: 'Retail trade of motor vehicle parts and accessories',
    BEA_CODES: { '441000': 1.0 },
  },
  ['45400']: {
    label:
      'Sale, maintenance and repair of motorcycles and related parts and accessories',
    BEA_CODES: { '423100': 0.4027085400735509, '441000': 0.5972914599264492 },
  },
  ['46110']: {
    label:
      'Agents selling agricultural raw materials, livestock, textile raw materials and semi-finished goods',
    BEA_CODES: { '425000': 1.0 },
  },
  ['46120']: {
    label:
      'Agents involved in the sale of fuels, ores, metals and industrial chemicals',
    BEA_CODES: { '425000': 1.0 },
  },
  ['46130']: {
    label: 'Agents involved in the sale of timber and building materials',
    BEA_CODES: { '425000': 1.0 },
  },
  ['46140']: {
    label:
      'Agents involved in the sale of machinery, industrial equipment, ships and aircraft',
    BEA_CODES: { '425000': 1.0 },
  },
  ['46150']: {
    label:
      'Agents involved in the sale of furniture, household goods, hardware and ironmongery',
    BEA_CODES: { '425000': 1.0 },
  },
  ['46160']: {
    label:
      'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
    BEA_CODES: { '425000': 1.0 },
  },
  ['46170']: {
    label: 'Agents involved in the sale of food, beverages and tobacco',
    BEA_CODES: { '425000': 1.0 },
  },
  ['46180']: {
    label: 'Agents specialized in the sale of other particular products',
    BEA_CODES: { '425000': 1.0 },
  },
  ['46190']: {
    label: 'Agents involved in the sale of a variety of goods',
    BEA_CODES: { '425000': 1.0 },
  },
  ['46210']: {
    label: 'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
    BEA_CODES: { '424400': 0.30744763597977665, '424A00': 0.6925523640202234 },
  },
  ['46220']: {
    label: 'Wholesale of flowers and plants',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46230']: {
    label: 'Wholesale of live animals',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46240']: {
    label: 'Wholesale of hides, skins and leather',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46310']: {
    label: 'Wholesale of fruit and vegetables',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46320']: {
    label: 'Wholesale of meat and meat products',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46330']: {
    label: 'Wholesale of dairy products, eggs and edible oils and fats',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46341']: {
    label:
      'Wholesale of fruit and vegetable juices, mineral water and soft drinks',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46342']: {
    label: 'Wholesale of wine, beer, spirits and other alcoholic beverages',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46350']: {
    label: 'Wholesale of tobacco products',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46360']: {
    label: 'Wholesale of sugar and chocolate and sugar confectionery',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46370']: {
    label: 'Wholesale of coffee, tea, cocoa and spices',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46380']: {
    label: 'Wholesale of other food, including fish, crustaceans and molluscs',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46390']: {
    label: 'Non-specialised wholesale of food, beverages and tobacco',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46410']: { label: 'Wholesale of textiles', BEA_CODES: { '424A00': 1.0 } },
  ['46420']: {
    label: 'Wholesale of clothing and footwear',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46431']: {
    label:
      'Wholesale of audio tapes, records, CDs and video tapes and the equipment on which these are played',
    BEA_CODES: { '423600': 1.0 },
  },
  ['46439']: {
    label:
      "Wholesale of radio, television goods & electrical household appliances (other than records, tapes, CD's & video tapes and the equipment used for playing them)",
    BEA_CODES: { '423600': 1.0 },
  },
  ['46440']: {
    label: 'Wholesale of china and glassware and cleaning materials',
    BEA_CODES: { '424A00': 0.5609113595584196, '423A00': 0.4390886404415804 },
  },
  ['46450']: {
    label: 'Wholesale of perfume and cosmetics',
    BEA_CODES: { '424A00': 0.6870903286557497, '424200': 0.31290967134425024 },
  },
  ['46460']: {
    label: 'Wholesale of pharmaceutical goods',
    BEA_CODES: { '424200': 1.0 },
  },
  ['46470']: {
    label: 'Wholesale of furniture, carpets and lighting equipment',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['46480']: {
    label: 'Wholesale of watches and jewellery',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['46491']: {
    label: 'Wholesale of musical instruments',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['46499']: {
    label:
      'Wholesale of household goods (other than musical instruments) n.e.c.',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['46510']: {
    label: 'Wholesale of computers, computer peripheral equipment and software',
    BEA_CODES: { '423400': 1.0 },
  },
  ['46520']: {
    label: 'Wholesale of electronic and telecommunications equipment and parts',
    BEA_CODES: { '423600': 1.0 },
  },
  ['46610']: {
    label: 'Wholesale of agricultural machinery, equipment and supplies',
    BEA_CODES: { '423800': 1.0 },
  },
  ['46620']: {
    label: 'Wholesale of machine tools',
    BEA_CODES: { '423800': 1.0 },
  },
  ['46630']: {
    label: 'Wholesale of mining, construction and civil engineering machinery',
    BEA_CODES: { '423800': 1.0 },
  },
  ['46640']: {
    label:
      'Wholesale of machinery for the textile industry and of sewing and knitting machines',
    BEA_CODES: { '423800': 1.0 },
  },
  ['46650']: {
    label: 'Wholesale of office furniture',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['46660']: {
    label: 'Wholesale of other office machinery and equipment',
    BEA_CODES: { '423400': 0.5447757123376157, '423800': 0.45522428766238426 },
  },
  ['46690']: {
    label: 'Wholesale of other machinery and equipment',
    BEA_CODES: { '423800': 1.0 },
  },
  ['46711']: {
    label: 'Wholesale of petroleum and petroleum products',
    BEA_CODES: { '424700': 1.0 },
  },
  ['46719']: {
    label: 'Wholesale of other fuels and related products',
    BEA_CODES: { '424700': 0.34947932861768216, '424A00': 0.6505206713823178 },
  },
  ['46720']: {
    label: 'Wholesale of metals and metal ores',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['46730']: {
    label: 'Wholesale of wood, construction materials and sanitary equipment',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['46740']: {
    label: 'Wholesale of hardware, plumbing and heating equipment and supplies',
    BEA_CODES: { '423800': 1.0 },
  },
  ['46750']: {
    label: 'Wholesale of chemical products',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46760']: {
    label: 'Wholesale of other intermediate products',
    BEA_CODES: { '423A00': 0.4390886404415804, '424A00': 0.5609113595584196 },
  },
  ['46770']: {
    label: 'Wholesale of waste and scrap',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['46900']: {
    label: 'Non-specialised wholesale trade',
    BEA_CODES: { '423A00': 0.4390886404415804, '424A00': 0.5609113595584196 },
  },
  ['47110']: {
    label:
      'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
    BEA_CODES: { '445000': 0.4874736487164769, '452000': 0.5125263512835231 },
  },
  ['47190']: {
    label: 'Other retail sale in non-specialised stores',
    BEA_CODES: { '452000': 0.5181169319479898, '4B0000': 0.48188306805201025 },
  },
  ['47210']: {
    label: 'Retail sale of fruit and vegetables in specialised stores',
    BEA_CODES: { '445000': 1.0 },
  },
  ['47220']: {
    label: 'Retail sale of meat and meat products in specialised stores',
    BEA_CODES: { '445000': 1.0 },
  },
  ['47230']: {
    label:
      'Retail sale of fish, crustaceans and molluscs in specialised stores',
    BEA_CODES: { '445000': 1.0 },
  },
  ['47240']: {
    label:
      'Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores',
    BEA_CODES: { '445000': 1.0 },
  },
  ['47250']: {
    label: 'Retail sale of beverages in specialised stores',
    BEA_CODES: { '445000': 1.0 },
  },
  ['47260']: {
    label: 'Retail sale of tobacco products in specialised stores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47290']: {
    label: 'Other retail sale of food in specialised stores',
    BEA_CODES: { '445000': 1.0 },
  },
  ['47300']: {
    label: 'Retail sale of automotive fuel in specialised stores',
    BEA_CODES: { '447000': 1.0 },
  },
  ['47410']: {
    label:
      'Retail sale of computers, peripheral units and software in specialised stores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47421']: {
    label: 'Retail sale of mobile telephones',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47429']: {
    label:
      'Retail sale of telecommunications equipment other than mobile telephones',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47430']: {
    label: 'Retail sale of audio and video equipment in specialised stores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47510']: {
    label: 'Retail sale of textiles in specialised stores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47520']: {
    label: 'Retail sale of hardware, paints and glass in specialised stores',
    BEA_CODES: { '444000': 1.0 },
  },
  ['47530']: {
    label:
      'Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
    BEA_CODES: { '444000': 1.0 },
  },
  ['47540']: {
    label:
      'Retail sale of electrical household appliances in specialised stores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47591']: {
    label: 'Retail sale of musical instruments and scores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47599']: {
    label:
      'Retail of furniture, lighting, and similar (not musical instruments or scores) in specialised store',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47610']: {
    label: 'Retail sale of books in specialised stores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47620']: {
    label: 'Retail sale of newspapers and stationery in specialised stores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47630']: {
    label: 'Retail sale of music and video recordings in specialised stores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47640']: {
    label:
      'Retail sale of sports goods, fishing gear, camping goods, boats and bicycles',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47650']: {
    label: 'Retail sale of games and toys in specialised stores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47710']: {
    label: 'Retail sale of clothing in specialised stores',
    BEA_CODES: { '448000': 1.0 },
  },
  ['47721']: {
    label: 'Retail sale of footwear in specialised stores',
    BEA_CODES: { '448000': 1.0 },
  },
  ['47722']: {
    label: 'Retail sale of leather goods in specialised stores',
    BEA_CODES: { '448000': 1.0 },
  },
  ['47730']: {
    label: 'Dispensing chemist in specialised stores',
    BEA_CODES: { '446000': 1.0 },
  },
  ['47741']: {
    label: 'Retail sale of hearing aids',
    BEA_CODES: { '446000': 1.0 },
  },
  ['47749']: {
    label:
      'Retail sale of medical and orthopaedic goods in specialised stores (not incl. hearing aids) n.e.c.',
    BEA_CODES: { '446000': 1.0 },
  },
  ['47750']: {
    label: 'Retail sale of cosmetic and toilet articles in specialised stores',
    BEA_CODES: { '446000': 1.0 },
  },
  ['47760']: {
    label:
      'Retail sale of flowers, plants, seeds, fertilizers, pet animals and pet food in specialised stores',
    BEA_CODES: { '444000': 0.3753916618604824, '4B0000': 0.6246083381395177 },
  },
  ['47770']: {
    label: 'Retail sale of watches and jewellery in specialised stores',
    BEA_CODES: { '448000': 0.4000090715290062, '4B0000': 0.5999909284709938 },
  },
  ['47781']: {
    label: 'Retail sale in commercial art galleries',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47782']: {
    label: 'Retail sale by opticians',
    BEA_CODES: { '446000': 1.0 },
  },
  ['47789']: {
    label:
      'Other retail sale of new goods in specialised stores (not commercial art galleries and opticians)',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47791']: {
    label: 'Retail sale of antiques including antique books in stores',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47799']: {
    label:
      'Retail sale of other second-hand goods in stores (not incl. antiques)',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47810']: {
    label:
      'Retail sale via stalls and markets of food, beverages and tobacco products',
    BEA_CODES: { '445000': 0.5436897663429324, '454000': 0.4563102336570676 },
  },
  ['47820']: {
    label:
      'Retail sale via stalls and markets of textiles, clothing and footwear',
    BEA_CODES: { '448000': 0.4371824243218265, '454000': 0.5628175756781735 },
  },
  ['47890']: {
    label: 'Retail sale via stalls and markets of other goods',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['47910']: {
    label: 'Retail sale via mail order houses or via Internet',
    BEA_CODES: { '454000': 1.0 },
  },
  ['47990']: {
    label: 'Other retail sale not in stores, stalls or markets',
    BEA_CODES: { '454000': 0.46186860541815955, '4B0000': 0.5381313945818404 },
  },
  ['49100']: {
    label: 'Passenger rail transport, interurban',
    BEA_CODES: { '482000': 1.0 },
  },
  ['49200']: { label: 'Freight rail transport', BEA_CODES: { '482000': 1.0 } },
  ['49311']: {
    label:
      'Urban and suburban passenger railway transportation by underground, metro and similar systems',
    BEA_CODES: { '485000': 1.0 },
  },
  ['49319']: {
    label:
      'Other urban, suburban or metropolitan passenger land transport (not underground, metro or similar)',
    BEA_CODES: { '485000': 1.0 },
  },
  ['49320']: { label: 'Taxi operation', BEA_CODES: { '485000': 1.0 } },
  ['49390']: {
    label: 'Other passenger land transport',
    BEA_CODES: { '485000': 1.0 },
  },
  ['49410']: {
    label: 'Freight transport by road',
    BEA_CODES: { '484000': 1.0 },
  },
  ['49420']: { label: 'Removal services', BEA_CODES: { '484000': 1.0 } },
  ['49500']: { label: 'Transport via pipeline', BEA_CODES: { '486000': 1.0 } },
  ['50100']: {
    label: 'Sea and coastal passenger water transport',
    BEA_CODES: { '483000': 1.0 },
  },
  ['50200']: {
    label: 'Sea and coastal freight water transport',
    BEA_CODES: { '483000': 1.0 },
  },
  ['50300']: {
    label: 'Inland passenger water transport',
    BEA_CODES: { '483000': 1.0 },
  },
  ['50400']: {
    label: 'Inland freight water transport',
    BEA_CODES: { '483000': 1.0 },
  },
  ['51101']: {
    label: 'Scheduled passenger air transport',
    BEA_CODES: { '481000': 1.0 },
  },
  ['51102']: {
    label: 'Non-scheduled passenger air transport',
    BEA_CODES: { '481000': 1.0 },
  },
  ['51210']: { label: 'Freight air transport', BEA_CODES: { '481000': 1.0 } },
  ['51220']: { label: 'Space transport', BEA_CODES: { '481000': 1.0 } },
  ['52101']: {
    label:
      'Operation of warehousing and storage facilities for water transport activities',
    BEA_CODES: { '493000': 1.0 },
  },
  ['52102']: {
    label:
      'Operation of warehousing and storage facilities for air transport activities',
    BEA_CODES: { '493000': 1.0 },
  },
  ['52103']: {
    label:
      'Operation of warehousing and storage facilities for land transport activities',
    BEA_CODES: { '493000': 1.0 },
  },
  ['52211']: {
    label: 'Operation of rail freight terminals',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['52212']: {
    label: 'Operation of rail passenger facilities at railway stations',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['52213']: {
    label:
      'Operation of bus and coach passenger facilities at bus and coach stations',
    BEA_CODES: { '48A000': 0.6585767386960169, '485000': 0.3414232613039832 },
  },
  ['52219']: {
    label: 'Other service activities incidental to land transportation, n.e.c.',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['52220']: {
    label: 'Service activities incidental to water transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['52230']: {
    label: 'Service activities incidental to air transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['52241']: {
    label: 'Cargo handling for water transport activities',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['52242']: {
    label: 'Cargo handling for air transport activities',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['52243']: {
    label: 'Cargo handling for land transport activities',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['52290']: {
    label: 'Other transportation support activities',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['53100']: {
    label: 'Postal activities under universal service obligation',
    BEA_CODES: { '491000': 1.0 },
  },
  ['53201']: { label: 'Licensed carriers', BEA_CODES: { '492000': 1.0 } },
  ['53202']: { label: 'Unlicensed carrier', BEA_CODES: { '492000': 1.0 } },
  ['55100']: {
    label: 'Hotels and similar accommodation',
    BEA_CODES: { '721000': 1.0 },
  },
  ['55201']: {
    label: 'Holiday centres and villages',
    BEA_CODES: { '721000': 1.0 },
  },
  ['55202']: { label: 'Youth hostels', BEA_CODES: { '721000': 1.0 } },
  ['55209']: {
    label: 'Other holiday and other collective accommodation',
    BEA_CODES: { '721000': 1.0 },
  },
  ['55300']: {
    label: 'Recreational vehicle parks, trailer parks and camping grounds',
    BEA_CODES: { '721000': 1.0 },
  },
  ['55900']: { label: 'Other accommodation', BEA_CODES: { '721000': 1.0 } },
  ['56101']: { label: 'Licensed restaurants', BEA_CODES: { '722110': 1.0 } },
  ['56102']: {
    label: 'Unlicensed restaurants and cafes',
    BEA_CODES: { '722211': 0.6666117776907466, '722A00': 0.3333882223092534 },
  },
  ['56103']: {
    label: 'Take-away food shops and mobile food stands',
    BEA_CODES: { '722211': 0.6666117776907466, '722A00': 0.3333882223092534 },
  },
  ['56210']: {
    label: 'Event catering activities',
    BEA_CODES: { '722A00': 1.0 },
  },
  ['56290']: { label: 'Other food services', BEA_CODES: { '722A00': 1.0 } },
  ['56301']: { label: 'Licensed clubs', BEA_CODES: { '722A00': 1.0 } },
  ['56302']: { label: 'Public houses and bars', BEA_CODES: { '722A00': 1.0 } },
  ['58110']: { label: 'Book publishing', BEA_CODES: { '511130': 1.0 } },
  ['58120']: {
    label: 'Publishing of directories and mailing lists',
    BEA_CODES: { '5111A0': 1.0 },
  },
  ['58130']: {
    label: 'Publishing of newspapers',
    BEA_CODES: { '511110': 1.0 },
  },
  ['58141']: {
    label: 'Publishing of learned journals',
    BEA_CODES: { '511120': 1.0 },
  },
  ['58142']: {
    label: 'Publishing of  consumer and business journals and periodicals',
    BEA_CODES: { '511120': 1.0 },
  },
  ['58190']: {
    label: 'Other publishing activities',
    BEA_CODES: { '5111A0': 1.0 },
  },
  ['58210']: {
    label: 'Publishing of computer games',
    BEA_CODES: { '511200': 1.0 },
  },
  ['58290']: {
    label: 'Other software publishing',
    BEA_CODES: { '511200': 1.0 },
  },
  ['59111']: {
    label: 'Motion picture production activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['59112']: {
    label: 'Video production activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['59113']: {
    label: 'Television programme production activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['59120']: {
    label:
      'Motion picture, video and television programme post-production activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['59131']: {
    label: 'Motion picture distribution activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['59132']: {
    label: 'Video distribution activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['59133']: {
    label: 'Television programme distribution activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['59140']: {
    label: 'Motion picture projection activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['59200']: {
    label: 'Sound recording and music publishing activities',
    BEA_CODES: { '512200': 1.0 },
  },
  ['60100']: { label: 'Radio broadcasting', BEA_CODES: { '515100': 1.0 } },
  ['60200']: {
    label: 'Television programming and broadcasting activities',
    BEA_CODES: { '515100': 1.0 },
  },
  ['61100']: {
    label: 'Wired telecommunications activities',
    BEA_CODES: { '517110': 1.0 },
  },
  ['61200']: {
    label: 'Wireless telecommunications activities',
    BEA_CODES: { '517210': 1.0 },
  },
  ['61300']: {
    label: 'Satellite telecommunications activities',
    BEA_CODES: { '517A00': 1.0 },
  },
  ['61900']: {
    label: 'Other telecommunications activities',
    BEA_CODES: { '517A00': 1.0 },
  },
  ['62011']: {
    label:
      'Ready-made interactive leisure and entertainment software development',
    BEA_CODES: { '541511': 1.0 },
  },
  ['62012']: {
    label: 'Business and domestic software development',
    BEA_CODES: { '541511': 1.0 },
  },
  ['62020']: {
    label: 'Information technology consultancy activities',
    BEA_CODES: { '541512': 0.6633762210880862, '54151A': 0.3366237789119137 },
  },
  ['62030']: {
    label: 'Computer facilities management activities',
    BEA_CODES: { '54151A': 1.0 },
  },
  ['62090']: {
    label: 'Other information technology service activities',
    BEA_CODES: { '54151A': 1.0 },
  },
  ['63110']: {
    label: 'Data processing, hosting and related activities',
    BEA_CODES: { '518200': 1.0 },
  },
  ['63120']: { label: 'Web portals', BEA_CODES: { '519130': 1.0 } },
  ['63910']: { label: 'News agency activities', BEA_CODES: { '5191A0': 1.0 } },
  ['63990']: {
    label: 'Other information service activities n.e.c.',
    BEA_CODES: { '5191A0': 1.0 },
  },
  ['64110']: { label: 'Central banking', BEA_CODES: { '52A000': 1.0 } },
  ['64191']: { label: 'Banks', BEA_CODES: { '52A000': 1.0 } },
  ['64192']: { label: 'Building societies', BEA_CODES: { '52A000': 1.0 } },
  ['64201']: {
    label: 'Activities of agricultural holding companies',
    BEA_CODES: { '550000': 1.0 },
  },
  ['64202']: {
    label: 'Activities of production holding companies',
    BEA_CODES: { '550000': 1.0 },
  },
  ['64203']: {
    label: 'Activities of construction holding companies',
    BEA_CODES: { '550000': 1.0 },
  },
  ['64204']: {
    label: 'Activities of distribution holding companies',
    BEA_CODES: { '550000': 1.0 },
  },
  ['64205']: {
    label: 'Activities of financial services holding companies',
    BEA_CODES: { '550000': 1.0 },
  },
  ['64209']: {
    label: 'Activities of other holding companies n.e.c.',
    BEA_CODES: { '550000': 1.0 },
  },
  ['64301']: {
    label: 'Activities of investment trusts',
    BEA_CODES: { '525000': 1.0 },
  },
  ['64302']: {
    label: 'Activities of unit trusts',
    BEA_CODES: { '525000': 1.0 },
  },
  ['64303']: {
    label: 'Activities of venture and development capital companies',
    BEA_CODES: { '525000': 1.0 },
  },
  ['64304']: {
    label: 'Activities of open-ended investment companies',
    BEA_CODES: { '525000': 1.0 },
  },
  ['64305']: {
    label: 'Activities of property unit trusts',
    BEA_CODES: { '525000': 1.0 },
  },
  ['64306']: {
    label: 'Activities of real estate investment trusts',
    BEA_CODES: { '525000': 1.0 },
  },
  ['64910']: { label: 'Financial leasing', BEA_CODES: { '522A00': 1.0 } },
  ['64921']: {
    label:
      'Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['64922']: {
    label: 'Activities of mortgage finance companies',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['64929']: {
    label: 'Other credit granting n.e.c.',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['64991']: {
    label: 'Security dealing on own account',
    BEA_CODES: { '523900': 0.6813741146991368, '523A00': 0.3186258853008632 },
  },
  ['64992']: { label: 'Factoring', BEA_CODES: { '522A00': 1.0 } },
  ['64999']: {
    label: 'Financial intermediation not elsewhere classified',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['65110']: { label: 'Life insurance', BEA_CODES: { '524113': 1.0 } },
  ['65120']: { label: 'Non-life insurance', BEA_CODES: { '5241XX': 1.0 } },
  ['65201']: { label: 'Life reinsurance', BEA_CODES: { '5241XX': 1.0 } },
  ['65202']: { label: 'Non-life reinsurance', BEA_CODES: { '5241XX': 1.0 } },
  ['65300']: { label: 'Pension funding', BEA_CODES: { '525000': 1.0 } },
  ['66110']: {
    label: 'Administration of financial markets',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['66120']: {
    label: 'Security and commodity contracts dealing activities',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['66190']: {
    label: 'Activities auxiliary to financial intermediation n.e.c.',
    BEA_CODES: { '523900': 1.0 },
  },
  ['66210']: {
    label: 'Risk and damage evaluation',
    BEA_CODES: { '524200': 1.0 },
  },
  ['66220']: {
    label: 'Activities of insurance agents and brokers',
    BEA_CODES: { '524200': 1.0 },
  },
  ['66290']: {
    label: 'Other activities auxiliary to insurance and pension funding',
    BEA_CODES: { '524200': 1.0 },
  },
  ['66300']: {
    label: 'Fund management activities',
    BEA_CODES: { '523900': 1.0 },
  },
  ['68100']: {
    label: 'Buying and selling of own real estate',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['68201']: {
    label: 'Renting and operating of Housing Association real estate',
    BEA_CODES: { '531HST': 1.0 },
  },
  ['68202']: {
    label: 'Letting and operating of conference and exhibition centres',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['68209']: {
    label: 'Other letting and operating of own or leased real estate',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['68310']: { label: 'Real estate agencies', BEA_CODES: { '531ORE': 1.0 } },
  ['68320']: {
    label: 'Management of real estate on a fee or contract basis',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['69101']: { label: 'Barristers at law', BEA_CODES: { '541100': 1.0 } },
  ['69102']: { label: 'Solicitors', BEA_CODES: { '541100': 1.0 } },
  ['69109']: {
    label:
      'Activities of patent and copyright agents; other legal activities n.e.c.',
    BEA_CODES: { '541100': 1.0 },
  },
  ['69201']: {
    label: 'Accounting and auditing activities',
    BEA_CODES: { '541200': 1.0 },
  },
  ['69202']: { label: 'Bookkeeping activities', BEA_CODES: { '541200': 1.0 } },
  ['69203']: { label: 'Tax consultancy', BEA_CODES: { '541200': 1.0 } },
  ['70100']: {
    label: 'Activities of head offices',
    BEA_CODES: { '550000': 1.0 },
  },
  ['70210']: {
    label: 'Public relations and communications activities',
    BEA_CODES: { '541800': 1.0 },
  },
  ['70221']: { label: 'Financial management', BEA_CODES: { '541610': 1.0 } },
  ['70229']: {
    label: 'Management consultancy activities other than financial management',
    BEA_CODES: { '541610': 1.0 },
  },
  ['71111']: {
    label: 'Architectural activities',
    BEA_CODES: { '541300': 1.0 },
  },
  ['71112']: {
    label: 'Urban planning and landscape architectural activities',
    BEA_CODES: { '541300': 1.0 },
  },
  ['71121']: {
    label:
      'Engineering design activities for industrial process and production',
    BEA_CODES: { '541300': 1.0 },
  },
  ['71122']: {
    label: 'Engineering related scientific and technical consulting activities',
    BEA_CODES: { '541300': 1.0 },
  },
  ['71129']: {
    label: 'Other engineering activities',
    BEA_CODES: { '541300': 1.0 },
  },
  ['71200']: {
    label: 'Technical testing and analysis',
    BEA_CODES: { '541300': 1.0 },
  },
  ['72110']: {
    label: 'Research and experimental development on biotechnology',
    BEA_CODES: { '541700': 1.0 },
  },
  ['72190']: {
    label:
      'Other research and experimental development on natural sciences and engineering',
    BEA_CODES: { '541700': 1.0 },
  },
  ['72200']: {
    label:
      'Research and experimental development on social sciences and humanities',
    BEA_CODES: { '541700': 1.0 },
  },
  ['73110']: { label: 'Advertising agencies', BEA_CODES: { '541800': 1.0 } },
  ['73120']: {
    label: 'Media representation services',
    BEA_CODES: { '541800': 1.0 },
  },
  ['73200']: {
    label: 'Market research and public opinion polling',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['74100']: {
    label: 'specialised design activities',
    BEA_CODES: { '541400': 1.0 },
  },
  ['74201']: {
    label: 'Portrait photographic activities',
    BEA_CODES: { '541920': 1.0 },
  },
  ['74202']: {
    label: 'Other specialist photography',
    BEA_CODES: { '541920': 1.0 },
  },
  ['74203']: { label: 'Film processing', BEA_CODES: { '541920': 1.0 } },
  ['74209']: {
    label: 'Photographic activities not elsewhere classified',
    BEA_CODES: { '541920': 1.0 },
  },
  ['74300']: {
    label: 'Translation and interpretation activities',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['74901']: {
    label: 'Environmental consulting activities',
    BEA_CODES: { '5416A0': 1.0 },
  },
  ['74902']: {
    label: 'Quantity surveying activities',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['74909']: {
    label: 'Other professional, scientific and technical activities n.e.c.',
    BEA_CODES: { '5419A0': 0.2917081754786542, '541610': 0.7082918245213459 },
  },
  ['75000']: { label: 'Veterinary activities', BEA_CODES: { '541940': 1.0 } },
  ['77110']: {
    label: 'Renting and leasing of cars and light motor vehicles',
    BEA_CODES: { '532100': 1.0 },
  },
  ['77120']: {
    label: 'Renting and leasing of trucks and other heavy vehicles',
    BEA_CODES: { '532100': 1.0 },
  },
  ['77210']: {
    label: 'Renting and leasing of recreational and sports goods',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['77220']: {
    label: 'Renting of video tapes and disks',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['77291']: {
    label: 'Renting and leasing of media entertainment equipment',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['77299']: {
    label: 'Renting and leasing of other personal and household goods',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['77310']: {
    label: 'Renting and leasing of agricultural machinery and equipment',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77320']: {
    label:
      'Renting and leasing of construction and civil engineering machinery and equipment',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77330']: {
    label:
      'Renting and leasing of office machinery and equipment (including computers)',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77341']: {
    label: 'Renting and leasing of passenger water transport equipment',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['77342']: {
    label: 'Renting and leasing of freight water transport equipment',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77351']: {
    label: 'Renting and leasing of air passenger transport equipment',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77352']: {
    label: 'Renting and leasing of freight air transport equipment',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77390']: {
    label:
      'Renting and leasing of other machinery, equipment and tangible goods n.e.c.',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77400']: {
    label:
      'Leasing of intellectual property and similar products, except copyright works',
    BEA_CODES: { '533000': 1.0 },
  },
  ['78101']: {
    label: 'Motion picture, television and other theatrical casting activities',
    BEA_CODES: { '561300': 1.0 },
  },
  ['78109']: {
    label: 'Other activities of employment placement agencies',
    BEA_CODES: { '561300': 1.0 },
  },
  ['78200']: {
    label: 'Temporary employment agency activities',
    BEA_CODES: { '561300': 1.0 },
  },
  ['78300']: {
    label:
      'Human resources provision and management of human resources functions',
    BEA_CODES: { '561300': 1.0 },
  },
  ['79110']: {
    label: 'Travel agency activities',
    BEA_CODES: { '561500': 1.0 },
  },
  ['79120']: {
    label: 'Tour operator activities',
    BEA_CODES: { '561500': 1.0 },
  },
  ['79901']: {
    label: 'Activities of tourist guides',
    BEA_CODES: { '561500': 0.36228065958367384, '713900': 0.6377193404163262 },
  },
  ['79909']: {
    label: 'Other reservation service activities n.e.c.',
    BEA_CODES: { '561500': 1.0 },
  },
  ['80100']: {
    label: 'Private security activities',
    BEA_CODES: { '561600': 1.0 },
  },
  ['80200']: {
    label: 'Security systems service activities',
    BEA_CODES: { '561600': 1.0 },
  },
  ['80300']: {
    label: 'Investigation activities',
    BEA_CODES: { '561600': 1.0 },
  },
  ['81100']: {
    label: 'Combined facilities support activities',
    BEA_CODES: { '561200': 1.0 },
  },
  ['81210']: {
    label: 'General cleaning of buildings',
    BEA_CODES: { '561700': 1.0 },
  },
  ['81221']: {
    label: 'Window cleaning services',
    BEA_CODES: { '561700': 1.0 },
  },
  ['81222']: {
    label: 'Specialised cleaning services',
    BEA_CODES: { '561700': 1.0 },
  },
  ['81223']: {
    label: 'Furnace and chimney cleaning services',
    BEA_CODES: {
      '561700': 0.3625000319586021,
      '230301': 0.45043476482303885,
      '230302': 0.18706520321835907,
    },
  },
  ['81229']: {
    label: 'Other building and industrial cleaning activities',
    BEA_CODES: { '561700': 1.0 },
  },
  ['81291']: {
    label: 'Disinfecting and exterminating services',
    BEA_CODES: { '561700': 1.0 },
  },
  ['81299']: { label: 'Other cleaning services', BEA_CODES: { '561700': 1.0 } },
  ['81300']: {
    label: 'Landscape service activities',
    BEA_CODES: { '561700': 1.0 },
  },
  ['82110']: {
    label: 'Combined office administrative service activities',
    BEA_CODES: { '561100': 1.0 },
  },
  ['82190']: {
    label:
      'Photocopying, document preparation and other specialised office support activities',
    BEA_CODES: { '561400': 1.0 },
  },
  ['82200']: {
    label: 'Activities of call centres',
    BEA_CODES: { '561400': 1.0 },
  },
  ['82301']: {
    label: 'Activities of exhibition and fair organisers',
    BEA_CODES: { '561900': 1.0 },
  },
  ['82302']: {
    label: 'Activities of conference organisers',
    BEA_CODES: { '561900': 1.0 },
  },
  ['82911']: {
    label: 'Activities of collection agencies',
    BEA_CODES: { '561400': 1.0 },
  },
  ['82912']: {
    label: 'Activities of credit bureaus',
    BEA_CODES: { '561400': 1.0 },
  },
  ['82920']: {
    label: 'Packaging activities',
    BEA_CODES: { '561900': 0.32016369328254063, '561400': 0.6798363067174593 },
  },
  ['82990']: {
    label: 'Other business support service activities n.e.c.',
    BEA_CODES: { '561900': 1.0 },
  },
  ['84110']: {
    label: 'General public administration activities',
    BEA_CODES: { S00600: 0.3209525745618917, GSLGO: 0.6790474254381084 },
  },
  ['84120']: {
    label:
      'Regulation of health care, education, cultural and other social services, not incl. social security',
    BEA_CODES: { GSLGH: 0.07494276380341575, GSLGO: 0.9250572361965842 },
  },
  ['84130']: {
    label:
      'Regulation of and contribution to more efficient operation of businesses',
    BEA_CODES: { S00600: 0.3209525745618917, GSLGO: 0.6790474254381084 },
  },
  ['84210']: { label: 'Foreign affairs', BEA_CODES: { S00600: 1.0 } },
  ['84220']: { label: 'Defence activities', BEA_CODES: { S00500: 1.0 } },
  ['84230']: {
    label: 'Justice and judicial activities',
    BEA_CODES: { GSLGO: 0.6790474254381084, S00600: 0.3209525745618917 },
  },
  ['84240']: {
    label: 'Public order and safety activities',
    BEA_CODES: { GSLGO: 0.6790474254381084, S00600: 0.3209525745618917 },
  },
  ['84250']: { label: 'Fire service activities', BEA_CODES: { GSLGO: 1.0 } },
  ['84300']: {
    label: 'Compulsory social security activities',
    BEA_CODES: { GSLGO: 0.6790474254381084, S00600: 0.3209525745618917 },
  },
  ['85100']: { label: 'Pre-primary education', BEA_CODES: { '611B00': 1.0 } },
  ['85200']: { label: 'Primary education', BEA_CODES: { '611100': 1.0 } },
  ['85310']: {
    label: 'General secondary education',
    BEA_CODES: { '611100': 1.0 },
  },
  ['85320']: {
    label: 'Technical and vocational secondary education',
    BEA_CODES: { '611100': 1.0 },
  },
  ['85410']: {
    label: 'Post-secondary non-tertiary education',
    BEA_CODES: { '611A00': 1.0 },
  },
  ['85421']: {
    label: 'First-degree level higher education',
    BEA_CODES: { '611A00': 1.0 },
  },
  ['85422']: {
    label: 'Post-graduate level higher education',
    BEA_CODES: { '611A00': 1.0 },
  },
  ['85510']: {
    label: 'Sports and recreation education',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['85520']: { label: 'Cultural education', BEA_CODES: { '611B00': 1.0 } },
  ['85530']: {
    label: 'Driving school activities',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['85590']: { label: 'Other education n.e.c.', BEA_CODES: { '611B00': 1.0 } },
  ['85600']: {
    label: 'Educational support services',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['86101']: { label: 'Hospital activities', BEA_CODES: { '622000': 1.0 } },
  ['86102']: {
    label: 'Medical nursing home activities',
    BEA_CODES: { '622000': 1.0 },
  },
  ['86210']: {
    label: 'General medical practice activities',
    BEA_CODES: { '621100': 1.0 },
  },
  ['86220']: {
    label: 'Specialists medical practice activities',
    BEA_CODES: { '621100': 1.0 },
  },
  ['86230']: {
    label: 'Dental practice activities',
    BEA_CODES: { '621200': 1.0 },
  },
  ['86900']: {
    label: 'Other human health activities',
    BEA_CODES: { '621900': 1.0 },
  },
  ['87100']: {
    label: 'Residential nursing care facilities',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['87200']: {
    label:
      'Residential care activities for learning difficulties, mental health and substance abuse',
    BEA_CODES: { '623B00': 1.0 },
  },
  ['87300']: {
    label: 'Residential care activities for the elderly and disabled',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['87900']: {
    label: 'Other residential care activities n.e.c.',
    BEA_CODES: { '623B00': 1.0 },
  },
  ['88100']: {
    label:
      'Social work activities without accommodation for the elderly and disabled',
    BEA_CODES: { '624100': 1.0 },
  },
  ['88910']: {
    label: 'Child day-care activities',
    BEA_CODES: { '624400': 1.0 },
  },
  ['88990']: {
    label: 'Other social work activities without accommodation n.e.c.',
    BEA_CODES: { '624100': 1.0 },
  },
  ['90010']: {
    label: 'Performing arts',
    BEA_CODES: { '711100': 0.46559918343912476, '711500': 0.5344008165608752 },
  },
  ['90020']: {
    label: 'Support activities to performing arts',
    BEA_CODES: { '711A00': 1.0 },
  },
  ['90030']: { label: 'Artistic creation', BEA_CODES: { '711500': 1.0 } },
  ['90040']: {
    label: 'Operation of arts facilities',
    BEA_CODES: { '711100': 1.0 },
  },
  ['91011']: { label: 'Library activities', BEA_CODES: { '5191A0': 1.0 } },
  ['91012']: { label: 'Archives activities', BEA_CODES: { '5191A0': 1.0 } },
  ['91020']: { label: 'Museums activities', BEA_CODES: { '712000': 1.0 } },
  ['91030']: {
    label:
      'Operation of historical sites and buildings and similar visitor attractions',
    BEA_CODES: { '712000': 1.0 },
  },
  ['91040']: {
    label: 'Botanical and zoological gardens and nature reserves activities',
    BEA_CODES: { '712000': 1.0 },
  },
  ['92000']: {
    label: 'Gambling and betting activities',
    BEA_CODES: { '713200': 1.0 },
  },
  ['93110']: {
    label: 'Operation of sports facilities',
    BEA_CODES: { '713900': 1.0 },
  },
  ['93120']: {
    label: 'Activities of sport clubs',
    BEA_CODES: { '711200': 1.0 },
  },
  ['93130']: { label: 'Fitness facilities', BEA_CODES: { '713900': 1.0 } },
  ['93191']: {
    label: 'Activities of racehorse owners',
    BEA_CODES: { '711200': 1.0 },
  },
  ['93199']: { label: 'Other sports activities', BEA_CODES: { '713900': 1.0 } },
  ['93210']: {
    label: 'Activities of amusement parks and theme parks',
    BEA_CODES: { '713100': 1.0 },
  },
  ['93290']: {
    label: 'Other amusement and recreation activities n.e.c.',
    BEA_CODES: { '713900': 1.0 },
  },
  ['94110']: {
    label: 'Activities of business and employers membership organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['94120']: {
    label: 'Activities of professional membership organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['94200']: {
    label: 'Activities of trade unions',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['94910']: {
    label: 'Activities of religious organizations',
    BEA_CODES: { '813100': 1.0 },
  },
  ['94920']: {
    label: 'Activities of political organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['94990']: {
    label: 'Activities of other membership organizations n.e.c.',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['95110']: {
    label: 'Repair of computers and peripheral equipment',
    BEA_CODES: { '811200': 1.0 },
  },
  ['95120']: {
    label: 'Repair of communication equipment',
    BEA_CODES: { '811200': 1.0 },
  },
  ['95210']: {
    label: 'Repair of consumer electronics',
    BEA_CODES: { '811200': 1.0 },
  },
  ['95220']: {
    label: 'Repair of household appliances and home and garden equipment',
    BEA_CODES: { '811400': 1.0 },
  },
  ['95230']: {
    label: 'Repair of footwear and leather goods',
    BEA_CODES: { '811400': 1.0 },
  },
  ['95240']: {
    label: 'Repair of furniture and home furnishings',
    BEA_CODES: { '811400': 1.0 },
  },
  ['95250']: {
    label: 'Repair of watches, clocks and jewellery',
    BEA_CODES: { '811400': 1.0 },
  },
  ['95290']: {
    label: 'Repair of personal and household goods n.e.c.',
    BEA_CODES: { '811400': 1.0 },
  },
  ['96010']: {
    label: 'Washing and (dry-)cleaning of textile and fur products',
    BEA_CODES: { '812300': 1.0 },
  },
  ['96020']: {
    label: 'Hairdressing and other beauty treatment',
    BEA_CODES: { '812100': 1.0 },
  },
  ['96030']: {
    label: 'Funeral and related activities',
    BEA_CODES: { '812200': 1.0 },
  },
  ['96040']: {
    label: 'Physical well-being activities',
    BEA_CODES: { '812100': 1.0 },
  },
  ['96090']: {
    label: 'Other service activities n.e.c.',
    BEA_CODES: { '812900': 1.0 },
  },
  ['97000']: {
    label: 'Activities of households as employers of domestic personnel',
    BEA_CODES: { '814000': 1.0 },
  },
  ['98000']: {
    label: 'Residents property management',
    BEA_CODES: { '814000': 1.0 },
  },
  ['98100']: {
    label:
      'Undifferentiated goods-producing activities of private households for own use',
    BEA_CODES: { '814000': 1.0 },
  },
  ['98200']: {
    label:
      'Undifferentiated service-producing activities of private households for own use',
    BEA_CODES: { '814000': 1.0 },
  },
  ['99000']: {
    label: 'Activities of extraterritorial organizations and bodies',
    BEA_CODES: { S00600: 1.0 },
  },
};
