import {
  GQFilterOperator,
  GQAggregateKind,
  GQColumnDimension,
  GQDrilldownAggregateKind,
  GQDrilldownParameters,
  GQDrilldownTimeSeriesKind,
  GQFilterExpressionPrimitiveWithNewFiltersInput,
  GQFilterFieldWatershed,
  GQFilterFieldLegacy,
} from '../generated/graphql';
import {
  IntensityDenominatorKind,
  stringToIntensityDenominatorKind,
} from './intensityDenominators';
import { DateTime } from 'luxon';
import DateTimeUtils from './DateTimeUtils';
import invariant from 'invariant';
import { YMInterval } from './YearMonth';
import assertNever from '@watershed/shared-util/assertNever';
import difference from 'lodash/difference';
import { FootprintContribution } from '../analysis/drilldownTypes';
import { FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM } from '../footprint/constants';
import { SAVED_VIEW_DRAFT_ID } from '../footprint/constants';
import { encodeOperator } from './AnalysisFilters';
import { NONE_GROUP_NAME } from '../analysis/drilldownConstants';

export type FootprintFilters =
  Array<GQFilterExpressionPrimitiveWithNewFiltersInput>;

export const FilterFieldsWatershed: Array<string> = Object.values(
  GQFilterFieldWatershed
);
export const AllFilterFieldNamesOld = difference(
  Object.values(GQFilterFieldLegacy) as Array<string>,
  FilterFieldsWatershed
) as Array<GQFilterFieldLegacy>;

/**
 * FootprintFilters that are not exposed via the "Filters" section of the page
 * and get special treatment instead. Currently this is is only FootprintKind
 * which is a page-wide global option.
 */
const HIDDEN_FILTER_FIELDS: Array<string> = [
  GQFilterFieldWatershed.FootprintKind,
  GQFilterFieldWatershed.CompanyId,
];

export function isVisibleFilterField(fieldName: string): boolean {
  return !HIDDEN_FILTER_FIELDS.includes(fieldName);
}

const DATE_URL_FORMAT = 'yyyyMM';

export interface DrilldownFiltersArgs {
  savedViewId?: string;
  version?: string;
  // TODO (bryan): Make this optional?
  interval: YMInterval;
  // TODO (bryan): Make this optional, defaulting to GQAggregateKind.Total?
  aggregateKind: GQAggregateKind;
  // TODO (bryan): Make this optional, defaulting to GQColumnDimension.Summary?
  columnDimension: string;
  intensityKind?: IntensityDenominatorKind;
  // TODO (bryan): Make this optional, defaulting to an empty array?
  filterValues: FootprintFilters;

  // We need to distinguish between a field being empty by default vs.
  // having been explicitly edited by removing all filter values. This
  // set keeps track of all fields that were ever explicitly cleared so we
  // can make sure the current state overrides any default values inherited from
  // the "saved view" version of these filters.
  explicitlyClearedFilterFields?: Set<string>;
}

export type ApplyFilterOptions = {
  replace?: boolean;
};

export type UpdateFilters = (params: {
  filters: DrilldownFilters;
  groupBy: Array<string>;
  options?: ApplyFilterOptions;
}) => void;

/**
 * DrilldownFilters is a wrapper for handling drilldown filtering logic.
 * It can be instantiated from and turned into URLSearchParams.
 *
 * This is a fork of AnalysisFilters while we migrate the UI to consume data exclusively from MARTA.
 */
class DrilldownFilters {
  // TODO: make version required everywhere in the client.
  private readonly _version?: string | undefined;
  private readonly _savedViewId?: string | undefined;
  private readonly _interval: YMInterval;

  // This presumes for the moment that we are only aggregating across one dimension
  private readonly _aggregateKind: GQAggregateKind;
  private readonly _intensityKind?: IntensityDenominatorKind;

  // This indicates whether to pull a total or a time series
  private readonly _columnDimension: string;

  private readonly _filterValues: FootprintFilters;
  private readonly _explicitlyClearedFilterFields: Set<string>;

  constructor(args: DrilldownFiltersArgs) {
    this._savedViewId = args.savedViewId;
    this._version = args.version;
    this._interval = args.interval;
    this._aggregateKind = args.aggregateKind;
    this._intensityKind = args.intensityKind;
    this._columnDimension = args.columnDimension;
    this._explicitlyClearedFilterFields = new Set(
      args.explicitlyClearedFilterFields
    );

    // ensure no duplicates
    const fields = args.filterValues.map((value) => value.field);
    invariant(
      new Set(fields).size === fields.length,
      `unexpected duplicate filter for field: ${JSON.stringify(
        args.filterValues
      )}`
    );

    // keep in sorted order. All custom fields come after the known fields.
    const fieldOrder = FilterFieldsWatershed;
    // Create a new array so we don't mutate the original.
    const sortedFilters = [...args.filterValues].sort((a, b) => {
      const aIndex = fieldOrder.indexOf(a.field);
      const bIndex = fieldOrder.indexOf(b.field);
      // Both custom filters, so sort alphabetically.
      if (aIndex === -1 && bIndex === -1) {
        // TODO: i18n (please resolve or remove this TODO line if legit)
        // eslint-disable-next-line @watershed/require-locale-argument
        return a.field.localeCompare(b.field);
      }
      if (aIndex === -1) {
        return 1;
      }
      if (bIndex === -1) {
        return -1;
      }
      return fieldOrder.indexOf(a.field) - fieldOrder.indexOf(b.field);
    });

    this._filterValues = sortedFilters;
  }

  getActiveVisibleFilterValues(): Array<GQFilterExpressionPrimitiveWithNewFiltersInput> {
    return this._filterValues.filter(
      (f) => isVisibleFilterField(f.field) && f.value.length
    );
  }

  getFilterFields(): Array<string> {
    return this._filterValues.map((value) => value.field);
  }

  toUrlSearchParams(): URLSearchParams {
    const filters: DrilldownFilters = this;
    // TODO(Avi-FILTER): Add support for arrays in URL
    const params = new URLSearchParams();

    if (filters._version) {
      if (filters._version.startsWith('fps_')) {
        params.append(FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM, filters._version);
      } else {
        // Backward compatibility with versions like `Draft`.
        params.append('version', filters._version);
      }
    }
    if (filters._savedViewId) params.append('view', filters._savedViewId);
    params.append('aggregateKind', filters._aggregateKind);
    if (filters._intensityKind) {
      params.append('intensityKind', filters._intensityKind);
    }
    params.append('columnDimension', filters._columnDimension);

    function formatDate(input: DateTime): string {
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return input.toFormat(DATE_URL_FORMAT);
    }

    // NOTE: We use inclusive interval since frontend expects this.
    const interval = DateTimeUtils.ymIntervalToLegacyInclusiveInterval(
      filters._interval
    );
    params.append('start', formatDate(interval.start));
    params.append('end', formatDate(interval.end));

    for (const filter of filters._filterValues) {
      switch (filter.operator) {
        case GQFilterOperator.NotIn:
          params.append(encodeOperator(filter.field), filter.operator);
          break;
        case GQFilterOperator.In:
          if (isVisibleFilterField(filter.field) && filter.value.length > 0) {
            params.append(encodeOperator(filter.field), filter.operator);
          }
          break;
        default:
          assertNever(filter.operator);
      }
      if (filter.value.length > 0) {
        for (const str of filter.value) {
          // For any null values, we send a special string value to and from the server to represent it
          params.append(filter.field, str?.toString() ?? NONE_GROUP_NAME);
        }
      } else if (filters._explicitlyClearedFilterFields.has(filter.field)) {
        // Include an empty parameter to indicate we really want this field set to an empty list.
        params.append(filter.field, '');
      }
    }

    return params;
  }
}

export function getValueForField(
  field: string,
  row: Partial<FootprintContribution>
): any {
  return row[field] || null;
}

export function argsFromDrilldownParameters(
  params: GQDrilldownParameters & { footprintVersionId?: string },
  defaultInterval: YMInterval
): DrilldownFiltersArgs {
  return {
    savedViewId: SAVED_VIEW_DRAFT_ID,
    version: params.footprintVersionId ?? undefined,
    interval: params.defaultTimeInterval ?? defaultInterval,
    aggregateKind: convertSavedViewAggregateKindToLegacy(
      params.aggregateKind,
      params.intensityDenominatorName
    ),
    intensityKind: params.intensityDenominatorName
      ? stringToIntensityDenominatorKind(params.intensityDenominatorName)
      : undefined,
    columnDimension: convertSavedViewTimeSeriesKindToLegacy(
      params.timeSeriesKind
    ),
    filterValues: params.filterExpressions?.expressions || [],
  };
}

export function convertSavedViewAggregateKindToLegacy(
  aggregateKind: GQDrilldownAggregateKind,
  intensityDenominatorName: string | null
): GQAggregateKind {
  switch (aggregateKind) {
    case GQDrilldownAggregateKind.Intensity:
      if (intensityDenominatorName === 'revenue') {
        return GQAggregateKind.RevenueIntensity;
      } else if (intensityDenominatorName === 'headcount') {
        return GQAggregateKind.HeadcountIntensity;
      } else {
        return GQAggregateKind.CustomIntensity;
      }
    case GQDrilldownAggregateKind.Emissions:
      return GQAggregateKind.Total;
    case GQDrilldownAggregateKind.Electricity:
      return GQAggregateKind.Electricity;
    case GQDrilldownAggregateKind.LocationBasedEmissions:
      return GQAggregateKind.LocationBased;
    case GQDrilldownAggregateKind.PercentageEmissions:
      return GQAggregateKind.PercentageOfTotalFootprint;
    case GQDrilldownAggregateKind.PercentageOfCurrentView:
      return GQAggregateKind.PercentageOfCurrentView;
  }
}

function convertSavedViewTimeSeriesKindToLegacy(
  timeSeriesKind: GQDrilldownTimeSeriesKind
): GQColumnDimension {
  switch (timeSeriesKind) {
    case GQDrilldownTimeSeriesKind.Monthly:
      return GQColumnDimension.Monthly;
    case GQDrilldownTimeSeriesKind.Yearly:
      return GQColumnDimension.Yearly;
    case GQDrilldownTimeSeriesKind.Summary:
      return GQColumnDimension.Summary;
  }
}

export default DrilldownFilters;
