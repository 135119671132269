import React from 'react';
import {
  Container,
  Typography,
  Box,
  Theme,
  Chip,
  Stack,
  ChipProps,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import BetaChip from '@watershed/ui-core/components/BetaChip';
import Breadcrumb, { BreadcrumbProps } from './Breadcrumb';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { mixinSx } from '@watershed/style/styleUtils';

export const PAGE_HEADER_Z_INDEX = 3;

const rootSx: SystemStyleObject<Theme> = {
  color: (theme) => theme.palette.text.primary,
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'space-between',
  spacing: (theme) => theme.spacing(3),
  position: 'relative',
};

const containerSx: SystemStyleObject<Theme> = {
  padding: (theme) => theme.spacing(4, 0),
  marginBottom: (theme) => theme.spacing(2),
};

const fullWidthSx: SystemStyleObject<Theme> = {
  zIndex: PAGE_HEADER_Z_INDEX,
  padding: (theme) => theme.spacing(2),
  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  backgroundColor: (theme) => theme.palette.background.paper,
};

const containerCompactSx: SystemStyleObject<Theme> = {
  gridTemplateColumns: '1fr auto',
  padding: (theme) => theme.spacing(2),
  marginBottom: (theme) => theme.spacing(0),
};

const headerWithTabsSx: SystemStyleObject<Theme> = {
  borderBottom: 0,
  paddingBottom: (theme) => theme.spacing(1),
};

const titleSx: SystemStyleObject<Theme> = {
  lineHeight: 1.125,
};

const subtitleSx: SystemStyleObject<Theme> = {
  margin: (theme) => theme.spacing(1, 0, 0),
  textWrap: 'balance',
  '& a': {
    color: 'inherit',
  },
};

export const PAGE_HEADER_TABS_HEIGHT = 41;

const tabsSx: SystemStyleObject<Theme> = {
  height: PAGE_HEADER_TABS_HEIGHT,
  marginTop: (theme) => theme.spacing(-0.5),
  paddingLeft: (theme) => theme.spacing(2),
  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  backgroundColor: (theme) => theme.palette.background.paper,
};

export interface PageTitleProps {
  chip?: ChipProps;
  breadcrumb?: BreadcrumbProps;
  sectionName?: React.ReactNode;
  title: string;
  renderTitle?: ({
    title,
    titleNode,
  }: {
    title: string;
    titleNode: React.ReactNode;
  }) => React.ReactNode;
  subtitle?: React.ReactNode;
  titleRightCol?: React.ReactNode;
  renderTitleRightCol?: ({
    titleRightCol,
  }: {
    titleRightCol: React.ReactNode;
  }) => React.ReactNode;
  fullWidth?: boolean;
}

const PageTitle = ({
  title,
  chip,
  renderTitle,
  sectionName,
  subtitle,
  breadcrumb,
  titleRightCol,
  renderTitleRightCol,
  fullWidth,
}: PageTitleProps) => {
  const titleNode = (
    <Typography variant="h1" id="title" sx={titleSx}>
      {title}
    </Typography>
  );

  return (
    <Box maxWidth="100%" width={fullWidth ? '100%' : undefined}>
      {breadcrumb && <Breadcrumb breadcrumb={breadcrumb} />}
      {sectionName && !breadcrumb && (
        <Typography variant="body2" gutterBottom>
          {sectionName}
        </Typography>
      )}
      <Stack direction="row" alignItems="center" gap={1}>
        {renderTitle ? renderTitle({ title, titleNode }) : titleNode}
        {chip ? (
          chip.label === 'Beta' ? (
            <BetaChip />
          ) : (
            <Chip {...chip} />
          )
        ) : null}
        {titleRightCol
          ? renderTitleRightCol
            ? renderTitleRightCol({ titleRightCol })
            : titleRightCol
          : null}
      </Stack>
      {subtitle && (
        <Typography component="div" variant="body2" sx={subtitleSx}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export interface PageHeaderProps {
  heading?: PageTitleProps;
  rightCol?: React.ReactNode;
  rightColSx?: SystemStyleObject<Theme>;
  renderRightCol?: (props: {
    rightCol: React.ReactNode;
    rightColSx?: SystemStyleObject<Theme>;
  }) => React.ReactNode;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  height?: number;
  isCompact?: boolean;
  tabs?: React.ReactNode;
  sx?: SystemStyleObject<Theme>;
  documentTitle?: string;
}

const PageHeader = (props: PageHeaderProps) => {
  const {
    heading,
    maxWidth,
    rightCol,
    isCompact,
    tabs,
    rightColSx,
    renderRightCol,
    documentTitle,
  } = props;
  const renderedTitle = heading ? <PageTitle {...heading} /> : null;
  const renderedRightCol = rightCol ? (
    renderRightCol ? (
      renderRightCol({ rightCol, rightColSx })
    ) : (
      <Box sx={mixinSx({ justifySelf: 'end' }, rightColSx)}>{rightCol}</Box>
    )
  ) : null;
  useDocumentTitle(documentTitle ?? heading?.title);
  if (maxWidth) {
    return (
      <Container
        disableGutters
        maxWidth={maxWidth}
        component="header"
        sx={mixinSx(
          rootSx,
          containerSx,
          isCompact ? containerCompactSx : {},
          { height: props.height },
          props.sx
        )}
      >
        {renderedTitle}
        {renderedRightCol}
      </Container>
    );
  } else {
    return (
      <>
        <Box
          component="header"
          sx={mixinSx(
            rootSx,
            fullWidthSx,
            isCompact ? containerCompactSx : {},
            tabs ? headerWithTabsSx : {},
            { height: props.height },
            props.sx
          )}
        >
          {renderedTitle}
          {renderedRightCol}
        </Box>
        {tabs && <Box sx={tabsSx}>{tabs}</Box>}
      </>
    );
  }
};

export default PageHeader;
