import { SxProps, Theme } from '@mui/material';
import { mixinSx, sxType } from '@watershed/style/styleUtils';
import { transition } from '../utils';

export const SIDEBAR_BREAKPOINT_WIDTH = 1420;
export const SIDEBAR_EXPANDED_WIDTH = 260;
export const SIDEBAR_COLLAPSED_WIDTH = 48;

export const SKIP_NAV_LINK_CLASS = 'skip-nav-link';

export const getSharedSidebarStyles = (theme: Theme) => {
  const COLORS = {
    BACKGROUND: theme.palette.background.dark,
    BACKGROUND_ACTIVE: theme.palette.grey80,
    SCROLLBAR: theme.palette.grey30,
    TEXT_PRIMARY: theme.palette.white,
    TEXT_SECONDARY: theme.palette.grey30,
    TEXT_DISABLED: theme.palette.grey50,
    FOCUS: theme.palette.cobalt40,
  };
  const BOX_SHADOW_FOCUS = `0 0 0 2px ${COLORS.FOCUS}`;

  // See https://github.com/reach/reach-ui/blob/main/packages/skip-nav/styles.css for reference.
  const skipNavLinkSxProps: SxProps<Theme> = (theme) => ({
    [`& .${SKIP_NAV_LINK_CLASS}`]: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: '1px',
      width: '1px',
      margin: '-1px',
      padding: 0,
      overflow: 'hidden',
      position: 'absolute',
      color: theme.palette.cobalt40,
      '&:focus': {
        padding: theme.spacing(1, 2),
        position: 'fixed',
        zIndex: 99999,
        top: theme.spacing(1),
        left: theme.spacing(1),
        width: 'auto',
        height: 'auto',
        clip: 'auto',
        textDecoration: 'none',
        outline: 'none',
        borderRadius: 1,
        backgroundColor: COLORS.BACKGROUND,
        boxShadow: BOX_SHADOW_FOCUS,
      },
    },
  });

  return {
    COLORS,
    BOX_SHADOW_FOCUS,

    rootSxProps: sxType({
      zIndex: 50,
      gridColumn: 1,
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      colorScheme: 'dark',
      backgroundColor: COLORS.BACKGROUND,
      overflow: 'hidden',
      transition: transition('width', 'box-shadow'),
      '@media print': { display: 'none' },
    }),

    logoBarSxProps: mixinSx(
      {
        padding: 0.5,
        height: 56,
        paddingInlineEnd: 2,
        display: 'flex',
        alignItems: 'center',
        transition: transition('translate'),
      },
      skipNavLinkSxProps
    ),

    collapseButtonSxProps: sxType((theme) => ({
      marginLeft: 'auto',
      color: COLORS.TEXT_PRIMARY,
      transition: transition('opacity'),
      position: 'relative',
      right: -4,
      padding: '4px',
      '&:focus': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      '&:focus-visible': {
        boxShadow: BOX_SHADOW_FOCUS,
      },
    })),

    iconButtonSxProps: sxType((theme) => ({
      marginInlineStart: 'auto',
      minWidth: 'unset',
      width: '24px',
      height: '24px',
      aspectRatio: '1 / 1',
      color: COLORS.TEXT_PRIMARY,
      transition: (theme) =>
        theme.transitions.create(['opacity', 'background-color'], {
          duration: theme.transitions.duration.short,
        }),
      '&:hover, &:focus': {
        backgroundColor: (theme) => theme.palette.grey80,
      },
      'a &:hover, a &:focus-visible': {
        backgroundColor: (theme) => theme.palette.grey70,
      },
      '&:focus-visible': {
        boxShadow: BOX_SHADOW_FOCUS,
      },
    })),

    menuPaperSxProps: sxType({
      transition:
        transition('opacity', 'translate', 'transform') + ' !important',
      width: 228,
      backgroundColor: COLORS.BACKGROUND,
      border: 0,
      padding: 0.5,
      marginInlineStart: 2,
      marginBlockStart: -1.5,
      // When nav appears collapsed, menu should always hug nav
      // Using translate because transform already handles a scale animation
      // and we don’t want them to conflict
      '&[data-appears-collapsed="true"]': {
        translate: `-${SIDEBAR_EXPANDED_WIDTH - SIDEBAR_COLLAPSED_WIDTH}px 0`,
      },
      '& .MuiMenuItem-root': {
        color: (theme) => COLORS.TEXT_PRIMARY,
        gap: 1,
        border: 0,
        paddingBlock: 1,
        paddingInline: 1.5,
        borderRadius: 1,
        '&:hover, &:focus, &:active': {
          backgroundColor: (theme) => theme.palette.grey80,
        },
        '& svg': {
          color: COLORS.TEXT_SECONDARY,
          width: 16,
          height: 16,
        },
        '& span': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },
    }),
  };
};
