import {
  GQCancelFootprintReviewRequestMutation,
  GQFootprintsForFootprintSelectorQuery,
} from '@watershed/shared-universal/generated/graphql';

export function mergeCancelWatershedFootprintReviewRequestIntoFootprintSelector(
  data: GQFootprintsForFootprintSelectorQuery | null,
  payload: GQCancelFootprintReviewRequestMutation['deleteWatershedFootprintReviewRequest']
): GQFootprintsForFootprintSelectorQuery | null {
  if (data === null) {
    return null;
  }

  const updatedFootprints = data.footprints.edges.map((edge) => {
    if (!edge?.node) {
      return edge;
    }

    const updatedVersions = edge.node.footprintVersions.map((version) => {
      if (version.id === payload.footprintVersion.id) {
        return {
          ...version,
          watershedFootprintReviewRequest: null,
        };
      }
      return version;
    });

    return {
      ...edge,
      node: {
        ...edge.node,
        footprintVersions: updatedVersions,
      },
    };
  });

  return {
    ...data,
    footprints: {
      ...data.footprints,
      edges: updatedFootprints,
    },
  };
}
