import minBy from 'lodash/minBy';
import {
  GQAggregateKind,
  GQBusinessCategoryForForecastingFragment,
  GQFilterFieldWatershed,
  GQGrowthFactorType,
  GQPlanTargetIntensityType,
} from '../generated/graphql';
import { ReductionsContributions } from '../reductions/contributionsUtils';
import must from '../utils/must';
import { YM, YMInterval, YearMonth } from '../utils/YearMonth';
import { BusinessMetrics, BusinessMetricsRow } from './BusinessMetrics';
import { IntensityDenominatorKind } from '../utils/intensityDenominators';
import { TimeseriesKindIntensity } from './ForecastX';
import {
  GrossProfitGrowthForecastConfig,
  GrowthForecastConfig,
  NightsStayedGrowthForecastConfig,
  OrdersGrowthForecastConfig,
  PatientsGrowthForecastConfig,
} from './GrowthForecastConfig';
import assertNever from '@watershed/shared-util/assertNever';
import { BadInputError } from '@watershed/errors/BadInputError';
import { GrowthFactorIdentifier } from './GrowthFactorIdentifier';
import {
  BusinessCategory,
  isBusinessCategory,
} from '../utils/BusinessCategory';

export type TimeseriesIntensityType = Exclude<
  GQPlanTargetIntensityType,
  'SupplierEngagement' | 'SupplierEngagementBySpend' | 'RenewableElectricity'
>;

/**
 * This function makes sure that we have a continuous year of headcount
 * or revenue data on the reference period.
 */
export function hasGrowthFactorDataInForecastReferencePeriod(
  contributions: ReductionsContributions,
  forecastReferencePeriod: YMInterval
): boolean {
  const values = new Array<BusinessMetricsRow>();
  const actuals = Array.from(contributions.monthInfos.values()).map((v) => {
    return {
      date: YM.fromJSDate(v.date),
      headcount: v.headcount,
      revenue: v.revenue,
    };
  });

  const actualsStart = must(minBy(actuals, (a) => a.date)).date;

  actuals.forEach((actual) => {
    const offset = YM.diff(actual.date, actualsStart);
    // values is an array where the index represents how many months between
    // the start and current month
    values[offset] = {
      growthFactors: {
        [GQGrowthFactorType.Revenue]: actual.revenue,
        [GQGrowthFactorType.Headcount]: actual.headcount,
      },
      forecasted: false,
    };
  });
  let hasAllRevenueData = true;
  let hasAllHeadcountData = true;

  for (const month of forecastReferencePeriod.iter('month')) {
    const offset = YM.diff(month, actualsStart);
    const refPeriodMonthData: BusinessMetricsRow | undefined = values[offset];
    hasAllRevenueData =
      !!refPeriodMonthData?.growthFactors[GQGrowthFactorType.Revenue] &&
      hasAllRevenueData;
    hasAllHeadcountData =
      !!refPeriodMonthData?.growthFactors[GQGrowthFactorType.Headcount] &&
      hasAllHeadcountData;
  }
  return hasAllHeadcountData || hasAllRevenueData;
}

export function getProhibitedGrowthFactors(
  businessMetrics: BusinessMetrics,
  referencePeriodInterval: YMInterval
): Array<GrowthFactorIdentifier> {
  return businessMetrics.growthFactorsPresentForHistoricalInterval({
    interval: referencePeriodInterval,
  }).notCompletelyPresent;
}

// The visible interval in reductions must contain both the forecast reference
// period and the baseline year
export function calculateVisibleIntervalStart(
  forecastReferencePeriod: YMInterval,
  baselineYearStart: YearMonth
): YearMonth {
  return YM.min(forecastReferencePeriod.start, baselineYearStart);
}

// Create a special error class so we can just catch missing intensity errors
export class MissingIntensityError extends Error {}

/**
 * A filterable footprint field is a field that
 * - has a string or nullable string type (custom tags)
 * - overlaps with GQFilterFieldLegacy, the type we use to compose
 *   footprint filters
 */
export type FilterableFootprintContributionsFields =
  | GQFilterFieldWatershed
  | string;

export type SelectedBoundary = Map<
  FilterableFootprintContributionsFields,
  Array<string>
>;
export type PotentialBoundaries = Map<
  FilterableFootprintContributionsFields,
  Set<string>
>;

export const ALLOWED_BOUNDARY_FIELDS = [
  GQFilterFieldWatershed.CategoryId,
  GQFilterFieldWatershed.SubcategoryId,
  GQFilterFieldWatershed.Location,
  GQFilterFieldWatershed.LocationCountry,
  GQFilterFieldWatershed.Vendor,
  GQFilterFieldWatershed.Product,
  GQFilterFieldWatershed.BuildingName,
  GQFilterFieldWatershed.ElectricityType,
  GQFilterFieldWatershed.Description,
] as const;

const FILTER_FIELD_WATERSHED_TO_LABEL: Record<string, string> = {
  [GQFilterFieldWatershed.CategoryId]: 'Category',
  [GQFilterFieldWatershed.SubcategoryId]: 'Subcategory',
  [GQFilterFieldWatershed.LocationCountry]: 'Country',
  [GQFilterFieldWatershed.LocationCity]: 'City',
  [GQFilterFieldWatershed.LocationState]: 'State',
  [GQFilterFieldWatershed.Vendor]: 'Supplier',
  [GQFilterFieldWatershed.BuildingName]: 'Building name',
  [GQFilterFieldWatershed.CompanyId]: 'Company ID',
  [GQFilterFieldWatershed.Description]: 'Description',
  [GQFilterFieldWatershed.ElectricityType]: 'Electricity type',
  [GQFilterFieldWatershed.FootprintKind]: 'Footprint kind',
  [GQFilterFieldWatershed.GhgCategoryId]: 'GHG Category',
  [GQFilterFieldWatershed.GhgScope]: 'GHG scope',
  [GQFilterFieldWatershed.InputUnit]: 'Input unit',
  [GQFilterFieldWatershed.Location]: 'Location',
  [GQFilterFieldWatershed.IsSupplierSpecific]: 'Is supplier specific',
  [GQFilterFieldWatershed.Product]: 'Product',
};

/**
 * Returns a label to be used in the UI for a given filter field. If the field
 * is not covered by FILTER_FIELD_WATERSHED_TO_LABEL (for example, custom tags),
 * the simply return the field value as-is.
 */
export function getLabelForFilterField(
  field: FilterableFootprintContributionsFields
): string {
  const label = FILTER_FIELD_WATERSHED_TO_LABEL[field];
  if (label) {
    return label;
  }
  return field;
}

// Boundaries for target and initiatives cannot include ghg scope and category id
// because those are first class.
export function isFilterFieldValidBoundary(
  field: FilterableFootprintContributionsFields
): boolean {
  return field !== 'ghgCategoryId' && field !== 'ghgScope';
}

/**
 * TODO: We really need to consolidate intensity type, intensity kind, and aggregate kind
 * They all serve slightly different functions eg
 * - whether you can forecast on this,
 * - whether you can aggregate with this,
 * - whether you can set a target on this,
 * - whether you can set a SBT target on this,
 * but they should share the same types at least!
 */
export const INTENSITY_TYPE_TO_INTENSITY_KIND: Record<
  Exclude<
    GQPlanTargetIntensityType,
    | 'Absolute'
    | 'SupplierEngagement'
    | 'SupplierEngagementBySpend'
    | 'RenewableElectricity'
    | 'Custom'
  >,
  IntensityDenominatorKind
> = {
  [GQPlanTargetIntensityType.Headcount]: 'headcount',
  [GQPlanTargetIntensityType.Revenue]: 'revenue',
  [GQPlanTargetIntensityType.GrossProfit]: 'gross_profit',
  [GQPlanTargetIntensityType.NightsStayed]: 'nights_stayed',
  [GQPlanTargetIntensityType.Patients]: 'patients',
  [GQPlanTargetIntensityType.Orders]: 'orders',
};

export function timeseriesKindIntensityFromType(
  intensityType: TimeseriesIntensityType,
  config: GrowthForecastConfig | null
): TimeseriesKindIntensity {
  switch (intensityType) {
    case GQPlanTargetIntensityType.Absolute:
      return {
        kind: GQAggregateKind.Total,
      };
    case GQPlanTargetIntensityType.Headcount:
      return {
        kind: GQAggregateKind.HeadcountIntensity,
      };
    case GQPlanTargetIntensityType.Revenue:
      return {
        kind: GQAggregateKind.RevenueIntensity,
      };
    case GQPlanTargetIntensityType.GrossProfit:
      return {
        kind: GQAggregateKind.CustomIntensity,
        customIntensityConfig: GrossProfitGrowthForecastConfig,
      };
    case GQPlanTargetIntensityType.NightsStayed:
      return {
        kind: GQAggregateKind.CustomIntensity,
        customIntensityConfig: NightsStayedGrowthForecastConfig,
      };
    case GQPlanTargetIntensityType.Orders:
      return {
        kind: GQAggregateKind.CustomIntensity,
        customIntensityConfig: OrdersGrowthForecastConfig,
      };
    case GQPlanTargetIntensityType.Patients:
      return {
        kind: GQAggregateKind.CustomIntensity,
        customIntensityConfig: PatientsGrowthForecastConfig,
      };
    case GQPlanTargetIntensityType.Custom:
      BadInputError.invariant(
        config !== null,
        'Must provide a config for custom intensities'
      );
      return {
        kind: GQAggregateKind.CustomIntensity,
        customIntensityConfig: config,
      };
    default:
      assertNever(intensityType);
  }
}

export function categorizedEmissionsToBusinessCategories(
  categorizedEmissions: Array<GQBusinessCategoryForForecastingFragment>
): Array<BusinessCategory> {
  const businessCategories: Array<BusinessCategory> = [];
  for (const { businessCategory } of categorizedEmissions) {
    if (isBusinessCategory(businessCategory)) {
      businessCategories.push(businessCategory);
    } else {
      throw new BadInputError(
        `Found invalid business category ${businessCategory}`
      );
    }
  }
  return businessCategories;
}
