import Icon, { IconProps } from '../Icon';
const SidebarRightIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M19 2H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3Zm0 18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-3v16h3Zm-5 0V4H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h9Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SidebarRightIcon;
