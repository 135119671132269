import React from 'react';
import { Trans } from '@lingui/react/macro';
import { Dialog, DialogHeaderProps, DialogProps } from './Dialog';
import Button from './Button';
import { createDialogHook } from '../hooks/useDialog';

/**
 * Open a dialog to make sure the user acknowledges a message.
 */
export const useDialogAlert = createDialogHook(DialogAlert);

export default function DialogAlert({
  title,
  header,
  onClose,
  children,
  maxWidth,
  actions,
}: {
  // Keep title separate from header for backwards-compat and as a convenient shorthand
  title: DialogHeaderProps['title'];
  header?: Omit<DialogHeaderProps, 'title'>;
  onClose: () => void;
  children: React.ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  actions?: Array<React.ReactNode>;
}) {
  return (
    <Dialog
      header={{ ...header, title }}
      onClose={onClose}
      maxWidth={maxWidth ?? 'sm'}
      actions={
        actions ?? [
          <Button key="ok" color="primary" onClick={onClose}>
            <Trans context="button copy">OK</Trans>
          </Button>,
        ]
      }
    >
      {children}
    </Dialog>
  );
}
