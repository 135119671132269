import React from 'react';
import WatershedI18nProvider from '@watershed/intl/frontend/WatershedI18nProvider';
import { useRouter } from 'next/router';
import ErrorPage from '../components/ErrorPage';
import loadI18nMessages from '../utils/loadI18nMessages';

/**
 * Wrapper around WatershedI18nProvider with Dashboard-specific
 * message loading and error boundary fallback behaviour
 */
export default function DashboardWatershedI18nProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { pathname } = useRouter();

  return (
    <WatershedI18nProvider
      FallbackComponent={ErrorPage}
      loadMessages={loadI18nMessages}
      extraLoadMessagesArgs={[pathname]}
    >
      {children}
    </WatershedI18nProvider>
  );
}
