import {
  GQTargetUnit,
  GQTargetKind,
  GQTarget,
  GQPlan,
  GQCommonPlanTargetFieldsFragment,
  GQPlanTargetTargetComparisonType,
  GQSupplierDisclosureTargetFieldsFragment,
  GQPlanTargetIntensityType,
} from '../generated/graphql';
import { formatNumber, formatPercentageNonzero } from './helpers';
import { getPlanNetZeroYear } from './PlanUtils';

export type DisclosureTargetForSupplierChart = Pick<
  GQSupplierDisclosureTargetFieldsFragment,
  'intensityType' | 'description' | 'emissionsTarget' | 'filters' | 'baseYear'
>;
interface TargetInfo {
  kind: GQTargetKind;
  title: string;
  amount: string;
  descriptor: string | null;
}

function displayAmount(amount: number, unit: GQTargetUnit): string {
  switch (unit) {
    case GQTargetUnit.Percent:
      const prefix = amount < 0 ? '↓' : '↑';
      amount = amount < 0 ? amount * -1 : amount;
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return `${prefix}${formatPercentageNonzero(amount / 100)}`;
    case GQTargetUnit.PercentAbsolute:
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return formatPercentageNonzero(amount / 100);
    case GQTargetUnit.Tco2E:
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return `${formatNumber(amount)}`;
    case GQTargetUnit.Year:
      return amount.toString();
  }
}

export function parseTargets(
  targets: Array<
    Pick<GQTarget, 'kind' | 'title' | 'targetAmount' | 'unit' | 'descriptor'>
  >,
  plan: Pick<GQPlan, 'variables'> | undefined
): Array<TargetInfo> {
  let targetInfos = targets.map((t) => {
    return {
      kind: t.kind,
      title: t.title,
      amount: displayAmount(t.targetAmount, t.unit),
      descriptor: t.descriptor,
    };
  });

  // The reduction target and net zero commitment from a published plan
  // replaces any hard-coded targets.
  if (plan) {
    const planNetZeroYear = getPlanNetZeroYear(plan);
    if (planNetZeroYear !== undefined) {
      // Replace hard-coded net zero year with year from plan.
      targetInfos = targetInfos.filter(
        (targetInfo) => targetInfo.kind !== GQTargetKind.NetZero
      );
      targetInfos.push({
        kind: GQTargetKind.NetZero,
        title: 'Net zero by',
        amount: displayAmount(planNetZeroYear, GQTargetUnit.Year),
        descriptor: null,
      });
    }
  }

  return targetInfos;
}
export function supplierDisclosureTargetToPlanTarget(
  target: DisclosureTargetForSupplierChart
): Pick<
  GQCommonPlanTargetFieldsFragment,
  | 'filters'
  | 'intensityType'
  | 'customIntensityConfigId'
  | 'comparisonType'
  | 'emissionsTargetCustom'
  | 'baseYear'
  | 'interimTargetValue'
> {
  return {
    filters: target.filters,
    intensityType:
      // https://watershedclimate.slack.com/archives/C04464Y3RJS/p1711610079558179
      target.intensityType in GQPlanTargetIntensityType
        ? (target.intensityType as GQPlanTargetIntensityType)
        : GQPlanTargetIntensityType.Custom,
    // Custom intensities are not supported for supplier disclosure targets.
    customIntensityConfigId: null,
    // Supplier targets are currently all base year targets
    comparisonType: GQPlanTargetTargetComparisonType.BaseYear,
    emissionsTargetCustom: target.emissionsTarget,
    baseYear: target.baseYear,
    interimTargetValue: null,
  };
}
