import otaClient from '@crowdin/ota-client';
import surfaceGlobs from '../.surfaces.json';
import { Messages } from '@lingui/core';
import assertNever from '@watershed/shared-util/assertNever';
import invariant from 'invariant';
import { LocalizationEnv, SupportedLocale } from '@watershed/intl/constants';
import { minimatch } from 'minimatch';

export default async function loadI18nMessages(
  locale: SupportedLocale,
  env: LocalizationEnv,
  pathname: string
) {
  const surface = getSurface(surfaceGlobs, pathname);
  console.info(
    `Loading ${env} translations for ${surface} at pathname ${pathname}`
  );
  switch (env) {
    case 'production':
      // IMPORTANT: There are issues with Next's treeshaking of dynamic
      // expressions in imports, so we can't destructure "messages" inline here.
      const bundles = await Promise.all([
        import(`../generated/locales/${locale}/global/messages`),
        import(`../generated/locales/${locale}/${surface}/messages`),
      ]);

      return bundles.reduce(
        (acc, { messages }) => ({ ...acc, ...messages }),
        {} as Messages
      );
    case 'test':
      invariant(
        process.env.NEXT_PUBLIC_CROWDIN_DISTRIBUTION_ID,
        'Missing CrowdIn distribution ID'
      );
      const client = new otaClient(
        process.env.NEXT_PUBLIC_CROWDIN_DISTRIBUTION_ID
      );
      const otaStrings = await client.getStringsByLocale(locale.slice(0, 2));
      return otaStrings as Messages;
    default:
      assertNever(env);
  }
}

export function getSurface(
  globs: Record<string, Array<string>>,
  pathname: string
) {
  // Find the surface that matches the location
  const match = Object.entries(globs).find(([, patterns]) => {
    return patterns.some((pattern) => {
      // Transform pattern to equivalent URL:
      // - Prefix with /
      // - strip off any file extension
      // - convert index to ''

      // Prefix with /
      pattern = '/' + pattern;
      // Remove file extension
      pattern = pattern.replace(/\.[^/.]+$/, '');
      // Convert index to '/'
      pattern = pattern.replace(/index$/, '');
      // Remove trailing slash
      pattern = pattern.replace(/\/$/, '');

      return minimatch(pathname, pattern);
    });
  });

  return match ? match[0] : 'unassigned';
}
