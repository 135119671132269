// TODO: Need to better unify this with the existing FinanceFiltersButton.

import { Box, Stack, SxProps, Theme, Tooltip } from '@mui/material';
import Skeleton from '@watershed/ui-core/components/Skeleton';
import AddIcon from '@watershed/icons/components/Add';
import {
  FinanceFiltersType,
  financeFilterOptionSchema,
} from '@watershed/shared-universal/apiSchemas/finance/zodFinance';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import omit from 'lodash/omit';
import React from 'react';
import { useFinanceSnapshotsContext } from '../../utils/finance/FinanceSnapshotsContext';
import Button from '@watershed/ui-core/components/Button';
import uniq from 'lodash/uniq';
import { getFinanceFieldLabel } from '@watershed/shared-universal/fund/financeFieldRegistry';
import { useFinanceFiltersContext } from '../../utils/finance/useFinanceFilters';
import { mixinSx } from '@watershed/style/styleUtils';
import { FilterRow } from '../FilterRow';
import { useFinanceSnapshotFilterOptionsQuery } from '@watershed/shared-frontend/generated/urql';
import sortBy from 'lodash/sortBy';
import {
  GQFinanceTagType,
  GQFlags,
} from '@watershed/shared-universal/generated/graphql';
import { isFetchingOrDataBang } from '@watershed/shared-frontend/utils/isFetchingOrStale';
import { useFeatureFlag } from '../../utils/FeatureFlag';

function getTagTypeLabel(
  tagType: GQFinanceTagType,
  { shouldRenameFund }: { shouldRenameFund: boolean }
): string {
  switch (tagType) {
    case GQFinanceTagType.FundTag:
      return shouldRenameFund ? 'Line of business' : 'Fund';
    case GQFinanceTagType.AssetTag:
      return 'Asset';
    case GQFinanceTagType.AssetYearTag:
      return 'Annual data';
    case GQFinanceTagType.HoldingTag:
      return 'Holding';
  }
}

const getFinanceFieldLabelWithTagLabel = (
  field: string,
  filterOptions: Record<string, { label: string; tagType?: GQFinanceTagType }>,
  { shouldRenameFund }: { shouldRenameFund: boolean }
): string => {
  const option = filterOptions[field];
  return (
    getFinanceFieldLabel(field, { filterOptions, shouldRenameFund }) +
    (option?.tagType
      ? ` (${getTagTypeLabel(option.tagType, { shouldRenameFund })} column)`
      : '')
  );
};

export function FinanceFilterSelector({
  onChange,
  value,
  lockedFilters,
  fullyLockedFilterFields,
  sx,
  addButtonSx,
}: {
  onChange: (
    value: Array<{ field: string; value?: Array<string> | null }>
  ) => void;
  lockedFilters: FinanceFiltersType;
  value: Array<{ field: string; value?: Array<string> | null }>;
  fullyLockedFilterFields: Array<string>;
  sx?: SxProps<Theme>;
  addButtonSx?: SxProps<Theme>;
}) {
  const [newFilter, setNewFilter] = React.useState<boolean>(true);
  const { combinedFilters } = useFinanceFiltersContext();
  const { financeSnapshot } = useFinanceSnapshotsContext();

  const shouldRenameFund = useFeatureFlag(GQFlags.AssetManagerRenameFunds);

  const filterLoading = (
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
    <Tooltip title="Loading snapshot…">
      {/* this box isn't extra - it's required to get the tooltip to display */}
      <Box width="100%">
        <FilterContent sx={sx} addButtonSx={addButtonSx}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 36px 1fr 28px',
            }}
          >
            <Skeleton variant="rectangular" height={36} />
            <div />
            <Skeleton variant="rectangular" height={36} />
            <div />
          </Box>
        </FilterContent>
      </Box>
    </Tooltip>
  );

  // Don't fetch data for a snapshot currently being generated
  const pause = !financeSnapshot?.completedAt;

  const [filterState] = useFinanceSnapshotFilterOptionsQuery({
    pause,
    variables: {
      financeSnapshotId: financeSnapshot?.id ?? 'paused if i do not exist',
      filters: combinedFilters,
    },
  });

  if (pause) {
    return filterLoading;
  }

  const { fetching, paused, data } = isFetchingOrDataBang(filterState);

  if (fetching || paused) {
    return filterLoading;
  }
  const filterOptionsForSnapshot = financeFilterOptionSchema.parse(
    data.financeSnapshotFilterOptions
  );

  const filterOptions = new Map<string, Array<{ id: string; label: string }>>();

  Object.entries(omit(filterOptionsForSnapshot, 'year')).map(([key, value]) => {
    filterOptions.set(
      key,
      sortBy(value.options, (o) => o.label)
    );
  });

  const handleDeleteFilter = async (index: number) => {
    if (index === value.length) {
      setNewFilter(false);
    } else {
      const newValue = value.filter((_, i) => i !== index);

      onChange(newValue);
    }
  };

  const handleAddFilter = () => {
    setNewFilter(true);
  };

  const onValueChange = (
    index: number,
    newValue: Array<string>,
    lockedFilters: Array<string>
  ): void => {
    const newValues = [
      ...value.slice(0, index),
      {
        field: value[index].field,
        value: uniq([...lockedFilters, ...newValue]),
      },
      ...value.slice(index + 1),
    ];
    onChange(newValues);
  };

  const onFilterFieldChange = async (index: number, newField: string) => {
    if (index === value.length) {
      setNewFilter(false);
      onChange([...value, { field: newField, value: [] }]);
    } else {
      const newValues = [
        ...value.slice(0, index),
        { field: newField, value: [] },
        ...value.slice(index + 1),
      ];
      onChange(newValues);
    }
  };

  const filters = value
    .map(({ field, value }): Filter | null => {
      const currentFilter = value?.map((v) => ({
        id: v,
        label: filterOptions.get(field)?.find((fo) => fo.id === v)?.label ?? v,
      }));

      if (!currentFilter) {
        return null;
      }

      return {
        field: field as keyof FinanceFiltersType | '',
        value: currentFilter,
      };
    })
    .filter(isNotNullish);

  if (newFilter) {
    filters.push({
      field: '',
      value: [],
    });
  }

  return (
    <FilterContent
      onClickAdd={newFilter ? undefined : handleAddFilter}
      sx={sx}
      addButtonSx={addButtonSx}
    >
      {filters.map((filter, index) => (
        <FilterRow
          key={filter.field}
          index={index}
          field={filter.field}
          value={filter.value}
          lockedFilters={
            (filter.field !== '' ? lockedFilters[filter.field] : null) ?? null
          }
          fullyLocked={fullyLockedFilterFields.includes(filter.field)}
          filterOptions={filterOptions}
          existingFilterFields={filters.map((f) => f.field)}
          onValueChange={onValueChange}
          onFilterFieldChange={onFilterFieldChange}
          onDeleteFilter={handleDeleteFilter}
          getFieldLabel={(field: string) =>
            getFinanceFieldLabelWithTagLabel(field, filterOptionsForSnapshot, {
              shouldRenameFund,
            })
          }
        />
      ))}
    </FilterContent>
  );
}

type Filter = {
  field: string;
  value: Array<{ id: string; label: string }>;
};

function FilterContent({
  onClickAdd,
  sx,
  addButtonSx,
  children,
}: React.PropsWithChildren<{
  onClickAdd?: () => void;
  sx?: SxProps<Theme>;
  addButtonSx?: SxProps<Theme>;
}>) {
  return (
    <Stack width="100%">
      <Stack
        sx={mixinSx(
          {
            gap: 2,
            paddingX: 1,
            paddingTop: 1,
          },
          sx
        )}
      >
        {children}
      </Stack>
      <Stack direction="row" gap={1}>
        <Button
          disabled={!onClickAdd}
          variant="text"
          startIcon={<AddIcon />}
          onClick={onClickAdd}
          sx={mixinSx({ ml: 1, my: 1 }, addButtonSx)}
        >
          {/* // TODO: i18n (please resolve or remove this TODO line if legit) //  */}
          {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready  */}
          Add filter
        </Button>
      </Stack>
    </Stack>
  );
}
