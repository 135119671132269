import { Box, Stack, SxProps } from '@mui/material';
import { createTsFormAndFragment } from 'zod-form';
import {
  RTFFormProps,
  PropType,
  FormComponentMapping,
} from 'zod-form/lib/src/createSchemaForm';

import ProgressButton, {
  ProgressButtonProps,
} from '@watershed/ui-core/components/ProgressButton';
import { ComponentType, ReactNode } from 'react';
import * as z from 'zod';
/* need to import react-hook-form for typescript to infer the type of ZodForm below */
import 'react-hook-form';
import { useFormState } from 'react-hook-form';
import { Concat } from 'typescript-tuple';
import {
  PartialSomeProperties,
  ShallowWritable,
} from '@watershed/shared-universal/utils/utilTypes';
import { mixinSx } from '@watershed/style/styleUtils';
import { Theme } from '@mui/system';

import React from 'react';
import {
  DATE_RANGE_SCHEMA,
  ZodDateField,
  ZodDateRangeField,
} from './ZodDateFields';
import { ZodTextArrayField, ZodTextField } from './ZodTextField';
import { ZodNumberArrayField, ZodNumberField } from './ZodNumberField';
import { ZodBooleanField } from './ZodBooleanField';
import { ZodEnumArrayField, ZodEnumField } from './ZodSelectFields';
import { ZodFormSchemaType } from './types';
import {
  ymIntervalArray,
  ymIntervalSchema,
} from '@watershed/shared-universal/zodSchemas/utilSchemas';
import { CommonZodFieldProps } from './fieldUtils';

export type ZodFieldContextType = {
  inline?: boolean;
  disabled?: boolean;
};

const ZodFieldContext = React.createContext<ZodFieldContextType>({});

/**
 * This HOC lets use add functionality to every zod field. for example we can
 * add a hidden prop to every field.
 */
export function makeZodField<P>(Component: ComponentType<P>) {
  function ZodFieldWrapper({ hidden, ...props }: P & { hidden?: boolean }) {
    const context = React.useContext(ZodFieldContext);
    return hidden ? <></> : <Component {...context} {...(props as P)} />;
  }
  return ZodFieldWrapper;
}

function Stub(props: CommonZodFieldProps) {
  return null;
}

const mapping = [
  [z.string(), makeZodField(ZodTextField)],
  [z.string().refine(() => {}), makeZodField(ZodTextField)],
  [z.number(), makeZodField(ZodNumberField)],
  [z.boolean(), makeZodField(ZodBooleanField)],
  [z.enum(['placeholder']), makeZodField(ZodEnumField)],
  [z.array(z.enum(['placeholder'])), makeZodField(ZodEnumArrayField)],
  [z.array(z.string()), makeZodField(ZodTextArrayField)],
  [z.array(z.number()), makeZodField(ZodNumberArrayField)],
  // note: zod-form cares not about optional or nullable when checking for a
  // mapping so this is our top level mapping for every combination of optional
  // and required date ranges
  [DATE_RANGE_SCHEMA, makeZodField(ZodDateRangeField)],
  [z.date(), makeZodField(ZodDateField)],
  // It will be hard (maybe impossible?) to properly support YMInterval because
  // the `.transform()` in `ymIntervalSchema` breaks `zod-form` mapping. So for
  // now, recommendation is to just manually handle YMInterval form fields.
  // Needs to be fixed upstream in `zod-form` probably.
  [ymIntervalSchema, makeZodField(Stub)],
  // same as above for the non array schema
  [ymIntervalArray, makeZodField(Stub)],
] as const; // 👈 `as const` is necessary

function ZodTsCustomFormComponent({
  children,
  onSubmit,
  submitButtonProps,
  extraButtons,
  sx,
  noButtons,
  formId,
  disableIfDirty = true,
  ...formContext
}: {
  children: ReactNode;
  onSubmit: () => void;
  submitButtonProps?: PartialSomeProperties<
    ProgressButtonProps,
    'isInProgress'
  > & {
    children?: ReactNode;
  };
  extraButtons?: ReactNode;
  sx?: SxProps<Theme>;
  noButtons?: boolean;
  formId?: string;
  disableIfDirty?: boolean;
} & ZodFieldContextType) {
  const { isSubmitting, isDirty } = useFormState();
  const buttons = (
    <>
      {extraButtons}
      <ProgressButton
        type="submit"
        data-test={`ZodForm-submit-${formId}`}
        form={formId}
        isInProgress={isSubmitting}
        disabled={
          isSubmitting ||
          submitButtonProps?.disabled ||
          (disableIfDirty && !isDirty)
        }
        {...submitButtonProps}
      >
        {submitButtonProps?.children === undefined
          ? 'Submit'
          : // Allow passing through `null`
            submitButtonProps?.children}
      </ProgressButton>
    </>
  );

  return (
    <ZodFieldContext.Provider value={formContext}>
      <form onSubmit={onSubmit} id={formId} data-test={`ZodForm-${formId}`}>
        <Box
          sx={mixinSx(
            {
              rowGap: 3,
              display: 'grid',
              gridTemplateColumns: '1fr',
              gridTemplateRows: '1fr',
            },
            sx
          )}
        >
          {children}
          {noButtons ? null : (
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap={1}
              // if grid styling is applied to the form, this will make the buttons span the full width
              sx={{ gridColumn: '1/-1' }}
            >
              {buttons}
            </Stack>
          )}
        </Box>
      </form>
    </ZodFieldContext.Provider>
  );
}

/**
 * This function allows creating a zod form with extra component mappings for custom components
 */
export function makeZodForm<Mapping extends FormComponentMapping>(
  customMapping: Mapping
) {
  const combined: Concat<
    ShallowWritable<typeof mapping>,
    ShallowWritable<typeof customMapping>
  > = [...mapping, ...customMapping];
  return createTsFormAndFragment(combined, {
    FormComponent: ZodTsCustomFormComponent,
  });
}

export type ZodFormMapping = typeof mapping;

const [ZodForm, ZodFormFragment, ZodFormFragmentField] =
  createTsFormAndFragment(mapping, {
    FormComponent: ZodTsCustomFormComponent,
  });

export { ZodForm, ZodFormFragment, ZodFormFragmentField };

export type ZodTypeForSatisifies<T> = z.ZodType<T, any, any>;
export type ZodTypeForGqlInput<T extends { id: string }> = ZodTypeForSatisifies<
  Omit<T, 'id'>
>;

type defaultPropMap = readonly [
  readonly ['name', 'name'],
  readonly ['control', 'control'],
  readonly ['enumValues', 'enumValues'],
];

export type WsZodFormProps<SchemaType extends ZodFormSchemaType> = RTFFormProps<
  ZodFormMapping,
  SchemaType,
  defaultPropMap,
  typeof ZodTsCustomFormComponent
>;

export type ZodFormDefaultMappedProps<S extends ZodFormSchemaType> = PropType<
  ZodFormMapping,
  S
>;
export type ZodFormCustomMappedProps<
  S extends ZodFormSchemaType,
  CustomMapping extends FormComponentMapping,
> = PropType<
  Concat<ShallowWritable<ZodFormMapping>, ShallowWritable<CustomMapping>>,
  S
>;

export {
  useForm,
  useWatch,
  useFormState,
  useFieldArray,
} from 'react-hook-form';
export type { UseFormReturn, FieldArrayWithId } from 'react-hook-form';
export { useFormContext } from 'react-hook-form';
export {
  createUniqueFieldSchema,
  useFieldInfo,
  useMaybeFieldName,
} from 'zod-form';
export type {
  RenderedFieldMap,
  PropType as ZodFormMappedProps,
  RTFFormProps as ZodFormProps,
  RTFFormSubmitFn as ZodFormSubmitFn,
} from 'zod-form/lib/src/createSchemaForm';
export type { UnwrapZodType } from 'zod-form/lib/src/unwrap';

export * from './utils';
export * from './fieldUtils';
export * from './types';
export * from './ZodSelectFields';
export * from './ZodDateFields';
export * from './ZodTextField';
export * from './ZodNumberField';
export * from './ZodBooleanField';
