import { forwardRef, ReactNode } from 'react';

// We pulled this out of @reach/skip-nav to enable upgrade to React 18

const defaultId = 'skip-nav';

/**
 * SkipNavLink
 *
 * Renders a link that remains hidden until focused to skip to the main content.
 *
 * @see Docs https://reach.tech/skip-nav#skipnavlink
 */
export const SkipNavLink = forwardRef<
  HTMLAnchorElement,
  { children: ReactNode; className: string }
>(({ children, ...props }, forwardedRef) => (
  <a {...props} ref={forwardedRef} href={`#${defaultId}`}>
    {children}
  </a>
));

/**
 * SkipNavContent
 *
 * Renders a div as the target for the link.
 *
 * @see Docs https://reach.tech/skip-nav#skipnavcontent
 */
export const SkipNavContent = forwardRef<HTMLDivElement>((_props, ref) => (
  <div ref={ref} id={defaultId} />
));
