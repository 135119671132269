import invariant from 'invariant';

// The minimum scroll adjustment that we'd scroll for.
const MINIMUM_SCROLL_ADJUSTMENT = 30;

// How close to the bottom of the page does it have to be scrolled to be
// considered "the limit". In testing, sometimes the limit condition wasn't
// triggered, because the math was off by a few pixels.
const SCROLLED_TO_LIMIT_PADDING = 8;

/**
 * Scroll an element into view reasonably close to the center
 * of the page.
 *
 * @param ref The element to scroll into view
 * @param onScrollComplete a callback to call when the scroll has been completed.
 *  Can be used to trigger animations on the element.
 */
export default function scrollRefIntoView(
  ref: React.RefObject<HTMLElement>,
  onScrollComplete?: () => void
) {
  invariant(ref.current, 'scrollRefIntoView: ref.current is null');

  const boundingRect = ref.current.getBoundingClientRect();

  const boundingTop = boundingRect.y;
  const offsetTop = ref.current.offsetTop;
  const offsetDifference = boundingTop - offsetTop;

  const innerHeight = window.innerHeight;
  const usableHeight = innerHeight - offsetDifference;

  let offsetY = 0;
  if (boundingRect.height > usableHeight) {
    offsetY = offsetTop;
  } else {
    const spacing = (innerHeight - usableHeight) / 2;
    offsetY = offsetTop - spacing;
  }

  // Do not scroll if it would be a too small adjustment.
  const currentOffsetY = window.scrollY;
  const isSmallScroll = offsetY - currentOffsetY < MINIMUM_SCROLL_ADJUSTMENT;
  const isFullyVisible = currentOffsetY + boundingRect.height < usableHeight;
  if (isSmallScroll && isFullyVisible) {
    if (onScrollComplete) {
      onScrollComplete();
    }
    return;
  }

  const scrollListener = () => {
    const scrolledToElement = window.scrollY >= offsetY * 0.9;
    const scrolledToLimit =
      window.innerHeight + window.scrollY >=
      window.document.body.scrollHeight - SCROLLED_TO_LIMIT_PADDING;

    if (scrolledToElement || scrolledToLimit) {
      window.removeEventListener('scroll', scrollListener);
      if (onScrollComplete) {
        onScrollComplete();
      }
    }
  };

  window.addEventListener('scroll', scrollListener);
  window.scrollTo(0, offsetY);
}
