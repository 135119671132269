import { Box, SxProps, Theme } from '@mui/material';
import { ComponentPropsWithoutRef } from 'react';
import { SystemCssProperties } from '@mui/system';

function Wordmark({
  size = 13,
  color: externalColor,
  ...props
}: { size?: number; color: SystemCssProperties<Theme>['color'] } & Omit<
  ComponentPropsWithoutRef<'svg'>,
  'color'
>) {
  const sx: SxProps<Theme> = {
    fill: externalColor ?? ((theme) => theme.palette.midnight),
  };
  return (
    <svg
      width={size * 7.3} // 91
      height={size}
      viewBox="0 0 91 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* No translation necessary for SVG title (not user facing) */}
      {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready */}
      <title>Watershed wordmark</title>
      <Box
        component="path"
        d="M20.6108 12.4353C18.7542 12.4353 17.3037 11.3858 17.3037 9.40191C17.3037 7.23103 19.0442 6.39722 20.9443 6.39722H22.4964C23.4536 6.39722 23.8887 6.0234 23.8887 5.36209C23.8887 4.4995 23.091 4.08259 22.0612 4.08259C20.8282 4.08259 20.045 4.67202 19.9725 5.7215H17.6809C17.8259 3.68005 19.1603 2.35742 22.0322 2.35742C24.9041 2.35742 26.079 3.73756 26.079 5.64961V9.71809C26.079 10.1781 26.253 10.3507 26.7172 10.3507H27.0798V12.1334H25.5858C24.5415 12.1334 23.9612 11.6159 23.9612 10.7245V10.3651H23.9323C23.3231 11.774 22.0902 12.4353 20.6108 12.4353ZM21.2924 10.6814C22.5979 10.6814 23.8887 9.87624 23.8887 7.9067V7.02981H23.8598C23.5697 7.61921 23.004 7.9067 22.0177 7.9499L21.3069 7.97858C20.1611 8.00738 19.6679 8.58237 19.6679 9.33004C19.6679 10.0776 20.1611 10.6958 21.3069 10.6958L21.2924 10.6814Z"
        sx={sx}
      />
      <Box
        component="path"
        d="M28.574 9.57506V4.4427H26.7319V2.66003H28.574V0.0722656H30.7787V2.66003H33.6651V4.4427H30.7787V9.25876C30.7787 10.0639 31.1993 10.3802 31.9246 10.3802H33.7086V12.1628H31.1703C29.4587 12.1628 28.5594 11.3147 28.5594 9.58946L28.574 9.57506Z"
        sx={sx}
      />
      <Box
        component="path"
        d="M34.2446 7.40381C34.2446 4.25538 36.2173 2.37207 38.9731 2.37207C41.7289 2.37207 43.5711 3.9966 43.5711 7.04443V8.022H36.5799C36.6524 9.64656 37.5662 10.7104 39.0311 10.7104C40.0899 10.7104 40.8876 10.1353 41.1923 9.22959H43.455C43.0344 11.1274 41.3663 12.4355 39.0021 12.4355C36.1737 12.4355 34.2592 10.5379 34.2592 7.40381H34.2446ZM41.3663 6.45492C41.3663 5.07483 40.5251 4.09724 39.0311 4.09724C37.5371 4.09724 36.7394 5.13234 36.5943 6.45492H41.3663Z"
        sx={sx}
      />
      <Box
        component="path"
        d="M45.0796 2.65918H47.2408V4.15433H47.2698C47.7195 3.20547 48.3286 2.65918 49.6051 2.65918H50.5333V4.44185H49.2134C47.8935 4.44185 47.2844 5.14629 47.2844 6.69895V12.1476H45.0941V2.65918H45.0796Z"
        sx={sx}
      />
      <Box
        component="path"
        d="M51.0555 8.99957H53.3183C53.3763 10.1929 54.1306 10.7104 55.2619 10.7104C56.3932 10.7104 57.0895 10.1641 57.0895 9.31586C57.0895 8.56832 56.6253 8.12267 55.4359 8.12267H54.3916C52.3899 8.12267 51.2296 7.03003 51.2296 5.43425C51.2296 3.62282 52.7091 2.37207 55.2184 2.37207C57.7277 2.37207 59.2071 3.72346 59.2651 5.73616H57.017C56.9589 4.6723 56.3788 4.09724 55.2039 4.09724C54.1595 4.09724 53.5214 4.58605 53.5214 5.36236C53.5214 6.06681 54.0581 6.41184 54.9428 6.41184H56.0887C58.3803 6.41184 59.4247 7.6913 59.4247 9.24399C59.4247 11.1274 57.9307 12.4499 55.2619 12.4499C52.593 12.4499 51.0991 11.0986 51.041 9.01397L51.0555 8.99957Z"
        sx={sx}
      />
      <Box
        component="path"
        d="M60.9482 0.100586H63.1385V2.54457C63.1385 2.97587 63.1385 3.30653 63.1094 4.11161H63.1385C63.6461 3.07651 64.6324 2.37206 66.0104 2.37206C67.9685 2.37206 69.3174 3.72344 69.3174 6.02366V12.1481H67.1272V6.45496C67.1272 4.75854 66.402 4.11161 65.3142 4.11161C64.2264 4.11161 63.1385 4.81605 63.1385 6.71378V12.1481H60.9482V0.100586Z"
        sx={sx}
      />
      <Box
        component="path"
        d="M70.8105 7.40381C70.8105 4.25538 72.7688 2.37207 75.5391 2.37207C78.3095 2.37207 80.137 3.9966 80.137 7.04443V8.022H73.1458C73.2184 9.64656 74.1321 10.7104 75.5971 10.7104C76.6559 10.7104 77.4537 10.1353 77.7583 9.22959H80.0211C79.6003 11.1274 77.9324 12.4355 75.5681 12.4355C72.7252 12.4355 70.8251 10.5379 70.8251 7.40381H70.8105ZM77.9324 6.45492C77.9324 5.07484 77.091 4.09724 75.5971 4.09724C74.1032 4.09724 73.3054 5.13234 73.1603 6.45492H77.9324Z"
        sx={sx}
      />
      <Box
        component="path"
        d="M81.2251 7.40385C81.2251 4.11161 83.2557 2.37206 85.4895 2.37206C86.9544 2.37206 88.1148 3.11963 88.695 4.32726H88.7239C88.695 3.9966 88.695 3.70906 88.695 3.36403V0.100586H90.8851V12.1481H88.7095V11.5155C88.7095 11.1704 88.7095 10.8542 88.7239 10.4947H88.695C88.1148 11.7024 86.9544 12.4499 85.4895 12.4499C83.2557 12.4499 81.2251 10.7103 81.2251 7.41813V7.40385ZM86.1421 10.7103C87.7376 10.7103 88.695 9.40218 88.695 7.40385C88.695 5.40548 87.7376 4.09723 86.1421 4.09723C84.5467 4.09723 83.5749 5.40548 83.5749 7.40385C83.5749 9.40218 84.5321 10.7103 86.1421 10.7103Z"
        sx={sx}
      />
      <Box
        component="path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29298 7.62823L11.3135 12.0618H13.1121L18.5948 0H16.071L12.2273 8.98521H12.1839L8.36908 0H5.81633L8.20883 5.24956L6.39645 9.47405H6.36752L2.53822 0H0L5.49716 12.0618H7.28122L9.29298 7.62823Z"
        sx={sx}
      />
    </svg>
  );
}

export default Wordmark;
