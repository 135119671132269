import { Trans, useLingui } from '@lingui/react/macro';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import CloseIcon from '@watershed/icons/components/Close';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import IconButton from '@watershed/ui-core/components/IconButton';
import React from 'react';

export type FilterOption = { id: string; label: string };

export function FilterRow({
  index,
  field,
  value,
  filterOptions,
  existingFilterFields,
  onValueChange,
  onFilterFieldChange,
  onDeleteFilter,
  getFieldLabel,
  lockedFilters,
  fullyLocked,
}: {
  index: number;
  field: string | null;
  value: Array<FilterOption>;
  filterOptions: Map<string, Array<FilterOption>>;
  existingFilterFields: Array<string | null>;
  lockedFilters: Array<string> | null;
  fullyLocked: boolean;
  onValueChange: (
    index: number,
    newValue: Array<string>,
    lockedFilters: Array<string>
  ) => void;
  onFilterFieldChange: (filterIndex: number, newValue: string) => void;
  onDeleteFilter: (index: number) => void;
  getFieldLabel: (field: string) => string;
}) {
  const { t } = useLingui();
  return (
    <Box>
      <Box display="flex">
        <Autocomplete
          data-test={TestIds.FilterRowFieldAutocomplete}
          disabled={!!lockedFilters && lockedFilters.length > 0}
          value={field ?? undefined}
          onChange={(evt, newValue) => {
            onFilterFieldChange(index, newValue);
          }}
          getOptionDisabled={(option) => existingFilterFields.includes(option)}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {getFieldLabel(option)}
              </Box>
            );
          }}
          options={[...filterOptions.keys()]}
          sx={{ width: '100%' }}
          size="small"
          disableClearable
          getOptionLabel={(option) => getFieldLabel(option)}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{ padding: 0 }}
                variant="outlined"
                placeholder={t`Select a column`}
                aria-labelledby={field ?? undefined}
                autoComplete="off"
              />
            );
          }}
          openOnFocus={true}
        />
        <Box sx={{ ml: 2, mr: 2, mt: 1 }}>
          <Trans context="In the sense of 'equals to'. This is found in a filter component where the user first selects a field to filter on and then a value e.g. 'field is value'">
            is
          </Trans>
        </Box>
        <Autocomplete
          data-test={TestIds.FilterRowValueAutocomplete}
          disabled={fullyLocked}
          placeholder={t`Select a column`}
          limitTags={1}
          options={field ? (filterOptions.get(field) ?? []) : []}
          getOptionDisabled={(option) =>
            !!value.find((existingValue) => existingValue.id === option.id)
          }
          multiple
          sx={{ width: '100%' }}
          size="small"
          disableClearable
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {option.label}
              </Box>
            );
          }}
          renderTags={(value: ReadonlyArray<FilterOption>, getTagProps) =>
            value.map((option: FilterOption, index: number) => (
              <Box
                key={option.id}
                sx={{ maxWidth: 180 }}
                data-test={TestIds.FilterRowValueChip}
              >
                <Chip
                  title={option.label}
                  label={option.label}
                  size="small"
                  {...getTagProps({
                    index,
                  })}
                  disabled={
                    !!lockedFilters && lockedFilters.includes(option.id)
                  }
                />
              </Box>
            ))
          }
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{ padding: 0 }}
                variant="outlined"
                aria-labelledby={field ?? undefined}
                autoComplete="off"
                placeholder={!value.length ? t`Select a column` : undefined}
              />
            );
          }}
          openOnFocus={true}
          value={value}
          onChange={(evt, newValue: Array<FilterOption>) => {
            onValueChange(
              index,
              newValue.map((val) => val.id),
              lockedFilters ?? []
            );
          }}
        />
        <IconButton
          data-test={TestIds.FilterRowDeleteButton}
          sx={{ ml: 1 }}
          onClick={() => onDeleteFilter(index)}
          size="small"
          disabled={!!lockedFilters && lockedFilters.length > 0}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
