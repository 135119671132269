import {
  GridColDef,
  GridFilterOperator,
  GridFilterItem,
  GridCellParams,
} from '@watershed/ui-core/components/DataGrid/DataGrid';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import { getValueForField } from '@watershed/shared-universal/suppliers/SupplierColumnGetters';
import {
  SUPPLIER_DEFAULT_FIELD_ORDER,
  SupplierColumnName,
} from '@watershed/shared-universal/suppliers/SupplierColumnRegistry';

export enum SupplierGridFilterOperatorValue {
  GhgCategories = 'ghgCategoriesFilter',
  OneOf = 'oneOf',
  ArrayContains = 'arrayContains',
  CommitmentAndFilterDate = 'commitmentAndFilterDate',
}

export const ghgCategoryIdsOperator: GridFilterOperator = {
  label: 'ghgCategoryIds',
  value: SupplierGridFilterOperatorValue.GhgCategories,
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    const filterItemValue = filterItem.value;
    if (
      !filterItem.field ||
      !filterItem.value ||
      filterItem.operator !== SupplierGridFilterOperatorValue.GhgCategories
    ) {
      return null;
    }

    return (params: GridCellParams<any, unknown, string>): boolean => {
      const paramValues = params.formattedValue?.split(', ') ?? [];

      for (const item in filterItemValue) {
        if (paramValues.includes(filterItemValue[item])) {
          return true;
        }
      }

      return false;
    };
  },
};

export const ghgCategoryIdsFilterOperators = [ghgCategoryIdsOperator];

const oneOfOperator: GridFilterOperator = {
  label: 'one of',
  value: SupplierGridFilterOperatorValue.OneOf,
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    const filterItemValue = filterItem.value;
    if (
      !filterItem.field ||
      !filterItemValue ||
      !Array.isArray(filterItemValue) ||
      filterItem.operator !== SupplierGridFilterOperatorValue.OneOf
    ) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      return filterItemValue.includes(params.formattedValue);
    };
  },
};

export const singleSelectFilterOperators = [oneOfOperator];

const arrayContainsOperator: GridFilterOperator = {
  label: 'arrayContains',
  value: SupplierGridFilterOperatorValue.ArrayContains,
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    const filterItemValue = filterItem.value;
    if (
      !filterItem.field ||
      !filterItemValue ||
      !Array.isArray(filterItemValue) ||
      filterItem.operator !== SupplierGridFilterOperatorValue.ArrayContains
    ) {
      return null;
    }

    return (params: GridCellParams<any, any>): boolean => {
      return !isEmpty(
        intersection(
          SUPPLIER_DEFAULT_FIELD_ORDER.includes(
            column.field as SupplierColumnName
          )
            ? getValueForField(column.field as SupplierColumnName)(params.value)
            : params.value,
          filterItemValue.map((option) => option.value)
        )
      );
    };
  },
};

export const arrayContainsFilterOperators = [arrayContainsOperator];

export type CommitmentAndTargetYearFilterValue = {
  // User can select multiple commitment statuses (eg. "100% clean power", "50% clean power", ...)
  commitmentStatus: Array<string>;
  // User can select a single target year upper bound (eg. "by 2025", "by 2030", ...)
  targetYear: number | null;
};

export type CommitmentAndTargetYearCellValue = {
  commitmentStatus: string;
  targetYear: number | null;
} | null;

export const commitmentAndTargetDateOperator: GridFilterOperator = {
  label: 'commitment and target date',
  value: SupplierGridFilterOperatorValue.CommitmentAndFilterDate,
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (
      !filterItem.field ||
      !filterItem.value ||
      filterItem.operator !==
        SupplierGridFilterOperatorValue.CommitmentAndFilterDate
    ) {
      return null;
    }

    const filterItemValue =
      filterItem.value as CommitmentAndTargetYearFilterValue;
    return (params: GridCellParams<any, any>): boolean => {
      const cellValue = getValueForField(column.field as SupplierColumnName)(
        params.value
      );
      if (
        filterItemValue.targetYear !== null &&
        (!cellValue?.targetYear ||
          filterItemValue.targetYear < cellValue.targetYear)
      ) {
        return false;
      }
      if (
        filterItemValue.commitmentStatus.length &&
        !filterItemValue.commitmentStatus.includes(
          cellValue?.commitmentStatus ?? null
        )
      ) {
        return false;
      }
      return true;
    };
  },
};

// This function is passed as in getApplyQuickFilterFn to DataGrid so that when
// the user types in the search text box, we apply a case-insensitive filter
// to the formatted value of the column. For example, if the user types "AMA",
// we want it to match "Amazon" (supplier name) and "jeff@amazon.com" (contacts).
export const caseInsensitiveFormattedValueQuickFilterFn =
  (value: string) => (params: GridCellParams<any>) => {
    return Boolean(
      params.formattedValue &&
        typeof params.formattedValue === 'string' &&
        params.formattedValue.toLowerCase().includes(value.toLowerCase())
    );
  };
