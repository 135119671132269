import { t } from '@lingui/core/macro';
import {
  GQApprovalStatus,
  GQChangelogEventPartsFragment,
  GQDataApprovalObjectChangelogQuery,
  GQDataApprovalUserUploadTaskChangelogQuery,
  GQFlags,
  GQObjectType,
  GQPermissionType,
  GQUserUploadForDatasourceFragment,
  GQUserUploadTaskWithDatasourceFragment,
  GQApprovalTargetLockState,
} from '@watershed/shared-universal/generated/graphql';
import {
  DataApprovalsFiles,
  DataGovApprovalViewState,
  DataGovApprovalsButtonType,
  DataGovDatasourceUploadsApprovalSettings,
  DataGovApprovalsLockingButtonType,
} from '@watershed/shared-universal/dataApprovals/dataGovApprovalsTypes';
import {
  getErroredUploads,
  getIncompleteUploads,
  getProcessingUploads,
} from '@watershed/shared-universal/measurement/userUploadUtils';
import assertNever from '@watershed/shared-util/assertNever';
import { BadInputError } from '@watershed/errors/BadInputError';
import { useUserContext } from '../../../../../../utils/UserContext';
import {
  UserPermissions,
  hasPermission,
} from '@watershed/shared-universal/utils/permissionUtils';
import { useFeatureFlag } from '../../../../../../utils/FeatureFlag';
import { Cache } from '@urql/exchange-graphcache';
import {
  DataApprovalObjectChangelogDocument,
  DataApprovalUserUploadTaskChangelogDocument,
} from '@watershed/shared-frontend/generated/urql';
import invariant from 'invariant';
import { formatList } from '@watershed/intl/formatters';
import useLocale from '@watershed/intl/frontend/useLocale';

/**
 * For Data Approvals views to pass in given GraphQL, call hooks, and get back the settings
 * for the UI re: which buttons/state/tooltips/controls to show the user
 * (based on the user's permissions, the tasks's state, and what files are uploaded)
 *
 * This pulls relevant data out of GraphQL and passes to our test/server-friendly helper method.
 */
export function useApprovalViewSettingsForDatasourceUploads({
  userUploads,
  userUploadTask,
}: {
  userUploads: Array<GQUserUploadForDatasourceFragment>;
  userUploadTask: Pick<
    GQUserUploadTaskWithDatasourceFragment,
    | 'approvalStatus'
    | 'approvers'
    | 'datasource'
    | 'measurementProject'
    | 'hasIncompleteBuildings'
    | 'facilitiesPreview'
  >;
}): DataGovDatasourceUploadsApprovalSettings {
  const hasDataGovApprovalFlows = useFeatureFlag(
    GQFlags.DataIngestionCsrdApprovalFlow
  );
  const locale = useLocale();

  const {
    approvalStatus,
    approvers,
    datasource: { id: datasourceId, dataset },
    measurementProject,
    facilitiesPreview,
    hasIncompleteBuildings,
  } = userUploadTask;

  const userContext = useUserContext();

  // Safety net to exit early if outside of FF or removed from FF
  if (!hasDataGovApprovalFlows) {
    return {
      approvalViewState: DataGovApprovalViewState.CANNOT_SUBMIT,
      buttonType: DataGovApprovalsButtonType.SUBMIT_FOR_APPROVAL,
      isDisabled: true,
      calloutText: t({
        message: `You do not have access to use approval flows.`,
        comment: `A callout message`,
      }),
    };
  }

  const approversWithStatus = approvers.map((a) => ({
    userId: a.user.id,
    approvalStatus: a.approvalStatus,
    displayName: a.user.displayName,
  }));

  return getApprovalViewSettingsForDatasourceUploads({
    approvalStatus,
    approvers: approversWithStatus,
    datasourceId,
    isMeasurementProjectActive: measurementProject.active,
    userId: userContext.userId,
    userPermissions: userContext.permissions,
    userUploads,
    hasIncompleteBuildings:
      dataset.canonicalDataset?.kind === 'Buildings' && hasIncompleteBuildings,
    facilitiesToBeApprovedCount:
      dataset.canonicalDataset?.kind === 'Buildings'
        ? facilitiesPreview.totalCount
        : 0,
    locale,
  });
}

/**
 * Largely for testing! You probably want useApprovalViewSettingsForDatasourceUploads() if using in UI
 *
 * Abstracted out to allow passing in simpler data structures but still get back the settings
 * for the UI re: which buttons/state/tooltips/controls to show the user
 * (based on the user's permissions, the tasks's state, and what files are uploaded)
 */
export function getApprovalViewSettingsForDatasourceUploads({
  approvalStatus,
  approvers,
  datasourceId,
  isMeasurementProjectActive,
  userId,
  userPermissions,
  userUploads,
  hasIncompleteBuildings,
  facilitiesToBeApprovedCount,
  locale,
}: {
  approvalStatus: GQApprovalStatus;
  approvers: Array<{
    userId: string;
    approvalStatus: GQApprovalStatus | null;
    displayName: string;
  }>;
  datasourceId: string;
  isMeasurementProjectActive: boolean;
  userId: string;
  userPermissions: Readonly<UserPermissions>;
  userUploads: DataApprovalsFiles;
  hasIncompleteBuildings: boolean;
  facilitiesToBeApprovedCount: number;
  locale: string;
}): DataGovDatasourceUploadsApprovalSettings {
  const hasPermissionToApproveData = userHasPermissionToApproveDatasource(
    userPermissions,
    datasourceId
  );
  const isAssignedToApproveData = approvers
    .map((a) => a.userId)
    .includes(userId);

  const approvalViewState: DataGovApprovalViewState =
    ((): DataGovApprovalViewState => {
      switch (approvalStatus) {
        case GQApprovalStatus.Approved:
          if (hasPermissionToApproveData && isAssignedToApproveData) {
            return DataGovApprovalViewState.APPROVED_CAN_REJECT;
          }
          return DataGovApprovalViewState.APPROVED;

        case GQApprovalStatus.SubmittedForApproval:
          const hasAlreadyApproved = approvers.some(
            (a) =>
              a.userId === userId &&
              a.approvalStatus === GQApprovalStatus.Approved
          );
          if (hasAlreadyApproved) {
            return DataGovApprovalViewState.APPROVED_AWAITING_OTHERS;
          }

          return hasPermissionToApproveData && isAssignedToApproveData
            ? DataGovApprovalViewState.SUBMITTED_HAS_PERMISSION
            : DataGovApprovalViewState.SUBMITTED_BUT_NO_PERMISSION;

        // NOTE: there is no explicit state for rejected. It will show as "can submit"
        // The key is to make sure we show the comments and events from rejection to communicate this.
        case GQApprovalStatus.NotReadyForApproval:
          const userHasPermissionsToSubmit =
            userHasPermissionsToSubmitDatasourceForApproval(
              userPermissions,
              datasourceId
            );
          if (!userHasPermissionsToSubmit) {
            return DataGovApprovalViewState.CANNOT_SUBMIT_NO_PERMISSION;
          }

          if (hasIncompleteBuildings) {
            return DataGovApprovalViewState.CANNOT_SUBMIT;
          }

          const hasActiveFiles = userUploads.length > 0;
          if (!hasActiveFiles && facilitiesToBeApprovedCount === 0) {
            return DataGovApprovalViewState.CAN_SUBMIT_EMPTY;
          }

          const hasIncompleteUploads =
            getIncompleteUploads(userUploads).length > 0;
          const hasProcessingUploads =
            getProcessingUploads(userUploads).length > 0;
          const hasErroredUploads = getErroredUploads(userUploads).length > 0;
          if (hasIncompleteUploads || hasErroredUploads || hasProcessingUploads)
            return DataGovApprovalViewState.CANNOT_SUBMIT;

          return DataGovApprovalViewState.CAN_SUBMIT;

        default:
          assertNever(approvalStatus);
      }
    })();

  let result: DataGovDatasourceUploadsApprovalSettings;
  // Based on the evaluated state, return configured settings
  switch (approvalViewState) {
    case DataGovApprovalViewState.SUBMITTED_BUT_NO_PERMISSION:
      result = {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.APPROVAL_DROPDOWN,
        isDisabled: true,
        tooltip: t({
          message: `You are not assigned to approve this data.`,
          context: `Button tooltip`,
        }),
        calloutText: t({
          message: `Submitted for approval`,
          context: `Callout text`,
        }),
      };
      break;
    case DataGovApprovalViewState.SUBMITTED_HAS_PERMISSION:
      result = {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.APPROVAL_DROPDOWN,
        isDisabled: false,
        calloutText: t({
          message: `Your approval is requested`,
          context: `Callout text`,
        }),
      };
      break;
    case DataGovApprovalViewState.APPROVED_AWAITING_OTHERS:
      const otherApprovers = approvers.filter(
        (a) => a.approvalStatus === 'SubmittedForApproval'
      );
      const formattedOtherApprovers = formatList(
        otherApprovers.map((a) => a.displayName),
        {
          style: 'short',
          type: 'conjunction',
          locale,
        }
      );
      result = {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.POST_APPROVAL_DROPDOWN,
        buttonColor: 'secondary',
        isDisabled: false,
        calloutText: t({
          message: `You approved this data. Awaiting approval from ${formattedOtherApprovers}`,
          context: `Callout text`,
        }),
      };
      break;
    case DataGovApprovalViewState.APPROVED:
      result = {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.APPROVED,
        isDisabled: true,
        calloutText: t({
          message: `All reviewers have approved this data`,
          context: `Callout text`,
        }),
      };
      break;
    case DataGovApprovalViewState.APPROVED_CAN_REJECT:
      result = {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.POST_APPROVAL_DROPDOWN,
        buttonColor: 'secondary',
        isDisabled: false,
        calloutText: t({
          message: `All reviewers have approved this data`,
          context: `Callout text`,
        }),
      };
      break;
    case DataGovApprovalViewState.CANNOT_SUBMIT:
      {
        const message = hasIncompleteBuildings
          ? t({
              message: `Cannot submit for approval until all facilities are complete.`,
              context: `Callout text`,
            })
          : t({
              message: `Cannot submit for approval until all files have finished processing without error.`,
              context: `Callout text`,
            });

        result = {
          approvalViewState,
          buttonType: DataGovApprovalsButtonType.SUBMIT_FOR_APPROVAL,
          isDisabled: true,
          calloutText: message,
          tooltip: message,
        };
      }
      break;
    case DataGovApprovalViewState.CANNOT_SUBMIT_NO_PERMISSION:
      result = {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.SUBMIT_FOR_APPROVAL,
        isDisabled: true,
        calloutText: t({
          message: `Awaiting submission`,
          context: `Callout text`,
        }),
      };
      break;
    case DataGovApprovalViewState.CAN_SUBMIT:
      result = {
        approvalViewState,
        buttonColor: 'primary',
        buttonType: DataGovApprovalsButtonType.SUBMIT_FOR_APPROVAL,
        isDisabled: false,
        calloutText: t({
          message: `Upload the specified data and submit it for approval.`,
          context: `Callout text`,
        }),
      };
      break;
    case DataGovApprovalViewState.CAN_SUBMIT_EMPTY:
      result = {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.SUBMIT_FOR_APPROVAL,
        isDisabled: false,
        tooltip: t({
          message: `If there is no data that applies here, submit empty data for the approver to review.`,
          context: `Button tooltip`,
        }),
        calloutText: t({
          message: `Upload the specified data and submit it for approval.`,
          context: `Callout text`,
        }),
      };
      break;
    default:
      throw new BadInputError('Add support state for ' + approvalViewState);
  }
  if (!isMeasurementProjectActive) {
    result.isDisabled = true;
    result.tooltip = t({
      message: `This measurement project is complete`,
      context: `Button tooltip`,
    });
  }
  return result;
}

export function useLockingViewSettingsForUserUploadTask({
  userUploadTask,
}: {
  userUploadTask: Pick<
    GQUserUploadTaskWithDatasourceFragment,
    | 'approvalStatus'
    | 'approvers'
    | 'datasource'
    | 'lockState'
    | 'measurementProject'
  >;
}): {
  lockState: GQApprovalTargetLockState;
  isDisabled: boolean;
  buttonType: DataGovApprovalsLockingButtonType;
  tooltip: string;
} | null {
  const userContext = useUserContext();

  const hasDataGovApprovalFlows = useFeatureFlag(
    GQFlags.DataIngestionCsrdApprovalFlow
  );

  // Safety net to exit early if outside of FF or removed from FF
  if (!hasDataGovApprovalFlows) {
    return null;
  }

  const {
    approvalStatus,
    approvers,
    datasource: { id: datasourceId },
    lockState,
    measurementProject,
  } = userUploadTask;

  const approverIds = approvers.map((approver) => approver.user.id);
  const anyApproverHasApproved = approvers.some(
    (approver) => approver.approvalStatus === GQApprovalStatus.Approved
  );

  return getLockingViewSettings({
    approvalStatus,
    lockState,
    approverIds,
    datasourceId,
    userId: userContext.userId,
    userPermissions: userContext.permissions,
    anyApproverHasApproved,
    isMeasurementProjectActive: measurementProject.active,
  });
}

export function getLockingViewSettings({
  approvalStatus,
  lockState,
  approverIds,
  datasourceId,
  userId,
  userPermissions,
  anyApproverHasApproved,
  isMeasurementProjectActive,
}: {
  approvalStatus: GQApprovalStatus;
  lockState: GQApprovalTargetLockState;
  approverIds: Array<string>;
  datasourceId: string;
  userId: string;
  userPermissions: Readonly<UserPermissions>;
  anyApproverHasApproved: boolean;
  isMeasurementProjectActive: boolean;
}): {
  lockState: GQApprovalTargetLockState;
  isDisabled: boolean;
  buttonType: DataGovApprovalsLockingButtonType;
  tooltip: string;
} | null {
  const hasPermissionToApproveData = userHasPermissionToApproveDatasource(
    userPermissions,
    datasourceId
  );
  const isAssignedToApproveData = approverIds.includes(userId);
  const isAssignedAndAbleToApproveData =
    hasPermissionToApproveData && isAssignedToApproveData;
  const hasPermissionToSubmit = userHasPermissionsToSubmitDatasourceForApproval(
    userPermissions,
    datasourceId
  );
  const hasPermissionToUnlock =
    userHasPermissionToManageMeasurement(userPermissions);

  let result: {
    lockState: GQApprovalTargetLockState;
    isDisabled: boolean;
    buttonType: DataGovApprovalsLockingButtonType;
    tooltip: string;
  } | null;
  if (
    approvalStatus === GQApprovalStatus.Approved ||
    approvalStatus === GQApprovalStatus.SubmittedForApproval
  ) {
    invariant(
      lockState !== GQApprovalTargetLockState.Unlocked,
      'Data must be locked if already approved or submitted for approval'
    );
    // If the data is locked and approved or submitted for approval, uploaders can request unlock
    if (hasPermissionToUnlock) {
      if (isAssignedToApproveData) {
        // Approvers should just submit a review; they shouldn't see a button to unlock without reviewing
        result = null;
      } else if (lockState === GQApprovalTargetLockState.UnlockRequested) {
        result = {
          lockState,
          isDisabled: false,
          buttonType: DataGovApprovalsLockingButtonType.LOCK_TOGGLE,
          tooltip: t({
            message: `An uploader has requested to undo submission. Note that unsubmitting will remove existing approvals.`,
            context: `Button tooltip`,
          }),
        };
      } else {
        result = {
          lockState,
          isDisabled: false,
          buttonType: DataGovApprovalsLockingButtonType.LOCK_TOGGLE,
          tooltip: t({
            message: `Undo submission to enable editing. Note that unsubmitting will remove existing approvals.`,
            context: `Button tooltip`,
          }),
        };
      }
    } else if (isAssignedAndAbleToApproveData) {
      if (lockState === GQApprovalTargetLockState.UnlockRequested) {
        result = null;
      } else {
        result = null;
      }
    } else if (hasPermissionToSubmit) {
      if (!anyApproverHasApproved) {
        result = {
          lockState,
          isDisabled: false,
          buttonType: DataGovApprovalsLockingButtonType.UPLOADER_UNLOCK,
          tooltip: t({
            message: `Undo submission to enable editing.`,
            context: `Button tooltip`,
          }),
        };
      } else if (lockState === GQApprovalTargetLockState.UnlockRequested) {
        result = {
          lockState,
          isDisabled: true,
          buttonType: DataGovApprovalsLockingButtonType.UNLOCK_REQUEST,
          tooltip: t({
            message: `Unsubmit request pending`,
            context: `Button tooltip`,
          }),
        };
      } else {
        result = {
          lockState,
          isDisabled: false,
          buttonType: DataGovApprovalsLockingButtonType.UNLOCK_REQUEST,
          tooltip: t({
            message: `Request to undo submission. Note that unsubmitting will remove existing approvals.`,
            context: `Button tooltip`,
          }),
        };
      }
    } else {
      result = null;
    }
    if (!isMeasurementProjectActive && result) {
      result.isDisabled = true;
      result.tooltip = t({
        message: `This measurement project is complete`,
        context: `Button tooltip`,
      });
    }

    return result;
  } else {
    // This is the case where data is not submitted for approval. If data is already locked in this case something is wrong.
    invariant(
      lockState === GQApprovalTargetLockState.Unlocked,
      'Data should not be locked if not submitted for approval'
    );
    return null;
  }
}

export function userHasPermissionsToSubmitDatasourceForApproval(
  userPermissions: Readonly<UserPermissions>,
  datasourceId: string
): boolean {
  return hasPermission(userPermissions, [GQPermissionType.ManageDatasource], {
    source: { id: datasourceId },
  });
}

export function userHasPermissionToApproveDatasource(
  userPermissions: Readonly<UserPermissions>,
  datasourceId: string
): boolean {
  return hasPermission(userPermissions, [GQPermissionType.ApproveDatasource], {
    source: { id: datasourceId },
  });
}

export function userHasPermissionToManageMeasurement(
  userPermissions: Readonly<UserPermissions>
): boolean {
  return hasPermission(userPermissions, [GQPermissionType.ManageMeasurement]);
}

function concatEvents(
  existingEvents: Array<GQChangelogEventPartsFragment>,
  newEvents: Array<GQChangelogEventPartsFragment>
): Array<GQChangelogEventPartsFragment> {
  const latestCachedEventTime = existingEvents[0]?.eventTime;
  // Safeguard against duplicating events that were already present in the changelog
  const trulyNewEvents = newEvents.filter(
    (e) => !latestCachedEventTime || e.eventTime > latestCachedEventTime
  );
  return trulyNewEvents.concat(existingEvents);
}

// Helper for updating the urql cache to insert new changelog entries returned by various mutations
export function updateDataApprovalUserUploadTaskChangelogCache(
  cache: Cache,
  targetKind: string,
  targetId: string,
  newChangelogEvents: Array<GQChangelogEventPartsFragment>
) {
  if (targetKind !== 'UserUploadTask') {
    return;
  }
  cache.updateQuery(
    {
      query: DataApprovalUserUploadTaskChangelogDocument,
      variables: {
        input: { userUploadTaskId: targetId },
      },
    },
    (data: GQDataApprovalUserUploadTaskChangelogQuery | null) => {
      if (data) {
        const existingEvents =
          data.dataApprovalUserUploadTaskChangelog.changelogEvents;
        data.dataApprovalUserUploadTaskChangelog.changelogEvents = concatEvents(
          existingEvents,
          newChangelogEvents
        );
      }
      return data;
    }
  );
}

export function updateDataApprovalObjectChangelogCache(
  cache: Cache,
  objectId: string,
  objectType: GQObjectType,
  newChangelogEvents: Array<GQChangelogEventPartsFragment>
) {
  cache.updateQuery(
    {
      query: DataApprovalObjectChangelogDocument,
      variables: {
        input: { objectId, objectType },
      },
    },
    (data: GQDataApprovalObjectChangelogQuery | null) => {
      if (data) {
        const existingEvents = data.dataApprovalObjectChangelog.changelogEvents;
        data.dataApprovalObjectChangelog.changelogEvents = concatEvents(
          existingEvents,
          newChangelogEvents
        );
      }
      return data;
    }
  );
}
