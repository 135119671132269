import Icon, { IconProps } from '../Icon';
const CircleRemoveIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M8 11a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8Z" />
    <path
      fillRule="evenodd"
      d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1ZM3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default CircleRemoveIcon;
