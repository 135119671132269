import {
  GQCompanyClimateCommitmentRawAllFieldsFragment,
  GQCompanySbtCommitmentStage,
} from '../generated/graphql';

export function getSbtCommitmentStageFromCommitments(
  commitments: Array<
    Pick<
      GQCompanyClimateCommitmentRawAllFieldsFragment,
      'sbtCommitmentStage' | 'kind'
    >
  >
): GQCompanySbtCommitmentStage {
  const sbtCommitments = commitments.filter(
    (commitment) => commitment.kind === 'ScienceBasedTarget'
  );
  const committed = sbtCommitments.find(
    (commitment) => commitment.sbtCommitmentStage === 'Committed'
  );
  const targetsSet = sbtCommitments.find(
    (commitment) => commitment.sbtCommitmentStage === 'TargetsSet'
  );

  return targetsSet
    ? GQCompanySbtCommitmentStage.TargetsSet
    : committed
      ? GQCompanySbtCommitmentStage.Committed
      : GQCompanySbtCommitmentStage.None;
}
