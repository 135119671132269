import { atom, useAtom, useSetAtom } from 'jotai';

export const atomSideBarExpandAutoCollapsed = atom<boolean>(false);

export function useIsSideBarExpandAutoCollapsed() {
  return useAtom(atomSideBarExpandAutoCollapsed);
}

export function useSetIsSideBarExpandAutoCollapsed() {
  return useSetAtom(atomSideBarExpandAutoCollapsed);
}
