import memoize from 'lodash/memoize';
import * as Yup from 'yup';
import intensityDenominatorsJson from '../generated/shared_data/intensityDenominatorsMetadata.json';
import {
  GQAggregateKind,
  GQIntensityDenominatorKind,
} from '../generated/graphql';
import assertIncluded from './assertIncluded';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';

/*
    The following is not business logic, but rather conversion from MARTA conventions
    to the GraphQL schema.
*/
export const intensityKindMapping: Record<
  GQIntensityDenominatorKind,
  IntensityDenominatorKind
> = {
  [GQIntensityDenominatorKind.Revenue]: 'revenue' as const,
  [GQIntensityDenominatorKind.Headcount]: 'headcount' as const,
  [GQIntensityDenominatorKind.NightsStayed]: 'nights_stayed' as const,
  [GQIntensityDenominatorKind.GrossProfit]: 'gross_profit' as const,
  [GQIntensityDenominatorKind.NetRevenue]: 'net_revenue' as const,
  [GQIntensityDenominatorKind.Megawatts]: 'megawatts' as const,
  [GQIntensityDenominatorKind.MonthlyActiveUsers]:
    'monthly_active_users' as const,
  [GQIntensityDenominatorKind.Orders]: 'orders' as const,
  [GQIntensityDenominatorKind.OrdersKg]: 'orders_kg' as const,
  [GQIntensityDenominatorKind.Merchants]: 'merchants' as const,
  [GQIntensityDenominatorKind.Patients]: 'patients' as const,
  [GQIntensityDenominatorKind.PayingSitesUnderManagement]:
    'paying_sites_under_management' as const,
  [GQIntensityDenominatorKind.OperationalRevenue]:
    'operational_revenue' as const,
  [GQIntensityDenominatorKind.Shipments]: 'shipments' as const,
  [GQIntensityDenominatorKind.Gmv]: 'gmv' as const,
  [GQIntensityDenominatorKind.Units]: 'units' as const,
  [GQIntensityDenominatorKind.SupplierSpend]: 'supplier_spend' as const,
  [GQIntensityDenominatorKind.SquareFeet]: 'square_feet' as const,
};

export const aggregateKindMapping = {
  [GQIntensityDenominatorKind.Revenue]: GQAggregateKind.RevenueIntensity,
  [GQIntensityDenominatorKind.Headcount]: GQAggregateKind.HeadcountIntensity,
  [GQIntensityDenominatorKind.NightsStayed]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.GrossProfit]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.NetRevenue]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.Megawatts]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.MonthlyActiveUsers]:
    GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.Orders]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.OrdersKg]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.Merchants]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.Patients]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.PayingSitesUnderManagement]:
    GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.OperationalRevenue]:
    GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.Shipments]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.Gmv]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.Units]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.SupplierSpend]: GQAggregateKind.CustomIntensity,
  [GQIntensityDenominatorKind.SquareFeet]: GQAggregateKind.CustomIntensity,
};

export const intensityKindStringToGqlMapping: Record<
  IntensityDenominatorKind,
  GQIntensityDenominatorKind
> = {
  revenue: 'Revenue',
  headcount: 'Headcount',
  nights_stayed: 'NightsStayed',
  gross_profit: 'GrossProfit',
  net_revenue: 'NetRevenue',
  megawatts: 'Megawatts',
  monthly_active_users: 'MonthlyActiveUsers',
  orders: 'Orders',
  orders_kg: 'OrdersKg',
  merchants: 'Merchants',
  patients: 'Patients',
  paying_sites_under_management: 'PayingSitesUnderManagement',
  operational_revenue: 'OperationalRevenue',
  shipments: 'Shipments',
  gmv: 'GMV',
  units: 'Units',
  supplier_spend: 'SupplierSpend',
  square_feet: 'SquareFeet',
};

// If this list grows to ~15 items or more, we probably need a better solution
// than storing these kinds in code! For now, intensity denominators seem to be
// fairly shareable across customers in similar industries.
export const IntensityDenominatorKinds = [
  'revenue',
  'headcount',
  'nights_stayed',
  'gross_profit',
  'net_revenue',
  'megawatts',
  'monthly_active_users',
  'orders',
  'orders_kg',
  'merchants',
  'patients',
  'paying_sites_under_management',
  'operational_revenue',
  'shipments',
  'gmv',
  'units',
  'supplier_spend',
  'square_feet',
] as const;
export type IntensityDenominatorKind =
  (typeof IntensityDenominatorKinds)[number];

export const AggregationKinds = ['sum', 'average'] as const;
export type AggregationKind = (typeof AggregationKinds)[number];

export interface IntensityDenominatorMetadata {
  label: string;
  unit: string;
  unitUserFacing: string;
  intensityUnit: string;
  tooltipUnit: string;
  description: string;
  scaleFactor: number;
  aggregationKind?: AggregationKind;
  isCurrency: boolean;
}
export function stringToIntensityDenominatorKind(
  str: string
): IntensityDenominatorKind {
  assertIncluded(str, IntensityDenominatorKinds);
  return str;
}

const intensityDenominatorMetadataSchema = Yup.object({
  label: Yup.string().required(),
  // A specific reference to a value in the user_activity_table unit field
  unit: Yup.string().required(),
  // The most brief version of the unit
  unitUserFacing: Yup.string().required(),
  // The unit in the context of emissions
  intensityUnit: Yup.string().required(),
  // The brief version of intensityUnit
  tooltipUnit: Yup.string().required(),
  description: Yup.string().required(),
  scaleFactor: Yup.number().required(),
  // if calculating the intensity value over e.g. a year, should
  // you average or sum the monthly values (e.g. for headcount, you
  // would average the number of the time period but for revenue, you
  // would sum).
  aggregationKind: Yup.mixed()
    .oneOf([...AggregationKinds]) // have to spread otherwise the array is read-only
    .optional(),
  // True if the unit is a currency.
  isCurrency: Yup.boolean().required(),
})
  .defined()
  .noUnknown();

const intensityDenominatorsSchema: Record<
  IntensityDenominatorKind,
  typeof intensityDenominatorMetadataSchema
> = {
  revenue: intensityDenominatorMetadataSchema,
  headcount: intensityDenominatorMetadataSchema,
  nights_stayed: intensityDenominatorMetadataSchema,
  gross_profit: intensityDenominatorMetadataSchema,
  net_revenue: intensityDenominatorMetadataSchema,
  megawatts: intensityDenominatorMetadataSchema,
  monthly_active_users: intensityDenominatorMetadataSchema,
  orders: intensityDenominatorMetadataSchema,
  orders_kg: intensityDenominatorMetadataSchema,
  merchants: intensityDenominatorMetadataSchema,
  patients: intensityDenominatorMetadataSchema,
  paying_sites_under_management: intensityDenominatorMetadataSchema,
  operational_revenue: intensityDenominatorMetadataSchema,
  shipments: intensityDenominatorMetadataSchema,
  gmv: intensityDenominatorMetadataSchema,
  units: intensityDenominatorMetadataSchema,
  supplier_spend: intensityDenominatorMetadataSchema,
  square_feet: intensityDenominatorMetadataSchema,
};

const intensityDenominatorsYupSchema = Yup.object(intensityDenominatorsSchema);

/**
 * Returns a list of intensity denominators we're allowed to use, along with their units.
 */
export const getIntensityDenominators = memoize(
  (): Record<IntensityDenominatorKind, IntensityDenominatorMetadata> => {
    return intensityDenominatorsYupSchema.validateSync(
      intensityDenominatorsJson
    );
  }
);

// Headcount should be included in employees, not under intensity_denominator_custom or intensity_denominator_monetary.
export const customIntensityDenominators = pickBy(
  omit(getIntensityDenominators(), ['headcount']),
  (metadata, kind) => metadata.unit !== 'USD'
);
export const monetaryIntensityDenominators = pickBy(
  omit(getIntensityDenominators(), ['headcount']),
  (metadata, kind) => metadata.unit === 'USD'
);
export default getIntensityDenominators();
