import { useEffect } from 'react';

/**
 * Use this to set the window title.
 * @returns nothing
 */
export default function useDocumentTitle(title: string | null | undefined) {
  useEffect(() => {
    if (title) {
      window.document.title =
        title.length > 0 ? `${title} – Watershed` : 'Watershed';
    }
  }, [title]);
}
