import invariant from 'invariant';
import first from 'lodash/first';
import fromPairs from 'lodash/fromPairs';
import orderBy from 'lodash/orderBy';
import Company from '../companyData/Company';
import {
  getTargetName,
  getReadableScopeFromFilterGroupExpression,
  getFootprintScopeFromFilterGroupExpression,
} from '../companySurveys/utils';
import {
  GQDisclosureForSuppliersTableFragment,
  GQCompanyClimateCommitment,
  GQTimeseriesFrequency,
  GQDisclosureTargetIntensityType,
  GQSbtCommitmentStage,
  GQExternalReportType,
  GQPrivateDisclosureType,
} from '../generated/graphql';
import {
  getDisclosureType,
  isSurveyDisclosure,
} from '../utils/DisclosureUtils';
import {
  inferTimeseriesInfo,
  generateDescriptionForParentTargetLegacy,
} from '../utils/PlanTargetUtils';
import { FiscalYearlyPercentageTimeseries } from '../utils/SimpleTimeseries';
import { supplierDisclosureTargetToPlanTarget } from '../utils/TargetUtils';
import { YM, YearMonth } from '../utils/YearMonth';
import { isScienceBasedTargetCommitment } from '../utils/climateCommitmentUtils';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { FootprintScope } from '../forecast/types';

export interface CombinedTarget {
  id: string;
  parentId: string | null;
  parentName: string | null;
  createdAt: YearMonth;
  baselineYear: number | null;
  targetDate: YearMonth | null;
  targetYear: number | null;
  title: string;
  description: string | null;
  scopes: Array<FootprintScope>;
  reductionPercentage: number | null;
  sbtiStatus?: string | null;
  externalUrl?: string | null;
  source: string;
  disclosureType: GQExternalReportType | GQPrivateDisclosureType | null;
  intensityType: GQDisclosureTargetIntensityType | null;
}

// adapted from SupplierDetailsTabDataHistory
function getFieldsFromDisclosure(
  disclosure: GQDisclosureForSuppliersTableFragment
): Pick<CombinedTarget, 'source' | 'disclosureType' | 'createdAt'> {
  const disclosureType = getDisclosureType(disclosure);
  if (isNotNullish(disclosure.publicDisclosure)) {
    return {
      source: `${disclosure.publicDisclosure?.reportType} climate report ${disclosure.publicDisclosure.publishingYear}`,
      disclosureType,
      createdAt: YM.make(disclosure.publicDisclosure.publishingYear),
    };
  }

  invariant(
    isNotNullish(disclosure.privateDisclosure),
    // this is enforced by DB constraint
    'Public disclosure and private disclosure must not both be null'
  );

  return {
    source: isSurveyDisclosure(
      disclosure.privateDisclosure.privateDisclosureType
    )
      ? `Watershed survey response ${disclosure.privateDisclosure.createdAt.getFullYear()}`
      : 'Private data',
    disclosureType,
    createdAt: YM.fromJSDate(disclosure.privateDisclosure.createdAt),
  };
}

function getSbtiStatusFromCommitment(commitment: GQCompanyClimateCommitment) {
  if (
    isScienceBasedTargetCommitment(commitment) &&
    'commitment' in commitment
  ) {
    if (commitment.commitment.stage === GQSbtCommitmentStage.Committed) {
      return 'Committed';
    } else if (
      commitment.commitment.stage === GQSbtCommitmentStage.TargetsSet
    ) {
      if (commitment.submittedToSBTi) {
        return 'Validated';
      } else {
        return 'Set';
      }
    } else if (commitment.commitment.stage === GQSbtCommitmentStage.Removed) {
      return 'Removed';
    }
  }
  return null;
}

export function getCombinedTargetFromTarget(
  disclosure: GQDisclosureForSuppliersTableFragment,
  climateCommitmentDisclosures: Array<GQDisclosureForSuppliersTableFragment>,
  name: string,
  parentFields?: {
    parentId: string;
    parentName: string;
  }
): Array<CombinedTarget> {
  // HACK to pull sbti status onto targets
  const disclosureIdToSbtiCommitment: Record<
    string,
    GQCompanyClimateCommitment | undefined
  > = fromPairs(
    climateCommitmentDisclosures.map((disclosure) => [
      disclosure.id,
      first(
        // Use the most recent climate commitment's sbti status
        orderBy(
          disclosure.climateCommitments ?? [],
          (c) => c.commitmentMadeDate,
          'desc'
        ).filter(isScienceBasedTargetCommitment)
      ),
    ])
  );

  return (
    disclosure.targets?.map((target) => {
      const scopes = getFootprintScopeFromFilterGroupExpression(target.filters);
      const commitment = disclosureIdToSbtiCommitment[disclosure.id];

      const tmInfo =
        target.emissionsTarget &&
        target.emissionsTarget.frequency === GQTimeseriesFrequency.Yearly
          ? inferTimeseriesInfo(
              FiscalYearlyPercentageTimeseries.fromNonUTCTimeZone(
                target.emissionsTarget
              )
            )
          : null;

      return {
        id: target.id,
        base: target.emissionsTarget?.base ?? null,
        parentId: parentFields?.parentId ?? null,
        parentName: parentFields?.parentName ?? null,
        targetDate: tmInfo?.targetEndYear ?? null,
        targetYear: tmInfo?.targetEndYear
          ? YM.year(tmInfo.targetEndYear)
          : null,
        baselineYear: YM.year(target.baseYear),
        title: getTargetName(
          target.name,
          target.baseYear,
          getReadableScopeFromFilterGroupExpression(target.filters)
        ),
        description: generateDescriptionForParentTargetLegacy(
          supplierDisclosureTargetToPlanTarget(target),
          // Hack for the `Unit` intensity type
          target.intensityType === GQDisclosureTargetIntensityType.Unit
            ? target.unit
            : null,
          parentFields?.parentName ?? name
        ),
        scopes,
        reductionPercentage: tmInfo?.percentChange ?? null,
        sbtiStatus: commitment ? getSbtiStatusFromCommitment(commitment) : null,
        intensityType: target.intensityType ?? null,
        ...getFieldsFromDisclosure(disclosure),
      };
    }) ?? []
  );
}

export function getCombinedTargetFromCommitment(
  disclosure: GQDisclosureForSuppliersTableFragment,
  parentFields?: {
    parentId: string;
    parentName: string;
  }
): Array<CombinedTarget> {
  return (
    disclosure.climateCommitments?.map((commitment) => {
      let targetDate = null;
      if ('targetYear' in commitment && commitment.targetYear) {
        targetDate = YM.make(commitment.targetYear);
      } else if (commitment.commitmentPeriodEnd) {
        targetDate = YM.fromJSDate(commitment.commitmentPeriodEnd);
      }

      return {
        id: commitment.id,
        parentId: parentFields?.parentId ?? null,
        parentName: parentFields?.parentName ?? null,
        targetDate,
        targetYear: targetDate ? YM.year(targetDate) : null,
        baselineYear:
          commitment.__typename === 'ScienceBasedTargetCommitment'
            ? commitment.commitment?.baselineYear
            : null,
        title: Company.getCommitmentTitle(commitment),
        scopes: [],
        description: commitment.description,
        reductionPercentage:
          'targetPercentageCleanEnergy' in commitment
            ? (commitment.targetPercentageCleanEnergy ?? null)
            : null,
        externalUrl: disclosure.publicDisclosure?.publicUrl,
        sbtiStatus: getSbtiStatusFromCommitment(commitment),
        intensityType: null, // commitments do not have intensity type
        ...getFieldsFromDisclosure(disclosure),
      };
    }) ?? []
  );
}
