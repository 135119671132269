import {
  GQFilterConjunction,
  GQFilterExpressionGroup,
  GQFilterExpressionGroupWithNewFilters,
  GQFilterFieldLegacy,
  GQFilterFieldWatershed,
  GQFilterOperator,
} from '../generated/graphql';
import invariant from 'invariant';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import assertNever from '@watershed/shared-util/assertNever';
import { FootprintScope, FootprintScopes } from '../forecast/types';
import omitTypename from '../utils/omitTypename';
import filterToHumanReadableString, {
  Options as FilterToStringOptions,
} from '../utils/filterToHumanReadableString';
import isIncludedIn from '../utils/isIncludedIn';
import {
  ALL_GHG_IDS,
  GHG_SCOPES,
  GhgId,
  GhgScope,
  SCOPE_1,
  SCOPE_1_CATEGORY_ID,
  SCOPE_2,
  SCOPE_2_CATEGORY_ID,
  SCOPE_3_GHG_CATEGORY_IDS,
  SCOPE_3_GHG_OFFICIAL_CATEGORY_IDS,
  Scope3GhgCategoryId,
} from '../constants';
import assertKeyOf from '../utils/assertKeyOf';
import { EqualityFunction } from '../utils/samesies';
import { getGroupedFiltersInDrilldownOrder } from '../utils/FootprintAnalysisUtils';
import sortBy from 'lodash/sortBy';
import { getLabelForFilterField } from '../forecast/utils';

// Initiatives can only use a subset of the full set of AnalysisFilters
// Keep in sync with things changed in https://github.com/watershed-climate/ghg/pull/3540/files.
// Add to mapKeys in SummarizedFootprint to ensure correct forecasting.
export const LEGACY_REDUCTION_FILTER_FIELDS = [
  GQFilterFieldLegacy.BusinessCategory,
  GQFilterFieldLegacy.BusinessSubcategory,
  GQFilterFieldLegacy.Location,
  GQFilterFieldLegacy.LocationCountry,
  GQFilterFieldLegacy.Vendor,
  GQFilterFieldLegacy.Product,
  GQFilterFieldLegacy.GhgScope,
  GQFilterFieldLegacy.GhgCategoryId,
  GQFilterFieldLegacy.BuildingName,
  GQFilterFieldLegacy.ElectricityType,
  GQFilterFieldLegacy.Description,
] as const;

export const LEGACY_REDUCTION_FILTER_FIELDS_SET = new Set(
  LEGACY_REDUCTION_FILTER_FIELDS
);

export const isLegacyReductionFilterField = (
  str: string
): str is LegacyReductionFilterField => {
  return LEGACY_REDUCTION_FILTER_FIELDS_SET.has(
    str as LegacyReductionFilterField
  );
};

export const MINIMAL_FILTER_FIELDS_FOR_REDUCTIONS_CONTRIBUTIONS = [
  // GHG Scope and Category are always required to create new targets
  GQFilterFieldWatershed.GhgScope,
  GQFilterFieldWatershed.GhgCategoryId,
  // Business category and subcategory are always required for forecasting (see scalingAreaForRow)
  GQFilterFieldWatershed.CategoryId,
  GQFilterFieldWatershed.SubcategoryId,
];

export const REDUCTION_FILTER_FIELDS = [
  GQFilterFieldWatershed.CategoryId,
  GQFilterFieldWatershed.SubcategoryId,
  GQFilterFieldWatershed.Location,
  GQFilterFieldWatershed.LocationCountry,
  GQFilterFieldWatershed.Vendor,
  GQFilterFieldWatershed.Product,
  GQFilterFieldWatershed.GhgScope,
  GQFilterFieldWatershed.GhgCategoryId,
  GQFilterFieldWatershed.BuildingName,
  GQFilterFieldWatershed.ElectricityType,
  GQFilterFieldWatershed.Description,
] as const;

export const LegacyToWatershedFilterField: Record<
  (typeof LEGACY_REDUCTION_FILTER_FIELDS)[number],
  (typeof REDUCTION_FILTER_FIELDS)[number]
> = {
  [GQFilterFieldLegacy.BusinessCategory]: GQFilterFieldWatershed.CategoryId,
  [GQFilterFieldLegacy.BusinessSubcategory]:
    GQFilterFieldWatershed.SubcategoryId,
  [GQFilterFieldLegacy.Location]: GQFilterFieldWatershed.Location,
  [GQFilterFieldLegacy.LocationCountry]: GQFilterFieldWatershed.LocationCountry,
  [GQFilterFieldLegacy.Vendor]: GQFilterFieldWatershed.Vendor,
  [GQFilterFieldLegacy.Product]: GQFilterFieldWatershed.Product,
  [GQFilterFieldLegacy.GhgScope]: GQFilterFieldWatershed.GhgScope,
  [GQFilterFieldLegacy.GhgCategoryId]: GQFilterFieldWatershed.GhgCategoryId,
  [GQFilterFieldLegacy.BuildingName]: GQFilterFieldWatershed.BuildingName,
  [GQFilterFieldLegacy.ElectricityType]: GQFilterFieldWatershed.ElectricityType,
  [GQFilterFieldLegacy.Description]: GQFilterFieldWatershed.Description,
};

export const WatershedToLegacyFilterField: Record<
  (typeof REDUCTION_FILTER_FIELDS)[number],
  (typeof LEGACY_REDUCTION_FILTER_FIELDS)[number]
> = {
  [GQFilterFieldWatershed.CategoryId]: GQFilterFieldLegacy.BusinessCategory,
  [GQFilterFieldWatershed.SubcategoryId]:
    GQFilterFieldLegacy.BusinessSubcategory,
  [GQFilterFieldWatershed.Location]: GQFilterFieldLegacy.Location,
  [GQFilterFieldWatershed.LocationCountry]: GQFilterFieldLegacy.LocationCountry,
  [GQFilterFieldWatershed.Vendor]: GQFilterFieldLegacy.Vendor,
  [GQFilterFieldWatershed.Product]: GQFilterFieldLegacy.Product,
  [GQFilterFieldWatershed.GhgScope]: GQFilterFieldLegacy.GhgScope,
  [GQFilterFieldWatershed.GhgCategoryId]: GQFilterFieldLegacy.GhgCategoryId,
  [GQFilterFieldWatershed.BuildingName]: GQFilterFieldLegacy.BuildingName,
  [GQFilterFieldWatershed.ElectricityType]: GQFilterFieldLegacy.ElectricityType,
  [GQFilterFieldWatershed.Description]: GQFilterFieldLegacy.Description,
};

export type LegacyReductionFilterField =
  (typeof LEGACY_REDUCTION_FILTER_FIELDS)[number];

export type ReductionFilterField =
  | (typeof REDUCTION_FILTER_FIELDS)[number]
  | string;

export type ReductionFilterPrimitive = {
  field: ReductionFilterField;
  operator: GQFilterOperator;
  value: Array<string>;
};

export interface ReductionFilter {
  name: ReductionFilterName;
  filter: Array<ReductionFilterPrimitive>;
}

// This class is used to get around the amount of gas that the
// XModel has to run. This can take a list of reductions filters
// and check deep equality between them. This is used to avoid
// rerendering the XModel when the filters are the same.
export class ReductionFiltersContainer {
  constructor(public readonly filters: Array<ReductionFilter>) {}

  [EqualityFunction](other: ReductionFiltersContainer): boolean {
    const s1 = new Set(this.filters.map((r) => JSON.stringify(r)));
    const s2 = new Set(other.filters.map((r) => JSON.stringify(r)));
    return s1.size === s2.size && [...s1].every((v) => s2.has(v));
  }
}
// As much as anything, we're naming queries to make sure they are all defined
// in this file. This is important so we have a full list of filters we use on
// the server
export type ReductionFilterName =
  | { type: SmartQuery }
  | { type: 'businessCategory'; businessCategory: string }
  | {
      type: 'businessCategoryAndScope';
      businessCategory: string;
      scope: string;
    }
  | { type: 'scope'; scope: string }
  | { type: 'vendor'; vendor: string }
  | { type: 'custom'; id: string }
  // This one is a bit of a hack
  | { type: 'new' };

// A pre-defined query used in a smart initiative
export enum SmartQuery {
  Buildings = 'buildings',
  BuildingsElectricity = 'buildings_electricity',
  Cloud = 'cloud',
  CloudAws = 'cloud_aws',
  CloudAzure = 'cloud_azure',
  CloudGoogle = 'cloud_google',
  Employees = 'employees',
  EmployeesCommuting = 'employees_commuting',
  EmployeesRemoteWork = 'employees_remote_work',
  EmployeesWaste = 'employees_waste',
  Travel = 'travel',
  TravelFlights = 'travel_flights',
}

// this is a helper for next few functions
const verifySingleFilterForField = (
  filter: ReductionFilterPrimitive,
  field: GQFilterFieldWatershed
) => {
  const intermediateReturn =
    filter.field === field &&
    filter.operator === GQFilterOperator.In &&
    filter.value.length === 1;
  if (field === GQFilterFieldWatershed.GhgScope) {
    return (
      intermediateReturn &&
      FootprintScopes.map((s) => s.toString()).includes(filter.value[0])
    );
  }
  return intermediateReturn;
};

// checks whether a filter is filtering for only 1 category (no scope)
export function isReductionFilterOnlyCategory(
  filters: ReductionFilter
): boolean {
  if (filters.filter.length !== 1) {
    return false;
  }
  return verifySingleFilterForField(
    filters.filter[0],
    GQFilterFieldWatershed.CategoryId
  );
}

export function filterExpressionGroupWithNewFiltersToReductionFilter(
  feg: GQFilterExpressionGroupWithNewFilters
): ReductionFilter {
  switch (feg.conjunction) {
    case GQFilterConjunction.AndConjunction:
      const primitives = feg.expressions
        .filter((primitive) => primitive.value)
        .map((primitive) => {
          const field = isIncludedIn(
            primitive.field,
            LEGACY_REDUCTION_FILTER_FIELDS
          )
            ? // If the filter expression field is a legacy field, convert it to a watershed field
              LegacyToWatershedFilterField[primitive.field]
            : // Otherwise, it's a tag and leave it as is
              primitive.field;

          return {
            ...omitTypename(primitive),
            field,
            // Remove '' values
            value: primitive.value.filter(Boolean),
          };
        });
      return {
        name: { type: 'new' },
        filter: primitives,
      };
    default:
      assertNever(feg.conjunction);
  }
}

export function filterExpressionGroupToReductionFilter(
  feg: GQFilterExpressionGroup
): ReductionFilter {
  switch (feg.conjunction) {
    case GQFilterConjunction.AndConjunction:
      const primitives = feg.expressions
        .filter((primitive) => primitive.value)
        .map((primitive) => {
          invariant(
            isIncludedIn(primitive.field, LEGACY_REDUCTION_FILTER_FIELDS),
            `unexpected custom initiative filter field: ${primitive.field}`
          );
          // TODO(notin): Custom initiatives only support In.
          invariant(
            primitive.operator === GQFilterOperator.In,
            `unexpected custom initiative filter operator: ${primitive.operator}`
          );
          const legacyField = primitive.field;
          const footprintContributionField =
            LegacyToWatershedFilterField[legacyField];

          return {
            operator: primitive.operator,
            field: footprintContributionField,
            // Remove '' values
            value: primitive.value.filter(Boolean),
          };
        });
      return {
        name: { type: 'new' },
        filter: primitives,
      };
    default:
      assertNever(feg.conjunction);
  }
}

export function reductionFilterToFilterExpressionGroup(
  rf: ReductionFilter
): GQFilterExpressionGroupWithNewFilters {
  return {
    conjunction: GQFilterConjunction.AndConjunction,
    expressions: rf.filter
      .filter((filter) => {
        // TODO(notin): Custom initiatives only support In.
        invariant(
          filter.operator === GQFilterOperator.In,
          `unexpected custom initiative filter operator: ${filter.operator}`
        );
        return filter.value;
      })
      .map((filter) => {
        const field = isIncludedIn(filter.field, REDUCTION_FILTER_FIELDS)
          ? WatershedToLegacyFilterField[filter.field]
          : filter.field;

        return { ...filter, field };
      }),
  };
}

export function reductionFilterMatches(
  filter: ReductionFilter,
  row: Record<ReductionFilterField, string | undefined | null>
): boolean {
  for (const primitive of filter.filter) {
    const value = row[primitive.field];
    if (value === undefined || value === null) {
      // TODO: Should we be able to match empty to empty here?
      return false;
    }
    switch (primitive.operator) {
      case GQFilterOperator.In:
        if (
          // Don't use find when length === 1 for performance
          primitive.value.length > 0 &&
          (primitive.value.length === 1
            ? value.toLowerCase() === primitive.value[0].toLowerCase()
            : primitive.value.find(
                (x) => x.toLowerCase() === value.toLowerCase()
              ))
        ) {
          break;
        }
        return false;
      case GQFilterOperator.NotIn:
        if (
          primitive.value.find((x) => x.toLowerCase() === value.toLowerCase())
        ) {
          return false;
        }
        break;
      default:
        assertNever(primitive.operator);
    }
  }
  return true;
}

export function reductionFilterToReadableString(
  { filter }: ReductionFilter,
  options?: Partial<FilterToStringOptions>
): string {
  const allFiltersInOrder = getGroupedFiltersInDrilldownOrder();
  const getSortIndex = (field: string) => {
    const idxOf = allFiltersInOrder.indexOf(field as GQFilterFieldWatershed);
    // Put custom tags at the very end of the list
    return idxOf === -1 ? allFiltersInOrder.length : idxOf;
  };
  filter.sort((a, b) => getSortIndex(a.field) - getSortIndex(b.field));
  return filterToHumanReadableString(filter, options);
}

export function reductionFilterToNameAndValueObjects(
  { filter }: ReductionFilter,
  options?: Partial<FilterToStringOptions>
): Array<{ name: string; value: string }> {
  const allFiltersInOrder = getGroupedFiltersInDrilldownOrder();
  const getSortIndex = (field: string) => {
    const idxOf = allFiltersInOrder.indexOf(field as GQFilterFieldWatershed);
    // Put custom tags at the very end of the list
    return idxOf === -1 ? allFiltersInOrder.length : idxOf;
  };
  filter = sortBy(filter, (f) => getSortIndex(f.field));

  return filter.flatMap((f) => {
    if (
      options?.excludeGhgScopeAndCategory &&
      (f.field === 'ghgCategoryId' || f.field === 'ghgScope')
    ) {
      return [];
    }

    return f.value.map((v) => ({
      name: getLabelForFilterField(f.field),
      value: v,
    }));
  });
}

// record for readable strings for scopes
// TODO(ishaan): there have to be some shared types we can use for this
// We should be using GhgScope but to date, we've persisted scope filters
// as FootprintScope types. For consistency, we'll support both types
// and migrate to GhgScope as part of a deeper refactor of GQFilterFields
export const scopeDisplayNames: Record<FootprintScope, GhgScope> = {
  'scope 1': 'Scope 1',
  'scope 2': 'Scope 2',
  'scope 3': 'Scope 3',
};
export const displayNamesToScope: Record<GhgScope, FootprintScope> = {
  'Scope 1': 'scope 1',
  'Scope 2': 'scope 2',
  'Scope 3': 'scope 3',
};

/**
 * Filters out all invalid GHG scope and category values and normalizes the
 * GHG scope values
 */
export function normalizeGhgScopeAndCategoryValues(
  rawGhgScopes: Array<string>,
  rawGhgCategories: Array<string>
): { ghgScopes: Array<GhgScope>; ghgCategories: Array<Scope3GhgCategoryId> } {
  const ghgCategories = new Set(
    rawGhgCategories.filter((categoryId): categoryId is Scope3GhgCategoryId =>
      isIncludedIn(categoryId, SCOPE_3_GHG_CATEGORY_IDS)
    )
  );

  const ghgScopes = new Set(
    rawGhgScopes
      .map((scope): GhgScope | null => {
        if (scope in scopeDisplayNames) {
          assertKeyOf(scope, scopeDisplayNames);
          return scopeDisplayNames[scope];
        } else if (isIncludedIn(scope, GHG_SCOPES)) {
          return scope;
        }
        return null;
      })
      .filter(isNotNullish)
  );

  if (rawGhgCategories.includes(SCOPE_1_CATEGORY_ID)) {
    ghgScopes.add(SCOPE_1);
  }
  if (rawGhgCategories.includes(SCOPE_2_CATEGORY_ID)) {
    ghgScopes.add(SCOPE_2);
  }

  return {
    ghgScopes: Array.from(ghgScopes).sort(),
    ghgCategories: Array.from(ghgCategories),
  };
}

// Given a filter on GHG scope and category, return the list of GHG categories
// that the filter contains.
export function ghgCategoriesForFilter(
  filter: GQFilterExpressionGroup
): Array<GhgId> {
  const reductionFilter = filterExpressionGroupToReductionFilter(filter);
  invariant(
    verifyReductionFilterOnOnlyGhgScopeAndCategory(reductionFilter),
    'Expected a filter filter on only ghgScope and/or ghgCategoryId'
  );
  // Apply scope filter, if present
  const categories: Set<GhgId> = new Set();
  const addCategories = (catsToAdd: ReadonlyArray<GhgId>) => {
    catsToAdd.forEach((c) => categories.add(c));
  };
  const scopeFilterValues = reductionFilter.filter
    .find((f) => f.field === 'ghgScope')
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/require-locale-argument
    ?.value.map((v) => v.toLocaleLowerCase());
  if (scopeFilterValues === undefined) {
    addCategories(ALL_GHG_IDS);
  }
  if (scopeFilterValues?.includes('scope 1')) {
    addCategories([SCOPE_1_CATEGORY_ID]);
  }
  if (scopeFilterValues?.includes('scope 2')) {
    addCategories([SCOPE_2_CATEGORY_ID]);
  }
  if (scopeFilterValues?.includes('scope 3')) {
    addCategories(SCOPE_3_GHG_OFFICIAL_CATEGORY_IDS);
  }
  // Apply category filter, if present
  const ghgCategoryFilter = reductionFilter.filter.find(
    (f) => f.field === 'ghgCategoryId'
  );
  if (ghgCategoryFilter) {
    return ghgCategoryFilter.value.filter((c): c is GhgId =>
      categories.has(c as GhgId)
    );
  }
  return Array.from(categories);
}

export function verifyReductionFilterOnOnlyGhgScopeAndCategory(
  reductionFilter: ReductionFilter
): boolean {
  return reductionFilter.filter.every(
    (f) => f.field === 'ghgScope' || f.field === 'ghgCategoryId'
  );
}
