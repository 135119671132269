import { TextField, Typography, SxProps, Theme } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import CalendarIcon from '@watershed/icons/components/Calendar';
import ChevronLeftIcon from '@watershed/icons/components/ChevronLeft';
import ChevronRightIcon from '@watershed/icons/components/ChevronRight';
import ChevronDownIcon from '@watershed/icons/components/ChevronDown';
import useToggle from '../hooks/useToggle';
import { DATE_FORMAT_MDY_TEXT } from '@watershed/shared-universal/constants';
import { mixinSx } from '@watershed/style/styleUtils';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import useLocale from '@watershed/intl/frontend/useLocale';
import DateTimeUtils from '@watershed/shared-universal/utils/DateTimeUtils';

export default function DatePicker({
  date,
  onChange,
  onNullValue,
  disablePast = false,
  prefix,
  id,
  disabled,
  fullWidth,
  minDate,
  maxDate,
  dateFormat = DATE_FORMAT_MDY_TEXT,
  fieldSx,
  hideTopMargin,
  fsUnmask,
}: {
  date: DateTime | null;
  onChange: (dateTime: DateTime) => void;
  onNullValue?: () => void;
  disablePast?: boolean;
  prefix?: LocalizedString;
  id: string;
  disabled?: boolean;
  fullWidth?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
  dateFormat?: string;
  fieldSx?: SxProps<Theme>;
  hideTopMargin?: boolean;
  fsUnmask?: boolean;
}) {
  const locale = useLocale();
  const [open, toggleOpen] = useToggle();

  // TODO (bryan): Show warning if the date is outside the bounds of the
  // min/max?

  // NOTE (bryan): if the `date` is null, the date picker will show the current
  // date. But if that's outside the min/max boundaries, we want to use a value
  // that's in-bounds. This comes up e.g. in reporting, where a date might have
  // a max-value of a year ago, so showing a date picker with everything grayed
  // out is a bad UX.
  let value = date;
  if (date === null && (minDate || maxDate)) {
    value = DateTimeUtils.clamp({
      value: DateTime.utc(),
      min: minDate,
      max: maxDate,
    });
  }

  return (
    // TODO: Luxon hardcodes first day of week as Monday
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
      <MuiDatePicker<DateTime>
        open={open}
        onOpen={toggleOpen}
        onClose={toggleOpen}
        minDate={minDate}
        maxDate={maxDate}
        renderInput={(params) => (
          <TextField
            id={id}
            {...params}
            onClick={!disabled ? toggleOpen : undefined}
            sx={mixinSx(fieldSx, {
              '& input': {
                padding: 0,
                ...(fullWidth ? {} : { maxWidth: '16ch' }),
              },
              '& .MuiInputBase-root': {
                cursor: 'pointer',
                maxHeight: 32,
                p: 1,
                marginTop: hideTopMargin ? 0 : undefined,
              },
              '& .MuiInputLabel-root': {
                color: 'text.primary',
              },
              '& .MuiInputBase-input': {
                maxHeight: '32px',
                height: '32px',
                pl: 0.5,
                ml: 0.5,
              },
              '& .MuiInputBase-input:focus, & .MuiInputBase-input:focus-visible, & .MuiInputBase-input:active':
                {
                  backgroundColor: 'transparent',
                },
            })}
            hiddenLabel={false}
            fullWidth={fullWidth}
            color="secondary"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              className: clsx(
                params.inputProps?.className,
                fsUnmask && 'fs-unmask'
              ),
            }}
            InputProps={{
              id,
              margin: 'none',
              startAdornment: (
                <>
                  <CalendarIcon />
                  {prefix && (
                    <Typography
                      component="span"
                      color="textSecondary"
                      children={prefix}
                      sx={{
                        lineHeight: '24px',
                        pl: '0.5em',
                        userSelect: 'none',
                      }}
                      id={id}
                    />
                  )}
                </>
              ),
              endAdornment: <ChevronDownIcon />,
            }}
          />
        )}
        ToolbarComponent={() => null}
        components={{
          LeftArrowIcon: ChevronLeftIcon,
          RightArrowIcon: ChevronRightIcon,
          SwitchViewIcon: ChevronDownIcon,
        }}
        inputFormat={dateFormat}
        value={value ?? date}
        disabled={disabled}
        disableMaskedInput
        disablePast={disablePast && date !== null && date >= DateTime.utc()}
        onChange={(newDateInitial) => {
          // There's a bug in either MUI or Luxon where opening the date picker
          // with no value, then clicking the month/year setter, then clicking
          // any year (thus choosing a date) causes the value to be non-day-aligned.
          const newDate = newDateInitial?.startOf('day');
          if (!newDate || newDate === date) {
            onNullValue && !newDate && onNullValue();
            return;
          }
          onChange(newDate);
        }}
      />
    </LocalizationProvider>
  );
}
