import Field, { FieldProps, filterFieldInputProps } from './Field';
import SelectableTileGroup, {
  SelectableTileGroupProps,
} from './SelectableTileGroup';

export function SelectableTileGroupFieldNonFormik<
  T extends string | number | boolean | null,
>(props: FieldProps & SelectableTileGroupProps<T>) {
  const tileGroupProps = filterFieldInputProps(
    props
  ) as SelectableTileGroupProps<T>;
  return (
    <Field {...props}>
      {/* silly hoops to make TS happy with the union */}
      {tileGroupProps.multiple ? (
        <SelectableTileGroup<T> {...tileGroupProps} />
      ) : (
        <SelectableTileGroup {...tileGroupProps} />
      )}
    </Field>
  );
}
