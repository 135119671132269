import React, { useEffect } from 'react';
import { useUserContext } from '../utils/UserContext';
import { setIsLoggedInAsSelf } from '@watershed/shared-frontend/utils/privilege/privilegeStore';

/**
 * Synchronizes information from the user context/react hooks to the privilegeStore and exchange
 */
export const PrivilegeSyncer: React.FC = () => {
  const { loginAsUser } = useUserContext();

  useEffect(() => {
    setIsLoggedInAsSelf(!loginAsUser);
  }, [loginAsUser]);

  return null;
};
