import nullifyEmptyString from '@watershed/shared-universal/utils/nullifyEmptyString';
import { useId } from 'react';
import { useDescription, useFieldInfo, useTsController } from 'zod-form';
// TODO: i18n (please resolve or remove this TODO line if legit)
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { humanize } from '@watershed/shared-universal/utils/helpers';
import { FieldProps } from '@watershed/ui-core/components/Form/Field';
import pick from 'lodash/pick';
import { useWatch } from './ZodForm';

const STRIP_FROM_IDS_REGEX = /(md|id)$/gi;

export function humanizeFieldId(id: string) {
  return humanize(id).replace(STRIP_FROM_IDS_REGEX, '');
}

export type CommonControllerProps<T> = {
  onChangeTransform?: (v: T | null | undefined) => T | null | undefined;
};

export function useBaseFieldProps({ name }: { name: string }) {
  const id = useId();
  const { label, placeholder } = useDescription();
  const { isOptional, isNullable } = useFieldInfo();

  const baseFieldProps = {
    id,
    label: nullifyEmptyString(label) ?? humanizeFieldId(name),
    placeholder,
    required: !(isOptional || isNullable),
  };
  return baseFieldProps;
}

export function useFieldPropsFromTsController<T>({
  onChangeTransform = (a) => a,
}: CommonControllerProps<T> = {}) {
  const {
    field: { name, value: _value, onChange },
    error,
  } = useTsController<T>();

  // it would seem this watch should not be necessary but sometimes a child component can update a value and parent will not get the change or vice versa
  const value: T = useWatch({
    name,
    defaultValue: _value,
  });
  const baseFieldProps = useBaseFieldProps({ name });
  return {
    ...baseFieldProps,
    validationState: error ? 'error' : 'default',
    validationMessage: error ? error.errorMessage : undefined,
    onChange: (v: T | null | undefined) => {
      return onChange(onChangeTransform(v) as any);
    },
    value,
  } as const;
}

const COMMON_FIELD_PROP_KEYS = [
  'label',
  'sublabel',
  'infoTooltipProps',
  'required',
  'hideRequired',
  'nullValueLabel',
  'inline',
  'renderLabel',
  'disableNullOption',
  'dataTest',
  'name',
  'fsUnmask',
  'fullWidth',
  'maxLength',
] as const;
// These are the keys from our CommonZodFieldProps type that can be passed
// through to "inputs" which is the component that does the actual editing.
// TODO: Specify the *full* set of props that can be passed through to inputs.
const COMMON_INPUT_PROP_KEYS = [
  'disabled',
  'onSelect',
  'onBlur',
  'onKeyDown',
  'onKeyPress',
  'readOnly',
  'placeholder',
  'autoFocus',
  'loading',
] as const;

export type CommonZodFieldProps = Pick<
  FieldProps,
  (typeof COMMON_FIELD_PROP_KEYS)[number] // these the the props specific to the Field component that we expect every zod component to pass through
> & {
  // this are props that we expect every zod field to implement in some form
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  name: string;
};

// typescript can't prevent extra things being passed to a type sometimes
// this function helps use not pass things to underlying dom elements that react will complain about
export function getCommonFieldProps<T extends CommonZodFieldProps>(
  props: T
): CommonZodFieldProps {
  return pick(props, COMMON_FIELD_PROP_KEYS);
}

export function getInputFieldProps<T extends Record<string, unknown>>(
  props: T
): {
  [K in Extract<keyof T, (typeof COMMON_INPUT_PROP_KEYS)[number]>]: T[K];
} {
  return pick(props, COMMON_INPUT_PROP_KEYS) as any;
}
