import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { useLingui } from '@lingui/react/macro';

import { createStyles, makeStyles, ClassNameMap } from '@mui/styles';
import { TextareaAutosize, type Theme } from '@mui/material';
import clsx from 'clsx';
import Field, { filterFieldInputProps } from './Field';
import type { FieldProps } from './Field';
import SearchIcon from '@watershed/icons/components/Search';
import { useField } from 'formik';
import omit from 'lodash/omit';
import { getPaletteUtils } from '@watershed/style/styleUtils';
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '../DataGrid/DataGrid';

const useStyles = makeStyles((theme: Theme) => {
  const paletteUtils = getPaletteUtils(theme.palette);
  const inputSmallFontStyles = omit(theme.typography.body3, 'color');

  return createStyles({
    input: {
      ...paletteUtils.textFieldStyles,
      margin: 0,
      width: '100%',
      resize: 'none',
      '&:invalid': {
        boxShadow: paletteUtils.boxShadowField.error,
      },
    },
    inputSmall: {
      ...inputSmallFontStyles,
      padding: theme.spacing(0.5, 1),
    },
    inputWarning: {
      boxShadow: paletteUtils.boxShadowField.warning,
    },
    inputError: {
      boxShadow: paletteUtils.boxShadowField.error,
    },
    searchMedium: {
      '& input': {
        paddingLeft: 36,
        '&::-webkit-search-decoration': { WebkitAppearance: 'none' },
      },
      '& > svg': {
        position: 'absolute',
        bottom: 6,
        left: 10,
      },
    },
    searchSmall: {
      '& input': {
        paddingLeft: 28,
        '&::-webkit-search-decoration': { WebkitAppearance: 'none' },
      },
      '& > svg': {
        position: 'absolute',
        bottom: 6,
        left: 8,
      },
    },
    resizeNone: {
      resize: 'none',
    },
  });
});

export type TextFieldCommonProps = {
  id: string;
  size?: 'small' | 'medium';
  fsUnmask?: boolean;
  name?: string;
};

export interface TextFieldProps
  extends Omit<FieldProps, 'inputId'>,
    Omit<
      ComponentPropsWithoutRef<'input'>,
      'size' | keyof TextFieldCommonProps
    >,
    TextFieldCommonProps {}

export interface TextFieldMultilineProps
  extends Omit<FieldProps, 'inputId'>,
    Omit<ComponentPropsWithoutRef<'textarea'>, keyof TextFieldCommonProps>,
    TextFieldCommonProps {
  disableResize?: boolean;
  maxRows?: number;
}

function getInputProps(
  props: TextFieldProps | TextFieldMultilineProps,
  classes: ClassNameMap
) {
  const { id, validationState, validationMessage, sublabel, required, value } =
    props;
  const result: Record<string, any> = {
    id,
    className: clsx(
      classes.input,
      'size' in props && props.size === 'small' && classes.inputSmall,
      validationState === 'error' && classes.inputError,
      validationState === 'warning' && classes.inputWarning,
      'disableResize' in props && props.disableResize && classes.resizeNone,
      props.className,
      props.fsUnmask && 'fs-unmask'
    ),
    'aria-invalid': validationState === 'error',
    'aria-required': required,
    'aria-describedby': clsx(
      sublabel && `${id}-sublabel`,
      validationMessage && `${id}-validationMessage`
    ),
    required,
  };
  // Stringify the value, because `number` is incompatible with
  // `react-expanding-textarea`. Don't set this, though, unless props.value is
  // defined, so we don't inadvertently override formikProps.value.
  if (value != null) {
    result.value = String(value);
  }
  return result;
}
/** When `type="number"`, the default browser behavior is to edit the value
 * (!!!). Ideally, we wouldn't use `type="number"`, but we still have needs for
 * the min/max properties there — so this is a hopefully-temporary fix: if you
 * scroll on a focused number input, we blur the input rather than change the
 * value. That way the page scrolls, and the field remains unedited. (We tried
 * e.preventDefault and e.stopPropagation and forwarding scroll events but those
 * didn't do the trick.)
 *
 * TODO: We'd like to create a TextFieldNumeric component that has the same
 * min/max properties as the native number input, but doesn't have the
 * many-other-problems that come with the native number input.
 **/
const onWheelHandlerToPreventChangingValue =
  (props: TextFieldProps) => (e: any) => {
    if (props.type === 'number') {
      e.currentTarget.blur();
    }
    props.onWheel?.(e);
  };

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(
    {
      children,
      size = 'medium',
      className,
      style,
      ...rawProps
    }: TextFieldProps,
    ref
  ) {
    const classes = useStyles();
    const [formikProps, meta] = useField({
      name: rawProps.name ?? rawProps.id,
      ...rawProps,
    });

    const props: TextFieldProps = {
      validationState: meta.touched && meta.error ? 'error' : 'default',
      validationMessage: meta.touched && meta.error ? meta.error : undefined,
      size,
      ...rawProps,
    };

    return (
      <Field {...props} inputId={rawProps.id} className={className}>
        <input
          data-test={`textField-${rawProps.id}`}
          ref={ref}
          onWheel={onWheelHandlerToPreventChangingValue(rawProps)}
          {...formikProps}
          {...filterFieldInputProps(rawProps)}
          {...getInputProps(props, classes)}
          style={style}
        />
        {children}
      </Field>
    );
  }
);

export default TextField;

export const TextFieldNonFormik = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextFieldNonFormik(
    { children, size = 'medium', className, style, fsUnmask, ...props },
    ref
  ) {
    const classes = useStyles();
    return (
      <Field {...props} inputId={props.id} className={className}>
        <input
          ref={ref}
          {...filterFieldInputProps(props)}
          {...getInputProps({ size, fsUnmask, ...props }, classes)}
          style={style}
          onWheel={onWheelHandlerToPreventChangingValue(props)}
        />
        {children}
      </Field>
    );
  }
);

export interface TextFieldSearchProps extends Omit<TextFieldProps, 'label'> {
  label?: LocalizedString;
  showSearchIcon?: boolean;
}

/**
 * A search field with a search icon.
 */
export const TextFieldSearch = forwardRef<
  HTMLInputElement,
  TextFieldSearchProps
>(function TextFieldSearch(
  {
    label,
    visuallyHideLabel = true,
    className,
    size = 'medium',
    showSearchIcon = true,
    ...props
  },
  ref
) {
  const { t } = useLingui();
  label ??= t({
    message: 'Search',
    context: 'Default placeholder for search field',
  });

  const classes = useStyles();
  return (
    <TextFieldNonFormik
      data-test={`textFieldSearch-${props.id}`}
      ref={ref}
      visuallyHideLabel={visuallyHideLabel}
      placeholder={label}
      {...props}
      size={size}
      label={label}
      type="search"
      className={clsx(
        size === 'small' ? classes.searchSmall : classes.searchMedium,
        className
      )}
    >
      {showSearchIcon && (
        <SearchIcon
          color={(theme) => theme.palette.secondary.dark}
          size={size === 'small' ? 16 : 20}
        />
      )}
    </TextFieldNonFormik>
  );
});

export const TextFieldMultiline = forwardRef<
  HTMLTextAreaElement,
  TextFieldMultilineProps
>(function TextFieldMultiline({ children, ...rawProps }, ref) {
  const classes = useStyles();
  const [formikProps, meta] = useField({
    name: rawProps.name ?? rawProps.id,
    ...rawProps,
  });

  const props: TextFieldMultilineProps = {
    validationState: meta.touched && meta.error ? 'error' : 'default',
    validationMessage: meta.touched && meta.error ? meta.error : undefined,
    ...rawProps,
  };

  return (
    <Field {...props} inputId={rawProps.id}>
      <TextareaAutosize
        ref={ref}
        {...formikProps}
        {...filterFieldInputProps(rawProps)}
        {...getInputProps(props, classes)}
        minRows={props.rows ?? 2}
        maxRows={props.maxRows}
      />
      {children}
    </Field>
  );
});

export const TextFieldMultilineNonFormik = forwardRef<
  HTMLTextAreaElement,
  TextFieldMultilineProps
>(function TextFieldMultilineNonFormik(
  { children, ...props }: TextFieldMultilineProps,
  ref
) {
  const classes = useStyles();
  const spreadProps: ReturnType<typeof filterFieldInputProps> &
    ReturnType<typeof getInputProps> = {
    ...filterFieldInputProps(props),
    ...getInputProps(props, classes),
  };
  if (spreadProps.value != null) {
    spreadProps.value = String(spreadProps.value);
  }
  return (
    <Field {...props} inputId={props.id}>
      <TextareaAutosize
        data-test={`textField-${props.id}`}
        ref={ref}
        {...spreadProps}
        minRows={props.rows ?? 2}
      />
      {children}
    </Field>
  );
});

// For use with MUI Datagrid
export function TextEditCell({
  id,
  field,
  value,
  ...props
}: GridRenderEditCellParams) {
  const apiRef = useGridApiContext();
  return (
    <TextFieldNonFormik
      {...props}
      id={field}
      value={value}
      onChange={async (event) => {
        await apiRef.current.setEditCellValue({
          id,
          field,
          value: event.target.value ?? '',
          debounceMs: 10,
        });
      }}
    />
  );
}
