import Icon, { IconProps } from '../Icon';
const MeterIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M16 6h4a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-4V6Zm-2 0H4a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h10V6ZM1 7a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V7Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default MeterIcon;
