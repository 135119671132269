/* @skip-file-for-translation */
import invariant from 'invariant';
import { plural, t } from '@lingui/core/macro';
import { GQPermissionType } from '../generated/graphql';
import {
  getPermissionTypeDisplayName,
  getPermissionDescription,
} from './permissionsDisplayUtils';
import { SupportedLocale } from '@watershed/intl/constants';

import { formatList } from '@watershed/intl/formatters';

export function formatObjectName(
  locale: SupportedLocale,
  objectName?: string
): string {
  return (
    objectName?.toLocaleLowerCase(locale) ||
    t({ message: 'unknown', context: 'Unknown object name' })
  );
}

export function formatAddPermissionDescription(
  items: ReadonlyArray<{
    permission: GQPermissionType;
    objectName?: string;
  }>,
  locale: SupportedLocale
): string {
  // TODO: Allow cross permission type assignment?
  invariant(
    items.every((item) => item.permission === items[0].permission),
    'Permissions not all matching'
  );
  const firstPermission = items[0].permission;

  switch (firstPermission) {
    case GQPermissionType.ApproveDatasource:
    case GQPermissionType.ManageDatasource:
      if (items[0].objectName) {
        const formattedItems = items.map((item) =>
          formatObjectName(locale, item.objectName)
        );
        const listOfItems = formatList(formattedItems, {
          style: 'short',
          type: 'conjunction',
          locale,
        });

        return plural(items.length, {
          one: `${listOfItems} data source`,
          other: `${listOfItems} data sources`,
        });
      }
      return firstPermission === GQPermissionType.ApproveDatasource
        ? t`View and approve all data sources`
        : t`Upload and view summaries for all data sources`;

    default:
      const permissionDisplayName =
        getPermissionTypeDisplayName(firstPermission);
      const permissionDescription = getPermissionDescription(firstPermission);
      return (
        permissionDescription ??
        t`Grant collaborators ${permissionDisplayName} access`
      );
  }
}
