import mergeWith from 'lodash/mergeWith';
import { FinanceFiltersType } from '../../apiSchemas/finance/zodFinance';
import uniq from 'lodash/uniq';

export function combineFilters(filters: Array<FinanceFiltersType | {}>) {
  const combinedFilters = mergeWith(
    {},
    ...filters,
    (objValue: Array<string>, srcValue: Array<string>) => {
      if (Array.isArray(objValue) && Array.isArray(srcValue)) {
        return uniq(objValue.concat(srcValue)); // Merge arrays by concatenation
      }
    }
  );
  return combinedFilters;
}

export function stripAssetIdFilter(filters: any) {
  // Check if filter is an object and has 'assetId' property
  if (filters && typeof filters === 'object' && 'assetId' in filters) {
    // Use object destructuring to create a copy of the object without 'assetId'
    const { assetId: _, ...filteredFilter } = filters;
    return filteredFilter;
  }

  // Return as is if filter is not an object or doesn't have 'assetId'
  return filters;
}
