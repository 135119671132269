import { Typography } from '@mui/material';

import { normalizeValueAllowEmpty } from '@watershed/shared-universal/utils/helpers';
import isNotNullish from '@watershed/shared-util/isNotNullish';
/* need to import react-hook-form for typescript to infer the type of ZodForm below */
import 'react-hook-form';
import Field from '@watershed/ui-core/components/Form/Field';
import NumberField, {
  numberFormatterAllowEmpty,
} from '@watershed/ui-core/components/Form/NumberField';
import {
  CommonZodFieldProps,
  getInputFieldProps,
  getCommonFieldProps,
  useFieldPropsFromTsController,
  CommonControllerProps,
} from './fieldUtils';
import {
  AbstractSelectProps,
  ZodAbstractSelectField,
  ZodSelectFieldEditorType,
} from './ZodSelectFields';
import { AbstractZodMultiInputField } from './ZodArrayField';
import { SupportedLocale } from '@watershed/intl/constants';

type UnitFieldProps = {
  unit: string | null;
} & CommonZodFieldProps;

type NumberFieldProps = {
  noFormatter?: boolean;
  formatter?: (unformattedStr: string, locale: SupportedLocale) => string;
} & CommonZodFieldProps;

// TODO: This is all stolen from NumberFieldWithUnits.tsx. Need to decide how we want to handle
// these fields that have a number and a unit, but also other possible formatting options.
function ZodUnitField({ unit, ...restProps }: UnitFieldProps) {
  const { onChange, ...tsProps } = useFieldPropsFromTsController<number>();
  return (
    <Field
      {...tsProps}
      {...getInputFieldProps(tsProps)}
      {...restProps}
      inputId={tsProps.id}
    >
      {unit === '%' ? (
        <NumberField
          {...tsProps}
          {...getInputFieldProps(restProps)}
          {...getCommonFieldProps(restProps)}
          value={
            isNotNullish(tsProps.value)
              ? Number(tsProps.value) * 100
              : undefined
          }
          onChange={(value) => {
            isNotNullish(value)
              ? onChange(Number(value) / 100)
              : onChange(null);
          }}
          normalizer={normalizeValueAllowEmpty}
          formatter={numberFormatterAllowEmpty}
          suppressValidation
          // prevent scrolling from changing number
          onWheel={(event) => event.currentTarget.blur()}
          data-test={`ZodField-${restProps.name}`}
          showSteppers={false}
        />
      ) : (
        <NumberField
          {...tsProps}
          {...getInputFieldProps(restProps)}
          {...getCommonFieldProps(restProps)}
          value={tsProps.value?.toString() || ''}
          onChange={(value) => onChange(value)}
          normalizer={normalizeValueAllowEmpty}
          formatter={numberFormatterAllowEmpty}
          suppressValidation
          // prevent scrolling from changing number
          onWheel={(event) => event.currentTarget.blur()}
          data-test={`ZodField-${restProps.name}`}
          showSteppers={!unit}
        />
      )}
      {unit && (
        <Typography
          variant="body2"
          sx={{
            position: 'absolute',
            top: 5,
            right: 8,
            pointerEvents: 'none',
          }}
        >
          {unit}
        </Typography>
      )}
    </Field>
  );
}

export type AbstractNumberFieldProps = CommonZodFieldProps &
  CommonControllerProps<number | string | null> &
  (UnitFieldProps | NumberFieldProps);

export function ZodAbstractNumberField(props: AbstractNumberFieldProps) {
  const { onChange, ...tsProps } = useFieldPropsFromTsController<
    number | string | null
  >({
    onChangeTransform: props.onChangeTransform,
  });
  if ('unit' in props) {
    // This seems crazy...
    return <ZodUnitField {...props} />;
  }
  return (
    <Field
      {...tsProps}
      {...getInputFieldProps(props)}
      {...getCommonFieldProps(props)}
      inputId={tsProps.id}
    >
      <NumberField
        data-test={`ZodField-${props.name}`}
        {...tsProps}
        {...getInputFieldProps(props)}
        {...getCommonFieldProps(props)}
        value={tsProps.value ?? undefined}
        onChange={onChange}
        normalizer={normalizeValueAllowEmpty}
        formatter={
          'formatter' in props && props.formatter
            ? props.formatter
            : 'noFormatter' in props && props.noFormatter
              ? undefined
              : numberFormatterAllowEmpty
        }
        suppressValidation
      />
    </Field>
  );
}

export function ZodNumberField(
  props: AbstractNumberFieldProps | AbstractSelectProps<number | null>
) {
  if ('listOptions' in props) {
    return <ZodAbstractSelectField<number | null> {...props} />;
  }
  return <ZodAbstractNumberField {...props} />;
}

/**
 * Multi select number field.
 */
export function ZodNumberArrayField(
  props: AbstractNumberFieldProps | AbstractSelectProps<number | null>
) {
  if (!('listOptions' in props)) {
    return (
      <AbstractZodMultiInputField<number | string, AbstractNumberFieldProps>
        {...props}
      />
    );
  }
  return (
    <ZodAbstractSelectField<number | null>
      editorType={ZodSelectFieldEditorType.SelectAutocomplete}
      {...props}
      multiple={props.multiple ?? true}
    />
  );
}
