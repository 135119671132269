import invariant from 'invariant';
import { Box, BoxProps, Theme } from '@mui/material';
import type { SystemCssProperties } from '@mui/system';
import { ChartColorScale } from './common';
import { mixinSx } from '@watershed/style/styleUtils';
import { CATEGORICAL_DATA_VIZ_SCALE } from '@watershed/style/palette';

export default function LegendDot({
  colorScale = CATEGORICAL_DATA_VIZ_SCALE,
  colorScaleIndex = -1,
  color,
  size,
  marginRight = 0,
  marginTop = 0,
  hollow = false,
  round = true,
  display,
  sx,
}: {
  colorScale?: ChartColorScale;
  colorScaleIndex?: number;
  color?: SystemCssProperties<Theme>['color'];
  size: number;
  marginRight?: number;
  marginTop?: number | string;
  hollow?: boolean;
  round?: boolean;
  display?: 'inline-block' | 'block';
  sx?: BoxProps['sx'];
}) {
  let backgroundColor = color;
  if (color === undefined) {
    invariant(
      -1 <= colorScaleIndex,
      `unexpected color index: ${colorScaleIndex}`
    );
    backgroundColor =
      colorScaleIndex === -1
        ? 'black'
        : colorScale[colorScaleIndex % colorScale.length];
  }
  return (
    <Box
      width={size}
      height={size}
      flexShrink={0}
      marginRight={marginRight}
      marginTop={marginTop}
      display={display}
      sx={mixinSx(
        hollow
          ? {
              border: '2px solid',
              borderColor: backgroundColor,
            }
          : {
              backgroundColor,
            },
        round ? { borderRadius: `${size / 2}px` } : { borderRadius: '2px' },
        sx
      )}
    />
  );
}
