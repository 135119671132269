import Icon, { IconProps } from '../Icon';
const FootprintsIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5ZM2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M7 6a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1Zm3.804.02a1 1 0 0 1 1.176.784l2 10a1 1 0 1 1-1.96.392l-2-10a1 1 0 0 1 .784-1.177Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default FootprintsIcon;
