import {
  GQCreatePermissionItemInput,
  GQDataIssueWithCommentsForMeasurementPageFragment,
  GQPermissionObjectType,
  GQPermissionType,
} from '@watershed/shared-universal/generated/graphql';
import flattenConnection, {
  TConnectionType,
} from '@watershed/shared-universal/utils/flattenConnection';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';

export function getLatestTimestampForDataIssue(issue: {
  createdAt: Date;
  comments: TConnectionType<{ createdAt: Date }>;
}) {
  const comments = flattenConnection(issue.comments);
  const latestComment = comments.at(-1);

  // T-40514: technically, this is the time it was created as a draft rather
  // than published
  return latestComment?.createdAt ?? issue.createdAt;
}

export function latestCommentIsByWatershed(
  issue: GQDataIssueWithCommentsForMeasurementPageFragment
) {
  const comments = flattenConnection(issue.comments);
  const lastComment = orderBy(
    comments.filter((comment) => comment.isPublished),
    [(comment) => comment.createdAt],
    ['desc']
  )[0];
  // If there's no comment, it's only the data issue created by Watershed.
  return (
    lastComment === undefined ||
    !!lastComment.person?.isWatershedEmployee ||
    !!lastComment.person?.isWatershedContractor
  );
}

export function getDataIssuePermissionsForDatasource(
  datasourceId: string,
  datasourceName: string
): Array<GQCreatePermissionItemInput & { objectName?: string }> {
  return [
    {
      objectId: datasourceId,
      objectName: datasourceName,
      objectType: GQPermissionObjectType.Datasource,
      permission: GQPermissionType.ManageDatasource,
    },
    {
      objectId: datasourceId,
      objectName: datasourceName,
      objectType: GQPermissionObjectType.Datasource,
      permission: GQPermissionType.ApproveDatasource,
    },
  ];
}

export function formatCommentForMentions(
  commentText: string,
  usersMentionedId: Array<string>,
  userDataById: Record<string, { id: string; name: string }>
): [string, Array<string>] {
  let formattedCommentText = commentText;
  const actualUsersMentionedId: Array<string> = [];
  usersMentionedId.forEach((userId) => {
    const user = userDataById[userId];
    if (formattedCommentText.includes('@' + user.name)) {
      actualUsersMentionedId.push(userId);
    }
    formattedCommentText = formattedCommentText.replace(
      '@' + user.name,
      `<@${user.id}>`
    );
  });

  return [formattedCommentText, uniq(actualUsersMentionedId)];
}
