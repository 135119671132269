import { GQBiChartKind, GQBiQueryFilter } from '../generated/graphql';
import { BiNormalizedMeasure, BiQueryComponentHashKey } from './types';
import { YMInterval } from '../utils/YearMonth';

/**
 * TODO split this up into a union type of BiActivityQueryParams
 * and BiSnapshotQueryParams that extend BiCommonQueryParams
 */
export type BiQueryParams = {
  measures?: Array<BiNormalizedMeasure> | null;
  filters?: Array<GQBiQueryFilter> | null;
  dimensions?: Array<string> | null;
  timeIntervals?: Array<YMInterval> | null;
  view?: string | null;
  chartKind?: GQBiChartKind | null;
  // footprint specific
  footprintKind?: string | null;
  searchTerm?: string | null;
  // activity specific
  measurementProjectIds?: Array<string> | null;
  datasourceIds?: Array<string> | null;
};

export function makeBiQueryHashParams(params: BiQueryParams): {
  [key: string]: string | undefined | null;
} {
  return {
    [BiQueryComponentHashKey.footprintKind]: getStringifiedOrNull(
      params.footprintKind
    ),
    [BiQueryComponentHashKey.view]: getStringifiedOrNull(params.view),
    [BiQueryComponentHashKey.chartKind]: getStringifiedOrNull(params.chartKind),
    [BiQueryComponentHashKey.filters]: getStringifiedOrNull(params.filters),
    [BiQueryComponentHashKey.dimensions]: getStringifiedOrNull(
      params.dimensions
    ),
    [BiQueryComponentHashKey.measures]: getStringifiedOrNull(params.measures),
    [BiQueryComponentHashKey.intervals]: getStringifiedOrNull(
      params.timeIntervals
    ),
    [BiQueryComponentHashKey.measurementProjectIds]: getStringifiedOrNull(
      params.measurementProjectIds
    ),
    [BiQueryComponentHashKey.datasourceIds]: getStringifiedOrNull(
      params.datasourceIds
    ),
  };
}

export function getStringifiedOrNull(value: any | null): string | null {
  return value ? JSON.stringify(value) : null;
}
