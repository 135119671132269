import Icon, { IconProps } from '../Icon';
const BoxSumIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16 16" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M3.333 2.667a.667.667 0 0 0-.667.666v9.334c0 .368.299.666.667.666h9.333a.667.667 0 0 0 .667-.666V3.333a.667.667 0 0 0-.667-.666H3.333Zm-2 .666a2 2 0 0 1 2-2h9.333a2 2 0 0 1 2 2v9.334a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V3.333Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M4.732 4.378a.667.667 0 0 1 .6-.378h5.334c.368 0 .667.298.667.667v1.266a.667.667 0 0 1-1.334 0v-.6H6.72l1.8 2.25a.667.667 0 0 1 0 .833l-1.8 2.25H10v-.6a.667.667 0 1 1 1.334 0v1.267a.667.667 0 0 1-.667.667H5.333a.667.667 0 0 1-.52-1.083L7.145 8 4.812 5.083a.667.667 0 0 1-.08-.705Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default BoxSumIcon;
