import {
  makeCustomErrorInvariant,
  CustomErrorInvariant,
  WatershedErrorOptions,
  WatershedError,
  makeRethrower,
} from './WatershedError';

export class BadDataError extends WatershedError {
  status = 500;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('BAD_DATA_ERROR', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BadDataError);
    }
    Object.setPrototypeOf(this, BadDataError.prototype);
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(BadDataError);
  static wrapAndRethrow = makeRethrower(BadDataError);
}
