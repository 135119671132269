import Icon, { IconProps } from '../Icon';
const ArrowRightIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M19.95 12.375a1.278 1.278 0 0 0 0-.75 1.035 1.035 0 0 0-.225-.3L12.75 4.35c-.375-.375-1.05-.375-1.425 0s-.375 1.05 0 1.425L16.65 11.1H5.025a.993.993 0 0 0-.975.975c0 .525.45.975.975.975h11.55l-5.325 5.325c-.375.375-.375 1.05 0 1.425s1.05.375 1.425 0l6.975-6.975c.15-.225.225-.3.3-.45Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ArrowRightIcon;
