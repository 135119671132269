import invariant from 'invariant';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';

import { Analytics } from '@watershed/analytics/analyticsUtils';
import {
  getFromStorage,
  setStorage,
} from '@watershed/shared-frontend/hooks/useStorageState';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { YMInterval } from '@watershed/shared-universal/utils/YearMonth';
import must from '@watershed/shared-universal/utils/must';
import {
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
} from '@watershed/ui-core/components/DataGrid/DataGrid';
import {
  CommitmentAndTargetYearFilterValue,
  SupplierGridFilterOperatorValue,
} from './suppliersTableColumns/filterOperators';
import optionalOnly from '@watershed/shared-universal/utils/optionalOnly';
import only from '@watershed/shared-universal/utils/only';
import { trailing12MonthsInterval } from '@watershed/shared-universal/utils/SuppliersUtils';
import {
  GQBiFilterOperator,
  GQFilterExpressionGroupWithNewFilters,
} from '@watershed/shared-universal/generated/graphql';

const STORAGE_KEY = 'supplierFilters';

enum FilterKey {
  GridFilterModel = 'gridFilterModel',
  SelectedInterval = 'selectedInterval',
  SupplierView = 'supplierView',
  ViewFilterOverride = 'viewFilterOverride',
}

type Filters = {
  [FilterKey.GridFilterModel]: GridFilterModel;
  [FilterKey.SelectedInterval]: YMInterval | null;
  [FilterKey.SupplierView]: string | null;
  [FilterKey.ViewFilterOverride]: GQFilterExpressionGroupWithNewFilters | null;
};

const EMPTY_GRID_FILTER_MODEL: GridFilterModel = Object.freeze({
  items: [],
  logicOperator: GridLogicOperator.And,
});

function createEmptyFilters(): Filters {
  return {
    [FilterKey.GridFilterModel]: EMPTY_GRID_FILTER_MODEL,
    [FilterKey.SelectedInterval]: null,
    [FilterKey.SupplierView]: null,
    [FilterKey.ViewFilterOverride]: null,
  };
}

type Location = { search: string; pathname: string; hash: string };

type SupplierFiltersArgs = {
  location: Location;
  push: (location: Location) => void;
  replace: (location: Location) => void;
  visibleInterval: YMInterval;
};

/**
 * SupplierFilters manages filters for the suppliers page. It handles updating
 * the URL, session storage, and updating the filters when URL changes. The
 * filters are initialized from URL search params, or from session storage if no
 * URL search params are present.
 */
export class SupplierFilters {
  private readonly _location: Location;
  private readonly _push: (location: Location) => void;
  private readonly _replace: (location: Location) => void;
  private readonly _visibleInterval: YMInterval;
  private _filters: Filters = createEmptyFilters();

  constructor(args: SupplierFiltersArgs) {
    this._location = args.location;
    this._push = args.push;
    this._replace = args.replace;
    this._visibleInterval = args.visibleInterval;

    // Initialize filters from URL search params, or from session storage,
    // and then update the URL search params to match the filters.
    this.initializeFilters();
    this.updateSearchParamsAndSessionStorage({ replace: true });
  }

  /**
   * Return the filters for the given field
   *
   * @param field The field of the filter.
   * @returns DataGrid GridFilterItem for the given field.
   */
  getFilters(field: string): Array<GridFilterItem> {
    return this._filters[FilterKey.GridFilterModel].items.filter(
      (i) => i.field === field
    );
  }

  /**
   * Append a oneOf filter for the given singleSelect column. If a filter
   * with this value already exists, the new filter will be ignored, and the
   * operation is a no-op.
   *
   * @param field The field of the filter.
   * @param value Filter to append to the filter list.
   */
  appendFilterForSingleSelectColumn(field: string, value: string) {
    const filtersForColumn = this.getFilters(field);
    invariant(
      filtersForColumn.length < 2,
      'Expected at most a single oneOf filter'
    );
    const filterForColumn = filtersForColumn[0];

    // oneOf is a custom filter operator defined in filterOperators.ts
    this.setFilterForSingleSelectColumn(
      field,
      uniq([...(filterForColumn?.value ?? []), value])
    );
  }

  /**
   * Append an arrayContains filter for the given column. If a filter
   * with this value already exists, the new filter will be ignored, and the
   * operation is a no-op.
   *
   * @param field The field of the filter.
   * @param value Filter to append to the filter list.
   */
  appendFilterForArrayContainsColumn(field: string, value: string) {
    const filtersForColumn = this.getFilters(field);
    const filterForColumn = optionalOnly(
      filtersForColumn,
      'Expected at most a single arrayContains filter'
    );

    // custom filter operator defined in filterOperators.ts
    this.setFilterForArrayContainsColumn(
      field,
      uniq([...(filterForColumn?.value ?? []), value])
    );
  }

  /**
   * Delete a oneOf filter for the given singleSelect column. If the filter does
   * not exist, the operation is a no-op.
   *
   * @param field The field of the filter.
   * @param singleValue Filter to delete from the filter list.
   */
  deleteFilterForSingleSelectColumn(field: string, value: string) {
    const filtersForColumn = this.getFilters(field);
    invariant(filtersForColumn.length === 1, 'Expected a single oneOf filter');
    const filterForColumn = filtersForColumn[0];
    const newValues = filterForColumn.value.filter((v: string) => v !== value);
    if (newValues.length === 0) {
      this.setFilterForSingleSelectColumn(field, []);
    } else {
      this.setFilterForSingleSelectColumn(field, newValues);
    }
  }

  /**
   * Delete a arrayContains filter for the given arrayContains column. If the filter does
   * not exist, the operation is a no-op.
   *
   * @param field The field of the filter.
   * @param value Formatted value to delete from the filter list.
   */
  deleteFilterForArrayContainsColumn(field: string, value: string) {
    const filtersForColumn = this.getFilters(field);
    const filterForColumn = only(
      filtersForColumn,
      'Expected a single includes filter'
    );
    const newValues = filterForColumn.value.filter(
      (v: { name: string; value: any }) => v.name !== value
    );
    this.setFilterForArrayContainsColumn(field, newValues);
  }

  /**
   * Delete a ghgCategoryIds filter for the given column. If the filter does
   * not exist, the operation is a no-op.
   *
   * @param field The field of the filter.
   * @param singleValue Filter to delete from the filter list.
   */
  deleteFilterForGhgCategoryIdsColumn(field: string, value: string) {
    const filtersForColumn = this.getFilters(field);
    invariant(
      filtersForColumn.length === 1,
      'Expected a single ghgCategoryIds filter'
    );
    const filterForColumn = filtersForColumn[0];
    const newValues = filterForColumn.value.filter((v: string) => v !== value);
    if (newValues.length === 0) {
      this.setFilterForGhgCategoryIdsColumn(field, []);
    } else {
      this.setFilterForGhgCategoryIdsColumn(field, newValues);
    }
  }

  /**
   * Sets a ghgCategoryIds filter for the given column to the given values.
   *
   * @param field The field of the filter.
   * @param values The filter values.
   */
  setFilterForGhgCategoryIdsColumn(field: string, values: Array<string>) {
    if (values.length === 0) {
      this.setFiltersForColumn(field, []);
      return;
    }
    const newGhgCategoryIdsFilter: GridFilterItem = {
      field,
      value: values,
      operator: SupplierGridFilterOperatorValue.GhgCategories,
      id: field,
    };
    this.setFiltersForColumn(field, [newGhgCategoryIdsFilter]);
  }

  /**
   * Sets an array contains filter for the given column to the given values.
   *
   * @param field The field of the filter.
   * @param values The filter values.
   */
  setFilterForArrayContainsColumn(field: string, values: Array<string>) {
    if (values.length === 0) {
      this.setFiltersForColumn(field, []);
      return;
    }
    const newIncludesFilter: GridFilterItem = {
      field,
      value: values,
      operator: SupplierGridFilterOperatorValue.ArrayContains,
      id: field,
    };
    this.setFiltersForColumn(field, [newIncludesFilter]);
  }

  /**
   * Sets a oneOf filter for the given singleSelect column to the given values.
   *
   * @param field The field of the filter.
   * @param values The filter values.
   */
  setFilterForSingleSelectColumn(field: string, values: Array<string>) {
    if (values.length === 0) {
      this.setFiltersForColumn(field, []);
      return;
    }
    const newOneOfFilter: GridFilterItem = {
      field,
      value: values,
      operator: SupplierGridFilterOperatorValue.OneOf,
      id: field,
    };
    this.setFiltersForColumn(field, [newOneOfFilter]);
  }

  /**
   * Sets a commitment and target year filter for the given commitment column to the given values.
   *
   * @param field The field of the filter.
   * @param values The filter values.
   */
  setFilterForCommitmentAndTargetYearColumn(
    field: string,
    value: CommitmentAndTargetYearFilterValue
  ) {
    if (value.commitmentStatus.length === 0 && value.targetYear === null) {
      this.setFiltersForColumn(field, []);
      return;
    }
    const newCommitmentFilter: GridFilterItem = {
      field,
      value,
      operator: SupplierGridFilterOperatorValue.CommitmentAndFilterDate,
      id: field,
    };
    this.setFiltersForColumn(field, [newCommitmentFilter]);
  }

  /**
   * Deletes a filter for the given commitment column.
   *
   * @param field The field of the filter.
   * @param values The filter commitmentStatus value.
   */
  deleteFilterForCommitmentAndTargetYearColumn(
    field: string,
    commitmentStatus: string | null
  ) {
    const filtersForColumn = this.getFilters(field);
    invariant(filtersForColumn.length === 1, 'Expected a single filter');
    const currentValue = filtersForColumn[0]
      .value as CommitmentAndTargetYearFilterValue;
    const newCommitmentStatus = currentValue.commitmentStatus.filter(
      (v: string) => v !== commitmentStatus
    );
    if (newCommitmentStatus.length === 0) {
      this.setFiltersForColumn(field, []);
    } else {
      this.setFiltersForColumn(field, [
        {
          ...filtersForColumn[0],
          value: {
            commitmentStatus: newCommitmentStatus,
            targetYear: currentValue.targetYear,
          },
        },
      ]);
    }
  }

  getBiQueryFilters() {
    return this.getGridFilterModel().items.map(({ field, value, operator }) => {
      let biOperator = GQBiFilterOperator.In;
      switch (operator) {
        // TODO: Support the other MUI grid operators
        case SupplierGridFilterOperatorValue.ArrayContains:
        case SupplierGridFilterOperatorValue.CommitmentAndFilterDate:
        case SupplierGridFilterOperatorValue.GhgCategories:
        case SupplierGridFilterOperatorValue.OneOf:
        default:
          biOperator = GQBiFilterOperator.In;
      }
      return {
        dimension: field,
        value,
        operator: biOperator,
      };
    });
  }

  /**
   * Sets a search text filter on the table.
   */
  setSearchText(searchText: string | null) {
    this._filters[FilterKey.GridFilterModel] = {
      ...this._filters[FilterKey.GridFilterModel],
      quickFilterValues: searchText?.length ? [searchText] : [],
    };
    this.updateSearchParamsAndSessionStorage({ replace: true });
  }

  /**
   * Returns the current search text filter on the table, if any
   */
  getSearchText() {
    return (
      this._filters[FilterKey.GridFilterModel].quickFilterValues?.[0] ?? null
    );
  }

  /**
   * Remove grid filters.
   */
  resetGridFilters() {
    this._filters[FilterKey.GridFilterModel] = EMPTY_GRID_FILTER_MODEL;
    this.updateSearchParamsAndSessionStorage();
  }

  /**
   * Returns true if there is any grid filters.
   */
  hasGridFilters() {
    return Boolean(
      this._filters[FilterKey.GridFilterModel].items.length > 0 ||
        (this._filters[FilterKey.GridFilterModel].quickFilterValues &&
          this._filters[FilterKey.GridFilterModel].quickFilterValues.length > 0)
    );
  }

  /**
   * Return the underling MUI DataGrid filter model for the current filters.
   */
  getGridFilterModel(): GridFilterModel {
    return this._filters[FilterKey.GridFilterModel];
  }

  /**
   * Return the number of active filters.
   */
  getNumActiveFilters(): number {
    return this._filters[FilterKey.GridFilterModel].items.length;
  }

  /**
   * Replace the filters for the given field with the given filters.
   *
   * @param field The field of the filter.
   * @param values Filters to set for the given key.
   */
  private setFiltersForColumn(
    field: string,
    newFilters: Array<GridFilterItem>
  ) {
    const newItems = [
      ...this._filters[FilterKey.GridFilterModel].items.filter(
        (i) => i.field !== field
      ),
      ...newFilters.filter((i) => i.field === field),
    ];
    this._filters[FilterKey.GridFilterModel] = {
      ...this._filters[FilterKey.GridFilterModel],
      items: newItems,
    };
    this.updateSearchParamsAndSessionStorage();
    Analytics.action(`suppliers.filter.${field}`, {
      values: newFilters.map((f) => f.value),
    });
  }

  /**
   * Return the selected interval.
   */
  getSelectedInterval(): YMInterval {
    return (
      this._filters[FilterKey.SelectedInterval] ??
      this.getTrailing12MonthsInterval()
    );
  }

  /**
   * Update the selected interval.
   */
  setSelectedInterval(interval: YMInterval): void {
    invariant(
      this.isWithinVisibleInterval(interval),
      `Visible interval ${this._visibleInterval.toURLString()} does not contain selected interval ${interval?.toURLString()}`
    );
    this._filters[FilterKey.SelectedInterval] = interval;
    this.updateSearchParamsAndSessionStorage();
  }

  /**
   * Return true if there is a selected interval.
   */
  hasSelectedInterval(): boolean {
    return this._filters[FilterKey.SelectedInterval] !== null;
  }

  /**
   * Returns true if there is a view filter.
   */
  hasSupplierView() {
    return Boolean(this._filters[FilterKey.SupplierView]);
  }

  /**
   * Sets the view filter.
   */
  setSupplierView(viewId: string | null) {
    this._filters[FilterKey.SupplierView] = viewId;
    // Clear any live view filters if the view is changed
    this.setViewFilterOverride(null);
    this.updateSearchParamsAndSessionStorage();
  }

  /**
   * Return the view filter
   *
   * @returns The id of the currently selected view.
   */
  getSupplierView(): string | null {
    return this._filters[FilterKey.SupplierView];
  }

  /**
   * Returns true if there is a view filter override.
   */
  hasViewFilterOverride() {
    return Boolean(this._filters[FilterKey.ViewFilterOverride]);
  }

  /**
   * Sets the view filter override.
   */
  setViewFilterOverride(
    ViewFilterOverride: GQFilterExpressionGroupWithNewFilters | null
  ) {
    this._filters[FilterKey.ViewFilterOverride] = ViewFilterOverride;
    this.updateSearchParamsAndSessionStorage();
  }

  /**
   * Return the filter expression group that overrides the current view
   *
   * @returns The id of the currently selected view.
   */
  getViewFilterOverride(): GQFilterExpressionGroupWithNewFilters | null {
    return this._filters[FilterKey.ViewFilterOverride];
  }

  private isWithinVisibleInterval(interval: YMInterval): boolean {
    return this._visibleInterval.containsInterval(interval);
  }

  private getTrailing12MonthsInterval(): YMInterval {
    return trailing12MonthsInterval(this._visibleInterval);
  }

  private initializeFilters() {
    const params = new URLSearchParams(this._location.search);
    const sessionStoredFilters = getFromStorage(
      STORAGE_KEY,
      createEmptyFilters(),
      window.sessionStorage
    );
    const localStoredFilters = getFromStorage(
      STORAGE_KEY,
      createEmptyFilters(),
      window.localStorage
    );

    this._filters[FilterKey.GridFilterModel] = this.getInitialGridFilterModel(
      params,
      sessionStoredFilters
    );
    this._filters[FilterKey.SelectedInterval] = this.getInitialSelectedInterval(
      params,
      localStoredFilters
    );
    this._filters[FilterKey.SupplierView] = this.getInitialSupplierView(
      params,
      localStoredFilters
    );
    this._filters[FilterKey.ViewFilterOverride] =
      this.getInitialViewFilterOverride(params, localStoredFilters);
  }

  private getInitialGridFilterModel(
    params: URLSearchParams,
    storedFilters: Filters
  ): GridFilterModel {
    const param = params.get(FilterKey.GridFilterModel);
    // temporary - can delete this after Aug 25 2023 ish
    // get rid of filters that we no longer support
    const upgradeFilters = (oldModel: GridFilterModel) => ({
      ...oldModel,
      items: oldModel.items.filter(
        (item) =>
          !(
            item.field === 'disclosures' &&
            item.operator === SupplierGridFilterOperatorValue.OneOf
          )
      ),
    });
    if (param) {
      try {
        return upgradeFilters(JSON.parse(param));
      } catch (e) {
        // Invalid grid filter, ignore.
        console.warn(`Invalid URL param for grid filter model: ${param}`);
      }
    }
    return upgradeFilters(storedFilters[FilterKey.GridFilterModel]);
  }

  private getInitialSelectedInterval(
    params: URLSearchParams,
    storedFilters: Filters
  ): YMInterval {
    // Always return trailing 12 months if the footprint interval is not selectable.
    // We can remove this check once we roll out interval selector widely.
    const param = params.get(FilterKey.SelectedInterval);

    // If the URL param has a valid interval, use it.
    if (param) {
      try {
        const selectedInterval = YMInterval.fromURLString(param);
        if (this.isWithinVisibleInterval(selectedInterval)) {
          return selectedInterval;
        }
      } catch (e) {
        // Invalid interval, ignore.
        console.warn(`Invalid URL param for selected interval: ${param}`);
      }
    }

    // If the session storage has a valid interval, use it.
    // TODO: Add zod schema and validate stored values.
    const selectedInterval = isNotNullish(
      storedFilters[FilterKey.SelectedInterval]
    )
      ? YMInterval.fromObject(storedFilters[FilterKey.SelectedInterval])
      : null;

    if (
      selectedInterval !== null &&
      this.isWithinVisibleInterval(selectedInterval)
    ) {
      return selectedInterval;
    }

    // Otherwise default to trailing 12 months.
    return this.getTrailing12MonthsInterval();
  }

  private getInitialSupplierView(
    params: URLSearchParams,
    storedFilters: Filters
  ): string | null {
    const param = params.get(FilterKey.SupplierView);
    if (param) {
      return param;
    }
    return storedFilters[FilterKey.SupplierView];
  }

  private getInitialViewFilterOverride(
    params: URLSearchParams,
    storedFilters: Filters
  ): GQFilterExpressionGroupWithNewFilters | null {
    const param = params.get(FilterKey.ViewFilterOverride);

    // If the URL param has a valid interval, use it.
    if (param) {
      try {
        return JSON.parse(param);
      } catch (e) {
        // Invalid grid filter, ignore.
        console.warn(`Invalid URL param for live view filter: ${param}`);
      }
    }

    // If the session storage has stored view, use it.
    return storedFilters[FilterKey.ViewFilterOverride];
  }

  private updateSearchParamsAndSessionStorage(
    args: { replace: boolean } = { replace: false }
  ) {
    const oldParams = new URLSearchParams(this._location.search);
    const newParams = new URLSearchParams(this._location.search);

    if (this.hasGridFilters()) {
      newParams.set(
        FilterKey.GridFilterModel,
        JSON.stringify(this.getGridFilterModel())
      );
    } else {
      newParams.delete(FilterKey.GridFilterModel);
    }

    // Only add selected interval to URL if the footprint interval is selectable.
    // We can remove this check once we roll out interval selector widely.
    if (this.hasSelectedInterval()) {
      newParams.set(
        FilterKey.SelectedInterval,
        must(this.getSelectedInterval()).toURLString()
      );
    } else {
      newParams.delete(FilterKey.SelectedInterval);
    }

    if (this.hasSupplierView()) {
      newParams.set(FilterKey.SupplierView, must(this.getSupplierView()));
    } else {
      newParams.delete(FilterKey.SupplierView);
    }

    if (this.hasViewFilterOverride()) {
      newParams.set(
        FilterKey.ViewFilterOverride,
        JSON.stringify(this.getViewFilterOverride())
      );
    } else {
      newParams.delete(FilterKey.ViewFilterOverride);
    }

    const isUrlSearchParamsEqual = (
      params1: URLSearchParams,
      params2: URLSearchParams
    ) => isEqual(new Map(params1.entries()), new Map(params2.entries()));

    if (!isUrlSearchParamsEqual(oldParams, newParams)) {
      const newParamsStr = newParams.toString();
      const newSearch = newParamsStr.length > 0 ? `?${newParamsStr}` : '';

      if (args.replace) {
        this._replace({
          pathname: location.pathname,
          search: newSearch,
          hash: location.hash,
        });
      } else {
        this._push({
          pathname: location.pathname,
          search: newSearch,
          hash: location.hash,
        });
      }
    }

    setStorage(
      STORAGE_KEY,
      omit(this._filters, [
        FilterKey.SupplierView,
        FilterKey.ViewFilterOverride,
        FilterKey.SelectedInterval,
      ]),
      window.sessionStorage
    );
    setStorage(
      STORAGE_KEY,
      pick(
        this._filters,
        FilterKey.SupplierView,
        FilterKey.ViewFilterOverride,
        FilterKey.SelectedInterval
      ),
      window.localStorage
    );
  }
}
