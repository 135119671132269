import React, { useContext, useMemo } from 'react';

import useGlobalLocation from '@watershed/ui-core/hooks/useGlobalLocation';
import { YMInterval } from '@watershed/shared-universal/utils/YearMonth';
import { SupplierFilters } from './SupplierFilters';

const SupplierFiltersContext = React.createContext<SupplierFilters | null>(
  null
);

export function SupplierFiltersContextProvider({
  visibleInterval,
  children,
}: {
  visibleInterval: YMInterval;
  children: React.ReactNode;
}) {
  const { location, push, replace } = useGlobalLocation();

  const value = useMemo<SupplierFilters>(() => {
    return new SupplierFilters({
      location,
      push,
      replace,
      visibleInterval,
    });
  }, [location, push, replace, visibleInterval]);

  return (
    <SupplierFiltersContext.Provider value={value}>
      {children}
    </SupplierFiltersContext.Provider>
  );
}

export function useSupplierFilters() {
  const context = useContext(SupplierFiltersContext);

  // TODO(SUP-2489): Non-supply chain pages should not be using supplier
  // filters. There's currently a dependency on supplier filters in the
  // permissions dialog (useSuppliersData > useSuppliersPageFootprintInterval >
  // useSupplierFilters). Once we remove that dependency, we can uncomment this.
  //
  // if (context === null) {
  //   throw new Error(
  //     'useSupplierFilters must be used within a SupplierFiltersContextProvider'
  //   );
  // }

  return context;
}
