import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Trans, useLingui } from '@lingui/react/macro';
import IconButton from '@watershed/ui-core/components/IconButton';
import SidebarOpenIcon from '@watershed/icons/components/SidebarLeftOpen';
import SidebarCloseIcon from '@watershed/icons/components/SidebarRightOpen';

import { GQPermissionType } from '@watershed/shared-universal/generated/graphql';
import useGlobalLocation from '@watershed/ui-core/hooks/useGlobalLocation';
import { Location } from 'history';

import { useHoverIntent } from 'react-use-hoverintent';

import { SetStateAction, useMemo, useState } from 'react';
import useHasPermission from '../../utils/useHasPermission';
import { useUserContext } from '../../utils/UserContext';
import LogoBar from './LogoBar';
import NavSection from './NavSection';
import { SkipNavLink } from './SkipNav';
import UserBar from './UserBar';
import { DEFAULT_DURATION_MS, NavSectionItem, NavSectionData } from './utils';
import useSidebarFinanceVariant from './variants/SideBarFinanceVariant';
import useSidebarStandardVariant from './variants/SideBarStandardVariant';
import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
  SKIP_NAV_LINK_CLASS,
  getSharedSidebarStyles,
} from './variants/sharedSidebarStyles';
import { mixinSx } from '@watershed/style/styleUtils';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import {
  useIsOnFinancePage,
  useIsOnUnknownPage,
} from '../../utils/CurrentProductContext';
import { hasPermission } from '@watershed/shared-universal/utils/permissionUtils';
import { DevelopmentChip } from '../AdminChipBar';
import { getCurrentDevEnv } from '@watershed/shared-frontend/utils/devEnv';

import {
  BackTo,
  getBackToBreadcrumb,
} from '@watershed/shared-universal/dashboardRoutes';
import { mapStrToEnumOrNull } from '@watershed/shared-universal/utils/mapStrToEnum';
import { Pill } from '@watershed/ui-core/components/Pill';

/*
  This file is just the parent SideBar that knows how to render and work with
  any variants we come up with in the future. It is expected that any variants
  export classes (visual theme) and the navEntries themselves.
*/

function convertURLSpecialChars(pathPart: string) {
  // Can add necessary special char replacements
  return pathPart.replace(/%20/g, ' ');
}

function getMatchScore(entry: NavSectionItem, location: Location<any>): number {
  // The match score is the length of the prefix match of the entry's path and
  // the current location path.
  const entryPathParts = entry.location?.pathname.split('/').slice(1);
  let locationPathParts = location.pathname.split('/').slice(1);

  // Override if there is a "Back To" query parameter; highlight the back to target
  const queryParams = new URLSearchParams(location.search);
  const backToParam = queryParams.get('backTo');
  const backTo = backToParam ? mapStrToEnumOrNull(backToParam, BackTo) : null;

  if (backTo) {
    const backToBreadcrumb = getBackToBreadcrumb(backTo);
    if (backToBreadcrumb) {
      locationPathParts = backToBreadcrumb.url.split('/').slice(1);
    }
  }

  const minLength = Math.min(entryPathParts.length, locationPathParts.length);
  let i = 0;
  while (
    i < minLength &&
    entryPathParts[i] === convertURLSpecialChars(locationPathParts[i])
  ) {
    i++;
  }
  // Bonus point for all parts matching.
  const maxLength = Math.max(entryPathParts.length, locationPathParts.length);
  if (i === maxLength) {
    i++;

    // And an extra bonus if the fragment (hash) matches as well.
    if (location.hash === `#${entry.location.hash}`) {
      i++;
    }
  }
  return i;
}

function findActiveEntry(
  navEntries: Array<NavSectionData | NavSectionItem>,
  location: Location<any>,
  activeEntry: NavSectionItem | null = null,
  activeEntryMatchScore = 0
) {
  for (const entry of navEntries) {
    if ('location' in entry) {
      const matchScore = getMatchScore(entry, location);
      if (
        matchScore > activeEntryMatchScore ||
        (matchScore === activeEntryMatchScore && activeEntry === null)
      ) {
        activeEntry = entry;
        activeEntryMatchScore = matchScore;
      }
    }
    if (entry.subentries?.length) {
      const subEntryResult = findActiveEntry(
        entry.subentries,
        location,
        activeEntry,
        activeEntryMatchScore
      );
      if (
        subEntryResult.matchScore > activeEntryMatchScore ||
        (subEntryResult.matchScore === activeEntryMatchScore &&
          activeEntry === null)
      ) {
        activeEntry = subEntryResult.activeEntry;
        activeEntryMatchScore = subEntryResult.matchScore;
      }
    }
  }
  return { activeEntry, matchScore: activeEntryMatchScore };
}

export default function SideBar({
  isCollapsed,
  setIsCollapsed,
  toggleCreateFinanceSavedView,
}: {
  isCollapsed: boolean;
  setIsCollapsed: (value: SetStateAction<boolean>) => void;
  toggleCreateFinanceSavedView: () => void;
}) {
  const { t } = useLingui();
  const isOnUnknownPage = useIsOnUnknownPage();
  const isOnFinancePage = useIsOnFinancePage();
  const userContext = useUserContext();
  // Is false when an error is thrown and `<ErrorFallback>` is rendered.
  const { hasInitialized } = userContext;
  const isAdmin = useHasPermission([GQPermissionType.Admin]);
  const isWatershedEmployee = userContext.userIsWatershedEmployee;

  const [isSideBarHovered, hoverIntentRef, setIsSideBarHovered] =
    useHoverIntent({
      interval: 75,
      timeout: 100,
    });

  const [isHoverDisabled, setIsHoverDisabled] = useState(false);
  const [isNavMenuHovered, setIsNavMenuHovered] = useState(false);
  const appearsCollapsed =
    isCollapsed && !isHoverDisabled && !isSideBarHovered && !isNavMenuHovered;

  const sharedSidebarStyles = getSharedSidebarStyles(useTheme());

  // TODO: Look to db for these two
  const canAccessFundMode =
    userContext.canAccessFinance &&
    hasPermission(
      userContext.permissions,
      [GQPermissionType.FinanceReadOnly, GQPermissionType.ManageFund],
      { allowAnyObject: true }
    );
  const canAccessCorporateMode = userContext.canAccessCorporate;

  // This seems regrettable. We should find a better way to do this that doesn't call both hooks.
  const financeVariant = useSidebarFinanceVariant({
    funds: userContext.funds,
    views: userContext.financeSavedViews,
    toggleCreateFinanceSavedView,
  });
  const standardVariant = useSidebarStandardVariant();
  const { navEntries, syntheticNavEntries, contentAfterNavEntries } =
    isOnFinancePage ? financeVariant : standardVariant;

  const { location } = useGlobalLocation();
  const hasHome = useHasPermission([GQPermissionType.ViewFootprintDetail]);

  const activeEntry = useMemo(() => {
    const { activeEntry } = findActiveEntry(
      [...navEntries, ...(syntheticNavEntries ?? [])],
      location
    );
    return activeEntry;
  }, [navEntries, syntheticNavEntries, location]);

  const onClick = () => {
    setIsHoverDisabled(true);
    setIsCollapsed((value) => !value);
    setIsSideBarHovered(false);
    setTimeout(() => {
      setIsHoverDisabled(false);
    }, DEFAULT_DURATION_MS);
  };

  const canToggle =
    (isOnFinancePage && canAccessCorporateMode) ||
    (!isOnFinancePage && canAccessFundMode);

  if (isOnUnknownPage) {
    return null;
  }

  const handleNavMenuHover = (hovered: boolean) => {
    setIsNavMenuHovered(hovered);
  };

  return (
    <Stack
      data-test={TestIds.Sidebar}
      ref={hoverIntentRef}
      component="nav"
      aria-label={t`Sidebar`}
      data-appears-collapsed={appearsCollapsed}
      data-is-collapsed={isCollapsed}
      sx={mixinSx(sharedSidebarStyles.rootSxProps, {
        color: (theme) => (isOnFinancePage ? theme.palette.white : undefined),
        width: SIDEBAR_EXPANDED_WIDTH,
        '&[data-appears-collapsed="true"]': {
          width: SIDEBAR_COLLAPSED_WIDTH,
          boxShadow: 'none',
          ':hover': {
            width: SIDEBAR_COLLAPSED_WIDTH + 4,
            boxShadow: '4px 4px 12px rgba(4, 19, 48, 0.15)',
          },
        },
      })}
    >
      <Box sx={sharedSidebarStyles.logoBarSxProps}>
        <SkipNavLink className={SKIP_NAV_LINK_CLASS}>
          <Typography variant="h3" component="span" color="inherit">
            <Trans>Skip to main content</Trans>
          </Typography>
        </SkipNavLink>
        <LogoBar
          isOnFinancePage={isOnFinancePage}
          appearsCollapsed={appearsCollapsed}
          canToggle={canToggle}
          hasHome={hasHome}
        />
        <CollapseButton
          appearsCollapsed={appearsCollapsed}
          isCollapsed={isCollapsed}
          isSideBarHovered={isSideBarHovered}
          onClick={onClick}
        />
      </Box>
      <Box
        sx={{
          overflowX: 'hidden',
          overflowY: 'auto',
          maxHeight: '100%',
          scrollSnapType: 'y proximity',
          scrollbarWidth: 'thin',
          scrollbarColor: `${sharedSidebarStyles.COLORS.SCROLLBAR} ${sharedSidebarStyles.COLORS.BACKGROUND}`,
        }}
      >
        {getCurrentDevEnv() === 'dev-proxy' && (
          <Box
            sx={{
              marginBottom: 2,
              paddingInline: 2,
              scrollSnapAlign: 'start',
              '[data-appears-collapsed="true"] &': {
                opacity: 0,
              },
            }}
          >
            <DevelopmentChip fullWidth />
          </Box>
        )}
        {userContext.stagingOrg && (
          <Box
            sx={{
              marginBottom: 2,
              paddingInline: 2,
              scrollSnapAlign: 'start',
              '[data-appears-collapsed="true"] &': {
                opacity: 0,
              },
            }}
          >
            <Pill
              sx={(theme) => ({
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.primary.contrastText,
                width: '100%',
              })}
              label={t({
                message: 'Staging organization',
                context:
                  'Indicator in the sidebar that this user is in a staging organization',
              })}
            />
          </Box>
        )}
        {navEntries.map((section, i) => (
          <NavSection
            key={i}
            section={section}
            appearsCollapsed={appearsCollapsed}
            activeEntry={activeEntry}
            // Finance: Last section (only section) should be sticky
            isSticky={i === navEntries.length - 1 && !!contentAfterNavEntries}
            handleNavMenuHover={handleNavMenuHover}
          />
        ))}
        {contentAfterNavEntries
          ? contentAfterNavEntries({ appearsCollapsed, activeEntry })
          : null}
      </Box>
      {hasInitialized && (
        <UserBar
          isOnFinancePage={isOnFinancePage}
          appearsCollapsed={appearsCollapsed}
          isAdmin={isAdmin}
          data-test={TestIds.SidebarUserBar}
          isWatershedEmployee={isWatershedEmployee}
        />
      )}
    </Stack>
  );
}

function CollapseButton({
  appearsCollapsed,
  isCollapsed,
  isSideBarHovered,
  onClick,
}: {
  appearsCollapsed: boolean;
  isCollapsed: boolean;
  isSideBarHovered: boolean;
  onClick: () => void;
}) {
  const { t } = useLingui();
  const sharedSidebarStyles = getSharedSidebarStyles(useTheme());

  return (
    <IconButton
      data-appears-collapsed={appearsCollapsed}
      data-is-collapsed={isCollapsed}
      data-is-sidebar-hovered={isSideBarHovered}
      tabIndex={appearsCollapsed ? -1 : 0}
      sx={mixinSx(sharedSidebarStyles.collapseButtonSxProps, {
        '&, &:hover, &:focus': {
          color: sharedSidebarStyles.COLORS.TEXT_PRIMARY,
        },
        '&:hover, &:focus': {
          backgroundColor: sharedSidebarStyles.COLORS.BACKGROUND_ACTIVE,
        },
      })}
      title={isCollapsed ? t`Expand` : t`Collapse`}
      onClick={onClick}
      size="large"
    >
      {isCollapsed ? <SidebarOpenIcon /> : <SidebarCloseIcon />}
    </IconButton>
  );
}
