import { ReactNode } from 'react';
import { Box, Tooltip, TooltipProps } from '@mui/material';
import TextLink from '../TextLink';
import { SystemCssProperties, Theme } from '@mui/system';

export const longTextContainerSx: SystemCssProperties<Theme> = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export function DataGridTextWithTooltipCell({
  text,
  href,
  tooltipProps,
}: {
  text: LocalizedString;
  href?: string;
  // The text will be displayed in the tooltip if `title` is not provided
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'> & {
    title?: ReactNode;
  };
}) {
  return (
    <Tooltip title={tooltipProps?.title ?? text} {...tooltipProps}>
      {href ? (
        <TextLink href={href} sx={longTextContainerSx}>
          {text}
        </TextLink>
      ) : (
        <Box sx={longTextContainerSx}>{text}</Box>
      )}
    </Tooltip>
  );
}
