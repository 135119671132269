/*
  This file is semi-automatically generated from
  cliq/taxonomy/scripts/generate_anzsic_rev2__ceda_5_weighted_mapping.py in the cliq repo
  Do not manually modify this file
 */

import type { WeightedBeaCodes } from './industryCodeUtils';

export const ANZSIC_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['0111']: {
    label: 'Nursery Production (Under Cover)',
    BEA_CODES: { '111900': 0.4840199159967734, '111400': 0.5159800840032266 },
  },
  ['0112']: {
    label: 'Nursery Production (Outdoors)',
    BEA_CODES: { '111900': 0.4840199159967734, '111400': 0.5159800840032266 },
  },
  ['0113']: { label: 'Turf Growing', BEA_CODES: { '111400': 1.0 } },
  ['0114']: {
    label: 'Floriculture Production (Under Cover)',
    BEA_CODES: {
      '111900': 0.34602000437471414,
      '111400': 0.36886794328779654,
      '111200': 0.2851120523374893,
    },
  },
  ['0115']: {
    label: 'Floriculture Production (Outdoors)',
    BEA_CODES: {
      '111900': 0.34602000437471414,
      '111400': 0.36886794328779654,
      '111200': 0.2851120523374893,
    },
  },
  ['0121']: {
    label: 'Mushroom Growing',
    BEA_CODES: {
      '111900': 0.34602000437471414,
      '111200': 0.2851120523374893,
      '111400': 0.36886794328779654,
    },
  },
  ['0122']: {
    label: 'Vegetable Growing (Under Cover)',
    BEA_CODES: {
      '1111B0': 0.6053785558855238,
      '111900': 0.11509130713723519,
      '1111A0': 0.279530136977241,
    },
  },
  ['0123']: {
    label: 'Vegetable Growing (Outdoors)',
    BEA_CODES: {
      '1111B0': 0.6053785558855238,
      '111900': 0.11509130713723519,
      '1111A0': 0.279530136977241,
    },
  },
  ['0131']: { label: 'Grape Growing', BEA_CODES: { '111300': 1.0 } },
  ['0132']: { label: 'Kiwifruit Growing', BEA_CODES: { '111300': 1.0 } },
  ['0133']: { label: 'Berry Fruit Growing', BEA_CODES: { '111300': 1.0 } },
  ['0134']: { label: 'Apple and Pear Growing', BEA_CODES: { '111300': 1.0 } },
  ['0135']: { label: 'Stone Fruit Growing', BEA_CODES: { '111300': 1.0 } },
  ['0136']: { label: 'Citrus Fruit Growing', BEA_CODES: { '111300': 1.0 } },
  ['0137']: { label: 'Olive Growing', BEA_CODES: { '111300': 1.0 } },
  ['0139']: {
    label: 'Other Fruit and Tree Nut Growing',
    BEA_CODES: { '111300': 0.5785144622935176, '111400': 0.42148553770648245 },
  },
  ['0141']: {
    label: 'Sheep Farming (Specialised)',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['0142']: {
    label: 'Beef Cattle Farming (Specialised)',
    BEA_CODES: { '112120': 0.3193224977413668, '1121A0': 0.6806775022586332 },
  },
  ['0143']: {
    label: 'Beef Cattle Feedlots (Specialised)',
    BEA_CODES: { '112120': 0.3193224977413668, '1121A0': 0.6806775022586332 },
  },
  ['0144']: {
    label: 'Sheep-Beef Cattle Farming',
    BEA_CODES: { '112120': 0.3193224977413668, '1121A0': 0.6806775022586332 },
  },
  ['0145']: {
    label: 'Grain-Sheep or Grain-Beef Cattle Farming',
    BEA_CODES: { '112A00': 0.6953819760520972, '111900': 0.3046180239479028 },
  },
  ['0146']: { label: 'Rice Growing', BEA_CODES: { '1111B0': 1.0 } },
  ['0149']: {
    label: 'Other Grain Growing',
    BEA_CODES: {
      '1111B0': 0.6053785558855238,
      '111900': 0.11509130713723519,
      '1111A0': 0.279530136977241,
    },
  },
  ['0151']: { label: 'Sugar Cane Growing', BEA_CODES: { '111900': 1.0 } },
  ['0152']: { label: 'Cotton Growing', BEA_CODES: { '111900': 1.0 } },
  ['0159']: {
    label: 'Other Crop Growing n.e.c.',
    BEA_CODES: {
      '1111B0': 0.6053785558855238,
      '111900': 0.11509130713723519,
      '1111A0': 0.279530136977241,
    },
  },
  ['0160']: {
    label: 'Dairy Cattle Farming',
    BEA_CODES: { '112120': 0.3193224977413668, '1121A0': 0.6806775022586332 },
  },
  ['0171']: { label: 'Poultry Farming (Meat)', BEA_CODES: { '112300': 1.0 } },
  ['0172']: { label: 'Poultry Farming (Eggs)', BEA_CODES: { '112300': 1.0 } },
  ['0180']: {
    label: 'Deer Farming',
    BEA_CODES: { '112A00': 0.5166480243477356, '112300': 0.4833519756522644 },
  },
  ['0191']: { label: 'Horse Farming', BEA_CODES: { '112A00': 1.0 } },
  ['0192']: { label: 'Pig Farming', BEA_CODES: { '112A00': 1.0 } },
  ['0193']: {
    label: 'Beekeeping',
    BEA_CODES: { '112A00': 0.5166480243477356, '112300': 0.4833519756522644 },
  },
  ['0199']: {
    label: 'Other Livestock Farming n.e.c.',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['0201']: {
    label: 'Offshore Longline and Rack Aquaculture',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['0202']: {
    label: 'Offshore Caged Aquaculture',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['0203']: { label: 'Onshore Aquaculture', BEA_CODES: { '112A00': 1.0 } },
  ['0301']: { label: 'Forestry', BEA_CODES: { '113000': 1.0 } },
  ['0302']: { label: 'Logging', BEA_CODES: { '113000': 1.0 } },
  ['0411']: {
    label: 'Rock Lobster and Crab Potting',
    BEA_CODES: { '311700': 0.5497837753347574, '114000': 0.4502162246652425 },
  },
  ['0412']: {
    label: 'Prawn Fishing',
    BEA_CODES: { '311700': 0.5497837753347574, '114000': 0.4502162246652425 },
  },
  ['0413']: {
    label: 'Line Fishing',
    BEA_CODES: { '311700': 0.5497837753347574, '114000': 0.4502162246652425 },
  },
  ['0414']: {
    label: 'Fish Trawling, Seining and Netting',
    BEA_CODES: { '311700': 0.5497837753347574, '114000': 0.4502162246652425 },
  },
  ['0419']: {
    label: 'Other Fishing',
    BEA_CODES: { '311700': 0.5497837753347574, '114000': 0.4502162246652425 },
  },
  ['0420']: { label: 'Hunting and Trapping', BEA_CODES: { '114000': 1.0 } },
  ['0510']: {
    label: 'Forestry Support Services',
    BEA_CODES: { '113000': 1.0 },
  },
  ['0521']: { label: 'Cotton Ginning', BEA_CODES: { '115000': 1.0 } },
  ['0522']: { label: 'Shearing Services', BEA_CODES: { '115000': 1.0 } },
  ['0529']: {
    label: 'Other Agriculture and Fishing Support Services',
    BEA_CODES: { '115000': 1.0 },
  },
  ['0600']: {
    label: 'Coal Mining',
    BEA_CODES: { '212100': 0.13168853735993769, '211000': 0.8683114626400623 },
  },
  ['0700']: { label: 'Oil and Gas Extraction', BEA_CODES: { '211000': 1.0 } },
  ['0801']: { label: 'Iron Ore Mining', BEA_CODES: { '2122A0': 1.0 } },
  ['0802']: {
    label: 'Bauxite Mining',
    BEA_CODES: { '2122A0': 0.6605949656750572, '212230': 0.3394050343249428 },
  },
  ['0803']: {
    label: 'Copper Ore Mining',
    BEA_CODES: { '2122A0': 0.6605949656750572, '212230': 0.3394050343249428 },
  },
  ['0804']: {
    label: 'Gold Ore Mining',
    BEA_CODES: { '2122A0': 0.6605949656750572, '212230': 0.3394050343249428 },
  },
  ['0805']: {
    label: 'Mineral Sand Mining',
    BEA_CODES: { '2122A0': 0.6605949656750572, '212230': 0.3394050343249428 },
  },
  ['0806']: {
    label: 'Nickel Ore Mining',
    BEA_CODES: { '2122A0': 0.6605949656750572, '212230': 0.3394050343249428 },
  },
  ['0807']: {
    label: 'Silver-Lead-Zinc Ore Mining',
    BEA_CODES: { '2122A0': 0.6605949656750572, '212230': 0.3394050343249428 },
  },
  ['0809']: { label: 'Other Metal Ore Mining', BEA_CODES: { '2122A0': 1.0 } },
  ['0911']: {
    label: 'Gravel and Sand Quarrying',
    BEA_CODES: { '2123A0': 0.5295300513889923, '212310': 0.47046994861100766 },
  },
  ['0919']: {
    label: 'Other Construction Material Mining',
    BEA_CODES: { '2123A0': 0.5295300513889923, '212310': 0.47046994861100766 },
  },
  ['0990']: {
    label: 'Other Non-Metallic Mineral Mining and Quarrying',
    BEA_CODES: { '2123A0': 0.5295300513889923, '212310': 0.47046994861100766 },
  },
  ['1011']: {
    label: 'Petroleum Exploration',
    BEA_CODES: {
      '213111': 0.1576037965957898,
      '48A000': 0.42158916167347155,
      '21311A': 0.42080704173073863,
    },
  },
  ['1012']: { label: 'Mineral Exploration', BEA_CODES: { '21311A': 1.0 } },
  ['1090']: {
    label: 'Other Mining Support Services',
    BEA_CODES: {
      '213111': 0.1576037965957898,
      '48A000': 0.42158916167347155,
      '21311A': 0.42080704173073863,
    },
  },
  ['1111']: {
    label: 'Meat Processing',
    BEA_CODES: {
      '311700': 0.04408551386445961,
      '311615': 0.24178932371852452,
      '31161A': 0.5873834879863632,
      '311410': 0.12674167443065262,
    },
  },
  ['1112']: {
    label: 'Poultry Processing',
    BEA_CODES: {
      '311700': 0.04408551386445961,
      '311615': 0.24178932371852452,
      '31161A': 0.5873834879863632,
      '311410': 0.12674167443065262,
    },
  },
  ['1113']: {
    label: 'Cured Meat and Smallgoods Manufacturing',
    BEA_CODES: {
      '311700': 0.04408551386445961,
      '311615': 0.24178932371852452,
      '31161A': 0.5873834879863632,
      '311410': 0.12674167443065262,
    },
  },
  ['1120']: {
    label: 'Seafood Processing',
    BEA_CODES: { '311700': 0.5497837753347574, '114000': 0.4502162246652425 },
  },
  ['1131']: {
    label: 'Milk and Cream Processing',
    BEA_CODES: {
      '31151A': 0.36737764663351147,
      '311520': 0.07064203152022043,
      '311514': 0.19639071003546757,
      '311513': 0.3655896118108005,
    },
  },
  ['1132']: {
    label: 'Ice Cream Manufacturing',
    BEA_CODES: {
      '31151A': 0.36737764663351147,
      '311520': 0.07064203152022043,
      '311514': 0.19639071003546757,
      '311513': 0.3655896118108005,
    },
  },
  ['1133']: {
    label: 'Cheese and Other Dairy Product Manufacturing',
    BEA_CODES: {
      '31151A': 0.36737764663351147,
      '311520': 0.07064203152022043,
      '311514': 0.19639071003546757,
      '311513': 0.3655896118108005,
    },
  },
  ['1140']: {
    label: 'Fruit and Vegetable Processing',
    BEA_CODES: {
      '311990': 0.13483582331737734,
      '311940': 0.11214779958043093,
      '311420': 0.2211430359647191,
      '311210': 0.1133589865436549,
      '311930': 0.05546540873213644,
      '311910': 0.1872471864532505,
      '311410': 0.1758017594084308,
    },
  },
  ['1150']: {
    label: 'Oil and Fat Manufacturing',
    BEA_CODES: {
      '311700': 0.12895971842002346,
      '311221': 0.18900322643723114,
      '311224': 0.4511512514665624,
      '311225': 0.23088580367618303,
    },
  },
  ['1161']: {
    label: 'Grain Mill Product Manufacturing',
    BEA_CODES: {
      '3118A0': 0.4747369367748898,
      '311210': 0.3518481877866715,
      '311230': 0.1734148754384387,
    },
  },
  ['1162']: {
    label: 'Cereal, Pasta and Baking Mix Manufacturing',
    BEA_CODES: {
      '3118A0': 0.4747369367748898,
      '311210': 0.3518481877866715,
      '311230': 0.1734148754384387,
    },
  },
  ['1171']: {
    label: 'Bread Manufacturing (Factory based)',
    BEA_CODES: {
      '3118A0': 0.4747369367748898,
      '311210': 0.3518481877866715,
      '311230': 0.1734148754384387,
    },
  },
  ['1172']: {
    label: 'Cake and Pastry Manufacturing (Factory based)',
    BEA_CODES: {
      '311910': 0.23857377024971574,
      '3118A0': 0.19487720956332974,
      '311410': 0.22399102145694583,
      '311810': 0.3425579987300087,
    },
  },
  ['1173']: {
    label: 'Biscuit Manufacturing (Factory based)',
    BEA_CODES: {
      '311910': 0.23857377024971574,
      '3118A0': 0.19487720956332974,
      '311410': 0.22399102145694583,
      '311810': 0.3425579987300087,
    },
  },
  ['1174']: {
    label: 'Bakery Product Manufacturing (Non-factory based)',
    BEA_CODES: {
      '311910': 0.23857377024971574,
      '3118A0': 0.19487720956332974,
      '311410': 0.22399102145694583,
      '311810': 0.3425579987300087,
    },
  },
  ['1181']: {
    label: 'Sugar Manufacturing',
    BEA_CODES: { '311300': 0.6614920727555685, '111900': 0.33850792724443146 },
  },
  ['1182']: {
    label: 'Confectionery Manufacturing',
    BEA_CODES: { '311300': 1.0 },
  },
  ['1191']: {
    label: 'Potato, Corn and Other Crisp Manufacturing',
    BEA_CODES: {
      '311990': 0.13483582331737734,
      '311940': 0.11214779958043093,
      '311420': 0.2211430359647191,
      '311210': 0.1133589865436549,
      '311930': 0.05546540873213644,
      '311910': 0.1872471864532505,
      '311410': 0.1758017594084308,
    },
  },
  ['1192']: {
    label: 'Prepared Animal and Bird Feed Manufacturing',
    BEA_CODES: { '311111': 0.33825338253382536, '311119': 0.6617466174661747 },
  },
  ['1199']: {
    label: 'Other Food Product Manufacturing n.e.c.',
    BEA_CODES: {
      '311990': 0.13483582331737734,
      '311940': 0.11214779958043093,
      '311420': 0.2211430359647191,
      '311210': 0.1133589865436549,
      '311930': 0.05546540873213644,
      '311910': 0.1872471864532505,
      '311410': 0.1758017594084308,
    },
  },
  ['1211']: {
    label: 'Soft Drink, Cordial and Syrup Manufacturing',
    BEA_CODES: {
      '311990': 0.13483582331737734,
      '311940': 0.11214779958043093,
      '311420': 0.2211430359647191,
      '311210': 0.1133589865436549,
      '311930': 0.05546540873213644,
      '311910': 0.1872471864532505,
      '311410': 0.1758017594084308,
    },
  },
  ['1212']: {
    label: 'Beer Manufacturing',
    BEA_CODES: { '311210': 0.38649700657959735, '312120': 0.6135029934204027 },
  },
  ['1213']: {
    label: 'Spirit Manufacturing',
    BEA_CODES: { '312140': 0.5461040598470203, '312130': 0.45389594015297974 },
  },
  ['1214']: {
    label: 'Wine and Other Alcoholic Beverage Manufacturing',
    BEA_CODES: {
      '31161A': 0.37997118973424826,
      '311420': 0.10313318396678982,
      '31151A': 0.10161969908677404,
      '311513': 0.10112511384919745,
      '311410': 0.08198763807171215,
      '311990': 0.06288259411308435,
      '311514': 0.0543232965862808,
      '311940': 0.052301713210831144,
      '311920': 0.03413719199907029,
      '311700': 0.028518379382011693,
    },
  },
  ['1220']: {
    label: 'Cigarette and Tobacco Product Manufacturing',
    BEA_CODES: { '312200': 1.0 },
  },
  ['1311']: {
    label: 'Wool Scouring',
    BEA_CODES: {
      '313100': 0.19863351992097464,
      '313300': 0.37092525518603886,
      '314900': 0.4304412248929865,
    },
  },
  ['1312']: {
    label: 'Natural Textile Manufacturing',
    BEA_CODES: {
      '313100': 0.19863351992097464,
      '313300': 0.37092525518603886,
      '314900': 0.4304412248929865,
    },
  },
  ['1313']: {
    label: 'Synthetic Textile Manufacturing',
    BEA_CODES: {
      '313100': 0.19863351992097464,
      '313300': 0.37092525518603886,
      '314900': 0.4304412248929865,
    },
  },
  ['1320']: {
    label: 'Leather Tanning, Fur Dressing and Leather Product Manufacturing',
    BEA_CODES: { '313200': 0.478636289930274, '315000': 0.5213637100697259 },
  },
  ['1331']: {
    label: 'Textile Floor Covering Manufacturing',
    BEA_CODES: { '314110': 0.4648723328045848, '314900': 0.5351276671954153 },
  },
  ['1332']: {
    label: 'Rope, Cordage and Twine Manufacturing',
    BEA_CODES: { '314900': 1.0 },
  },
  ['1333']: {
    label: 'Cut and Sewn Textile Product Manufacturing',
    BEA_CODES: {
      '337900': 0.20788140935075097,
      '314120': 0.08100941161127655,
      '336360': 0.4837959440954637,
      '314900': 0.22731323494250874,
    },
  },
  ['1334']: {
    label: 'Textile Finishing and Other Textile Product Manufacturing',
    BEA_CODES: {
      '323110': 0.5411413062149998,
      '561900': 0.2916308791699591,
      '313300': 0.0774040608788264,
      '314900': 0.08982375373621465,
    },
  },
  ['1340']: {
    label: 'Knitted Product Manufacturing',
    BEA_CODES: { '313200': 1.0 },
  },
  ['1351']: {
    label: 'Clothing Manufacturing',
    BEA_CODES: {
      '335120': 0.0880322608620241,
      '339990': 0.2207093920735409,
      '322220': 0.1668285491284364,
      '339940': 0.026779984389905472,
      '315000': 0.11153412540109271,
      '336360': 0.19302749111091838,
      '313200': 0.10239354782759517,
      '314900': 0.09069464920648686,
    },
  },
  ['1352']: { label: 'Footwear Manufacturing', BEA_CODES: { '316000': 1.0 } },
  ['1411']: {
    label: 'Log Sawmilling',
    BEA_CODES: {
      '321100': 0.3245176647456778,
      '3219A0': 0.23743422701077424,
      '321910': 0.21656226509646706,
      '113000': 0.22148584314708092,
    },
  },
  ['1412']: {
    label: 'Wood Chipping',
    BEA_CODES: {
      '321100': 0.3245176647456778,
      '3219A0': 0.23743422701077424,
      '321910': 0.21656226509646706,
      '113000': 0.22148584314708092,
    },
  },
  ['1413']: {
    label: 'Timber Resawing and Dressing',
    BEA_CODES: {
      '321100': 0.3245176647456778,
      '3219A0': 0.23743422701077424,
      '321910': 0.21656226509646706,
      '113000': 0.22148584314708092,
    },
  },
  ['1491']: {
    label: 'Prefabricated Wooden Building Manufacturing',
    BEA_CODES: {
      '321100': 0.30228025953414556,
      '3219A0': 0.22116416935069785,
      '337215': 0.07760351024599729,
      '321200': 0.19722961303272185,
      '321910': 0.20172244783643747,
    },
  },
  ['1492']: {
    label: 'Wooden Structural Fitting and Component Manufacturing',
    BEA_CODES: {
      '321100': 0.30228025953414556,
      '3219A0': 0.22116416935069785,
      '337215': 0.07760351024599729,
      '321200': 0.19722961303272185,
      '321910': 0.20172244783643747,
    },
  },
  ['1493']: {
    label: 'Veneer and Plywood Manufacturing',
    BEA_CODES: { '321200': 1.0 },
  },
  ['1494']: {
    label: 'Reconstituted Wood Product Manufacturing',
    BEA_CODES: { '321200': 1.0 },
  },
  ['1499']: {
    label: 'Other Wood Product Manufacturing n.e.c.',
    BEA_CODES: { '3219A0': 1.0 },
  },
  ['1510']: {
    label: 'Pulp, Paper and Paperboard Manufacturing',
    BEA_CODES: {
      '322120': 0.4312398948613959,
      '322220': 0.1737587050970545,
      '322110': 0.06808718194217377,
      '322299': 0.04638202165999765,
      '322130': 0.2526397557604935,
      '339940': 0.027892440678884665,
    },
  },
  ['1521']: {
    label: 'Corrugated Paperboard and Paperboard Container Manufacturing',
    BEA_CODES: {
      '322120': 0.31479454583816857,
      '322220': 0.12683959278404894,
      '322299': 0.03385773815803355,
      '322130': 0.18442082498153814,
      '322210': 0.34008729823821077,
    },
  },
  ['1522']: {
    label: 'Paper Bag Manufacturing',
    BEA_CODES: {
      '322120': 0.31479454583816857,
      '322220': 0.12683959278404894,
      '322299': 0.03385773815803355,
      '322130': 0.18442082498153814,
      '322210': 0.34008729823821077,
    },
  },
  ['1523']: {
    label: 'Paper Stationery Manufacturing',
    BEA_CODES: {
      '323110': 0.23224883698641247,
      '322210': 0.19013336872138545,
      '322120': 0.1759928928995348,
      '322130': 0.10310456432147096,
      '339990': 0.09381520064288294,
      '322220': 0.07091249566864988,
      '33299A': 0.05022154395122347,
      '322291': 0.03827070385361143,
      '322230': 0.026371471331991535,
      '322299': 0.01892892162283709,
    },
  },
  ['1524']: {
    label: 'Sanitary Paper Product Manufacturing',
    BEA_CODES: {
      '323110': 0.23224883698641247,
      '322210': 0.19013336872138545,
      '322120': 0.1759928928995348,
      '322130': 0.10310456432147096,
      '339990': 0.09381520064288294,
      '322220': 0.07091249566864988,
      '33299A': 0.05022154395122347,
      '322291': 0.03827070385361143,
      '322230': 0.026371471331991535,
      '322299': 0.01892892162283709,
    },
  },
  ['1529']: {
    label: 'Other Converted Paper Product Manufacturing',
    BEA_CODES: {
      '323110': 0.23224883698641247,
      '322210': 0.19013336872138545,
      '322120': 0.1759928928995348,
      '322130': 0.10310456432147096,
      '339990': 0.09381520064288294,
      '322220': 0.07091249566864988,
      '33299A': 0.05022154395122347,
      '322291': 0.03827070385361143,
      '322230': 0.026371471331991535,
      '322299': 0.01892892162283709,
    },
  },
  ['1611']: {
    label: 'Printing',
    BEA_CODES: {
      '323110': 0.5411413062149998,
      '561900': 0.2916308791699591,
      '313300': 0.0774040608788264,
      '314900': 0.08982375373621465,
    },
  },
  ['1612']: {
    label: 'Printing Support Services',
    BEA_CODES: { '323120': 1.0 },
  },
  ['1620']: {
    label: 'Reproduction of Recorded Media',
    BEA_CODES: { '334610': 1.0 },
  },
  ['1701']: {
    label: 'Petroleum Refining and Petroleum Fuel Manufacturing',
    BEA_CODES: {
      '324190': 0.039068875994550195,
      '325110': 0.1168147973171914,
      '324110': 0.8441163266882584,
    },
  },
  ['1709']: {
    label: 'Other Petroleum and Coal Product Manufacturing',
    BEA_CODES: { '324190': 0.2100277445097768, '325190': 0.7899722554902232 },
  },
  ['1811']: {
    label: 'Industrial Gas Manufacturing',
    BEA_CODES: { '324190': 0.2100277445097768, '325190': 0.7899722554902232 },
  },
  ['1812']: {
    label: 'Basic Organic Chemical Manufacturing',
    BEA_CODES: { '324190': 0.2100277445097768, '325190': 0.7899722554902232 },
  },
  ['1813']: {
    label: 'Basic Inorganic Chemical Manufacturing',
    BEA_CODES: {
      '312140': 0.04641366287168912,
      '325190': 0.31257009913297346,
      '325310': 0.06319459162120393,
      '3259A0': 0.09209388937969858,
      '325120': 0.017292905431953536,
      '325130': 0.021083914168895154,
      '325110': 0.24847299977854032,
      '325180': 0.08999597562514734,
      '325610': 0.10888196198989858,
    },
  },
  ['1821']: {
    label: 'Synthetic Resin and Synthetic Rubber Manufacturing',
    BEA_CODES: {
      '3252A0': 0.1558668058314535,
      '325211': 0.5837109861620821,
      '3259A0': 0.26042220800646443,
    },
  },
  ['1829']: {
    label: 'Other Basic Polymer Manufacturing',
    BEA_CODES: {
      '3252A0': 0.1558668058314535,
      '325211': 0.5837109861620821,
      '3259A0': 0.26042220800646443,
    },
  },
  ['1831']: {
    label: 'Fertiliser Manufacturing',
    BEA_CODES: { '325180': 0.5874772660148295, '325310': 0.4125227339851704 },
  },
  ['1832']: {
    label: 'Pesticide Manufacturing',
    BEA_CODES: { '325610': 0.7537254384808123, '325320': 0.24627456151918767 },
  },
  ['1841']: {
    label: 'Human Pharmaceutical and Medicinal Product Manufacturing',
    BEA_CODES: {
      '325190': 0.39541209784311365,
      '325412': 0.3632606338113026,
      '339113': 0.09612302687070731,
      '325414': 0.07287022532835281,
      '325411': 0.03676045306663454,
      '325413': 0.035573563079889146,
    },
  },
  ['1842']: {
    label: 'Veterinary Pharmaceutical and Medicinal Product Manufacturing',
    BEA_CODES: {
      '325190': 0.39541209784311365,
      '325412': 0.3632606338113026,
      '339113': 0.09612302687070731,
      '325414': 0.07287022532835281,
      '325411': 0.03676045306663454,
      '325413': 0.035573563079889146,
    },
  },
  ['1851']: {
    label: 'Cleaning Compound Manufacturing',
    BEA_CODES: {
      '339990': 0.16425713179295212,
      '3259A0': 0.24960629921259841,
      '325610': 0.2951077836581903,
      '325620': 0.1976829740544727,
      '332999': 0.0933458112817865,
    },
  },
  ['1852']: {
    label: 'Cosmetic and Toiletry Preparation Manufacturing',
    BEA_CODES: {
      '339990': 0.16425713179295212,
      '3259A0': 0.24960629921259841,
      '325610': 0.2951077836581903,
      '325620': 0.1976829740544727,
      '332999': 0.0933458112817865,
    },
  },
  ['1891']: {
    label: 'Photographic Chemical Product Manufacturing',
    BEA_CODES: {
      '312140': 0.04641366287168912,
      '325190': 0.31257009913297346,
      '325310': 0.06319459162120393,
      '3259A0': 0.09209388937969858,
      '325120': 0.017292905431953536,
      '325130': 0.021083914168895154,
      '325110': 0.24847299977854032,
      '325180': 0.08999597562514734,
      '325610': 0.10888196198989858,
    },
  },
  ['1892']: {
    label: 'Explosive Manufacturing',
    BEA_CODES: {
      '312140': 0.04641366287168912,
      '325190': 0.31257009913297346,
      '325310': 0.06319459162120393,
      '3259A0': 0.09209388937969858,
      '325120': 0.017292905431953536,
      '325130': 0.021083914168895154,
      '325110': 0.24847299977854032,
      '325180': 0.08999597562514734,
      '325610': 0.10888196198989858,
    },
  },
  ['1899']: {
    label: 'Other Basic Chemical Product Manufacturing n.e.c.',
    BEA_CODES: {
      '312140': 0.04641366287168912,
      '325190': 0.31257009913297346,
      '325310': 0.06319459162120393,
      '3259A0': 0.09209388937969858,
      '325120': 0.017292905431953536,
      '325130': 0.021083914168895154,
      '325110': 0.24847299977854032,
      '325180': 0.08999597562514734,
      '325610': 0.10888196198989858,
    },
  },
  ['1911']: {
    label: 'Polymer Film and Sheet Packaging Material Manufacturing',
    BEA_CODES: {
      '326190': 0.3082908346966133,
      '326110': 0.1487963130509282,
      '339990': 0.09398322704058082,
      '3252A0': 0.08547857588637815,
      '336360': 0.08219562544083488,
      '322220': 0.0710395024982182,
      '3219A0': 0.06998703806966945,
      '326120': 0.056057579036385724,
      '326160': 0.045551399039118444,
      '314900': 0.03861990524127285,
    },
  },
  ['1912']: {
    label: 'Rigid and Semi-Rigid Polymer Product Manufacturing',
    BEA_CODES: {
      '326190': 0.3082908346966133,
      '326110': 0.1487963130509282,
      '339990': 0.09398322704058082,
      '3252A0': 0.08547857588637815,
      '336360': 0.08219562544083488,
      '322220': 0.0710395024982182,
      '3219A0': 0.06998703806966945,
      '326120': 0.056057579036385724,
      '326160': 0.045551399039118444,
      '314900': 0.03861990524127285,
    },
  },
  ['1913']: {
    label: 'Polymer Foam Product Manufacturing',
    BEA_CODES: {
      '326190': 0.3082908346966133,
      '326110': 0.1487963130509282,
      '339990': 0.09398322704058082,
      '3252A0': 0.08547857588637815,
      '336360': 0.08219562544083488,
      '322220': 0.0710395024982182,
      '3219A0': 0.06998703806966945,
      '326120': 0.056057579036385724,
      '326160': 0.045551399039118444,
      '314900': 0.03861990524127285,
    },
  },
  ['1914']: { label: 'Tyre Manufacturing', BEA_CODES: { '326210': 1.0 } },
  ['1915']: {
    label: 'Adhesive Manufacturing',
    BEA_CODES: {
      '325190': 0.5191054338369058,
      '3259A0': 0.15294629439215376,
      '33299A': 0.0538796171794669,
      '325520': 0.04653958712330934,
      '325413': 0.04670173218381713,
      '325610': 0.18082733528434708,
    },
  },
  ['1916']: {
    label: 'Paint and Coatings Manufacturing',
    BEA_CODES: {
      '312140': 0.04641366287168912,
      '325190': 0.31257009913297346,
      '325310': 0.06319459162120393,
      '3259A0': 0.09209388937969858,
      '325120': 0.017292905431953536,
      '325130': 0.021083914168895154,
      '325110': 0.24847299977854032,
      '325180': 0.08999597562514734,
      '325610': 0.10888196198989858,
    },
  },
  ['1919']: {
    label: 'Other Polymer Product Manufacturing',
    BEA_CODES: {
      '315000': 0.231171585720962,
      '316000': 0.12339576517956645,
      '339990': 0.457454074846317,
      '314900': 0.18797857425315453,
    },
  },
  ['1920']: {
    label: 'Natural Rubber Product Manufacturing',
    BEA_CODES: {
      '326220': 0.04826691687824263,
      '339920': 0.08865952827758766,
      '337900': 0.08797880560768297,
      '339990': 0.2341134047172241,
      '326210': 0.18243367553445927,
      '313300': 0.08290098244839386,
      '326290': 0.15733892629797255,
      '315000': 0.11830776023843691,
    },
  },
  ['2010']: {
    label: 'Glass and Glass Product Manufacturing',
    BEA_CODES: { '327200': 0.82517633510868, '327993': 0.17482366489132 },
  },
  ['2021']: { label: 'Clay Brick Manufacturing', BEA_CODES: { '327100': 1.0 } },
  ['2029']: {
    label: 'Other Ceramic Product Manufacturing',
    BEA_CODES: { '327100': 1.0 },
  },
  ['2031']: {
    label: 'Cement and Lime Manufacturing',
    BEA_CODES: { '327400': 0.5090392561983471, '327310': 0.4909607438016529 },
  },
  ['2032']: {
    label: 'Plaster Product Manufacturing',
    BEA_CODES: { '327400': 0.5090392561983471, '327310': 0.4909607438016529 },
  },
  ['2033']: {
    label: 'Ready-Mixed Concrete Manufacturing',
    BEA_CODES: {
      '327390': 0.1659726470762369,
      '327100': 0.14086953089538395,
      '327999': 0.07803279982633064,
      '327400': 0.11669396696335183,
      '327320': 0.39304533164926686,
      '327330': 0.10538572358942985,
    },
  },
  ['2034']: {
    label: 'Concrete Product Manufacturing',
    BEA_CODES: {
      '327390': 0.1659726470762369,
      '327100': 0.14086953089538395,
      '327999': 0.07803279982633064,
      '327400': 0.11669396696335183,
      '327320': 0.39304533164926686,
      '327330': 0.10538572358942985,
    },
  },
  ['2090']: {
    label: 'Other Non-Metallic Mineral Product Manufacturing',
    BEA_CODES: {
      '323110': 0.23224883698641247,
      '322210': 0.19013336872138545,
      '322120': 0.1759928928995348,
      '322130': 0.10310456432147096,
      '339990': 0.09381520064288294,
      '322220': 0.07091249566864988,
      '33299A': 0.05022154395122347,
      '322291': 0.03827070385361143,
      '322230': 0.026371471331991535,
      '322299': 0.01892892162283709,
    },
  },
  ['2110']: {
    label: 'Iron Smelting and Steel Manufacturing',
    BEA_CODES: {
      '331200': 0.055921632192818635,
      '331110': 0.7451578044798384,
      '33291A': 0.15133255811221913,
      '332996': 0.04758800521512386,
    },
  },
  ['2121']: {
    label: 'Iron and Steel Casting',
    BEA_CODES: {
      '331200': 0.055921632192818635,
      '331110': 0.7451578044798384,
      '33291A': 0.15133255811221913,
      '332996': 0.04758800521512386,
    },
  },
  ['2122']: {
    label: 'Steel Pipe and Tube Manufacturing',
    BEA_CODES: {
      '331200': 0.055921632192818635,
      '331110': 0.7451578044798384,
      '33291A': 0.15133255811221913,
      '332996': 0.04758800521512386,
    },
  },
  ['2131']: {
    label: 'Alumina Production',
    BEA_CODES: {
      '331410': 0.15696268550566592,
      '331490': 0.07500829737802854,
      '322220': 0.11401190081077237,
      '331313': 0.21955454933383908,
      '332913': 0.019172869944526102,
      '332996': 0.04802403868948841,
      '33291A': 0.15271916931392537,
      '331420': 0.1288286472903134,
      '332999': 0.08571784173344081,
    },
  },
  ['2132']: {
    label: 'Aluminium Smelting',
    BEA_CODES: {
      '331410': 0.15696268550566592,
      '331490': 0.07500829737802854,
      '322220': 0.11401190081077237,
      '331313': 0.21955454933383908,
      '332913': 0.019172869944526102,
      '332996': 0.04802403868948841,
      '33291A': 0.15271916931392537,
      '331420': 0.1288286472903134,
      '332999': 0.08571784173344081,
    },
  },
  ['2133']: {
    label: 'Copper, Silver, Lead and Zinc Smelting and Refining',
    BEA_CODES: {
      '331410': 0.15696268550566592,
      '331490': 0.07500829737802854,
      '322220': 0.11401190081077237,
      '331313': 0.21955454933383908,
      '332913': 0.019172869944526102,
      '332996': 0.04802403868948841,
      '33291A': 0.15271916931392537,
      '331420': 0.1288286472903134,
      '332999': 0.08571784173344081,
    },
  },
  ['2139']: {
    label: 'Other Basic Non-Ferrous Metal Manufacturing',
    BEA_CODES: {
      '331410': 0.15696268550566592,
      '331490': 0.07500829737802854,
      '322220': 0.11401190081077237,
      '331313': 0.21955454933383908,
      '332913': 0.019172869944526102,
      '332996': 0.04802403868948841,
      '33291A': 0.15271916931392537,
      '331420': 0.1288286472903134,
      '332999': 0.08571784173344081,
    },
  },
  ['2141']: {
    label: 'Non-Ferrous Metal Casting',
    BEA_CODES: { '331520': 1.0 },
  },
  ['2142']: {
    label: 'Aluminium Rolling, Drawing, Extruding',
    BEA_CODES: {
      '331410': 0.15696268550566592,
      '331490': 0.07500829737802854,
      '322220': 0.11401190081077237,
      '331313': 0.21955454933383908,
      '332913': 0.019172869944526102,
      '332996': 0.04802403868948841,
      '33291A': 0.15271916931392537,
      '331420': 0.1288286472903134,
      '332999': 0.08571784173344081,
    },
  },
  ['2149']: {
    label: 'Other Basic Non-Ferrous Metal Product Manufacturing',
    BEA_CODES: {
      '331410': 0.15696268550566592,
      '331490': 0.07500829737802854,
      '322220': 0.11401190081077237,
      '331313': 0.21955454933383908,
      '332913': 0.019172869944526102,
      '332996': 0.04802403868948841,
      '33291A': 0.15271916931392537,
      '331420': 0.1288286472903134,
      '332999': 0.08571784173344081,
    },
  },
  ['2210']: {
    label: 'Iron and Steel Forging',
    BEA_CODES: {
      '336370': 0.4448333465913611,
      '332119': 0.1827698671545621,
      '33211A': 0.24165141993477052,
      '332114': 0.13074536631930633,
    },
  },
  ['2221']: {
    label: 'Structural Steel Fabricating',
    BEA_CODES: {
      '337215': 0.08336362838625566,
      '332310': 0.4752102894535608,
      '332320': 0.44142608216018353,
    },
  },
  ['2222']: {
    label: 'Prefabricated Metal Building Manufacturing',
    BEA_CODES: {
      '337215': 0.08336362838625566,
      '332310': 0.4752102894535608,
      '332320': 0.44142608216018353,
    },
  },
  ['2223']: {
    label: 'Architectural Aluminium Product Manufacturing',
    BEA_CODES: {
      '337215': 0.08336362838625566,
      '332310': 0.4752102894535608,
      '332320': 0.44142608216018353,
    },
  },
  ['2224']: {
    label: 'Metal Roof and Guttering Manufacturing (except Aluminium)',
    BEA_CODES: {
      '337215': 0.08336362838625566,
      '332310': 0.4752102894535608,
      '332320': 0.44142608216018353,
    },
  },
  ['2229']: {
    label: 'Other Structural Metal Product Manufacturing',
    BEA_CODES: {
      '337215': 0.08336362838625566,
      '332310': 0.4752102894535608,
      '332320': 0.44142608216018353,
    },
  },
  ['2231']: {
    label: 'Boiler, Tank and Other Heavy Gauge Metal Container Manufacturing',
    BEA_CODES: {
      '332430': 0.2601431639226915,
      '332310': 0.542705798138869,
      '333414': 0.06810307802433786,
      '332420': 0.12904795991410165,
    },
  },
  ['2239']: {
    label: 'Other Metal Container Manufacturing',
    BEA_CODES: {
      '323110': 0.22319840724392265,
      '331313': 0.1312358738548523,
      '332320': 0.1247458179525149,
      '332720': 0.09755630973720941,
      '339990': 0.09015934645987289,
      '331420': 0.07700564691545214,
      '33399A': 0.07234003358391373,
      '322220': 0.06814912958147641,
      '332430': 0.06437271059026917,
      '332999': 0.05123672408051637,
    },
  },
  ['2240']: {
    label:
      'Sheet Metal Product Manufacturing (except Metal Structural and Container Products)',
    BEA_CODES: {
      '337215': 0.08336362838625566,
      '332310': 0.4752102894535608,
      '332320': 0.44142608216018353,
    },
  },
  ['2291']: {
    label: 'Spring and Wire Product Manufacturing',
    BEA_CODES: {
      '323110': 0.22319840724392265,
      '331313': 0.1312358738548523,
      '332320': 0.1247458179525149,
      '332720': 0.09755630973720941,
      '339990': 0.09015934645987289,
      '331420': 0.07700564691545214,
      '33399A': 0.07234003358391373,
      '322220': 0.06814912958147641,
      '332430': 0.06437271059026917,
      '332999': 0.05123672408051637,
    },
  },
  ['2292']: {
    label: 'Nut, Bolt, Screw and Rivet Manufacturing',
    BEA_CODES: {
      '323110': 0.22319840724392265,
      '331313': 0.1312358738548523,
      '332320': 0.1247458179525149,
      '332720': 0.09755630973720941,
      '339990': 0.09015934645987289,
      '331420': 0.07700564691545214,
      '33399A': 0.07234003358391373,
      '322220': 0.06814912958147641,
      '332430': 0.06437271059026917,
      '332999': 0.05123672408051637,
    },
  },
  ['2293']: {
    label: 'Metal Coating and Finishing',
    BEA_CODES: {
      '339910': 0.1444735770288158,
      '332710': 0.5078530699521434,
      '332800': 0.34767335301904084,
    },
  },
  ['2299']: {
    label: 'Other Fabricated Metal Product Manufacturing n.e.c.',
    BEA_CODES: {
      '337215': 0.08336362838625566,
      '332310': 0.4752102894535608,
      '332320': 0.44142608216018353,
    },
  },
  ['2311']: {
    label: 'Motor Vehicle Manufacturing',
    BEA_CODES: {
      '336120': 0.09795518724014134,
      '333618': 0.08137815555334818,
      '336213': 0.006893615813597083,
      '336500': 0.045195896184246,
      '336111': 0.15740659278284685,
      '336211': 0.019843623618225937,
      '336999': 0.01955414283889827,
      '336112': 0.49401597820380017,
      '336310': 0.0777568077648962,
    },
  },
  ['2312']: {
    label: 'Motor Vehicle Body and Trailer Manufacturing',
    BEA_CODES: {
      '811100': 0.5781612772711712,
      '326190': 0.25982073387071675,
      '336212': 0.02457128629672279,
      '332430': 0.05655286172232424,
      '336211': 0.021760916249105224,
      '336214': 0.03107902026080732,
      '332420': 0.028053904329152533,
    },
  },
  ['2313']: {
    label: 'Automotive Electrical Component Manufacturing',
    BEA_CODES: { '335930': 1.0 },
  },
  ['2319']: {
    label: 'Other Motor Vehicle Parts Manufacturing',
    BEA_CODES: {
      '333618': 0.38740272373540857,
      '333611': 0.2424340683095547,
      '336310': 0.37016320795503677,
    },
  },
  ['2391']: {
    label: 'Shipbuilding and Repair Services',
    BEA_CODES: {
      '333618': 0.38740272373540857,
      '333611': 0.2424340683095547,
      '336310': 0.37016320795503677,
    },
  },
  ['2392']: {
    label: 'Boatbuilding and Repair Services',
    BEA_CODES: {
      '333618': 0.38740272373540857,
      '333611': 0.2424340683095547,
      '336310': 0.37016320795503677,
    },
  },
  ['2393']: {
    label: 'Railway Rolling Stock Manufacturing and Repair Services',
    BEA_CODES: {
      '333618': 0.38740272373540857,
      '333611': 0.2424340683095547,
      '336310': 0.37016320795503677,
    },
  },
  ['2394']: {
    label: 'Aircraft Manufacturing and Repair Services',
    BEA_CODES: {
      '334512': 0.01655339650936473,
      '33451A': 0.0739670080724929,
      '334511': 0.29120321623432566,
      '334513': 0.0667368622571073,
      '334220': 0.18255320506684536,
      '33399A': 0.13030854152707316,
      '334515': 0.07919977026897675,
      '334514': 0.04214287993363326,
      '334290': 0.030203248141412207,
      '334516': 0.0871318719887687,
    },
  },
  ['2399']: {
    label: 'Other Transport Equipment Manufacturing n.e.c.',
    BEA_CODES: {
      '333618': 0.38740272373540857,
      '333611': 0.2424340683095547,
      '336310': 0.37016320795503677,
    },
  },
  ['2411']: {
    label: 'Photographic, Optical and Ophthalmic Equipment Manufacturing',
    BEA_CODES: {
      '334112': 0.3054010280966129,
      '334111': 0.29131751285120766,
      '334118': 0.40328145905217944,
    },
  },
  ['2412']: {
    label: 'Medical and Surgical Equipment Manufacturing',
    BEA_CODES: {
      '339990': 0.16425713179295212,
      '3259A0': 0.24960629921259841,
      '325610': 0.2951077836581903,
      '325620': 0.1976829740544727,
      '332999': 0.0933458112817865,
    },
  },
  ['2419']: {
    label: 'Other Professional and Scientific Equipment Manufacturing',
    BEA_CODES: {
      '334512': 0.01655339650936473,
      '33451A': 0.0739670080724929,
      '334511': 0.29120321623432566,
      '334513': 0.0667368622571073,
      '334220': 0.18255320506684536,
      '33399A': 0.13030854152707316,
      '334515': 0.07919977026897675,
      '334514': 0.04214287993363326,
      '334290': 0.030203248141412207,
      '334516': 0.0871318719887687,
    },
  },
  ['2421']: {
    label: 'Computer and Electronic Office Equipment Manufacturing',
    BEA_CODES: {
      '33441A': 0.2792951343057953,
      '334418': 0.18862603084351484,
      '334413': 0.5320788348506899,
    },
  },
  ['2422']: {
    label: 'Communication Equipment Manufacturing',
    BEA_CODES: {
      '33441A': 0.2792951343057953,
      '334418': 0.18862603084351484,
      '334413': 0.5320788348506899,
    },
  },
  ['2429']: {
    label: 'Other Electronic Equipment Manufacturing',
    BEA_CODES: {
      '33441A': 0.2792951343057953,
      '334418': 0.18862603084351484,
      '334413': 0.5320788348506899,
    },
  },
  ['2431']: {
    label: 'Electric Cable and Wire Manufacturing',
    BEA_CODES: {
      '33441A': 0.2792951343057953,
      '334418': 0.18862603084351484,
      '334413': 0.5320788348506899,
    },
  },
  ['2432']: {
    label: 'Electric Lighting Equipment Manufacturing',
    BEA_CODES: {
      '335120': 0.3331692267296836,
      '336320': 0.6077523959564133,
      '335110': 0.05907837731390311,
    },
  },
  ['2439']: {
    label: 'Other Electrical Equipment Manufacturing',
    BEA_CODES: {
      '33441A': 0.2792951343057953,
      '334418': 0.18862603084351484,
      '334413': 0.5320788348506899,
    },
  },
  ['2441']: {
    label: 'Whiteware Appliance Manufacturing',
    BEA_CODES: {
      '335221': 0.23067664451305667,
      '335224': 0.18902088021351196,
      '335222': 0.1835260871840494,
      '335228': 0.23083363859961276,
      '335210': 0.16594274948976923,
    },
  },
  ['2449']: {
    label: 'Other Domestic Appliance Manufacturing',
    BEA_CODES: {
      '334512': 0.01655339650936473,
      '33451A': 0.0739670080724929,
      '334511': 0.29120321623432566,
      '334513': 0.0667368622571073,
      '334220': 0.18255320506684536,
      '33399A': 0.13030854152707316,
      '334515': 0.07919977026897675,
      '334514': 0.04214287993363326,
      '334290': 0.030203248141412207,
      '334516': 0.0871318719887687,
    },
  },
  ['2451']: {
    label: 'Pump and Compressor Manufacturing',
    BEA_CODES: { '33291A': 0.7072320570879649, '33399B': 0.2927679429120351 },
  },
  ['2452']: {
    label:
      'Fixed Space Heating, Cooling and Ventilation Equipment Manufacturing',
    BEA_CODES: {
      '334512': 0.01655339650936473,
      '33451A': 0.0739670080724929,
      '334511': 0.29120321623432566,
      '334513': 0.0667368622571073,
      '334220': 0.18255320506684536,
      '33399A': 0.13030854152707316,
      '334515': 0.07919977026897675,
      '334514': 0.04214287993363326,
      '334290': 0.030203248141412207,
      '334516': 0.0871318719887687,
    },
  },
  ['2461']: {
    label: 'Agricultural Machinery and Equipment Manufacturing',
    BEA_CODES: {
      '333111': 0.31124764048206766,
      '332200': 0.08253775228691738,
      '333120': 0.35130499491796136,
      '333112': 0.06499564396689415,
      '33329A': 0.18991396834615942,
    },
  },
  ['2462']: {
    label: 'Mining and Construction Machinery Manufacturing',
    BEA_CODES: {
      '333130': 0.3148445143785114,
      '333920': 0.2946913991184273,
      '333120': 0.3904640865030613,
    },
  },
  ['2463']: {
    label: 'Machine Tool and Parts Manufacturing',
    BEA_CODES: {
      '337900': 0.07166192117488386,
      '332200': 0.06814775962835307,
      '332999': 0.10836954892851791,
      '333120': 0.2900569459013937,
      '332500': 0.05437584294919826,
      '333514': 0.062325790499025924,
      '333511': 0.048711224336879966,
      '333130': 0.23388281132923722,
      '33351B': 0.062468155252510114,
    },
  },
  ['2469']: {
    label: 'Other Specialised Machinery and Equipment Manufacturing',
    BEA_CODES: {
      '337900': 0.07166192117488386,
      '332200': 0.06814775962835307,
      '332999': 0.10836954892851791,
      '333120': 0.2900569459013937,
      '332500': 0.05437584294919826,
      '333514': 0.062325790499025924,
      '333511': 0.048711224336879966,
      '333130': 0.23388281132923722,
      '33351B': 0.062468155252510114,
    },
  },
  ['2491']: {
    label: 'Lifting and Material Handling Equipment Manufacturing',
    BEA_CODES: {
      '332200': 0.0524038350734057,
      '333120': 0.22304616377422848,
      '33399A': 0.1176565488948812,
      '336211': 0.040286708612782045,
      '333920': 0.16833759708681925,
      '332310': 0.21841941505911636,
      '333130': 0.17984973149876696,
    },
  },
  ['2499']: {
    label: 'Other Machinery and Equipment Manufacturing n.e.c.',
    BEA_CODES: { '332310': 0.8471061452513966, '332410': 0.15289385474860334 },
  },
  ['2511']: {
    label: 'Wooden Furniture and Upholstered Seat Manufacturing',
    BEA_CODES: {
      '336390': 0.27653807558217725,
      '336320': 0.09578121928762816,
      '336350': 0.18627603115980262,
      '336211': 0.036166889088896474,
      '336360': 0.11513195328098652,
      '3363A0': 0.11274738007303725,
      '336999': 0.03563928286935022,
      '336310': 0.1417191686581215,
    },
  },
  ['2512']: {
    label: 'Metal Furniture Manufacturing',
    BEA_CODES: {
      '336390': 0.27653807558217725,
      '336320': 0.09578121928762816,
      '336350': 0.18627603115980262,
      '336211': 0.036166889088896474,
      '336360': 0.11513195328098652,
      '3363A0': 0.11274738007303725,
      '336999': 0.03563928286935022,
      '336310': 0.1417191686581215,
    },
  },
  ['2513']: {
    label: 'Mattress Manufacturing',
    BEA_CODES: {
      '326220': 0.04826691687824263,
      '339920': 0.08865952827758766,
      '337900': 0.08797880560768297,
      '339990': 0.2341134047172241,
      '326210': 0.18243367553445927,
      '313300': 0.08290098244839386,
      '326290': 0.15733892629797255,
      '315000': 0.11830776023843691,
    },
  },
  ['2519']: {
    label: 'Other Furniture Manufacturing',
    BEA_CODES: {
      '333318': 0.28395953757225434,
      '333316': 0.030248292170257488,
      '3259A0': 0.6350827640567525,
      '339940': 0.050709406200735684,
    },
  },
  ['2591']: {
    label: 'Jewellery and Silverware Manufacturing',
    BEA_CODES: {
      '323110': 0.22319840724392265,
      '331313': 0.1312358738548523,
      '332320': 0.1247458179525149,
      '332720': 0.09755630973720941,
      '339990': 0.09015934645987289,
      '331420': 0.07700564691545214,
      '33399A': 0.07234003358391373,
      '322220': 0.06814912958147641,
      '332430': 0.06437271059026917,
      '332999': 0.05123672408051637,
    },
  },
  ['2592']: {
    label: 'Toy, Sporting and Recreational Product Manufacturing',
    BEA_CODES: { '339930': 0.15744383250284116, '339920': 0.8425561674971589 },
  },
  ['2599']: {
    label: 'Other Manufacturing n.e.c.',
    BEA_CODES: {
      '339950': 0.06769650934539558,
      '337900': 0.0816249893317402,
      '3219A0': 0.1617478876845609,
      '339990': 0.2172057693948963,
      '324190': 0.2978407442178032,
      '321910': 0.14752923103183407,
      '339940': 0.026354868993769737,
    },
  },
  ['2611']: {
    label: 'Fossil Fuel Electricity Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['2612']: {
    label: 'Hydro-Electricity Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['2619']: {
    label: 'Other Electricity Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['2620']: { label: 'Electricity Transmission', BEA_CODES: { '221100': 1.0 } },
  ['2630']: { label: 'Electricity Distribution', BEA_CODES: { '221100': 1.0 } },
  ['2640']: {
    label: 'On Selling Electricity and Electricity Market Operation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['2700']: { label: 'Gas Supply', BEA_CODES: { '221200': 1.0 } },
  ['2811']: {
    label: 'Water Supply',
    BEA_CODES: { '221300': 0.6069412575576475, '312110': 0.3930587424423525 },
  },
  ['2812']: {
    label: 'Sewerage and Drainage Services',
    BEA_CODES: { '221300': 0.41859919369396476, '562111': 0.5814008063060353 },
  },
  ['2911']: {
    label: 'Solid Waste Collection Services',
    BEA_CODES: { '562111': 1.0 },
  },
  ['2919']: {
    label: 'Other Waste Collection Services',
    BEA_CODES: { '221300': 0.41859919369396476, '562111': 0.5814008063060353 },
  },
  ['2921']: {
    label: 'Waste Treatment and Disposal Services',
    BEA_CODES: { '221300': 0.41859919369396476, '562111': 0.5814008063060353 },
  },
  ['2922']: {
    label: 'Waste Remediation and Materials Recovery Services',
    BEA_CODES: { '562111': 1.0 },
  },
  ['3011']: {
    label: 'House Construction',
    BEA_CODES: {
      '233412': 0.03316393319154888,
      '233230': 0.05835996274870559,
      '233210': 0.055173996779364985,
      '230301': 0.21061924897278,
      '2334A0': 0.2020081747224384,
      '2332A0': 0.09670153894708905,
      '230302': 0.08747000828470701,
      '233411': 0.1589934499408833,
      '233262': 0.09750968641248277,
    },
  },
  ['3019']: {
    label: 'Other Residential Building Construction',
    BEA_CODES: {
      '233412': 0.03316393319154888,
      '233230': 0.05835996274870559,
      '233210': 0.055173996779364985,
      '230301': 0.21061924897278,
      '2334A0': 0.2020081747224384,
      '2332A0': 0.09670153894708905,
      '230302': 0.08747000828470701,
      '233411': 0.1589934499408833,
      '233262': 0.09750968641248277,
    },
  },
  ['3020']: {
    label: 'Non-Residential Building Construction',
    BEA_CODES: {
      '233412': 0.03316393319154888,
      '233230': 0.05835996274870559,
      '233210': 0.055173996779364985,
      '230301': 0.21061924897278,
      '2334A0': 0.2020081747224384,
      '2332A0': 0.09670153894708905,
      '230302': 0.08747000828470701,
      '233411': 0.1589934499408833,
      '233262': 0.09750968641248277,
    },
  },
  ['3101']: {
    label: 'Road and Bridge Construction',
    BEA_CODES: {
      '230301': 0.16135041299456088,
      '2334A0': 0.15475367317426547,
      '233411': 0.12180111236987168,
      '2332C0': 0.11176542238642077,
      '233240': 0.10401198641271249,
      '2332D0': 0.08581989425800643,
      '233262': 0.07469985887024763,
      '2332A0': 0.07408075625765292,
      '230302': 0.06700869949070568,
      '233230': 0.04470818378555604,
    },
  },
  ['3109']: {
    label: 'Other Heavy and Civil Engineering Construction',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3211']: {
    label: 'Land Development and Subdivision',
    BEA_CODES: {
      '230301': 0.1654436898528296,
      '2334A0': 0.1586795982300486,
      '233411': 0.12489106835648443,
      '2332C0': 0.11460078430783331,
      '233240': 0.10665065246298203,
      '2332D0': 0.08799704757683437,
      '233262': 0.0765949095116106,
      '2332A0': 0.07596010096836475,
      '233230': 0.04584237966809655,
      '233210': 0.04333976906491575,
    },
  },
  ['3212']: {
    label: 'Site Preparation Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3221']: {
    label: 'Concreting Services',
    BEA_CODES: {
      '230301': 0.15848409257941568,
      '2334A0': 0.15200454099353125,
      '561700': 0.12754452611476083,
      '233411': 0.11963736820279026,
      '233240': 0.10216425838684982,
      '2332D0': 0.08429534089385968,
      '233262': 0.07337284813309213,
      '2332A0': 0.07276474361107937,
      '230302': 0.06581831887885677,
      '233230': 0.04391396220576422,
    },
  },
  ['3222']: {
    label: 'Bricklaying Services',
    BEA_CODES: {
      '230301': 0.15848409257941568,
      '2334A0': 0.15200454099353125,
      '561700': 0.12754452611476083,
      '233411': 0.11963736820279026,
      '233240': 0.10216425838684982,
      '2332D0': 0.08429534089385968,
      '233262': 0.07337284813309213,
      '2332A0': 0.07276474361107937,
      '230302': 0.06581831887885677,
      '233230': 0.04391396220576422,
    },
  },
  ['3223']: {
    label: 'Roofing Services',
    BEA_CODES: {
      '230301': 0.15848409257941568,
      '2334A0': 0.15200454099353125,
      '561700': 0.12754452611476083,
      '233411': 0.11963736820279026,
      '233240': 0.10216425838684982,
      '2332D0': 0.08429534089385968,
      '233262': 0.07337284813309213,
      '2332A0': 0.07276474361107937,
      '230302': 0.06581831887885677,
      '233230': 0.04391396220576422,
    },
  },
  ['3224']: {
    label: 'Structural Steel Erection Services',
    BEA_CODES: {
      '230301': 0.15848409257941568,
      '2334A0': 0.15200454099353125,
      '561700': 0.12754452611476083,
      '233411': 0.11963736820279026,
      '233240': 0.10216425838684982,
      '2332D0': 0.08429534089385968,
      '233262': 0.07337284813309213,
      '2332A0': 0.07276474361107937,
      '230302': 0.06581831887885677,
      '233230': 0.04391396220576422,
    },
  },
  ['3231']: {
    label: 'Plumbing Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3232']: {
    label: 'Electrical Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3233']: {
    label: 'Air Conditioning and Heating Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3234']: {
    label: 'Fire and Security Alarm Installation Services',
    BEA_CODES: {
      '230301': 0.16135041299456088,
      '2334A0': 0.15475367317426547,
      '233411': 0.12180111236987168,
      '2332C0': 0.11176542238642077,
      '233240': 0.10401198641271249,
      '2332D0': 0.08581989425800643,
      '233262': 0.07469985887024763,
      '2332A0': 0.07408075625765292,
      '230302': 0.06700869949070568,
      '233230': 0.04470818378555604,
    },
  },
  ['3239']: {
    label: 'Other Building Installation Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3241']: {
    label: 'Plastering and Ceiling Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3242']: {
    label: 'Carpentry Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3243']: {
    label: 'Tiling and Carpeting Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3244']: {
    label: 'Painting and Decorating Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3245']: {
    label: 'Glazing Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3291']: {
    label: 'Landscape Construction Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3292']: {
    label: 'Hire of Construction Machinery with Operator',
    BEA_CODES: {
      '230301': 0.1606238637834802,
      '2334A0': 0.15405682860430217,
      '233411': 0.12125265079200104,
      '21311A': 0.11576507828839523,
      '233240': 0.10354362797923493,
      '2332D0': 0.08543345349649266,
      '233262': 0.07436349082183213,
      '2332A0': 0.0737471759834142,
      '230302': 0.06670696417533245,
      '233230': 0.044506866075514975,
    },
  },
  ['3299']: {
    label: 'Other Construction Services n.e.c.',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['3311']: {
    label: 'Wool Wholesaling',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3312']: {
    label: 'Cereal Grain Wholesaling',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3319']: {
    label: 'Other Agricultural Product Wholesaling',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3321']: {
    label: 'Petroleum Product Wholesaling',
    BEA_CODES: {
      '424A00': 0.4310264840551062,
      '423A00': 0.33741308613729293,
      '424700': 0.2315604298076009,
    },
  },
  ['3322']: {
    label: 'Metal and Mineral Wholesaling',
    BEA_CODES: {
      '424A00': 0.4310264840551062,
      '423A00': 0.33741308613729293,
      '424700': 0.2315604298076009,
    },
  },
  ['3323']: {
    label: 'Industrial and Agricultural Chemical Product Wholesaling',
    BEA_CODES: {
      '424A00': 0.3627428513227917,
      '424400': 0.16103393461884027,
      '423800': 0.1922634355603571,
      '423A00': 0.28395977849801085,
    },
  },
  ['3331']: {
    label: 'Timber Wholesaling',
    BEA_CODES: {
      '424A00': 0.3761178267674958,
      '423800': 0.19935253110050855,
      '423A00': 0.2944298816326274,
      '423100': 0.13009976049936825,
    },
  },
  ['3332']: {
    label: 'Plumbing Goods Wholesaling',
    BEA_CODES: {
      '424A00': 0.3761178267674958,
      '423800': 0.19935253110050855,
      '423A00': 0.2944298816326274,
      '423100': 0.13009976049936825,
    },
  },
  ['3339']: {
    label: 'Other Hardware Goods Wholesaling',
    BEA_CODES: {
      '424A00': 0.4310264840551062,
      '423A00': 0.33741308613729293,
      '424700': 0.2315604298076009,
    },
  },
  ['3411']: {
    label: 'Agricultural and Construction Machinery Wholesaling',
    BEA_CODES: { '423800': 1.0 },
  },
  ['3419']: {
    label: 'Other Specialised Industrial Machinery and Equipment Wholesaling',
    BEA_CODES: {
      '423600': 0.17379103355911626,
      '423100': 0.12463266112326206,
      '423400': 0.22854379642922523,
      '423800': 0.19097526665189282,
      '423A00': 0.28205724223650364,
    },
  },
  ['3491']: {
    label: 'Professional and Scientific Goods Wholesaling',
    BEA_CODES: {
      '423600': 0.17379103355911626,
      '423100': 0.12463266112326206,
      '423400': 0.22854379642922523,
      '423800': 0.19097526665189282,
      '423A00': 0.28205724223650364,
    },
  },
  ['3492']: {
    label: 'Computer and Computer Peripheral Wholesaling',
    BEA_CODES: {
      '423600': 0.12415678905271273,
      '423400': 0.16327231239416842,
      '424A00': 0.2574082104654524,
      '424200': 0.11722697173694571,
      '423800': 0.13643325210974822,
      '423A00': 0.20150246424097248,
    },
  },
  ['3493']: {
    label: 'Telecommunication Goods Wholesaling',
    BEA_CODES: { '423600': 1.0 },
  },
  ['3494']: {
    label: 'Other Electrical and Electronic Goods Wholesaling',
    BEA_CODES: {
      '423600': 0.12415678905271273,
      '423400': 0.16327231239416842,
      '424A00': 0.2574082104654524,
      '424200': 0.11722697173694571,
      '423800': 0.13643325210974822,
      '423A00': 0.20150246424097248,
    },
  },
  ['3499']: {
    label: 'Other Machinery and Equipment Wholesaling n.e.c.',
    BEA_CODES: {
      '423600': 0.12415678905271273,
      '423400': 0.16327231239416842,
      '424A00': 0.2574082104654524,
      '424200': 0.11722697173694571,
      '423800': 0.13643325210974822,
      '423A00': 0.20150246424097248,
    },
  },
  ['3501']: {
    label: 'Car Wholesaling',
    BEA_CODES: {
      '441000': 0.27930019817042656,
      '423100': 0.18831103840211674,
      '423A00': 0.4261683229395462,
      '425000': 0.10622044048791052,
    },
  },
  ['3502']: {
    label: 'Commercial Vehicle Wholesaling',
    BEA_CODES: {
      '441000': 0.27930019817042656,
      '423100': 0.18831103840211674,
      '423A00': 0.4261683229395462,
      '425000': 0.10622044048791052,
    },
  },
  ['3503']: {
    label: 'Trailer and Other Motor Vehicle Wholesaling',
    BEA_CODES: {
      '441000': 0.27930019817042656,
      '423100': 0.18831103840211674,
      '423A00': 0.4261683229395462,
      '425000': 0.10622044048791052,
    },
  },
  ['3504']: {
    label: 'Motor Vehicle New Parts Wholesaling',
    BEA_CODES: {
      '423100': 0.13822591244970908,
      '425000': 0.07796896789395226,
      '441000': 0.20501466651706285,
      '423A00': 0.31282024567083955,
      '452000': 0.26597020746843625,
    },
  },
  ['3505']: {
    label: 'Motor Vehicle Dismantling and Used Parts Wholesaling',
    BEA_CODES: {
      '423100': 0.13822591244970908,
      '425000': 0.07796896789395226,
      '441000': 0.20501466651706285,
      '423A00': 0.31282024567083955,
      '452000': 0.26597020746843625,
    },
  },
  ['3601']: {
    label: 'General Line Grocery Wholesaling',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3602']: {
    label: 'Meat, Poultry and Smallgoods Wholesaling',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3603']: {
    label: 'Dairy Produce Wholesaling',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3604']: {
    label: 'Fish and Seafood Wholesaling',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3605']: {
    label: 'Fruit and Vegetable Wholesaling',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3606']: {
    label: 'Liquor and Tobacco Product Wholesaling',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3609']: {
    label: 'Other Grocery Wholesaling',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3711']: {
    label: 'Textile Product Wholesaling',
    BEA_CODES: { '424A00': 0.5609113595584196, '423A00': 0.4390886404415804 },
  },
  ['3712']: {
    label: 'Clothing and Footwear Wholesaling',
    BEA_CODES: { '424A00': 0.5609113595584196, '423A00': 0.4390886404415804 },
  },
  ['3720']: {
    label: 'Pharmaceutical and Toiletry Goods Wholesaling',
    BEA_CODES: {
      '423600': 0.12415678905271273,
      '423400': 0.16327231239416842,
      '424A00': 0.2574082104654524,
      '424200': 0.11722697173694571,
      '423800': 0.13643325210974822,
      '423A00': 0.20150246424097248,
    },
  },
  ['3731']: {
    label: 'Furniture and Floor Covering Wholesaling',
    BEA_CODES: {
      '423600': 0.12415678905271273,
      '423400': 0.16327231239416842,
      '424A00': 0.2574082104654524,
      '424200': 0.11722697173694571,
      '423800': 0.13643325210974822,
      '423A00': 0.20150246424097248,
    },
  },
  ['3732']: {
    label: 'Jewellery and Watch Wholesaling',
    BEA_CODES: {
      '423600': 0.12415678905271273,
      '423400': 0.16327231239416842,
      '424A00': 0.2574082104654524,
      '424200': 0.11722697173694571,
      '423800': 0.13643325210974822,
      '423A00': 0.20150246424097248,
    },
  },
  ['3733']: {
    label: 'Kitchen and Diningware Wholesaling',
    BEA_CODES: {
      '423600': 0.12415678905271273,
      '423400': 0.16327231239416842,
      '424A00': 0.2574082104654524,
      '424200': 0.11722697173694571,
      '423800': 0.13643325210974822,
      '423A00': 0.20150246424097248,
    },
  },
  ['3734']: {
    label: 'Toy and Sporting Goods Wholesaling',
    BEA_CODES: {
      '423600': 0.12415678905271273,
      '423400': 0.16327231239416842,
      '424A00': 0.2574082104654524,
      '424200': 0.11722697173694571,
      '423800': 0.13643325210974822,
      '423A00': 0.20150246424097248,
    },
  },
  ['3735']: {
    label: 'Book and Magazine Wholesaling',
    BEA_CODES: {
      '423600': 0.12415678905271273,
      '423400': 0.16327231239416842,
      '424A00': 0.2574082104654524,
      '424200': 0.11722697173694571,
      '423800': 0.13643325210974822,
      '423A00': 0.20150246424097248,
    },
  },
  ['3736']: {
    label: 'Paper Product Wholesaling',
    BEA_CODES: {
      '423600': 0.12415678905271273,
      '423400': 0.16327231239416842,
      '424A00': 0.2574082104654524,
      '424200': 0.11722697173694571,
      '423800': 0.13643325210974822,
      '423A00': 0.20150246424097248,
    },
  },
  ['3739']: {
    label: 'Other Goods Wholesaling n.e.c.',
    BEA_CODES: { '424A00': 0.6925523640202234, '424400': 0.30744763597977665 },
  },
  ['3800']: {
    label: 'Commission-Based Wholesaling',
    BEA_CODES: {
      '441000': 0.27930019817042656,
      '423100': 0.18831103840211674,
      '423A00': 0.4261683229395462,
      '425000': 0.10622044048791052,
    },
  },
  ['3911']: {
    label: 'Car Retailing',
    BEA_CODES: {
      '441000': 0.27930019817042656,
      '423100': 0.18831103840211674,
      '423A00': 0.4261683229395462,
      '425000': 0.10622044048791052,
    },
  },
  ['3912']: {
    label: 'Motorcycle Retailing',
    BEA_CODES: {
      '441000': 0.4416421122902327,
      '423100': 0.2977659354066435,
      '425000': 0.16796056720609562,
      '811400': 0.09263138509702816,
    },
  },
  ['3913']: {
    label: 'Trailer and Other Motor Vehicle Retailing',
    BEA_CODES: {
      '441000': 0.27930019817042656,
      '423100': 0.18831103840211674,
      '423A00': 0.4261683229395462,
      '425000': 0.10622044048791052,
    },
  },
  ['3921']: {
    label: 'Motor Vehicle Parts Retailing',
    BEA_CODES: {
      '423100': 0.13822591244970908,
      '425000': 0.07796896789395226,
      '441000': 0.20501466651706285,
      '423A00': 0.31282024567083955,
      '452000': 0.26597020746843625,
    },
  },
  ['3922']: {
    label: 'Tyre Retailing',
    BEA_CODES: {
      '423100': 0.13822591244970908,
      '425000': 0.07796896789395226,
      '441000': 0.20501466651706285,
      '423A00': 0.31282024567083955,
      '452000': 0.26597020746843625,
    },
  },
  ['4000']: { label: 'Fuel Retailing', BEA_CODES: { '447000': 1.0 } },
  ['4110']: {
    label: 'Supermarket and Grocery Stores',
    BEA_CODES: {
      '447000': 0.14926291540388556,
      '445000': 0.414711910726486,
      '452000': 0.4360251738696284,
    },
  },
  ['4121']: {
    label: 'Fresh Meat, Fish and Poultry Retailing',
    BEA_CODES: { '446000': 0.3358326465482474, '445000': 0.6641673534517526 },
  },
  ['4122']: {
    label: 'Fruit and Vegetable Retailing',
    BEA_CODES: { '446000': 0.3358326465482474, '445000': 0.6641673534517526 },
  },
  ['4123']: { label: 'Liquor Retailing', BEA_CODES: { '445000': 1.0 } },
  ['4129']: {
    label: 'Other Specialised Food Retailing',
    BEA_CODES: { '446000': 0.3358326465482474, '445000': 0.6641673534517526 },
  },
  ['4211']: {
    label: 'Furniture Retailing',
    BEA_CODES: { '4B0000': 0.6246083381395177, '444000': 0.3753916618604824 },
  },
  ['4212']: {
    label: 'Floor Coverings Retailing',
    BEA_CODES: { '4B0000': 0.6246083381395177, '444000': 0.3753916618604824 },
  },
  ['4213']: {
    label: 'Houseware Retailing',
    BEA_CODES: { '4B0000': 0.6246083381395177, '444000': 0.3753916618604824 },
  },
  ['4214']: {
    label: 'Manchester and Other Textile Goods Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4221']: {
    label: 'Electrical, Electronic and Gas Appliance Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4222']: {
    label: 'Computer and Computer Peripheral Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4229']: {
    label: 'Other Electrical and Electronic Goods Retailing',
    BEA_CODES: { '4B0000': 0.6246083381395177, '444000': 0.3753916618604824 },
  },
  ['4231']: {
    label: 'Hardware and Building Supplies Retailing',
    BEA_CODES: { '4B0000': 0.6246083381395177, '444000': 0.3753916618604824 },
  },
  ['4232']: {
    label: 'Garden Supplies Retailing',
    BEA_CODES: { '4B0000': 0.6246083381395177, '444000': 0.3753916618604824 },
  },
  ['4241']: {
    label: 'Sport and Camping Equipment Retailing',
    BEA_CODES: { '441000': 0.4531866833985384, '4B0000': 0.5468133166014616 },
  },
  ['4242']: {
    label: 'Entertainment Media Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4243']: { label: 'Toy and Game Retailing', BEA_CODES: { '4B0000': 1.0 } },
  ['4244']: {
    label: 'Newspaper and Book Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4245']: {
    label: 'Marine Equipment Retailing',
    BEA_CODES: {
      '441000': 0.27930019817042656,
      '423100': 0.18831103840211674,
      '423A00': 0.4261683229395462,
      '425000': 0.10622044048791052,
    },
  },
  ['4251']: { label: 'Clothing Retailing', BEA_CODES: { '448000': 1.0 } },
  ['4252']: { label: 'Footwear Retailing', BEA_CODES: { '448000': 1.0 } },
  ['4253']: {
    label: 'Watch and Jewellery Retailing',
    BEA_CODES: {
      '4B0000': 0.2767351610796724,
      '448000': 0.1844970808558838,
      '446000': 0.14309708713200628,
      '444000': 0.16631874035232957,
      '441000': 0.22935193058010797,
    },
  },
  ['4259']: {
    label: 'Other Personal Accessory Retailing',
    BEA_CODES: { '448000': 1.0 },
  },
  ['4260']: { label: 'Department Stores', BEA_CODES: { '452000': 1.0 } },
  ['4271']: {
    label: 'Pharmaceutical, Cosmetic and Toiletry Goods Retailing',
    BEA_CODES: { '446000': 1.0 },
  },
  ['4272']: {
    label: 'Stationery Goods Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4273']: {
    label: 'Antique and Used Goods Retailing',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['4274']: {
    label: 'Flower Retailing',
    BEA_CODES: {
      '4B0000': 0.2767351610796724,
      '448000': 0.1844970808558838,
      '446000': 0.14309708713200628,
      '444000': 0.16631874035232957,
      '441000': 0.22935193058010797,
    },
  },
  ['4279']: {
    label: 'Other Store-Based Retailing n.e.c.',
    BEA_CODES: {
      '447000': 0.14926291540388556,
      '445000': 0.414711910726486,
      '452000': 0.4360251738696284,
    },
  },
  ['4310']: { label: 'Non-Store Retailing', BEA_CODES: { '454000': 1.0 } },
  ['4320']: {
    label: 'Retail Commission-Based Buying and/or Selling',
    BEA_CODES: {
      '441000': 0.27930019817042656,
      '423100': 0.18831103840211674,
      '423A00': 0.4261683229395462,
      '425000': 0.10622044048791052,
    },
  },
  ['4400']: { label: 'Accommodation', BEA_CODES: { '721000': 1.0 } },
  ['4511']: {
    label: 'Cafes and Restaurants',
    BEA_CODES: {
      '722A00': 0.20503367231452857,
      '722211': 0.40996607451017425,
      '722110': 0.3850002531752972,
    },
  },
  ['4512']: {
    label: 'Takeaway Food Services',
    BEA_CODES: {
      '722A00': 0.20503367231452857,
      '722211': 0.40996607451017425,
      '722110': 0.3850002531752972,
    },
  },
  ['4513']: {
    label: 'Catering Services',
    BEA_CODES: {
      '722A00': 0.20503367231452857,
      '722211': 0.40996607451017425,
      '722110': 0.3850002531752972,
    },
  },
  ['4520']: { label: 'Pubs, Taverns and Bars', BEA_CODES: { '722A00': 1.0 } },
  ['4530']: {
    label: 'Clubs (Hospitality)',
    BEA_CODES: {
      '722A00': 0.20503367231452857,
      '722211': 0.40996607451017425,
      '722110': 0.3850002531752972,
    },
  },
  ['4610']: { label: 'Road Freight Transport', BEA_CODES: { '484000': 1.0 } },
  ['4621']: {
    label: 'Interurban and Rural Bus Transport',
    BEA_CODES: {
      '713900': 0.2556033419657171,
      '48A000': 0.49024232334443213,
      '485000': 0.25415433468985077,
    },
  },
  ['4622']: {
    label: 'Urban Bus Transport (Including Tramway)',
    BEA_CODES: { '485000': 1.0 },
  },
  ['4623']: {
    label: 'Taxi and Other Road Transport',
    BEA_CODES: {
      '713900': 0.2556033419657171,
      '48A000': 0.49024232334443213,
      '485000': 0.25415433468985077,
    },
  },
  ['4710']: { label: 'Rail Freight Transport', BEA_CODES: { '482000': 1.0 } },
  ['4720']: {
    label: 'Rail Passenger Transport',
    BEA_CODES: { '482000': 0.3798257630787381, '48A000': 0.6201742369212618 },
  },
  ['4810']: { label: 'Water Freight Transport', BEA_CODES: { '483000': 1.0 } },
  ['4820']: {
    label: 'Water Passenger Transport',
    BEA_CODES: { '48A000': 0.7354740237965843, '483000': 0.2645259762034157 },
  },
  ['4900']: {
    label: 'Air and Space Transport',
    BEA_CODES: { '48A000': 0.3966252618976354, '481000': 0.6033747381023645 },
  },
  ['5010']: {
    label: 'Scenic and Sightseeing Transport',
    BEA_CODES: { '485000': 1.0 },
  },
  ['5021']: {
    label: 'Pipeline Transport',
    BEA_CODES: { '486000': 0.36355735275282236, '221300': 0.6364426472471777 },
  },
  ['5029']: { label: 'Other Transport n.e.c.', BEA_CODES: { '485000': 1.0 } },
  ['5101']: { label: 'Postal Services', BEA_CODES: { '491000': 1.0 } },
  ['5102']: {
    label: 'Courier Pick-up and Delivery Services',
    BEA_CODES: { '492000': 1.0 },
  },
  ['5211']: { label: 'Stevedoring Services', BEA_CODES: { '48A000': 1.0 } },
  ['5212']: {
    label: 'Port and Water Transport Terminal Operations',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['5219']: {
    label: 'Other Water Transport Support Services',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['5220']: {
    label: 'Airport Operations and Other Air Transport Support Services',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['5291']: { label: 'Customs Agency Services', BEA_CODES: { '48A000': 1.0 } },
  ['5292']: {
    label: 'Freight Forwarding Services',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['5299']: {
    label: 'Other Transport Support Services n.e.c.',
    BEA_CODES: { '482000': 0.3798257630787381, '48A000': 0.6201742369212618 },
  },
  ['5301']: { label: 'Grain Storage Services', BEA_CODES: { '493000': 1.0 } },
  ['5309']: {
    label: 'Other Warehousing and Storage Services',
    BEA_CODES: { '493000': 1.0 },
  },
  ['5411']: {
    label: 'Newspaper Publishing',
    BEA_CODES: {
      '511120': 0.16133948665356238,
      '519130': 0.7153946956100619,
      '511110': 0.12326581773637581,
    },
  },
  ['5412']: {
    label: 'Magazine and Other Periodical Publishing',
    BEA_CODES: {
      '511120': 0.16133948665356238,
      '519130': 0.7153946956100619,
      '511110': 0.12326581773637581,
    },
  },
  ['5413']: {
    label: 'Book Publishing',
    BEA_CODES: {
      '5111A0': 0.1154504892709481,
      '519130': 0.5479070985196417,
      '511130': 0.33664241220941016,
    },
  },
  ['5414']: {
    label: 'Directory and Mailing List Publishing',
    BEA_CODES: { '5111A0': 0.17403960005262464, '519130': 0.8259603999473754 },
  },
  ['5419']: {
    label: 'Other Publishing (except Software, Music and Internet)',
    BEA_CODES: { '5111A0': 0.17403960005262464, '519130': 0.8259603999473754 },
  },
  ['5420']: { label: 'Software Publishing', BEA_CODES: { '511200': 1.0 } },
  ['5511']: {
    label: 'Motion Picture and Video Production',
    BEA_CODES: { '512100': 1.0 },
  },
  ['5512']: {
    label: 'Motion Picture and Video Distribution',
    BEA_CODES: { '512100': 1.0 },
  },
  ['5513']: {
    label: 'Motion Picture Exhibition',
    BEA_CODES: { '512100': 1.0 },
  },
  ['5514']: {
    label:
      'Post-production Services and Other Motion Picture and Video Activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['5521']: {
    label: 'Music Publishing',
    BEA_CODES: { '512200': 0.23873832908936582, '519130': 0.7612616709106341 },
  },
  ['5522']: {
    label: 'Music and Other Sound Recording Activities',
    BEA_CODES: { '512200': 0.23873832908936582, '519130': 0.7612616709106341 },
  },
  ['5610']: {
    label: 'Radio Broadcasting',
    BEA_CODES: { '515100': 0.5037104376438968, '519130': 0.4962895623561032 },
  },
  ['5621']: {
    label: 'Free-to-Air Television Broadcasting',
    BEA_CODES: {
      '515200': 0.16986440705772338,
      '519130': 0.4119876301175467,
      '515100': 0.41814796282472994,
    },
  },
  ['5622']: {
    label: 'Cable and Other Subscription Broadcasting',
    BEA_CODES: {
      '515200': 0.16986440705772338,
      '519130': 0.4119876301175467,
      '515100': 0.41814796282472994,
    },
  },
  ['5700']: {
    label: 'Internet Publishing and Broadcasting',
    BEA_CODES: {
      '5111A0': 0.1154504892709481,
      '519130': 0.5479070985196417,
      '511130': 0.33664241220941016,
    },
  },
  ['5801']: {
    label: 'Wired Telecommunications Network Operation',
    BEA_CODES: { '517110': 1.0 },
  },
  ['5802']: {
    label: 'Other Telecommunications Network Operation',
    BEA_CODES: {
      '515100': 0.18770505385252692,
      '515200': 0.07625149590352573,
      '517210': 0.7360434502439474,
    },
  },
  ['5809']: {
    label: 'Other Telecommunications Services',
    BEA_CODES: { '517A00': 0.2810506227627404, '812900': 0.7189493772372596 },
  },
  ['5910']: {
    label: 'Internet Service Providers and Web Search Portals',
    BEA_CODES: { '517110': 1.0 },
  },
  ['5921']: {
    label: 'Data Processing and Web Hosting Services',
    BEA_CODES: { '541512': 0.6633762210880862, '54151A': 0.3366237789119137 },
  },
  ['5922']: {
    label: 'Electronic Information Storage Services',
    BEA_CODES: { '541512': 0.6633762210880862, '54151A': 0.3366237789119137 },
  },
  ['6010']: { label: 'Libraries and Archives', BEA_CODES: { '5191A0': 1.0 } },
  ['6020']: {
    label: 'Other Information Services',
    BEA_CODES: { '5191A0': 1.0 },
  },
  ['6210']: {
    label: 'Central Banking',
    BEA_CODES: { '52A000': 0.5817258632009072, '522A00': 0.4182741367990928 },
  },
  ['6221']: {
    label: 'Banking',
    BEA_CODES: { '52A000': 0.5817258632009072, '522A00': 0.4182741367990928 },
  },
  ['6222']: {
    label: 'Building Society Operation',
    BEA_CODES: { '52A000': 0.5817258632009072, '522A00': 0.4182741367990928 },
  },
  ['6223']: {
    label: 'Credit Union Operation',
    BEA_CODES: { '52A000': 0.5817258632009072, '522A00': 0.4182741367990928 },
  },
  ['6229']: {
    label: 'Other Depository Financial Intermediation',
    BEA_CODES: { '52A000': 0.5817258632009072, '522A00': 0.4182741367990928 },
  },
  ['6230']: { label: 'Non-Depository Financing', BEA_CODES: { '522A00': 1.0 } },
  ['6240']: {
    label: 'Financial Asset Investing',
    BEA_CODES: { '550000': 1.0 },
  },
  ['6310']: { label: 'Life Insurance', BEA_CODES: { '524113': 1.0 } },
  ['6321']: { label: 'Health Insurance', BEA_CODES: { '5241XX': 1.0 } },
  ['6322']: { label: 'General Insurance', BEA_CODES: { '5241XX': 1.0 } },
  ['6330']: { label: 'Superannuation Funds', BEA_CODES: { '525000': 1.0 } },
  ['6411']: {
    label: 'Financial Asset Broking Services',
    BEA_CODES: { '523900': 0.6813741146991368, '523A00': 0.3186258853008632 },
  },
  ['6419']: {
    label: 'Other Auxiliary Finance and Investment Services',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['6420']: {
    label: 'Auxiliary Insurance Services',
    BEA_CODES: { '524200': 1.0 },
  },
  ['6611']: {
    label: 'Passenger Car Rental and Hiring',
    BEA_CODES: { '532100': 1.0 },
  },
  ['6619']: {
    label: 'Other Motor Vehicle and Transport Equipment Rental and Hiring',
    BEA_CODES: { '532100': 1.0 },
  },
  ['6620']: {
    label: 'Farm Animal and Bloodstock Leasing',
    BEA_CODES: {
      '532400': 0.3844153812170968,
      '532100': 0.45596951735817104,
      '532A00': 0.15961510142473218,
    },
  },
  ['6631']: {
    label: 'Heavy Machinery and Scaffolding Rental and Hiring',
    BEA_CODES: {
      '532400': 0.3844153812170968,
      '532100': 0.45596951735817104,
      '532A00': 0.15961510142473218,
    },
  },
  ['6632']: {
    label: 'Video and Other Electronic Media Rental and Hiring',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['6639']: {
    label: 'Other Goods and Equipment Rental and Hiring n.e.c.',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['6640']: {
    label: 'Non-Financial Intangible Assets (Except Copyrights) Leasing',
    BEA_CODES: { '533000': 1.0 },
  },
  ['6711']: {
    label: 'Residential Property Operators',
    BEA_CODES: {
      '531HSO': 0.4324602451197983,
      '523900': 0.11204285699763826,
      '531HST': 0.1443340189278296,
      '531ORE': 0.3111628789547338,
    },
  },
  ['6712']: {
    label: 'Non-Residential Property Operators',
    BEA_CODES: {
      '531HSO': 0.4324602451197983,
      '523900': 0.11204285699763826,
      '531HST': 0.1443340189278296,
      '531ORE': 0.3111628789547338,
    },
  },
  ['6720']: { label: 'Real Estate Services', BEA_CODES: { '531ORE': 1.0 } },
  ['6910']: {
    label: 'Scientific Research Services',
    BEA_CODES: { '541700': 1.0 },
  },
  ['6921']: { label: 'Architectural Services', BEA_CODES: { '541300': 1.0 } },
  ['6922']: {
    label: 'Surveying and Mapping Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['6923']: {
    label: 'Engineering Design and Engineering Consulting Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['6924']: {
    label: 'Other Specialised Design Services',
    BEA_CODES: { '541800': 1.0 },
  },
  ['6925']: {
    label: 'Scientific Testing and Analysis Services',
    BEA_CODES: { '811100': 0.39299389467359985, '541300': 0.6070061053264001 },
  },
  ['6931']: {
    label: 'Legal Services',
    BEA_CODES: { '5419A0': 0.2072530294145758, '541100': 0.7927469705854242 },
  },
  ['6932']: { label: 'Accounting Services', BEA_CODES: { '541200': 1.0 } },
  ['6940']: { label: 'Advertising Services', BEA_CODES: { '541800': 1.0 } },
  ['6950']: {
    label: 'Market Research and Statistical Services',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['6961']: {
    label: 'Corporate Head Office Management Services',
    BEA_CODES: { '550000': 1.0 },
  },
  ['6962']: {
    label: 'Management Advice and Related Consulting Services',
    BEA_CODES: { '115000': 0.6104745956903009, '113000': 0.389525404309699 },
  },
  ['6970']: {
    label: 'Veterinary Services',
    BEA_CODES: { '115000': 0.5205538646526133, '541940': 0.4794461353473866 },
  },
  ['6991']: {
    label: 'Professional Photographic Services',
    BEA_CODES: {
      '711500': 0.15241994177583698,
      '812900': 0.28961062590975256,
      '541920': 0.05127820232896652,
      '518200': 0.5066912299854439,
    },
  },
  ['6999']: {
    label: 'Other Professional, Scientific and Technical Services n.e.c.',
    BEA_CODES: { '212100': 0.13168853735993769, '211000': 0.8683114626400623 },
  },
  ['7000']: {
    label: 'Computer System Design and Related Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['7211']: {
    label: 'Employment Placement and Recruitment Services',
    BEA_CODES: {
      '5419A0': 0.2333017348167189,
      '5416A0': 0.1296343683301444,
      '711A00': 0.0705877905658073,
      '541610': 0.5664761062873294,
    },
  },
  ['7212']: { label: 'Labour Supply Services', BEA_CODES: { '561300': 1.0 } },
  ['7220']: {
    label: 'Travel Agency and Tour Arrangement Services',
    BEA_CODES: { '561500': 1.0 },
  },
  ['7291']: {
    label: 'Office Administrative Services',
    BEA_CODES: { '561300': 1.0 },
  },
  ['7292']: {
    label: 'Document Preparation Services',
    BEA_CODES: { '323110': 0.46634394753593583, '561400': 0.5336560524640642 },
  },
  ['7293']: {
    label: 'Credit Reporting and Debt Collection Services',
    BEA_CODES: { '561400': 1.0 },
  },
  ['7294']: { label: 'Call Centre Operation', BEA_CODES: { '561400': 1.0 } },
  ['7299']: {
    label: 'Other Administrative Services n.e.c.',
    BEA_CODES: { '561500': 1.0 },
  },
  ['7311']: {
    label: 'Building and Other Industrial Cleaning Services',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['7312']: {
    label: 'Building Pest Control Services',
    BEA_CODES: {
      '230301': 0.14804385712821186,
      '2334A0': 0.1419911499219358,
      '561700': 0.1191424533169306,
      '233411': 0.11175618421472783,
      '48A000': 0.10689670819447315,
      '233240': 0.09543412607579875,
      '2332D0': 0.07874233432993347,
      '233262': 0.06853936738579854,
      '2332A0': 0.06797132211696018,
      '230302': 0.06148249731522983,
    },
  },
  ['7313']: { label: 'Gardening Services', BEA_CODES: { '561700': 1.0 } },
  ['7320']: {
    label: 'Packaging Services',
    BEA_CODES: {
      '561900': 0.23460884706272542,
      '3259A0': 0.2672221991901939,
      '561400': 0.4981689537470807,
    },
  },
  ['7510']: {
    label: 'Central Government Administration',
    BEA_CODES: {
      '561400': 0.03720726206818252,
      GSLGE: 0.3709063135405163,
      GSLGO: 0.3809613260808984,
      GSLGH: 0.03086327371061133,
      S00600: 0.1800618245997915,
    },
  },
  ['7520']: {
    label: 'State Government Administration',
    BEA_CODES: {
      '561400': 0.03720726206818252,
      GSLGE: 0.3709063135405163,
      GSLGO: 0.3809613260808984,
      GSLGH: 0.03086327371061133,
      S00600: 0.1800618245997915,
    },
  },
  ['7530']: {
    label: 'Local Government Administration',
    BEA_CODES: {
      '561400': 0.03720726206818252,
      GSLGE: 0.3709063135405163,
      GSLGO: 0.3809613260808984,
      GSLGH: 0.03086327371061133,
      S00600: 0.1800618245997915,
    },
  },
  ['7540']: {
    label: 'Justice',
    BEA_CODES: {
      '561200': 0.013853596804598004,
      S00500: 0.3429193635560711,
      S00203: 0.044841614047113725,
      S00102: 0.007251347895437588,
      GSLGO: 0.38925058331905243,
      '561900': 0.017903742469581724,
      S00600: 0.18397975190814536,
    },
  },
  ['7551']: {
    label: 'Domestic Government Representation',
    BEA_CODES: {
      S00500: 0.3743049460273791,
      GSLGO: 0.4248766155094664,
      S00600: 0.20081843846315445,
    },
  },
  ['7552']: {
    label: 'Foreign Government Representation',
    BEA_CODES: { S00600: 1.0 },
  },
  ['7600']: {
    label: 'Defence',
    BEA_CODES: {
      S00500: 0.3743049460273791,
      GSLGO: 0.4248766155094664,
      S00600: 0.20081843846315445,
    },
  },
  ['7711']: {
    label: 'Police Services',
    BEA_CODES: {
      '561200': 0.013853596804598004,
      S00500: 0.3429193635560711,
      S00203: 0.044841614047113725,
      S00102: 0.007251347895437588,
      GSLGO: 0.38925058331905243,
      '561900': 0.017903742469581724,
      S00600: 0.18397975190814536,
    },
  },
  ['7712']: {
    label: 'Investigation and Security Services',
    BEA_CODES: { '327999': 0.2583469454426658, '339910': 0.7416530545573342 },
  },
  ['7713']: {
    label: 'Fire Protection and Other Emergency Services',
    BEA_CODES: { '115000': 0.6104745956903009, '113000': 0.389525404309699 },
  },
  ['7714']: {
    label: 'Correctional and Detention Services',
    BEA_CODES: {
      '561200': 0.013853596804598004,
      S00500: 0.3429193635560711,
      S00203: 0.044841614047113725,
      S00102: 0.007251347895437588,
      GSLGO: 0.38925058331905243,
      '561900': 0.017903742469581724,
      S00600: 0.18397975190814536,
    },
  },
  ['7719']: {
    label: 'Other Public Order and Safety Services',
    BEA_CODES: {
      '561200': 0.013853596804598004,
      S00500: 0.3429193635560711,
      S00203: 0.044841614047113725,
      S00102: 0.007251347895437588,
      GSLGO: 0.38925058331905243,
      '561900': 0.017903742469581724,
      S00600: 0.18397975190814536,
    },
  },
  ['7720']: {
    label: 'Regulatory Services',
    BEA_CODES: { '811100': 0.39299389467359985, '541300': 0.6070061053264001 },
  },
  ['8010']: {
    label: 'Preschool Education',
    BEA_CODES: { '611100': 0.36243332953818935, '611B00': 0.6375666704618106 },
  },
  ['8021']: {
    label: 'Primary Education',
    BEA_CODES: { '611100': 0.36243332953818935, '611B00': 0.6375666704618106 },
  },
  ['8022']: { label: 'Secondary Education', BEA_CODES: { '611100': 1.0 } },
  ['8023']: {
    label: 'Combined Primary and Secondary Education',
    BEA_CODES: { '611100': 0.36243332953818935, '611B00': 0.6375666704618106 },
  },
  ['8024']: {
    label: 'Special School Education',
    BEA_CODES: { '611100': 0.36243332953818935, '611B00': 0.6375666704618106 },
  },
  ['8101']: {
    label: 'Technical and Vocational Education and Training',
    BEA_CODES: { '611100': 0.36243332953818935, '611B00': 0.6375666704618106 },
  },
  ['8102']: { label: 'Higher Education', BEA_CODES: { '611A00': 1.0 } },
  ['8211']: {
    label: 'Sports and Physical Recreation Instruction',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['8212']: { label: 'Arts Education', BEA_CODES: { '611B00': 1.0 } },
  ['8219']: {
    label: 'Adult, Community and Other Education n.e.c.',
    BEA_CODES: { '611100': 0.36243332953818935, '611B00': 0.6375666704618106 },
  },
  ['8220']: {
    label: 'Educational Support Services',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['8401']: {
    label: 'Hospitals (Except Psychiatric Hospitals)',
    BEA_CODES: { '622000': 1.0 },
  },
  ['8402']: { label: 'Psychiatric Hospitals', BEA_CODES: { '622000': 1.0 } },
  ['8511']: {
    label: 'General Practice Medical Services',
    BEA_CODES: {
      '621200': 0.17146171693735499,
      '621400': 0.1643715497239779,
      '621100': 0.664166733338667,
    },
  },
  ['8512']: {
    label: 'Specialist Medical Services',
    BEA_CODES: {
      '621200': 0.17146171693735499,
      '621400': 0.1643715497239779,
      '621100': 0.664166733338667,
    },
  },
  ['8520']: {
    label: 'Pathology and Diagnostic Imaging Services',
    BEA_CODES: {
      '621900': 0.09456218573498577,
      '621500': 0.14542530648254914,
      '621400': 0.28679675132685795,
      '621600': 0.24376495419862246,
      '621300': 0.22945080225698467,
    },
  },
  ['8531']: {
    label: 'Dental Services',
    BEA_CODES: {
      '621200': 0.17146171693735499,
      '621400': 0.1643715497239779,
      '621100': 0.664166733338667,
    },
  },
  ['8532']: {
    label: 'Optometry and Optical Dispensing',
    BEA_CODES: {
      '621900': 0.09456218573498577,
      '621500': 0.14542530648254914,
      '621400': 0.28679675132685795,
      '621600': 0.24376495419862246,
      '621300': 0.22945080225698467,
    },
  },
  ['8533']: {
    label: 'Physiotherapy Services',
    BEA_CODES: {
      '621900': 0.09456218573498577,
      '621500': 0.14542530648254914,
      '621400': 0.28679675132685795,
      '621600': 0.24376495419862246,
      '621300': 0.22945080225698467,
    },
  },
  ['8534']: {
    label: 'Chiropractic and Osteopathic Services',
    BEA_CODES: {
      '621900': 0.09456218573498577,
      '621500': 0.14542530648254914,
      '621400': 0.28679675132685795,
      '621600': 0.24376495419862246,
      '621300': 0.22945080225698467,
    },
  },
  ['8539']: {
    label: 'Other Allied Health Services',
    BEA_CODES: {
      '621900': 0.09456218573498577,
      '621500': 0.14542530648254914,
      '621400': 0.28679675132685795,
      '621600': 0.24376495419862246,
      '621300': 0.22945080225698467,
    },
  },
  ['8591']: {
    label: 'Ambulance Services',
    BEA_CODES: {
      '621900': 0.09456218573498577,
      '621500': 0.14542530648254914,
      '621400': 0.28679675132685795,
      '621600': 0.24376495419862246,
      '621300': 0.22945080225698467,
    },
  },
  ['8599']: {
    label: 'Other Health Care Services n.e.c.',
    BEA_CODES: {
      '621900': 0.09456218573498577,
      '621500': 0.14542530648254914,
      '621400': 0.28679675132685795,
      '621600': 0.24376495419862246,
      '621300': 0.22945080225698467,
    },
  },
  ['8601']: {
    label: 'Aged Care Residential Services',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['8609']: {
    label: 'Other Residential Care Services',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['8710']: {
    label: 'Child Care Services',
    BEA_CODES: {
      '621400': 0.2900054769150852,
      '624100': 0.22721292777812283,
      '624400': 0.13306927450637185,
      '5419A0': 0.22650713975167555,
      '624A00': 0.12320518104874455,
    },
  },
  ['8790']: {
    label: 'Other Social Assistance Services',
    BEA_CODES: {
      '722A00': 0.20503367231452857,
      '722211': 0.40996607451017425,
      '722110': 0.3850002531752972,
    },
  },
  ['8910']: {
    label: 'Museum Operation',
    BEA_CODES: {
      '711500': 0.3852512733251319,
      '711A00': 0.2790967727100268,
      '711100': 0.33565195396484127,
    },
  },
  ['8921']: {
    label: 'Zoological and Botanical Gardens Operation',
    BEA_CODES: { '712000': 1.0 },
  },
  ['8922']: {
    label: 'Nature Reserves and Conservation Parks Operation',
    BEA_CODES: { '712000': 1.0 },
  },
  ['9001']: {
    label: 'Performing Arts Operation',
    BEA_CODES: {
      '711500': 0.3852512733251319,
      '711A00': 0.2790967727100268,
      '711100': 0.33565195396484127,
    },
  },
  ['9002']: {
    label: 'Creative Artists, Musicians, Writers and Performers',
    BEA_CODES: {
      '711500': 0.15241994177583698,
      '812900': 0.28961062590975256,
      '541920': 0.05127820232896652,
      '518200': 0.5066912299854439,
    },
  },
  ['9003']: {
    label: 'Performing Arts Venue Operation',
    BEA_CODES: {
      '711500': 0.3852512733251319,
      '711A00': 0.2790967727100268,
      '711100': 0.33565195396484127,
    },
  },
  ['9111']: {
    label: 'Health and Fitness Centres and Gymnasia Operation',
    BEA_CODES: {
      '713900': 0.53673971449843,
      '711A00': 0.19644417829281716,
      '711200': 0.2668161072087528,
    },
  },
  ['9112']: {
    label: 'Sports and Physical Recreation Clubs and Sports Professionals',
    BEA_CODES: { '713900': 0.6679557287732761, '711200': 0.33204427122672386 },
  },
  ['9113']: {
    label:
      'Sports and Physical Recreation Venues, Grounds and Facilities Operation',
    BEA_CODES: {
      '713900': 0.53673971449843,
      '711A00': 0.19644417829281716,
      '711200': 0.2668161072087528,
    },
  },
  ['9114']: {
    label: 'Sports and Physical Recreation Administrative Service',
    BEA_CODES: {
      '713900': 0.3380168279643871,
      '813B00': 0.3702406979884926,
      '711A00': 0.12371254860590865,
      '711200': 0.1680299254412117,
    },
  },
  ['9121']: {
    label: 'Horse and Dog Racing Administration and Track Operation',
    BEA_CODES: {
      '713900': 0.53673971449843,
      '711A00': 0.19644417829281716,
      '711200': 0.2668161072087528,
    },
  },
  ['9129']: {
    label: 'Other Horse and Dog Racing Activities',
    BEA_CODES: {
      '713900': 0.3380168279643871,
      '813B00': 0.3702406979884926,
      '711A00': 0.12371254860590865,
      '711200': 0.1680299254412117,
    },
  },
  ['9131']: {
    label: 'Amusement Parks and Centres Operation',
    BEA_CODES: { '713100': 1.0 },
  },
  ['9139']: {
    label: 'Amusement and Other Recreational Activities n.e.c.',
    BEA_CODES: {
      '713900': 0.53673971449843,
      '711A00': 0.19644417829281716,
      '711200': 0.2668161072087528,
    },
  },
  ['9201']: {
    label: 'Casino Operation',
    BEA_CODES: { '721000': 0.6601637246798537, '713200': 0.33983627532014626 },
  },
  ['9202']: {
    label: 'Lottery Operation',
    BEA_CODES: { '721000': 0.6601637246798537, '713200': 0.33983627532014626 },
  },
  ['9209']: {
    label: 'Other Gambling Activities',
    BEA_CODES: { '721000': 0.6601637246798537, '713200': 0.33983627532014626 },
  },
  ['9411']: {
    label: 'Automotive Electrical Services',
    BEA_CODES: { '811100': 0.7568537659554531, '811300': 0.24314623404454694 },
  },
  ['9412']: {
    label: 'Automotive Body, Paint and Interior Repair',
    BEA_CODES: { '811100': 0.7568537659554531, '811300': 0.24314623404454694 },
  },
  ['9419']: {
    label: 'Other Automotive Repair and Maintenance',
    BEA_CODES: { '811100': 0.7568537659554531, '811300': 0.24314623404454694 },
  },
  ['9421']: {
    label: 'Domestic Appliance Repair and Maintenance',
    BEA_CODES: { '4B0000': 0.7965350090538803, '811200': 0.20346499094611972 },
  },
  ['9422']: {
    label:
      'Electronic (except Domestic Appliance) and Precision Equipment Repair and Maintenance',
    BEA_CODES: {
      '811400': 0.23130258591577632,
      '811300': 0.4288064838299432,
      '811200': 0.3398909302542805,
    },
  },
  ['9429']: {
    label: 'Other Machinery and Equipment Repair and Maintenance',
    BEA_CODES: { '811300': 0.6495994427028909, '811400': 0.350400557297109 },
  },
  ['9491']: {
    label: 'Clothing and Footwear Repair',
    BEA_CODES: { '448000': 0.7931876292898534, '811400': 0.2068123707101466 },
  },
  ['9499']: {
    label: 'Other Repair and Maintenance n.e.c.',
    BEA_CODES: { '811300': 0.6495994427028909, '811400': 0.350400557297109 },
  },
  ['9511']: {
    label: 'Hairdressing and Beauty Services',
    BEA_CODES: { '812100': 1.0 },
  },
  ['9512']: {
    label: 'Diet and Weight Reduction Centre Operation',
    BEA_CODES: { '812900': 0.4846021525018648, '812100': 0.5153978474981352 },
  },
  ['9520']: {
    label: 'Funeral, Crematorium and Cemetery Services',
    BEA_CODES: { '812200': 1.0 },
  },
  ['9531']: {
    label: 'Laundry and Dry-Cleaning Services',
    BEA_CODES: { '561700': 0.846654524826083, '812300': 0.15334547517391695 },
  },
  ['9532']: {
    label: 'Photographic Film Processing',
    BEA_CODES: {
      '711500': 0.15241994177583698,
      '812900': 0.28961062590975256,
      '541920': 0.05127820232896652,
      '518200': 0.5066912299854439,
    },
  },
  ['9533']: {
    label: 'Parking Services',
    BEA_CODES: { '812900': 0.3335498742665549, '48A000': 0.6664501257334451 },
  },
  ['9534']: {
    label: 'Brothel Keeping and Prostitution Services',
    BEA_CODES: { '812900': 0.4846021525018648, '812100': 0.5153978474981352 },
  },
  ['9539']: {
    label: 'Other Personal Services n.e.c.',
    BEA_CODES: { '812900': 0.4846021525018648, '812100': 0.5153978474981352 },
  },
  ['9540']: { label: 'Religious Services', BEA_CODES: { '813100': 1.0 } },
  ['9551']: {
    label: 'Business and Professional Association Services',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['9552']: {
    label: 'Labour Association Services',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['9559']: {
    label: 'Other Interest Group Services n.e.c.',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['9601']: {
    label: 'Private Households Employing Staff',
    BEA_CODES: { '814000': 1.0 },
  },
};
