import Field, { filterFieldInputProps } from './Field';
import type { FieldProps } from './Field';
import { Autocomplete, AutocompleteProps } from './Autocomplete';
import { useField } from 'formik';
import { AutocompleteValue, TextFieldProps } from '@mui/material';
import usePaletteUtils from '../../hooks/usePaletteUtils';
import {
  TestIdPrefix,
  getPrefixedTestId,
} from '@watershed/shared-universal/utils/testUtils';

export type AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<FieldProps, 'inputId'> & {
  id: string;
  size?: 'medium' | 'small' | undefined;
} & Omit<
    AutocompleteProps<T, Multiple, DisabledClearable, FreeSolo>,
    'renderInput'
  >;

function getInputProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: AutocompleteFieldProps<T, Multiple, DisabledClearable, FreeSolo>) {
  const { id, validationState, validationMessage, required } = props;
  const result: Record<string, any> = {
    'aria-invalid': validationState === 'error',
    'aria-required': required,
    'aria-describedby': validationMessage && `${id}-validationMessage`,
  };
  return result;
}

function getGenericOptionLabel<T>(item: T) {
  if (typeof item === 'string') {
    return item;
  }
  if (item && typeof item === 'object' && 'name' in item) {
    return String((item as any).name);
  }
  return JSON.stringify(item);
}

export function AutocompleteFieldNonFormik<
  T,
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  className,
  AutocompleteInputProps,
  ...props
}: AutocompleteFieldProps<T, Multiple, DisabledClearable, FreeSolo> & {
  /**
   * Allows customizing the input field itself for the autocomplete component
   */
  AutocompleteInputProps?: TextFieldProps['InputProps'];
}) {
  return (
    <Field
      {...props}
      dataTest={
        props.dataTest ??
        getPrefixedTestId(TestIdPrefix.autocompleteField, props.id)
      }
      className={className}
      inputId={props.id}
    >
      <Autocomplete
        InputProps={AutocompleteInputProps}
        getOptionLabel={getGenericOptionLabel}
        {...filterFieldInputProps(props)}
        {...getInputProps(props)}
        isOptionEqualToValue={props.isOptionEqualToValue}
        size={props.size}
        validationState={props.validationState}
        sx={props.sx}
        renderOption={props.renderOption}
      />
    </Field>
  );
}

export function AutocompleteField<
  T,
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  className,
  ...rawProps
}: AutocompleteFieldProps<T, Multiple, DisabledClearable, FreeSolo>) {
  const paletteUtils = usePaletteUtils();
  const [formikProps, meta, fieldHelpers] = useField<
    AutocompleteValue<T, Multiple, DisabledClearable, FreeSolo>
  >({
    name: rawProps.id,
    value: rawProps.value as string | undefined,
    multiple: rawProps.multiple,
  });

  const props: AutocompleteFieldProps<
    T,
    Multiple,
    DisabledClearable,
    FreeSolo
  > = {
    validationState: meta.touched && meta.error ? 'error' : 'default',
    validationMessage: meta.touched && meta.error ? meta.error : undefined,
    ...rawProps,
  };

  return (
    <Field
      sx={{
        '& [disabled]': {
          ...paletteUtils.disabledPlaceholderStyles,
          boxShadow: 'none',
        },
      }}
      {...props}
      className={className}
      inputId={props.id}
      dataTest={getPrefixedTestId(TestIdPrefix.autocompleteField, props.id)}
    >
      <Autocomplete
        getOptionLabel={getGenericOptionLabel}
        {...formikProps}
        // `formikProps.multiple` is of a different type than `props.multiple`
        multiple={props.multiple}
        onChange={(evt, value) => {
          fieldHelpers.setValue(value);
        }}
        {...filterFieldInputProps(props)}
        {...getInputProps(props)}
        size={props.size}
      />
    </Field>
  );
}
