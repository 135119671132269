import { z } from 'zod';

export const CompanyChangeRequestSchema = z.object({
  // START Company properties
  name: z.string(),
  url: z.string().nullish(),
  isin: z.string().nullish(),
  lei: z.string().nullish(),
  tickerSymbol: z.string().nullish(),
  bea: z.string().nullish(),
  country: z.string().nullish(),
  sAndPId: z.string().nullish(),
  // END Company properties

  // For company matching, we would need to
  // create a new org_company to match the newly created company
  // with the customer's supplier. This allows us to keep track
  // of what the supplier's name was.
  orgCompanyName: z.string().optional(),
  // For matching company to corporate asset
  assetCorporateId: z.string().optional(),
});

export interface CompanyChangeRequestPayload
  extends z.infer<typeof CompanyChangeRequestSchema> {}
