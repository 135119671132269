// The field names in this file are obnoxiously short. I'm sorry! It's because
// we have one of these per row of a footprint, and brevity is extra helpful in
// that context.
//
// To make up for that, every name is vaguely descriptive and has a unique
// meaning:
//   t:  type, and in particular TraceASTType. Forms a tagged union for the
//       various AST node types. Present on every AST node
//   v:  value. The numeric value of this node. Present on every AST node
//   u:  unit. A string (not necessarily in any canonical representation) that
//       parses to the unit corresponding to `v`
//   o:  operation, which is typically a enum represented by a small number
//   e:  expression. Used when an AST node has a single subexpression
//   l:  left expression. Used when an AST node has two subexpressions
//   r:  right expression. Used when an AST node has two subexpressions
//   n:  name. For `TAnnotation`'s `TVariable`, a variable name (e.g., an EM
//       variable).
//   s:  source. For `TReference`, the slug of the reference data.
//   c:  column. For `TInput`, the BART column used. For `TReference`, the
//       column from the reference data.
//   i:  For `TReference`, the stable row ID of the row that was looked up.
//   d:  description. For `TConversion`, an arbitrary human-readable string that
//       describes the conversion.
//   k:  kind. For `TAnnotation`, a number representing the kind of annotation.
//       Today we only have one kind, `TVariable`, for emissions model
//       variables.

import { z } from 'zod';

// These enum values are part of the interface of these enums, and are written
// down in data artifacts. They may *never* be changed, including using the
// same value to refer to a different concept (e.g., adding a field). If you
// need to modify what any of these enum values means, you *must* make a new
// number.
export enum TraceASTType {
  TNumber = 1,
  TUnary = 2,
  TBinary = 3,
  TConversion = 4,
  TInput = 5,
  TReference = 6,
  TAnnotation = 7,
}

export enum TraceASTUnaryOp {
  TNegative = 1,
}

export enum TraceASTBinaryOp {
  TPlus = 1,
  TMinus = 2,
  TMultiply = 3,
  TDivide = 4,
}

export enum TraceASTAnnotationKind {
  TVariable = 1,
}

export type TraceASTDef<
  T extends TraceASTType,
  V extends Record<string, unknown>,
> = {
  t: T;
  v: number;
  u: string | null;
} & V;

export type TraceASTNumber = TraceASTDef<TraceASTType.TNumber, {}>;
export type TraceASTUnary = TraceASTDef<
  TraceASTType.TUnary,
  {
    o: TraceASTUnaryOp;
    e: TraceASTExpr;
  }
>;
export type TraceASTBinary = TraceASTDef<
  TraceASTType.TBinary,
  {
    o: TraceASTBinaryOp;
    l: TraceASTExpr;
    r: TraceASTExpr;
  }
>;
export type TraceASTConversion = TraceASTDef<
  TraceASTType.TConversion,
  {
    e: TraceASTExpr;
    d: string;
  }
>;
export type TraceASTInput = TraceASTDef<
  TraceASTType.TInput,
  {
    c: string;
  }
>;
export type TraceASTReference = TraceASTDef<
  TraceASTType.TReference,
  {
    s: string;
    c: string;
    i: string;
  }
>;
export type TraceASTAnnotation = TraceASTDef<
  TraceASTType.TAnnotation,
  {
    k: TraceASTAnnotationKind;
    n: string;
    e: TraceASTExpr;
  }
>;

export type TraceASTExpr =
  | TraceASTNumber
  | TraceASTUnary
  | TraceASTBinary
  | TraceASTConversion
  | TraceASTInput
  | TraceASTReference
  | TraceASTAnnotation;

const base = z.object({
  v: z.number().finite(),
  u: z.string().nullable(),
});

// There is a circular dependency in the zod schema, which we use z.lazy to deal
// with. These eslint warnings aren't helpful!
/* eslint-disable @typescript-eslint/no-use-before-define */

export const traceASTNumberSchema = base
  .merge(
    z.object({
      t: z.literal(TraceASTType.TNumber),
    })
  )
  .strict();
export const traceASTUnarySchema = base
  .merge(
    z.object({
      t: z.literal(TraceASTType.TUnary),
      o: z.nativeEnum(TraceASTUnaryOp),
      e: z.lazy(() => traceASTExprSchema),
    })
  )
  .strict();
export const traceASTBinarySchema = base
  .merge(
    z.object({
      t: z.literal(TraceASTType.TBinary),
      o: z.nativeEnum(TraceASTBinaryOp),
      l: z.lazy(() => traceASTExprSchema),
      r: z.lazy(() => traceASTExprSchema),
    })
  )
  .strict();
export const traceASTConversionSchema = base
  .merge(
    z.object({
      t: z.literal(TraceASTType.TConversion),
      e: z.lazy(() => traceASTExprSchema),
      d: z.string(),
    })
  )
  .strict();
export const traceASTInputSchema = base
  .merge(
    z.object({
      t: z.literal(TraceASTType.TInput),
      c: z.string(),
    })
  )
  .strict();
export const traceASTReferenceSchema = base
  .merge(
    z.object({
      t: z.literal(TraceASTType.TReference),
      s: z.string(),
      c: z.string(),
      i: z.string(),
    })
  )
  .strict();
export const traceASTAnnotationSchema = base
  .merge(
    z.object({
      t: z.literal(TraceASTType.TAnnotation),
      v: z.number().finite(),
      u: z.string().nullable(),
      k: z.nativeEnum(TraceASTAnnotationKind),
      n: z.string(),
      e: z.lazy(() => traceASTExprSchema),
    })
  )
  .strict();

export const traceASTExprSchema: z.ZodType<TraceASTExpr> = z.discriminatedUnion(
  't',
  [
    traceASTNumberSchema,
    traceASTUnarySchema,
    traceASTBinarySchema,
    traceASTConversionSchema,
    traceASTInputSchema,
    traceASTReferenceSchema,
    traceASTAnnotationSchema,
  ]
);
