import * as Yup from 'yup';
import has from 'lodash/has';
import {
  GQCompanyEmissionsInterfaceV2,
  GQCompanyEmissionsUnits,
} from '../generated/graphql';
import sumBy from 'lodash/sumBy';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import { z } from 'zod';
export const SUPPORTED_SUBDIVISION_COUNTRIES = ['US', 'CA'];
export const SUBDIVISION_OPTIONAL_COUNTRIES = ['US'];

export const PleeResinType = {
  HDPE: 'HDPE - High-density polyethylene',
  LLDPE: 'LLDPE - Linear low-density polyethylene',
  LDPE: 'LDPE - Low-density polyethylene',
  PP: 'PP - Polypropylene',
  PVC: 'PVC - Polyvinyl chloride',
  PS: 'PS - Polystyrene',
  PET: 'PET - Polyethylene terephthalate',
  PLA: 'PLA - Polylactic acid',
} as const;

type PleeLocationPlasticResin = {
  resinProportions: Partial<Record<keyof typeof PleeResinType, number>>;
};

type PleeLocationRecycleRate = {
  recycleRate: number;
};

export const PleeDairyManureManagementSystemType = {
  UNCOVERED_ANAEROBIC_LAGOON: 'Uncovered anaerobic lagoon',
  LIQUID_SLURRY: 'Liquid slurry',
  SOLID_STORAGE: 'Solid storage',
  DRY_LOT: 'Dry lot',
  PASTURE_PADDOCK: 'Pasture paddock',
  DAILY_SPREAD: 'Daily spread',
  ANAEROBIC_DIGESTION_BIOGAS: 'Anaerobic digestion-biogas',
  BURNED_FOR_FUEL: 'Burned for fuel',
  SOLID_LIQUID_SEPARATION: 'Solid/liquid separation',
  COMPOSTING: 'Composting',
  OTHER: 'Other',
} as const;

export const PleeDairyFeedRegenerativePracticeType = {
  REDUCE_TILLAGE: 'Reduce/eliminate tillage',
  DIVERSIFIED_CROP_ROTATION: 'Diversified crop rotations',
  COVER_CROPS_GREEN_MANURE: 'Cover crops/green manure',
  PERENNIAL_PASTURE: 'Perennial pasture',
  EDGE_OF_FIELD:
    'Edge of field, e.g. prairie strips, riparian buffers, pollinator plantings, hedgerows',
  GRAZING_STUBBLE: 'Grazing stubble ',
  PRECISION_AGRICULTURE_MANAGEMENT: 'Precision agriculture/nutrient management',
  OTHER: 'Other',
} as const;

const PleeDairyFeedAdditiveShared = {
  AGOLIN: 'Agolin',
  RUMENSEN_MONENSIN: 'Rumensen/Monensin',
  MOOTRAL: 'Mootral',
} as const;

export const PleeDairyFeedAdditiveType = {
  ...PleeDairyFeedAdditiveShared,
  OTHER: 'Other',
} as const;

export const PleeDairyFeedAdditiveAspirationalType = {
  ...PleeDairyFeedAdditiveShared,
  SEAWEED: 'Seaweed',
  BOVARE_3NOP: 'Bovaer/3NOP',
  SUNFLOWER_CANOLA_OIL: 'Sunflower/canola oil',
  COTTONSEED: 'Cottonseed',
  OTHER: 'Other',
};

export const PleePorkManureManagementSystemType = {
  UNCOVERED_ANAEROBIC_LAGOON: 'Uncovered anaerobic lagoon',
  LIQUID_SLURRY: 'Liquid slurry',
  SOLID_STORAGE: 'Solid storage',
  DRY_LOT: 'Dry lot',
  PIT_MORE_THAN_1_MONTH: 'Pit, >1 month',
  PIT_LESS_THAN_1_MONTH: 'Pit, <1 month',
  PASTURE_PADDOCK: 'Pasture paddock',
  DAILY_SPREAD: 'Daily spread',
  ANAEROBIC_DIGESTION_BIOGAS: 'Anaerobic digestion-biogas',
  BURNED_FOR_FUEL: 'Burned for fuel',
} as const;

export const PleePoultryManureManagementSystemType = {
  UNCOVERED_ANAEROBIC_LAGOON: 'Uncovered anaerobic lagoon',
  LIQUID_SLURRY: 'Liquid slurry',
  SOLID_STORAGE: 'Solid storage',
  DRY_LOT: 'Dry lot',
  PASTURE_PADDOCK: 'Pasture paddock',
  PIT_MORE_THAN_1_MONTH: 'Pit, >1 month',
  DAILY_SPREAD: 'Daily spread',
  ANAEROBIC_DIGESTION_BIOGAS: 'Anaerobic digestion-biogas',
  BURNED_FOR_FUEL: 'Burned for fuel',
  OTHER_MANURE_WITH_LITTER: 'Other (poultry manure with litter)',
} as const;

export const PleeBeefManureManagementSystemType = {
  UNCOVERED_ANAEROBIC_LAGOON: 'Uncovered anaerobic lagoon',
  LIQUID_SLURRY: 'Liquid slurry',
  SOLID_STORAGE: 'Solid storage',
  DRY_LOT: 'Dry lot',
  PASTURE_PADDOCK: 'Pasture paddock',
  DAILY_SPREAD: 'Daily spread',
  ANAEROBIC_DIGESTION_BIOGAS: 'Anaerobic digestion-biogas',
  BURNED_FOR_FUEL: 'Burned for fuel',
} as const;

export const PleeFertilizerType = {
  SYNTHETIC: 'Synthetic fertilizer',
  MANURE: 'Manure',
  COMPOST: 'Compost',
  OTHER: 'Other',
} as const;

export type PleeLocationDairyFarm = {
  avgAnnualMilkProduction?: number;
  manureManagementBreakdownPct?: Partial<
    Record<keyof typeof PleeDairyManureManagementSystemType, number>
  >;
  feedRegenerativePracticeBreakdownPct: Partial<
    Record<keyof typeof PleeDairyFeedRegenerativePracticeType, number>
  >;
  feedAdditiveBreakdownPct: Partial<
    Record<keyof typeof PleeDairyFeedAdditiveType, number>
  >;
  feedAdditiveAspirationalUse: Partial<
    Record<keyof typeof PleeDairyFeedAdditiveAspirationalType, number>
  >;
  soyFeedPercentage?: number;
  soySourceLocations?: Array<
    Partial<{
      country: string;
      countrySubdivision: string;
      soyPercentage: number;
    }>
  >;
};

type PleeLocationPorkFarm = {
  manureManagementBreakdownPct?: Partial<
    Record<keyof typeof PleeDairyManureManagementSystemType, number>
  >;
};

export const PoultryType = {
  MEAT: 'meat',
  EGGS: 'eggs',
} as const;

type PleeLocationPoultryFarm = {
  productType?: string;
  manureManagementBreakdownPct?: Partial<
    Record<keyof typeof PleePoultryManureManagementSystemType, number>
  >;
};

export const BeefFinishType = {
  GRASS: 'grass',
  GRAIN: 'grain',
} as const;

type PleeLocationBeefFarm = {
  beefFinish?: string;
  annualBeefProduction?: number;
  manureManagementBreakdownPct?: Partial<
    Record<keyof typeof PleeBeefManureManagementSystemType, number>
  >;
};

export const GrainType = {
  WHEAT: 'Wheat',
  OATS: 'Oats',
  OTHER: 'Other',
} as const;

export const OilSeedType = {
  OIL_PALM: 'Oil palm',
  OTHER: 'Other',
} as const;

export const SugarConfectioneryType = {
  COCOA: 'Cocoa',
  OTHER: 'Other',
} as const;

export const FruitAndVegetableType = {
  CASHEWS: 'Cashews',
  OTHER: 'Other',
};

export const PleeBeaToProductType = {
  naics_311300: SugarConfectioneryType,
  naics_311224: OilSeedType,
  naics_1111b0: GrainType,
  naics_111300: FruitAndVegetableType,
  naics_311210: GrainType,
  naics_311230: GrainType,
};

type PleeLocationCropFarm = {
  productType?: string;
  fertilizerNitrogen?: number;
  organicProportion?: number;
  fertilizerTypeBreakdownPct?: Partial<
    Record<keyof typeof PleeFertilizerType, number>
  >;
};

type PleeLocationOnsiteRenewableProportion = {
  onsiteRenewableProportion?: number;
};

type PleeLocationFarm = {
  poultryFarm?: PleeLocationPoultryFarm;
  porkFarm?: PleeLocationPorkFarm;
  dairyFarm?: PleeLocationDairyFarm;
  cropFarm?: PleeLocationCropFarm;
  beefFarm?: PleeLocationBeefFarm;
};

const CountrySubdivisionSchema = Yup.string().when('country', {
  is: (country: string) =>
    SUPPORTED_SUBDIVISION_COUNTRIES.includes(country) &&
    !SUBDIVISION_OPTIONAL_COUNTRIES.includes(country),
  then: (schema) => schema.required(),
  otherwise: (schema) => schema.optional(),
});

const BreakdownPctSchema = Yup.lazy((obj) =>
  Yup.object(mapValues(obj, () => Yup.number().required()))
    .optional()
    .test('test-sum-to-100', 'Must sum up to 100', (breakdown) =>
      isEmpty(breakdown)
        ? true
        : sumBy(Object.values(breakdown), (el) => el ?? 0) === 100
    )
);

const PleeCountrySchema = Yup.object({
  country: Yup.string().required(),
  countrySubdivision: CountrySubdivisionSchema,
});

const DairyFarmSchema = Yup.object({
  avgAnnualMilkProduction: Yup.number().optional(),
  manureManagementBreakdownPct: BreakdownPctSchema,
  feedRegenerativePracticeBreakdownPct: BreakdownPctSchema,
  feedAdditiveBreakdownPct: BreakdownPctSchema,
  feedAdditiveAspirationalUse: Yup.lazy((obj) =>
    Yup.object(mapValues(obj, () => Yup.number().required())).optional()
  ),
  soyFeedPercentage: Yup.number().min(0).max(100),
  soySourceLocations: Yup.array(
    Yup.object({
      ...PleeCountrySchema.fields,
      soyPercentage: Yup.number(),
    })
  )
    .optional()
    .test('test-sum-to-100', 'Must sum up to 100', (soySourceLocations) =>
      isEmpty(soySourceLocations)
        ? true
        : sumBy(soySourceLocations, (loc) => loc.soyPercentage ?? 0) === 100
    ),
});

export const PleeLocationSchema = Yup.object({
  ...PleeCountrySchema.fields,
  productionProportion: Yup.number().optional(),
  resinProportions: BreakdownPctSchema,
  poultryFarm: Yup.object({
    productType: Yup.string().optional(),
    manureManagementBreakdownPct: BreakdownPctSchema,
  }).optional(),
  porkFarm: Yup.object({
    manureManagementBreakdownPct: BreakdownPctSchema,
  }).optional(),
  dairyFarm: DairyFarmSchema.optional(),
  cropFarm: Yup.object({
    productType: Yup.string().optional(),
    fertilizerNitrogen: Yup.number().optional(),
    organicProportion: Yup.number().optional(),
    fertilizerTypeBreakdownPct: BreakdownPctSchema,
  }).optional(),
  beefFarm: Yup.object({
    manureManagementBreakdownPct: BreakdownPctSchema,
  }).optional(),
}).required();

export const PleeLocationBaseSchema = PleeLocationSchema.pick([
  'country',
  'countrySubdivision',
  'productionProportion',
]);

export type PleeLocation = Partial<
  Yup.InferType<typeof PleeLocationBaseSchema>
> &
  Partial<PleeLocationPlasticResin> &
  Partial<PleeLocationRecycleRate> &
  Partial<PleeLocationFarm> & {
    onsiteRenewableProportion?: PleeLocationOnsiteRenewableProportion;
  };

export type PleeLocationTopic =
  | 'locationBase'
  | 'plasticResin'
  | 'recycleRate'
  | 'dairyFarm'
  | 'onsiteRenewableProportion'
  | 'cropFarm'
  | 'porkFarm'
  | 'poultryFarm'
  | 'beefFarm'
  | 'fertilizerTypeBreakdownPct';

export type PleeTopic =
  | 'productionLocations'
  | 'countryOfOperation'
  | 'truckTransport'
  | 'railTransport'
  | 'warehousing'
  | 'onsiteRenewableProportion';

export type pleeCategory =
  | 'dairy'
  | 'cheeseButter'
  | 'paper'
  | 'plastic'
  | 'glass'
  | 'metal'
  | 'railTransport'
  | 'truckTransport'
  | 'generic'
  | 'grain'
  | 'fatsAndOils'
  | 'sugarAndConfectionery'
  | 'fruits'
  | 'flourAndCereal'
  | 'beef'
  | 'poultry'
  | 'pork'
  | 'warehousing';

export const pleeCategories: Record<string, pleeCategory> = {
  // Paperboard mills
  naics_322130: 'paper',
  // Paperboard container manufacturing
  naics_322210: 'paper',
  // Paper Bag and Coated and Treated Paper Manufacturing
  naics_322220: 'paper',
  // All other converted paper product manufacturing
  naics_322299: 'paper',
  // Plastics packaging materials and unlaminated film and sheet manufacturing
  naics_326110: 'plastic',
  // Glass and glass product manufacturing
  naics_327200: 'glass',
  // Metal cans
  naics_332430: 'metal',
  // Primary aluminum
  naics_331313: 'metal',
  // Secondary aluminum
  naics_33131b: 'metal',
  // Rail transport
  naics_482000: 'railTransport',
  // Truck transport
  naics_484000: 'truckTransport',
  // Warehousing
  naics_493000: 'warehousing',
  naics_112120: 'dairy',
  // Fluid milk and butter
  naics_31151a: 'cheeseButter',
  // Cheese
  naics_311513: 'cheeseButter',
  // Dry, condensed, and evaporated dairy product manufacturing
  naics_311514: 'cheeseButter',
  // Fresh wheat, corn, rice, and other grains
  naics_1111b0: 'grain',
  // Refined vegetable, olive, and seed oils
  naics_311224: 'fatsAndOils',
  // Sugar, candy, and chocolate
  naics_311300: 'sugarAndConfectionery',
  // TODO: Support vegetables
  // naics_111200: 'vegetables',
  // Fresh fruits and tree nuts
  naics_111300: 'fruits',
  // Flours and malts
  naics_311210: 'flourAndCereal',
  // Breakfast cereals
  naics_311230: 'flourAndCereal',
  // Animal farms and aquaculture ponds (except cattle and poultry)
  naics_112a00: 'pork',
  // Poultry farms
  naics_112300: 'poultry',
  // Cattle ranches and feedlots
  naics_1121a0: 'beef',
};

// these topics are used *within* a location question
// in order for these topic questions to show up, 'productionLocations' needs to be listed in `pleeTopics`
export const pleeLocationTopics: Record<
  pleeCategory,
  Array<PleeLocationTopic>
> = {
  dairy: ['locationBase', 'dairyFarm', 'onsiteRenewableProportion'],
  cheeseButter: ['locationBase', 'dairyFarm'],
  paper: ['locationBase', 'recycleRate'],
  plastic: ['locationBase', 'plasticResin', 'recycleRate'],
  glass: ['locationBase', 'recycleRate'],
  metal: ['locationBase', 'recycleRate'],
  railTransport: [],
  truckTransport: [],
  warehousing: [],
  generic: [],
  grain: [
    'locationBase',
    'cropFarm',
    'onsiteRenewableProportion',
    'fertilizerTypeBreakdownPct',
  ],
  fatsAndOils: ['locationBase', 'cropFarm', 'fertilizerTypeBreakdownPct'],
  sugarAndConfectionery: ['locationBase', 'cropFarm'],
  fruits: [
    'locationBase',
    'cropFarm',
    'fertilizerTypeBreakdownPct',
    'onsiteRenewableProportion',
  ],
  flourAndCereal: ['locationBase', 'cropFarm', 'fertilizerTypeBreakdownPct'],
  pork: ['locationBase', 'porkFarm', 'onsiteRenewableProportion'],
  poultry: ['locationBase', 'poultryFarm', 'onsiteRenewableProportion'],
  beef: ['locationBase', 'beefFarm', 'onsiteRenewableProportion'],
};

export const pleeTopics: Record<pleeCategory, Array<PleeTopic>> = {
  dairy: ['productionLocations'],
  cheeseButter: [
    'countryOfOperation',
    'onsiteRenewableProportion',
    'productionLocations',
  ],
  paper: ['productionLocations'],
  plastic: ['productionLocations'],
  glass: ['productionLocations'],
  metal: ['productionLocations'],
  railTransport: ['countryOfOperation', 'railTransport'],
  truckTransport: ['countryOfOperation', 'truckTransport'],
  warehousing: ['countryOfOperation', 'warehousing'],
  generic: ['countryOfOperation', 'onsiteRenewableProportion'],
  grain: ['productionLocations'],
  fatsAndOils: [
    'countryOfOperation',
    'onsiteRenewableProportion',
    'productionLocations',
  ],
  sugarAndConfectionery: [
    'countryOfOperation',
    'onsiteRenewableProportion',
    'productionLocations',
  ],
  fruits: ['productionLocations'],
  flourAndCereal: [
    'countryOfOperation',
    'onsiteRenewableProportion',
    'productionLocations',
  ],
  pork: ['productionLocations'],
  poultry: ['productionLocations'],
  beef: ['productionLocations'],
};

export const getDefaultPleeLocation = (
  beaCode: string
): PleeLocation | null => {
  if (
    has(pleeCategories, beaCode) &&
    pleeTopics[pleeCategories[beaCode]].includes('productionLocations')
  ) {
    return {
      country: undefined,
      countrySubdivision: undefined,
      productionProportion: 100,
    };
  }
  return null;
};

const PleeRailTransportationSchema = Yup.object({
  avgFuelEconomy: Yup.number().min(1),
});

export const PLEE_TRUCK_TYPES = {
  LIGHT_GASOLINE: 'a. Light trucks, gasoline',
  LIGHT_DIESEL: 'b. Light trucks, diesel',
  LIGHT_ELECTRIC: 'c. Light trucks, electric',
  MEDIUM_GASOLINE: 'd. Medium trucks (class 3-6) single unit, gasoline',
  MEDIUM_DIESEL: 'e. Medium trucks (class 3-6) single unit, diesel',
  MEDIUM_ELECTRIC: 'f. Medium trucks (class 3-6) single unit, electric',
  HEAVY_GASOLINE: 'g. Heavy trucks (class 7-8) combination, gasoline',
  HEAVY_DIESEL: 'h. Heavy trucks (class 7-8) combination, diesel',
  HEAVY_ELECTRIC: 'i. Heavy trucks (class 7-8) combination, electric',
};

export const PLEE_TRUCK_ALTERNATIVE_FUEL_TYPES = {
  LNG: 'Liquified natural gas (LNG)',
  CNG: 'Compressed natural gas (CNG)',
  BIODIESEL: 'Biodiesel',
};

const PleeTruckFleetType = Yup.object({
  truckType: Yup.string().oneOf(Object.keys(PLEE_TRUCK_TYPES)).defined(),
  fuelEconomy: Yup.number().min(0),
  percentage: Yup.number().min(0).max(100),
});

const PleeTruckAlternativeFuel = Yup.object({
  fuel: Yup.string().oneOf(Object.keys(PLEE_TRUCK_ALTERNATIVE_FUEL_TYPES)),
  percentage: Yup.number().min(0).max(100),
});

const PleeTruckTransportationSchema = Yup.object({
  fleetBreakdown: Yup.array(PleeTruckFleetType).min(1),
  alternativeFuels: Yup.array(PleeTruckAlternativeFuel),
  emptyMilesPercentage: Yup.number().min(0).max(100).optional(),
});

const PleeWarehousingSchema = Yup.object({
  forkTrucks: Yup.string().optional(),
});

const PleeGenericSchema = Yup.object({
  onsiteRenewableProportion: Yup.number().min(0).max(100).optional(),
});

export const PleeInputSchema = Yup.object({
  country: Yup.string().required(), // should be required for any PLEE category: https://watershedclimate.slack.com/archives/C058GVBLGE6/p1699575076016899
  truckTransportation:
    PleeTruckTransportationSchema.optional().default(undefined),
  railTransportation:
    PleeRailTransportationSchema.optional().default(undefined),
  warehousing: PleeWarehousingSchema.optional().default(undefined),
  generic: PleeGenericSchema.optional().default(undefined),
});

export type PleeInputType = Yup.InferType<typeof PleeInputSchema>;

export const getDefaultPleeInput = (beaCode: string): PleeInputType | null => {
  const topics = pleeTopics[pleeCategories[beaCode] ?? 'generic'];
  if (topics.length === 1 && topics[0] === 'productionLocations') {
    return null;
  }
  return {
    country: '', // should be required for any PLEE category. We default to an empty string which currently does not break the Python code if passed in, although the frontend should require a country to be selected
    railTransportation: topics.includes('railTransport')
      ? { avgFuelEconomy: 0 }
      : undefined,
    truckTransportation: topics.includes('truckTransport')
      ? {
          fleetBreakdown: [],
          alternativeFuels: [],
          emptyMilesPercentage: undefined,
        }
      : undefined,
    warehousing: {
      forkTrucks: undefined,
    },
    generic: topics.includes('onsiteRenewableProportion')
      ? { onsiteRenewableProportion: undefined }
      : undefined,
  };
};

const CompanyEmissionsInterfaceSchema = z.object({
  revenueCurrency: z.string().nullable().default(null),
  scope1: z.number().nullable().default(null),
  scope2: z.number().nullable().default(null),
  scope2Location: z.number().nullable().default(null),
  scope2Market: z.number().nullable().default(null),
  scope3: z.number().nullable().default(null),
  scope301: z.number().nullable().default(null),
  scope302: z.number().nullable().default(null),
  scope303: z.number().nullable().default(null),
  scope304: z.number().nullable().default(null),
  scope305: z.number().nullable().default(null),
  scope306: z.number().nullable().default(null),
  scope307: z.number().nullable().default(null),
  scope308: z.number().nullable().default(null),
  scope309: z.number().nullable().default(null),
  scope310: z.number().nullable().default(null),
  scope311: z.number().nullable().default(null),
  scope312: z.number().nullable().default(null),
  scope313: z.number().nullable().default(null),
  scope314: z.number().nullable().default(null),
  scope315: z.number().nullable().default(null),
  scope316: z.number().nullable().default(null),
  scope317: z.number().nullable().default(null),
  units: z.nativeEnum(GQCompanyEmissionsUnits),
});

export const PleeEstimateOutputSchema = z
  .object({
    bea_emissions: z.record(z.string(), CompanyEmissionsInterfaceSchema),
  })
  .nullable();

// We explicitly did not define it as a zod type because
// we want typecheck to verify PleeEstimateOutputSchema.parse() matches
// the expected shape from GraphQL.
export type PleeEstimateOutput = {
  bea_emissions: Record<string, GQCompanyEmissionsInterfaceV2>;
};
