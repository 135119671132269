import React, { useContext, useEffect, useState } from 'react';
import { FeatureFlagsMap } from '../utils/FeatureFlag';

export type SessionOverridesData = {
  footprintVersion: string | null;
  featureFlagOverrides: FeatureFlagsMap;
};

type SessionOverrides = {
  data: SessionOverridesData;
  updateData(data: SessionOverridesData): void;
};

const defaults: SessionOverrides = {
  data: {
    footprintVersion: null,
    featureFlagOverrides: new Map(),
  },
  updateData: () => {},
};

export const SessionOverrideContext = React.createContext(defaults);

export function useSessionOverrideContext() {
  return useContext(SessionOverrideContext);
}

const DataMappings: Record<
  keyof SessionOverridesData,
  {
    parser: (paramValue: string | null, sessionValue: string | null) => any;
    serializer: (value: any) => string | null;
  }
> = {
  footprintVersion: {
    parser: parseString,
    serializer: serializeString,
  },
  featureFlagOverrides: {
    parser: (_paramValue, sessionValue) =>
      sessionValue ? parseMap(sessionValue) : null,
    serializer: serializeMap,
  },
};

function parseString(paramValue: string | null, sessionValue: string | null) {
  return paramValue || sessionValue;
}

// Leaving these around for when we next need them.
//
// function parseBoolean(paramValue: string | null, sessionValue: string | null) {
//   if (paramValue === 'true') {
//     return true;
//   } else if (paramValue === 'false') {
//     return false;
//   } else if (sessionValue === 'true') {
//     return true;
//   } else if (sessionValue === 'false') {
//     return false;
//   }
//   return null;
// }
//
// function serializeBoolean(value: boolean) {
//   return String(value);
// }

function serializeString(value: string | null) {
  return value;
}

function serializeMap(map: Map<any, any>) {
  return JSON.stringify(Array.from(map.entries()));
}

function parseMap<K, V>(value: string): Map<K, V> {
  return new Map(JSON.parse(value));
}

export function serializeSessionOverridesData(
  data: SessionOverridesData
): Record<string, string> {
  const result: Record<string, string> = {};
  (Object.keys(data) as Array<keyof SessionOverridesData>).forEach((key) => {
    const value = DataMappings[key].serializer(data[key]);
    if (value !== null) {
      result[key] = value;
    }
  });
  return result;
}

export function SessionOverrideContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [sessionOverridesData, setSessionOverridesData] = useState(
    defaults.data
  );

  const setWindowSessionStorage = (data: SessionOverridesData) => {
    const serializedData = serializeSessionOverridesData(data);
    Object.keys(data).forEach((key) => {
      if (key in serializedData) {
        window.sessionStorage.setItem(key, serializedData[key]);
      } else {
        window.sessionStorage.removeItem(key);
      }
    });
  };

  useEffect(() => {
    const overrides = { ...defaults.data };
    const searchParams = new URLSearchParams(window.location.search);
    Object.keys(overrides).forEach((key) => {
      const paramValue = searchParams.get(key);
      const sessionValue = window.sessionStorage.getItem(key);
      const lookup = key as keyof typeof DataMappings;
      const value = DataMappings[lookup].parser(paramValue, sessionValue);
      overrides[lookup] = value || overrides[lookup];
      if (paramValue !== null) {
        const newUrl = new URL(window.location.toString());
        newUrl.searchParams.delete(key);
        window.history.replaceState(
          window.history.state,
          '',
          newUrl.toString()
        );
      }
    });

    setSessionOverridesData(overrides);
    setWindowSessionStorage(overrides);
  }, [setSessionOverridesData]);

  const value = {
    data: sessionOverridesData,
    updateData: (data: SessionOverridesData) => {
      setSessionOverridesData(data);
      setWindowSessionStorage(data);
    },
  };
  return (
    <SessionOverrideContext.Provider value={value}>
      {children}
    </SessionOverrideContext.Provider>
  );
}
