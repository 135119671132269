import { UseQueryState } from 'urql';

/**
 * Possible spirtual successor to isFetchingOrStale. Rather than
 * looking at whether data is stale, we look at whether the data
 * is partial. This is useful for cases where we want to show
 * a loading state when we have partial data, but continue to
 * show the data when it's stale.
 */
export default function isFetchingOrPartial<T extends object>(
  result: UseQueryState<T>
) {
  return (
    result.fetching ||
    result.operation?.context.meta?.cacheOutcome === 'partial'
  );
}
