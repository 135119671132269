import Icon, { IconProps } from '../Icon';
const SimulateIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M21 20.19c0-.55.45-1 1-1s1 .45 1 1V22c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1V2c0-.55.45-1 1-1h1.81c.55 0 1 .45 1 1s-.45 1-1 1H3v3h.81c.55 0 1 .45 1 1s-.45 1-1 1H3v3.01h.81c.55 0 1 .45 1 1s-.45 1-1 1H3v3h.81c.55 0 1 .45 1 1s-.45 1-1 1H3V21h3v-.81c0-.55.45-1 1-1s1 .45 1 1V21h3v-.81c0-.55.45-1 1-1s1 .45 1 1V21h3v-.81c0-.55.45-1 1-1s1 .45 1 1V21h3v-.81ZM6.99 18.02a.998.998 0 0 1-.89-1.45l5-10.01c.14-.29.41-.49.73-.54.32-.05.64.05.87.28l4.26 4.28 4.3-4.65c.37-.41 1-.43 1.41-.06.41.37.44 1 .06 1.41l-5 5.41c-.18.2-.44.31-.71.32-.27 0-.54-.1-.73-.29L12.27 8.7l-4.38 8.77c-.18.35-.53.55-.9.55Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SimulateIcon;
