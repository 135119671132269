import {
  Box,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  alpha,
} from '@mui/material';
import Icon from '@watershed/icons/Icon';
import { mixinSx } from '@watershed/style/styleUtils';

import React from 'react';

export type SelectableTileProps = React.PropsWithChildren<
  {
    label: React.ReactNode;
    sublabel?: React.ReactNode;
    adornment?: React.ReactNode;
    selected?: boolean;
    disabled?: boolean;
    layout?: 'horizontal' | 'vertical';
    inputAffordance?: React.ReactNode;
    wrapLabel?: boolean;
    onClick?: () => void;
    tooltip?: React.ReactNode;
    tooltipProps?: Partial<TooltipProps>;
    tabIndex?: number;
    sx?: SxProps<Theme>;
    graphicPlacement?: 'start' | 'end';
  } & (
    | { Icon?: typeof Icon; image?: never }
    | {
        Icon?: never;
        image?: React.ReactNode;
      }
  )
>;

export default function SelectableTile({
  label,
  sublabel,
  adornment,
  selected,
  disabled,
  layout = 'horizontal',
  inputAffordance,
  wrapLabel,
  onClick,
  tooltip,
  tooltipProps,
  Icon,
  image,
  tabIndex,
  graphicPlacement = 'start',
  children,
  sx,
}: SelectableTileProps) {
  return (
    <Tooltip
      title={tooltip ?? ''}
      {...tooltipProps}
      placement={tooltipProps?.placement ?? 'right'} // Default placement should be to the right
    >
      <Box
        tabIndex={tabIndex ?? 0}
        aria-disabled={disabled}
        aria-checked={selected}
        sx={mixinSx(
          (theme) => ({
            display: 'flex',
            flexGrow: 1,
            flexDirection: layout === 'horizontal' ? 'row' : 'column',
            gap:
              layout === 'horizontal'
                ? graphicPlacement === 'start'
                  ? Icon
                    ? 1
                    : image
                      ? 1.5
                      : 1.5
                  : 1.5
                : Icon || image
                  ? 0.5
                  : 0,
            px: 2,
            py: 1.5,
            borderRadius: '6px',
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            ':focus-visible': {
              outlineColor: theme.palette.primary.main,
              outlineOffset: '2px',
            },
            '&[aria-checked="true"]': {
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.cobalt05,
            },
            '&[aria-disabled="true"]': {
              cursor: 'not-allowed',
              '& .MuiTypography-root': {
                color: theme.palette.text.disabled,
              },
              svg: {
                fill: theme.palette.text.disabled,
              },
              img: {
                filter: 'grayscale(100%)',
              },
            },
            '&:hover:not([aria-disabled="true"])': {
              cursor: 'pointer',
              backgroundColor: theme.palette.panel,
            },
            '&:hover:is([aria-checked="true"])': {
              backgroundColor: alpha(theme.palette.cobalt10, 0.6),
            },
          }),
          sx
        )}
        onClick={() => {
          if (!disabled) onClick?.();
        }}
        onKeyDown={(event) => {
          if (!disabled && event.key === ' ') {
            (event.target as HTMLDivElement | undefined)
              ?.querySelector('input')
              ?.click();
          }
        }}
      >
        <Box display="flex">
          {inputAffordance && (
            <Box sx={{ marginTop: '-1px' }}>{inputAffordance}</Box>
          )}
          {graphicPlacement === 'start' ? (
            Icon ? (
              <Box
                sx={{
                  py: layout === 'horizontal' ? 0.25 : 0,
                  paddingInlineStart: inputAffordance ? 1 : 0,
                }}
              >
                <Icon size={20} />
              </Box>
            ) : image ? (
              <Box
                flexShrink={0}
                display="flex"
                alignItems="center"
                sx={{ paddingInlineStart: inputAffordance ? 1.5 : 0 }}
              >
                {image}
              </Box>
            ) : null
          ) : null}
        </Box>
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            gap={1}
            width="100%"
          >
            {typeof label === 'string' ? (
              <Typography variant="h4" noWrap={!wrapLabel}>
                {label}
              </Typography>
            ) : (
              label
            )}
            {adornment}
          </Box>
          {sublabel && typeof sublabel === 'string' ? (
            <Typography variant="body2">{sublabel}</Typography>
          ) : (
            sublabel
          )}
          {children}
        </Box>
        {graphicPlacement === 'end' ? (
          Icon ? (
            <Icon size={24} />
          ) : image ? (
            <Box flexShrink={0} display="flex" alignItems="center">
              {image}
            </Box>
          ) : null
        ) : null}
      </Box>
    </Tooltip>
  );
}
