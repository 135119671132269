import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import isNotNullish from '@watershed/shared-util/isNotNullish';

interface UserFieldsNeededForLatestActivated {
  createdAt: Date | DateTime; // Since we call this from both the client and the server. Not the tightest abstraction, honestly.
  loginActivated: boolean;
}

/**
 * This is used to check against our known list of E2E users. Make sure to add an entry here if you
 * add a new `E2E_USERNAME_*` environment variable! We use this to determine what we send to Sentry,
 * in part, so it's most useful to be accurate. It's okay if some of these are undefined, depending
 * on which environments we initialize this in.
 */
const E2E_USERNAMES = new Set(
  [
    process.env.E2E_USERNAME_APPAREL,
    process.env.E2E_USERNAME_CORPORATE,
    process.env.E2E_USERNAME_CSRD_DEMO,
    process.env.E2E_USERNAME_DATA_INGESTION,
    process.env.E2E_USERNAME_EVERYTHING_CO,
    process.env.E2E_USERNAME_ENT_FOUND,
    process.env.E2E_USERNAME_ENT_FOUND_SMOKE,
    process.env.E2E_USERNAME_FINANCE,
    process.env.E2E_USERNAME_FOOTPRINT_PLATFORM,
    process.env.E2E_USERNAME_PORTCO,
    process.env.E2E_USERNAME_REDUCTIONS,
    process.env.E2E_USERNAME_RELIABILITY_PROBER,
    process.env.E2E_USERNAME_SUPPLIER,
    process.env.E2E_USERNAME_SUPPLY_CHAIN,
    process.env.E2E_USERNAME_SUPPLY_CHAIN_ADMIN,
    process.env.E2E_USERNAME_LOGIN,
  ].filter(isNotNullish)
);

export function getLatestUserActivatedPreferred<
  T extends UserFieldsNeededForLatestActivated,
>(users: Array<T>): T {
  const sortedUsers = orderBy(users, (user) => user.createdAt, 'desc');
  const activatedUsers = sortedUsers.filter((user) => user.loginActivated);

  // if there are no activated users, use the latest created user
  const user = activatedUsers.length > 0 ? activatedUsers[0] : sortedUsers[0];
  return user;
}

/**
 * Is an authenticated user a customer?
 */
export function isAuthenticatedUserACustomer({
  loginAsUserId,
  isWatershedEmployee,
  isWatershedContractor,
  isE2ETester,
}: {
  loginAsUserId?: string;
  isWatershedEmployee: boolean;
  isWatershedContractor: boolean;
  isE2ETester: boolean;
}): boolean {
  return (
    !loginAsUserId &&
    !isWatershedEmployee &&
    !isWatershedContractor &&
    !isE2ETester
  );
}

/**
 * This function checks against our known list of E2E users for determining `isRealUser` tag for
 * Sentry. Ensure that you add mappings to `E2E_USERNAMES` if you add new `E2E_USERNAME_*` environment
 * variables! Takes `User` shaped objects to check the username.
 */
export function isE2ETester(user: { name: string }): boolean {
  return E2E_USERNAMES.has(user.name);
}

/**
 * Takes a user-shaped object and checks if they're a contractor for Watershed. Currently, we just do
 * an email check but this could be more sophisticated in the future. This gets passed `User` objects,
 * but it also gets passed login as objects so we need the loose typing here.
 */
export function isWatershedContractor(user: { email: string }): boolean {
  return user.email.endsWith('-c@watershedclimate.com');
}
