import {
  GQFootprintsForFootprintSelectorQuery,
  GQUpdateFootprintReviewRequestStatusMutation,
} from '@watershed/shared-universal/generated/graphql';

export function mergeWatershedFootprintReviewRequestIntoFootprintSelector(
  data: GQFootprintsForFootprintSelectorQuery | null,
  payload: GQUpdateFootprintReviewRequestStatusMutation['updateWatershedFootprintReviewRequestStatus']
): GQFootprintsForFootprintSelectorQuery | null {
  if (data === null) {
    return null;
  }

  const updatedFootprints = data.footprints.edges.map((edge) => {
    if (!edge?.node) {
      return null;
    }

    const updatedVersions = edge.node.footprintVersions.map((version) => {
      if (
        version.watershedFootprintReviewRequest?.id ===
        payload.watershedFootprintReviewRequest.id
      ) {
        return {
          ...version,
          watershedFootprintReviewRequest: {
            ...version.watershedFootprintReviewRequest,
            status: payload.watershedFootprintReviewRequest.status,
          },
        };
      }
      return version;
    });

    return {
      ...edge,
      node: {
        ...edge.node,
        footprintVersions: updatedVersions,
      },
    };
  });

  return {
    ...data,
    footprints: {
      ...data.footprints,
      edges: updatedFootprints,
    },
  };
}
