import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { GQFinanceStandardView } from '@watershed/shared-universal/generated/graphql';
import {
  parseArrayQueryParam,
  parseQueryParam,
} from '@watershed/shared-universal/utils/queryParamUtils';
import { FinanceFiltersType } from '@watershed/shared-universal/apiSchemas/finance/zodFinance';
import { FINANCE_FILTER_COLUMNS_QUERY_PARAMS } from '@watershed/shared-universal/fund/financeFieldRegistry';
import defaultYear from '@watershed/shared-universal/finance/defaultYear';

function getFiltersFromQuery(query: ParsedUrlQuery): FinanceFiltersType {
  // Check the query for any filters in our available filters list
  const queryFilters = FINANCE_FILTER_COLUMNS_QUERY_PARAMS.reduce(
    (acc, filter) => {
      if (query[filter]) {
        const parsed = parseArrayQueryParam(query[filter]);
        if (parsed) {
          acc[filter] = parsed;
        }
      }
      return acc;
    },
    {} as Record<string, Array<string>>
  );

  // Look for any tag filters
  Object.keys(query).forEach((key) => {
    if (key.startsWith('ft_')) {
      const parsed = parseArrayQueryParam(query[key]);
      if (parsed) {
        queryFilters[key] = parsed;
      }
    }
  });

  return queryFilters;
}

export default function useV3Query(
  standardView: GQFinanceStandardView | null = null,
  breadcrumb: {
    viewId?: string | null;
    fundId?: string | null;
  } | null = null
) {
  const { query, asPath } = useRouter();

  // If the view is a breadcrumb, we only want the view as a breadcrumb!
  const viewId = breadcrumb?.viewId
    ? null
    : (parseQueryParam(query.viewId) ?? null);

  const filters = getFiltersFromQuery(query);
  const yearString = parseQueryParam(query.year) ?? null;
  const year = yearString ? parseInt(yearString, 10) : defaultYear;
  const assetId = parseQueryParam(query.assetId) ?? null;
  const fundGroup = parseQueryParam(query.fundGroup) ?? null;
  const fundCategory = parseQueryParam(query.fundCategory) ?? null;
  const fundId = parseQueryParam(query.fundId) ?? null;
  const isTotalEmissions = parseQueryParam(query.isTotalEmissions) === 'true';
  const starred = asPath.includes('tracker/');

  if (standardView === GQFinanceStandardView.Asset && !assetId) {
    console.warn(`Asset view set without an assetId in URL params: ${asPath}`);
  }
  if (standardView === GQFinanceStandardView.Fund && !fundId) {
    console.warn(`Fund view set without an fundId in URL params: ${asPath}`);
  }
  if (standardView === GQFinanceStandardView.FundGroup && !fundGroup) {
    console.warn(
      `Fund group view set without an fundGroup in URL params: ${asPath}`
    );
  }
  if (standardView === GQFinanceStandardView.FundCategory && !fundCategory) {
    console.warn(
      `Fund category view set without an fundCategory in URL params: ${asPath}`
    );
  }

  return {
    filters,
    viewId,
    year,
    assetId,
    fundId,
    fundGroup,
    fundCategory,
    standardView,
    breadcrumb,
    isTotalEmissions,
    starred,
  };
}
