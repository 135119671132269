import { Stack, Typography } from '@mui/material';

export interface CommandPaletteShortcutButtonProps {
  description: string;
  textRepresentation?: string;
  onClick?: () => void;
}

export default function CommandPaletteShortcutButton({
  description,
  textRepresentation,
  onClick,
}: CommandPaletteShortcutButtonProps) {
  return (
    <Stack
      gap={0.75}
      direction="row"
      component="button"
      onClick={onClick}
      border="0"
      borderRadius="4px"
      py={0.5}
      pl={0.5}
      pr={1}
      bgcolor="transparent"
      alignItems="center"
      sx={{
        ':hover': {
          backgroundColor: (theme) => theme.palette.grey10,
          '& div': {
            backgroundColor: (theme) => theme.palette.grey20,
          },
        },
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        fontSize="11px"
        px={0.5}
        height={20}
        flexShrink={0}
        borderRadius="4px"
        bgcolor={(theme) => theme.palette.grey10}
        textTransform="uppercase"
        letterSpacing="0.025rem"
        color={(theme) => theme.palette.grey70}
        fontWeight="bold"
        boxShadow={(theme) => `0 0.5px 0 0 ${theme.palette.grey30}`}
      >
        {textRepresentation}
      </Stack>
      <Typography variant="body3" color={(theme) => theme.palette.grey50}>
        {description}
      </Typography>
    </Stack>
  );
}
