/// <reference types="@welldone-software/why-did-you-render" />

import React from 'react';

if (
  process.env.NODE_ENV === 'development' &&
  process.env.NEXT_PUBLIC_WHY_DID_YOU_RENDER
) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: false,
    // include: [/./],
  });
}
