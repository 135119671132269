import { OutputKey, InputKey } from '../../fund/financeFieldRegistry';
import {
  GQFinanceStandardView,
  GQFinanceChartKind,
  GQFinanceHighchartsChartKind,
  GQFinanceChartMetric,
} from '../../generated/graphql';
import { FinanceChartConfigType } from '../../apiSchemas/finance/zodFinance';
import assertNever from '@watershed/shared-util/assertNever';
import { z } from 'zod';

const supportedNonPercentageBasedFinanceChartKinds = [
  GQFinanceHighchartsChartKind.BarHorizontal,
  GQFinanceHighchartsChartKind.BarVertical,
] as const;

const supportedPercentageBasedFinanceChartKinds = [
  GQFinanceHighchartsChartKind.Meter,
  GQFinanceHighchartsChartKind.Treemap,
  GQFinanceHighchartsChartKind.Pie,
  GQFinanceHighchartsChartKind.Pareto,
] as const;

// Hardcoded ID of a data point that represents "XXX others" when there are too many
// results to return to the frontend. These groups are not real and we shouldn't try to
// process click events for them.
export const AGGREGATED_OTHERS_ID = '__aggregated_others__';

export const FINANCE_CHART_KIND_TO_HIGHCHARTS_CHART_KIND: Record<
  GQFinanceChartKind,
  GQFinanceHighchartsChartKind
> = {
  [GQFinanceChartKind.FinanceColumnChart]:
    GQFinanceHighchartsChartKind.BarVertical,
  [GQFinanceChartKind.FinanceStackedBarChart]:
    GQFinanceHighchartsChartKind.Treemap,
  [GQFinanceChartKind.FinancedEmissionsBySector]:
    GQFinanceHighchartsChartKind.BarHorizontal,
  [GQFinanceChartKind.TotalEmissionsByScope]:
    GQFinanceHighchartsChartKind.Meter,
  [GQFinanceChartKind.FinancedEmissionsByScope]:
    GQFinanceHighchartsChartKind.Meter,
} as const;

export const highchartsDataUnionSchema = z.discriminatedUnion(
  'highchartsKind',
  [
    z.object({
      highchartsKind: z.literal(GQFinanceHighchartsChartKind.BarVertical),
      highchartsData: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          data: z.array(z.number()),
        })
      ),
    }),
    z.object({
      highchartsKind: z.literal(GQFinanceHighchartsChartKind.BarHorizontal),
      highchartsData: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          data: z.array(z.number()),
        })
      ),
    }),
    z.object({
      highchartsKind: z.literal(GQFinanceHighchartsChartKind.Pie),
      highchartsData: z.array(
        z.object({
          id: z.string(),
          label: z.string(),
          value: z.number(),
        })
      ),
    }),
    z.object({
      highchartsKind: z.literal(GQFinanceHighchartsChartKind.Meter),
      highchartsData: z.array(
        z.object({
          id: z.string(),
          label: z.string(),
          value: z.number(),
        })
      ),
    }),
    z.object({
      highchartsKind: z.literal(GQFinanceHighchartsChartKind.Treemap),
      highchartsData: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          value: z.number(),
        })
      ),
    }),
    z.object({
      highchartsKind: z.literal(GQFinanceHighchartsChartKind.Pareto),
      highchartsData: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          value: z.number(),
        })
      ),
    }),
  ]
);

export type FinanceHighchartsDataUnionType = z.TypeOf<
  typeof highchartsDataUnionSchema
>;

export function supportedFinanceChartKindsForMetric(metric: string | null) {
  switch (metric) {
    case GQFinanceChartMetric.EconomicIntensity:
    case GQFinanceChartMetric.RevenueIntensity:
    case GQFinanceChartMetric.Waci:
    case GQFinanceChartMetric.Pcaf:
      return supportedNonPercentageBasedFinanceChartKinds;
    default:
      return [
        ...supportedNonPercentageBasedFinanceChartKinds,
        ...supportedPercentageBasedFinanceChartKinds,
      ];
  }
}

export function convertFinancedEmissionsBySectorDataToHighchartsData(
  highchartsKind: GQFinanceHighchartsChartKind,
  data: Array<{
    id: string;
    sectorCode: string | null | undefined;
    name: string;
    value: {
      metric: 'FinancedEmissions';
      quantity: number;
      unit: string;
      description: string;
      isMissingInputs: boolean;
      name: string;
    };
    members: Array<string>;
  }>
): FinanceHighchartsDataUnionType {
  switch (highchartsKind) {
    case GQFinanceHighchartsChartKind.Pie:
    case GQFinanceHighchartsChartKind.Meter:
      return {
        highchartsKind,
        highchartsData: data.map((d) => ({
          id: d.id,
          label: d.name,
          value: d.value.quantity,
        })),
      };
    case GQFinanceHighchartsChartKind.BarHorizontal:
    case GQFinanceHighchartsChartKind.BarVertical:
      return {
        highchartsKind,
        highchartsData: data.map((d, index) => ({
          id: d.id,
          name: d.name,
          data: Array(index)
            .fill(0)
            .concat(d.value.quantity, Array(data.length - index - 1).fill(0)),
        })),
      };
    case GQFinanceHighchartsChartKind.Treemap:
    case GQFinanceHighchartsChartKind.Pareto:
      return {
        highchartsKind,
        highchartsData: data.map((d) => ({
          ...d,
          value: d.value.quantity,
        })),
      };
    default:
      assertNever(highchartsKind);
  }
}

export function convertScopesDataToHighchartsData(
  highchartsKind: GQFinanceHighchartsChartKind,
  data: {
    oneYearGross: number;
    oneYearScope1: number;
    oneYearScope2: number;
    oneYearScope3: number;
  }
): FinanceHighchartsDataUnionType {
  switch (highchartsKind) {
    case GQFinanceHighchartsChartKind.Pie:
    case GQFinanceHighchartsChartKind.Meter:
      return {
        highchartsKind,
        highchartsData: [
          { id: 'oneYearScope1', label: 'Scope 1', value: data.oneYearScope1 },
          { id: 'oneYearScope2', label: 'Scope 2', value: data.oneYearScope2 },
          { id: 'oneYearScope3', label: 'Scope 3', value: data.oneYearScope3 },
        ],
      };
    case GQFinanceHighchartsChartKind.BarHorizontal:
    case GQFinanceHighchartsChartKind.BarVertical:
      return {
        highchartsKind,
        highchartsData: [
          {
            id: 'oneYearScope1',
            name: 'Scope 1',
            data: [data.oneYearScope1, 0, 0],
          },
          {
            id: 'oneYearScope2',
            name: 'Scope 2',
            data: [0, data.oneYearScope2, 0],
          },
          {
            id: 'oneYearScope3',
            name: 'Scope 3',
            data: [0, 0, data.oneYearScope3],
          },
        ],
      };
    case GQFinanceHighchartsChartKind.Treemap:
    case GQFinanceHighchartsChartKind.Pareto:
      return {
        highchartsKind,
        highchartsData: [
          { id: 'oneYearScope1', name: 'Scope 1', value: data.oneYearScope1 },
          { id: 'oneYearScope2', name: 'Scope 2', value: data.oneYearScope2 },
          { id: 'oneYearScope3', name: 'Scope 3', value: data.oneYearScope3 },
        ],
      };
    default:
      assertNever(highchartsKind);
  }
}

export function convertToHighchartsData(
  highchartsKind: GQFinanceHighchartsChartKind,
  data: Array<{
    id: string;
    name: string;
    y: number;
  }>
): FinanceHighchartsDataUnionType {
  switch (highchartsKind) {
    case GQFinanceHighchartsChartKind.Pie:
    case GQFinanceHighchartsChartKind.Meter:
      return {
        highchartsKind,
        highchartsData: data.map((d) => ({
          id: d.id,
          label: d.name,
          value: d.y,
        })),
      };
    case GQFinanceHighchartsChartKind.BarHorizontal:
    case GQFinanceHighchartsChartKind.BarVertical:
      return {
        highchartsKind,
        highchartsData: data.map((d, index) => ({
          id: d.id,
          name: d.name,
          data: Array(index)
            .fill(0)
            .concat(d.y, Array(data.length - index - 1).fill(0)),
        })),
      };
    case GQFinanceHighchartsChartKind.Treemap:
    case GQFinanceHighchartsChartKind.Pareto:
      return {
        highchartsKind,
        highchartsData: data.map((d) => ({
          ...d,
          value: d.y,
        })),
      };

    default:
      assertNever(highchartsKind);
  }
}

/**
 * If a saved view hasn't provided a list of charts to render,
 * gives the default list.
 */
export function getDefaultCharts(
  standardView: GQFinanceStandardView | null,
  isTotalEmissions: boolean
) {
  const chartConfigs: Array<FinanceChartConfigType> = [];
  if (standardView === GQFinanceStandardView.Overview || !standardView) {
    chartConfigs.push({
      metric: OutputKey.financedEmissions,
      dimension: InputKey.fundId,
      chartKind: GQFinanceHighchartsChartKind.BarVertical,
    });
  }

  if (
    standardView === GQFinanceStandardView.Overview ||
    standardView === GQFinanceStandardView.Fund ||
    standardView === GQFinanceStandardView.FundGroup ||
    standardView === GQFinanceStandardView.FundCategory ||
    !standardView
  ) {
    chartConfigs.push(
      {
        metric: OutputKey.financedEmissions,
        dimension: OutputKey.assetId,
        chartType: GQFinanceChartKind.FinanceStackedBarChart,
        chartKind:
          FINANCE_CHART_KIND_TO_HIGHCHARTS_CHART_KIND[
            GQFinanceChartKind.FinanceStackedBarChart
          ],
      },
      {
        metric: OutputKey.economicIntensity,
        dimension: OutputKey.assetId,
        chartKind: GQFinanceHighchartsChartKind.BarVertical,
      },
      {
        metric: OutputKey.economicIntensity,
        dimension: OutputKey.industrySectorLabel,
        chartType: GQFinanceChartKind.FinancedEmissionsBySector,
        chartKind:
          FINANCE_CHART_KIND_TO_HIGHCHARTS_CHART_KIND[
            GQFinanceChartKind.FinancedEmissionsBySector
          ],
      }
    );
  }

  if (standardView === GQFinanceStandardView.Asset) {
    const chartType = isTotalEmissions
      ? GQFinanceChartKind.TotalEmissionsByScope
      : GQFinanceChartKind.FinancedEmissionsByScope;
    chartConfigs.push({
      metric: isTotalEmissions
        ? OutputKey.totalEmissions
        : OutputKey.financedEmissions,

      // TODO: Add to registry?
      dimension: 'scope',

      chartType,
      chartKind: FINANCE_CHART_KIND_TO_HIGHCHARTS_CHART_KIND[chartType],
    });
  }
  return chartConfigs;
}
