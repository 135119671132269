/*
  This file is semi-automatically generated from
  cliq/taxonomy/scripts/generate_naics_2022__ceda_5_weighted_mapping.py in the cliq repo
  Do not manually modify this file
 */
import type { WeightedBeaCodes } from './industryCodeUtils';

export const NAICS_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['111110']: { label: 'Soybean Farming', BEA_CODES: { '1111A0': 1.0 } },
  ['111120']: {
    label: 'Oilseed (except Soybean) Farming',
    BEA_CODES: { '1111A0': 1.0 },
  },
  ['111130']: {
    label: 'Dry Pea and Bean Farming',
    BEA_CODES: { '1111B0': 1.0 },
  },
  ['111140']: { label: 'Wheat Farming', BEA_CODES: { '1111B0': 1.0 } },
  ['111150']: { label: 'Corn Farming', BEA_CODES: { '1111B0': 1.0 } },
  ['111160']: { label: 'Rice Farming', BEA_CODES: { '1111B0': 1.0 } },
  ['111191']: {
    label: 'Oilseed and Grain Combination Farming',
    BEA_CODES: { '1111B0': 1.0 },
  },
  ['111199']: {
    label: 'All Other Grain Farming',
    BEA_CODES: { '1111B0': 1.0 },
  },
  ['111211']: { label: 'Potato Farming', BEA_CODES: { '111200': 1.0 } },
  ['111219']: {
    label: 'Other Vegetable (except Potato) and Melon Farming',
    BEA_CODES: { '111200': 1.0 },
  },
  ['111310']: { label: 'Orange Groves', BEA_CODES: { '111300': 1.0 } },
  ['111320']: {
    label: 'Citrus (except Orange) Groves',
    BEA_CODES: { '111300': 1.0 },
  },
  ['111331']: { label: 'Apple Orchards', BEA_CODES: { '111300': 1.0 } },
  ['111332']: { label: 'Grape Vineyards', BEA_CODES: { '111300': 1.0 } },
  ['111333']: { label: 'Strawberry Farming', BEA_CODES: { '111300': 1.0 } },
  ['111334']: {
    label: 'Berry (except Strawberry) Farming',
    BEA_CODES: { '111300': 1.0 },
  },
  ['111335']: { label: 'Tree Nut Farming', BEA_CODES: { '111300': 1.0 } },
  ['111336']: {
    label: 'Fruit and Tree Nut Combination Farming',
    BEA_CODES: { '111300': 1.0 },
  },
  ['111339']: {
    label: 'Other Noncitrus Fruit Farming',
    BEA_CODES: { '111300': 1.0 },
  },
  ['111411']: { label: 'Mushroom Production', BEA_CODES: { '111400': 1.0 } },
  ['111419']: {
    label: 'Other Food Crops Grown Under Cover',
    BEA_CODES: { '111400': 1.0 },
  },
  ['111421']: {
    label: 'Nursery and Tree Production',
    BEA_CODES: { '111400': 1.0 },
  },
  ['111422']: {
    label: 'Floriculture Production',
    BEA_CODES: { '111400': 1.0 },
  },
  ['111910']: { label: 'Tobacco Farming', BEA_CODES: { '111900': 1.0 } },
  ['111920']: { label: 'Cotton Farming', BEA_CODES: { '111900': 1.0 } },
  ['111930']: { label: 'Sugarcane Farming', BEA_CODES: { '111900': 1.0 } },
  ['111940']: { label: 'Hay Farming', BEA_CODES: { '111900': 1.0 } },
  ['111991']: { label: 'Sugar Beet Farming', BEA_CODES: { '111900': 1.0 } },
  ['111992']: { label: 'Peanut Farming', BEA_CODES: { '111900': 1.0 } },
  ['111998']: {
    label: 'All Other Miscellaneous Crop Farming',
    BEA_CODES: { '111900': 1.0 },
  },
  ['112111']: {
    label: 'Beef Cattle Ranching and Farming',
    BEA_CODES: { '1121A0': 1.0 },
  },
  ['112112']: { label: 'Cattle Feedlots', BEA_CODES: { '1121A0': 1.0 } },
  ['112120']: {
    label: 'Dairy Cattle and Milk Production',
    BEA_CODES: { '112120': 1.0 },
  },
  ['112130']: {
    label: 'Dual-Purpose Cattle Ranching and Farming',
    BEA_CODES: { '1121A0': 1.0 },
  },
  ['112210']: { label: 'Hog and Pig Farming', BEA_CODES: { '112A00': 1.0 } },
  ['112310']: { label: 'Chicken Egg Production', BEA_CODES: { '112300': 1.0 } },
  ['112320']: {
    label: 'Broilers and Other Meat Type Chicken Production',
    BEA_CODES: { '112300': 1.0 },
  },
  ['112330']: { label: 'Turkey Production', BEA_CODES: { '112300': 1.0 } },
  ['112340']: { label: 'Poultry Hatcheries', BEA_CODES: { '112300': 1.0 } },
  ['112390']: {
    label: 'Other Poultry Production',
    BEA_CODES: { '112300': 1.0 },
  },
  ['112410']: { label: 'Sheep Farming', BEA_CODES: { '112A00': 1.0 } },
  ['112420']: { label: 'Goat Farming', BEA_CODES: { '112A00': 1.0 } },
  ['112511']: {
    label: 'Finfish Farming and Fish Hatcheries',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['112512']: { label: 'Shellfish Farming', BEA_CODES: { '112A00': 1.0 } },
  ['112519']: { label: 'Other Aquaculture', BEA_CODES: { '112A00': 1.0 } },
  ['112910']: { label: 'Apiculture', BEA_CODES: { '112A00': 1.0 } },
  ['112920']: {
    label: 'Horses and Other Equine Production',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['112930']: {
    label: 'Fur-Bearing Animal and Rabbit Production',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['112990']: {
    label: 'All Other Animal Production',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['113110']: {
    label: 'Timber Tract Operations',
    BEA_CODES: { '113000': 1.0 },
  },
  ['113210']: {
    label: 'Forest Nurseries and Gathering of Forest Products',
    BEA_CODES: { '113000': 1.0 },
  },
  ['113310']: { label: 'Logging', BEA_CODES: { '113000': 1.0 } },
  ['114111']: { label: 'Finfish Fishing', BEA_CODES: { '114000': 1.0 } },
  ['114112']: { label: 'Shellfish Fishing', BEA_CODES: { '114000': 1.0 } },
  ['114119']: { label: 'Other Marine Fishing', BEA_CODES: { '114000': 1.0 } },
  ['114210']: { label: 'Hunting and Trapping', BEA_CODES: { '114000': 1.0 } },
  ['115111']: { label: 'Cotton Ginning', BEA_CODES: { '115000': 1.0 } },
  ['115112']: {
    label: 'Soil Preparation, Planting, and Cultivating',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115113']: {
    label: 'Crop Harvesting, Primarily by Machine',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115114']: {
    label: 'Postharvest Crop Activities (except Cotton Ginning)',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115115']: {
    label: 'Farm Labor Contractors and Crew Leaders',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115116']: {
    label: 'Farm Management Services',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115210']: {
    label: 'Support Activities for Animal Production',
    BEA_CODES: { '115000': 1.0 },
  },
  ['115310']: {
    label: 'Support Activities for Forestry',
    BEA_CODES: { '115000': 1.0 },
  },
  ['211120']: {
    label: 'Crude Petroleum Extraction',
    BEA_CODES: { '211000': 1.0 },
  },
  ['211130']: { label: 'Natural Gas Extraction', BEA_CODES: { '211000': 1.0 } },
  ['212114']: { label: 'Surface Coal Mining', BEA_CODES: { '212100': 1.0 } },
  ['212115']: {
    label: 'Underground Coal Mining',
    BEA_CODES: { '212100': 1.0 },
  },
  ['212210']: { label: 'Iron Ore Mining', BEA_CODES: { '2122A0': 1.0 } },
  ['212220']: {
    label: 'Gold Ore and Silver Ore Mining',
    BEA_CODES: { '2122A0': 1.0 },
  },
  ['212230']: {
    label: 'Copper, Nickel, Lead, and Zinc Mining',
    BEA_CODES: { '212230': 1.0 },
  },
  ['212290']: { label: 'Other Metal Ore Mining', BEA_CODES: { '2122A0': 1.0 } },
  ['212311']: {
    label: 'Dimension Stone Mining and Quarrying',
    BEA_CODES: { '212310': 1.0 },
  },
  ['212312']: {
    label: 'Crushed and Broken Limestone Mining and Quarrying',
    BEA_CODES: { '212310': 1.0 },
  },
  ['212313']: {
    label: 'Crushed and Broken Granite Mining and Quarrying',
    BEA_CODES: { '212310': 1.0 },
  },
  ['212319']: {
    label: 'Other Crushed and Broken Stone Mining and Quarrying',
    BEA_CODES: { '212310': 1.0 },
  },
  ['212321']: {
    label: 'Construction Sand and Gravel Mining',
    BEA_CODES: { '2123A0': 1.0 },
  },
  ['212322']: { label: 'Industrial Sand Mining', BEA_CODES: { '2123A0': 1.0 } },
  ['212323']: {
    label: 'Kaolin, Clay, and Ceramic and Refractory Minerals Mining',
    BEA_CODES: { '2123A0': 1.0 },
  },
  ['212390']: {
    label: 'Other Nonmetallic Mineral Mining and Quarrying',
    BEA_CODES: { '2123A0': 1.0 },
  },
  ['213111']: {
    label: 'Drilling Oil and Gas Wells',
    BEA_CODES: { '213111': 1.0 },
  },
  ['213112']: {
    label: 'Support Activities for Oil and Gas Operations',
    BEA_CODES: { '21311A': 1.0 },
  },
  ['213113']: {
    label: 'Support Activities for Coal Mining',
    BEA_CODES: { '21311A': 1.0 },
  },
  ['213114']: {
    label: 'Support Activities for Metal Mining',
    BEA_CODES: { '21311A': 1.0 },
  },
  ['213115']: {
    label: 'Support Activities for Nonmetallic Minerals (except Fuels) Mining',
    BEA_CODES: { '21311A': 1.0 },
  },
  ['221111']: {
    label: 'Hydroelectric Power Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221112']: {
    label: 'Fossil Fuel Electric Power Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221113']: {
    label: 'Nuclear Electric Power Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221114']: {
    label: 'Solar Electric Power Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221115']: {
    label: 'Wind Electric Power Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221116']: {
    label: 'Geothermal Electric Power Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221117']: {
    label: 'Biomass Electric Power Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221118']: {
    label: 'Other Electric Power Generation',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221121']: {
    label: 'Electric Bulk Power Transmission and Control',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221122']: {
    label: 'Electric Power Distribution',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221210']: {
    label: 'Natural Gas Distribution',
    BEA_CODES: { '221100': 1.0 },
  },
  ['221310']: {
    label: 'Water Supply and Irrigation Systems',
    BEA_CODES: { '221300': 1.0 },
  },
  ['221320']: {
    label: 'Sewage Treatment Facilities',
    BEA_CODES: { '221300': 1.0 },
  },
  ['221330']: {
    label: 'Steam and Air-Conditioning Supply',
    BEA_CODES: { '221300': 1.0 },
  },
  ['236115']: {
    label: 'New Single-Family Housing Construction (except For-Sale Builders)',
    BEA_CODES: { '233411': 1.0 },
  },
  ['236116']: {
    label: 'New Multifamily Housing Construction (except For-Sale Builders)',
    BEA_CODES: { '233412': 1.0 },
  },
  ['236117']: {
    label: 'New Housing For-Sale Builders',
    BEA_CODES: {
      '233411': 0.4033684,
      '233412': 0.0841343,
      '2334A0': 0.5124973,
    },
  },
  ['236118']: { label: 'Residential Remodelers', BEA_CODES: { '230302': 1.0 } },
  ['236210']: {
    label: 'Industrial Building Construction',
    BEA_CODES: { '230301': 0.7830278, '233230': 0.2169722 },
  },
  ['236220']: {
    label: 'Commercial and Institutional Building Construction',
    BEA_CODES: {
      '230301': 0.4578566,
      '233210': 0.1199458,
      '233262': 0.2119772,
      '2332A0': 0.2102203,
    },
  },
  ['237110']: {
    label: 'Water and Sewer Line and Related Structures Construction',
    BEA_CODES: { '233240': 1.0 },
  },
  ['237120']: {
    label: 'Oil and Gas Pipeline and Related Structures Construction',
    BEA_CODES: { '233240': 1.0 },
  },
  ['237130']: {
    label: 'Power and Communication Line and Related Structures Construction',
    BEA_CODES: { '233240': 1.0 },
  },
  ['237210']: {
    label: 'Land Subdivision',
    BEA_CODES: {
      '233210': 0.0503606,
      '233230': 0.0532675,
      '233240': 0.1239249,
      '233262': 0.089001,
      '2332A0': 0.0882634,
      '2332C0': 0.1331627,
      '2332D0': 0.10225,
      '233411': 0.1451198,
      '233412': 0.030269,
      '2334A0': 0.184381,
    },
  },
  ['237310']: {
    label: 'Highway, Street, and Bridge Construction',
    BEA_CODES: { '2332C0': 1.0 },
  },
  ['237990']: {
    label: 'Other Heavy and Civil Engineering Construction',
    BEA_CODES: { '2332D0': 1.0 },
  },
  ['238110']: {
    label: 'Poured Concrete Foundation and Structure Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238120']: {
    label: 'Structural Steel and Precast Concrete Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238130']: {
    label: 'Framing Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238140']: {
    label: 'Masonry Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238150']: {
    label: 'Glass and Glazing Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238160']: {
    label: 'Roofing Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238170']: {
    label: 'Siding Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238190']: {
    label: 'Other Foundation, Structure, and Building Exterior Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238210']: {
    label: 'Electrical Contractors and Other Wiring Installation Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238220']: {
    label: 'Plumbing, Heating, and Air-Conditioning Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238290']: {
    label: 'Other Building Equipment Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238310']: {
    label: 'Drywall and Insulation Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238320']: {
    label: 'Painting and Wall Covering Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238330']: {
    label: 'Flooring Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238340']: {
    label: 'Tile and Terrazzo Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238350']: {
    label: 'Finish Carpentry Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238390']: {
    label: 'Other Building Finishing Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238910']: {
    label: 'Site Preparation Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['238990']: {
    label: 'All Other Specialty Trade Contractors',
    BEA_CODES: {
      '230301': 0.1687896,
      '230302': 0.0700998,
      '233210': 0.0442182,
      '233230': 0.0467706,
      '233240': 0.10881,
      '233262': 0.0781457,
      '2332A0': 0.0774981,
      '2332D0': 0.0897787,
      '233411': 0.1274197,
      '233412': 0.0265771,
      '2334A0': 0.1618924,
    },
  },
  ['311111']: {
    label: 'Dog and Cat Food Manufacturing',
    BEA_CODES: { '311111': 1.0 },
  },
  ['311119']: {
    label: 'Other Animal Food Manufacturing',
    BEA_CODES: { '311119': 1.0 },
  },
  ['311211']: { label: 'Flour Milling', BEA_CODES: { '311210': 1.0 } },
  ['311212']: { label: 'Rice Milling', BEA_CODES: { '311210': 1.0 } },
  ['311213']: { label: 'Malt Manufacturing', BEA_CODES: { '311210': 1.0 } },
  ['311221']: {
    label: 'Wet Corn Milling and Starch Manufacturing',
    BEA_CODES: { '311221': 1.0 },
  },
  ['311224']: {
    label: 'Soybean and Other Oilseed Processing',
    BEA_CODES: { '311224': 1.0 },
  },
  ['311225']: {
    label: 'Fats and Oils Refining and Blending',
    BEA_CODES: { '311225': 1.0 },
  },
  ['311230']: {
    label: 'Breakfast Cereal Manufacturing',
    BEA_CODES: { '311230': 1.0 },
  },
  ['311313']: {
    label: 'Beet Sugar Manufacturing',
    BEA_CODES: { '311300': 1.0 },
  },
  ['311314']: {
    label: 'Cane Sugar Manufacturing',
    BEA_CODES: { '311300': 1.0 },
  },
  ['311340']: {
    label: 'Nonchocolate Confectionery Manufacturing',
    BEA_CODES: { '311300': 1.0 },
  },
  ['311351']: {
    label: 'Chocolate and Confectionery Manufacturing from Cacao Beans',
    BEA_CODES: { '311300': 1.0 },
  },
  ['311352']: {
    label: 'Confectionery Manufacturing from Purchased Chocolate',
    BEA_CODES: { '311300': 1.0 },
  },
  ['311411']: {
    label: 'Frozen Fruit, Juice, and Vegetable Manufacturing',
    BEA_CODES: { '311410': 1.0 },
  },
  ['311412']: {
    label: 'Frozen Specialty Food Manufacturing',
    BEA_CODES: { '311410': 1.0 },
  },
  ['311421']: {
    label: 'Fruit and Vegetable Canning',
    BEA_CODES: { '311420': 1.0 },
  },
  ['311422']: { label: 'Specialty Canning', BEA_CODES: { '311420': 1.0 } },
  ['311423']: {
    label: 'Dried and Dehydrated Food Manufacturing',
    BEA_CODES: { '311420': 1.0 },
  },
  ['311511']: {
    label: 'Fluid Milk Manufacturing',
    BEA_CODES: { '31151A': 1.0 },
  },
  ['311512']: {
    label: 'Creamery Butter Manufacturing',
    BEA_CODES: { '31151A': 1.0 },
  },
  ['311513']: { label: 'Cheese Manufacturing', BEA_CODES: { '311513': 1.0 } },
  ['311514']: {
    label: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing',
    BEA_CODES: { '311514': 1.0 },
  },
  ['311520']: {
    label: 'Ice Cream and Frozen Dessert Manufacturing',
    BEA_CODES: { '311520': 1.0 },
  },
  ['311611']: {
    label: 'Animal (except Poultry) Slaughtering',
    BEA_CODES: { '31161A': 1.0 },
  },
  ['311612']: {
    label: 'Meat Processed from Carcasses',
    BEA_CODES: { '31161A': 1.0 },
  },
  ['311613']: {
    label: 'Rendering and Meat Byproduct Processing',
    BEA_CODES: { '31161A': 1.0 },
  },
  ['311615']: { label: 'Poultry Processing', BEA_CODES: { '311615': 1.0 } },
  ['311710']: {
    label: 'Seafood Product Preparation and Packaging',
    BEA_CODES: { '311700': 1.0 },
  },
  ['311811']: { label: 'Retail Bakeries', BEA_CODES: { '311810': 1.0 } },
  ['311812']: { label: 'Commercial Bakeries', BEA_CODES: { '311810': 1.0 } },
  ['311813']: {
    label: 'Frozen Cakes, Pies, and Other Pastries Manufacturing',
    BEA_CODES: { '311810': 1.0 },
  },
  ['311821']: {
    label: 'Cookie and Cracker Manufacturing',
    BEA_CODES: { '3118A0': 1.0 },
  },
  ['311824']: {
    label:
      'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour',
    BEA_CODES: { '3118A0': 1.0 },
  },
  ['311830']: { label: 'Tortilla Manufacturing', BEA_CODES: { '3118A0': 1.0 } },
  ['311911']: {
    label: 'Roasted Nuts and Peanut Butter Manufacturing',
    BEA_CODES: { '311910': 1.0 },
  },
  ['311919']: {
    label: 'Other Snack Food Manufacturing',
    BEA_CODES: { '311910': 1.0 },
  },
  ['311920']: {
    label: 'Coffee and Tea Manufacturing',
    BEA_CODES: { '311920': 1.0 },
  },
  ['311930']: {
    label: 'Flavoring Syrup and Concentrate Manufacturing',
    BEA_CODES: { '311930': 1.0 },
  },
  ['311941']: {
    label: 'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing',
    BEA_CODES: { '311940': 1.0 },
  },
  ['311942']: {
    label: 'Spice and Extract Manufacturing',
    BEA_CODES: { '311940': 1.0 },
  },
  ['311991']: {
    label: 'Perishable Prepared Food Manufacturing',
    BEA_CODES: { '311990': 1.0 },
  },
  ['311999']: {
    label: 'All Other Miscellaneous Food Manufacturing',
    BEA_CODES: { '311990': 1.0 },
  },
  ['312111']: {
    label: 'Soft Drink Manufacturing',
    BEA_CODES: { '312110': 1.0 },
  },
  ['312112']: {
    label: 'Bottled Water Manufacturing',
    BEA_CODES: { '312110': 1.0 },
  },
  ['312113']: { label: 'Ice Manufacturing', BEA_CODES: { '312110': 1.0 } },
  ['312120']: { label: 'Breweries', BEA_CODES: { '312120': 1.0 } },
  ['312130']: { label: 'Wineries', BEA_CODES: { '312130': 1.0 } },
  ['312140']: { label: 'Distilleries', BEA_CODES: { '312140': 1.0 } },
  ['312230']: { label: 'Tobacco Manufacturing', BEA_CODES: { '312200': 1.0 } },
  ['313110']: {
    label: 'Fiber, Yarn, and Thread Mills',
    BEA_CODES: { '313100': 1.0 },
  },
  ['313210']: {
    label: 'Broadwoven Fabric Mills',
    BEA_CODES: { '313200': 1.0 },
  },
  ['313220']: {
    label: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
    BEA_CODES: { '313200': 1.0 },
  },
  ['313230']: { label: 'Nonwoven Fabric Mills', BEA_CODES: { '313200': 1.0 } },
  ['313240']: { label: 'Knit Fabric Mills', BEA_CODES: { '313200': 1.0 } },
  ['313310']: {
    label: 'Textile and Fabric Finishing Mills',
    BEA_CODES: { '313300': 1.0 },
  },
  ['313320']: { label: 'Fabric Coating Mills', BEA_CODES: { '313300': 1.0 } },
  ['314110']: { label: 'Carpet and Rug Mills', BEA_CODES: { '314110': 1.0 } },
  ['314120']: {
    label: 'Curtain and Linen Mills',
    BEA_CODES: { '314120': 1.0 },
  },
  ['314910']: {
    label: 'Textile Bag and Canvas Mills',
    BEA_CODES: { '314900': 1.0 },
  },
  ['314994']: {
    label: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills',
    BEA_CODES: { '314900': 1.0 },
  },
  ['314999']: {
    label: 'All Other Miscellaneous Textile Product Mills',
    BEA_CODES: { '314900': 1.0 },
  },
  ['315120']: { label: 'Apparel Knitting Mills', BEA_CODES: { '315000': 1.0 } },
  ['315210']: {
    label: 'Cut and Sew Apparel Contractors',
    BEA_CODES: { '315000': 1.0 },
  },
  ['315250']: {
    label: 'Cut and Sew Apparel Manufacturing (except Contractors)',
    BEA_CODES: { '315000': 1.0 },
  },
  ['315990']: {
    label: 'Apparel Accessories and Other Apparel Manufacturing',
    BEA_CODES: { '315000': 1.0 },
  },
  ['316110']: {
    label: 'Leather and Hide Tanning and Finishing',
    BEA_CODES: { '316000': 1.0 },
  },
  ['316210']: { label: 'Footwear Manufacturing', BEA_CODES: { '316000': 1.0 } },
  ['316990']: {
    label: 'Other Leather and Allied Product Manufacturing',
    BEA_CODES: { '316000': 1.0 },
  },
  ['321113']: { label: 'Sawmills', BEA_CODES: { '321100': 1.0 } },
  ['321114']: { label: 'Wood Preservation', BEA_CODES: { '321100': 1.0 } },
  ['321211']: {
    label: 'Hardwood Veneer and Plywood Manufacturing',
    BEA_CODES: { '321200': 1.0 },
  },
  ['321212']: {
    label: 'Softwood Veneer and Plywood Manufacturing',
    BEA_CODES: { '321200': 1.0 },
  },
  ['321215']: {
    label: 'Engineered Wood Member Manufacturing',
    BEA_CODES: { '321200': 1.0 },
  },
  ['321219']: {
    label: 'Reconstituted Wood Product Manufacturing',
    BEA_CODES: { '321200': 1.0 },
  },
  ['321911']: {
    label: 'Wood Window and Door Manufacturing',
    BEA_CODES: { '321910': 1.0 },
  },
  ['321912']: {
    label: 'Cut Stock, Resawing Lumber, and Planing',
    BEA_CODES: { '321910': 1.0 },
  },
  ['321918']: {
    label: 'Other Millwork (including Flooring)',
    BEA_CODES: { '321910': 1.0 },
  },
  ['321920']: {
    label: 'Wood Container and Pallet Manufacturing',
    BEA_CODES: { '3219A0': 1.0 },
  },
  ['321991']: {
    label: 'Manufactured Home (Mobile Home) Manufacturing',
    BEA_CODES: { '3219A0': 1.0 },
  },
  ['321992']: {
    label: 'Prefabricated Wood Building Manufacturing',
    BEA_CODES: { '3219A0': 1.0 },
  },
  ['321999']: {
    label: 'All Other Miscellaneous Wood Product Manufacturing',
    BEA_CODES: { '3219A0': 1.0 },
  },
  ['322110']: { label: 'Pulp Mills', BEA_CODES: { '322110': 1.0 } },
  ['322120']: { label: 'Paper Mills', BEA_CODES: { '322120': 1.0 } },
  ['322130']: { label: 'Paperboard Mills', BEA_CODES: { '322130': 1.0 } },
  ['322211']: {
    label: 'Corrugated and Solid Fiber Box Manufacturing',
    BEA_CODES: { '322210': 1.0 },
  },
  ['322212']: {
    label: 'Folding Paperboard Box Manufacturing',
    BEA_CODES: { '322210': 1.0 },
  },
  ['322219']: {
    label: 'Other Paperboard Container Manufacturing',
    BEA_CODES: { '322210': 1.0 },
  },
  ['322220']: {
    label: 'Paper Bag and Coated and Treated Paper Manufacturing',
    BEA_CODES: { '322220': 1.0 },
  },
  ['322230']: {
    label: 'Stationery Product Manufacturing',
    BEA_CODES: { '322230': 1.0 },
  },
  ['322291']: {
    label: 'Sanitary Paper Product Manufacturing',
    BEA_CODES: { '322291': 1.0 },
  },
  ['322299']: {
    label: 'All Other Converted Paper Product Manufacturing',
    BEA_CODES: { '322299': 1.0 },
  },
  ['323111']: {
    label: 'Commercial Printing (except Screen and Books)',
    BEA_CODES: { '323110': 1.0 },
  },
  ['323113']: {
    label: 'Commercial Screen Printing',
    BEA_CODES: { '323110': 1.0 },
  },
  ['323117']: { label: 'Books Printing', BEA_CODES: { '323110': 1.0 } },
  ['323120']: {
    label: 'Support Activites for Printing',
    BEA_CODES: { '323120': 1.0 },
  },
  ['324110']: { label: 'Petroleum Refineries', BEA_CODES: { '324110': 1.0 } },
  ['324121']: {
    label: 'Asphalt Paving Mixture and Block Manufacturing',
    BEA_CODES: { '324121': 1.0 },
  },
  ['324122']: {
    label: 'Asphalt Shingle and Coating Materials Manufacturing',
    BEA_CODES: { '324122': 1.0 },
  },
  ['324191']: {
    label: 'Petroleum Lubricating Oil and Grease Manufacturing',
    BEA_CODES: { '324190': 1.0 },
  },
  ['324199']: {
    label: 'All Other Petroleum and Coal Products Manufacturing',
    BEA_CODES: { '324190': 1.0 },
  },
  ['325110']: {
    label: 'Petrochemical Manufacturing',
    BEA_CODES: { '325110': 1.0 },
  },
  ['325120']: {
    label: 'Industrial Gas Manufacturing',
    BEA_CODES: { '325120': 1.0 },
  },
  ['325130']: {
    label: 'Synthetic Dye and Pigment Manufacturing',
    BEA_CODES: { '325130': 1.0 },
  },
  ['325180']: {
    label: 'Other Basic Inorganic Chemical Manufacturing',
    BEA_CODES: { '325180': 1.0 },
  },
  ['325193']: {
    label: 'Ethyl Alcohol Manufacturing',
    BEA_CODES: { '325190': 1.0 },
  },
  ['325194']: {
    label:
      'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing',
    BEA_CODES: { '325190': 1.0 },
  },
  ['325199']: {
    label: 'All Other Basic Organic Chemical Manufacturing',
    BEA_CODES: { '325190': 1.0 },
  },
  ['325211']: {
    label: 'Plastics Material and Resin Manufacturing',
    BEA_CODES: { '325211': 1.0 },
  },
  ['325212']: {
    label: 'Synthetic Rubber Manufacturing',
    BEA_CODES: { '3252A0': 1.0 },
  },
  ['325220']: {
    label: 'Artificial and Synthetic Fibers and Filaments Manufacturing',
    BEA_CODES: { '3252A0': 1.0 },
  },
  ['325311']: {
    label: 'Nitrogenous Fertilizer Manufacturing',
    BEA_CODES: { '325310': 1.0 },
  },
  ['325312']: {
    label: 'Phosphatic Fertilizer Manufacturing',
    BEA_CODES: { '325310': 1.0 },
  },
  ['325314']: {
    label: 'Fertilizer (Mixing Only) Manufacturing',
    BEA_CODES: { '325310': 1.0 },
  },
  ['325315']: { label: 'Compost Manufacturing', BEA_CODES: { '325310': 1.0 } },
  ['325320']: {
    label: 'Pesticide and Other Agricultural Chemical Manufacturing',
    BEA_CODES: { '325320': 1.0 },
  },
  ['325411']: {
    label: 'Medicinal and Botanical Manufacturing',
    BEA_CODES: { '325411': 1.0 },
  },
  ['325412']: {
    label: 'Pharmaceutical Preparation Manufacturing',
    BEA_CODES: { '325412': 1.0 },
  },
  ['325413']: {
    label: 'In-Vitro Diagnostic Substance Manufacturing',
    BEA_CODES: { '325413': 1.0 },
  },
  ['325414']: {
    label: 'Biological Product (except Diagnostic) Manufacturing',
    BEA_CODES: { '325414': 1.0 },
  },
  ['325510']: {
    label: 'Paint and Coating Manufacturing',
    BEA_CODES: { '325510': 1.0 },
  },
  ['325520']: { label: 'Adhesive Manufacturing', BEA_CODES: { '325520': 1.0 } },
  ['325611']: {
    label: 'Soap and Other Detergent Manufacturing',
    BEA_CODES: { '325610': 1.0 },
  },
  ['325612']: {
    label: 'Polish and Other Sanitation Good Manufacturing',
    BEA_CODES: { '325610': 1.0 },
  },
  ['325613']: {
    label: 'Surface Active Agent Manufacturing',
    BEA_CODES: { '325610': 1.0 },
  },
  ['325620']: {
    label: 'Toilet Preparation Manufacturing',
    BEA_CODES: { '325620': 1.0 },
  },
  ['325910']: {
    label: 'Printing Ink Manufacturing',
    BEA_CODES: { '325910': 1.0 },
  },
  ['325920']: {
    label: 'Explosives Manufacturing',
    BEA_CODES: { '3259A0': 1.0 },
  },
  ['325991']: {
    label: 'Custom Compounding of Purchased Resins',
    BEA_CODES: { '3259A0': 1.0 },
  },
  ['325992']: {
    label:
      'Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing',
    BEA_CODES: { '3259A0': 1.0 },
  },
  ['325998']: {
    label:
      'All Other Miscellaneous Chemical Product and Preparation Manufacturing',
    BEA_CODES: { '3259A0': 1.0 },
  },
  ['326111']: {
    label: 'Plastics Bag and Pouch Manufacturing',
    BEA_CODES: { '326110': 1.0 },
  },
  ['326112']: {
    label:
      'Plastics Packaging Film and Sheet (including Laminated) Manufacturing',
    BEA_CODES: { '326110': 1.0 },
  },
  ['326113']: {
    label:
      'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing',
    BEA_CODES: { '326110': 1.0 },
  },
  ['326121']: {
    label: 'Unlaminated Plastics Profile Shape Manufacturing',
    BEA_CODES: { '326120': 1.0 },
  },
  ['326122']: {
    label: 'Plastics Pipe and Pipe Fitting Manufacturing',
    BEA_CODES: { '326120': 1.0 },
  },
  ['326130']: {
    label:
      'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing',
    BEA_CODES: { '326130': 1.0 },
  },
  ['326140']: {
    label: 'Polystyrene Foam Product Manufacturing',
    BEA_CODES: { '326140': 1.0 },
  },
  ['326150']: {
    label: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing',
    BEA_CODES: { '326150': 1.0 },
  },
  ['326160']: {
    label: 'Plastics Bottle Manufacturing',
    BEA_CODES: { '326160': 1.0 },
  },
  ['326191']: {
    label: 'Plastics Plumbing Fixture Manufacturing',
    BEA_CODES: { '326190': 1.0 },
  },
  ['326199']: {
    label: 'All Other Plastics Product Manufacturing',
    BEA_CODES: { '326190': 1.0 },
  },
  ['326211']: {
    label: 'Tire Manufacturing (except Retreading)',
    BEA_CODES: { '326210': 1.0 },
  },
  ['326212']: { label: 'Tire Retreading', BEA_CODES: { '326210': 1.0 } },
  ['326220']: {
    label: 'Rubber and Plastics Hoses and Belting Manufacturing',
    BEA_CODES: { '326220': 1.0 },
  },
  ['326291']: {
    label: 'Rubber Product Manufacturing for Mechanical Use',
    BEA_CODES: { '326290': 1.0 },
  },
  ['326299']: {
    label: 'All Other Rubber Product Manufacturing',
    BEA_CODES: { '326290': 1.0 },
  },
  ['327110']: {
    label: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing',
    BEA_CODES: { '327100': 1.0 },
  },
  ['327120']: {
    label: 'Clay Building Material and Refractories Manufacturing',
    BEA_CODES: { '327100': 1.0 },
  },
  ['327211']: {
    label: 'Flat Glass Manufacturing',
    BEA_CODES: { '327200': 1.0 },
  },
  ['327212']: {
    label: 'Other Pressed and Blown Glass and Glassware Manufacturing',
    BEA_CODES: { '327200': 1.0 },
  },
  ['327213']: {
    label: 'Glass Container Manufacturing',
    BEA_CODES: { '327200': 1.0 },
  },
  ['327215']: {
    label: 'Glass Product Manufacturing Made of Purchased Glass',
    BEA_CODES: { '327200': 1.0 },
  },
  ['327310']: { label: 'Cement Manufacturing', BEA_CODES: { '327310': 1.0 } },
  ['327320']: {
    label: 'Ready-Mix Concrete Manufacturing',
    BEA_CODES: { '327320': 1.0 },
  },
  ['327331']: {
    label: 'Concrete Block and Brick Manufacturing',
    BEA_CODES: { '327330': 1.0 },
  },
  ['327332']: {
    label: 'Concrete Pipe Manufacturing',
    BEA_CODES: { '327330': 1.0 },
  },
  ['327390']: {
    label: 'Other Concrete Product Manufacturing',
    BEA_CODES: { '327390': 1.0 },
  },
  ['327410']: { label: 'Lime Manufacturing', BEA_CODES: { '327400': 1.0 } },
  ['327420']: {
    label: 'Gypsum Product Manufacturing',
    BEA_CODES: { '327400': 1.0 },
  },
  ['327910']: {
    label: 'Abrasive Product Manufacturing',
    BEA_CODES: { '327910': 1.0 },
  },
  ['327991']: {
    label: 'Cut Stone and Stone Product Manufacturing',
    BEA_CODES: { '327991': 1.0 },
  },
  ['327992']: {
    label: 'Ground or Treated Mineral and Earth Manufacturing',
    BEA_CODES: { '327992': 1.0 },
  },
  ['327993']: {
    label: 'Mineral Wool Manufacturing',
    BEA_CODES: { '327993': 1.0 },
  },
  ['327999']: {
    label: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing',
    BEA_CODES: { '327999': 1.0 },
  },
  ['331110']: {
    label: 'Iron and Steel Mills and Ferroalloy Manufacturing',
    BEA_CODES: { '331110': 1.0 },
  },
  ['331210']: {
    label: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel',
    BEA_CODES: { '331200': 1.0 },
  },
  ['331221']: {
    label: 'Rolled Steel Shape Manufacturing',
    BEA_CODES: { '331200': 1.0 },
  },
  ['331222']: { label: 'Steel Wire Drawing', BEA_CODES: { '331200': 1.0 } },
  ['331313']: {
    label: 'Alumina Refining and Primary Aluminum Production',
    BEA_CODES: { '331313': 1.0 },
  },
  ['331315']: {
    label: 'Aluminum Sheet, Plate, and Foil Manufacturing',
    BEA_CODES: { '33131B': 1.0 },
  },
  ['331318']: {
    label: 'Other Aluminum Rolling, Drawing, and Extruding',
    BEA_CODES: { '33131B': 1.0 },
  },
  ['331410']: {
    label: 'Nonferrous Metal (except Aluminum) Smelting and Refining',
    BEA_CODES: { '331410': 1.0 },
  },
  ['331420']: {
    label: 'Copper Rolling, Drawing, Extruding, and Alloying',
    BEA_CODES: { '331420': 1.0 },
  },
  ['331491']: {
    label:
      'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding',
    BEA_CODES: { '331490': 1.0 },
  },
  ['331492']: {
    label:
      'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)',
    BEA_CODES: { '331490': 1.0 },
  },
  ['331511']: { label: 'Iron Foundries', BEA_CODES: { '331510': 1.0 } },
  ['331512']: {
    label: 'Steel Investment Foundries',
    BEA_CODES: { '331510': 1.0 },
  },
  ['331513']: {
    label: 'Steel Foundries (except Investment)',
    BEA_CODES: { '331510': 1.0 },
  },
  ['331523']: {
    label: 'Nonferrous Metal Die-Casting Foundries',
    BEA_CODES: { '331520': 1.0 },
  },
  ['331524']: {
    label: 'Aluminum Foundries (except Die-Casting)',
    BEA_CODES: { '331520': 1.0 },
  },
  ['331529']: {
    label: 'Other Nonferrous Metal Foundries (except Die-Casting)',
    BEA_CODES: { '331520': 1.0 },
  },
  ['332111']: { label: 'Iron and Steel Forging', BEA_CODES: { '33211A': 1.0 } },
  ['332112']: { label: 'Nonferrous Forging', BEA_CODES: { '33211A': 1.0 } },
  ['332114']: { label: 'Custom Roll Forming', BEA_CODES: { '332114': 1.0 } },
  ['332117']: {
    label: 'Powder Metallurgy Part Manufacturing',
    BEA_CODES: { '33211A': 1.0 },
  },
  ['332119']: {
    label: 'Metal Crown, Closure, and Other Metal Stamping (except Automotive)',
    BEA_CODES: { '332119': 1.0 },
  },
  ['332215']: {
    label:
      'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing',
    BEA_CODES: { '332200': 1.0 },
  },
  ['332216']: {
    label: 'Saw Blade and Handtool Manufacturing',
    BEA_CODES: { '332200': 1.0 },
  },
  ['332311']: {
    label: 'Prefabricated Metal Building and Component Manufacturing',
    BEA_CODES: { '332310': 1.0 },
  },
  ['332312']: {
    label: 'Fabricated Structural Metal Manufacturing',
    BEA_CODES: { '332310': 1.0 },
  },
  ['332313']: {
    label: 'Plate Work Manufacturing',
    BEA_CODES: { '332310': 1.0 },
  },
  ['332321']: {
    label: 'Metal Window and Door Manufacturing',
    BEA_CODES: { '332320': 1.0 },
  },
  ['332322']: {
    label: 'Sheet Metal Work Manufacturing',
    BEA_CODES: { '332320': 1.0 },
  },
  ['332323']: {
    label: 'Ornamental and Architectural Metal Work Manufacturing',
    BEA_CODES: { '332320': 1.0 },
  },
  ['332410']: {
    label: 'Power Boiler and Heat Exchanger Manufacturing',
    BEA_CODES: { '332410': 1.0 },
  },
  ['332420']: {
    label: 'Metal Tank (Heavy Gauge) Manufacturing',
    BEA_CODES: { '332420': 1.0 },
  },
  ['332431']: {
    label: 'Metal Can Manufacturing',
    BEA_CODES: { '332430': 1.0 },
  },
  ['332439']: {
    label: 'Other Metal Container Manufacturing',
    BEA_CODES: { '332430': 1.0 },
  },
  ['332510']: { label: 'Hardware Manufacturing', BEA_CODES: { '332500': 1.0 } },
  ['332613']: { label: 'Spring Manufacturing', BEA_CODES: { '332600': 1.0 } },
  ['332618']: {
    label: 'Other Fabricated Wire Product Manufacturing',
    BEA_CODES: { '332600': 1.0 },
  },
  ['332710']: { label: 'Machine Shops', BEA_CODES: { '332710': 1.0 } },
  ['332721']: {
    label: 'Precision Turned Product Manufacturing',
    BEA_CODES: { '332720': 1.0 },
  },
  ['332722']: {
    label: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing',
    BEA_CODES: { '332720': 1.0 },
  },
  ['332811']: { label: 'Metal Heat Treating', BEA_CODES: { '332800': 1.0 } },
  ['332812']: {
    label:
      'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers',
    BEA_CODES: { '332800': 1.0 },
  },
  ['332813']: {
    label: 'Electroplating, Plating, Polishing, Anodizing, and Coloring',
    BEA_CODES: { '332800': 1.0 },
  },
  ['332911']: {
    label: 'Industrial Valve Manufacturing',
    BEA_CODES: { '33291A': 1.0 },
  },
  ['332912']: {
    label: 'Fluid Power Valve and Hose Fitting Manufacturing',
    BEA_CODES: { '33291A': 1.0 },
  },
  ['332913']: {
    label: 'Plumbing Fixture Fitting and Trim Manufacturing',
    BEA_CODES: { '332913': 1.0 },
  },
  ['332919']: {
    label: 'Other Metal Valve and Pipe Fitting Manufacturing',
    BEA_CODES: { '33291A': 1.0 },
  },
  ['332991']: {
    label: 'Ball and Roller Bearing Manufacturing',
    BEA_CODES: { '332991': 1.0 },
  },
  ['332992']: {
    label: 'Small Arms Ammunition Manufacturing',
    BEA_CODES: { '33299A': 1.0 },
  },
  ['332993']: {
    label: 'Ammunition (except Small Arms) Manufacturing',
    BEA_CODES: { '33299A': 1.0 },
  },
  ['332994']: {
    label: 'Small Arms, Ordnance, and Ordnance Accessories Manufacturing',
    BEA_CODES: { '33299A': 1.0 },
  },
  ['332996']: {
    label: 'Fabricated Pipe and Pipe Fitting Manufacturing',
    BEA_CODES: { '332996': 1.0 },
  },
  ['332999']: {
    label: 'All Other Miscellaneous Fabricated Metal Product Manufacturing',
    BEA_CODES: { '332999': 1.0 },
  },
  ['333111']: {
    label: 'Farm Machinery and Equipment Manufacturing',
    BEA_CODES: { '333111': 1.0 },
  },
  ['333112']: {
    label:
      'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing',
    BEA_CODES: { '333112': 1.0 },
  },
  ['333120']: {
    label: 'Construction Machinery Manufacturing',
    BEA_CODES: { '333120': 1.0 },
  },
  ['333131']: {
    label: 'Mining Machinery and Equipment Manufacturing',
    BEA_CODES: { '333130': 1.0 },
  },
  ['333132']: {
    label: 'Oil and Gas Field Machinery and Equipment Manufacturing',
    BEA_CODES: { '333130': 1.0 },
  },
  ['333241']: {
    label: 'Food Product Machinery Manufacturing',
    BEA_CODES: { '33329A': 1.0 },
  },
  ['333242']: {
    label: 'Semiconductor Machinery Manufacturing',
    BEA_CODES: { '333242': 1.0 },
  },
  ['333243']: {
    label: 'Sawmill, Woodworking, and Paper Machinery Manufacturing',
    BEA_CODES: { '33329A': 1.0 },
  },
  ['333248']: {
    label: 'All Other Industrial Machinery Manufacturing',
    BEA_CODES: { '33329A': 1.0 },
  },
  ['333310']: {
    label: 'Commercial and Service Industry Machinery Manufacturing',
    BEA_CODES: { '333314': 0.209136, '333316': 0.0761472, '333318': 0.7147168 },
  },
  ['333413']: {
    label:
      'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing',
    BEA_CODES: { '333413': 1.0 },
  },
  ['333414']: {
    label: 'Heating Equipment (except Warm Air Furnaces) Manufacturing',
    BEA_CODES: { '333414': 1.0 },
  },
  ['333415']: {
    label:
      'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing',
    BEA_CODES: { '333415': 1.0 },
  },
  ['333511']: {
    label: 'Industrial Mold Manufacturing',
    BEA_CODES: { '333511': 1.0 },
  },
  ['333514']: {
    label: 'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing',
    BEA_CODES: { '333514': 1.0 },
  },
  ['333515']: {
    label: 'Cutting Tool and Machine Tool Accessory Manufacturing',
    BEA_CODES: { '33351B': 1.0 },
  },
  ['333517']: {
    label: 'Machine Tool Manufacturing',
    BEA_CODES: { '333517': 1.0 },
  },
  ['333519']: {
    label: 'Rolling Mill and Other Metalworking Machinery Manufacturing',
    BEA_CODES: { '33351B': 1.0 },
  },
  ['333611']: {
    label: 'Turbine and Turbine Generator Set Units Manufacturing',
    BEA_CODES: { '333611': 1.0 },
  },
  ['333612']: {
    label: 'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing',
    BEA_CODES: { '333612': 1.0 },
  },
  ['333613']: {
    label: 'Mechanical Power Transmission Equipment Manufacturing',
    BEA_CODES: { '333613': 1.0 },
  },
  ['333618']: {
    label: 'Other Engine Equipment Manufacturing',
    BEA_CODES: { '333618': 1.0 },
  },
  ['333912']: {
    label: 'Air and Gas Compressor Manufacturing',
    BEA_CODES: { '333912': 1.0 },
  },
  ['333914']: {
    label: 'Measuring, Dispensing, and Other Pumping Equipment Manufacturing',
    BEA_CODES: { '33391A': 1.0 },
  },
  ['333921']: {
    label: 'Elevator and Moving Stairway Manufacturing',
    BEA_CODES: { '333920': 1.0 },
  },
  ['333922']: {
    label: 'Conveyor and Conveying Equipment Manufacturing',
    BEA_CODES: { '333920': 1.0 },
  },
  ['333923']: {
    label: 'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing',
    BEA_CODES: { '333920': 1.0 },
  },
  ['333924']: {
    label:
      'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing',
    BEA_CODES: { '333920': 1.0 },
  },
  ['333991']: {
    label: 'Power-Driven Handtool Manufacturing',
    BEA_CODES: { '333991': 1.0 },
  },
  ['333992']: {
    label: 'Welding and Soldering Equipment Manufacturing',
    BEA_CODES: { '33399A': 1.0 },
  },
  ['333993']: {
    label: 'Packaging Machinery Manufacturing',
    BEA_CODES: { '333993': 1.0 },
  },
  ['333994']: {
    label: 'Industrial Process Furnace and Oven Manufacturing',
    BEA_CODES: { '333994': 1.0 },
  },
  ['333995']: {
    label: 'Fluid Power Cylinder and Actuator Manufacturing',
    BEA_CODES: { '33399B': 1.0 },
  },
  ['333996']: {
    label: 'Fluid Power Pump and Motor Manufacturing',
    BEA_CODES: { '33399B': 1.0 },
  },
  ['333998']: {
    label: 'All Other Miscellaneous General Purpose Machinery Manufacturing',
    BEA_CODES: { '33399A': 1.0 },
  },
  ['334111']: {
    label: 'Electronic Computer Manufacturing',
    BEA_CODES: { '334111': 1.0 },
  },
  ['334112']: {
    label: 'Computer Storage Device Manufacturing',
    BEA_CODES: { '334112': 1.0 },
  },
  ['334118']: {
    label:
      'Computer Terminal and Other Computer Peripheral Equipment Manufacturing',
    BEA_CODES: { '334118': 1.0 },
  },
  ['334210']: {
    label: 'Telephone Apparatus Manufacturing',
    BEA_CODES: { '334210': 1.0 },
  },
  ['334220']: {
    label:
      'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing',
    BEA_CODES: { '334220': 1.0 },
  },
  ['334290']: {
    label: 'Other Communications Equipment Manufacturing',
    BEA_CODES: { '334290': 1.0 },
  },
  ['334310']: {
    label: 'Audio and Video Equipment Manufacturing',
    BEA_CODES: { '334300': 1.0 },
  },
  ['334412']: {
    label: 'Bare Printed Circuit Board Manufacturing',
    BEA_CODES: { '33441A': 1.0 },
  },
  ['334413']: {
    label: 'Semiconductor and Related Device Manufacturing',
    BEA_CODES: { '334413': 1.0 },
  },
  ['334416']: {
    label:
      'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing',
    BEA_CODES: { '33441A': 1.0 },
  },
  ['334417']: {
    label: 'Electronic Connector Manufacturing',
    BEA_CODES: { '33441A': 1.0 },
  },
  ['334418']: {
    label: 'Printed Circuit Assembly (Electronic Assembly) Manufacturing',
    BEA_CODES: { '334418': 1.0 },
  },
  ['334419']: {
    label: 'Other Electronic Component Manufacturing',
    BEA_CODES: { '33441A': 1.0 },
  },
  ['334510']: {
    label: 'Electromedical and Electrotherapeutic Apparatus Manufacturing',
    BEA_CODES: { '334510': 1.0 },
  },
  ['334511']: {
    label:
      'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing',
    BEA_CODES: { '334511': 1.0 },
  },
  ['334512']: {
    label:
      'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use',
    BEA_CODES: { '334512': 1.0 },
  },
  ['334513']: {
    label:
      'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables',
    BEA_CODES: { '334513': 1.0 },
  },
  ['334514']: {
    label: 'Totalizing Fluid Meter and Counting Device Manufacturing',
    BEA_CODES: { '334514': 1.0 },
  },
  ['334515']: {
    label:
      'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals',
    BEA_CODES: { '334515': 1.0 },
  },
  ['334516']: {
    label: 'Analytical Laboratory Instrument Manufacturing',
    BEA_CODES: { '334516': 1.0 },
  },
  ['334517']: {
    label: 'Irradiation Apparatus Manufacturing',
    BEA_CODES: { '334517': 1.0 },
  },
  ['334519']: {
    label: 'Other Measuring and Controlling Device Manufacturing',
    BEA_CODES: { '33451A': 1.0 },
  },
  ['334610']: {
    label: 'Manufacturing and Reproducing Magnetic and Optical Media',
    BEA_CODES: { '334610': 1.0 },
  },
  ['335131']: {
    label: 'Residential Electric Lighting Fixture Manufacturing',
    BEA_CODES: { '335120': 1.0 },
  },
  ['335132']: {
    label:
      'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing',
    BEA_CODES: { '335120': 1.0 },
  },
  ['335139']: {
    label: 'Electric Lamp Bulb and Other Lighting Equipment Manufacturing',
    BEA_CODES: { '335110': 0.1507373, '335120': 0.8492627 },
  },
  ['335210']: {
    label: 'Small Electrical Appliance Manufacturing',
    BEA_CODES: { '335210': 1.0 },
  },
  ['335220']: {
    label: 'Major Household Appliance Manufacturing',
    BEA_CODES: {
      '335221': 0.276561,
      '335222': 0.2200188,
      '335224': 0.2266709,
      '335228': 0.2767493,
    },
  },
  ['335311']: {
    label: 'Power, Distribution, and Specialty Transformer Manufacturing',
    BEA_CODES: { '335311': 1.0 },
  },
  ['335312']: {
    label: 'Motor and Generator Manufacturing',
    BEA_CODES: { '335312': 1.0 },
  },
  ['335313']: {
    label: 'Switchgear and Switchboard Apparatus Manufacturing',
    BEA_CODES: { '335313': 1.0 },
  },
  ['335314']: {
    label: 'Relay and Industrial Control Manufacturing',
    BEA_CODES: { '335314': 1.0 },
  },
  ['335910']: {
    label: 'Battery Manufacturing',
    BEA_CODES: { '335911': 0.587675, '335912': 0.412325 },
  },
  ['335921']: {
    label: 'Fiber Optic Cable Manufacturing',
    BEA_CODES: { '335920': 1.0 },
  },
  ['335929']: {
    label: 'Other Communication and Energy Wire Manufacturing',
    BEA_CODES: { '335920': 1.0 },
  },
  ['335931']: {
    label: 'Current-Carrying Wiring Device Manufacturing',
    BEA_CODES: { '335930': 1.0 },
  },
  ['335932']: {
    label: 'Noncurrent-Carrying Wiring Device Manufacturing',
    BEA_CODES: { '335930': 1.0 },
  },
  ['335991']: {
    label: 'Carbon and Graphite Product Manufacturing',
    BEA_CODES: { '335991': 1.0 },
  },
  ['335999']: {
    label:
      'All Other Miscellaneous Electrical Equipment and Component Manufacturing',
    BEA_CODES: { '335999': 1.0 },
  },
  ['336110']: {
    label: 'Automobile and Light Duty Motor Vehicle Manufacturing',
    BEA_CODES: { '336111': 0.2416341, '336112': 0.7583659 },
  },
  ['336120']: {
    label: 'Heavy Duty Truck Manufacturing',
    BEA_CODES: { '336120': 1.0 },
  },
  ['336211']: {
    label: 'Motor Vehicle Body Manufacturing',
    BEA_CODES: { '336211': 1.0 },
  },
  ['336212']: {
    label: 'Truck Trailer Manufacturing',
    BEA_CODES: { '336212': 1.0 },
  },
  ['336213']: {
    label: 'Motor Home Manufacturing',
    BEA_CODES: { '336213': 1.0 },
  },
  ['336214']: {
    label: 'Travel Trailer and Camper Manufacturing',
    BEA_CODES: { '336214': 1.0 },
  },
  ['336310']: {
    label: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
    BEA_CODES: { '336310': 1.0 },
  },
  ['336320']: {
    label: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
    BEA_CODES: { '336320': 1.0 },
  },
  ['336330']: {
    label:
      'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
    BEA_CODES: { '3363A0': 1.0 },
  },
  ['336340']: {
    label: 'Motor Vehicle Brake System Manufacturing',
    BEA_CODES: { '3363A0': 1.0 },
  },
  ['336350']: {
    label: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
    BEA_CODES: { '336350': 1.0 },
  },
  ['336360']: {
    label: 'Motor Vehicle Seating and Interior Trim Manufacturing',
    BEA_CODES: { '336360': 1.0 },
  },
  ['336370']: {
    label: 'Motor Vehicle Metal Stamping',
    BEA_CODES: { '336370': 1.0 },
  },
  ['336390']: {
    label: 'Other Motor Vehicle Parts Manufacturing',
    BEA_CODES: { '336390': 1.0 },
  },
  ['336411']: { label: 'Aircraft Manufacturing', BEA_CODES: { '336411': 1.0 } },
  ['336412']: {
    label: 'Aircraft Engine and Engine Parts Manufacturing',
    BEA_CODES: { '336412': 1.0 },
  },
  ['336413']: {
    label: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing',
    BEA_CODES: { '336413': 1.0 },
  },
  ['336414']: {
    label: 'Guided Missile and Space Vehicle Manufacturing',
    BEA_CODES: { '336414': 1.0 },
  },
  ['336415']: {
    label:
      'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing',
    BEA_CODES: { '33641A': 1.0 },
  },
  ['336419']: {
    label:
      'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing',
    BEA_CODES: { '33641A': 1.0 },
  },
  ['336510']: {
    label: 'Railroad Rolling Stock Manufacturing',
    BEA_CODES: { '336500': 1.0 },
  },
  ['336611']: {
    label: 'Ship Building and Repairing',
    BEA_CODES: { '336611': 1.0 },
  },
  ['336612']: { label: 'Boat Building', BEA_CODES: { '336612': 1.0 } },
  ['336991']: {
    label: 'Motorcycle, Bicycle, and Parts Manufacturing',
    BEA_CODES: { '336991': 1.0 },
  },
  ['336992']: {
    label: 'Military Armored Vehicle, Tank, and Tank Component Manufacturing',
    BEA_CODES: { '336992': 1.0 },
  },
  ['336999']: {
    label: 'All Other Transportation Equipment Manufacturing',
    BEA_CODES: { '336999': 1.0 },
  },
  ['337110']: {
    label: 'Wood Kitchen Cabinet and Countertop Manufacturing',
    BEA_CODES: { '337110': 1.0 },
  },
  ['337121']: {
    label: 'Upholstered Household Furniture Manufacturing',
    BEA_CODES: { '337121': 1.0 },
  },
  ['337122']: {
    label: 'Nonupholstered Wood Household Furniture Manufacturing',
    BEA_CODES: { '337122': 1.0 },
  },
  ['337126']: {
    label: 'Household Furniture (except Wood and Upholstered) Manufacturing',
    BEA_CODES: { '33712N': 1.0 },
  },
  ['337127']: {
    label: 'Institutional Furniture Manufacturing',
    BEA_CODES: { '337127': 1.0 },
  },
  ['337211']: {
    label: 'Wood Office Furniture Manufacturing',
    BEA_CODES: { '33721A': 1.0 },
  },
  ['337212']: {
    label: 'Custom Architectural Woodwork and Millwork Manufacturing',
    BEA_CODES: { '33721A': 1.0 },
  },
  ['337214']: {
    label: 'Office Furniture (except Wood) Manufacturing',
    BEA_CODES: { '33721A': 1.0 },
  },
  ['337215']: {
    label: 'Showcase, Partition, Shelving, and Locker Manufacturing',
    BEA_CODES: { '337215': 1.0 },
  },
  ['337910']: { label: 'Mattress Manufacturing', BEA_CODES: { '337900': 1.0 } },
  ['337920']: {
    label: 'Blind and Shade Manufacturing',
    BEA_CODES: { '337900': 1.0 },
  },
  ['339112']: {
    label: 'Surgical and Medical Instrument Manufacturing',
    BEA_CODES: { '339112': 1.0 },
  },
  ['339113']: {
    label: 'Surgical Appliance and Supplies Manufacturing',
    BEA_CODES: { '339113': 1.0 },
  },
  ['339114']: {
    label: 'Dental Equipment and Supplies Manufacturing',
    BEA_CODES: { '339114': 1.0 },
  },
  ['339115']: {
    label: 'Ophthalmic Goods Manufacturing',
    BEA_CODES: { '339115': 1.0 },
  },
  ['339116']: { label: 'Dental Laboratories', BEA_CODES: { '339116': 1.0 } },
  ['339910']: {
    label: 'Jewelry and Silverware Manufacturing',
    BEA_CODES: { '339910': 1.0 },
  },
  ['339920']: {
    label: 'Sporting and Athletic Goods Manufacturing',
    BEA_CODES: { '339920': 1.0 },
  },
  ['339930']: {
    label: 'Doll, Toy, and Game Manufacturing',
    BEA_CODES: { '339930': 1.0 },
  },
  ['339940']: {
    label: 'Office Supplies (except Paper) Manufacturing',
    BEA_CODES: { '339940': 1.0 },
  },
  ['339950']: { label: 'Sign Manufacturing', BEA_CODES: { '339950': 1.0 } },
  ['339991']: {
    label: 'Gasket, Packing, and Sealing Device Manufacturing',
    BEA_CODES: { '339990': 1.0 },
  },
  ['339992']: {
    label: 'Musical Instrument Manufacturing',
    BEA_CODES: { '339990': 1.0 },
  },
  ['339993']: {
    label: 'Fastener, Button, Needle, and Pin Manufacturing',
    BEA_CODES: { '339990': 1.0 },
  },
  ['339994']: {
    label: 'Broom, Brush, and Mop Manufacturing',
    BEA_CODES: { '339990': 1.0 },
  },
  ['339995']: {
    label: 'Burial Casket Manufacturing',
    BEA_CODES: { '339990': 1.0 },
  },
  ['339999']: {
    label: 'All Other Miscellaneous Manufacturing',
    BEA_CODES: { '339990': 1.0 },
  },
  ['423110']: {
    label: 'Automobile and Other Motor Vehicle Merchant Wholesalers',
    BEA_CODES: { '423100': 1.0 },
  },
  ['423120']: {
    label: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
    BEA_CODES: { '423100': 1.0 },
  },
  ['423130']: {
    label: 'Tire and Tube Merchant Wholesalers',
    BEA_CODES: { '423100': 1.0 },
  },
  ['423140']: {
    label: 'Motor Vehicle Parts (Used) Merchant Wholesalers',
    BEA_CODES: { '423100': 1.0 },
  },
  ['423210']: {
    label: 'Furniture Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423220']: {
    label: 'Home Furnishing Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423310']: {
    label: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423320']: {
    label:
      'Brick, Stone, and Related Construction Material Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423330']: {
    label: 'Roofing, Siding, and Insulation Material Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423390']: {
    label: 'Other Construction Material Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423410']: {
    label: 'Photographic Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423420']: {
    label: 'Office Equipment Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423430']: {
    label:
      'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423440']: {
    label: 'Other Commercial Equipment Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423450']: {
    label:
      'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423460']: {
    label: 'Ophthalmic Goods Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423490']: {
    label: 'Other Professional Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423400': 1.0 },
  },
  ['423510']: {
    label: 'Metal Service Centers and Other Metal Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423520']: {
    label: 'Coal and Other Mineral and Ore Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423610']: {
    label:
      'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers',
    BEA_CODES: { '423600': 1.0 },
  },
  ['423620']: {
    label:
      'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers',
    BEA_CODES: { '423600': 1.0 },
  },
  ['423690']: {
    label: 'Other Electronic Parts and Equipment Merchant Wholesalers',
    BEA_CODES: { '423600': 1.0 },
  },
  ['423710']: {
    label: 'Hardware Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423720']: {
    label:
      'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423730']: {
    label:
      'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423740']: {
    label: 'Refrigeration Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423810']: {
    label:
      'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423820']: {
    label: 'Farm and Garden Machinery and Equipment Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423830']: {
    label: 'Industrial Machinery and Equipment Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423840']: {
    label: 'Industrial Supplies Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423850']: {
    label: 'Service Establishment Equipment and Supplies Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423860']: {
    label:
      'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers',
    BEA_CODES: { '423800': 1.0 },
  },
  ['423910']: {
    label: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423920']: {
    label: 'Toy and Hobby Goods and Supplies Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423930']: {
    label: 'Recyclable Material Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423940']: {
    label:
      'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['423990']: {
    label: 'Other Miscellaneous Durable Goods Merchant Wholesalers',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['424110']: {
    label: 'Printing and Writing Paper Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424120']: {
    label: 'Stationery and Office Supplies Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424130']: {
    label: 'Industrial and Personal Service Paper Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424210']: {
    label: "Drugs and Druggists' Sundries Merchant Wholesalers",
    BEA_CODES: { '424200': 1.0 },
  },
  ['424310']: {
    label: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424340']: {
    label: 'Footwear Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424350']: {
    label: 'Clothing and Clothing Accessories Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424410']: {
    label: 'General Line Grocery Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424420']: {
    label: 'Packaged Frozen Food Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424430']: {
    label: 'Dairy Product (except Dried or Canned) Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424440']: {
    label: 'Poultry and Poultry Product Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424450']: {
    label: 'Confectionery Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424460']: {
    label: 'Fish and Seafood Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424470']: {
    label: 'Meat and Meat Product Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424480']: {
    label: 'Fresh Fruit and Vegetable Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424490']: {
    label: 'Other Grocery and Related Products Merchant Wholesalers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['424510']: {
    label: 'Grain and Field Bean Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424520']: {
    label: 'Livestock Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424590']: {
    label: 'Other Farm Product Raw Material Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424610']: {
    label: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424690']: {
    label: 'Other Chemical and Allied Products Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424710']: {
    label: 'Petroleum Bulk Stations and Terminals',
    BEA_CODES: { '424700': 1.0 },
  },
  ['424720']: {
    label:
      'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)',
    BEA_CODES: { '424700': 1.0 },
  },
  ['424810']: {
    label: 'Beer and Ale Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424820']: {
    label: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424910']: {
    label: 'Farm Supplies Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424920']: {
    label: 'Book, Periodical, and Newspaper Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424930']: {
    label: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers",
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424940']: {
    label: 'Tobacco Product and Electronic Cigarette Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424950']: {
    label: 'Paint, Varnish, and Supplies Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['424990']: {
    label: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['425120']: {
    label: 'Wholesale Trade Agents and Brokers',
    BEA_CODES: { '425000': 1.0 },
  },
  ['441110']: { label: 'New Car Dealers', BEA_CODES: { '441000': 1.0 } },
  ['441120']: { label: 'Used Car Dealers', BEA_CODES: { '441000': 1.0 } },
  ['441210']: {
    label: 'Recreational Vehicle Dealers',
    BEA_CODES: { '441000': 1.0 },
  },
  ['441222']: { label: 'Boat Dealers', BEA_CODES: { '441000': 1.0 } },
  ['441227']: {
    label: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers',
    BEA_CODES: { '441000': 0.4912539, '454000': 0.5087461 },
  },
  ['441330']: {
    label: 'Automotive Parts and Accessories Retailers',
    BEA_CODES: { '441000': 0.4912539, '454000': 0.5087461 },
  },
  ['441340']: {
    label: 'Tire Dealers',
    BEA_CODES: { '441000': 0.4912539, '454000': 0.5087461 },
  },
  ['444110']: { label: 'Home Centers', BEA_CODES: { '444000': 1.0 } },
  ['444120']: {
    label: 'Paint and Wallpaper Retailers',
    BEA_CODES: { '444000': 1.0 },
  },
  ['444140']: {
    label: 'Hardware Retailers',
    BEA_CODES: { '444000': 0.4118398, '454000': 0.5881602 },
  },
  ['444180']: {
    label: 'Other Building Material Dealers',
    BEA_CODES: { '444000': 0.4118398, '454000': 0.5881602 },
  },
  ['444230']: {
    label: 'Outdoor Power Equipment Retailers',
    BEA_CODES: { '444000': 0.4118398, '454000': 0.5881602 },
  },
  ['444240']: {
    label: 'Nursery, Garden Center, and Farm Supply Retailers',
    BEA_CODES: { '444000': 0.4118398, '454000': 0.5881602 },
  },
  ['445110']: {
    label:
      'Supermarkets and Other Grocery Retailers (except Convenience Retailers)',
    BEA_CODES: { '445000': 1.0 },
  },
  ['445131']: {
    label: 'Convenience Retailers',
    BEA_CODES: { '445000': 0.5436732, '454000': 0.4563268 },
  },
  ['445132']: {
    label: 'Vending Machine Operators',
    BEA_CODES: { '454000': 1.0 },
  },
  ['445230']: {
    label: 'Fruit and Vegetable Retailers',
    BEA_CODES: { '445000': 1.0 },
  },
  ['445240']: {
    label: 'Meat Retailers',
    BEA_CODES: { '445000': 0.5436732, '454000': 0.4563268 },
  },
  ['445250']: {
    label: 'Fish and Seafood Retailers',
    BEA_CODES: { '445000': 0.5436732, '454000': 0.4563268 },
  },
  ['445291']: { label: 'Baked Goods Retailers', BEA_CODES: { '445000': 1.0 } },
  ['445292']: {
    label: 'Confectionery and Nut Retailers',
    BEA_CODES: { '445000': 1.0 },
  },
  ['445298']: {
    label: 'All Other Specialty Food Retailers',
    BEA_CODES: { '445000': 0.5436732, '454000': 0.4563268 },
  },
  ['445320']: {
    label: 'Beer, Wine, and Liquor Retailers',
    BEA_CODES: { '445000': 0.5436732, '454000': 0.4563268 },
  },
  ['449110']: {
    label: 'Furniture Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['449121']: {
    label: 'Floor Covering Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['449122']: {
    label: 'Window Treatment Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['449129']: {
    label: 'All Other Home Furnishings Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['449210']: {
    label: 'Electronics and Appliance Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['455110']: {
    label: 'Department Stores',
    BEA_CODES: { '452000': 0.5560915, '454000': 0.4439085 },
  },
  ['455211']: {
    label: 'Warehouse Clubs and Supercenters',
    BEA_CODES: { '452000': 0.5560915, '454000': 0.4439085 },
  },
  ['455219']: {
    label: 'All Other General Merchandise Retailers',
    BEA_CODES: { '452000': 0.366534, '454000': 0.2925914, '4B0000': 0.3408746 },
  },
  ['456110']: {
    label: 'Pharmacies and Drug Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['456120']: {
    label: 'Cosmetics, Beauty Supplies, and Perfume Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['456130']: {
    label: 'Optical Goods Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['456191']: {
    label: 'Food (Health) Supplement Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['456199']: {
    label: 'All Other Health and Personal Care Retailers',
    BEA_CODES: { '446000': 0.3759374, '454000': 0.6240626 },
  },
  ['457110']: {
    label: 'Gasoline Stations with Convenience Stores',
    BEA_CODES: { '447000': 1.0 },
  },
  ['457120']: {
    label: 'Other Gasoline Stations',
    BEA_CODES: { '447000': 1.0 },
  },
  ['457210']: { label: 'Fuel Dealers', BEA_CODES: { '454000': 1.0 } },
  ['458110']: {
    label: 'Clothing and Clothing Accessories Retailers',
    BEA_CODES: { '448000': 0.4371696, '454000': 0.5628304 },
  },
  ['458210']: {
    label: 'Shoe Retailers',
    BEA_CODES: { '448000': 0.4371696, '454000': 0.5628304 },
  },
  ['458310']: {
    label: 'Jewelry Retailers',
    BEA_CODES: { '448000': 0.4371696, '454000': 0.5628304 },
  },
  ['458320']: {
    label: 'Luggage and Leather Goods Retailers',
    BEA_CODES: { '448000': 0.4371696, '454000': 0.5628304 },
  },
  ['459110']: {
    label: 'Sporting Goods Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459120']: {
    label: 'Hobby, Toy, and Game Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459130']: {
    label: 'Sewing, Needlework, and Piece Goods Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459140']: {
    label: 'Musical Instrument and Supplies Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459210']: {
    label: 'Book Retailers and News Dealers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459310']: {
    label: 'Florists',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459410']: {
    label: 'Office Supplies and Stationery Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459420']: {
    label: 'Gift, Novelty, and Souvenir Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459510']: {
    label: 'Used Merchandise Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459910']: {
    label: 'Pet and Pet Supplies Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459920']: {
    label: 'Art Dealers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459930']: {
    label: 'Manufactured (Mobile) Home Dealers',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['459991']: {
    label:
      'Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['459999']: {
    label: 'All Other Miscellaneous Retailers',
    BEA_CODES: { '454000': 0.4618896, '4B0000': 0.5381104 },
  },
  ['481111']: {
    label: 'Scheduled Passenger Air Transportation',
    BEA_CODES: { '481000': 1.0 },
  },
  ['481112']: {
    label: 'Scheduled Freight Air Transportation',
    BEA_CODES: { '481000': 1.0 },
  },
  ['481211']: {
    label: 'Nonscheduled Chartered Passenger Air Transportation',
    BEA_CODES: { '481000': 1.0 },
  },
  ['481212']: {
    label: 'Nonscheduled Chartered Freight Air Transportation',
    BEA_CODES: { '481000': 1.0 },
  },
  ['481219']: {
    label: 'Other Nonscheduled Air Transportation',
    BEA_CODES: { '481000': 1.0 },
  },
  ['482111']: { label: 'Line-Haul Railroads', BEA_CODES: { '482000': 1.0 } },
  ['482112']: { label: 'Short Line Railroads', BEA_CODES: { '482000': 1.0 } },
  ['483111']: {
    label: 'Deep Sea Freight Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['483112']: {
    label: 'Deep Sea Passenger Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['483113']: {
    label: 'Coastal and Great Lakes Freight Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['483114']: {
    label: 'Coastal and Great Lakes Passenger Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['483211']: {
    label: 'Inland Water Freight Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['483212']: {
    label: 'Inland Water Passenger Transportation',
    BEA_CODES: { '483000': 1.0 },
  },
  ['484110']: {
    label: 'General Freight Trucking, Local',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484121']: {
    label: 'General Freight Trucking, Long-Distance, Truckload',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484122']: {
    label: 'General Freight Trucking, Long-Distance, Less Than Truckload',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484210']: {
    label: 'Used Household and Office Goods Moving',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484220']: {
    label: 'Specialized Freight (except Used Goods) Trucking, Local',
    BEA_CODES: { '484000': 1.0 },
  },
  ['484230']: {
    label: 'Specialized Freight (except Used Goods) Trucking, Long-Distance',
    BEA_CODES: { '484000': 1.0 },
  },
  ['485111']: {
    label: 'Mixed Mode Transit Systems',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485112']: { label: 'Commuter Rail Systems', BEA_CODES: { '485000': 1.0 } },
  ['485113']: {
    label: 'Bus and Other Motor Vehicle Transit Systems',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485119']: {
    label: 'Other Urban Transit Systems',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485210']: {
    label: 'Interurban and Rural Bus Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485310']: {
    label: 'Taxi and Ridesharing Services',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485320']: { label: 'Limousine Service', BEA_CODES: { '485000': 1.0 } },
  ['485410']: {
    label: 'School and Employee Bus Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485510']: { label: 'Charter Bus Industry', BEA_CODES: { '485000': 1.0 } },
  ['485991']: {
    label: 'Special Needs Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['485999']: {
    label: 'All Other Transit and Ground Passenger Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['486110']: {
    label: 'Pipeline Transportation of Crude Oil',
    BEA_CODES: { '486000': 1.0 },
  },
  ['486210']: {
    label: 'Pipeline Transportation of Natural Gas',
    BEA_CODES: { '486000': 1.0 },
  },
  ['486910']: {
    label: 'Pipeline Transportation of Refined Petroleum Products',
    BEA_CODES: { '486000': 1.0 },
  },
  ['486990']: {
    label: 'All Other Pipeline Transportation',
    BEA_CODES: { '486000': 1.0 },
  },
  ['487110']: {
    label: 'Scenic and Sightseeing Transportation, Land',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['487210']: {
    label: 'Scenic and Sightseeing Transportation, Water',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['487990']: {
    label: 'Scenic and Sightseeing Transportation, Other',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488111']: { label: 'Air Traffic Control', BEA_CODES: { '48A000': 1.0 } },
  ['488119']: {
    label: 'Other Airport Operations',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488190']: {
    label: 'Other Support Activities for Air Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488210']: {
    label: 'Support Activities for Rail Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488310']: {
    label: 'Port and Harbor Operations',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488320']: { label: 'Marine Cargo Handling', BEA_CODES: { '48A000': 1.0 } },
  ['488330']: {
    label: 'Navigational Services to Shipping',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488390']: {
    label: 'Other Support Activities for Water Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488410']: { label: 'Motor Vehicle Towing', BEA_CODES: { '48A000': 1.0 } },
  ['488490']: {
    label: 'Other Support Activities for Road Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488510']: {
    label: 'Freight Transportation Arrangement',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['488991']: { label: 'Packing and Crating', BEA_CODES: { '48A000': 1.0 } },
  ['488999']: {
    label: 'All Other Support Activities for Transportation',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['491110']: { label: 'Postal Service', BEA_CODES: { '491000': 1.0 } },
  ['492110']: {
    label: 'Couriers and Express Delivery Services',
    BEA_CODES: { '492000': 1.0 },
  },
  ['492210']: {
    label: 'Local Messengers and Local Delivery',
    BEA_CODES: { '492000': 1.0 },
  },
  ['493110']: {
    label: 'General Warehousing and Storage',
    BEA_CODES: { '493000': 1.0 },
  },
  ['493120']: {
    label: 'Refrigerated Warehousing and Storage',
    BEA_CODES: { '493000': 1.0 },
  },
  ['493130']: {
    label: 'Farm Product Warehousing and Storage',
    BEA_CODES: { '493000': 1.0 },
  },
  ['493190']: {
    label: 'Other Warehousing and Storage',
    BEA_CODES: { '493000': 1.0 },
  },
  ['512110']: {
    label: 'Motion Picture and Video Production',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512120']: {
    label: 'Motion Picture and Video Distribution',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512131']: {
    label: 'Motion Picture Theaters (except Drive-Ins)',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512132']: {
    label: 'Drive-In Motion Picture Theaters',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512191']: {
    label: 'Teleproduction and Other Postproduction Services',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512199']: {
    label: 'Other Motion Picture and Video Industries',
    BEA_CODES: { '512100': 1.0 },
  },
  ['512230']: { label: 'Music Publishers', BEA_CODES: { '512200': 1.0 } },
  ['512240']: {
    label: 'Sound Recording Studios',
    BEA_CODES: { '512200': 1.0 },
  },
  ['512250']: {
    label: 'Record Production and Distribution',
    BEA_CODES: { '512200': 1.0 },
  },
  ['512290']: {
    label: 'Other Sound Recording Industries',
    BEA_CODES: { '512200': 1.0 },
  },
  ['513110']: {
    label: 'Newspaper Publishers',
    BEA_CODES: { '511110': 0.146982, '519130': 0.853018 },
  },
  ['513120']: {
    label: 'Periodical Publishers',
    BEA_CODES: { '511120': 0.1840131, '519130': 0.8159869 },
  },
  ['513130']: {
    label: 'Book Publishers',
    BEA_CODES: { '511130': 0.3805703, '519130': 0.6194297 },
  },
  ['513140']: {
    label: 'Directory and Mailing List Publishers',
    BEA_CODES: { '5111A0': 0.1740426, '519130': 0.8259574 },
  },
  ['513191']: {
    label: 'Greeting Card Publishers',
    BEA_CODES: { '5111A0': 0.1740426, '519130': 0.8259574 },
  },
  ['513199']: {
    label: 'All Other Publishers',
    BEA_CODES: { '5111A0': 0.1740426, '519130': 0.8259574 },
  },
  ['513210']: { label: 'Software Publishers', BEA_CODES: { '511200': 1.0 } },
  ['516110']: {
    label: 'Radio Broadcasting Stations',
    BEA_CODES: { '515100': 1.0 },
  },
  ['516120']: {
    label: 'Television Broadcasting Stations',
    BEA_CODES: { '515100': 1.0 },
  },
  ['516210']: {
    label:
      'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers',
    BEA_CODES: {
      '515100': 0.3913646,
      '515200': 0.1589919,
      '519130': 0.3855908,
      '5191A0': 0.0640528,
    },
  },
  ['517111']: {
    label: 'Wired Telecommunications Carriers',
    BEA_CODES: { '517110': 1.0 },
  },
  ['517112']: {
    label: 'Wireless Telecommunications Carriers (except Satellite)',
    BEA_CODES: { '517210': 1.0 },
  },
  ['517121']: {
    label: 'Telecommunications Resellers',
    BEA_CODES: { '517A00': 1.0 },
  },
  ['517122']: {
    label: 'Agents for Wireless Telecommunications Services',
    BEA_CODES: { '517210': 0.8892586, '517A00': 0.1107414 },
  },
  ['517410']: {
    label: 'Satellite Telecommunications',
    BEA_CODES: { '517A00': 1.0 },
  },
  ['517810']: {
    label: 'All Other Telecommunications',
    BEA_CODES: { '517A00': 1.0 },
  },
  ['518210']: {
    label:
      'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
    BEA_CODES: { '518200': 1.0 },
  },
  ['519210']: { label: 'Libraries and Archives', BEA_CODES: { '5191A0': 1.0 } },
  ['519290']: {
    label: 'Web Search Portals and All Other Information Services',
    BEA_CODES: { '519130': 0.8575477, '5191A0': 0.1424523 },
  },
  ['521110']: {
    label: 'Monetary Authorities-Central Bank',
    BEA_CODES: { '52A000': 1.0 },
  },
  ['522110']: { label: 'Commercial Banking', BEA_CODES: { '52A000': 1.0 } },
  ['522130']: { label: 'Credit Unions', BEA_CODES: { '52A000': 1.0 } },
  ['522180']: {
    label: 'Savings Institutions and Other Depository Credit Intermediation',
    BEA_CODES: { '52A000': 1.0 },
  },
  ['522210']: { label: 'Credit Card Issuing', BEA_CODES: { '522A00': 1.0 } },
  ['522220']: { label: 'Sales Financing', BEA_CODES: { '522A00': 1.0 } },
  ['522291']: { label: 'Consumer Lending', BEA_CODES: { '522A00': 1.0 } },
  ['522292']: { label: 'Real Estate Credit', BEA_CODES: { '522A00': 1.0 } },
  ['522299']: {
    label:
      'International, Secondary Market, and All Other Nondepository Credit Intermediation',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['522310']: {
    label: 'Mortgage and Nonmortgage Loan Brokers',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['522320']: {
    label:
      'Financial Transactions Processing, Reserve, and Clearinghouse Activities',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['522390']: {
    label: 'Other Activities Related to Credit Intermediation',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['523150']: {
    label: 'Investment Banking and Securities Intermediation',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['523160']: {
    label: 'Commodity Contracts Intermediation',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['523210']: {
    label: 'Securities and Commodity Exchanges',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['523910']: {
    label: 'Miscellaneous Intermediation',
    BEA_CODES: { '523900': 1.0 },
  },
  ['523940']: {
    label: 'Portfolio Management and Investment Advice',
    BEA_CODES: { '523900': 1.0 },
  },
  ['523991']: {
    label: 'Trust, Fiduciary, and Custody Activities',
    BEA_CODES: { '523900': 1.0 },
  },
  ['523999']: {
    label: 'Miscellaneous Financial Investment Activities',
    BEA_CODES: { '523900': 1.0 },
  },
  ['524113']: {
    label: 'Direct Life Insurance Carriers',
    BEA_CODES: { '524113': 1.0 },
  },
  ['524114']: {
    label: 'Direct Health and Medical Insurance Carriers',
    BEA_CODES: { '5241XX': 1.0 },
  },
  ['524126']: {
    label: 'Direct Property and Casualty Insurance Carriers',
    BEA_CODES: { '5241XX': 1.0 },
  },
  ['524127']: {
    label: 'Direct Title Insurance Carriers',
    BEA_CODES: { '5241XX': 1.0 },
  },
  ['524128']: {
    label: 'Other Direct Insurance (except Life, Health, and Medical) Carriers',
    BEA_CODES: { '5241XX': 1.0 },
  },
  ['524130']: { label: 'Reinsurance Carriers', BEA_CODES: { '5241XX': 1.0 } },
  ['524210']: {
    label: 'Insurance Agencies and Brokerages',
    BEA_CODES: { '524200': 1.0 },
  },
  ['524291']: { label: 'Claims Adjusting', BEA_CODES: { '524200': 1.0 } },
  ['524292']: {
    label:
      'Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds',
    BEA_CODES: { '524200': 1.0 },
  },
  ['524298']: {
    label: 'All Other Insurance Related Activities',
    BEA_CODES: { '524200': 1.0 },
  },
  ['525110']: { label: 'Pension Funds', BEA_CODES: { '525000': 1.0 } },
  ['525120']: {
    label: 'Health and Welfare Funds',
    BEA_CODES: { '525000': 1.0 },
  },
  ['525190']: { label: 'Other Insurance Funds', BEA_CODES: { '525000': 1.0 } },
  ['525910']: {
    label: 'Open-End Investment Funds',
    BEA_CODES: { '525000': 1.0 },
  },
  ['525920']: {
    label: 'Trusts, Estates, and Agency Accounts',
    BEA_CODES: { '525000': 1.0 },
  },
  ['525990']: {
    label: 'Other Financial Vehicles',
    BEA_CODES: { '525000': 1.0 },
  },
  ['531110']: {
    label: 'Lessors of Residential Buildings and Dwellings',
    BEA_CODES: { '531HSO': 0.7497652, '531HST': 0.2502348 },
  },
  ['531120']: {
    label: 'Lessors of Nonresidential Buildings (except Miniwarehouses)',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531130']: {
    label: 'Lessors of Miniwarehouses and Self-Storage Units',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531190']: {
    label: 'Lessors of Other Real Estate Property',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531210']: {
    label: 'Offices of Real Estate Agents and Brokers',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531311']: {
    label: 'Residential Property Managers',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531312']: {
    label: 'Nonresidential Property Managers',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531320']: {
    label: 'Offices of Real Estate Appraisers',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['531390']: {
    label: 'Other Activities Related to Real Estate',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['532111']: { label: 'Passenger Car Rental', BEA_CODES: { '532100': 1.0 } },
  ['532112']: { label: 'Passenger Car Leasing', BEA_CODES: { '532100': 1.0 } },
  ['532120']: {
    label:
      'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
    BEA_CODES: { '532100': 1.0 },
  },
  ['532210']: {
    label: 'Consumer Electronics and Appliances Rental',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['532281']: {
    label: 'Formal Wear and Costume Rental',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['532282']: {
    label: 'Video Tape and Disc Rental',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['532283']: {
    label: 'Home Health Equipment Rental',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['532284']: {
    label: 'Recreational Goods Rental',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['532289']: {
    label: 'All Other Consumer Goods Rental',
    BEA_CODES: { '532A00': 1.0 },
  },
  ['532310']: { label: 'General Rental Centers', BEA_CODES: { '532A00': 1.0 } },
  ['532411']: {
    label:
      'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing',
    BEA_CODES: { '532400': 1.0 },
  },
  ['532412']: {
    label:
      'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing',
    BEA_CODES: { '532400': 1.0 },
  },
  ['532420']: {
    label: 'Office Machinery and Equipment Rental and Leasing',
    BEA_CODES: { '532400': 1.0 },
  },
  ['532490']: {
    label:
      'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
    BEA_CODES: { '532400': 1.0 },
  },
  ['533110']: {
    label:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
    BEA_CODES: { '533000': 1.0 },
  },
  ['541110']: { label: 'Offices of Lawyers', BEA_CODES: { '541100': 1.0 } },
  ['541120']: { label: 'Offices of Notaries', BEA_CODES: { '541100': 1.0 } },
  ['541191']: {
    label: 'Title Abstract and Settlement Offices',
    BEA_CODES: { '541100': 1.0 },
  },
  ['541199']: {
    label: 'All Other Legal Services',
    BEA_CODES: { '541100': 1.0 },
  },
  ['541211']: {
    label: 'Offices of Certified Public Accountants',
    BEA_CODES: { '541200': 1.0 },
  },
  ['541213']: {
    label: 'Tax Preparation Services',
    BEA_CODES: { '541200': 1.0 },
  },
  ['541214']: { label: 'Payroll Services', BEA_CODES: { '541200': 1.0 } },
  ['541219']: {
    label: 'Other Accounting Services',
    BEA_CODES: { '541200': 1.0 },
  },
  ['541310']: { label: 'Architectural Services', BEA_CODES: { '541300': 1.0 } },
  ['541320']: {
    label: 'Landscape Architectural Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541330']: { label: 'Engineering Services', BEA_CODES: { '541300': 1.0 } },
  ['541340']: { label: 'Drafting Services', BEA_CODES: { '541300': 1.0 } },
  ['541350']: {
    label: 'Building Inspection Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541360']: {
    label: 'Geophysical Surveying and Mapping Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541370']: {
    label: 'Surveying and Mapping (except Geophysical) Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541380']: {
    label: 'Testing Laboratories and Services',
    BEA_CODES: { '541300': 1.0 },
  },
  ['541410']: {
    label: 'Interior Design Services',
    BEA_CODES: { '541400': 1.0 },
  },
  ['541420']: {
    label: 'Industrial Design Services',
    BEA_CODES: { '541400': 1.0 },
  },
  ['541430']: {
    label: 'Graphic Design Services',
    BEA_CODES: { '541400': 1.0 },
  },
  ['541490']: {
    label: 'Other Specialized Design Services',
    BEA_CODES: { '541400': 1.0 },
  },
  ['541511']: {
    label: 'Custom Computer Programming Services',
    BEA_CODES: { '541511': 1.0 },
  },
  ['541512']: {
    label: 'Computer Systems Design Services',
    BEA_CODES: { '541512': 1.0 },
  },
  ['541513']: {
    label: 'Computer Facilities Management Services',
    BEA_CODES: { '54151A': 1.0 },
  },
  ['541519']: {
    label: 'Other Computer Related Services',
    BEA_CODES: { '54151A': 1.0 },
  },
  ['541611']: {
    label:
      'Administrative Management and General Management Consulting Services',
    BEA_CODES: { '541610': 1.0 },
  },
  ['541612']: {
    label: 'Human Resources Consulting Services',
    BEA_CODES: { '541610': 1.0 },
  },
  ['541613']: {
    label: 'Marketing Consulting Services',
    BEA_CODES: { '541610': 1.0 },
  },
  ['541614']: {
    label: 'Process, Physical Distribution, and Logistics Consulting Services',
    BEA_CODES: { '541610': 1.0 },
  },
  ['541618']: {
    label: 'Other Management Consulting Services',
    BEA_CODES: { '541610': 1.0 },
  },
  ['541620']: {
    label: 'Environmental Consulting Services',
    BEA_CODES: { '5416A0': 1.0 },
  },
  ['541690']: {
    label: 'Other Scientific and Technical Consulting Services',
    BEA_CODES: { '5416A0': 1.0 },
  },
  ['541713']: {
    label: 'Research and Development in Nanotechnology',
    BEA_CODES: { '541700': 1.0 },
  },
  ['541714']: {
    label:
      'Research and Development in Biotechnology (except Nanobiotechnology)',
    BEA_CODES: { '541700': 1.0 },
  },
  ['541715']: {
    label:
      'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)',
    BEA_CODES: { '541700': 1.0 },
  },
  ['541720']: {
    label: 'Research and Development in the Social Sciences and Humanities',
    BEA_CODES: { '541700': 1.0 },
  },
  ['541810']: { label: 'Advertising Agencies', BEA_CODES: { '541800': 1.0 } },
  ['541820']: {
    label: 'Public Relations Agencies',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541830']: { label: 'Media Buying Agencies', BEA_CODES: { '541800': 1.0 } },
  ['541840']: { label: 'Media Representatives', BEA_CODES: { '541800': 1.0 } },
  ['541850']: {
    label: 'Indoor and Outdoor Display Advertising',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541860']: {
    label: 'Direct Mail Advertising',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541870']: {
    label: 'Advertising Material Distribution Services',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541890']: {
    label: 'Other Services Related to Advertising',
    BEA_CODES: { '541800': 1.0 },
  },
  ['541910']: {
    label: 'Marketing Research and Public Opinion Polling',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['541921']: {
    label: 'Photography Studios, Portrait',
    BEA_CODES: { '541920': 1.0 },
  },
  ['541922']: { label: 'Commercial Photography', BEA_CODES: { '541920': 1.0 } },
  ['541930']: {
    label: 'Translation and Interpretation Services',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['541940']: { label: 'Veterinary Services', BEA_CODES: { '541940': 1.0 } },
  ['541990']: {
    label: 'All Other Professional, Scientific, and Technical Services',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['551111']: {
    label: 'Offices of Bank Holding Companies',
    BEA_CODES: { '550000': 1.0 },
  },
  ['551112']: {
    label: 'Offices of Other Holding Companies',
    BEA_CODES: { '550000': 1.0 },
  },
  ['551114']: {
    label: 'Corporate, Subsidiary, and Regional Managing Offices',
    BEA_CODES: { '550000': 1.0 },
  },
  ['561110']: {
    label: 'Office Administrative Services',
    BEA_CODES: { '561100': 1.0 },
  },
  ['561210']: {
    label: 'Facilities Support Services',
    BEA_CODES: { '561200': 1.0 },
  },
  ['561311']: {
    label: 'Employment Placement Agencies',
    BEA_CODES: { '561300': 1.0 },
  },
  ['561312']: {
    label: 'Executive Search Services',
    BEA_CODES: { '561300': 1.0 },
  },
  ['561320']: {
    label: 'Temporary Help Services',
    BEA_CODES: { '561300': 1.0 },
  },
  ['561330']: {
    label: 'Professional Employer Organizations',
    BEA_CODES: { '561300': 1.0 },
  },
  ['561410']: {
    label: 'Document Preparation Services',
    BEA_CODES: { '561400': 1.0 },
  },
  ['561421']: {
    label: 'Telephone Answering Services',
    BEA_CODES: { '561400': 1.0 },
  },
  ['561422']: {
    label: 'Telemarketing Bureaus and Other Contact Centers',
    BEA_CODES: { '561400': 1.0 },
  },
  ['561431']: { label: 'Private Mail Centers', BEA_CODES: { '561400': 1.0 } },
  ['561439']: {
    label: 'Other Business Service Centers (including Copy Shops)',
    BEA_CODES: { '561400': 1.0 },
  },
  ['561440']: { label: 'Collection Agencies', BEA_CODES: { '561400': 1.0 } },
  ['561450']: { label: 'Credit Bureaus', BEA_CODES: { '561400': 1.0 } },
  ['561491']: { label: 'Repossession Services', BEA_CODES: { '561400': 1.0 } },
  ['561492']: {
    label: 'Court Reporting and Stenotype Services',
    BEA_CODES: { '561400': 1.0 },
  },
  ['561499']: {
    label: 'All Other Business Support Services',
    BEA_CODES: { '561400': 1.0 },
  },
  ['561510']: { label: 'Travel Agencies', BEA_CODES: { '561500': 1.0 } },
  ['561520']: { label: 'Tour Operators', BEA_CODES: { '561500': 1.0 } },
  ['561591']: {
    label: 'Convention and Visitors Bureaus',
    BEA_CODES: { '561500': 1.0 },
  },
  ['561599']: {
    label: 'All Other Travel Arrangement and Reservation Services',
    BEA_CODES: { '561500': 1.0 },
  },
  ['561611']: {
    label: 'Investigation and Personal Background Check Services',
    BEA_CODES: { '561600': 1.0 },
  },
  ['561612']: {
    label: 'Security Guards and Patrol Services',
    BEA_CODES: { '561600': 1.0 },
  },
  ['561613']: { label: 'Armored Car Services', BEA_CODES: { '561600': 1.0 } },
  ['561621']: {
    label: 'Security Systems Services (except Locksmiths)',
    BEA_CODES: { '561600': 1.0 },
  },
  ['561622']: { label: 'Locksmiths', BEA_CODES: { '561600': 1.0 } },
  ['561710']: {
    label: 'Exterminating and Pest Control Services',
    BEA_CODES: { '561700': 1.0 },
  },
  ['561720']: { label: 'Janitorial Services', BEA_CODES: { '561700': 1.0 } },
  ['561730']: { label: 'Landscaping Services', BEA_CODES: { '561700': 1.0 } },
  ['561740']: {
    label: 'Carpet and Upholstery Cleaning Services',
    BEA_CODES: { '561700': 1.0 },
  },
  ['561790']: {
    label: 'Other Services to Buildings and Dwellings',
    BEA_CODES: { '561700': 1.0 },
  },
  ['561910']: {
    label: 'Packaging and Labeling Services',
    BEA_CODES: { '561900': 1.0 },
  },
  ['561920']: {
    label: 'Convention and Trade Show Organizers',
    BEA_CODES: { '561900': 1.0 },
  },
  ['561990']: {
    label: 'All Other Support Services',
    BEA_CODES: { '561900': 1.0 },
  },
  ['562111']: { label: 'Solid Waste Collection', BEA_CODES: { '562111': 1.0 } },
  ['562112']: {
    label: 'Hazardous Waste Collection',
    BEA_CODES: { '562HAZ': 1.0 },
  },
  ['562119']: { label: 'Other Waste Collection', BEA_CODES: { '562OTH': 1.0 } },
  ['562211']: {
    label: 'Hazardous Waste Treatment and Disposal',
    BEA_CODES: { '562HAZ': 1.0 },
  },
  ['562212']: { label: 'Solid Waste Landfill', BEA_CODES: { '562212': 1.0 } },
  ['562213']: {
    label: 'Solid Waste Combustors and Incinerators',
    BEA_CODES: { '562213': 1.0 },
  },
  ['562219']: {
    label: 'Other Nonhazardous Waste Treatment and Disposal',
    BEA_CODES: { '562OTH': 1.0 },
  },
  ['562910']: { label: 'Remediation Services', BEA_CODES: { '562910': 1.0 } },
  ['562920']: {
    label: 'Materials Recovery Facilities',
    BEA_CODES: { '562920': 1.0 },
  },
  ['562991']: {
    label: 'Septic Tank and Related Services',
    BEA_CODES: { '562OTH': 1.0 },
  },
  ['562998']: {
    label: 'All Other Miscellaneous Waste Management Services',
    BEA_CODES: { '562OTH': 1.0 },
  },
  ['611110']: {
    label: 'Elementary and Secondary Schools',
    BEA_CODES: { '611100': 1.0 },
  },
  ['611210']: { label: 'Junior Colleges', BEA_CODES: { '611A00': 1.0 } },
  ['611310']: {
    label: 'Colleges, Universities, and Professional Schools',
    BEA_CODES: { '611A00': 1.0 },
  },
  ['611410']: {
    label: 'Business and Secretarial Schools',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611420']: { label: 'Computer Training', BEA_CODES: { '611B00': 1.0 } },
  ['611430']: {
    label: 'Professional and Management Development Training',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611511']: {
    label: 'Cosmetology and Barber Schools',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611512']: { label: 'Flight Training', BEA_CODES: { '611B00': 1.0 } },
  ['611513']: {
    label: 'Apprenticeship Training',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611519']: {
    label: 'Other Technical and Trade Schools',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611610']: { label: 'Fine Arts Schools', BEA_CODES: { '611B00': 1.0 } },
  ['611620']: {
    label: 'Sports and Recreation Instruction',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611630']: { label: 'Language Schools', BEA_CODES: { '611B00': 1.0 } },
  ['611691']: {
    label: 'Exam Preparation and Tutoring',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611692']: {
    label: 'Automobile Driving Schools',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611699']: {
    label: 'All Other Miscellaneous Schools and Instruction',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['611710']: {
    label: 'Educational Support Services',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['621111']: {
    label: 'Offices of Physicians (except Mental Health Specialists)',
    BEA_CODES: { '621100': 1.0 },
  },
  ['621112']: {
    label: 'Offices of Physicians, Mental Health Specialists',
    BEA_CODES: { '621100': 1.0 },
  },
  ['621210']: { label: 'Offices of Dentists', BEA_CODES: { '621200': 1.0 } },
  ['621310']: {
    label: 'Offices of Chiropractors',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621320']: {
    label: 'Offices of Optometrists',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621330']: {
    label: 'Offices of Mental Health Practitioners (except Physicians)',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621340']: {
    label:
      'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621391']: { label: 'Offices of Podiatrists', BEA_CODES: { '621300': 1.0 } },
  ['621399']: {
    label: 'Offices of All Other Miscellaneous Health Practitioners',
    BEA_CODES: { '621300': 1.0 },
  },
  ['621410']: {
    label: 'Family Planning Centers',
    BEA_CODES: { '621400': 1.0 },
  },
  ['621420']: {
    label: 'Outpatient Mental Health and Substance Abuse Centers',
    BEA_CODES: { '621400': 1.0 },
  },
  ['621491']: { label: 'HMO Medical Centers', BEA_CODES: { '621400': 1.0 } },
  ['621492']: {
    label: 'Kidney Dialysis Centers',
    BEA_CODES: { '621400': 1.0 },
  },
  ['621493']: {
    label: 'Freestanding Ambulatory Surgical and Emergency Centers',
    BEA_CODES: { '621400': 1.0 },
  },
  ['621498']: {
    label: 'All Other Outpatient Care Centers',
    BEA_CODES: { '621400': 1.0 },
  },
  ['621511']: { label: 'Medical Laboratories', BEA_CODES: { '621500': 1.0 } },
  ['621512']: {
    label: 'Diagnostic Imaging Centers',
    BEA_CODES: { '621500': 1.0 },
  },
  ['621610']: {
    label: 'Home Health Care Services',
    BEA_CODES: { '621600': 1.0 },
  },
  ['621910']: { label: 'Ambulance Services', BEA_CODES: { '621900': 1.0 } },
  ['621991']: { label: 'Blood and Organ Banks', BEA_CODES: { '621900': 1.0 } },
  ['621999']: {
    label: 'All Other Miscellaneous Ambulatory Health Care Services',
    BEA_CODES: { '621900': 1.0 },
  },
  ['622110']: {
    label: 'General Medical and Surgical Hospitals',
    BEA_CODES: { '622000': 1.0 },
  },
  ['622210']: {
    label: 'Psychiatric and Substance Abuse Hospitals',
    BEA_CODES: { '622000': 1.0 },
  },
  ['622310']: {
    label: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
    BEA_CODES: { '622000': 1.0 },
  },
  ['623110']: {
    label: 'Nursing Care Facilities (Skilled Nursing Facilities)',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['623210']: {
    label: 'Residential Intellectual and Developmental Disability Facilities',
    BEA_CODES: { '623B00': 1.0 },
  },
  ['623220']: {
    label: 'Residential Mental Health and Substance Abuse Facilities',
    BEA_CODES: { '623B00': 1.0 },
  },
  ['623311']: {
    label: 'Continuing Care Retirement Communities',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['623312']: {
    label: 'Assisted Living Facilities for the Elderly',
    BEA_CODES: { '623A00': 1.0 },
  },
  ['623990']: {
    label: 'Other Residential Care Facilities',
    BEA_CODES: { '623B00': 1.0 },
  },
  ['624110']: {
    label: 'Child and Youth Services',
    BEA_CODES: { '624100': 1.0 },
  },
  ['624120']: {
    label: 'Services for the Elderly and Persons with Disabilities',
    BEA_CODES: { '624100': 1.0 },
  },
  ['624190']: {
    label: 'Other Individual and Family Services',
    BEA_CODES: { '624100': 1.0 },
  },
  ['624210']: {
    label: 'Community Food Services',
    BEA_CODES: { '624A00': 1.0 },
  },
  ['624221']: { label: 'Temporary Shelters', BEA_CODES: { '624A00': 1.0 } },
  ['624229']: {
    label: 'Other Community Housing Services',
    BEA_CODES: { '624A00': 1.0 },
  },
  ['624230']: {
    label: 'Emergency and Other Relief Services',
    BEA_CODES: { '624A00': 1.0 },
  },
  ['624310']: {
    label: 'Vocational Rehabilitation Services',
    BEA_CODES: { '624A00': 1.0 },
  },
  ['624410']: { label: 'Child Care Services', BEA_CODES: { '624400': 1.0 } },
  ['711110']: {
    label: 'Theater Companies and Dinner Theaters',
    BEA_CODES: { '711100': 1.0 },
  },
  ['711120']: { label: 'Dance Companies', BEA_CODES: { '711100': 1.0 } },
  ['711130']: {
    label: 'Musical Groups and Artists',
    BEA_CODES: { '711100': 1.0 },
  },
  ['711190']: {
    label: 'Other Performing Arts Companies',
    BEA_CODES: { '711100': 1.0 },
  },
  ['711211']: { label: 'Sports Teams and Clubs', BEA_CODES: { '711200': 1.0 } },
  ['711212']: { label: 'Racetracks', BEA_CODES: { '711200': 1.0 } },
  ['711219']: { label: 'Other Spectator Sports', BEA_CODES: { '711200': 1.0 } },
  ['711310']: {
    label:
      'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
    BEA_CODES: { '711A00': 1.0 },
  },
  ['711320']: {
    label:
      'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
    BEA_CODES: { '711A00': 1.0 },
  },
  ['711410']: {
    label:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
    BEA_CODES: { '711A00': 1.0 },
  },
  ['711510']: {
    label: 'Independent Artists, Writers, and Performers',
    BEA_CODES: { '711500': 1.0 },
  },
  ['712110']: { label: 'Museums', BEA_CODES: { '712000': 1.0 } },
  ['712120']: { label: 'Historical Sites', BEA_CODES: { '712000': 1.0 } },
  ['712130']: {
    label: 'Zoos and Botanical Gardens',
    BEA_CODES: { '712000': 1.0 },
  },
  ['712190']: {
    label: 'Nature Parks and Other Similar Institutions',
    BEA_CODES: { '712000': 1.0 },
  },
  ['713110']: {
    label: 'Amusement and Theme Parks',
    BEA_CODES: { '713100': 1.0 },
  },
  ['713120']: { label: 'Amusement Arcades', BEA_CODES: { '713100': 1.0 } },
  ['713210']: {
    label: 'Casinos (except Casino Hotels)',
    BEA_CODES: { '713200': 1.0 },
  },
  ['713290']: {
    label: 'Other Gambling Industries',
    BEA_CODES: { '713200': 1.0 },
  },
  ['713910']: {
    label: 'Golf Courses and Country Clubs',
    BEA_CODES: { '713900': 1.0 },
  },
  ['713920']: { label: 'Skiing Facilities', BEA_CODES: { '713900': 1.0 } },
  ['713930']: { label: 'Marinas', BEA_CODES: { '713900': 1.0 } },
  ['713940']: {
    label: 'Fitness and Recreational Sports Centers',
    BEA_CODES: { '713900': 1.0 },
  },
  ['713950']: { label: 'Bowling Centers', BEA_CODES: { '713900': 1.0 } },
  ['713990']: {
    label: 'All Other Amusement and Recreation Industries',
    BEA_CODES: { '713900': 1.0 },
  },
  ['721110']: {
    label: 'Hotels (except Casino Hotels) and Motels',
    BEA_CODES: { '721000': 1.0 },
  },
  ['721120']: { label: 'Casino Hotels', BEA_CODES: { '721000': 1.0 } },
  ['721191']: { label: 'Bed-and-Breakfast Inns', BEA_CODES: { '721000': 1.0 } },
  ['721199']: {
    label: 'All Other Traveler Accommodation',
    BEA_CODES: { '721000': 1.0 },
  },
  ['721211']: {
    label: 'RV (Recreational Vehicle) Parks and Campgrounds',
    BEA_CODES: { '721000': 1.0 },
  },
  ['721214']: {
    label: 'Recreational and Vacation Camps (except Campgrounds)',
    BEA_CODES: { '721000': 1.0 },
  },
  ['721310']: {
    label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
    BEA_CODES: { '721000': 1.0 },
  },
  ['722310']: {
    label: 'Food Service Contractors',
    BEA_CODES: { '722A00': 1.0 },
  },
  ['722320']: { label: 'Caterers', BEA_CODES: { '722A00': 1.0 } },
  ['722330']: { label: 'Mobile Food Services', BEA_CODES: { '722A00': 1.0 } },
  ['722410']: {
    label: 'Drinking Places (Alcoholic Beverages)',
    BEA_CODES: { '722A00': 1.0 },
  },
  ['722511']: {
    label: 'Full-Service Restaurants',
    BEA_CODES: { '722110': 1.0 },
  },
  ['722513']: {
    label: 'Limited-Service Restaurants',
    BEA_CODES: { '722211': 1.0 },
  },
  ['722514']: {
    label: 'Cafeterias, Grill Buffets, and Buffets',
    BEA_CODES: { '722A00': 1.0 },
  },
  ['722515']: {
    label: 'Snack and Nonalcoholic Beverage Bars',
    BEA_CODES: { '722A00': 1.0 },
  },
  ['811111']: {
    label: 'General Automotive Repair',
    BEA_CODES: { '811100': 1.0 },
  },
  ['811114']: {
    label: 'Specialized Automotive Repair',
    BEA_CODES: { '811100': 1.0 },
  },
  ['811121']: {
    label: 'Automotive Body, Paint, and Interior Repair and Maintenance',
    BEA_CODES: { '811100': 1.0 },
  },
  ['811122']: {
    label: 'Automotive Glass Replacement Shops',
    BEA_CODES: { '811100': 1.0 },
  },
  ['811191']: {
    label: 'Automotive Oil Change and Lubrication Shops',
    BEA_CODES: { '811100': 1.0 },
  },
  ['811192']: { label: 'Car Washes', BEA_CODES: { '811100': 1.0 } },
  ['811198']: {
    label: 'All Other Automotive Repair and Maintenance',
    BEA_CODES: { '811100': 1.0 },
  },
  ['811210']: {
    label: 'Electronic and Precision Equipment Repair and Maintenance',
    BEA_CODES: { '811200': 1.0 },
  },
  ['811310']: {
    label:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
    BEA_CODES: { '811300': 1.0 },
  },
  ['811411']: {
    label: 'Home and Garden Equipment Repair and Maintenance',
    BEA_CODES: { '811400': 1.0 },
  },
  ['811412']: {
    label: 'Appliance Repair and Maintenance',
    BEA_CODES: { '811400': 1.0 },
  },
  ['811420']: {
    label: 'Reupholstery and Furniture Repair',
    BEA_CODES: { '811400': 1.0 },
  },
  ['811430']: {
    label: 'Footwear and Leather Goods Repair',
    BEA_CODES: { '811400': 1.0 },
  },
  ['811490']: {
    label: 'Other Personal and Household Goods Repair and Maintenance',
    BEA_CODES: { '811400': 1.0 },
  },
  ['812111']: { label: 'Barber Shops', BEA_CODES: { '812100': 1.0 } },
  ['812112']: { label: 'Beauty Salons', BEA_CODES: { '812100': 1.0 } },
  ['812113']: { label: 'Nail Salons', BEA_CODES: { '812100': 1.0 } },
  ['812191']: {
    label: 'Diet and Weight Reducing Centers',
    BEA_CODES: { '812100': 1.0 },
  },
  ['812199']: {
    label: 'Other Personal Care Services',
    BEA_CODES: { '812100': 1.0 },
  },
  ['812210']: {
    label: 'Funeral Homes and Funeral Services',
    BEA_CODES: { '812200': 1.0 },
  },
  ['812220']: {
    label: 'Cemeteries and Crematories',
    BEA_CODES: { '812200': 1.0 },
  },
  ['812310']: {
    label: 'Coin-Operated Laundries and Drycleaners',
    BEA_CODES: { '812300': 1.0 },
  },
  ['812320']: {
    label: 'Drycleaning and Laundry Services (except Coin-Operated)',
    BEA_CODES: { '812300': 1.0 },
  },
  ['812331']: { label: 'Linen Supply', BEA_CODES: { '812300': 1.0 } },
  ['812332']: { label: 'Industrial Launderers', BEA_CODES: { '812300': 1.0 } },
  ['812910']: {
    label: 'Pet Care (except Veterinary) Services',
    BEA_CODES: { '812900': 1.0 },
  },
  ['812921']: {
    label: 'Photofinishing Laboratories (except One-Hour)',
    BEA_CODES: { '812900': 1.0 },
  },
  ['812922']: {
    label: 'One-Hour Photofinishing',
    BEA_CODES: { '812900': 1.0 },
  },
  ['812930']: {
    label: 'Parking Lots and Garages',
    BEA_CODES: { '812900': 1.0 },
  },
  ['812990']: {
    label: 'All Other Personal Services',
    BEA_CODES: { '812900': 1.0 },
  },
  ['813110']: {
    label: 'Religious Organizations',
    BEA_CODES: { '813100': 1.0 },
  },
  ['813211']: {
    label: 'Grantmaking Foundations',
    BEA_CODES: { '813A00': 1.0 },
  },
  ['813212']: {
    label: 'Voluntary Health Organizations',
    BEA_CODES: { '813A00': 1.0 },
  },
  ['813219']: {
    label: 'Other Grantmaking and Giving Services',
    BEA_CODES: { '813A00': 1.0 },
  },
  ['813311']: {
    label: 'Human Rights Organizations',
    BEA_CODES: { '813A00': 1.0 },
  },
  ['813312']: {
    label: 'Environment, Conservation and Wildlife Organizations',
    BEA_CODES: { '813A00': 1.0 },
  },
  ['813319']: {
    label: 'Other Social Advocacy Organizations',
    BEA_CODES: { '813A00': 1.0 },
  },
  ['813410']: {
    label: 'Civic and Social Organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['813910']: { label: 'Business Associations', BEA_CODES: { '813B00': 1.0 } },
  ['813920']: {
    label: 'Professional Organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['813930']: {
    label: 'Labor Unions and Similar Labor Organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['813940']: {
    label: 'Political Organizations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['813990']: {
    label:
      'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['814110']: { label: 'Private Households', BEA_CODES: { '814000': 1.0 } },
  ['921110']: {
    label: 'Executive Offices',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['921120']: {
    label: 'Legislative Bodies',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['921130']: {
    label: 'Public Finance Activities',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['921140']: {
    label: 'Executive and Legislative Offices, Combined',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['921150']: {
    label: 'American Indian and Alaska Native Tribal Governments',
    BEA_CODES: { GSLGO: 1.0 },
  },
  ['921190']: {
    label: 'Other General Government Support',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['922110']: {
    label: 'Courts',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['922120']: {
    label: 'Police Protection',
    BEA_CODES: {
      GSLGO: 0.62248,
      S00102: 0.0115953,
      S00203: 0.0717087,
      S00600: 0.2942159,
    },
  },
  ['922130']: {
    label: 'Legal Counsel and Prosecution',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['922140']: {
    label: 'Correctional Institutions',
    BEA_CODES: {
      GSLGO: 0.62248,
      S00102: 0.0115953,
      S00203: 0.0717087,
      S00600: 0.2942159,
    },
  },
  ['922150']: {
    label: 'Parole Offices and Probation Offices',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['922160']: {
    label: 'Fire Protection',
    BEA_CODES: { GSLGO: 0.6297825, S00203: 0.07255, S00600: 0.2976675 },
  },
  ['922190']: {
    label: 'Other Justice, Public Order, and Safety Activities',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['923110']: {
    label: 'Administration of Education Programs',
    BEA_CODES: { GSLGE: 0.6731901, S00600: 0.3268099 },
  },
  ['923120']: {
    label: 'Administration of Public Health Programs',
    BEA_CODES: { GSLGH: 0.1463234, S00600: 0.8536766 },
  },
  ['923130']: {
    label:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)",
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['923140']: {
    label: "Administration of Veterans' Affairs",
    BEA_CODES: { S00600: 1.0 },
  },
  ['924110']: {
    label:
      'Administration of Air and Water Resource and Solid Waste Management Programs',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['924120']: {
    label: 'Administration of Conservation Programs',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['925110']: {
    label: 'Administration of Housing Programs',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['925120']: {
    label:
      'Administration of Urban Planning and Community and Rural Development',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['926110']: {
    label: 'Administration of General Economic Programs',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['926120']: {
    label: 'Regulation and Administration of Transportation Programs',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['926130']: {
    label:
      'Regulation and Administration of Communications, Electric, Gas, and Other Utilities',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['926140']: {
    label: 'Regulation of Agricultural Marketing and Commodities',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['926150']: {
    label:
      'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors',
    BEA_CODES: { GSLGO: 0.6790474, S00600: 0.3209526 },
  },
  ['927110']: {
    label: 'Space Research and Technology',
    BEA_CODES: { S00102: 1.0 },
  },
  ['928110']: { label: 'National Security', BEA_CODES: { S00500: 1.0 } },
  ['928120']: { label: 'International Affairs', BEA_CODES: { S00600: 1.0 } },
};
