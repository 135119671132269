import Icon, { IconProps } from '../Icon';
const JudicialScalesIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M19 7a1 1 0 0 1 .936.649l3 8A1 1 0 0 1 22.6 16.8 6.008 6.008 0 0 1 19 18a6.008 6.008 0 0 1-3.598-1.199 1 1 0 0 1-.338-1.152l3-8A1 1 0 0 1 19 7Zm-1.776 8.584a3.996 3.996 0 0 0 3.552 0L19 10.848l-1.776 4.736ZM5 7a1 1 0 0 1 .936.649l3 8A1 1 0 0 1 8.6 16.8 6.008 6.008 0 0 1 5 18a6.008 6.008 0 0 1-3.599-1.199 1 1 0 0 1-.337-1.152l3-8A1 1 0 0 1 5 7Zm-1.776 8.584a3.996 3.996 0 0 0 3.552 0L5 10.848l-1.776 4.736ZM6 21a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12 2a1 1 0 0 1 1 1v18a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M11.553 4.106a1 1 0 0 1 .894 0C14.391 5.077 17.227 6 19 6h2a1 1 0 1 1 0 2h-2c-2.07 0-4.953-.932-7-1.89C9.952 7.069 7.07 8 5 8H3a1 1 0 0 1 0-2h2c1.773 0 4.61-.923 6.553-1.894Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default JudicialScalesIcon;
