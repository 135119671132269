import { AtBuildingBartRow } from '../measurement/facilitiesSchemaUtilsUniversal';
import { msg } from '@lingui/core/macro';
import { MessageDescriptor } from '@lingui/core';

export const BART_LEASE_TYPE_LABELS: Record<
  NonNullable<AtBuildingBartRow['building_lease_type']>,
  MessageDescriptor
> = {
  coworking_or_serviced_office: msg({
    context: 'Building lease type label',
    message: 'Coworking or serviced office',
  }),
  upstream_lease: msg({
    context: 'Building lease type label',
    message: 'Leased',
  }),
  upstream_sublease: msg({
    context: 'Building lease type label',
    message: 'Upstream sublease',
  }),
  owned: msg({ context: 'Building lease type label', message: 'Owned' }),
  downstream_lease_customer_paying_utilities: msg({
    context: 'Building lease type label',
    message: 'Downstream lease, paying utilities',
  }),
  downstream_lease_customer_not_paying_utilities: msg({
    context: 'Building lease type label',
    message: 'Downstream lease, not paying utilities',
  }),
  downstream_sublease_customer_not_paying_utilities: msg({
    context: 'Building lease type label',
    message: 'Downstream sublease, not paying utilities',
  }),
  downstream_sublease_customer_paying_utilities: msg({
    context: 'Building lease type label',
    message: 'Downstream sublease, paying utilities',
  }),
  franchise: msg({
    context: 'Building lease type label',
    message: 'Franchise',
  }),
  sold_building: msg({
    context: 'Building lease type label',
    message: 'Sold building',
  }),
} as const;
