import { forwardRef } from 'react';
import { Trans } from '@lingui/react/macro';
import { Chip, ChipProps } from '@mui/material';
import { CODE_FONT_FAMILY, mixinSx } from '@watershed/style/styleUtils';

export default forwardRef(function BetaChip(
  { size, icon, sx }: Pick<ChipProps, 'size' | 'icon' | 'sx'>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const offset = -2;
  return (
    <Chip
      variant="filled"
      color="primary"
      label={
        <Trans context="Chip copy indicating a feature is in the Beta stage">
          Beta
        </Trans>
      }
      size={size}
      icon={icon}
      ref={ref}
      sx={mixinSx(
        {
          position: 'relative',
          borderRadius: 0,
          color: 'cobalt50',
          backgroundColor: 'cobalt05',
          fontFamily: CODE_FONT_FAMILY,
          fontSize: '12px',
          '&::before, &::after': {
            content: '""',
            position: 'absolute',
          },
          '&::before': {
            insetBlock: offset,
            insetInline: 0,
            borderInline: '1px dashed',
            borderColor: 'cobalt20',
          },
          '&::after': {
            insetBlock: 0,
            insetInline: offset,
            borderBlock: '1px solid',
            borderColor: 'cobalt20',
          },
        },
        sx
      )}
    />
  );
});
