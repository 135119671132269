import { Box, Tooltip as _Tooltip, TooltipProps } from '@mui/material';
import InfoIcon from '@watershed/icons/components/Info';
import { useState } from 'react';

export function Tooltip({ children, ...restProps }: TooltipProps) {
  // MUI Tooltip is a bit expensive to render, which can make scrolling feel
  // sluggish on the table. To mitigate, only render the tooltip after mouse
  // enter. https://github.com/mui/material-ui/issues/27057
  const [renderTooltip, setRenderTooltip] = useState(false);
  return (
    <div onMouseEnter={() => !renderTooltip && setRenderTooltip(true)}>
      {!renderTooltip && children}
      {renderTooltip && <_Tooltip {...restProps}>{children}</_Tooltip>}
    </div>
  );
}

export const TextWithTooltip = ({
  text,
  tooltip,
  showInfoIcon,
  disableInteractive,
}: {
  text: string;
  tooltip: React.ReactNode;
  showInfoIcon?: boolean;
  disableInteractive?: boolean;
}) => (
  <Tooltip title={tooltip} disableInteractive={disableInteractive}>
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Box>
      {showInfoIcon && (
        <InfoIcon color="secondary.dark" sx={{ ml: 1, width: 20 }} />
      )}
    </Box>
  </Tooltip>
);
