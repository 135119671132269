import { pipe, tap } from 'wonka';
import { Exchange } from 'urql';
import { LocalizationEnv } from '@watershed/intl/constants';
import { HeaderKeys } from '@watershed/shared-universal/utils/constants';

type GetLocale = () => string;
type GetLocalizationEnv = () => LocalizationEnv;

/**
 * This exchange adds the Accept-Language header to all GraphQL requests. The
 * reason why this isn't just part of the fetchOptions is because it turns out
 * that if a query is refetched due to staleness, the fetchOptions are not
 * recalculated, so the Accept-Language header would not be updated. So if we
 * want to support changing the language on the fly, we need another exchange
 * to inject the accept-language header correctly.
 */
const localeHeaderExchange: (
  getLocale: GetLocale,
  getLocalizationEnv: GetLocalizationEnv
) => Exchange =
  (getLocale: GetLocale, getLocalizationEnv: GetLocalizationEnv) =>
  ({ forward }) => {
    return (ops$) =>
      pipe(
        ops$,
        tap((op) => {
          if (typeof op.context.fetchOptions === 'function') {
            op.context.fetchOptions = op.context.fetchOptions();
          }
          op.context.fetchOptions = {
            ...(op.context.fetchOptions || {}),
            headers: {
              ...(op.context.fetchOptions?.headers || {}),
              [HeaderKeys.localizationEnv]: getLocalizationEnv(),
              'Accept-Language': getLocale(),
            },
          };
        }),
        forward
      );
  };

export default localeHeaderExchange;
