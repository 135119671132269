/**
 * Determines whether two numbers are close enough to be considered equal, by
 * determining whether absolute error and relative error are both greater than a
 * given precision.
 */
export default function numberIsCloseTo(
  value: number,
  target: number,
  precision: number = 1e-6
): boolean {
  const absoluteError = Math.abs(target - value);
  const relativeError = Math.abs(absoluteError / target);
  return absoluteError <= precision || relativeError <= precision;
}
