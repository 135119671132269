import Icon, { IconProps } from '../Icon';
const SidebarLeftOpenIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M14.707 8.293a1 1 0 1 0-1.414 1.414L15.586 12l-2.293 2.293a1 1 0 0 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3Z" />
    <path
      fillRule="evenodd"
      d="M5 22h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3ZM5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3V4H5Zm5 0v16h9a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-9Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SidebarLeftOpenIcon;
