import {
  makeCustomErrorInvariant,
  makeRethrower,
  CustomErrorInvariant,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';

/**
 * Only use UnexpectedError if there is no more specific code to use. This
 * is a catch-all for unanticipated failures.
 */
export class UnexpectedError extends WatershedError {
  status = 500;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('UNEXPECTED', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnexpectedError);
    }
    Object.setPrototypeOf(this, UnexpectedError.prototype);
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(UnexpectedError);
  static wrapAndRethrow = makeRethrower(UnexpectedError);
}
