import {
  Slide,
  Typography,
  Box,
  Stack,
  SxProps,
  SlideProps,
} from '@mui/material';
import CloseIcon from '@watershed/icons/components/Close';
import IconButton, { IconButtonProps } from '../IconButton';
import { mixinSx } from '@watershed/style/styleUtils';
import { Theme } from '@mui/system';
import usePaletteUtils from '../../hooks/usePaletteUtils';
import { useClickAway } from 'react-use';
import { useRef } from 'react';

/** A subset of IconButtonProps; feel free to expand this if you have a need! */
type CloseButtonProps = Partial<Pick<IconButtonProps, 'sx' | 'size'>>;

export default function SheetPanel({
  title,
  subtitle,
  children,
  onClose,
  open,
  hideCloseButton,
  closeOnBackdropClick,
  closeButtonProps,
  width = 400,
  headerSx,
  contentSx,
  position = 'absolute',
  sx,
  timeout,
}: {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  hideCloseButton?: boolean;
  closeOnBackdropClick?: boolean;
  closeButtonProps?: CloseButtonProps;
  width?: number | string;
  headerSx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
  position?: 'absolute' | 'fixed';
  sx?: SxProps<Theme>;
  timeout?: SlideProps['timeout'];
}) {
  const paletteUtils = usePaletteUtils();

  // Close the panel when clicking outside of it if configured to do so
  const ref = useRef(null);
  useClickAway(ref, (e) => {
    if (closeOnBackdropClick) {
      onClose();
    }
  });

  return (
    <Slide
      ref={ref}
      direction="left"
      in={open}
      mountOnEnter
      unmountOnExit
      timeout={timeout}
    >
      <Box
        component="aside"
        sx={mixinSx(
          {
            position,
            width,
            borderLeft: (theme) =>
              `1px solid ${theme.palette.background.default}`,
            top: 0,
            bottom: 0,
            right: 0,
            backgroundColor: (theme) => theme.palette.background.paper,
            boxShadow: paletteUtils.boxShadowMenu,
            zIndex: 1,
          },
          sx
        )}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'auto 1fr',
          }}
        >
          <Stack
            gap={1}
            sx={mixinSx(
              {
                borderBottom: (theme: Theme) =>
                  `1px solid ${theme.palette.background.default}`,
                padding: 3,
                display: 'flex',
                flexDirection: 'column',
              },
              headerSx
            )}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box style={{ flexGrow: 1 }}>
                {typeof title === 'string' ? (
                  <Typography variant="h2">{title}</Typography>
                ) : (
                  title
                )}
              </Box>
              {!hideCloseButton && (
                <IconButton
                  onClick={onClose}
                  size={closeButtonProps?.size ?? 'large'}
                  aria-label="Close panel"
                  sx={closeButtonProps?.sx}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            {typeof subtitle === 'string' ? (
              <Typography variant="body2">{subtitle}</Typography>
            ) : (
              subtitle
            )}
          </Stack>
          <Box
            sx={mixinSx(
              {
                padding: 3,
                overflow: 'auto',
                height: '100%',
              },
              contentSx
            )}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Slide>
  );
}
