import must from '@watershed/shared-universal/utils/must';
import { createStore } from 'jotai';
import React, { createContext, useContext, useMemo } from 'react';

/**
 * @deprecated You probably just want to use ScopeProvider from jotai-scope instead
 * See BiJotaiScope for an example
 */

export function makeJotaiContext(name: string) {
  const contextSymbol = Symbol(name);
  const JotaiStoreContext = createContext<{
    [contextSymbol]: ReturnType<typeof createStore>;
  } | null>(null);

  function useJotaiStore(): ReturnType<typeof createStore>;
  function useJotaiStore(
    allowGlobal: true
  ): ReturnType<typeof createStore> | undefined;
  function useJotaiStore(allowGlobal?: true) {
    const contextValue = useContext(JotaiStoreContext);
    const store = contextValue?.[contextSymbol];
    return allowGlobal
      ? store
      : must(
          store,
          `[Jotai context: ${name}] using contextual atoms must be used inside a JotaiStoreProvider`
        );
  }
  function JotaiStoreProvider({ children }: { children: React.ReactNode }) {
    const value = useMemo(() => ({ [contextSymbol]: createStore() }), []);
    return (
      <JotaiStoreContext.Provider value={value}>
        {children}
      </JotaiStoreContext.Provider>
    );
  }

  return {
    useJotaiStore,
    JotaiStoreProvider,
  };
}
