import { GQPermissionType } from '../generated/graphql';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { PermissionType } from './permissionUtils';

export const PERMISSION_PARENTS: {
  [key in GQPermissionType]?: Array<GQPermissionType>;
} = {
  // Admin magicsauce
  [GQPermissionType.Admin]: [GQPermissionType.WatershedAdmin],
  [GQPermissionType.CorporateAdmin]: [GQPermissionType.Admin],
  [GQPermissionType.ManageMarketplacePurchases]: [
    GQPermissionType.CorporateAdmin,
  ],
  [GQPermissionType.ManageCompanyTags]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageOrgHierarchy]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ViewEmployeeReport]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageSingleSignOn]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageMeasurement]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageReductionPlans]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageSuppliers]: [GQPermissionType.CorporateAdmin],
  [GQPermissionType.ManageDisclosures]: [GQPermissionType.CorporateAdmin],

  // Manage/View and other hierarchies
  [GQPermissionType.ViewFootprintDetail]: [
    GQPermissionType.ViewReductions,
    GQPermissionType.ManageMeasurement,
    // Note: This doesn't seem right but we don't want to hit permissions
    // landmines with Finance Reporting
    GQPermissionType.FinanceAdmin,
  ],
  [GQPermissionType.ViewAuditDetail]: [GQPermissionType.ViewFootprintDetail],
  [GQPermissionType.ViewReductions]: [GQPermissionType.ManageReductionPlans],
  [GQPermissionType.ManageDataset]: [GQPermissionType.ManageMeasurement],
  [GQPermissionType.ManageDatasource]: [GQPermissionType.ManageDataset],
  [GQPermissionType.ApproveDatasource]: [GQPermissionType.ManageMeasurement],
  [GQPermissionType.EditReportQuestionInstance]: [
    GQPermissionType.ViewFootprintDetail,
  ],
  [GQPermissionType.ViewReportQuestionInstance]: [
    GQPermissionType.EditReportQuestionInstance,
  ],

  // Finance
  [GQPermissionType.FinanceAdmin]: [GQPermissionType.Admin],
  [GQPermissionType.ManageFund]: [GQPermissionType.FinanceAdmin],
  [GQPermissionType.FinanceReadOnly]: [GQPermissionType.ManageFund],

  // Learning Hub
  // This is unique permission in that the learning hub is not actually gated by
  // any permission, but we want to allow orgs to create a user that can _exclusively_
  // access learning hub.

  // It's only part of the permission hierarchy so that admin users can grant it to others.
  [GQPermissionType.ViewLearningHub]: [
    GQPermissionType.CorporateAdmin,
    GQPermissionType.FinanceAdmin,
  ],
};

export function getPermissionsHierarchy(
  permission: GQPermissionType
): Array<GQPermissionType> {
  return uniq(
    [
      permission,
      ...(PERMISSION_PARENTS[permission] || []).map((parent) =>
        getPermissionsHierarchy(parent)
      ),
    ].flat()
  );
}

export type SourceObjectWithPermissionDelegate = {
  id: string;
  permissionDelegateId?: string | null;
};

export function hasPermissionItem({
  permission,
  objectId,
  source,
  allowPermission,
  allowAnyObject,
}: {
  // This is the permission that the user has
  permission: string;

  // This is the permission being tested
  allowPermission: PermissionType;

  // This is the objectId associated with the permision that the user has
  objectId?: string | null;

  // This is the object being tested
  source?: SourceObjectWithPermissionDelegate | null;

  // If true, we don't check the object ID (useful for things like determining if a user can access a page)
  allowAnyObject?: boolean;
}): boolean {
  return !!getPermissionOrigin({
    permission,
    objectId,
    source,
    allowPermission,
    allowAnyObject,
  });
}

interface PermissionOrigin {
  permission: string;
  objectId?: string | null;
}

export function getPermissionOrigin({
  permission,
  objectId,
  source,
  allowPermission,
  allowAnyObject,
}: {
  // This is the permission that the user has
  permission: string;

  // This is the permission to check
  allowPermission: PermissionType;

  // This is the objectId associated with the permision that the user has
  objectId?: string | null;

  // This is the object being tested
  source?: SourceObjectWithPermissionDelegate | null;

  // If true, we don't check the object ID (useful for things like determining if a user can access a page)
  allowAnyObject?: boolean;
}): PermissionOrigin | null {
  // Check the parent recursively
  const parentOrigins =
    PERMISSION_PARENTS[allowPermission]
      ?.map((parent) =>
        getPermissionOrigin({
          permission,
          objectId,
          source,
          allowPermission: parent,
        })
      )
      .filter(isNotNullish) || [];

  if (parentOrigins.length > 0) {
    return parentOrigins[0];
  }

  // This is an easy disqualification; we aren't talking about the same permission
  if (allowPermission !== permission) {
    return null;
  }

  // Indicates that we have a general role that isn't scoped to an object
  if (!objectId) {
    return { permission: allowPermission };
  }

  // In this case, we aren't looking to match on an object anyway (e.g. determining access to an application route)
  if (allowAnyObject) {
    return { permission: allowPermission };
  }

  if (objectId === source?.id || objectId === source?.permissionDelegateId) {
    return { permission: allowPermission, objectId };
  }
  return null;
}

export function getUserOrRolePermissionOrigin({
  permissions,
  allowPermission,
  source,
}: {
  // Permissions that a user or a role has
  permissions: Array<{
    permission: GQPermissionType;
    objectId?: string | null;
  }>;

  // This is the permission being tested
  allowPermission: GQPermissionType;

  // This is the object being tested
  source?: SourceObjectWithPermissionDelegate | null;
}): PermissionOrigin | null {
  const sortedPermissions = sortBy(
    permissions,
    ({ permission, objectId }) =>
      getPermissionsHierarchy(permission).length + (!!objectId ? 1 : 0)
  );

  for (const p of sortedPermissions) {
    const origin = getPermissionOrigin({
      permission: p.permission,
      objectId: p.objectId,
      allowPermission,
      source,
    });
    if (!!origin) {
      return origin;
    }
  }
  return null;
}

// Just the minimal fields necessary to determine indirect access.
interface PermissionOriginForHasIndirectAccess {
  id: string;
  permission: {
    id: string;
    permission: GQPermissionType;
    object: {
      id: string;
    } | null;
  } | null;
}

export function hasIndirectAccess(
  permissionOrigin: PermissionOriginForHasIndirectAccess | null,
  permissionType: GQPermissionType,
  options: {
    // Is there an `objectType` being used?
    hasObjectType?: boolean;
  } = {}
): boolean {
  const { hasObjectType = false } = options;

  // If null, does not have access.
  if (permissionOrigin === null) return false;
  const { permission } = permissionOrigin;

  // When `permission` is null this means that the permission is granted due to
  // the user's `role`.
  if (permission === null) return true;

  // If they have the `permission` for a reason other than `permissionType`,
  // then it must be indirect access.
  if (permission.permission !== permissionType) return true;

  // If we asked for a specific `objectId`, but the permission is for _all_
  // objects for this `permissionType`, then it is indirect access.
  if (hasObjectType && permission.object === null) return true;

  return false;
}
