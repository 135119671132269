import {
  GQCarbonNeutralCommitment,
  GQCleanEnergyCommitment,
  GQCompanyClimateCommitment,
  GQCompanyClimateCommitmentKind,
  GQNetZeroCommitment,
  GQSbtTargetClassification,
  GQScienceBasedTargetCommitment,
} from '../generated/graphql';
import orderBy from 'lodash/orderBy';

export const SORTED_KINDS: Array<GQCompanyClimateCommitmentKind> = [
  GQCompanyClimateCommitmentKind.CleanEnergy,
  GQCompanyClimateCommitmentKind.ScienceBasedTarget,
  GQCompanyClimateCommitmentKind.NetZero,
  GQCompanyClimateCommitmentKind.CarbonNeutral,
];

export const COMMITMENT_KIND_DISPLAY_NAMES: Record<
  GQCompanyClimateCommitmentKind,
  string
> = {
  [GQCompanyClimateCommitmentKind.CleanEnergy]: 'Clean energy',
  [GQCompanyClimateCommitmentKind.ScienceBasedTarget]: 'Science-based target',
  [GQCompanyClimateCommitmentKind.NetZero]: 'Net zero',
  [GQCompanyClimateCommitmentKind.CarbonNeutral]: 'Carbon neutral',
};

export function isCarbonNeutralCommitment(
  commitment: GQCompanyClimateCommitment
): commitment is GQCarbonNeutralCommitment {
  return commitment.kind === GQCompanyClimateCommitmentKind.CarbonNeutral;
}

export function isNetZeroCommitment(
  commitment: GQCompanyClimateCommitment
): commitment is GQNetZeroCommitment {
  return commitment.kind === GQCompanyClimateCommitmentKind.NetZero;
}

export function isScienceBasedTargetCommitment(
  commitment: GQCompanyClimateCommitment
): commitment is GQScienceBasedTargetCommitment {
  return commitment.kind === GQCompanyClimateCommitmentKind.ScienceBasedTarget;
}

export function isCleanEnergyCommitment(
  commitment: Pick<GQCompanyClimateCommitment, 'kind'>
): commitment is GQCleanEnergyCommitment {
  return commitment.kind === GQCompanyClimateCommitmentKind.CleanEnergy;
}

type CommitmentForCleanPower = {
  description: string | null;
  externalUrl: string | null;
  commitmentMadeDate: Date | null;
  kind: GQCompanyClimateCommitmentKind;
};

/**
 * Returns a single commitment (or null) that is the top commitment for
 * use in clean power products that want to show peer commitments.
 * It uses a clean power commitment if there is one that isn't too old or else
 * the newest one. It also requires that the program has a url and that the
 * commitment has a description.
 */
export function getCompanyClimateCommitmentForCleanPower<
  T extends CommitmentForCleanPower,
>(commitments: Array<T>): T | null {
  const sortedCommitments = orderBy(
    commitments,
    [
      (commitment) => SORTED_KINDS.indexOf(commitment.kind),
      (commitment) => commitment.commitmentMadeDate,
    ],
    ['asc', 'desc']
  );

  return sortedCommitments[0] ?? null;
}

export const HumanNameForTargetClassification: Record<
  GQSbtTargetClassification,
  string
> = {
  [GQSbtTargetClassification.TwoDegrees]: '2º',
  [GQSbtTargetClassification.OnePointFiveDegrees]: '1.5º',
  [GQSbtTargetClassification.WellBelowTwoDegrees]: 'Well-below 2º',
};
