import { Stack, Typography } from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';

export default function LoadingPage({
  loadingMessage,
}: {
  loadingMessage?: string | null;
}) {
  return (
    <Stack
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      paddingY={6}
      gap={2}
    >
      <CircularProgress />
      {loadingMessage && (
        <Typography variant="body2">{loadingMessage}</Typography>
      )}
    </Stack>
  );
}
