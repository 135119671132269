import { ChipProps } from '@mui/material';
import { t } from '@lingui/core/macro';
import { DateTime } from 'luxon';
import { formatNumberForDisplay } from '@watershed/shared-frontend/components/emissionsModels/calculationExplanation/utils';
import {
  GQMeasurementProjectStatus,
  GQUserUploadAttempt,
  GQUserUploadedTableSchemaColumnKind,
  GQUserUploadForDatasetPageFragment,
  GQUserUploadProcessingMode,
  GQUserUploadSource,
  GQUutSchemaForIngestionFragment,
} from '@watershed/shared-universal/generated/graphql';
import {
  DataPreviewUntyped,
  getUserVisibleUploads,
} from '@watershed/shared-universal/measurement/userUploadUtils';
// TODO: i18n (please resolve or remove this TODO line if legit)
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { humanize } from '@watershed/shared-universal/utils/helpers';
import isNullish from '@watershed/shared-util/isNullish';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import {
  GridColumns,
  GridValueFormatterParams,
} from '@watershed/ui-core/components/DataGrid/DataGrid';
import keyBy from 'lodash/keyBy';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { parseDate } from '@watershed/shared-universal/utils/displayValues';
import DateTimeUtils from '@watershed/shared-universal/utils/DateTimeUtils';
import assertNever from '@watershed/shared-util/assertNever';
import useLocale from '@watershed/intl/frontend/useLocale';

export function getMeasurementCompleteMessage() {
  return t`The measurement is complete`;
}

export function getUploadedByString(
  uploadAttempt:
    | (Pick<GQUserUploadAttempt, 'userUploadSource'> & {
        uploader: { name: string } | null;
      })
    | null
) {
  if (uploadAttempt?.uploader?.name) {
    return uploadAttempt.uploader.name;
  }
  return uploadAttempt?.userUploadSource === GQUserUploadSource.PublicApi
    ? 'API'
    : '-';
}

export function chipPropsByMeasurementStatus(
  status: GQMeasurementProjectStatus
): Required<Pick<ChipProps, 'label' | 'color'>> {
  switch (status) {
    case GQMeasurementProjectStatus.InProgress:
      return {
        label: t({
          message: 'Active',
          context: 'Measurement status is active',
        }),
        color: 'primary',
      };
    case GQMeasurementProjectStatus.Planned:
      return {
        label: t({
          message: 'Planned',
          context: 'Measurement status is planned',
        }),
        color: 'secondary',
      };
    case GQMeasurementProjectStatus.Completed:
      return {
        label: t({
          message: 'Complete',
          context: 'Measurement status is complete',
        }),
        color: 'success',
      };
    default:
      assertNever(status);
  }
}

function columnIndexToSchemaMap(
  schema: Array<GQUutSchemaForIngestionFragment>,
  usesLegacyFilePreview: boolean
) {
  const key = usesLegacyFilePreview ? 'name' : 'alias';
  return keyBy(schema, (s) => s[key]);
}

/**
 * Convert columnar row preview data into rows for DataGrid based on the given schema.
 * This should probably live in a utils file somewhere.
 */
function convertDataRowFromSchemaMap({
  row,
  columnSchemaMap,
}: {
  row: Array<{ columnName: string; value: string }>;
  columnSchemaMap: { [key: string]: GQUutSchemaForIngestionFragment };
}) {
  return Object.fromEntries(
    row
      .map((col) => {
        if (col.columnName === 'index') {
          return ['index', Number(col.value)];
        } else {
          const columnSchema = columnSchemaMap[col.columnName];
          if (columnSchema) {
            const value =
              columnSchemaMap[col.columnName]?.kind ===
                GQUserUploadedTableSchemaColumnKind.Float &&
              !isNaN(Number(col.value))
                ? Number(col.value)
                : col.value;
            return [columnSchema.alias, value];
          } else {
            return null;
          }
        }
      })
      .filter(isNotNullish)
  );
}

function getUserUploadedTables(userUpload: GQUserUploadForDatasetPageFragment) {
  return userUpload.userVisibleAttempt?.latestUutsIfRevisionExists &&
    userUpload.userVisibleAttempt.latestUutsIfRevisionExists.length > 0
    ? userUpload.userVisibleAttempt.latestUutsIfRevisionExists
    : (userUpload.userVisibleAttempt?.userUploadedTables ?? []);
}

export function useUutSchemasForColumns(
  schemas: Array<GQUutSchemaForIngestionFragment>
): GridColumns {
  const locale = useLocale();
  return uniqBy(schemas, (s) => s.alias).map((schema) => {
    const type =
      schema.kind === GQUserUploadedTableSchemaColumnKind.Float
        ? 'number'
        : schema.kind === GQUserUploadedTableSchemaColumnKind.Date
          ? 'date'
          : undefined;
    return {
      field: schema.alias,
      headerName: humanize(schema.name),
      type,
      aggregable:
        schema.kind === GQUserUploadedTableSchemaColumnKind.Float
          ? true
          : false,
      flex: 1,
      minWidth: type === 'date' ? 160 : 120,
      valueGetter: ({
        value,
      }: GridValueFormatterParams<string | number | null | undefined>) => {
        return type === 'date' ? parseDate(value) : value;
      },
      valueFormatter:
        type === 'date'
          ? ({ value }: GridValueFormatterParams<Date | null>) => {
              if (!value) {
                return null;
              }
              return DateTimeUtils.formatDate(
                DateTime.fromJSDate(value).toUTC(),
                { format: 'med', locale }
              );
            }
          : ({
              value,
            }: GridValueFormatterParams<
              string | number | null | undefined
            >) => {
              if (isNullish(value)) {
                return value;
              }
              if (typeof value === 'number') {
                const { rounded } = formatNumberForDisplay(value, {
                  locale,
                });
                return rounded;
              }
              return value;
            },
    };
  });
}

export function useDataPreviewForDataGrid(
  allUserUploads: Array<GQUserUploadForDatasetPageFragment>
) {
  // Get user visible uploads. This function should move to the server and
  // then we can delete this here.
  const userUploads = getUserVisibleUploads(allUserUploads);
  const userUploadedTables = userUploads.flatMap(getUserUploadedTables);
  const previewUutRows = userUploads.flatMap((uu) =>
    getUserUploadedTables(uu).flatMap((uut) => {
      if (!uut.schema) return [];
      // Both rawDataPreview and dataPreview don't include the header row.
      // Prefer rawDataPreview if present; it's a more literal view of actual file
      // contents. Files after mid-2022 should have rawPreviewData.
      const usesLegacyFilePreview =
        uu.processingMode === GQUserUploadProcessingMode.Legacy;
      const columnMap = columnIndexToSchemaMap(
        uut.schema,
        usesLegacyFilePreview
      );
      const dataRows: DataPreviewUntyped = // UntypedData, but guaranteed at runtime
        usesLegacyFilePreview
          ? // Prefer rawDataPreview if present; it's a more literal view of actual file
            // contents. Files after mid-2022 should have rawPreviewData.
            (uut.rawDataPreview?.dataUntyped ?? uut.dataPreview?.dataUntyped)
          : uut.dataPreview?.dataUntyped;
      return (dataRows ?? [])
        .filter(
          // Filter out "separator" rows from real data rows.
          (row) => !(row[0].columnName === 'index' && row[0].value === '...')
        )
        .map((row, rowIndex) => ({
          id: `${uut.id}-${rowIndex}`,
          upload_name: uu.name,
          ...convertDataRowFromSchemaMap({
            row,
            columnSchemaMap: columnMap,
          }),
        }));
    })
  );
  const columns = useUutSchemasForColumns(
    userUploadedTables.flatMap((uut) => uut.schema).filter(isNotNullish)
  );
  if (userUploads.length > 1) {
    columns.splice(0, 0, {
      field: 'upload_name',
      headerName: 'Input file',
      minWidth: 250,
    });
  }
  const totalRowCount = sum(
    userUploadedTables.flatMap((uut) => uut.numRows ?? 0)
  );
  return {
    rows: previewUutRows,
    columns,
    totalRowCount,
  };
}
