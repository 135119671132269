import { useMemo } from 'react';
import {
  Store,
  RecordSource,
  Environment,
  Network,
  Observable,
  GraphQLSingularResponse,
  RequestParameters,
  Variables,
} from 'relay-runtime';
import { RelayEnvironmentProvider } from 'react-relay';

import fetchMultipart from 'fetch-multipart-graphql';

type DeferResponse =
  | {
      hasNext?: boolean;
      incremental: Array<GraphQLSingularResponse>;
    }
  | { data: GraphQLSingularResponse; hasNext?: boolean }
  | GraphQLSingularResponse;

type FlattenedDeferResponse = {
  data: ReadonlyArray<GraphQLSingularResponse>;
  isLast: boolean;
};

function flattenDeferResponse(
  responses: Array<DeferResponse>
): FlattenedDeferResponse {
  const formatted: ReadonlyArray<GraphQLSingularResponse> = responses.flatMap(
    (part) => {
      if ('incremental' in part) {
        return part.incremental;
      }

      return [part];
    }
  );

  const anyOfTheResponsesAreTheLast = responses.some((response) => {
    // This query is not deferred and should finish immediately.
    if (!response.hasOwnProperty('hasNext')) {
      return true;
    }

    if ('hasNext' in response) {
      return !response.hasNext;
    }

    return false;
  });

  if (anyOfTheResponsesAreTheLast) {
    const lastPart = formatted[formatted.length - 1];

    if (lastPart) {
      // Shoutout the Coinbase folks https://github.com/facebook/relay/issues/3904
      lastPart.extensions = { is_final: true };
    }
  }

  return { data: formatted, isLast: anyOfTheResponsesAreTheLast };
}

const fetchFn = (operation: RequestParameters, variables: Variables) => {
  return Observable.create((sink) => {
    fetchMultipart('/graphql', {
      method: 'POST',
      headers: {
        accept: 'multipart/mixed; deferSpec=20220824, application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
      credentials: 'same-origin',
      onNext: (responses: Array<DeferResponse>) => {
        const { data, isLast } = flattenDeferResponse(responses);

        data.forEach((part) => {
          sink.next(part);
        });

        if (isLast) {
          sink.complete();
        }
      },
      onError: (err) => sink.error(err as Error),
      onComplete: () => sink.complete(),
    });
  });
};

export function createEnvironment() {
  const network = Network.create(fetchFn);
  const store = new Store(new RecordSource());
  return new Environment({ store, network });
}
export default function RelayEnvironment({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const environment = useMemo(() => {
    return createEnvironment();
  }, []);

  return (
    <RelayEnvironmentProvider environment={environment}>
      {children}
    </RelayEnvironmentProvider>
  );
}
