// TODO(LOC-32) Internationalize or replace these APIs
import { DateTime } from 'luxon';
import assertNever from '@watershed/shared-util/assertNever';
import { pluralize } from './helpers';
import normalizeNegativeZero from './normalizeNegativeZero';
import { smartSentenceCase } from './helpers';
import { formatRelativeTime } from '@watershed/intl/formatters';
import { SupportedLocale } from '@watershed/intl/constants';
import { i18n } from '@watershed/intl';

export interface RelativeTimeProps {
  /**
   * If the formatted string prints `just now` instead of `now` when the
   * elapsed time is under 1 minute.
   */
  hasJust?: true;

  /**
   * Sometimes you want to show days until the 6 week mark, or weeks until
   * the 4 month mark, etc. This lets you customize those thresholds. The
   * object here should map from unit -> the minimum number of units to
   * render at that unit.
   *
   * For example, consider a date that renders as "3 weeks ago" with defaults.
   * If you pass `{ week: 4 }` as the custom threshold, the same date
   * would render as "21 days ago" since 3 is less than our threshold
   * of 4 for rendering weeks.
   */
  thresholds?: { [key in keyof typeof UNITS]?: number };

  /**
   * Whether to capitalize the first letter of the formatted string.
   */
  sentenceCase?: boolean;

  locale?: SupportedLocale;
}

const UNITS = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  week: (24 * 60 * 60 * 1000 * 30) / 4, // approximate, which is fine!
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
} as const;

const UNIT_KEYS = Object.keys(UNITS) as Array<keyof typeof UNITS>;

/**
 * Useful if you need the formatted time data as well as the actual
 * formatted string.
 */
export function getRelativeTimeData(
  dateOrStr: Date | string,
  props?: RelativeTimeProps
): { roundedValue: number; unit: keyof typeof UNITS } {
  const { thresholds } = props ?? {
    thresholds: undefined,
  };
  const timestamp =
    typeof dateOrStr === 'string' ? new Date(dateOrStr) : dateOrStr;
  const elapsed = timestamp.getTime() - Date.now();

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (const unit of UNIT_KEYS) {
    const elapsedAbs = Math.abs(elapsed);
    const thresholdValue = UNITS[unit];
    if (elapsedAbs > thresholdValue) {
      const roundedValue = Math.round(elapsed / thresholdValue);
      // Skip this one if we have a custom threshold we're over
      if (
        thresholds?.[unit] &&
        (thresholds?.[unit] ?? 0) > Math.abs(roundedValue)
      ) {
        continue;
      }
      return { roundedValue, unit };
    }
  }
  return {
    roundedValue: Math.round(elapsed / UNITS.second),
    unit: 'second',
  };
}

/**
 * Returns a string describing a relative time in human-readable text.
 */
export function getRelativeTime(
  dateOrStr: Date | string,
  props: RelativeTimeProps = {}
): string {
  const locale = props?.locale ?? i18n.locale;

  const { hasJust = false, sentenceCase = false } = props;
  const { roundedValue, unit } = getRelativeTimeData(dateOrStr, props);

  const formattedString = formatRelativeTime(roundedValue, unit, {
    numeric: 'auto',
    locale,
  });
  // Only return "just now" when operating in the `en-US` locale
  const result =
    hasJust && formattedString === 'now' ? `just now` : formattedString;
  if (sentenceCase) {
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/require-locale-argument
    return smartSentenceCase(result);
  }
  return result;
}

/**
 * Returns a string representing the date in human-readable text.
 * If date within last two days, will read as "Today at [TIME]"
 * or "Yesterday at [TIME]", otherwise will read as
 * "MMM DD, YYYY at [TIME]".
 */
export function getTodayYesterdayOrDateTimeString(
  date: Date,
  opts?: { sentenceCase?: boolean }
): string {
  const today = new Date();

  const todayWithoutTime = new Date(today);
  todayWithoutTime.setHours(0, 0, 0, 0);
  const dateWithoutTime = new Date(date);
  dateWithoutTime.setHours(0, 0, 0, 0);

  const localeTimeString = date.toLocaleTimeString(
    undefined,
    DateTime.TIME_SIMPLE
  );
  let result;

  if (date.toDateString() === today.toDateString()) {
    result = `today at ${localeTimeString}`;
  } else if (
    // this is a bit fragile as we're dropping the time
    // on the dates and implicitly assuming the browser's
    // timezone is the same as the passed in `date`
    (todayWithoutTime.getTime() - dateWithoutTime.getTime()) / UNITS.day ===
    1
  ) {
    result = `yesterday at ${localeTimeString}`;
  } else {
    result = `${date.toLocaleDateString(
      undefined,
      DateTime.DATE_MED
    )} at ${localeTimeString}`;
  }

  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return opts?.sentenceCase ? smartSentenceCase(result) : result;
}

/**
 * getRelativeTime for when we might not have a time yet
 */
export function maybeGetRelativeTime(
  timestamp?: Date | string | null,
  props?: RelativeTimeProps
): string | null {
  if (!timestamp) {
    return null;
  }
  return getRelativeTime(timestamp, props);
}

/**
 * This helper is used for showing relative times in a couples places in product.
 * Each use-case has slightly different needs (e.g. amount of space) and this
 * isn't unified with `RelativeTimestamp` yet.
 */
export function getRelativeTimeForCustomer(timestamp: Date | string): string {
  const { roundedValue, unit } = getRelativeTimeData(timestamp, {});

  if (unit === 'year') {
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/require-locale-argument
    return DateTime.fromJSDate(new Date(timestamp), { zone: 'utc' }).toFormat(
      'MMM d, yyyy'
    );
  }
  if (unit === 'month') {
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/require-locale-argument
    return DateTime.fromJSDate(new Date(timestamp), { zone: 'utc' }).toFormat(
      'MMM d'
    );
  }
  const shortenedUnit = (() => {
    switch (unit) {
      case 'week':
        return 'wk';
      case 'day':
        return 'day';
      case 'hour':
        return 'hr';
      case 'minute':
        return 'min';
      case 'second':
        return 'sec';
      default:
        assertNever(unit);
    }
  })();

  const value = normalizeNegativeZero(-roundedValue);

  return `${pluralize(value, shortenedUnit)} ago`;
}
