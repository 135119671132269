import invariant from 'invariant';
import { BadInputError } from '@watershed/errors/BadInputError';
import {
  GQDataIssueState,
  GQDiscussionAnchor,
  GQDiscussionAnchorPayload,
  GQDiscussionFieldsFragment,
  GQDiscussionProductSection,
  GQDiscussionAnchorReportQuestionInstance,
} from '../generated/graphql';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import assertNever from '@watershed/shared-util/assertNever';
import { t } from '@lingui/core/macro';

export type DiscussionFields = Omit<GQDiscussionFieldsFragment, '__typename'>;

export type CommonDiscussionFields = Omit<DiscussionFields, 'anchor'>;

export type DiscussionAnchor = Omit<
  GQDiscussionAnchor | GQDiscussionAnchorPayload,
  '__typename'
>;

export function getDiscussionAnchorId(input: GQDiscussionAnchor): string {
  const { anchorKey, anchorValue } = getDiscussionAnchorKeyAndValue(input);
  return `discussions-${anchorKey}-${JSON.stringify(anchorValue)}`;
}

export function getDiscussionAnchorKeyAndValue(input: GQDiscussionAnchor): {
  anchorKey: keyof GQDiscussionAnchor;
  anchorValue: string | GQDiscussionAnchorReportQuestionInstance;
  extraContext?: Partial<Pick<GQDiscussionAnchor, 'userUploadTaskId'>>;
} {
  const inputEntries = Object.entries(input);
  const nonNullInputEntries = inputEntries.filter(([_, value]) =>
    isNotNullish(value)
  );
  const buildingAnchor = nonNullInputEntries.find(
    ([key, _]) => key === 'buildingId'
  );
  if (buildingAnchor && buildingAnchor[1]) {
    // Buildings discussions can also have userUploadTaskIds specified. In this
    // case, the building is the anchor, and the userUploadTask is extra context.
    return {
      anchorKey: 'buildingId',
      anchorValue: buildingAnchor[1],
      extraContext: {
        userUploadTaskId: input.userUploadTaskId,
      },
    };
  }
  if (nonNullInputEntries.length === 0) {
    throw new BadInputError('Missing object or product section');
  }
  if (nonNullInputEntries.length > 1) {
    throw new BadInputError('Must have only one object or product section');
  }
  const [key, value] = nonNullInputEntries[0] as [
    keyof GQDiscussionAnchor,
    string | GQDiscussionAnchorReportQuestionInstance,
  ];
  return { anchorKey: key, anchorValue: value };
}

export function isComplete(state: GQDataIssueState): boolean {
  return state === GQDataIssueState.Done;
}

export const DiscussionProductSectionToDisplayName: Record<
  GQDiscussionProductSection,
  string
> = {
  Global: 'General support',
  Exclusions: 'Deduplication',
  Recategorization: 'Categories',
  FootprintQuestion: 'Footprints',
  Facilities: 'Facilities',
  SupplyChain: 'Supply Chain',
  EngagementTaskPortal: 'Task Portal',
  Finance: 'Finance',
  FinanceFootprintAssembly: 'Finance footprint creation',
};

export function getQueryParamForAnchor(anchor: GQDiscussionAnchor): string {
  const { anchorKey, anchorValue } = getDiscussionAnchorKeyAndValue(anchor);
  if (anchorKey === 'productSection') {
    if (anchorValue === GQDiscussionProductSection.Global) {
      return 'inboxDiscussionId';
    }
    invariant(
      typeof anchorValue === 'string',
      'anchorValue is string for productSection'
    );
    return anchorValue + 'DiscussionId';
  } else return anchorKey;
}

export const NEW_COMMENT_DISCUSSION_ID = 'new';

export function getDisplayNameForAnchor(anchor: GQDiscussionAnchor): string {
  const { anchorKey, anchorValue } = getDiscussionAnchorKeyAndValue(anchor);
  switch (anchorKey) {
    case 'productSection':
      return DiscussionProductSectionToDisplayName[
        anchorValue as GQDiscussionProductSection
      ];
    case 'financialsReviewItemId':
    case 'measurementVerificationItemQuestionId':
    case 'userUploadTaskId':
      return t({
        message: 'Data issues',
        context: 'Header label for product section',
      });
    case 'valueMappingTaskId':
      return t({
        message: 'Value mapping',
        context: 'Header label for product section',
      });
    case 'reportQuestionInstance':
      return t({
        message: 'Report question',
        context: 'Header label for product section',
      });
    case 'dataApprovalEventId':
      return t({
        message: 'Data review and approval',
        context: 'Header label for product section',
      });
    case 'buildingId':
      return t({
        message: 'Building',
        context: 'Header label for product section',
      });
    default:
      assertNever(anchorKey);
  }
}

// In approvals land, we need to keep track of where the data issue came from
// to distinguish them in the changelog, which is built off of LogEvents
export enum DataIssueSource {
  ApprovalSupportIssue = 'ApprovalSupportIssue',
  ApprovalEventComment = 'ApprovalEventComment',
  ApprovalChangelogComment = 'ApprovalChangelogComment',
}
