import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/core/macro';

export const UNIT_LABELS: { [key: string]: MessageDescriptor } = {
  sqft: msg({ context: 'Unit label', message: 'Square feet' }),
  square_feet: msg({ context: 'Unit label', message: 'Square feet' }),
  sqm: msg({ context: 'Unit label', message: 'Square meters' }),
  acre: msg({ context: 'Unit label', message: 'Acres' }),
  hectare: msg({ context: 'Unit label', message: 'Hectares' }),
  instance_hours: msg({ context: 'Unit label', message: 'Instance hours' }),
  hotel_nights: msg({
    context: 'Unit label',
    message: 'Hotel nights (room-nights)',
  }),
  gb: msg({ context: 'Unit label', message: 'Storage (GB)' }),
  kw: msg({ context: 'Unit label', message: 'Energy (kW)' }),
  kwh: msg({ context: 'Unit label', message: 'Energy (kWh)' }),
  therm: msg({ context: 'Unit label', message: 'therm' }),
  m3_natural_gas: msg({
    context: 'Unit label',
    message: 'Natural gas (cubic meter)',
  }),
  kg: msg({ context: 'Unit label', message: 'Kilograms' }),
  oz: msg({ context: 'Unit label', message: 'Ounces' }),
  lb: msg({ context: 'Unit label', message: 'Pounds' }),
  dist_km: msg({ context: 'Unit label', message: 'Distance (km)' }),
  tonne_km: msg({ context: 'Unit label', message: 'Tonne-kilometres' }),
  lb_km: msg({ context: 'Unit label', message: 'Kilometer ounces' }),
  lb_mile: msg({ context: 'Unit label', message: 'Mile pounds' }),
  kg_km: msg({ context: 'Unit label', message: 'Kilometer kilograms' }),
  kg_mile: msg({ context: 'Unit label', message: 'Mile kilograms' }),
  oz_km: msg({ context: 'Unit label', message: 'Kilometer ounces' }),
  oz_mile: msg({ context: 'Unit label', message: 'Mile ounces' }),
  miles: msg({ context: 'Unit label', message: 'Miles' }),
  km: msg({ context: 'Unit label', message: 'Kilometers' }),
  distance_miles: msg({ context: 'Unit label', message: 'Distance (miles)' }),
  distance_nautical_miles: msg({
    context: 'Unit label',
    message: 'Nautical miles',
  }),
  trip_count: msg({ context: 'Unit label', message: 'Trip count' }),
  shipment_count: msg({ context: 'Unit label', message: 'Shipment count' }),
  passenger_miles: msg({ context: 'Unit label', message: 'Passenger miles' }),
};
