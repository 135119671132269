import { useMemo } from 'react';
import { YMInterval } from '@watershed/shared-universal/utils/YearMonth';
import { useSupplierFilters } from './SupplierFiltersContext';
import { useUserContext } from '../../../utils/UserContext';
import useSuppliersSettings from './useSuppliersSettings';
import { GQFlags } from '@watershed/shared-universal/generated/graphql';
import { useFeatureFlag } from '../../../utils/FeatureFlag';

export function useSuppliersPageFootprintInterval(): {
  visibleInterval: YMInterval | null;
  footprintInterval: YMInterval | null;
} {
  const isMultipleFootprintsEnabled = useFeatureFlag(
    GQFlags.EnableMultipleFootprints
  );
  const { footprintInterval, companyId } = useUserContext();
  const { suppliersSettings } = useSuppliersSettings(companyId);

  // TODO(SUP-2489): Non-supply chain pages should not be using supplier
  // filters. There's currently a dependency on supplier filters in the
  // permissions dialog (useSuppliersData > useSuppliersPageFootprintInterval >
  // useSupplierFilters). Once we remove that dependency, we should update
  // useSupplierFilters such that it'll always be defined (or error out)
  // since we should only be using it on supply chain pages.
  const supplierFilters = useSupplierFilters();
  const selectedInterval = supplierFilters?.getSelectedInterval() ?? null;
  const visibleInterval = isMultipleFootprintsEnabled
    ? (suppliersSettings?.footprintInterval ?? null)
    : footprintInterval;

  // Keep this memoized to ensure referential equality in order to avoid
  // unnecessary query re-runs because we use this in some urql queries. Note
  // that, as of this writing, urql generates a key based on the query string
  // and variables, so we don't strictly need to memoize this, but that's
  // implementation details that isn't guaranteed in their API.
  const intervals = useMemo(
    () => ({
      visibleInterval,
      footprintInterval: selectedInterval,
    }),
    [visibleInterval, selectedInterval]
  );

  return intervals;
}
