import Icon, { IconProps } from '../Icon';
const FactoryIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M17 17a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2h-1Zm-6 1a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1Zm-4-1a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2H7Z" />
    <path
      fillRule="evenodd"
      d="M4 1a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V8a1 1 0 0 0-1.581-.814L16 11.056V8a1 1 0 0 0-1.581-.814L9 11.056V4a3 3 0 0 0-3-3H4Zm-.707 2.293A1 1 0 0 1 4 3h2a1 1 0 0 1 1 1v9a1 1 0 0 0 1.581.814L14 9.944V13a1 1 0 0 0 1.581.814L21 9.944V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 .293-.707Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default FactoryIcon;
