import { Box } from '@mui/material';
import { GQSupportCasePriority } from '@watershed/shared-universal/generated/graphql';
import { ReactNode } from 'react';

function IconWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        padding: '2px',
        height: '16px',
        width: '16px',
        position: 'relative',
      }}
    >
      {children}
    </Box>
  );
}

export default function SupportCasePriorityIcon({
  priority,
}: { priority: GQSupportCasePriority }) {
  if (priority === GQSupportCasePriority.Low) {
    return (
      <IconWrapper>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.cobalt,
            height: '100%',
            width: '100%',
          }}
        />
      </IconWrapper>
    );
  }
  if (priority === GQSupportCasePriority.Medium) {
    return (
      <IconWrapper>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.warning.main,
            height: '100%',
            width: '100%',
            transform: 'rotate(45deg)',
          }}
        />
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      <Box
        sx={{
          width: 0,
          height: 0,
          borderLeft: (theme) => `16px solid ${theme.palette.error.dark}`,
          borderTop: '16px solid transparent',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
        }}
      />
    </IconWrapper>
  );
}
