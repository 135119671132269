import nullifyEmptyString from '@watershed/shared-universal/utils/nullifyEmptyString';
import {
  TextFieldNonFormik,
  TextFieldMultilineNonFormik,
  TextFieldMultilineProps,
} from '@watershed/ui-core/components/Form/TextField';
import { SyntheticEvent } from 'react';
/* need to import react-hook-form for typescript to infer the type of ZodForm below */
import 'react-hook-form';
import MarkdownTextFieldWithPreview from '@watershed/ui-core/components/MarkdownTextFieldWithPreview';
import Field from '@watershed/ui-core/components/Form/Field';

import React from 'react';
import { CommonControllerProps } from './fieldUtils';
import {
  CommonZodFieldProps,
  getInputFieldProps,
  getCommonFieldProps,
  useFieldPropsFromTsController,
} from './fieldUtils';
import {
  AbstractSelectProps,
  ZodAbstractSelectField,
  ZodSelectFieldEditorType,
} from './ZodSelectFields';
import { AbstractZodMultiInputField } from './ZodArrayField';

type ZodAbstractTextFieldProps<T> = {
  isMarkdown?: boolean;
  multiline?: boolean | Pick<TextFieldMultilineProps, 'rows'>;
  onSelect?: (v: SyntheticEvent) => void;
  onBlur?: (v: SyntheticEvent) => void;
  onKeyDown?(
    evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
  onKeyUp?(
    evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
  nullifyEmptyString?: boolean;
  maxLength?: number;
} & CommonZodFieldProps &
  CommonControllerProps<T>;

function ZodAbstractTextField({
  nullifyEmptyString: shouldNullifyEmptyString = true,
  ...props
}: ZodAbstractTextFieldProps<string>) {
  const {
    onChange: _onChange,
    value: _value,
    ...tsProps
  } = useFieldPropsFromTsController<string | null>({
    onChangeTransform: props.onChangeTransform,
  });
  const value = _value ?? '';
  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) =>
    _onChange(
      shouldNullifyEmptyString
        ? nullifyEmptyString(e.target.value)
        : e.target.value
    );
  if (props.isMarkdown) {
    return (
      <Field
        inputId={tsProps.id}
        label={tsProps.label}
        {...getCommonFieldProps(props)}
      >
        <MarkdownTextFieldWithPreview
          {...tsProps}
          {...getInputFieldProps(props)}
          value={value}
          onChange={onChange}
          previewSx={{
            // on the Dashboard, we also remove this margin. See CP-990.
            '& ul': {
              marginTop: 0,
              marginBottom: 0,
            },
          }}
        />
      </Field>
    );
  }
  const { multiline = { rows: 1 } } = props;
  return multiline ? (
    <TextFieldMultilineNonFormik
      {...tsProps}
      {...getCommonFieldProps(props)}
      {...getInputFieldProps(props)}
      {...(typeof multiline === 'object' ? multiline : undefined)}
      value={value}
      onChange={onChange}
      data-test={`ZodField-${props.name}`}
    />
  ) : (
    <TextFieldNonFormik
      {...tsProps}
      {...getCommonFieldProps(props)}
      {...getInputFieldProps(props)}
      value={value}
      onChange={onChange}
      data-test={`ZodField-${props.name}`}
    />
  );
}

export function ZodTextField({
  ...props
}:
  | ZodAbstractTextFieldProps<string | null>
  | AbstractSelectProps<string | null>) {
  if ('listOptions' in props) {
    return <ZodAbstractSelectField<string | null> {...props} />;
  }
  return <ZodAbstractTextField {...props} />;
}

/**
 * Multi select string field.
 */
export function ZodTextArrayField({
  ...restProps
}:
  | ZodAbstractTextFieldProps<string | null | undefined>
  | AbstractSelectProps<string | null>) {
  if (!('listOptions' in restProps)) {
    return (
      <AbstractZodMultiInputField<
        string,
        ZodAbstractTextFieldProps<string | null | undefined>
      >
        {...restProps}
      />
    );
  }
  return (
    <ZodAbstractSelectField<string | null>
      editorType={ZodSelectFieldEditorType.SelectAutocomplete}
      {...restProps}
      multiple={restProps.multiple ?? true}
    />
  );
}
