import {
  GQSbtScope1And2Target,
  GQSbtScope3Target,
  GQTargetReductionRate,
  GQPlanTargetIntensityType,
  GQGrowthFactorType,
} from '../generated/graphql';
import { YearMonth } from '../utils/YearMonth';
import { ReductionFilter, ReductionFilterField } from './ReductionFilter';
import { FiscalYearlyTimeseries } from '../utils/SimpleTimeseries';
import { PlanTargetForForecasting } from '../forecast/ReductionsForecastX';
import assertNever from '@watershed/shared-util/assertNever';
import { ReductionFootprintContribution } from '../analysis/drilldownTypes';
import { FootprintForecastRowFields } from '../forecast/types';

export function isNotHeadcountOrRevenue(
  growthFactorType: GQGrowthFactorType
): boolean {
  return (
    growthFactorType !== GQGrowthFactorType.Headcount &&
    growthFactorType !== GQGrowthFactorType.Revenue
  );
}

export function getFilterValuesByField(
  filters: Array<ReductionFilter>,
  initialRecord?: Record<ReductionFilterField, Set<string>>
): Record<ReductionFilterField, Set<string>> {
  const filterValuesByField: Record<
    ReductionFilterField,
    Set<string>
  > = initialRecord
    ? initialRecord
    : {
        categoryId: new Set(),
        subcategoryId: new Set(),
        location: new Set(),
        locationCountry: new Set(),
        vendor: new Set(),
        product: new Set(),
        entity: new Set(),
        buildingName: new Set(),
        electricityType: new Set(),
        ghgScope: new Set(),
        ghgCategoryId: new Set(),
        description: new Set(),
      };
  for (const filter of filters) {
    for (const { field, value } of filter.filter) {
      // Add extra fields here for custom tags
      if (!filterValuesByField[field]) {
        filterValuesByField[field] = new Set<string>();
      }
      const fset = filterValuesByField[field];
      for (const str of value) {
        if (str) {
          fset.add(str.toLowerCase());
        }
      }
    }
  }

  return filterValuesByField;
}

export type VendorForReductionsPage = {
  name: string;
  targetYear?: number;
  emissionsProportion: number;
};

export enum PlanTargetSBTCategory {
  Absolute = 'Absolute',
  EconomicIntensity = 'EconomicIntensity',
  PhysicalIntensity = 'PhysicalIntensity',
  Supplier = 'Supplier',
  RenewableElectricity = 'RenewableElectricity',
}

/**
 * SBT has different requirements for checking whether a target satisfies
 * the scope 3 reduction requirements, based on how it classifies the
 * target type. This function maps the intensity type to the category we
 * should use to do the scope 3 commitment check.
 */
export function getSBTCategoryForTarget(
  planTarget: Pick<PlanTargetForForecasting, 'intensityType'>
): PlanTargetSBTCategory {
  switch (planTarget.intensityType) {
    case GQPlanTargetIntensityType.Absolute:
      return PlanTargetSBTCategory.Absolute;
    case GQPlanTargetIntensityType.GrossProfit:
    case GQPlanTargetIntensityType.Revenue:
      return PlanTargetSBTCategory.EconomicIntensity;
    case GQPlanTargetIntensityType.Headcount:
    case GQPlanTargetIntensityType.NightsStayed:
      return PlanTargetSBTCategory.PhysicalIntensity;
    case GQPlanTargetIntensityType.SupplierEngagement:
    case GQPlanTargetIntensityType.SupplierEngagementBySpend:
      return PlanTargetSBTCategory.Supplier;
    case GQPlanTargetIntensityType.Orders:
    case GQPlanTargetIntensityType.Patients:
      return PlanTargetSBTCategory.PhysicalIntensity;
    case GQPlanTargetIntensityType.RenewableElectricity:
      return PlanTargetSBTCategory.RenewableElectricity;
    case GQPlanTargetIntensityType.Custom:
      // We could map this to either a physical or economic intensity SBT
      // category based on the custom intensity config, but this doesn't
      // actually make a difference to the way that SBT calculations are
      // performed, nor is the category exposed anywhere on the UI – hence we
      // just choose one arbitrarily.
      //
      // If either of these facts change, we'll need to revisit this.
      return PlanTargetSBTCategory.PhysicalIntensity;
    default:
      assertNever(planTarget.intensityType);
  }
}

export const intensityTypeToGrowthRate: Record<
  GQPlanTargetIntensityType,
  GQTargetReductionRate
> = {
  [GQPlanTargetIntensityType.Absolute]: 'LinearAnnualReduction',
  [GQPlanTargetIntensityType.Headcount]: 'YearOverYear',
  [GQPlanTargetIntensityType.GrossProfit]: 'YearOverYear',
  [GQPlanTargetIntensityType.Orders]: 'YearOverYear',
  [GQPlanTargetIntensityType.Patients]: 'YearOverYear',
  [GQPlanTargetIntensityType.NightsStayed]: 'YearOverYear',
  [GQPlanTargetIntensityType.Revenue]: 'YearOverYear',
  [GQPlanTargetIntensityType.SupplierEngagement]: 'LinearAnnualReduction',
  [GQPlanTargetIntensityType.SupplierEngagementBySpend]:
    'LinearAnnualReduction',
  [GQPlanTargetIntensityType.RenewableElectricity]: 'LinearAnnualReduction',
  [GQPlanTargetIntensityType.Custom]: 'YearOverYear',
};

export interface CarbonFundingData {
  targetEmissions: FiscalYearlyTimeseries<number>;
  carbonOffsets: FiscalYearlyTimeseries<number>;
  carbonOffsetsCosts: FiscalYearlyTimeseries<number>;
  totalCarbonOffsetsCosts: FiscalYearlyTimeseries<number>;
  carbonRemoval: FiscalYearlyTimeseries<number>;
  carbonRemovalCosts: FiscalYearlyTimeseries<number>;
  totalCarbonRemovalCosts: FiscalYearlyTimeseries<number>;
  netEmissions: FiscalYearlyTimeseries<number>;
  estimatedRevenue: FiscalYearlyTimeseries<number>;
  estimatedCost: FiscalYearlyTimeseries<number>;
  estimatedCostPct: FiscalYearlyTimeseries<number>;
}

export type NetZeroAchievement =
  // Does not intend to target net zero
  | { type: 'no-target' }
  // Intends to target net zero, but fails to maintain net zero
  | { type: 'fail'; target: number }
  // Intends to target net zero, but achives it after their goal year
  | { type: 'too-late'; target: number; actual: YearMonth }
  // Intends to target net zero, and current plan achieves the target year
  | { type: 'on-track'; target: number; actual: YearMonth };

export type SBTAchievement<T extends string> =
  | { type: 'no-target' }
  | { type: 'fail'; target: T; reason: string }
  | { type: 'on-track'; target: T }
  | { type: 'not-applicable' }
  | { type: 'undetermined'; reason: string };

export interface Achievements {
  netZero: NetZeroAchievement;
  sbtScope12: SBTAchievement<GQSbtScope1And2Target>;
  sbtScope3: SBTAchievement<GQSbtScope3Target>;
}

export function achievementsHaveSbt(achievements: Achievements): boolean {
  return (
    achievements.sbtScope12.type !== 'no-target' &&
    achievements.sbtScope3.type !== 'no-target'
  );
}

export function achievementsPassSbt(achievements: Achievements): boolean {
  return (
    achievementsHaveSbt(achievements) &&
    achievements.sbtScope12.type === 'on-track' &&
    (achievements.sbtScope3.type === 'on-track' ||
      achievements.sbtScope3.type === 'not-applicable')
  );
}

// When creating reduction plans, we don't allow users to create targets that
// overlap with each other. However, some targets are allowed to overlap.
// Intensity types within the same groupings below are NOT allowed to overlap
// with each other. Intensity types not in an explicit group are in another
// implicit group.
export const TARGET_INTENSITY_OVERLAP_GROUPS: Array<
  Array<GQPlanTargetIntensityType>
> = [
  [
    GQPlanTargetIntensityType.SupplierEngagement,
    GQPlanTargetIntensityType.SupplierEngagementBySpend,
  ],
  [GQPlanTargetIntensityType.RenewableElectricity],
];

// TODO(ishaan): This should probably happen before the reductions forecast model
// even sees the rows.
// This is a hot code path -- avoid object spreads here!
export function contributionRowToFootprintForecastRowFields(
  row: ReductionFootprintContribution,
  customTagsToInclude: Array<string>
): FootprintForecastRowFields {
  const result: FootprintForecastRowFields = {
    categoryId: row.categoryId,
    subcategoryId: row.subcategoryId,
    ghgScope: row.ghgScope,
    ghgCategoryId: row.ghgCategoryId,
    description: row.description,
    location: row.location ?? undefined,
    locationCountry: row.locationCountry,
    buildingName: row.buildingName ?? undefined,
    vendor: row.vendor ?? undefined,
    product: row.product ?? undefined,
    entity: row.entity ?? undefined,
    electricityType: row.electricityType ?? undefined,
  };
  if (customTagsToInclude) {
    for (const tag of customTagsToInclude) {
      result[tag] = row[tag];
    }
  }
  return result;
}
