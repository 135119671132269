import { Stack } from '@mui/system';
import {
  useResolveDiscussionMutation,
  useRespondToDiscussionMutation,
} from '@watershed/shared-frontend/generated/urql';
import {
  GQDataIssueState,
  GQDiscussionFieldsFragment,
} from '@watershed/shared-universal/generated/graphql';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import Button from '@watershed/ui-core/components/Button';
import CheckmarkIcon from '@watershed/icons/components/Checkmark';
import { TextFieldMultiline } from '@watershed/ui-core/components/Form/TextField';
import ProgressButton from '@watershed/ui-core/components/ProgressButton';
import { Formik, Form } from 'formik';
import gql from 'graphql-tag';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import { Typography } from '@mui/material';
import { useUserContext } from '../../utils/UserContext';
import { ValidationMessage } from '@watershed/ui-core/components/Form/Field';
import { Trans, useLingui } from '@lingui/react/macro';

gql`
  mutation RespondToDiscussion($input: RespondToDiscussionInput!) {
    respondToDiscussion(input: $input) {
      discussion {
        ...DiscussionFields
      }
    }
  }
  mutation ResolveDiscussion($input: ResolveDiscussionInput!) {
    resolveDiscussion(input: $input) {
      discussion {
        ...DiscussionFields
      }
    }
  }
`;

export function EditDiscussionForm({
  discussion,
  disabled,
  disabledTooltip,
}: {
  discussion: GQDiscussionFieldsFragment;
  disabled?: boolean;
  disabledTooltip?: string;
}) {
  const { t } = useLingui();
  const snackbar = useSnackbar();
  const [, executeRespondToDiscussionMutation] =
    useRespondToDiscussionMutation();
  const userContext = useUserContext();
  return (
    <Formik
      initialValues={{
        message: '',
        shouldNotifyWatershed: true,
      }}
      onSubmit={async ({ message, shouldNotifyWatershed }, actions) => {
        actions.setSubmitting(true);

        const result = await executeRespondToDiscussionMutation({
          input: {
            discussionId: discussion.id,
            message,
            shouldNotifyWatershed,
          },
        });
        actions.setSubmitting(false);

        const updatedDiscussion = result.data?.respondToDiscussion?.discussion;

        if (result.error || !updatedDiscussion) {
          snackbar.enqueueSnackbar(
            <Trans context="Edit discussion error">
              An error occurred, please try again later
            </Trans>,
            { variant: 'error' }
          );
        } else {
          actions.resetForm({
            values: { message: '', shouldNotifyWatershed: true },
          });
        }
      }}
    >
      {(formik) => (
        <Form>
          <Stack gap={2}>
            {userContext.loginAsUser && (
              <ValidationMessage
                inputId="message"
                validationState="warning"
                validationMessage={`Warning: responding as ${userContext.userName}, not as
              ${userContext.loginAsUser.name}`}
              ></ValidationMessage>
            )}
            <TextFieldMultiline
              id="message"
              placeholder={t({
                message: 'Type your response',
                context: 'Response to a discussion thread',
              })}
              dataTest={TestIds.DataIssueResponseField}
              maxRows={10}
            />
            <Stack
              gap={1}
              direction="row"
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption">
                <Trans context="Discussion response SLA">
                  Our climate experts aim to provide a high-quality, tailored
                  response within 2 business days.
                </Trans>
              </Typography>
              <Stack spacing={1} direction="row">
                {GQDataIssueState.Done !== discussion.state && (
                  <ResolveDiscussionButton
                    discussionId={discussion.id}
                    disabled={disabled}
                    label={t({
                      message: 'Resolve',
                      context: 'Resolve discussion',
                    })}
                  />
                )}
                <ProgressButton
                  isInProgress={formik.isSubmitting}
                  label={
                    discussion.state === GQDataIssueState.Done ? (
                      <Trans context="Reopen and send discussion reply">
                        Reopen & send
                      </Trans>
                    ) : (
                      <Trans context="Reply to discussion">Reply</Trans>
                    )
                  }
                  progressLabel={
                    <Trans context="Submitting discussion reply loading state">
                      Submitting&hellip;
                    </Trans>
                  }
                  type="submit"
                  disabled={
                    disabled ||
                    formik.isSubmitting ||
                    formik.values.message === ''
                  }
                  tooltip={
                    disabled && disabledTooltip ? disabledTooltip : undefined
                  }
                />
              </Stack>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

function ResolveDiscussionButton({
  discussionId,
  disabled,
  label,
}: {
  discussionId: string;
  disabled?: boolean;
  label: string;
}): JSX.Element {
  const snackbar = useSnackbar();
  const [, executeResolveDiscussion] = useResolveDiscussionMutation();

  const onClick = async (discussionId: string) => {
    const res = await executeResolveDiscussion({
      input: {
        discussionId,
      },
    });
    const discussion = res.data?.resolveDiscussion?.discussion;
    if (res.error || !discussion) {
      snackbar.enqueueSnackbar(
        <Trans context="Error loading discussion modal">
          An error occurred, please try again later
        </Trans>,
        {
          variant: 'error',
        }
      );
    }
  };

  return (
    <Button
      startIcon={<CheckmarkIcon />}
      onClick={async () => {
        await onClick(discussionId);
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}
