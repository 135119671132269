import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { omitDeep } from 'lodash-omitdeep';

interface ObjWithTypename {
  __typename?: string;
}

type Options = {
  deep?: boolean;
};

export default function omitTypename<T extends ObjWithTypename>(
  objOrArray: T,
  opts?: Options
): Omit<T, '__typename'>;
export default function omitTypename<T extends ObjWithTypename>(
  objOrArray: Array<T>,
  opts?: Options
): Array<Omit<T, '__typename'>>;
export default function omitTypename<T extends ObjWithTypename>(
  objOrArray: T | Array<T>,
  opts?: Options
): Omit<T, '__typename'> | Array<Omit<T, '__typename'>> {
  if (Array.isArray(objOrArray)) {
    return objOrArray.map((obj) => omitTypename(obj, opts));
  }
  return opts?.deep
    ? omitDeep(cloneDeep(objOrArray), '__typename')
    : omit(objOrArray, '__typename');
}
