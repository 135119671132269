import React from 'react';

// For now it appears we literally do nothing for logged out, but that may change.
export default function LoggedOutLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return <>{children}</>;
}
