import * as Yup from 'yup';
import { msg, plural } from '@lingui/core/macro';
import flow from 'lodash/flow';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import path from 'path';

import invariant from 'invariant';
import compact from 'lodash/compact';
import toPairs from 'lodash/toPairs';
import EmissionsYear from '../companyData/EmissionsYear';
import {
  SCOPE_3_GHG_CATEGORY_IDS,
  SCOPE_3_OTHER_UPSTREAM_ID,
} from '../constants';
import { OPTIONAL_EMISSIONS_YEAR_PROPS } from '../externalReportConstants';
import { FootprintScope, FootprintScopes } from '../forecast/types';
import {
  GQCompanyEmissionsInterfaceV2,
  GQCompanyEmissionsUnits,
  GQEngagementTaskAnswerFieldsFragment,
  GQCompanySurveyAssignee,
  GQCompanySurveyStatus,
  GQDisclosureQualityScore,
  GQDisclosureTargetEmissionsType,
  GQDisclosureTargetIntensityType,
  GQDisclosureTargetReductionRate,
  GQEmissionsSource,
  GQFilterConjunction,
  GQFilterExpressionGroupInput,
  GQFilterFieldLegacy,
  GQFilterOperator,
  GQFootprintEstimateOutputByScope,
  GQSupplierHistoricalEmissionsFieldsFragment,
  GQSurveyDefinitionCategory,
  GQEngagementTaskFieldsFragment,
  GQCompanyEngagementTaskFieldsForOverviewFragment,
} from '../generated/graphql';
import {
  EdciReportColumnKey,
  ExtraEsgReportColumnKey,
  SfdrReportTagNames,
} from '../report/constants';
import assertNever from '@watershed/shared-util/assertNever';
import { getAllScopeEmissionsAndRatios } from '../utils/companyUtils';
import {
  DisclosureEFQualityInput,
  DisclosureQualityScoreInput,
  getDisclosureEFExplanations,
} from '../utils/DisclosureUtils';
import isNullish from '@watershed/shared-util/isNullish';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import must from '../utils/must';
import {
  straightlineYearly,
  totalToAnnualYoY,
  YearlyTimeseries,
  yearOverYear,
} from '../utils/SimpleTimeseries';
import { getGhgMethodologyTypeLabel } from '../utils/SuppliersUtils';
import { YM, YMInterval, YearMonth } from '../utils/YearMonth';
import { PleeEstimateOutput, PleeEstimateOutputSchema } from './pleeTypes';
import {
  ABSOLUTE_EMISSIONS_TO_REVENUE_INTENSITY_QUESTION_KEY_MAP,
  CEE_ACTIVITY_QUESTION_KEYS,
  getAttachmentsKey,
  QuestionKey,
  QUESTION_SCHEMAS,
  SurveyFormAttachments,
  SurveyFormValues,
  validateQuestionKey,
} from './questions';
import {
  AllocateEmissionsMethodV2,
  CarbonMeasurementPlan,
  DisclosureTargetInput,
  HaveMeasuredEmissionsV2,
  PostSurveyRecommendation,
  ReductionTargets,
  ReductionTimeseriesInput,
  ShareMethod,
  SurveyAnswer,
  SurveyAttachmentAnswer,
  TargetScope,
  YesNo,
  YesNoBool,
} from './types';
import isEmpty from 'lodash/isEmpty';
import { formatList } from '@watershed/intl/formatters';
import { QuestionKeyExtendedType } from '@watershed/shared-universal/companySurveys/questions';
import isIncludedIn from '../utils/isIncludedIn';
import { MessageDescriptor } from '@lingui/core';
import { SupportedLocale } from '@watershed/intl/constants';
import { i18n } from '@watershed/intl';

export const DISPLAY_TEXT_FOR_SURVEY_STATE: Record<
  GQCompanySurveyStatus,
  MessageDescriptor
> = {
  [GQCompanySurveyStatus.Submitted]: msg({
    message: 'Ready for review',
    context: 'Survey status',
  }),
  [GQCompanySurveyStatus.Closed]: msg({
    message: 'Closed',
    context: 'Survey status',
  }),
  [GQCompanySurveyStatus.InProgress]: msg({
    message: 'In progress',
    context: 'Survey status',
  }),
  [GQCompanySurveyStatus.AutoApproved]: msg({
    message: 'Auto approved',
    context: 'Survey status',
  }),
  [GQCompanySurveyStatus.Approved]: msg({
    message: 'Approved',
    context: 'Survey status',
  }),
  [GQCompanySurveyStatus.ChangesRequested]: msg({
    message: 'Changes requested',
    context: 'Survey status',
  }),
  [GQCompanySurveyStatus.SurveyCreated]: msg({
    message: 'Not started',
    context: 'Survey status',
  }),
  [GQCompanySurveyStatus.LoggedIn]: msg({
    message: 'Viewed',
    context: 'Survey status',
  }),
};

export const SURVEYEE_DISPLAY_TEXT_FOR_SURVEY_STATE: Record<
  GQCompanySurveyStatus,
  MessageDescriptor
> = {
  ...DISPLAY_TEXT_FOR_SURVEY_STATE,
  [GQCompanySurveyStatus.Submitted]: msg({
    message: 'Submitted',
    context: "Survey state, for example 'To do' or 'Submitted'",
  }),
  [GQCompanySurveyStatus.SurveyCreated]: msg({
    message: 'To do',
    context: "Survey state, for example 'To do' or 'Submitted'",
  }),
  [GQCompanySurveyStatus.LoggedIn]: msg({
    message: 'To do',
    context: "Survey state, for example 'To do' or 'Submitted'",
  }),
};

const DISPLAY_TEXT_FOR_LEARNING_TASK_STATE: Record<
  GQCompanySurveyStatus,
  MessageDescriptor
> = {
  [GQCompanySurveyStatus.Submitted]: msg({
    message: 'Completed',
    context: 'Learning task status',
  }),
  [GQCompanySurveyStatus.Closed]: msg({
    message: 'Completed',
    context: 'Learning task status',
  }),
  [GQCompanySurveyStatus.InProgress]: msg({
    message: 'In progress',
    context: 'Learning task status',
  }),
  [GQCompanySurveyStatus.Approved]: msg({
    message: 'Completed',
    context: 'Learning task status',
  }),
  [GQCompanySurveyStatus.AutoApproved]: msg({
    message: 'Completed',
    context: 'Learning task status',
  }),
  [GQCompanySurveyStatus.ChangesRequested]: msg({
    message: 'Completed',
    context: 'Learning task status',
  }),
  [GQCompanySurveyStatus.SurveyCreated]: msg({
    message: 'To do',
    context: 'Learning task status',
  }),
  [GQCompanySurveyStatus.LoggedIn]: msg({
    message: 'To do',
    context: 'Learning task status',
  }),
};

const SFDR_QUESTION_KEYS = [
  QuestionKey.BoardDiversity,
  QuestionKey.CleanPowerPercentage,
  QuestionKey.SfdrUnGlobalCompactCompliance,
  QuestionKey.SfdrFossilFuelSector,
  QuestionKey.TotalEnergyConsumption,
  QuestionKey.SfdrEnergyConsumptionHighImpact,
  QuestionKey.SfdrBiodiversitySensitiveAreas,
  QuestionKey.SfdrEmissionsToWater,
  QuestionKey.SfdrHazardousWaste,
  QuestionKey.SfdrUnGlobalCompactCompliance,
  QuestionKey.SfdrUnGlobalCompactViolations,
  QuestionKey.SfdrGenderPayGap,
  QuestionKey.SfdrExposureToControversialWeapons,
];

export interface EmissionsDisclosuresFields
  extends GQCompanyEmissionsInterfaceV2 {
  publicDisclosureId?: string | null;
  numEmployees?: number | null;
  orgId?: string | null;
  percentageCleanEnergy?: number | null;
  privateDisclosureId?: string | null;
  reportingYear: number;
  revenue?: number | null;
  revenueUsd?: number | null;
  totalMwh?: number | null;
  expenseCategory?: string | null;
}

export type EngagementTaskViewMode =
  | 'rootCustomerReviewSubmission'
  | 'supplierEdit'
  | 'supplierConfirm'
  | 'supplierReviewSubmission'
  | 'rootCustomerPreviewSupplierExperience'
  | 'rootCustomerTaskConfiguration';

export function isViewFromSupplierPerspective(
  mode?: EngagementTaskViewMode
): boolean {
  switch (mode) {
    case 'supplierConfirm':
    case 'supplierReviewSubmission':
    case 'supplierEdit':
    case 'rootCustomerPreviewSupplierExperience':
      return true;
    case 'rootCustomerReviewSubmission':
    case 'rootCustomerTaskConfiguration':
    case undefined:
      return false;
    default:
      assertNever(mode);
  }
}
export function isEngagementTaskReadOnly(
  mode: EngagementTaskViewMode
): boolean {
  switch (mode) {
    case 'rootCustomerReviewSubmission':
    case 'supplierConfirm':
    case 'supplierReviewSubmission':
      return true;
    case 'supplierEdit':
    case 'rootCustomerPreviewSupplierExperience':
    case 'rootCustomerTaskConfiguration':
      return false;
    default:
      assertNever(mode);
  }
}

export function isPreviewingEngagementTask(
  mode: EngagementTaskViewMode
): boolean {
  switch (mode) {
    case 'rootCustomerReviewSubmission':
    case 'supplierConfirm':
    case 'supplierReviewSubmission':
    case 'supplierEdit':
      return false;
    case 'rootCustomerPreviewSupplierExperience':
    case 'rootCustomerTaskConfiguration':
      return true;
    default:
      assertNever(mode);
  }
}

// Given an array of SurveyAnswers, return a SurveyFormValues map
export function surveyAnswersToSurveyFormValues(
  answers: Array<SurveyAnswer & { attachments?: SurveyAttachmentAnswer }>
): SurveyFormValues & SurveyFormAttachments {
  const answersByQuestionKey = keyBy(answers, (a) => a.questionKey);
  const getFormValue = (key: QuestionKey) => {
    const answer = answersByQuestionKey[key]?.answer;
    return answer ?? QUESTION_SCHEMAS[key].defaultValue;
  };

  // Set the form values
  const customQuestions = answers.reduce(
    (map, answer) =>
      !(answer.questionKey in QuestionKey)
        ? { ...map, [answer.questionKey]: answer.answer }
        : map,
    {} as SurveyFormValues & SurveyFormAttachments
  );

  const standardQuestions = Object.values(QuestionKey).reduce(
    (acc, key) => ({ ...acc, [key]: getFormValue(key) }),
    {} as SurveyFormValues & SurveyFormAttachments
  );

  const formValues = { ...customQuestions, ...standardQuestions };

  // Set additional explanation values
  answers.forEach((answerObj) => {
    const { attachments } = answerObj;
    if (!!attachments) {
      formValues[getAttachmentsKey(answerObj.questionKey)] = attachments;
    }
  });

  return formValues;
}

// Given an array of GQL SurveyAnswers, return an array of SurveyAnswers
// TODO (fravic): Improve the GQL schema to avoid this conversion
export function gqSurveyAnswersToSurveyAnswers(
  answers: Array<GQEngagementTaskAnswerFieldsFragment>
): Array<SurveyAnswer & { attachments: SurveyAttachmentAnswer }> {
  return answers.map(
    ({ questionKey, answer, skipped, attachments, questionIndex }) => ({
      questionKey,
      answer: answer ? answer.value : answer,
      skipped,
      attachments,
      questionIndex,
    })
  );
}

// Given a SurveyFormValues map, return an array of SurveyAnswers
export function surveyFormValuesToSurveyAnswers(
  values: SurveyFormValues,
  skipped: Partial<Record<QuestionKeyExtendedType, boolean>>
): Array<SurveyAnswer> {
  return Object.entries(values).reduce(
    (soFar, [questionKey, value]) => {
      return [
        ...soFar,
        {
          questionKey,
          answer: value,
          skipped: skipped[questionKey] ?? false,
          attachments: null,
          questionIndex: null,
        },
      ];
    },
    [] as Array<SurveyAnswer>
  );
}

// Given a set of current survey values, return the recommended next actions for the supplier
export function surveyFormValuesToSupplierRecommendation(
  values: SurveyFormValues
): PostSurveyRecommendation | null {
  switch (values[QuestionKey.ShareMethod]) {
    case ShareMethod.AttachReport: {
      if (values[QuestionKey.CleanPowerPercentage] === 100) {
        return PostSurveyRecommendation.RunAClimateProgram;
      }
      return PostSurveyRecommendation.BuyCleanPower;
    }

    case ShareMethod.NotMeasured: {
      if (
        values[QuestionKey.CarbonMeasurementPlan] ===
        CarbonMeasurementPlan.NoPlan
      ) {
        return PostSurveyRecommendation.RunAClimateProgram;
      }
      return PostSurveyRecommendation.GetComprehensiveMeasurement;
    }

    case ShareMethod.ManualAdd: {
      return PostSurveyRecommendation.GetComprehensiveMeasurement;
    }

    default: {
      return null;
    }
  }
}

// We currently allow suppliers to upload any kind of files in the survey form,
// but only allow customers to download files with these extensions.
const DOWNLOADABLE_FILE_EXTENSIONS = new Set([
  // Images
  '.avif',
  '.bmp',
  '.gif',
  '.heic',
  '.heif',
  '.jpeg',
  '.jpg',
  '.png',
  '.svg',
  '.tif',
  '.tiff',
  '.webp',

  // Word
  '.doc',
  '.docx',

  // Powerpoint
  '.ppt',
  '.pptx',

  // Excel
  '.xls',
  '.xlsx',

  // Others
  '.csv',
  '.pdf',
  '.txt',
]);

// Returns true if the file extension is one that we allow customers to download.
export function isDownloadableFile(filename: string): boolean {
  return DOWNLOADABLE_FILE_EXTENSIONS.has(path.extname(filename));
}

export const SURVEY_NOT_FOUND_ERROR_CODE = 'SURVEY_NOT_FOUND';

export function surveyAssigneeForSurveyStatus(
  status: GQCompanySurveyStatus
): GQCompanySurveyAssignee {
  switch (status) {
    case GQCompanySurveyStatus.SurveyCreated:
    case GQCompanySurveyStatus.LoggedIn:
    case GQCompanySurveyStatus.InProgress:
    case GQCompanySurveyStatus.ChangesRequested:
      return GQCompanySurveyAssignee.Supplier;

    case GQCompanySurveyStatus.Submitted:
    case GQCompanySurveyStatus.Approved:
    case GQCompanySurveyStatus.AutoApproved:
    case GQCompanySurveyStatus.Closed:
      return GQCompanySurveyAssignee.RootCustomer;
  }
}

export function isSurveyOpen(survey: {
  status: GQCompanySurveyStatus;
}): boolean {
  switch (survey.status) {
    case GQCompanySurveyStatus.AutoApproved:
    case GQCompanySurveyStatus.Approved:
    case GQCompanySurveyStatus.Submitted:
    case GQCompanySurveyStatus.Closed:
      return false;
    case GQCompanySurveyStatus.SurveyCreated:
    case GQCompanySurveyStatus.LoggedIn:
    case GQCompanySurveyStatus.InProgress:
    case GQCompanySurveyStatus.ChangesRequested:
      return true;
  }
}

export const isLearningTaskConfig = (
  engagementTaskConfig: { category: GQSurveyDefinitionCategory } | null
): boolean =>
  engagementTaskConfig?.category === GQSurveyDefinitionCategory.Learning;

export type RequiredTaskFields = Pick<
  GQEngagementTaskFieldsFragment,
  'status' | 'statusChangedAt'
> &
  Partial<
    Pick<
      | GQEngagementTaskFieldsFragment
      | GQCompanyEngagementTaskFieldsForOverviewFragment,
      'engagementTaskConfig'
    >
  >;

export const getSurveyStatusText = (
  engagementTask: RequiredTaskFields,
  viewMode: EngagementTaskViewMode
): MessageDescriptor =>
  viewMode !== 'supplierEdit'
    ? DISPLAY_TEXT_FOR_SURVEY_STATE[engagementTask.status]
    : isLearningTaskConfig(engagementTask.engagementTaskConfig ?? null)
      ? DISPLAY_TEXT_FOR_LEARNING_TASK_STATE[engagementTask.status]
      : SURVEYEE_DISPLAY_TEXT_FOR_SURVEY_STATE[engagementTask.status];

const TONNES_TO_KG = 1000;

export function tonnesToKg(input: number | null | undefined): number | null {
  return input === null || input === undefined ? null : input * TONNES_TO_KG;
}

export function percentIntToPercentFloat(
  input: number | null | undefined,
  options: { allowGreaterThan100?: boolean } = {}
): number | null {
  if (input === null || input === undefined) {
    return null;
  }
  invariant(
    input >= 0 && (input <= 100 || options.allowGreaterThan100),
    `Expected a percentage between 0 and 100, got ${input}`
  );
  return input / 100;
}

export function companySurveyName(
  reportingYear: number | null,
  taskConfigName: string
): string {
  return `${taskConfigName} ${
    isNotNullish(reportingYear) ? `(${reportingYear})` : ''
  }`;
}

export function getSurveyAnswerByKey(
  surveyAnswers: Array<SurveyAnswer>,
  key: QuestionKeyExtendedType
): SurveyAnswer | undefined {
  return surveyAnswers.find((a) => a.questionKey === key);
}

export function getSurveyAnswerValueByKey<T extends QuestionKey>(
  surveyAnswers: Array<SurveyAnswer>,
  key: T | string,
  options: { validate?: boolean } = { validate: true }
): Yup.InferType<(typeof QUESTION_SCHEMAS)[T]['schema']> | undefined {
  const surveyAnswer = getSurveyAnswerByKey(surveyAnswers, key);
  if (isNullish(surveyAnswer)) {
    return undefined;
  }
  const answer = surveyAnswer.answer;
  if (options.validate) {
    validateQuestionKey(key, answer);
  }
  return answer;
}

export function surveyAnswersHaveSomeEmissionsData(
  surveyAnswers: Array<SurveyAnswer>
): boolean {
  const emissionsData = buildEmissionsYearFromSurveyAnswers({
    surveyAnswers,
    // we actually don't need orgId and reportingYear
    orgId: null,
    reportingYear: 2021,
  });

  return emissionsData.some(
    ({ emissionsYear }) =>
      EmissionsYear.hasScope1Emissions(emissionsYear) ||
      EmissionsYear.hasScope2Emissions(emissionsYear) ||
      EmissionsYear.hasScope3Emissions(emissionsYear)
  );
}

export function buildEmissionsYearFromSurveyAnswers({
  surveyAnswers: originalSurveyAnswers,
  orgId,
  reportingYear,
  footprintEstimateOutputId,
}: {
  surveyAnswers: Array<SurveyAnswer>;
  orgId: string | null;
  reportingYear: number;
  footprintEstimateOutputId?: string | null;
}): Array<{
  emissionsYear: EmissionsDisclosuresFields;
  emissionsSource: GQEmissionsSource;
}> {
  const spendForReportingYear = getSurveyAnswerValueByKey(
    originalSurveyAnswers,
    QuestionKey.CustomerSpendInReportingYear
  );

  const surveyAnswers = transformAbsoluteToRevenueIntensity(
    originalSurveyAnswers,
    spendForReportingYear
  );
  // If the survey answer contains a CEE revenue intensity estimate, just return that
  const ceeRevenueIntensityByScope = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.CeeRevenueIntensityByScope
  ) as GQFootprintEstimateOutputByScope;
  const pickOnlyEmissionsYearFields = flow(
    (x) => pick(x, OPTIONAL_EMISSIONS_YEAR_PROPS),
    (x) => mapValues(x, (v) => v ?? null)
  );

  const ceeAbsoluteEmissionsByScope = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.CeeAbsoluteEmissionsByScope
  ) as GQFootprintEstimateOutputByScope;

  const ceeEmissions =
    ceeAbsoluteEmissionsByScope || ceeRevenueIntensityByScope
      ? [
          {
            emissionsYear: {
              ...pickOnlyEmissionsYearFields(
                ceeAbsoluteEmissionsByScope || ceeRevenueIntensityByScope
              ),
              orgId,
              reportingYear,
              scope2: null,
              footprintEstimateOutputId,
            },
            emissionsSource: GQEmissionsSource.SurveyEstimate,
          },
        ]
      : [];

  const pleeOutputsRaw =
    getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.PleeEstimationOutputs
    ) ?? null;

  const pleeEmissionsOutput: PleeEstimateOutput | null =
    PleeEstimateOutputSchema.parse(pleeOutputsRaw);

  const pleeEmissions = toPairs(pleeEmissionsOutput?.bea_emissions ?? {})
    .map(([beaCode, emissions]) => ({
      ...emissions,
      expenseCategory: beaCode,
      reportingYear,
      orgId,
    }))
    .map((emissionsYear) => ({
      emissionsYear,
      emissionsSource: GQEmissionsSource.SurveyEstimate,
    }));

  function getSurveyAnswerByQuestion(questionKey: QuestionKey): number | null {
    return getSurveyAnswerValueByKey(surveyAnswers, questionKey) ?? null;
  }

  function getDisclosedEmissions() {
    // If there are any revenue intensity answers, create a disclosure with
    // revenue intensity units (which is our preference in the supplier org, so we
    // can create an emissions factor). We can't base useRevenueIntensity on the
    // QuestionKey.AllocateEmissionsMethod after submission because absolute
    // emission answers may have been redacted during submission.
    const useRevenueIntensity = hasRevenueIntensityAnswers(surveyAnswers);
    if (
      getSurveyAnswerValueByKey(
        surveyAnswers,
        QuestionKey.HaveMeasuredEmissionsV2
      ) !== HaveMeasuredEmissionsV2.WillShare
    ) {
      return [];
    }

    const isAllocated =
      getSurveyAnswerValueByKey(
        surveyAnswers,
        QuestionKey.AllocateEmissionsMethodV2
      ) === AllocateEmissionsMethodV2.Allocated;

    const units = useRevenueIntensity
      ? GQCompanyEmissionsUnits.Kgco2ePerDollar
      : isAllocated
        ? GQCompanyEmissionsUnits.AllocatedKgco2e
        : GQCompanyEmissionsUnits.Kgco2e;

    const scope1TotalAns = getSurveyAnswerByQuestion(
      useRevenueIntensity
        ? QuestionKey.GhgScope1RevenueIntensity
        : QuestionKey.GhgScope1TonnesCo2e
    );
    const scope1StationaryAns = getSurveyAnswerByQuestion(
      QuestionKey.GhgScope1StationaryCombustionTonnesCo2e
    );
    const scope1MobileAns = getSurveyAnswerByQuestion(
      QuestionKey.GhgScope1MobileCombustionTonnesCo2e
    );
    const scope1FugitiveAns = getSurveyAnswerByQuestion(
      QuestionKey.GhgScope1FugitiveEmissionsTonnesCo2e
    );
    let scope1Total = scope1TotalAns;
    // Prioritize scope 1 breakdown if available
    if (
      scope1StationaryAns !== null ||
      scope1FugitiveAns !== null ||
      scope1MobileAns !== null
    ) {
      scope1Total =
        (scope1StationaryAns ?? 0) +
        (scope1FugitiveAns ?? 0) +
        (scope1MobileAns ?? 0);
    }

    const scope2MarketBasedAns = getSurveyAnswerByQuestion(
      useRevenueIntensity
        ? QuestionKey.GhgScope2MarketBasedRevenueIntensity
        : QuestionKey.GhgScope2MarketBasedTonnesCo2e
    );
    const scope2LocationBasedAns = getSurveyAnswerByQuestion(
      useRevenueIntensity
        ? QuestionKey.GhgScope2LocationBasedRevenueIntensity
        : QuestionKey.GhgScope2LocationBasedTonnesCo2e
    );

    const scope3ByCategoryKey = (getSurveyAnswerByQuestion(
      useRevenueIntensity
        ? QuestionKey.GhgScope3ByCategoryRevenueIntensity
        : QuestionKey.GhgScope3ByCategoryTonnesCo2e
    ) ?? {}) as {
      [category: string]: number;
    };

    // Prioritize scope 3 breakdown
    let scope3Total = Object.values(scope3ByCategoryKey).reduce(
      (soFar: number | null, tco2e) => (soFar ?? 0) + tco2e,
      null
    );
    if (scope3Total === null) {
      scope3Total =
        getSurveyAnswerByQuestion(
          useRevenueIntensity
            ? QuestionKey.GhgScope3TotalRevenueIntensity
            : QuestionKey.GhgScope3TotalTonnesCo2e
        ) ?? null;
    }

    const percentageCleanPower = getSurveyAnswerByQuestion(
      QuestionKey.CleanPowerPercentage
    );

    // if we're using absolute emissions, convert tonnes to kg
    // if we're using revenue intensity, convert tonnes/$ to kg/$
    const normalizeUnits = (n: number | null) => tonnesToKg(n);

    const revenueCurrency =
      getSurveyAnswerValueByKey(surveyAnswers, QuestionKey.RevenueCurrency) ??
      null;
    const revenue = getSurveyAnswerByQuestion(QuestionKey.Revenue);

    const emissionsYear = {
      orgId,
      reportingYear,
      numEmployees: getSurveyAnswerByQuestion(QuestionKey.Headcount),
      percentageCleanEnergy: percentageCleanPower,
      scope1: normalizeUnits(scope1Total),
      scope2Location: normalizeUnits(scope2LocationBasedAns),
      scope2Market: normalizeUnits(scope2MarketBasedAns),
      scope2: null,
      scope301: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[0]]
      ),
      scope302: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[1]]
      ),
      scope303: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[2]]
      ),
      scope304: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[3]]
      ),
      scope305: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[4]]
      ),
      scope306: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[5]]
      ),
      scope307: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[6]]
      ),
      scope308: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[7]]
      ),
      scope309: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[8]]
      ),
      scope310: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[9]]
      ),
      scope311: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[10]]
      ),
      scope312: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[11]]
      ),
      scope313: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[12]]
      ),
      scope314: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[13]]
      ),
      scope315: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[14]]
      ),
      scope316: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[15]]
      ),
      scope317: normalizeUnits(
        scope3ByCategoryKey[SCOPE_3_GHG_CATEGORY_IDS[16]]
      ),
      scope3: normalizeUnits(scope3Total),
      revenue,
      revenueCurrency,
      revenueUsd: revenueCurrency === 'USD' ? revenue : null,
      units,
    };
    return [{ emissionsYear, emissionsSource: GQEmissionsSource.Survey }];
  }
  return [...ceeEmissions, ...getDisclosedEmissions(), ...pleeEmissions];
}

function hasRevenueIntensityAnswers(
  surveyAnswers: Array<SurveyAnswer>
): boolean {
  const revenueIntensityQuestionKeys = Object.values(
    ABSOLUTE_EMISSIONS_TO_REVENUE_INTENSITY_QUESTION_KEY_MAP
  );
  return surveyAnswers.some((answer) =>
    revenueIntensityQuestionKeys.includes(answer.questionKey)
  );
}

export function transformAbsoluteToRevenueIntensity(
  answers: Array<SurveyAnswer>,
  revenueDenominator?: number
): Array<SurveyAnswer> {
  if (!revenueDenominator) {
    return answers;
  }
  // If we have revenue, transform absolute emission answers to revenue intensity
  const transformAbsoluteToRevenueIntensityAnswer = (
    absoluteAns: SurveyAnswer,
    intensityKey: QuestionKeyExtendedType
  ): SurveyAnswer => {
    if (absoluteAns.questionKey === QuestionKey.GhgScope3ByCategoryTonnesCo2e) {
      // GHG Scope 3 categories are a special case because they are a map of answers
      return {
        ...absoluteAns,
        questionKey: QuestionKey.GhgScope3ByCategoryRevenueIntensity,
        answer: mapValues(absoluteAns.answer, (value) =>
          isNotNullish(value) ? value / (revenueDenominator as number) : null
        ),
      };
    } else {
      return {
        ...absoluteAns,
        questionKey: intensityKey,
        answer:
          absoluteAns.answer === null
            ? null
            : absoluteAns.answer / (revenueDenominator as number),
      };
    }
  };
  return answers.map((answer) => {
    if (
      !(
        answer.questionKey in
        ABSOLUTE_EMISSIONS_TO_REVENUE_INTENSITY_QUESTION_KEY_MAP
      )
    ) {
      return answer;
    }
    const intensityKey =
      ABSOLUTE_EMISSIONS_TO_REVENUE_INTENSITY_QUESTION_KEY_MAP[
        answer.questionKey
      ];
    if (!intensityKey) {
      return answer;
    }
    return transformAbsoluteToRevenueIntensityAnswer(answer, intensityKey);
  });
}

export function hasCEEActivityAnswers(
  surveyAnswers: Array<SurveyAnswer>
): boolean {
  return surveyAnswers.some((answer) =>
    CEE_ACTIVITY_QUESTION_KEYS.includes(answer.questionKey)
  );
}

export function getDisclosureQualityFromEmissionsYear(
  emissionsData: GQCompanyEmissionsInterfaceV2,
  footprintVerified: boolean | null
): DisclosureQualityScoreInput {
  const scope1Total =
    EmissionsYear.getEmissionsForScope(emissionsData, 'scope1') ?? 0;
  const scope2Total = EmissionsYear.getScope2Emissions(emissionsData) ?? 0;
  const scope3Total = EmissionsYear.getScope3Emissions(emissionsData) ?? 0;
  const scope301 = emissionsData.scope301 ?? 0;
  const scope302 = emissionsData.scope302 ?? 0;
  const input = {
    totalEmissionsNonzero: scope1Total + scope2Total + scope3Total > 0,
    scope1Nonzero: scope1Total > 0,
    scope2Nonzero: scope2Total > 0,
    scope3Nonzero: scope3Total > 0,
    scope301Or302Nonzero: scope301 > 0 || scope302 > 0,
    scope1Verified: footprintVerified ?? false,
    scope2Verified: footprintVerified ?? false,
    scope3Verified: footprintVerified ?? false,
    pctEvaluationStatusesMatchResponse: 100,
  };

  let disclosureQualityScore: GQDisclosureQualityScore =
    GQDisclosureQualityScore.Low;
  if (!input.scope3Nonzero) {
    disclosureQualityScore = GQDisclosureQualityScore.Unusable;
  }

  if (input.scope301Or302Nonzero) {
    disclosureQualityScore = GQDisclosureQualityScore.Medium;
  }

  if (
    input.scope1Nonzero &&
    input.scope2Nonzero &&
    input.scope301Or302Nonzero
  ) {
    if (footprintVerified) {
      disclosureQualityScore = GQDisclosureQualityScore.VeryHigh;
    } else {
      disclosureQualityScore = GQDisclosureQualityScore.High;
    }
  }
  return { ...input, disclosureQualityScore };
}

export type EFQualitySurveyContext = {
  industry: string;
  disclosedCountries: boolean;
  disclosedCleanPower: boolean;
  thirdPartyVerified: boolean;
  hasCEEActivityAnswers: boolean;
};

export const supplierHistoricalEmissionsFieldsToEmissionsInterfaceNoUnits = (
  emissionsFields: GQSupplierHistoricalEmissionsFieldsFragment
): GQCompanyEmissionsInterfaceV2 => ({
  ...emissionsFields,
  scope2Location: null,
  scope2Market: emissionsFields.scope2, // hack - just throw it in Scope2 market for now. it's not totally right but good enough for now
});

export function getDisclosureEFQualityInput(
  historicalYear: GQSupplierHistoricalEmissionsFieldsFragment,
  surveyContext: EFQualitySurveyContext,
  supplierName: string
): DisclosureEFQualityInput {
  const emissionsInterface =
    supplierHistoricalEmissionsFieldsToEmissionsInterfaceNoUnits(
      historicalYear
    );

  const qualityInput = {
    thirdPartyVerified: surveyContext.thirdPartyVerified,
    disclosedRevenue:
      isNotNullish(historicalYear.revenue) ||
      isNotNullish(historicalYear.revenueUsd) ||
      historicalYear.units === GQCompanyEmissionsUnits.Kgco2ePerDollar,
    disclosedScope1: EmissionsYear.hasScope1Emissions(emissionsInterface),
    disclosedScope2: EmissionsYear.hasScope2Emissions(emissionsInterface),
    disclosedScope3: EmissionsYear.hasScope3Emissions(emissionsInterface),
    disclosedCleanPower: surveyContext.disclosedCleanPower,
    disclosedCEEActivities: surveyContext.hasCEEActivityAnswers,
    scope3CategoryBreakdown:
      EmissionsYear.hasScope3CategoryEmissions(emissionsInterface),
    disclosedIndustry: surveyContext.industry,
    disclosedCountries: surveyContext.disclosedCountries,
  };

  return {
    ...qualityInput,
    ...getDisclosureEFExplanations(qualityInput, supplierName),
  };
}

export function surveyAnswersToGQSupplierHistoricalEmissions(
  surveyAnswers: Array<SurveyAnswer>,
  surveyId: string,
  reportingYear: number | null
): Array<GQSupplierHistoricalEmissionsFieldsFragment> {
  const emissionsData = buildEmissionsYearFromSurveyAnswers({
    surveyAnswers,
    // we actually don't need orgId and reportingYear
    orgId: null,
    reportingYear: reportingYear ?? 2021,
  });

  return emissionsData.map(({ emissionsYear, emissionsSource }) => ({
    ...getAllScopeEmissionsAndRatios(emissionsYear),
    reportingYear: emissionsYear.reportingYear,
    publishingYear: null,
    revenueUsd: emissionsYear.revenueUsd ?? null,
    revenue: emissionsYear.revenue ?? null,
    revenueCurrency: emissionsYear.revenueCurrency ?? null,
    units: emissionsYear.units,
    source: emissionsSource,
    expenseCategory: emissionsYear.expenseCategory ?? null,
    publicUrl: null,
    surveyId,
  }));
}

function getScopeValueFromFilterGroupExpression(
  filterGroup: GQFilterExpressionGroupInput
) {
  const scopes = filterGroup.expressions.flatMap((expression) => {
    if (
      expression.field === GQFilterFieldLegacy.GhgScope &&
      expression.operator === GQFilterOperator.In
    ) {
      return expression.value;
    }
    return [];
  });
  const uniqScopes = [...new Set(scopes)];
  uniqScopes.sort();
  return uniqScopes;
}

const scopeNumber: Record<string, number> = {
  'scope 1': 1,
  'scope 2': 2,
  'scope 3': 3,
};

export function getTargetName(
  name: string,
  baseYear: YearMonth,
  scopeType: string | null
): string {
  return isEmpty(name) ? `${scopeType ?? YM.year(baseYear)} target` : name;
}

export function getReadableScopeFromFilterGroupExpression(
  filterGroup: GQFilterExpressionGroupInput
): string | null {
  const uniqScopes = getScopeValueFromFilterGroupExpression(filterGroup);

  const scopeNumbers = uniqScopes
    .sort()
    .map((scope) => scopeNumber[scope]?.toString() ?? null)
    .filter(isNotNullish);

  const formattedScopeList = formatList(scopeNumbers, {
    locale: i18n.locale as SupportedLocale,
    style: 'long',
    type: 'conjunction',
  });
  return scopeNumbers.length > 0
    ? plural(scopeNumbers.length, {
        one: `Scope ${formattedScopeList}`,
        other: `Scopes ${formattedScopeList}`,
      })
    : null;
}

function isFootprintScope(scope: string): scope is FootprintScope {
  return FootprintScopes.includes(scope as FootprintScope);
}

export function getFootprintScopeFromFilterGroupExpression(
  filterGroup: GQFilterExpressionGroupInput
): Array<FootprintScope> {
  const scopes = getScopeValueFromFilterGroupExpression(filterGroup);
  return scopes.filter(isFootprintScope);
}

export function getTargetScopeFromFootprintScopes(
  scopes: Array<FootprintScope>
): TargetScope | null {
  const uniqScopes = [...new Set(scopes)];
  uniqScopes.sort();

  if (isEqual(uniqScopes, ['scope 1', 'scope 2', 'scope 3'])) {
    return TargetScope.Scope1and2and3;
  } else if (isEqual(uniqScopes, ['scope 1', 'scope 2'])) {
    return TargetScope.Scope1and2;
  } else if (isEqual(uniqScopes, ['scope 2', 'scope 3'])) {
    return TargetScope.Scope2and3;
  } else if (isEqual(uniqScopes, ['scope 1', 'scope 3'])) {
    return TargetScope.Scope1and3;
  } else if (isEqual(uniqScopes, ['scope 1'])) {
    return TargetScope.Scope1;
  } else if (isEqual(uniqScopes, ['scope 2'])) {
    return TargetScope.Scope2;
  } else if (isEqual(uniqScopes, ['scope 3'])) {
    return TargetScope.Scope3;
  }
  return null;
}

export function getTargetScopeFromFilterGroupExpression(
  filterGroup: GQFilterExpressionGroupInput
): TargetScope | null {
  const uniqScopes = getFootprintScopeFromFilterGroupExpression(filterGroup);
  return getTargetScopeFromFootprintScopes(uniqScopes);
}

export function getFilterGroupFromTargetScope(
  targetScope: TargetScope
): GQFilterExpressionGroupInput {
  let value: Array<FootprintScope>;
  switch (targetScope) {
    case TargetScope.Scope1:
      value = ['scope 1'];
      break;
    case TargetScope.Scope2:
      value = ['scope 2'];
      break;
    case TargetScope.Scope3:
      value = ['scope 3'];
      break;
    case TargetScope.Scope1and2:
      value = ['scope 1', 'scope 2'];
      break;
    case TargetScope.Scope1and3:
      value = ['scope 1', 'scope 3'];
      break;
    case TargetScope.Scope2and3:
      value = ['scope 2', 'scope 3'];
      break;
    case TargetScope.Scope1and2and3:
      value = ['scope 1', 'scope 2', 'scope 3'];
      break;
    default:
      assertNever(targetScope);
  }
  const expression = {
    field: GQFilterFieldLegacy.GhgScope,
    operator: GQFilterOperator.In,
    value,
  };

  return {
    conjunction: GQFilterConjunction.AndConjunction,
    expressions: [expression],
  };
}

export function getTargetScopeFromFilterGroup(
  filterGroup: GQFilterExpressionGroupInput
): TargetScope | null {
  const scopes: Array<string> = [];
  filterGroup.expressions.map((expression) => {
    if (
      expression.field === GQFilterFieldLegacy.GhgScope &&
      expression.operator === GQFilterOperator.In
    ) {
      scopes.push(...expression.value);
    }
  });
  const uniqScopes = [...new Set(scopes)];
  uniqScopes.sort();
  if (isEqual(uniqScopes, ['scope 1', 'scope 2', 'scope 3'])) {
    return TargetScope.Scope1and2and3;
  } else if (isEqual(uniqScopes, ['scope 1', 'scope 2'])) {
    return TargetScope.Scope1and2;
  } else if (isEqual(uniqScopes, ['scope 2', 'scope 3'])) {
    return TargetScope.Scope2and3;
  } else if (isEqual(uniqScopes, ['scope 1', 'scope 3'])) {
    return TargetScope.Scope1and3;
  } else if (isEqual(uniqScopes, ['scope 1'])) {
    return TargetScope.Scope1;
  } else if (isEqual(uniqScopes, ['scope 2'])) {
    return TargetScope.Scope2;
  } else if (isEqual(uniqScopes, ['scope 3'])) {
    return TargetScope.Scope3;
  }
  return null;
}

export function buildDisclosureTargetsFromSurveyAnswers(
  surveyId: string,
  surveyAnswers: Array<SurveyAnswer>
): Array<DisclosureTargetInput> {
  const TARGET_QUESTION_KEY = QuestionKey.ReductionTargets;

  const targetAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    TARGET_QUESTION_KEY
  );

  const modifiedTargetAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.ModifiedProposeTarget
  );

  const acceptedTargetAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.AcceptedProposeTarget
  );

  const result = [];

  const reductionTargetToDisclosureTargetInput = (
    reductionTarget: Yup.InferType<typeof ReductionTargets>
  ) => {
    if (
      !(
        isNotNullish(reductionTarget.targetPercentage) &&
        isNotNullish(reductionTarget.targetYear) &&
        isNotNullish(reductionTarget.type) &&
        isNotNullish(reductionTarget.baselineYear) &&
        isNotNullish(reductionTarget.targetScope)
      )
    ) {
      return null;
    }

    return {
      reductionTarget: 100 - reductionTarget.targetPercentage,
      reductionRate: GQDisclosureTargetReductionRate.LinearAnnualReduction,
      targetYear: YM.make(reductionTarget.targetYear),
      baseYear: YM.make(reductionTarget.baselineYear),
      description: undefined,
      filters: getFilterGroupFromTargetScope(
        reductionTarget.targetScope as TargetScope
      ),
      // Assume that emissions are gross
      emissionsType: GQDisclosureTargetEmissionsType.GrossEmissions,
      intensityType: reductionTarget.type as GQDisclosureTargetIntensityType,
      unit: reductionTarget.unit ?? null,
      unitDescription: reductionTarget.unitDescription ?? null,
    };
  };
  if (isNotNullish(targetAnswer)) {
    result.push(
      ...targetAnswer.map((reductionTarget) =>
        reductionTargetToDisclosureTargetInput(reductionTarget)
      )
    );
  }
  if (isNotNullish(modifiedTargetAnswer)) {
    result.push(reductionTargetToDisclosureTargetInput(modifiedTargetAnswer));
  }
  if (isNotNullish(acceptedTargetAnswer)) {
    result.push(reductionTargetToDisclosureTargetInput(acceptedTargetAnswer));
  }

  return compact(result);
}

export function createReductionTimeseries(
  input: ReductionTimeseriesInput
): YearlyTimeseries<number> {
  // The range of the timeseries is inclusive of the base year and exclusive of
  // the end year. So, we need to add 1 to the year to make sure that we aren't
  // showing target lines that get you to your goal one year early.
  const reductionInterval = new YMInterval(
    input.baseYear,
    YM.plus(input.targetYear, 1, 'year')
  );
  const reductionStart = 100;
  const reductionTarget = input.reductionTarget;

  switch (input.reductionRate) {
    case GQDisclosureTargetReductionRate.LinearAnnualReduction:
      return straightlineYearly(
        reductionInterval,
        reductionStart,
        reductionTarget
      );
    case GQDisclosureTargetReductionRate.YearOverYear:
      // How much year-over-year % reduction is needed to achieve reduction target?
      const reductionPercent = totalToAnnualYoY(
        reductionStart - reductionTarget,
        reductionInterval.length('year')
      );
      return yearOverYear(reductionInterval, reductionStart, reductionPercent);
    default:
      assertNever(input.reductionRate);
  }
}

export function buildGQSupplierHistoricalEmissionsForReductionTargetsFromSurveyAnswers(
  surveyId: string,
  surveyAnswers: Array<SurveyAnswer>
): Array<GQSupplierHistoricalEmissionsFieldsFragment> {
  const targetAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.ReductionTargets
  );

  if (isNullish(targetAnswer)) {
    return [];
  }

  return targetAnswer
    .map((reductionTarget) => {
      if (isNullish(reductionTarget.baselineYearEmissions)) {
        return null;
      }

      const { scope1TonnesCo2e, scope2TonnesCo2e, scope3TonnesCo2e } =
        reductionTarget.baselineYearEmissions;

      if (
        isNullish(scope1TonnesCo2e) ||
        isNullish(scope2TonnesCo2e) ||
        isNullish(scope3TonnesCo2e)
      ) {
        return null;
      }

      const totalCo2e = scope1TonnesCo2e + scope2TonnesCo2e + scope3TonnesCo2e;

      return {
        orgId: null,
        reportingYear: reductionTarget.baselineYear,
        scope1: tonnesToKg(scope1TonnesCo2e),
        scope2: tonnesToKg(scope2TonnesCo2e),
        scope3: tonnesToKg(scope3TonnesCo2e),
        scope301: null,
        scope302: null,
        scope303: null,
        scope304: null,
        scope305: null,
        scope306: null,
        scope307: null,
        scope308: null,
        scope309: null,
        scope310: null,
        scope311: null,
        scope312: null,
        scope313: null,
        scope314: null,
        scope315: null,
        scope316: null,
        scope317: null,
        scope1Ratio: scope1TonnesCo2e / totalCo2e,
        scope2Ratio: scope2TonnesCo2e / totalCo2e,
        scope3Ratio: scope3TonnesCo2e / totalCo2e,
        publishingYear: null,
        surveyId,
        source: GQEmissionsSource.Survey,
        revenueUsd: null,
        revenue: null,
        revenueCurrency: null,
        publicUrl: null,
        units: GQCompanyEmissionsUnits.Kgco2e,
        expenseCategory: null,
      };
    })
    .filter(isNotNullish);
}

function getBoolOrNullFromYesNoAnswer(
  surveyAnswers: Array<SurveyAnswer>,
  questionKey: QuestionKey
): boolean | null {
  const answer = getSurveyAnswerValueByKey(surveyAnswers, questionKey);
  if (answer === null) {
    return null;
  }
  return YesNoBool[answer as YesNo];
}

function getBoolOrNullFromMultiSelectAnswer(
  surveyAnswers: Array<SurveyAnswer>,
  questionKey: QuestionKey
): boolean | null {
  const answer = getSurveyAnswerValueByKey(
    surveyAnswers,
    questionKey
  ) as Array<{ key: string; label: string; checked: boolean }> | null;
  if (!answer) {
    return null;
  }

  const affirmativeAnswers = answer
    .filter((answer) => answer.checked)
    .filter((answer) => !answer.key.endsWith('-None'));

  return affirmativeAnswers.length > 0;
}

export function surveyAnswersToEdciReportFields(
  surveyAnswers: Array<SurveyAnswer>
): Partial<
  Record<
    keyof typeof EdciReportColumnKey,
    string | boolean | number | null | undefined
  >
> {
  // TODO: Clean up the parsing of answers + question keys here
  const boardDiversityAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.BoardDiversity
  );
  const cSuiteDiversityAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.CSuiteDiversity
  );

  // TODO: Make this cleaner
  const maleBoardMembers = boardDiversityAnswer?.find(
    (answer) => answer.key === `${QuestionKey.BoardDiversity}-Total-Male`
  )?.value;
  const femaleBoardMembers = boardDiversityAnswer?.find(
    (answer) => answer.key === `${QuestionKey.BoardDiversity}-Total-Female`
  )?.value;
  const diverseMaleBoardMembers = boardDiversityAnswer?.find(
    (answer) => answer.key === `${QuestionKey.BoardDiversity}-Diverse-Male`
  )?.value;
  const diverseFemaleBoardMembers = boardDiversityAnswer?.find(
    (answer) => answer.key === `${QuestionKey.BoardDiversity}-Diverse-Female`
  )?.value;

  const maleCSuite = cSuiteDiversityAnswer?.find(
    (answer) => answer.key === `${QuestionKey.CSuiteDiversity}-Total-Male`
  )?.value;
  const femaleCSuite = cSuiteDiversityAnswer?.find(
    (answer) => answer.key === `${QuestionKey.CSuiteDiversity}-Total-Female`
  )?.value;

  const renewableEnergyConsumption =
    ((getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.CleanPowerPercentage
    ) ?? 0) /
      100) *
    (getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.TotalEnergyConsumption
    )?.value ?? 0);

  const employeeSurveyResponseRateRaw = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.EmployeeSurveyResponseRate
  )?.value;

  const employeeSurveyResponseRate = isNullish(employeeSurveyResponseRateRaw)
    ? null
    : employeeSurveyResponseRateRaw / 100;

  // Map these to the column option strings.
  const scope1MethodologyAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.GhgScope1Methodology
  );
  const scope1Methodology = scope1MethodologyAnswer
    ? getGhgMethodologyTypeLabel(scope1MethodologyAnswer)
    : null;
  const scope3MethodologyAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.GhgScope3Methodology
  );
  const scope3Methodology = scope3MethodologyAnswer
    ? getGhgMethodologyTypeLabel(scope3MethodologyAnswer)
    : null;
  const scope2LocationEmissions = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.GhgScope2LocationBasedTonnesCo2e
  );
  const scope2MarketEmissions = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.GhgScope2MarketBasedTonnesCo2e
  );

  const scope2Methodology = isNotNullish(scope2MarketEmissions)
    ? 'Market-based'
    : isNotNullish(scope2LocationEmissions)
      ? 'Location-based'
      : null;

  return {
    primarySectorOfOperationsSics: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.SasbSector
    ),
    primaryIndustryOfOperationsSics: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.SasbIndustry
    ),
    countryOfDomicile: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.CountryOfHeadquarters
    ),
    primaryCountryOfOperations: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.CountriesOfOperation
    )?.[0],
    revenueCurrency: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.RevenueCurrency
    ),
    revenue: getSurveyAnswerValueByKey(surveyAnswers, QuestionKey.Revenue),
    totalFteCurrentYear: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.Headcount
    ),
    totalFtePreviousYear: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.TotalEmployeesPriorYearEnd
    )?.value,
    // Skip scoped emissions for now
    totalEnergyConsumption: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.TotalEnergyConsumption
    )?.value,
    renewableEnergyConsumption,
    scope1Methodology,
    scope2Methodology,
    scope3Methodology,
    totalBoardMembers:
      isNotNullish(maleBoardMembers) && isNotNullish(femaleBoardMembers)
        ? maleBoardMembers + femaleBoardMembers
        : null,
    totalBoardMembersFemale: femaleBoardMembers,
    totalBoardMembersLgbtq: null, // Not currently collecting
    totalBoardMembersMinority:
      isNotNullish(diverseMaleBoardMembers) &&
      isNotNullish(diverseFemaleBoardMembers)
        ? diverseMaleBoardMembers + diverseFemaleBoardMembers
        : null,
    totalCSuiteEmployees:
      isNotNullish(maleCSuite) && isNotNullish(femaleCSuite)
        ? maleCSuite + femaleCSuite
        : null,
    totalCSuiteEmployeesFemale: femaleCSuite,
    workplaceInjury: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.WorkplaceInjury
    )?.value,
    workplaceFatality: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.WorkplaceFatality
    )?.value,
    daysLostDueToInjury: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.DaysLostDueToInjury
    )?.value,
    netChangeEmployeesNonOrganic: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.NetChangeEmployeesNonOrganic
    )?.value,
    annualPercentTurnover: percentIntToPercentFloat(
      getSurveyAnswerValueByKey(
        surveyAnswers,
        QuestionKey.AnnualPercentTurnover
      )?.value
    ),
    employeeSurvey: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.EmployeeSurvey
    )
      ? YesNoBool[
          must(
            getSurveyAnswerValueByKey(surveyAnswers, QuestionKey.EmployeeSurvey)
          )
        ]
      : null,
    employeeSurveyResponseRate,
  };
}

export function surveyAnswersToSfdrReportFinanceTags(
  surveyAnswers: Array<SurveyAnswer>
): Partial<
  | Record<SfdrReportTagNames, string | boolean | number | null | undefined>
  | undefined
> {
  if (
    !surveyAnswers.some((answer) =>
      isIncludedIn(answer.questionKey, SFDR_QUESTION_KEYS)
    )
  ) {
    return undefined;
  }
  // TODO: Clean up the parsing of answers + question keys here
  const boardDiversityAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.BoardDiversity
  );

  // TODO: Make this cleaner
  const maleBoardMembers = boardDiversityAnswer?.find(
    (answer) => answer.key === `${QuestionKey.BoardDiversity}-Total-Male`
  )?.value;
  const femaleBoardMembers = boardDiversityAnswer?.find(
    (answer) => answer.key === `${QuestionKey.BoardDiversity}-Total-Female`
  )?.value;
  const totalBoardMembers = (maleBoardMembers ?? 0) + (femaleBoardMembers ?? 0);
  const boardGenderDiversity = totalBoardMembers
    ? (femaleBoardMembers ?? 0) / totalBoardMembers
    : null;

  const renewableEnergyUsage = percentIntToPercentFloat(
    getSurveyAnswerValueByKey(surveyAnswers, QuestionKey.CleanPowerPercentage)
  );

  const ungcCompliance = getBoolOrNullFromMultiSelectAnswer(
    surveyAnswers,
    QuestionKey.SfdrUnGlobalCompactCompliance
  );
  const ungcMissingCompliance = ungcCompliance != null ? !ungcCompliance : null;

  const nonRenewableEnergyUsage = isNullish(renewableEnergyUsage)
    ? null
    : 1 - renewableEnergyUsage;

  return {
    [SfdrReportTagNames.ActiveInTheFossilFuelSector]:
      getBoolOrNullFromYesNoAnswer(
        surveyAnswers,
        QuestionKey.SfdrFossilFuelSector
      ),
    [SfdrReportTagNames.PercentOfNonRenewableEnergyConsumption]:
      nonRenewableEnergyUsage,
    [SfdrReportTagNames.TotalEnergyConsumptionGwh]: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.TotalEnergyConsumption
    )?.value,
    [SfdrReportTagNames.HighImpactSector]: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.SfdrEnergyConsumptionHighImpact
    ),
    [SfdrReportTagNames.NegativelyAffectsBiodiversitySensitiveAreas]:
      getBoolOrNullFromYesNoAnswer(
        surveyAnswers,
        QuestionKey.SfdrBiodiversitySensitiveAreas
      ),
    [SfdrReportTagNames.EmissionsToWater]: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.SfdrEmissionsToWater
    )?.value,
    [SfdrReportTagNames.HazardousWaste]: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.SfdrHazardousWaste
    )?.value,
    [SfdrReportTagNames.UNGCViolations]: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.SfdrUnGlobalCompactViolations
    ),
    [SfdrReportTagNames.UNGCMissingCompliance]: ungcMissingCompliance,
    [SfdrReportTagNames.UnadjustedGenderPayGap]: percentIntToPercentFloat(
      getSurveyAnswerValueByKey(surveyAnswers, QuestionKey.SfdrGenderPayGap)
        ?.value,
      { allowGreaterThan100: true }
    ),
    [SfdrReportTagNames.BoardGenderDiversity]: boardGenderDiversity,
    [SfdrReportTagNames.InvolvedInControversialWeapons]:
      getBoolOrNullFromYesNoAnswer(
        surveyAnswers,
        QuestionKey.SfdrExposureToControversialWeapons
      ),
  };
}

function parseDiversityAnswer(
  surveyAnswers: Array<SurveyAnswer>,
  questionKey: 'BoardDiversity' | 'CSuiteDiversity' | 'EmployeeDiversity'
) {
  const diversityAnswer = getSurveyAnswerValueByKey(surveyAnswers, questionKey);

  const keys = [
    `${questionKey}-Total-Male`,
    `${questionKey}-Total-Female`,
    `${questionKey}-White-Male`,
    `${questionKey}-White-Female`,
    `${questionKey}-Diverse-Male`,
    `${questionKey}-Diverse-Female`,
  ];

  const valueMap: Record<string, number | null | undefined> = {};
  keys.forEach((key) => {
    valueMap[key] = diversityAnswer?.find(
      (answer) => answer.key === key
    )?.value;
  });

  return valueMap;
}

// This includes all survey questions we asked for ESG
// that do not map to an existing report field. This is
// so that users can still edit/export these values.
export function surveyAnswersToExtraEsgReportFields(
  surveyAnswers: Array<SurveyAnswer>
): Partial<
  Record<
    keyof typeof ExtraEsgReportColumnKey,
    string | boolean | number | Array<string> | null | undefined
  >
> {
  const parentalLeave = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.ParentalLeave
  );
  const employeeSurveyKindsAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.EmployeeSurveyKinds
  );

  // TODO: Remove this brittle key to label mapping here based on the survey answers.
  const employeeSurveyMap = {
    [`${QuestionKey.EmployeeSurveyKinds}-Gender`]: 'Gender',
    [`${QuestionKey.EmployeeSurveyKinds}-Ethnicity`]: 'Ethnicity',
    [`${QuestionKey.EmployeeSurveyKinds}-Sexual-Orientation`]:
      'Sexual orientation',
    [`${QuestionKey.EmployeeSurveyKinds}-Impairment-Accessibility`]:
      'Impairment/Accessibility',
  };
  const employeeSurveyKinds = employeeSurveyKindsAnswer
    ? employeeSurveyKindsAnswer
        .filter((kind) => kind.checked)
        .map((kind) => employeeSurveyMap[kind.key])
    : null;

  const ungcComplianceKinds = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.SfdrUnGlobalCompactCompliance
  );

  const planToMeasureEmissionsAnswer = getSurveyAnswerValueByKey(
    surveyAnswers,
    QuestionKey.CarbonMeasurementPlan
  );
  const planToMeasureEmissions = planToMeasureEmissionsAnswer
    ? planToMeasureEmissionsAnswer ===
        CarbonMeasurementPlan.MeasurementInProgress ||
      planToMeasureEmissionsAnswer === CarbonMeasurementPlan.IntendToMeasure
    : null;

  const employeeDiversity = parseDiversityAnswer(
    surveyAnswers,
    QuestionKey.EmployeeDiversity
  );
  const csuiteDiversity = parseDiversityAnswer(
    surveyAnswers,
    QuestionKey.CSuiteDiversity
  );
  const boardDiversity = parseDiversityAnswer(
    surveyAnswers,
    QuestionKey.BoardDiversity
  );

  return {
    carbonRemoval: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.CarbonRemoval
    ),
    carbonRemovalAmount: getSurveyAnswerValueByKey(
      surveyAnswers,
      QuestionKey.CarbonRemovalAmount
    ),
    wasteManagement: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.WasteManagement
    ),
    environmentalRiskAssessment: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.EnvironmentalRiskAssessment
    ),
    ungcComplianceKinds: ungcComplianceKinds
      ? ungcComplianceKinds
          .map((kind) => (kind.checked ? kind.label : null))
          .filter(isNotNullish)
      : null,
    livingWage: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.LivingWage
    ),
    healthBenefits: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.HealthBenefits
    ),
    parentalLeavePrimary: parentalLeave ? parentalLeave.primary : null,
    parentalLeaveSecondary: parentalLeave ? parentalLeave.secondary : null,
    employeeSurveyKinds,
    esgRiskAssessment: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.EsgRiskAssessment
    ),
    esgBoardMember: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.EsgBoardMember
    ),
    esgBoardDiscussion: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.EsgBoardDiscussion
    ),
    haveMeasuredEmissions: getBoolOrNullFromYesNoAnswer(
      surveyAnswers,
      QuestionKey.HaveMeasuredEmissions
    ),
    planToMeasureEmissions,
    ...employeeDiversity,
    ...csuiteDiversity,
    ...boardDiversity,
  };
}

// Returns the rank of a survey status, in order from most to
// least attention or action required for the root org.
export function getSurveyStatusRank(status: GQCompanySurveyStatus): number {
  const rank: Record<GQCompanySurveyStatus, number> = {
    [GQCompanySurveyStatus.Submitted]: 0,
    [GQCompanySurveyStatus.ChangesRequested]: 1,
    [GQCompanySurveyStatus.Approved]: 2,
    [GQCompanySurveyStatus.AutoApproved]: 3,
    [GQCompanySurveyStatus.InProgress]: 4,
    [GQCompanySurveyStatus.LoggedIn]: 5,
    [GQCompanySurveyStatus.SurveyCreated]: 6,
    [GQCompanySurveyStatus.Closed]: 7,
  };
  return rank[status];
}

export function getScope3CategoryDisplay(categoryIdStr: string): {
  categoryName: string;
  key: string;
  categoryDesc: string | undefined;
} {
  // "Other upstream" doesn't have an official scope name. In CDP reports, it's
  // written as Scope 3.16, but according to the official GHG Protocol, there's no
  // Category 16 for scope 3 emissions. So for display purposes, we will omit 3.16
  // from the category name.
  if (categoryIdStr === SCOPE_3_OTHER_UPSTREAM_ID) {
    return {
      categoryName: 'Other upstream',
      categoryDesc: undefined,
      key: 'Other_upstream',
    };
  }

  const categoryIdSplit = categoryIdStr.split(' ');
  const categoryName = categoryIdSplit[0];
  const categoryDesc = categoryIdSplit.slice(1).join(' ');

  return {
    categoryName: `Scope ${categoryName}`,
    key: `Scope ${categoryName}`.replaceAll(/[\s.]/g, '_'), // making a formik-safe key
    categoryDesc: `(${categoryDesc})`,
  };
}
