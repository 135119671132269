import { Chip, SxProps, Theme, Stack, ChipProps } from '@mui/material';
import { mixinSx } from '@watershed/style/styleUtils';
import { ReactNode } from 'react';

export function OpenDiscussionCount({
  count,
  label,
  sx,
  chipProps,
}: {
  count: number;
  label: ReactNode;
  sx?: SxProps<Theme>;
  chipProps?: Pick<ChipProps, 'sx'>;
}) {
  return (
    <Stack direction="row" gap={1} sx={mixinSx({ alignItems: 'center' }, sx)}>
      {label}
      {count > 0 && <Chip size="small" label={count} {...chipProps} />}
    </Stack>
  );
}
