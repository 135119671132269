// Utils for interacting with the suppliers urql cache
import { Cache } from '@urql/exchange-graphcache';

import {
  SupplierFieldsFragmentDoc,
  SuppliersDataFieldsFragmentDoc,
} from '@watershed/shared-frontend/generated/urql';
import { GQSupplierFieldsFragment } from '@watershed/shared-universal/generated/graphql';
import { Supplier } from '@watershed/shared-universal/suppliers/supplierTypes';

export function patchSupplierInCache(
  cache: Cache,
  supplierId: string | undefined,
  patchGQLSupplier: (
    current: GQSupplierFieldsFragment
  ) => GQSupplierFieldsFragment,
  patchSupplierInSuppliersTable: (current: Supplier) => Supplier
) {
  if (!supplierId) {
    // No supplier ID, so we can't update the cache
    return;
  }
  // Update any existing Supplier fragments in the cache
  updateSupplierFragment(cache, supplierId, patchGQLSupplier);
  // Update any SuppliersData fragments that contain this supplier (untyped suppliers table data)
  updateSuppliersDataFragment(
    cache,
    { supplierId },
    patchSupplierInSuppliersTable
  );
}

// Patch a single GQL Supplier fragment in response to a mutation
function updateSupplierFragment(
  cache: Cache,
  supplierId: string,
  patch: (current: GQSupplierFieldsFragment) => GQSupplierFieldsFragment
) {
  const currentValue = cache.readFragment<GQSupplierFieldsFragment>(
    SupplierFieldsFragmentDoc,
    {
      __typename: 'Supplier',
      id: supplierId,
    }
  );
  if (!currentValue) {
    return;
  }
  cache.writeFragment(SupplierFieldsFragmentDoc, patch(currentValue));
}

// Patch suppliers table data in response to a mutation
export function updateSuppliersDataFragment(
  cache: Cache,
  // Can update by either supplierId or companyId
  { supplierId, companyId }: { supplierId?: string; companyId?: string },
  patch: (current: Supplier) => Supplier
) {
  // Update all SuppliersData fragments that contain this supplier
  const allFields = cache.inspectFields('Query');
  const suppliersDataFields = allFields.filter(
    (x) => x.fieldName === 'suppliersV2'
  );
  for (const field of suppliersDataFields) {
    const { fieldKey } = field;
    const id = cache.resolve({ __typename: 'Query' }, fieldKey);
    if (typeof id !== 'string') {
      continue;
    }
    const currentValue = cache.readFragment<{
      data: { rows: Array<Supplier> };
    }>(SuppliersDataFieldsFragmentDoc, id);
    if (!currentValue) {
      continue;
    }
    cache.writeFragment(SuppliersDataFieldsFragmentDoc, {
      ...currentValue,
      data: {
        ...currentValue.data,
        rows: currentValue.data.rows.map((row) => {
          if (
            (supplierId && row.id === supplierId) ||
            (companyId && row.companyId === companyId)
          ) {
            return patch(row);
          }
          return row;
        }),
      },
    });
  }
}
