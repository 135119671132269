import Icon, { IconProps } from '../Icon';
const CalendarIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M18.975 3H16.95v-.975a.993.993 0 0 0-.975-.975.993.993 0 0 0-.975.975V3H9v-.975a.993.993 0 0 0-.975-.975c-.525 0-1.05.375-1.05.975V3h-1.95c-1.65 0-3 1.35-3 3v14.025c0 1.65 1.35 3 3 3H19.05c1.65 0 3-1.35 3-3V6c-.075-1.65-1.425-3-3.075-3ZM5.025 5.025H7.05V6c0 .525.45.975.975.975C8.55 6.975 9 6.525 9 6v-.975h6V6c0 .525.45.975.975.975.525 0 .975-.45.975-.975v-.975h2.025c.525 0 .975.45.975.975v3H3.975V6c0-.525.45-.975 1.05-.975ZM18.975 21H5.025a.993.993 0 0 1-.975-.975v-9h15.975v9c0 .525-.45.975-1.05.975Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default CalendarIcon;
