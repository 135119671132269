import { NextPage } from 'next';

export enum Layouts {
  LoggedOut = 'LoggedOut',
  LoggedInFullBleed = 'LoggedInFullBleed',

  // Sidebar inherits all of LoggedIn and then also renders a Sidebar.
  // We may refactor this later.
  LoggedInSidebar = 'LoggedInSidebar',

  Finance = 'Finance',

  /**
   * A custom layout for the Emissions Factors pages on logged in dashboard, to provide some shared state
   */
  EmissionsFactors = 'EmissionsFactors',
}

export type NextPageWithLayout = NextPage & {
  layout?: Layouts;
};
