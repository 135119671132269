import { useFormikContext } from 'formik';
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '../DataGrid/DataGrid';
import { Checkbox, CheckboxProps } from '@mui/material';
import Field from './Field';
import type { FieldProps } from './Field';
import React, { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

export interface CheckboxFieldProps
  extends FieldProps,
    Omit<CheckboxProps, 'id' | 'value'> {
  testId?: string;
}

export function CheckboxField<T extends Record<string, number | ''>>({
  ...props
}: CheckboxFieldProps) {
  const form = useFormikContext<T>();

  function handleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.setFieldValue(props.inputId, event.target.checked);
  }

  return (
    <CheckboxFieldNonFormik
      checked={!!form.values[props.inputId]}
      onChange={handleValueChange}
      {...props}
    />
  );
}

export function CheckboxFieldNonFormik({
  validationMessage,
  ...props
}: CheckboxFieldProps) {
  return (
    <Field {...props} validationMessage={validationMessage}>
      <Checkbox
        {...omit(props, 'inputId')}
        id={props.inputId}
        checked={props.checked ?? false}
        data-test={props.testId ?? TestIds.Checkbox}
      />
    </Field>
  );
}

// For use with MUI Datagrid
export function DataGridCheckboxEditCell(props: GridRenderEditCellParams) {
  const { id, value, field, disabled } = props;
  const apiRef = useGridApiContext();

  function handleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    // TODO: URGENT Please fix this by await-ing or void-ing this line.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    apiRef.current.setEditCellValue({ id, field, value: event.target.checked });
  }

  return (
    <Checkbox
      onChange={handleValueChange}
      checked={value}
      disabled={disabled}
    />
  );
}

export function DataGridNullableCheckboxEditCell(
  props: GridRenderEditCellParams
) {
  const [localChecked, setLocalChecked] = useState(props.value ?? null);
  useEffect(() => setLocalChecked(props.value ?? null), [props.value]);
  const apiRef = useGridApiContext();

  const handleChange = () => {
    switch (localChecked) {
      case true:
        setLocalChecked(false);
        // TODO: URGENT Please fix this by await-ing or void-ing this line.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        apiRef.current.setEditCellValue({
          id: props.id,
          field: props.field,
          value: false,
        });
        break;
      case false:
        setLocalChecked(null);
        // TODO: URGENT Please fix this by await-ing or void-ing this line.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        apiRef.current.setEditCellValue({
          id: props.id,
          field: props.field,
          value: null,
        });
        break;
      default:
        setLocalChecked(true);
        // TODO: URGENT Please fix this by await-ing or void-ing this line.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        apiRef.current.setEditCellValue({
          id: props.id,
          field: props.field,
          value: true,
        });
        break;
    }
  };

  return (
    <Checkbox
      checked={!!localChecked}
      indeterminate={localChecked === null}
      onChange={handleChange}
      disabled={props.disabled}
    />
  );
}
