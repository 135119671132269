import { useEffect, useRef, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useRouter } from 'next/router';
import { Analytics, AnalyticsProperties } from './analyticsUtils';

export function useAnalyticsPath() {
  const router = useRouter();
  // Wait for the Next.js router to be ready before initializing analytics.
  // Otherwise, when a page has path params like `/foo/[fooId]`, this ends up
  // triggering two analytics events on the first page load:
  // 1. `/foo/[fooId]`: the path param uninterpolated
  // 2. `/foo/123`: the path param corrently interpolated
  // We wait for the router to be ready so that we can get the correct path.
  if (!router.isReady) return null;
  return `${window?.location?.origin}${router.asPath}`;
}

function withClientSideOnly<P extends {}>(
  Component: React.ComponentType<React.PropsWithChildren<P>>
) {
  return function WithClientSideOnly(props: P) {
    if (typeof window === 'undefined') {
      return null;
    }
    return <Component {...props} />;
  };
}

/**
 * React hook for registering Analytics page properties for just the lifetime of
 * the current component mount (Mixpanel) or the current URL pathname
 * (FullStory).
 *
 * Two method signatures are supported:
 *
 * 1. The simple case: `useAnalyticsPageProperties(pageVars);`
 * 2. For when you need to register page properties in a callback or `useEffect()`:
 *    `const register = useAnalyticsPageProperties(); register(pageVars);`
 */
export function useAnalyticsPageProperties(): (
  pageVars: AnalyticsProperties
) => void;
export function useAnalyticsPageProperties(
  pageVars?: AnalyticsProperties
): void;
export function useAnalyticsPageProperties(
  pageVars?: AnalyticsProperties
): (pageVars: AnalyticsProperties) => void | undefined {
  const pageVarsKeysRef = useRef<Array<string>>([]);

  const register = useCallback(function registerAnalyticsPageProperties(
    pageVars: AnalyticsProperties
  ) {
    void Analytics.registerPageProperties(pageVars);
    pageVarsKeysRef.current = Object.keys(pageVars);
  }, []);

  const pageVarsKey = JSON.stringify(pageVars);
  useEffect(() => {
    if (pageVars) {
      register(pageVars);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageVarsKey]);

  useEffect(() => {
    // On unmout, unregister any page properties that were actually registered
    // during the lifetime of this component.
    return () => Analytics.unregisterPageProperties(pageVarsKeysRef.current);
  }, []);

  return register;
}

export default withClientSideOnly(function AnalyticsTracker(props: any) {
  const path = useAnalyticsPath();
  useEffect(() => {
    if (path) {
      const url = new URL(path);

      // Set the path so it will show up in any other analytics events
      ReactGA.set({ page: url.pathname });
      // Send a page view
      ReactGA.send({ hitType: 'pageview', page: path });

      // Defer `updatePath()` because otherwise if `registerPageProperties()` in
      // being called in a child component's `useEffect()`, that registration
      // will happen after the `updatePath()` call and those page properties
      // will not be sent with the tracking event.
      setTimeout(() => {
        void Analytics.updatePath(url);
      });
    }
  }, [path]);

  return <>{props.children}</>;
});
