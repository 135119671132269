import uniq from 'lodash/uniq';
import {
  GQUserUploadAttempt,
  GQUserUploadForDatasourceFragment,
  GQUserUploadProcessingMode,
  GQUserUploadStatus,
  GQUserUploadedTableCell,
} from '../generated/graphql';
import isNotNullish from '@watershed/shared-util/isNotNullish';

export const SUPPORTING_DOCUMENTS_DIRECTORY = 'supporting_documents';
export const TEMPORARY_OBJECT_PREFIX = 'temp_';

export function isNotProcessedOrFinalized(status: GQUserUploadStatus): boolean {
  return (
    status !== GQUserUploadStatus.Processed &&
    status !== GQUserUploadStatus.Finalized
  );
}

export type UserUploadForDatasource = Pick<
  GQUserUploadForDatasourceFragment,
  'revisionRoot' | 'id'
> & {
  userVisibleAttempt: Pick<
    GQUserUploadAttempt,
    'id' | 'name' | 'remoteWritten'
  > | null;
};

/**
 * Ideally, this is implemented within the service fn that fetches all
 * user-visible uploads that belong to a user upload task (which is the
 * primary use case for this function)
 */
export function getUserVisibleUploads<T extends UserUploadForDatasource>(
  userUploads: Array<T>
): Array<T> {
  // If an upload has a revisionRoot, then that revisionRoot is actually the
  // original file, pre-OneSchema. We gather them to filter out of the list
  const revisionRootIds = uniq(
    userUploads.map((uu) => uu.revisionRoot).filter(isNotNullish)
  );

  return userUploads.filter((userUpload) => {
    // Filter out files that have not been written to GCS
    if (!userUpload.userVisibleAttempt?.remoteWritten) {
      return false;
    }
    // Filter out files that are Original Uploads (pre-One Schema user transforms in IDI)
    // NOTE: Legacy/non-IDI will never have a file with a revisionRoot shown in the same list
    if (!userUpload.revisionRoot && revisionRootIds.includes(userUpload.id)) {
      return false;
    }
    return true;
  });
}

export function getUploadsRequiringReview<
  T extends UserUploadForDatasource &
    Pick<
      GQUserUploadForDatasourceFragment,
      'status' | 'processingMode' | 'isBeingValueMapped'
    >,
>(userUploads: Array<T>): Array<T> {
  const userVisible = getUserVisibleUploads(userUploads);

  return userVisible.filter(
    (u) =>
      u.status === GQUserUploadStatus.Processed &&
      u.processingMode !== GQUserUploadProcessingMode.Legacy
  );
}

export function getUploadsRequiringValueMapping<
  T extends UserUploadForDatasource &
    Pick<
      GQUserUploadForDatasourceFragment,
      'status' | 'processingMode' | 'isBeingValueMapped'
    >,
>(userUploads: Array<T>): Array<T> {
  const userVisible = getUserVisibleUploads(userUploads);
  return userVisible.filter(
    (u) =>
      u.status === GQUserUploadStatus.Processed &&
      u.processingMode !== GQUserUploadProcessingMode.Legacy &&
      u.isBeingValueMapped
  );
}

export function getIncompleteUploads<
  T extends UserUploadForDatasource &
    Pick<GQUserUploadForDatasourceFragment, 'status' | 'processingMode'>,
>(userUploads: Array<T>): Array<T> {
  const userVisible = getUserVisibleUploads(userUploads);
  return userVisible.filter(
    (u) =>
      u.status === GQUserUploadStatus.Uploaded &&
      u.processingMode === GQUserUploadProcessingMode.IdiRawFile
  );
}

/**
 * Returns uploads that say "Processing please wait" in the UI
 */
export function getProcessingUploads<
  T extends UserUploadForDatasource &
    Pick<GQUserUploadForDatasourceFragment, 'status'>,
>(userUploads: Array<T>): Array<T> {
  const userVisible = getUserVisibleUploads(userUploads);
  return userVisible.filter((u) => u.status === GQUserUploadStatus.Validated);
}

export function getErroredUploads<
  T extends UserUploadForDatasource &
    Pick<GQUserUploadForDatasourceFragment, 'status'>,
>(userUploads: Array<T>): Array<T> {
  const userVisible = getUserVisibleUploads(userUploads);
  return userVisible.filter((u) => u.status === GQUserUploadStatus.Errored);
}

export function supportingDocumentsDirectoryForObjectId(
  objectId: string
): string {
  return `${SUPPORTING_DOCUMENTS_DIRECTORY}/${objectId}`;
}

export function supportingDocumentsDirectoryForTemporaryObjectId(
  objectId: string
): string {
  return `${SUPPORTING_DOCUMENTS_DIRECTORY}/${TEMPORARY_OBJECT_PREFIX}${objectId}`;
}

export type DataPreviewUntyped = Array<Array<GQUserUploadedTableCell>>;
