// TODO(LOC-36) - Internationalize this module
import { Box, Typography, Stack, SxProps, Theme } from '@mui/material';
import { useLingui } from '@lingui/react/macro';

import Logo from './Logo';
import CustomReactMarkdown from './CustomReactMarkdown';
import RelativeTimestamp from './RelativeTimestamp';
import React, { useEffect, useRef } from 'react';
import { mixinSx } from '@watershed/style/styleUtils';

export type DiscussionCommentProps = {
  id: string;
  message: string | React.ReactNode;
  createdAt?: Date | null;
  person?: {
    id?: string;
    name?: string;
  } | null;
};

/**
 * A styled comment in a discussion thread.
 */
export function DiscussionComment({
  message,
  createdAt,
  person,
  onClickLink,
  activeUserId,
}: DiscussionCommentProps & {
  activeUserId?: string;
  onClickLink?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}) {
  const { t } = useLingui();
  const { id, name } = person || {};

  const isLeftSide = person?.id !== activeUserId;
  const displayName = name ?? (id ? t`Deleted user` : t`Watershed`);

  // if we don't have a user for a Watershed comment, use the logo
  const usesWatershedLogo =
    name === 'Watershed' || !name || name === 'Climate GPT';

  const colorStyle = (theme: Theme) =>
    isLeftSide ? theme.palette.cobalt05 : theme.palette.cobalt10;

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection={isLeftSide ? 'row' : 'row-reverse'}
      width="100%"
    >
      <Box
        width={40}
        height={40}
        borderRadius={20}
        fontSize={20}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexGrow={0}
        flexShrink={0}
        sx={{
          color: (theme) => theme.palette.text.secondary,
          background: colorStyle,
        }}
      >
        {usesWatershedLogo ? <Logo /> : displayName.slice(0, 1)}
      </Box>
      <Box
        marginX="12px"
        paddingX="20px"
        paddingY="14px"
        width="60%"
        borderRadius={isLeftSide ? '0px 20px 20px 20px' : '20px 0px 20px 20px'}
        sx={{ backgroundColor: colorStyle, wordBreak: 'break-word' }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingBottom={1}
          spacing={1}
        >
          <Typography variant="h5" component="strong">
            {displayName}{' '}
          </Typography>
          <Typography variant="h5">
            {createdAt && <RelativeTimestamp timestamp={createdAt} />}
          </Typography>
        </Stack>
        {typeof message === 'string' ? (
          <CustomReactMarkdown
            source={message}
            variant="body1"
            onClickLink={onClickLink}
          />
        ) : (
          message
        )}
      </Box>
    </Box>
  );
}

/**
 * A discussion thread. It scrolls to the bottom initially, as
 * well as when the input `comments` list changes.
 */
export function DiscussionThread({
  comments,
  activeUserId,
  sx,
}: {
  comments: Array<DiscussionCommentProps>;
  activeUserId?: string;
  sx?: SxProps<Theme>;
}) {
  // scroll to bottom when list of comments changes
  const stackRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (stackRef.current) {
      stackRef.current.scrollTop = stackRef.current.scrollHeight;
    }
  }, [comments]);

  return (
    <Stack
      ref={stackRef}
      spacing={2}
      paddingY="8px"
      paddingX={2}
      sx={mixinSx({ overflowY: 'auto', overflowX: 'hidden' }, sx)}
    >
      {comments.map((comment, index) => (
        <DiscussionComment
          key={comment.id}
          {...comment}
          activeUserId={activeUserId}
        />
      ))}
    </Stack>
  );
}
