import { useLayoutEffect } from 'react';
import { Trans } from '@lingui/react/macro';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';
import { IdbKeyValueStore } from '@watershed/shared-frontend/utils/indexedDb';
import { z } from 'zod';
import { Typography } from '@mui/material';
import useLocalStorageState from '@watershed/shared-frontend/hooks/useLocalStorageState';
import once from 'lodash/once';
import createGlobalState from 'react-use/lib/factory/createGlobalState';
import DialogAlert from '@watershed/ui-core/components/DialogAlert';
import { ORG_ID_PARAM } from '@watershed/shared-universal/utils/pinnedOrganizationUniversalConstants';

const loginAsOrgInfoSchema = z.object({
  loginAsUserId: z.string().optional(),
  orgId: z.string(),
  orgName: z.string(),
});
type LoginAsOrgInfo = z.infer<typeof loginAsOrgInfoSchema>;

const callFunctionOnceEver = once((fn: () => void) => {
  fn();
});

const useLastDefinedOrgInfo = createGlobalState<LoginAsOrgInfo | undefined>();

export function LoginAsCrossOrgWarning(thisTabOrgInfo: LoginAsOrgInfo) {
  // we explicitly don't provide default here because it will get stuck for a given tab
  // and we want to make sure to reset to latest orgOnfo on local storage resets / clears not just whichever tab hook is called first
  const [latestOrgInfo, setLatestOrgInfo] = useLocalStorageState<
    LoginAsOrgInfo | undefined
  >('loginAsOrgInfo', undefined, (v) => loginAsOrgInfoSchema.parse(v));
  const [lastDefinedOrgInfo, setLastDefinedOrgInfo] = useLastDefinedOrgInfo();

  // it's an edge case, but if the user clears their localStorage, we want to reset the state back to the latest defined value
  // as indicated above we can't use the defaultValue for this because it can differ per tab and we want the latest value across all tabs
  useLayoutEffect(() => {
    if (latestOrgInfo != null) {
      setLastDefinedOrgInfo(latestOrgInfo);
    } else if (lastDefinedOrgInfo != null) {
      setLatestOrgInfo(lastDefinedOrgInfo);
    }
  }, [
    lastDefinedOrgInfo,
    latestOrgInfo,
    setLastDefinedOrgInfo,
    setLatestOrgInfo,
  ]);

  const {
    orgId: latestOrgId,
    orgName: latestOrgName,
    loginAsUserId: latestLoginAsUserId,
  } = latestOrgInfo ?? {};
  const {
    orgId: thisTabOrgId,
    orgName: thisTabOrgName,
    loginAsUserId: thisTabLoginAsUserId,
  } = thisTabOrgInfo;

  useClearLoginAsQueryParam();

  callFunctionOnceEver(() => {
    // we have to set this on mount but only once per page load (because of remounts)
    setLatestOrgInfo(thisTabOrgInfo);
  });

  // Validate whether the transition here is a `LoginAs` transition (e.g. we were before or after logged in as something)
  // and not an account switcher transition. We want the account switcher approach to not be blocked in the short term.
  const isLoginAsTransition = !!thisTabLoginAsUserId || !!latestLoginAsUserId;

  const onClose = () => {
    window.location.href = `/?${ORG_ID_PARAM}=${latestOrgId}`;
  };

  // orgId can only be null below if someone manually clears their local storage but it can happen
  return (
    <>
      {thisTabOrgId != null &&
        latestOrgId != null &&
        thisTabOrgId !== latestOrgId &&
        isLoginAsTransition && (
          <DialogAlert
            {...{
              title: 'Cross-org login-as warning',
              actions: [],
              onClose,
            }}
          >
            <>
              <Typography variant="body2">
                <Trans>
                  This tab was logged in as <strong>{thisTabOrgName}</strong>,
                  but in another tab you logged in as{' '}
                  <strong>{latestOrgName}</strong>. You will be redirected to{' '}
                  <strong>{latestOrgName}</strong> when you close this modal.
                </Trans>
              </Typography>
              <Typography variant="body2">
                <Trans>
                  To remain on <strong>{thisTabOrgName}</strong>, return to
                  Admin and log in again. To be logged into multiple orgs, you
                  must log in as yourself to each.
                </Trans>
              </Typography>
            </>
          </DialogAlert>
        )}
    </>
  );
}

function useClearLoginAsQueryParam() {
  // this was happening every time in the hook previously but maybe it should be in a useMount?
  const [loginAsRedirectParam, setLoginAsRedirectParam] =
    useQueryParam('loginAsRedirect');

  if (loginAsRedirectParam === 'true') {
    /**
     * Remove login as param from URL.
     */
    setLoginAsRedirectParam(null);
    // Clear IndexedDB
    void IdbKeyValueStore.clear();
    // Clear session storage
    window.sessionStorage.clear();
  }
}
