import {
  GQPlanTargetIntensityType,
  GQPlanTargetTargetComparisonType,
  GQSupplierForReductionsForecastFragment,
} from '../generated/graphql';
import { ReductionFilter } from '../reductions/ReductionFilter';
import { YearlyPercentageTimeseries } from '../utils/SimpleTimeseries';
import { FiscalYear, YearMonth } from '../utils/YearMonth';

export enum AsyncDataStatus {
  Loading, // Data is still loading, but will load eventually
  Paused, // Data will never load, don't hold your breath!
  Ready, // Data has loaded
}

export type AsyncData<T> =
  | { status: AsyncDataStatus.Loading }
  | { status: AsyncDataStatus.Paused }
  | { status: AsyncDataStatus.Ready; data: T };

export type SuppliersForForecasting = AsyncData<
  Array<GQSupplierForReductionsForecastFragment>
>;

export interface FootprintForecastRowFields extends Record<string, any> {
  categoryId: string | undefined;
  subcategoryId: string | undefined;
  location: string | undefined;
  locationCountry: string | undefined;
  vendor: string | undefined;
  product: string | undefined;
  ghgScope: string | undefined;
  ghgCategoryId: string | undefined;
  buildingName: string | undefined;
  electricityType: string | undefined;
  description: string | undefined;
}

// TODO: pull this enum up into the gql layer (we call it ghgCategory there,
// which is another thing we should fix)
export const FootprintScopes = ['scope 1', 'scope 2', 'scope 3'] as const;
export type FootprintScope = (typeof FootprintScopes)[number];

export type FootprintForecastRow = FootprintForecastRowFields & {
  yearMonth: YearMonth;
  kgco2e: number;
};

export type EmissionsTargetWithFilters = {
  id: string;
  scope?: FootprintScope;
  targets: YearlyPercentageTimeseries;
  comparisonType: GQPlanTargetTargetComparisonType;
  intensityType: GQPlanTargetIntensityType;
  customIntensityConfigId: string | null;
  baseYear: YearMonth;
  filters: ReductionFilter;
};

// Looks silly, but serves a real purpose: it discards any other properties that
// happen to be lying around
export function toFootprintForecastRowFields(
  f: FootprintForecastRowFields,
  footprintTags: Array<string>
): FootprintForecastRowFields {
  return {
    categoryId: f.categoryId,
    subcategoryId: f.subcategoryId,
    location: f.location,
    locationCountry: f.locationCountry,
    vendor: f.vendor,
    product: f.product,
    entity: f.entity,
    buildingName: f.buildingName,
    electricityType: f.electricityType,
    ghgScope: f.ghgScope,
    ghgCategoryId: f.ghgCategoryId,
    description: f.description,
    // Add in the footprint tags
    ...Object.fromEntries(footprintTags.map((key) => [key, f[key]])),
  };
}

export function mapKeyForFootprintForecastRowFields(
  fields: FootprintForecastRowFields,
  footprintTags: Array<string>
): string {
  // This should be in-sync with REDUCTION_FILTER_FIELDS
  // We don't use spread because of performance issues.
  // TODO - should add type-checking such that this stays in sync with REDUCTION_FILTER_FIELDS

  const valueList = [
    fields.categoryId,
    fields.subcategoryId,
    fields.location,
    fields.vendor,
    fields.product,
    fields.buildingName,
    fields.electricityType,
    fields.entity,
    fields.ghgScope,
    fields.ghgCategoryId,
    fields.description,
    fields.locationCountry,
  ];
  footprintTags.forEach((key) => valueList.push(fields[key]));

  const key = valueList.join('||');
  return key;
}

export type ActualAndNecessaryReduction = {
  actualReduction: number;
  necessaryReduction: number;
};

export type ActualAndNecessaryRemoval = {
  actualRemoval: number;
  necessaryRemoval: number;
};

export enum TargetValidity {
  Valid,
  Invalid,
  Undetermined,
}

type BaseTargetValidity<T> = {
  target: T;
  validity: TargetValidity;
  reason: string;
};

type EmissionsTargetValidity<T> = {
  type: 'emissions';
  reduction?: ActualAndNecessaryReduction;
} & BaseTargetValidity<T>;

type SupplierEngagementTargetValidity<T> = {
  type: 'supplierEngagement';
  reduction?: ActualAndNecessaryReduction;
} & BaseTargetValidity<T>;

type RETargetValidity<T> = {
  type: 'renewableElectricity';
  reduction?: ActualAndNecessaryReduction;
  interimReduction?: ActualAndNecessaryReduction;
  interimTargetYearInclusive?: number;
  targetYearInclusive?: number;
} & BaseTargetValidity<T>;

type LongTermNetZeroTargetValidity<T> = {
  type: 'longTermNetZero';
  reduction?: ActualAndNecessaryReduction;
  removal?: ActualAndNecessaryRemoval;
} & BaseTargetValidity<T>;

export type SbtTargetValidity<T> =
  | EmissionsTargetValidity<T>
  | RETargetValidity<T>
  | SupplierEngagementTargetValidity<T>
  | LongTermNetZeroTargetValidity<T>;

export type TargetsValidByScopeResult = {
  scope12: boolean;
  scope3: boolean;
};

export type ReduxTargetChartSeriesDatum = {
  // year
  x: number;
  y: number;
  actualXRange: FiscalYear;
};
