import { atom } from 'jotai';
import type { UserContextProps } from '../utils/UserContext';
import { mustAtom } from '@watershed/shared-frontend/components/jotai';
import { atomWithStorage } from 'jotai/utils';
import deriveDefaultLocaleForFrontend from '@watershed/intl/frontend/deriveDefaultLocaleForFrontend';

/**
 * An atom that context the entire UserContext
 * We keep this in sync with the provided value in SharedAppContainer
 * rather than using this directly create a selectAtom to get the specific property you need like we do for orgId below
 * this will result in fewer rerenders and allows us to move things around later.
 */
export const atomUserContext = mustAtom(
  atom<UserContextProps | null>(null),
  'UserContext'
);

export const atomOrgId = atom((get) => get(atomUserContext).orgId);

export const atomOrgFiscalYearStartMonth = atom(
  (get) => get(atomUserContext).orgFiscalYearStartMonth
);

export const atomUserId = atom((get) => get(atomUserContext).userId);

export const atomLocale = atomWithStorage(
  'locale',
  deriveDefaultLocaleForFrontend(null),
  /*= storage */ undefined, // default is localstorage
  { getOnInit: true }
);
