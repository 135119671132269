import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { GQDataIssueWithCommentsFieldsFragment } from '@watershed/shared-universal/generated/graphql';
import {
  DataIssueStatusIndicator,
  shouldShowStatusIndicator,
} from './DataIssueStatusIndicator';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';

export function DataIssueChatHeader({
  issue,
  datasourceName,
  activeUserId,
}: {
  issue: GQDataIssueWithCommentsFieldsFragment;
  datasourceName: string;
  activeUserId: string;
}) {
  const comments = flattenConnection(issue.comments);
  const latestComment = comments.at(-1);

  const isActiveUserTheLatestAuthor =
    latestComment?.person?.id === activeUserId;
  return (
    <Stack
      alignItems="start"
      spacing={0.5}
      data-test={TestIds.DataIssueDialogTitleHeader}
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Typography variant="h2">
          {shouldShowStatusIndicator(issue.state) && (
            <>
              <Box display="inline-block" sx={{ verticalAlign: 'middle' }}>
                <DataIssueStatusIndicator
                  state={issue.state}
                  isActiveUserTheLatestAuthor={isActiveUserTheLatestAuthor}
                  isSmall={false}
                />
              </Box>{' '}
            </>
          )}
          {issue.title}
        </Typography>
      </Stack>
      <Typography noWrap>{datasourceName}</Typography>
    </Stack>
  );
}
