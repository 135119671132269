import { useGetSuppliersSettingsQuery } from '@watershed/shared-frontend/generated/urql';
import { getGqlResultDataBang } from '@watershed/shared-frontend/utils/errorUtils';
import gql from 'graphql-tag';
import { useMemo } from 'react';

gql`
  fragment SuppliersSettingsFields on SuppliersSettings {
    id
    includedGhgCategories
    viewLatestSupplierMapping
    footprintSnapshotIds
    footprintInterval
  }

  fragment CompanyPortalSettingsFields on CompanyPortalSettings {
    id
    portalCallout
    sidebarContent
    portalTitle
    toggleHeaderImage
  }

  query GetSuppliersSettings($rootCompanyId: ID!) {
    suppliersSettings {
      ...SuppliersSettingsFields
    }
    companyPortalSettings(rootCompanyId: $rootCompanyId) {
      ...CompanyPortalSettingsFields
    }
  }
`;
export default function useSuppliersSettings(rootCompanyId: string) {
  const [state] = useGetSuppliersSettingsQuery({
    variables: { rootCompanyId },
  });

  const result = useMemo(() => {
    if (state.fetching) {
      return {
        fetching: state.fetching,
        stale: state.stale,
        error: state.error,
        suppliersSettings: null,
        companyPortalSettings: null,
      };
    }

    const data = getGqlResultDataBang(state);

    return {
      fetching: state.fetching,
      stale: state.stale,
      error: state.error,
      suppliersSettings: data.suppliersSettings,
      companyPortalSettings: data.companyPortalSettings,
    };
  }, [state]);

  return result;
}
