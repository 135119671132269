import { t } from '@lingui/core/macro';
import {
  GQSupportCaseIssueType,
  GQSupportCasePriority,
  GQSupportCaseStatus,
} from '../generated/graphql';

export function getSupportCaseConfirmationMsgWithSla(
  priority: GQSupportCasePriority
): string {
  if (priority === GQSupportCasePriority.High) {
    return t({
      message:
        'Thanks for your submission! Watershed Support will reach out to you within the next 4 hours.',
    });
  }
  if (priority === GQSupportCasePriority.Medium) {
    return t({
      message:
        'Thanks for your submission! Watershed Support will reach out to you within the next business day.',
    });
  }
  return t({
    message:
      'Thanks for your submission! Watershed Support will reach out to you within the next 2 business days',
  });
}

export enum SalesforceCaseIssueType {
  GettingStarted = 'Getting Started',
  Measurements = 'Measurements',
  ReportingAndRegulations = 'Reporting and Regulations',
  ReductionsAndTargets = 'Reductions and Targets',
  Marketplace = 'Marketplace',
  FinancedEmissions = 'Financed Emissions',
  SupplyChain = 'Supply Chain',
  PhysicalProducts = 'Physical Products',
}

// We need to convert from the GQL enum to the salesforce version for use in the Salesforce API
export const ISSUE_TYPES: {
  [key in GQSupportCaseIssueType]: SalesforceCaseIssueType;
} = {
  [GQSupportCaseIssueType.GettingStarted]:
    SalesforceCaseIssueType.GettingStarted,
  [GQSupportCaseIssueType.Measurements]: SalesforceCaseIssueType.Measurements,
  [GQSupportCaseIssueType.ReportingAndRegulations]:
    SalesforceCaseIssueType.ReportingAndRegulations,
  [GQSupportCaseIssueType.ReductionsAndTargets]:
    SalesforceCaseIssueType.ReductionsAndTargets,
  [GQSupportCaseIssueType.Marketplace]: SalesforceCaseIssueType.Marketplace,
  [GQSupportCaseIssueType.FinancedEmissions]:
    SalesforceCaseIssueType.FinancedEmissions,
  [GQSupportCaseIssueType.SupplyChain]: SalesforceCaseIssueType.SupplyChain,
  [GQSupportCaseIssueType.PhysicalProducts]:
    SalesforceCaseIssueType.PhysicalProducts,
};

// We need the internationalized version of the issue type for display in the UI
export function getIssueTypeLabel(issueType: GQSupportCaseIssueType): string {
  switch (issueType) {
    case GQSupportCaseIssueType.GettingStarted:
      return t({
        message: `Getting Started`,
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.Measurements:
      return t({
        message: `Measurements`,
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.ReportingAndRegulations:
      return t({
        message: `Reporting and Regulations`,
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.ReductionsAndTargets:
      return t({
        message: `Reductions and Targets`,
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.Marketplace:
      return t({
        message: `Marketplace`,
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.FinancedEmissions:
      return t({
        message: `Financed Emissions`,
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.SupplyChain:
      return t({
        message: `Supply Chain`,
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    case GQSupportCaseIssueType.PhysicalProducts:
      return t({
        message: `Physical Products`,
        context:
          'User-facing description of a support case issue type, which is really an area of the product',
      });
    default:
      throw new Error(`Unknown issue type: ${issueType}`);
  }
}

export function getStatusLabel(status: GQSupportCaseStatus): string {
  switch (status) {
    case GQSupportCaseStatus.PendingWatershedReply:
      return t({
        message: `Pending Watershed reply`,
        context: 'User-facing description of a support case status',
      });
    case GQSupportCaseStatus.Complete:
      return t({
        message: `Complete`,
        context: 'User-facing description of a support case status',
      });
    case GQSupportCaseStatus.PendingCustomerReply:
      return t({
        message: `Pending customer reply`,
        context: 'User-facing description of a support case status',
      });
    case GQSupportCaseStatus.OnHoldPendingWatershedAction:
      return t({
        message: `On hold — Pending Watershed action`,
        context: 'User-facing description of a support case status',
      });
    default:
      throw new Error(`Unknown status: ${status}`);
  }
}

export function getPriorityLabel(priority: GQSupportCasePriority): string {
  switch (priority) {
    case GQSupportCasePriority.Low:
      return t({
        message: `Low`,
        context: 'User-facing description of a support case priority',
      });
    case GQSupportCasePriority.Medium:
      return t({
        message: `Medium`,
        context: 'User-facing description of a support case priority',
      });
    case GQSupportCasePriority.High:
      return t({
        message: `High`,
        context: 'User-facing description of a support case priority',
      });
    default:
      throw new Error(`Unknown priority: ${priority}`);
  }
}
