/*
  This file is semi-automatically generated from
  cliq/taxonomy/scripts/generate_nace_2_1__ceda_5_weighted_mapping.py in the cliq repo
  Do not manually modify this file
 */

import type { WeightedBeaCodes } from './industryCodeUtils';

export const NACE_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['01.11']: {
    label:
      'Growing of cereals, other than rice, leguminous crops and oil seeds',
    BEA_CODES: {
      '111900': 0.06518595660513067,
      '111400': 0.06949023016063294,
      '1111B0': 0.3428771577559338,
      '111300': 0.09537955526252698,
      '113000': 0.06622737353152722,
      '1111A0': 0.15832159554063774,
      '111200': 0.05371163989450971,
      '112A00': 0.14880649124910095,
    },
  },
  ['01.12']: {
    label: 'Growing of rice',
    BEA_CODES: {
      '111900': 0.06980923916313964,
      '111400': 0.07441879124626402,
      '1111B0': 0.3671955549314986,
      '111300': 0.10214430425450825,
      '1111A0': 0.16955047840651516,
      '111200': 0.057521112069484286,
      '112A00': 0.15936051992859004,
    },
  },
  ['01.13']: {
    label: 'Growing of vegetables and melons, roots and tubers',
    BEA_CODES: {
      '111900': 0.05436810088139999,
      '311224': 0.11533810953605429,
      '111400': 0.05795806398195333,
      '1111B0': 0.28597539828594104,
      '111300': 0.07955095779215707,
      '113000': 0.055236690735145706,
      '312130': 0.050615667736261125,
      '1111A0': 0.13204752873688913,
      '111200': 0.04479799037052543,
      '112A00': 0.12411149194367288,
    },
  },
  ['01.14']: {
    label: 'Growing of sugar cane',
    BEA_CODES: {
      '111900': 0.06980923916313964,
      '111400': 0.07441879124626402,
      '1111B0': 0.3671955549314986,
      '111300': 0.10214430425450825,
      '1111A0': 0.16955047840651516,
      '111200': 0.057521112069484286,
      '112A00': 0.15936051992859004,
    },
  },
  ['01.15']: {
    label: 'Growing of tobacco',
    BEA_CODES: {
      '111900': 0.06980923916313964,
      '111400': 0.07441879124626402,
      '1111B0': 0.3671955549314986,
      '111300': 0.10214430425450825,
      '1111A0': 0.16955047840651516,
      '111200': 0.057521112069484286,
      '112A00': 0.15936051992859004,
    },
  },
  ['01.16']: {
    label: 'Growing of fibre crops',
    BEA_CODES: {
      '111900': 0.06980923916313964,
      '111400': 0.07441879124626402,
      '1111B0': 0.3671955549314986,
      '111300': 0.10214430425450825,
      '1111A0': 0.16955047840651516,
      '111200': 0.057521112069484286,
      '112A00': 0.15936051992859004,
    },
  },
  ['01.19']: {
    label: 'Growing of other non-perennial crops',
    BEA_CODES: {
      '111900': 0.06518595660513067,
      '111400': 0.06949023016063294,
      '1111B0': 0.3428771577559338,
      '111300': 0.09537955526252698,
      '113000': 0.06622737353152722,
      '1111A0': 0.15832159554063774,
      '111200': 0.05371163989450971,
      '112A00': 0.14880649124910095,
    },
  },
  ['01.21']: {
    label: 'Growing of grapes',
    BEA_CODES: {
      '111900': 0.11281037277147488,
      '311224': 0.2393192868719611,
      '111400': 0.12025931928687196,
      '111300': 0.16506320907617503,
      '312130': 0.10502431118314424,
      '112A00': 0.2575235008103728,
    },
  },
  ['01.22']: {
    label: 'Growing of tropical and subtropical fruits',
    BEA_CODES: {
      '111900': 0.11281037277147488,
      '311224': 0.2393192868719611,
      '111400': 0.12025931928687196,
      '111300': 0.16506320907617503,
      '312130': 0.10502431118314424,
      '112A00': 0.2575235008103728,
    },
  },
  ['01.23']: {
    label: 'Growing of citrus fruits',
    BEA_CODES: {
      '111900': 0.11281037277147488,
      '311224': 0.2393192868719611,
      '111400': 0.12025931928687196,
      '111300': 0.16506320907617503,
      '312130': 0.10502431118314424,
      '112A00': 0.2575235008103728,
    },
  },
  ['01.24']: {
    label: 'Growing of pome fruits and stone fruits',
    BEA_CODES: {
      '111900': 0.11281037277147488,
      '311224': 0.2393192868719611,
      '111400': 0.12025931928687196,
      '111300': 0.16506320907617503,
      '312130': 0.10502431118314424,
      '112A00': 0.2575235008103728,
    },
  },
  ['01.25']: {
    label: 'Growing of other tree and bush fruits and nuts',
    BEA_CODES: {
      '111900': 0.09341966102422866,
      '311224': 0.1981832530722028,
      '111400': 0.09958822550424927,
      '111300': 0.13669087922176232,
      '113000': 0.09491214224741902,
      '312130': 0.08697192739454654,
      '111200': 0.07697552438166717,
      '112A00': 0.2132583871539242,
    },
  },
  ['01.26']: {
    label: 'Growing of oleaginous fruits',
    BEA_CODES: {
      '111900': 0.05754679542297771,
      '311224': 0.12208148687082479,
      '111400': 0.06134664991070838,
      '1111B0': 0.3026952840796349,
      '111300': 0.08420199748660626,
      '312130': 0.05357497188967524,
      '1111A0': 0.13976784178847806,
      '111200': 0.04741715721939282,
      '112A00': 0.13136781533170183,
    },
  },
  ['01.27']: {
    label: 'Growing of beverage crops',
    BEA_CODES: {
      '111900': 0.11281037277147488,
      '311224': 0.2393192868719611,
      '111400': 0.12025931928687196,
      '111300': 0.16506320907617503,
      '312130': 0.10502431118314424,
      '112A00': 0.2575235008103728,
    },
  },
  ['01.28']: {
    label: 'Growing of spices, aromatic, drug and pharmaceutical crops',
    BEA_CODES: {
      '111900': 0.05436810088139999,
      '311224': 0.11533810953605429,
      '111400': 0.05795806398195333,
      '1111B0': 0.28597539828594104,
      '111300': 0.07955095779215707,
      '113000': 0.055236690735145706,
      '312130': 0.050615667736261125,
      '1111A0': 0.13204752873688913,
      '111200': 0.04479799037052543,
      '112A00': 0.12411149194367288,
    },
  },
  ['01.29']: {
    label: 'Growing of other perennial crops',
    BEA_CODES: {
      '111900': 0.06518595660513067,
      '111400': 0.06949023016063294,
      '1111B0': 0.3428771577559338,
      '111300': 0.09537955526252698,
      '113000': 0.06622737353152722,
      '1111A0': 0.15832159554063774,
      '111200': 0.05371163989450971,
      '112A00': 0.14880649124910095,
    },
  },
  ['01.30']: {
    label: 'Plant propagation',
    BEA_CODES: {
      '111900': 0.1615826763610701,
      '111400': 0.17225209163254127,
      '113000': 0.16416413627879767,
      '111200': 0.13314018813085587,
      '112A00': 0.3688609075967351,
    },
  },
  ['01.41']: {
    label: 'Raising of dairy cattle',
    BEA_CODES: { '1121A0': 0.6806775022586332, '112120': 0.3193224977413668 },
  },
  ['01.42']: {
    label: 'Raising of other cattle and buffaloes',
    BEA_CODES: { '1121A0': 0.6806775022586332, '112120': 0.3193224977413668 },
  },
  ['01.43']: {
    label: 'Raising of horses and other equines',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['01.44']: {
    label: 'Raising of camels and camelids',
    BEA_CODES: { '112300': 0.4833519756522644, '112A00': 0.5166480243477356 },
  },
  ['01.45']: {
    label: 'Raising of sheep and goats',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['01.46']: {
    label: 'Raising of swine and pigs',
    BEA_CODES: { '112A00': 1.0 },
  },
  ['01.47']: {
    label: 'Raising of poultry',
    BEA_CODES: {
      '115000': 0.06106360470754176,
      '561300': 0.5430092123242386,
      '711A00': 0.05349980164852118,
      '713900': 0.14617622426940538,
      '713100': 0.026801692599285935,
      '112300': 0.08190373341561247,
      '112A00': 0.08754573103539472,
    },
  },
  ['01.48']: {
    label: 'Raising of other animals',
    BEA_CODES: {
      '111400': 0.16439643024894315,
      '111900': 0.15421360014888733,
      '112300': 0.329351187996845,
      '112A00': 0.3520387816053245,
    },
  },
  ['01.50']: {
    label: 'Mixed farming',
    BEA_CODES: { '111900': 0.3046180239479028, '112A00': 0.6953819760520972 },
  },
  ['01.61']: {
    label: 'Support activities for crop production',
    BEA_CODES: {
      '115000': 0.11590025893189547,
      '221300': 0.2910034761293237,
      '561700': 0.5930962649387808,
    },
  },
  ['01.62']: {
    label: 'Support activities for animal production',
    BEA_CODES: {
      '115000': 0.07352184134970041,
      '561300': 0.6537943076098436,
      '711A00': 0.06441486623466913,
      '713900': 0.1759991933215516,
      '713100': 0.032269791484235276,
    },
  },
  ['01.63']: {
    label: 'Post-harvest crop activities and seed processing for propagation',
    BEA_CODES: {
      '561700': 0.2773685929977562,
      '1111B0': 0.17905469512636474,
      '221300': 0.13609127135504392,
      '1111A0': 0.08267749653252579,
      '112A00': 0.07770859131537093,
      '311224': 0.07221540790994935,
      '115000': 0.05420214836681476,
      '111300': 0.049808384147236096,
      '111400': 0.03628865818040256,
      '113000': 0.03458475406853568,
    },
  },
  ['01.70']: {
    label: 'Hunting, trapping and related service activities',
    BEA_CODES: { '114000': 1.0 },
  },
  ['02.10']: {
    label: 'Silviculture and other forestry activities',
    BEA_CODES: {
      '111900': 0.1615826763610701,
      '111400': 0.17225209163254127,
      '113000': 0.16416413627879767,
      '111200': 0.13314018813085587,
      '112A00': 0.3688609075967351,
    },
  },
  ['02.20']: {
    label: 'Logging',
    BEA_CODES: { '113000': 0.48797924314775454, '111400': 0.5120207568522455 },
  },
  ['02.30']: {
    label: 'Gathering of wild growing non-wood products',
    BEA_CODES: {
      '111900': 0.09341966102422866,
      '311224': 0.1981832530722028,
      '111400': 0.09958822550424927,
      '111300': 0.13669087922176232,
      '113000': 0.09491214224741902,
      '312130': 0.08697192739454654,
      '111200': 0.07697552438166717,
      '112A00': 0.2132583871539242,
    },
  },
  ['02.40']: {
    label: 'Support services to forestry',
    BEA_CODES: {
      '115000': 0.030504238687658263,
      '611B00': 0.08014752834966421,
      '561300': 0.27125949576131236,
      '5416A0': 0.049081801167015306,
      '541800': 0.35452934052625784,
      '541610': 0.21447759550809203,
    },
  },
  ['03.11']: {
    label: 'Marine fishing',
    BEA_CODES: {
      '5419A0': 0.806960020115665,
      '114000': 0.08690973095297963,
      '311700': 0.1061302489313553,
    },
  },
  ['03.30']: {
    label: 'Support activities for fishing and aquaculture',
    BEA_CODES: {
      '811100': 0.15514804996968345,
      '230301': 0.14713880300593135,
      '2334A0': 0.14112309853461683,
      '233411': 0.11107297183985515,
      '233240': 0.09485069728053076,
      '2332D0': 0.07826094945071098,
      '326190': 0.06972220691083596,
      '233262': 0.06812035751808553,
      '2332A0': 0.06755578494796077,
      '5419A0': 0.06700708054178922,
    },
  },
  ['03.12']: {
    label: 'Freshwater fishing',
    BEA_CODES: {
      '5419A0': 0.806960020115665,
      '114000': 0.08690973095297963,
      '311700': 0.1061302489313553,
    },
  },
  ['03.21']: {
    label: 'Marine aquaculture',
    BEA_CODES: {
      '111900': 0.22994687739514233,
      '111400': 0.2451304278880461,
      '112A00': 0.5249226947168116,
    },
  },
  ['03.22']: {
    label: 'Freshwater aquaculture',
    BEA_CODES: {
      '112300': 0.329351187996845,
      '111900': 0.15421360014888733,
      '111400': 0.16439643024894315,
      '112A00': 0.3520387816053245,
    },
  },
  ['05.10']: {
    label: 'Mining of hard coal',
    BEA_CODES: {
      '211000': 0.21663489927869342,
      '230301': 0.13168815637029563,
      '2334A0': 0.12630414470979556,
      '233411': 0.09940950031767389,
      '21311A': 0.0949104907127107,
      '233240': 0.08489068281197444,
      '2332D0': 0.07004297940725791,
      '233262': 0.06096722353029114,
      '2332A0': 0.060461935194528534,
      '230302': 0.05468998766677879,
    },
  },
  ['05.20']: {
    label: 'Mining of lignite',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['06.10']: {
    label: 'Extraction of crude petroleum',
    BEA_CODES: { '211000': 0.6949623174922129, '48A000': 0.30503768250778707 },
  },
  ['06.20']: {
    label: 'Extraction of natural gas',
    BEA_CODES: { '211000': 0.6949623174922129, '48A000': 0.30503768250778707 },
  },
  ['07.10']: {
    label: 'Mining of iron ores',
    BEA_CODES: {
      '230301': 0.1606238637834802,
      '2334A0': 0.15405682860430217,
      '233411': 0.12125265079200104,
      '21311A': 0.11576507828839523,
      '233240': 0.10354362797923493,
      '2332D0': 0.08543345349649266,
      '233262': 0.07436349082183213,
      '2332A0': 0.0737471759834142,
      '230302': 0.06670696417533245,
      '233230': 0.044506866075514975,
    },
  },
  ['07.21']: {
    label: 'Mining of uranium and thorium ores',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['07.29']: {
    label: 'Mining of other non-ferrous metal ores',
    BEA_CODES: {
      '230301': 0.1606238637834802,
      '2334A0': 0.15405682860430217,
      '233411': 0.12125265079200104,
      '21311A': 0.11576507828839523,
      '233240': 0.10354362797923493,
      '2332D0': 0.08543345349649266,
      '233262': 0.07436349082183213,
      '2332A0': 0.0737471759834142,
      '230302': 0.06670696417533245,
      '233230': 0.044506866075514975,
    },
  },
  ['08.11']: {
    label:
      'Quarrying of ornamental stone, limestone, gypsum, slate and other stone',
    BEA_CODES: {
      '230301': 0.1606238637834802,
      '2334A0': 0.15405682860430217,
      '233411': 0.12125265079200104,
      '21311A': 0.11576507828839523,
      '233240': 0.10354362797923493,
      '2332D0': 0.08543345349649266,
      '233262': 0.07436349082183213,
      '2332A0': 0.0737471759834142,
      '230302': 0.06670696417533245,
      '233230': 0.044506866075514975,
    },
  },
  ['08.12']: {
    label: 'Operation of gravel and sand pits and mining of clay and kaolin',
    BEA_CODES: {
      '230301': 0.1606238637834802,
      '2334A0': 0.15405682860430217,
      '233411': 0.12125265079200104,
      '21311A': 0.11576507828839523,
      '233240': 0.10354362797923493,
      '2332D0': 0.08543345349649266,
      '233262': 0.07436349082183213,
      '2332A0': 0.0737471759834142,
      '230302': 0.06670696417533245,
      '233230': 0.044506866075514975,
    },
  },
  ['08.91']: {
    label: 'Mining of chemical and fertiliser minerals',
    BEA_CODES: {
      '230301': 0.1606238637834802,
      '2334A0': 0.15405682860430217,
      '233411': 0.12125265079200104,
      '21311A': 0.11576507828839523,
      '233240': 0.10354362797923493,
      '2332D0': 0.08543345349649266,
      '233262': 0.07436349082183213,
      '2332A0': 0.0737471759834142,
      '230302': 0.06670696417533245,
      '233230': 0.044506866075514975,
    },
  },
  ['08.92']: {
    label: 'Extraction of peat',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['08.93']: {
    label: 'Extraction of salt',
    BEA_CODES: {
      '230301': 0.1606238637834802,
      '2334A0': 0.15405682860430217,
      '233411': 0.12125265079200104,
      '21311A': 0.11576507828839523,
      '233240': 0.10354362797923493,
      '2332D0': 0.08543345349649266,
      '233262': 0.07436349082183213,
      '2332A0': 0.0737471759834142,
      '230302': 0.06670696417533245,
      '233230': 0.044506866075514975,
    },
  },
  ['08.99']: {
    label: 'Other mining and quarrying n.e.c.',
    BEA_CODES: {
      '230301': 0.1606238637834802,
      '2334A0': 0.15405682860430217,
      '233411': 0.12125265079200104,
      '21311A': 0.11576507828839523,
      '233240': 0.10354362797923493,
      '2332D0': 0.08543345349649266,
      '233262': 0.07436349082183213,
      '2332A0': 0.0737471759834142,
      '230302': 0.06670696417533245,
      '233230': 0.044506866075514975,
    },
  },
  ['09.10']: {
    label: 'Support activities for petroleum and natural gas extraction',
    BEA_CODES: {
      '21311A': 0.20299173651488434,
      '211000': 0.46333228354512945,
      '561900': 0.05428097767787915,
      '213111': 0.07602598145871535,
      '48A000': 0.20336902080339173,
    },
  },
  ['09.90']: {
    label: 'Support activities for other mining and quarrying',
    BEA_CODES: {
      '212310': 0.02198613032620707,
      '311940': 0.03367702976672148,
      '212100': 0.07649203407380337,
      '21311A': 0.22096809278933585,
      '211000': 0.5043636395277002,
      '324190': 0.0607307247209098,
      '2122A0': 0.037677830274870136,
      '2123A0': 0.024746143203946854,
      '212230': 0.01935837531650526,
    },
  },
  ['10.11']: {
    label: 'Processing and preserving of meat, except of poultry meat',
    BEA_CODES: {
      '311615': 0.25393029674124323,
      '31161A': 0.6168777889420778,
      '311700': 0.04629918081339482,
      '311225': 0.0828927335032842,
    },
  },
  ['10.12']: {
    label: 'Processing and preserving of poultry meat',
    BEA_CODES: { '311615': 1.0 },
  },
  ['10.13']: {
    label: 'Production of meat and poultry meat products',
    BEA_CODES: { '311615': 0.2916030534351145, '31161A': 0.7083969465648855 },
  },
  ['10.20']: {
    label: 'Processing and preserving of fish, crustaceans and molluscs',
    BEA_CODES: { '311700': 1.0 },
  },
  ['10.31']: {
    label: 'Processing and preserving of potatoes',
    BEA_CODES: {
      '311210': 0.1361854701152226,
      '311410': 0.21120200508232673,
      '311420': 0.2656734082918509,
      '311990': 0.16198698088905908,
      '311910': 0.2249521356215407,
    },
  },
  ['10.32']: {
    label: 'Manufacture of fruit and vegetable juice',
    BEA_CODES: {
      '311940': 0.2202895911119206,
      '311410': 0.34532374100719426,
      '311420': 0.43438666788088515,
    },
  },
  ['10.39']: {
    label: 'Other processing and preserving of fruit and vegetables',
    BEA_CODES: {
      '115000': 0.054809560170440384,
      '311410': 0.060010207432435105,
      '424400': 0.2627226941291438,
      '561700': 0.2804769007386541,
      '221300': 0.13761636670794478,
      '311420': 0.07548752358985113,
      '311990': 0.046026420633256446,
      '311910': 0.0639171219788176,
      '311930': 0.018933204619456634,
    },
  },
  ['10.41']: {
    label: 'Manufacture of oils and fats',
    BEA_CODES: {
      '31161A': 0.4138089365447048,
      '111900': 0.05121692062716519,
      '311224': 0.10865310183045819,
      '111400': 0.05459880912786129,
      '311221': 0.04551862982007411,
      '311700': 0.031058039564269127,
      '311225': 0.05560542868159329,
      '111300': 0.07494017677652164,
      '312130': 0.04768197886098937,
      '112A00': 0.11691797816636301,
    },
  },
  ['10.42']: {
    label: 'Manufacture of margarine and similar edible fats',
    BEA_CODES: {
      '311221': 0.21698563250645414,
      '311224': 0.5179453361768998,
      '311225': 0.2650690313166461,
    },
  },
  ['10.51']: {
    label: 'Manufacture of dairy products',
    BEA_CODES: {
      '31151A': 0.3953026272906,
      '311513': 0.3933786809928824,
      '311514': 0.21131869171651754,
    },
  },
  ['10.52']: {
    label: 'Manufacture of ice cream and other edible ice',
    BEA_CODES: { '311520': 1.0 },
  },
  ['10.61']: {
    label: 'Manufacture of grain mill products',
    BEA_CODES: {
      '311210': 0.09084744818105397,
      '311300': 0.15792529154688204,
      '311514': 0.09335073403400568,
      '311119': 0.1724062661099681,
      '311420': 0.17722706521082868,
      '311230': 0.04477584213044023,
      '311410': 0.14088994366445753,
      '3118A0': 0.12257740912236377,
    },
  },
  ['10.62']: {
    label: 'Manufacture of starches and starch products',
    BEA_CODES: {
      '311221': 0.09853017068369044,
      '311300': 0.21664532324139732,
      '311514': 0.1280605516160477,
      '311225': 0.12036417617564046,
      '311420': 0.2431239129188249,
      '311410': 0.19327586536439917,
    },
  },
  ['10.71']: {
    label: 'Manufacture of bread; manufacture of fresh pastry goods and cakes',
    BEA_CODES: {
      '311300': 0.20121424429269677,
      '311514': 0.11893913393374912,
      '311810': 0.27453045670260484,
      '311420': 0.22580683337869983,
      '311410': 0.17950933169224942,
    },
  },
  ['10.72']: {
    label: 'Manufacture of rusks, biscuits, preserved pastries and cakes',
    BEA_CODES: {
      '311300': 0.14933815843793094,
      '311514': 0.08827482015652312,
      '311810': 0.20375233862396683,
      '311420': 0.16759040483447374,
      '311910': 0.14190287134713525,
      '311410': 0.1332291017048898,
      '3118A0': 0.11591230489508032,
    },
  },
  ['10.73']: {
    label: 'Manufacture of farinaceous products',
    BEA_CODES: {
      '311300': 0.19739928015790084,
      '311514': 0.11668408220132358,
      '311420': 0.22152560083594566,
      '311990': 0.13506908162080575,
      '311410': 0.17610588645071404,
      '3118A0': 0.15321606873331012,
    },
  },
  ['10.81']: {
    label: 'Manufacture of sugar',
    BEA_CODES: {
      '111900': 0.19095118953559828,
      '311300': 0.37314546571854973,
      '112A00': 0.43590334474585196,
    },
  },
  ['10.82']: {
    label: 'Manufacture of cocoa, chocolate and sugar confectionery',
    BEA_CODES: { '311300': 1.0 },
  },
  ['10.83']: {
    label: 'Processing of tea and coffee',
    BEA_CODES: { '311920': 1.0 },
  },
  ['10.84']: {
    label: 'Manufacture of condiments and seasonings',
    BEA_CODES: {
      '311940': 0.09738913380437628,
      '21311A': 0.6390079916259034,
      '2123A0': 0.07156229240896189,
      '311420': 0.1920405821607585,
    },
  },
  ['10.85']: {
    label: 'Manufacture of prepared meals and dishes',
    BEA_CODES: {
      '31161A': 0.3384097687080725,
      '311410': 0.07301979304318941,
      '311700': 0.025399026113915306,
      '311615': 0.1393022970328488,
      '311810': 0.11167195010699267,
      '311420': 0.09185242954008796,
      '311990': 0.056004467455692514,
      '311910': 0.07777368581943873,
      '311930': 0.023037725448851725,
      '3118A0': 0.06352885673091041,
    },
  },
  ['10.86']: {
    label: 'Manufacture of homogenised food preparations and dietetic food',
    BEA_CODES: {
      '311420': 0.31125611745513865,
      '311300': 0.27735725938009786,
      '311514': 0.1639477977161501,
      '311410': 0.24743882544861337,
    },
  },
  ['10.89']: {
    label: 'Manufacture of other food products n.e.c.',
    BEA_CODES: {
      '31161A': 0.319014674148584,
      '311615': 0.1313185404361628,
      '311810': 0.10527175651850322,
      '311420': 0.08658814132745793,
      '311513': 0.08490221394259678,
      '311910': 0.07331628496937875,
      '311410': 0.06883484945780304,
      '311990': 0.052794713948269484,
      '311225': 0.04286748338465239,
      '311221': 0.03509134186659164,
    },
  },
  ['10.91']: {
    label: 'Manufacture of prepared feeds for farm animals',
    BEA_CODES: { '311119': 1.0 },
  },
  ['10.92']: {
    label: 'Manufacture of prepared pet foods',
    BEA_CODES: { '311119': 0.6617466174661747, '311111': 0.33825338253382536 },
  },
  ['11.01']: {
    label: 'Distilling, rectifying and blending of spirits',
    BEA_CODES: {
      '325190': 0.21915989901958166,
      '424A00': 0.49950244436726954,
      '424400': 0.2217461865199156,
      '312130': 0.027048326343066278,
      '312140': 0.03254314375016697,
    },
  },
  ['11.02']: {
    label: 'Manufacture of wine from grape',
    BEA_CODES: {
      '111900': 0.028727937304054534,
      '311224': 0.06094430237222993,
      '111400': 0.030624862765945154,
      '424A00': 0.49390391882677886,
      '424400': 0.219260809916182,
      '111300': 0.04203448144925766,
      '312130': 0.02674516317025938,
      '312140': 0.03217839354021763,
      '112A00': 0.0655801306550749,
    },
  },
  ['11.03']: {
    label: 'Manufacture of cider and other fruit fermented beverages',
    BEA_CODES: { '312130': 1.0 },
  },
  ['11.04']: {
    label: 'Manufacture of other non-distilled fermented beverages',
    BEA_CODES: { '312130': 1.0 },
  },
  ['11.05']: { label: 'Manufacture of beer', BEA_CODES: { '312120': 1.0 } },
  ['11.06']: { label: 'Manufacture of malt', BEA_CODES: { '311210': 1.0 } },
  ['11.07']: {
    label: 'Manufacture of soft drinks and bottled waters',
    BEA_CODES: { '312110': 1.0 },
  },
  ['12.00']: {
    label: 'Manufacture of tobacco products',
    BEA_CODES: { '312200': 1.0 },
  },
  ['13.10']: {
    label: 'Preparation and spinning of textile fibres',
    BEA_CODES: {
      '313300': 0.37092525518603886,
      '313100': 0.19863351992097464,
      '314900': 0.4304412248929865,
    },
  },
  ['13.20']: {
    label: 'Weaving of textiles',
    BEA_CODES: {
      '315000': 0.3062799171250982,
      '316000': 0.16348741396966016,
      '314900': 0.24905336857898122,
      '313200': 0.2811793003262604,
    },
  },
  ['13.96']: {
    label: 'Manufacture of other technical and industrial textiles',
    BEA_CODES: {
      '811300': 0.2741815442007479,
      '339113': 0.1465961610908456,
      '339990': 0.1169222570360093,
      '336360': 0.1022575873126717,
      '322220': 0.08837852489594149,
      '33299A': 0.0625913096211627,
      '315000': 0.05908593900747016,
      '313200': 0.05424365771411245,
      '314900': 0.04804608896198763,
      '322291': 0.04769693015905102,
    },
  },
  ['23.99']: {
    label: 'Manufacture of other non-metallic mineral products n.e.c.',
    BEA_CODES: {
      '324110': 0.7590731266321561,
      '322120': 0.04806416474885183,
      '3259A0': 0.03893415804405034,
      '336350': 0.036254250904544164,
      '339113': 0.03212365023084249,
      '339990': 0.025621200864174413,
      '3363A0': 0.021943627317737118,
      '324121': 0.013151880867959707,
      '315000': 0.012947515297215998,
      '313200': 0.011886425092467871,
    },
  },
  ['13.30']: {
    label: 'Finishing of textiles',
    BEA_CODES: {
      '4B0000': 0.23744025273350156,
      '448000': 0.15829948509651875,
      '444000': 0.1427023714279853,
      '812900': 0.08163066445114571,
      '323110': 0.0807793300100519,
      '561600': 0.0676195458182042,
      '322210': 0.06613099267647239,
      '322120': 0.061212741296900325,
      '811200': 0.060651168277022174,
      '561900': 0.04353344821219767,
    },
  },
  ['13.91']: {
    label: 'Manufacture of knitted and crocheted fabrics',
    BEA_CODES: {
      '315000': 0.3062799171250982,
      '316000': 0.16348741396966016,
      '314900': 0.24905336857898122,
      '313200': 0.2811793003262604,
    },
  },
  ['13.92']: {
    label: 'Manufacture of household textiles and made-up furnishing articles',
    BEA_CODES: {
      '339113': 0.2135767019403894,
      '314120': 0.024945951553850994,
      '337900': 0.06401477881970241,
      '336360': 0.1489796054965429,
      '313200': 0.0790278642329806,
      '811300': 0.39945650355079887,
      '314900': 0.06999859440573482,
    },
  },
  ['13.93']: {
    label: 'Manufacture of carpets and rugs',
    BEA_CODES: { '314110': 0.4648723328045848, '314900': 0.5351276671954153 },
  },
  ['13.94']: {
    label: 'Manufacture of cordage, rope, twine and netting',
    BEA_CODES: { '811300': 0.8508939519233512, '314900': 0.1491060480766489 },
  },
  ['13.95']: {
    label: 'Manufacture of non-wovens and non-woven articles',
    BEA_CODES: { '313200': 1.0 },
  },
  ['13.99']: {
    label: 'Manufacture of other textiles n.e.c.',
    BEA_CODES: {
      '326190': 0.28365572472656475,
      '3259A0': 0.1314052142121769,
      '711500': 0.11385235346283354,
      '339990': 0.08647315254951395,
      '336360': 0.0756274824930091,
      '322220': 0.06536283047524558,
      '3219A0': 0.06439446707734335,
      '332430': 0.06174081159046043,
      '333318': 0.05875417500535148,
      '321910': 0.05873378840750091,
    },
  },
  ['14.24']: {
    label: 'Manufacture of leather clothes and fur apparel',
    BEA_CODES: {
      '315000': 0.4260866684336072,
      '316000': 0.22743837794858204,
      '314900': 0.3464749536178108,
    },
  },
  ['14.23']: {
    label: 'Manufacture of workwear',
    BEA_CODES: { '315000': 0.5515245079120031, '314900': 0.4484754920879969 },
  },
  ['14.10']: {
    label: 'Manufacture of knitted and crocheted apparel',
    BEA_CODES: {
      '339113': 0.33047485888871625,
      '339990': 0.26358034280979753,
      '313300': 0.09333540469162653,
      '315000': 0.13319869504427528,
      '316000': 0.0710993734141163,
      '314900': 0.10831132515146807,
    },
  },
  ['14.21']: {
    label: 'Manufacture of outerwear',
    BEA_CODES: {
      '339113': 0.33047485888871625,
      '339990': 0.26358034280979753,
      '313300': 0.09333540469162653,
      '315000': 0.13319869504427528,
      '316000': 0.0710993734141163,
      '314900': 0.10831132515146807,
    },
  },
  ['14.22']: {
    label: 'Manufacture of underwear',
    BEA_CODES: {
      '339113': 0.33047485888871625,
      '339990': 0.26358034280979753,
      '313300': 0.09333540469162653,
      '315000': 0.13319869504427528,
      '316000': 0.0710993734141163,
      '314900': 0.10831132515146807,
    },
  },
  ['14.29']: {
    label: 'Manufacture of other wearing apparel and accessories n.e.c.',
    BEA_CODES: {
      '339113': 0.33047485888871625,
      '339990': 0.26358034280979753,
      '313300': 0.09333540469162653,
      '315000': 0.13319869504427528,
      '316000': 0.0710993734141163,
      '314900': 0.10831132515146807,
    },
  },
  ['15.11']: {
    label: 'Tanning, dressing, dyeing of leather and fur',
    BEA_CODES: {
      '315000': 0.4260866684336072,
      '316000': 0.22743837794858204,
      '314900': 0.3464749536178108,
    },
  },
  ['15.12']: {
    label:
      'Manufacture of luggage, handbags, saddlery and harness of any material',
    BEA_CODES: {
      '326190': 0.2765903872722634,
      '3259A0': 0.12813215429928868,
      '711500': 0.1110165027217398,
      '339113': 0.10571880104297467,
      '339990': 0.08431926686965135,
      '3219A0': 0.06279052046026061,
      '332430': 0.06020296260465363,
      '333318': 0.05729071759174897,
      '321910': 0.05727083878620013,
      '326290': 0.056667848351218734,
    },
  },
  ['15.20']: {
    label: 'Manufacture of footwear',
    BEA_CODES: {
      '326190': 0.24919033959470713,
      '3259A0': 0.11543891802505545,
      '324190': 0.10416784819874812,
      '711500': 0.10001880501586487,
      '339113': 0.09524591289397255,
      '33291A': 0.07691549981045738,
      '339990': 0.0759662942477546,
      '336360': 0.06643841954288887,
      '326210': 0.05919699597333867,
      '322220': 0.05742096669721238,
    },
  },
  ['16.11']: {
    label: 'Sawmilling and planing of wood',
    BEA_CODES: {
      '111400': 0.18857375216021144,
      '321910': 0.17572430619091187,
      '113000': 0.17971942665446783,
      '3219A0': 0.19266036393209313,
      '321100': 0.26332215106231577,
    },
  },
  ['16.12']: {
    label: 'Processing and finishing of wood',
    BEA_CODES: {
      '111400': 0.18857375216021144,
      '321910': 0.17572430619091187,
      '113000': 0.17971942665446783,
      '3219A0': 0.19266036393209313,
      '321100': 0.26332215106231577,
    },
  },
  ['16.21']: {
    label: 'Manufacture of veneer sheets and wood-based panels',
    BEA_CODES: { '321200': 1.0 },
  },
  ['16.27']: {
    label: 'Finishing of wooden products',
    BEA_CODES: {
      '326190': 0.22524741789611147,
      '811300': 0.16102399171136558,
      '3259A0': 0.10434721607649719,
      '324190': 0.09415910292800328,
      '711500': 0.09040871170014138,
      '339113': 0.08609441272650745,
      '321100': 0.06988948487432142,
      '339990': 0.06866723507128442,
      '3219A0': 0.051134830611826415,
      '332430': 0.04902759640394142,
    },
  },
  ['16.22']: {
    label: 'Manufacture of assembled parquet floors',
    BEA_CODES: {
      '321910': 0.20172244783643747,
      '337215': 0.07760351024599729,
      '321200': 0.19722961303272185,
      '3219A0': 0.22116416935069785,
      '321100': 0.30228025953414556,
    },
  },
  ['16.23']: {
    label: "Manufacture of other builders' carpentry and joinery",
    BEA_CODES: {
      '321910': 0.20172244783643747,
      '337215': 0.07760351024599729,
      '321200': 0.19722961303272185,
      '3219A0': 0.22116416935069785,
      '321100': 0.30228025953414556,
    },
  },
  ['16.25']: {
    label: 'Manufacture of doors and windows of wood',
    BEA_CODES: {
      '321910': 0.20172244783643747,
      '337215': 0.07760351024599729,
      '321200': 0.19722961303272185,
      '3219A0': 0.22116416935069785,
      '321100': 0.30228025953414556,
    },
  },
  ['16.24']: {
    label: 'Manufacture of wooden containers',
    BEA_CODES: { '811300': 0.758978532912809, '3219A0': 0.24102146708719097 },
  },
  ['16.26']: {
    label: 'Manufacture of solid fuels from vegetable biomass',
    BEA_CODES: {
      '326190': 0.26119209193330895,
      '3259A0': 0.12099880171326845,
      '324190': 0.1091848834408037,
      '711500': 0.10483600992419194,
      '339113': 0.09983324103709683,
      '339990': 0.07962505827177643,
      '3219A0': 0.05929485675311226,
      '332430': 0.05685135300025342,
      '333318': 0.054101238021043545,
      '321910': 0.0540824659051445,
    },
  },
  ['16.28']: {
    label:
      'Manufacture of other products of wood and articles of cork, straw and plaiting materials',
    BEA_CODES: {
      '326190': 0.26119209193330895,
      '3259A0': 0.12099880171326845,
      '324190': 0.1091848834408037,
      '711500': 0.10483600992419194,
      '339113': 0.09983324103709683,
      '339990': 0.07962505827177643,
      '3219A0': 0.05929485675311226,
      '332430': 0.05685135300025342,
      '333318': 0.054101238021043545,
      '321910': 0.0540824659051445,
    },
  },
  ['17.11']: { label: 'Manufacture of pulp', BEA_CODES: { '322110': 1.0 } },
  ['17.12']: {
    label: 'Manufacture of paper and paperboard',
    BEA_CODES: {
      '322299': 0.04977077336124761,
      '339940': 0.029930311224835957,
      '322120': 0.46274703653087534,
      '322130': 0.2710980586006029,
      '322220': 0.18645382028243823,
    },
  },
  ['17.21']: {
    label:
      'Manufacture of corrugated paper, paperboard and containers of paper and paperboard',
    BEA_CODES: {
      '322299': 0.03385773815803355,
      '322210': 0.34008729823821077,
      '322120': 0.31479454583816857,
      '322130': 0.18442082498153814,
      '322220': 0.12683959278404894,
    },
  },
  ['17.22']: {
    label:
      'Manufacture of household and sanitary goods and of toilet requisites',
    BEA_CODES: {
      '322210': 0.21227061530040703,
      '322120': 0.19648376250581306,
      '322130': 0.11510903875515974,
      '339990': 0.10473811356163087,
      '336360': 0.0916016083164943,
      '322220': 0.07916884442377575,
      '33299A': 0.05606884318914181,
      '315000': 0.052928757505545564,
      '313200': 0.04859107688888706,
      '314900': 0.04303933955314482,
    },
  },
  ['17.23']: {
    label: 'Manufacture of paper stationery',
    BEA_CODES: {
      '339940': 0.017532703488372093,
      '323110': 0.3577171148255814,
      '322230': 0.040618186773255814,
      '322120': 0.27106990370639533,
      '339950': 0.04503542877906977,
      '322130': 0.15880496002906977,
      '322220': 0.10922170239825581,
    },
  },
  ['17.24']: {
    label: 'Manufacture of wallpaper',
    BEA_CODES: { '322220': 1.0 },
  },
  ['17.25']: {
    label: 'Manufacture of other articles of paper and paperboard',
    BEA_CODES: {
      '326190': 0.20381591796875,
      '323110': 0.153818359375,
      '322210': 0.12592529296875,
      '322120': 0.11656005859375,
      '3259A0': 0.0944189453125,
      '711500': 0.081806640625,
      '322130': 0.0682861328125,
      '339990': 0.0621337890625,
      '322220': 0.04696533203125,
      '3219A0': 0.04626953125,
    },
  },
  ['18.11']: { label: 'Printing of newspapers', BEA_CODES: { '323110': 1.0 } },
  ['18.12']: {
    label: 'Other printing',
    BEA_CODES: {
      '541800': 0.25976501526634427,
      '541300': 0.23146197934102633,
      '21311A': 0.10242849825152966,
      '518200': 0.0898564519967249,
      '5419A0': 0.06472123292367635,
      '561400': 0.05815972992485813,
      '812900': 0.05135945081494448,
      '323110': 0.05082381791708177,
      '491000': 0.04981627952938519,
      '322210': 0.041607544034428935,
    },
  },
  ['18.13']: {
    label: 'Pre-press and pre-media services',
    BEA_CODES: { '323120': 1.0 },
  },
  ['18.14']: {
    label: 'Binding and related services',
    BEA_CODES: { '323120': 1.0 },
  },
  ['18.20']: {
    label: 'Reproduction of recorded media',
    BEA_CODES: { '334610': 1.0 },
  },
  ['19.10']: {
    label: 'Manufacture of coke oven products',
    BEA_CODES: {
      '325610': 0.1445923339879264,
      '325110': 0.3299654994671549,
      '325190': 0.41508473343515895,
      '324190': 0.11035743310975976,
    },
  },
  ['19.20']: {
    label: 'Manufacture of refined petroleum products and fossil fuel products',
    BEA_CODES: {
      '324110': 0.551082100160866,
      '21311A': 0.09280385669253241,
      '212100': 0.03212570502236849,
      '211000': 0.21182646930060173,
      '324190': 0.025506150696635553,
      '2123A0': 0.01039307303874599,
      '325110': 0.07626264508824976,
    },
  },
  ['20.11']: {
    label: 'Manufacture of industrial gases',
    BEA_CODES: { '325120': 1.0 },
  },
  ['20.12']: {
    label: 'Manufacture of dyes and pigments',
    BEA_CODES: {
      '325180': 0.21243002001034242,
      '325130': 0.04976729545607842,
      '325190': 0.7378026845335791,
    },
  },
  ['20.13']: {
    label: 'Manufacture of other inorganic basic chemicals',
    BEA_CODES: {
      '325180': 0.12113917558817873,
      '325412': 0.38652477722930956,
      '327999': 0.012673889351881532,
      '331410': 0.08489005705493942,
      '325310': 0.08506314507340214,
      '562111': 0.3097089557022886,
    },
  },
  ['20.14']: {
    label: 'Manufacture of other organic basic chemicals',
    BEA_CODES: {
      '325610': 0.15199787248188285,
      '325110': 0.346865235024267,
      '312140': 0.06479289940828402,
      '325190': 0.4363439930855661,
    },
  },
  ['20.51']: {
    label: 'Manufacture of liquid biofuels',
    BEA_CODES: {
      '325190': 0.28926322841395663,
      '325110': 0.2299455459399661,
      '334413': 0.10393651507238137,
      '325610': 0.10076315018169718,
      '3259A0': 0.08522688457105583,
      '325414': 0.05330812271225734,
      '312140': 0.04295281603078164,
      '311940': 0.042646498169805876,
      '325413': 0.026023795829228893,
      '325520': 0.025933443078869137,
    },
  },
  ['20.15']: {
    label: 'Manufacture of fertilisers and nitrogen compounds',
    BEA_CODES: { '325180': 0.5874772660148295, '325310': 0.4125227339851704 },
  },
  ['20.16']: {
    label: 'Manufacture of plastics in primary forms',
    BEA_CODES: {
      '3259A0': 0.0662925277347339,
      '423A00': 0.40143541818082085,
      '48A000': 0.2180587743236016,
      '325211': 0.1485882369074229,
      '562111': 0.16562504285342072,
    },
  },
  ['20.17']: {
    label: 'Manufacture of synthetic rubber in primary forms',
    BEA_CODES: { '3252A0': 1.0 },
  },
  ['20.20']: {
    label:
      'Manufacture of pesticides, disinfectants and other agrochemical products',
    BEA_CODES: { '325610': 0.7537254384808123, '325320': 0.24627456151918767 },
  },
  ['20.30']: {
    label:
      'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
    BEA_CODES: {
      '325190': 0.6155321503601441,
      '339940': 0.01448079231692677,
      '325910': 0.023925195078031213,
      '325520': 0.05518457382953181,
      '3259A0': 0.18135691776710683,
      '325510': 0.1095203706482593,
    },
  },
  ['20.41']: {
    label:
      'Manufacture of soap and detergents, cleaning and polishing preparations',
    BEA_CODES: {
      '332999': 0.0933458112817865,
      '325620': 0.1976829740544727,
      '339990': 0.16425713179295212,
      '325610': 0.2951077836581903,
      '3259A0': 0.24960629921259841,
    },
  },
  ['20.42']: {
    label: 'Manufacture of perfumes and toilet preparations',
    BEA_CODES: {
      '332999': 0.0933458112817865,
      '325620': 0.1976829740544727,
      '339990': 0.16425713179295212,
      '325610': 0.2951077836581903,
      '3259A0': 0.24960629921259841,
    },
  },
  ['20.59']: {
    label: 'Manufacture of other chemical products n.e.c.',
    BEA_CODES: {
      '325190': 0.2806173277635016,
      '326190': 0.1784747668666276,
      '334413': 0.10082991632425314,
      '325610': 0.09775140136566345,
      '3259A0': 0.08267950521423471,
      '711500': 0.07163533280029417,
      '339990': 0.054408476177852653,
      '325414': 0.051714775589086666,
      '311940': 0.04137182047126933,
      '3219A0': 0.04051667742721664,
    },
  },
  ['20.60']: {
    label: 'Manufacture of man-made fibres',
    BEA_CODES: { '3252A0': 1.0 },
  },
  ['21.10']: {
    label: 'Manufacture of basic pharmaceutical products',
    BEA_CODES: {
      '325414': 0.1442852541543894,
      '325411': 0.0727868109320386,
      '325190': 0.782927934913572,
    },
  },
  ['21.20']: {
    label: 'Manufacture of pharmaceutical preparations',
    BEA_CODES: {
      '325180': 0.10686342982041921,
      '339113': 0.09022584029158195,
      '325414': 0.06839960752932926,
      '339114': 0.012048397175794626,
      '325412': 0.34097444699242485,
      '325413': 0.03339111059585983,
      '331410': 0.07488611847072163,
      '562111': 0.2732110491238686,
    },
  },
  ['22.11']: {
    label:
      'Manufacture, retreading and rebuilding of rubber tyres and manufacture of tubes',
    BEA_CODES: { '326210': 1.0 },
  },
  ['22.12']: {
    label: 'Manufacture of other rubber products',
    BEA_CODES: {
      '326190': 0.2666856206415175,
      '3259A0': 0.12354371180587723,
      '711500': 0.1070409757250694,
      '339113': 0.10193298598577175,
      '33291A': 0.08231562201514828,
      '339990': 0.08129977414954687,
      '326210': 0.06335312852392194,
      '3219A0': 0.06054197719772936,
      '332430': 0.05804708039573344,
      '333318': 0.05523912355968426,
    },
  },
  ['22.21']: {
    label: 'Manufacture of plastic plates, sheets, tubes and profiles',
    BEA_CODES: {
      '326130': 0.017186756731043668,
      '326220': 0.02495956616877557,
      '326140': 0.03696603558177148,
      '3252A0': 0.11010845780610788,
      '336612': 0.03200932356578822,
      '326110': 0.1916706307677671,
      '33441A': 0.11776710113214728,
      '326120': 0.07221006564551423,
      '326190': 0.39712206260108457,
    },
  },
  ['22.25']: {
    label: 'Processing and finishing of plastic products',
    BEA_CODES: {
      '326190': 0.24359236218910119,
      '326110': 0.11756964950571319,
      '3259A0': 0.11284562144749588,
      '711500': 0.09777191610546342,
      '339113': 0.09310624540435812,
      '339990': 0.07425973692503414,
      '33441A': 0.07223765450110295,
      '3252A0': 0.06753988725358606,
      '336360': 0.06494590272995716,
      '322220': 0.05613102393818789,
    },
  },
  ['22.22']: {
    label: 'Manufacture of plastic packing goods',
    BEA_CODES: {
      '336612': 0.039525389879291606,
      '326140': 0.04564598079238744,
      '326110': 0.23667655437751475,
      '326160': 0.07245440394725249,
      '326150': 0.053898792916091516,
      '326190': 0.49036976122646775,
      '314900': 0.06142911686099445,
    },
  },
  ['22.23']: {
    label: 'Manufacture of doors and windows of plastic',
    BEA_CODES: {
      '326190': 0.2887116687474279,
      '3259A0': 0.13374741057626133,
      '711500': 0.11588168365282528,
      '339990': 0.0880144696479767,
      '322220': 0.06652787240149814,
      '3219A0': 0.06554224867459546,
      '332430': 0.06284129382999547,
      '333318': 0.05980142275649561,
      '321910': 0.059780672783297656,
      '326290': 0.059151256929626465,
    },
  },
  ['22.24']: {
    label: 'Manufacture of builders’ ware of plastic',
    BEA_CODES: {
      '326190': 0.2887116687474279,
      '3259A0': 0.13374741057626133,
      '711500': 0.11588168365282528,
      '339990': 0.0880144696479767,
      '322220': 0.06652787240149814,
      '3219A0': 0.06554224867459546,
      '332430': 0.06284129382999547,
      '333318': 0.05980142275649561,
      '321910': 0.059780672783297656,
      '326290': 0.059151256929626465,
    },
  },
  ['23.66']: {
    label: 'Manufacture of other articles of concrete, cement and plaster',
    BEA_CODES: {
      '326190': 0.2887116687474279,
      '3259A0': 0.13374741057626133,
      '711500': 0.11588168365282528,
      '339990': 0.0880144696479767,
      '322220': 0.06652787240149814,
      '3219A0': 0.06554224867459546,
      '332430': 0.06284129382999547,
      '333318': 0.05980142275649561,
      '321910': 0.059780672783297656,
      '326290': 0.059151256929626465,
    },
  },
  ['22.26']: {
    label: 'Manufacture of other plastic products',
    BEA_CODES: {
      '326190': 0.27022923990237396,
      '3259A0': 0.12518531466267876,
      '711500': 0.10846329636750891,
      '339113': 0.10328743356186111,
      '339990': 0.08238005528688976,
      '336360': 0.07204775129963034,
      '322220': 0.06226896359740268,
      '3219A0': 0.06134643645568309,
      '332430': 0.058818388393637476,
      '333318': 0.055973120472333895,
    },
  },
  ['23.11']: {
    label: 'Manufacture of flat glass',
    BEA_CODES: { '327200': 1.0 },
  },
  ['23.12']: {
    label: 'Shaping and processing of flat glass',
    BEA_CODES: { '327200': 1.0 },
  },
  ['29.32']: {
    label: 'Manufacture of other parts and accessories for motor vehicles',
    BEA_CODES: {
      '811100': 0.15925359494829824,
      '230301': 0.15103240639932516,
      '2334A0': 0.14485751368626434,
      '233411': 0.11401219719193689,
      '233240': 0.09736064699639434,
      '2332D0': 0.08033189941174353,
      '233262': 0.06992296600594257,
      '2332A0': 0.06934345365358535,
      '230302': 0.06272363947473548,
      '811300': 0.05116168223177408,
    },
  },
  ['23.13']: {
    label: 'Manufacture of hollow glass',
    BEA_CODES: { '327200': 1.0 },
  },
  ['23.14']: {
    label: 'Manufacture of glass fibres',
    BEA_CODES: { '327993': 0.17482366489132, '327200': 0.82517633510868 },
  },
  ['23.15']: {
    label:
      'Manufacture and processing of other glass, including technical glassware',
    BEA_CODES: { '327200': 1.0 },
  },
  ['23.20']: {
    label: 'Manufacture of refractory products',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23.31']: {
    label: 'Manufacture of ceramic tiles and flags',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23.32']: {
    label:
      'Manufacture of bricks, tiles and construction products, in baked clay',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23.41']: {
    label: 'Manufacture of ceramic household and ornamental articles',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23.42']: {
    label: 'Manufacture of ceramic sanitary fixtures',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23.43']: {
    label: 'Manufacture of ceramic insulators and insulating fittings',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23.44']: {
    label: 'Manufacture of other technical ceramic products',
    BEA_CODES: {
      '811300': 0.27915896812217883,
      '334511': 0.21345276796781815,
      '33329A': 0.09788806511214539,
      '333318': 0.08088500128633908,
      '332999': 0.06765208036111046,
      '334515': 0.058053652033585146,
      '335930': 0.0553079027995416,
      '33451A': 0.05421802277989569,
      '335120': 0.047482283602684944,
      '335999': 0.04590125593470075,
    },
  },
  ['23.45']: {
    label: 'Manufacture of other ceramic products',
    BEA_CODES: { '327100': 1.0 },
  },
  ['23.51']: { label: 'Manufacture of cement', BEA_CODES: { '327310': 1.0 } },
  ['23.52']: {
    label: 'Manufacture of lime and plaster',
    BEA_CODES: { '327400': 1.0 },
  },
  ['23.61']: {
    label: 'Manufacture of concrete products for construction purposes',
    BEA_CODES: { '327390': 0.6116363636363636, '327330': 0.38836363636363636 },
  },
  ['23.62']: {
    label: 'Manufacture of plaster products for construction purposes',
    BEA_CODES: { '327400': 0.5992702949224689, '327999': 0.40072970507753114 },
  },
  ['23.63']: {
    label: 'Manufacture of ready-mixed concrete',
    BEA_CODES: { '327320': 1.0 },
  },
  ['23.64']: { label: 'Manufacture of mortars', BEA_CODES: { '327999': 1.0 } },
  ['23.65']: {
    label: 'Manufacture of fibre cement',
    BEA_CODES: { '327999': 0.356473133790119, '327100': 0.643526866209881 },
  },
  ['23.70']: {
    label: 'Cutting, shaping and finishing of stone',
    BEA_CODES: { '4B0000': 0.9812224482875551, '327991': 0.018777551712444898 },
  },
  ['23.91']: {
    label: 'Manufacture of abrasive products',
    BEA_CODES: { '327910': 1.0 },
  },
  ['24.10']: {
    label: 'Manufacture of basic iron and steel and of ferro-alloys',
    BEA_CODES: {
      '331200': 0.05024802110817942,
      '33211A': 0.08015303430079156,
      '331110': 0.6695567282321899,
      '332310': 0.20004221635883904,
    },
  },
  ['24.20']: {
    label:
      'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
    BEA_CODES: {
      '332996': 0.042852609868316675,
      '331200': 0.05035697287006188,
      '33291A': 0.1362737320852504,
      '331510': 0.09950817071235919,
      '331110': 0.6710085144640119,
    },
  },
  ['24.31']: { label: 'Cold drawing of bars', BEA_CODES: { '331200': 1.0 } },
  ['24.32']: {
    label: 'Cold rolling of narrow strip',
    BEA_CODES: { '331200': 1.0 },
  },
  ['24.33']: {
    label: 'Cold forming or folding',
    BEA_CODES: {
      '331200': 0.10663769836381351,
      '332320': 0.39435342076086594,
      '332310': 0.4245349579474315,
      '337215': 0.07447392292788908,
    },
  },
  ['24.34']: {
    label: 'Cold drawing of wire',
    BEA_CODES: { '331200': 0.06980784880097946, '331110': 0.9301921511990205 },
  },
  ['24.41']: {
    label: 'Precious metals production',
    BEA_CODES: {
      '332999': 0.19855848434925866,
      '331490': 0.17375068643602415,
      '322220': 0.2640993959362987,
      '331410': 0.3635914332784185,
    },
  },
  ['24.42']: {
    label: 'Aluminium production',
    BEA_CODES: {
      '332999': 0.13824845147969717,
      '332996': 0.07745469144299151,
      '33291A': 0.24631031582167165,
      '331313': 0.35410453467920777,
      '322220': 0.18388200657643192,
    },
  },
  ['24.43']: {
    label: 'Lead, zinc and tin production',
    BEA_CODES: {
      '332999': 0.13553429355923943,
      '331490': 0.11860070658132714,
      '332996': 0.07593406490427416,
      '33291A': 0.24147463710395367,
      '331410': 0.24818434838020448,
      '322220': 0.18027194947100111,
    },
  },
  ['24.44']: {
    label: 'Copper production',
    BEA_CODES: {
      '332999': 0.15774319151024682,
      '332913': 0.03528308266166414,
      '33291A': 0.28104311407287835,
      '331410': 0.2888522909463719,
      '331420': 0.23707832080883876,
    },
  },
  ['24.45']: {
    label: 'Other non-ferrous metal production',
    BEA_CODES: {
      '332999': 0.06747218399384199,
      '331490': 0.05904224300809405,
      '332996': 0.03780177742529915,
      '33291A': 0.12021179818525343,
      '331110': 0.5919199458841641,
      '331410': 0.12355205150334725,
    },
  },
  ['24.46']: {
    label: 'Processing of nuclear fuel',
    BEA_CODES: {
      '325180': 0.13426149604956517,
      '331410': 0.09408571590973683,
      '325412': 0.42839481612004776,
      '562111': 0.3432579719206503,
    },
  },
  ['24.51']: {
    label: 'Casting of iron',
    BEA_CODES: {
      '331200': 0.06134558269284044,
      '331110': 0.8174321442606898,
      '331510': 0.12122227304646983,
    },
  },
  ['24.52']: { label: 'Casting of steel', BEA_CODES: { '331510': 1.0 } },
  ['24.53']: { label: 'Casting of light metals', BEA_CODES: { '331520': 1.0 } },
  ['24.54']: {
    label: 'Casting of other non-ferrous metals',
    BEA_CODES: { '331520': 1.0 },
  },
  ['25.11']: {
    label: 'Manufacture of metal structures and parts of structures',
    BEA_CODES: {
      '332320': 0.44142608216018353,
      '332310': 0.4752102894535608,
      '337215': 0.08336362838625566,
    },
  },
  ['25.12']: {
    label: 'Manufacture of doors and windows of metal',
    BEA_CODES: { '332320': 1.0 },
  },
  ['25.21']: {
    label:
      'Manufacture of central heating radiators, steam generators and boilers',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['25.22']: {
    label: 'Manufacture of other tanks, reservoirs and containers of metal',
    BEA_CODES: {
      '332430': 0.14563246856291023,
      '332310': 0.303815729364526,
      '332420': 0.07224319363964961,
      '811300': 0.4783086084329142,
    },
  },
  ['25.30']: {
    label: 'Manufacture of weapons and ammunition',
    BEA_CODES: {
      '811400': 0.23933682762722575,
      '336992': 0.04306903089104494,
      '33299A': 0.10128991487305304,
      '336414': 0.13324411731905877,
      '811300': 0.4437009776588231,
      '33641A': 0.039359131630794394,
    },
  },
  ['25.40']: {
    label: 'Forging and shaping metal and powder metallurgy',
    BEA_CODES: {
      '33211A': 0.43527726035248604,
      '332114': 0.23550651955867602,
      '332119': 0.32921622008883794,
    },
  },
  ['25.51']: {
    label: 'Coating of metals',
    BEA_CODES: { '339910': 0.2935578141567745, '332800': 0.7064421858432255 },
  },
  ['25.52']: {
    label: 'Heat treatment of metals',
    BEA_CODES: { '339910': 0.2935578141567745, '332800': 0.7064421858432255 },
  },
  ['25.53']: {
    label: 'Machining of metals',
    BEA_CODES: {
      '339910': 0.06839805971498297,
      '115000': 0.16695489741194902,
      '332710': 0.24043264740441686,
      '332800': 0.16459883703413575,
      '811300': 0.35961555843451537,
    },
  },
  ['25.61']: {
    label: 'Manufacture of cutlery',
    BEA_CODES: {
      '323110': 0.29808997960815486,
      '339113': 0.15097062344163248,
      '339990': 0.12041124342942576,
      '33399A': 0.09661287193826618,
      '332430': 0.08597220842263636,
      '332999': 0.06842859778859665,
      '339910': 0.05370483395549752,
      '337900': 0.04525002483925454,
      '332200': 0.0430310514338164,
      '339950': 0.03752856514271926,
    },
  },
  ['25.62']: {
    label: 'Manufacture of locks and hinges',
    BEA_CODES: {
      '323110': 0.29808997960815486,
      '339113': 0.15097062344163248,
      '339990': 0.12041124342942576,
      '33399A': 0.09661287193826618,
      '332430': 0.08597220842263636,
      '332999': 0.06842859778859665,
      '339910': 0.05370483395549752,
      '337900': 0.04525002483925454,
      '332200': 0.0430310514338164,
      '339950': 0.03752856514271926,
    },
  },
  ['25.63']: {
    label: 'Manufacture of tools',
    BEA_CODES: {
      '811300': 0.27377025867803095,
      '333120': 0.17757909657649557,
      '333130': 0.14318808402104655,
      '33329A': 0.09599849536453005,
      '333318': 0.0793236480070461,
      '332999': 0.06634616707875941,
      '333242': 0.045671191276784116,
      '332200': 0.04172152316817512,
      '33351B': 0.03824434729555536,
      '333514': 0.03815718853357677,
    },
  },
  ['25.91']: {
    label: 'Manufacture of steel drums and similar containers',
    BEA_CODES: { '332430': 0.2334074064559222, '811300': 0.7665925935440778 },
  },
  ['25.92']: {
    label: 'Manufacture of light metal packaging',
    BEA_CODES: {
      '332430': 0.28679429915244875,
      '332119': 0.18131599299231363,
      '322220': 0.30361905964424946,
      '332999': 0.2282706482109882,
    },
  },
  ['25.93']: {
    label: 'Manufacture of wire products, chain and springs',
    BEA_CODES: {
      '332720': 0.17388613861386137,
      '331490': 0.07991513437057991,
      '332600': 0.03682562133764397,
      '339990': 0.1607016569003839,
      '331200': 0.060125782986461915,
      '332320': 0.22234921196201252,
      '33399A': 0.1289401899373611,
      '331420': 0.1372562638916953,
    },
  },
  ['25.94']: {
    label: 'Manufacture of fasteners and screw machine products',
    BEA_CODES: {
      '331200': 0.1219128096792779,
      '332320': 0.4508418155047692,
      '332600': 0.07466871519108892,
      '332720': 0.35257665962486395,
    },
  },
  ['25.99']: {
    label: 'Manufacture of other fabricated metal products n.e.c.',
    BEA_CODES: {
      '326190': 0.19253770240755733,
      '323110': 0.1453067738639692,
      '811300': 0.1376405984413955,
      '334511': 0.10524385771910524,
      '3259A0': 0.0891942443720263,
      '711500': 0.07727984538495779,
      '339113': 0.07359205522229371,
      '331410': 0.061080321868664855,
      '33291A': 0.059429003697015405,
      '339990': 0.05869559702301467,
    },
  },
  ['26.11']: {
    label: 'Manufacture of electronic components',
    BEA_CODES: {
      '325190': 0.2638523435160077,
      '811300': 0.11996486283843137,
      '811200': 0.09508944096132702,
      '334413': 0.0948060119120606,
      '325610': 0.09191141736636119,
      '334511': 0.0917284950721538,
      '3259A0': 0.07773996490304114,
      '333618': 0.05763861389123963,
      '334220': 0.05750393483946056,
      '33441A': 0.04976491469991698,
    },
  },
  ['27.33']: {
    label: 'Manufacture of wiring devices',
    BEA_CODES: {
      '325190': 0.23460117567787356,
      '326190': 0.14920814216801578,
      '811300': 0.10666533215848953,
      '811200': 0.08454764641014322,
      '334413': 0.08429563883919236,
      '325610': 0.0817219444975666,
      '334511': 0.08155930131347776,
      '3259A0': 0.06912156595002386,
      '339113': 0.05703056440759454,
      '333618': 0.0512486885776228,
    },
  },
  ['26.12']: {
    label: 'Manufacture of loaded electronic boards',
    BEA_CODES: {
      '334413': 0.4790460519633534,
      '33441A': 0.2514575334674061,
      '335999': 0.09967091230422329,
      '334418': 0.16982550226501716,
    },
  },
  ['26.20']: {
    label: 'Manufacture of computers and peripheral equipment',
    BEA_CODES: {
      '230301': 0.17112299984653428,
      '2334A0': 0.1641267121624483,
      '233411': 0.12917829800708272,
      '233240': 0.11031172963616973,
      '2332D0': 0.09101778842324675,
      '233262': 0.0792242405875988,
      '2332A0': 0.07856764049396531,
      '230302': 0.07106724711669027,
      '811300': 0.05796729820717093,
      '233230': 0.047416045519092884,
    },
  },
  ['26.30']: {
    label: 'Manufacture of communication equipment',
    BEA_CODES: {
      '811300': 0.21095868136684823,
      '811200': 0.1672151545251132,
      '334511': 0.16130491800960767,
      '334220': 0.10112089473628398,
      '33441A': 0.08751179749663307,
      '33329A': 0.07397339686601932,
      '333318': 0.061124288173517755,
      '332999': 0.05112425282521324,
      '334515': 0.0438707807380019,
      '335930': 0.04179583526276162,
    },
  },
  ['26.51']: {
    label:
      'Manufacture of instruments and appliances for measuring, testing and navigation',
    BEA_CODES: {
      '811300': 0.17269417967370987,
      '811200': 0.136885022946797,
      '334511': 0.13204680799347188,
      '339112': 0.10687188568849072,
      '339113': 0.09233409147467171,
      '333415': 0.08510570573698861,
      '334220': 0.0827791956757007,
      '33441A': 0.07163856913843893,
      '33329A': 0.0605558159857863,
      '33399A': 0.05908872568594429,
    },
  },
  ['26.40']: {
    label: 'Manufacture of consumer electronics',
    BEA_CODES: {
      '230301': 0.17112299984653428,
      '2334A0': 0.1641267121624483,
      '233411': 0.12917829800708272,
      '233240': 0.11031172963616973,
      '2332D0': 0.09101778842324675,
      '233262': 0.0792242405875988,
      '2332A0': 0.07856764049396531,
      '230302': 0.07106724711669027,
      '811300': 0.05796729820717093,
      '233230': 0.047416045519092884,
    },
  },
  ['26.52']: {
    label: 'Manufacture of watches and clocks',
    BEA_CODES: {
      '339910': 0.12721628224956852,
      '334514': 0.0740143007643512,
      '33451A': 0.12990608118709793,
      '811300': 0.6688633357989824,
    },
  },
  ['26.60']: {
    label:
      'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
    BEA_CODES: {
      '325412': 0.22040704422287818,
      '326190': 0.15258766438500132,
      '811300': 0.10908127176187822,
      '336390': 0.09771620226095062,
      '811200': 0.08646262668725953,
      '334511': 0.08340659641939994,
      '333120': 0.07075477733200515,
      '339112': 0.0675050035184559,
      '339113': 0.058322290560485456,
      '333415': 0.05375652285168566,
    },
  },
  ['26.70']: {
    label:
      'Manufacture of optical instruments, magnetic and optical media and photographic equipment',
    BEA_CODES: {
      '811300': 0.16914517632987844,
      '811200': 0.1340719263787684,
      '334511': 0.12933314060759624,
      '339112': 0.10467558306620979,
      '339113': 0.0904365521365632,
      '333415': 0.08335671549996741,
      '334220': 0.08107801707890135,
      '334510': 0.07842520399169012,
      '33441A': 0.07016633931633379,
      '33329A': 0.05931134559409126,
    },
  },
  ['27.11']: {
    label: 'Manufacture of electric motors, generators and transformers',
    BEA_CODES: {
      '811300': 0.19616866297645189,
      '334413': 0.15502846549298552,
      '334511': 0.14999605558988655,
      '333618': 0.09425167966130665,
      '33441A': 0.08137646764926305,
      '331420': 0.07144970219703643,
      '33329A': 0.06878722537044585,
      '33399A': 0.06712071209750582,
      '333611': 0.058982079230051145,
      '333318': 0.05683894973506712,
    },
  },
  ['29.10']: {
    label: 'Manufacture of motor vehicles',
    BEA_CODES: {
      '336112': 0.34142758229752446,
      '811300': 0.11705931619941666,
      '336111': 0.1087878829485296,
      '334511': 0.08950683270991924,
      '333120': 0.07592967810649495,
      '336120': 0.06769943529864052,
      '333618': 0.05624260778656289,
      '336310': 0.053739798009913166,
      '33441A': 0.048559609436142064,
      '33329A': 0.041047257206856445,
    },
  },
  ['27.12']: {
    label: 'Manufacture of electricity distribution and control apparatus',
    BEA_CODES: {
      '33441A': 0.19570905698859278,
      '335313': 0.08463308010340002,
      '335314': 0.07683064688258405,
      '811300': 0.471782385631507,
      '335999': 0.077573735760757,
      '335930': 0.09347109463315915,
    },
  },
  ['27.20']: {
    label: 'Manufacture of batteries and accumulators',
    BEA_CODES: {
      '336612': 0.05702735685955456,
      '335911': 0.05317129394216753,
      '335912': 0.037340248821993965,
      '326290': 0.14495406623953355,
      '326190': 0.7075070341367504,
    },
  },
  ['27.31']: {
    label: 'Manufacture of fibre optic cables',
    BEA_CODES: {
      '811200': 0.26608729890876365,
      '334510': 0.15564742940713241,
      '33441A': 0.13925638429519632,
      '331420': 0.12226909663629204,
      '335920': 0.08321520980987737,
      '333242': 0.05600179997750028,
      '335999': 0.0551974350320621,
      '331200': 0.05356058049274384,
      '339115': 0.04030824614692316,
      '333314': 0.02845651929350883,
    },
  },
  ['27.32']: {
    label: 'Manufacture of other electronic and electric wires and cables',
    BEA_CODES: {
      '33441A': 0.3070711831611327,
      '331200': 0.11810525532416308,
      '335920': 0.18349602470758963,
      '335999': 0.12171464718504645,
      '331420': 0.26961288962206814,
    },
  },
  ['27.40']: {
    label: 'Manufacture of lighting equipment',
    BEA_CODES: {
      '811300': 0.2682379803046443,
      '334511': 0.2051022747191996,
      '33329A': 0.09405858267150286,
      '336320': 0.08322658648292725,
      '333318': 0.07772069630408694,
      '332999': 0.06500546094413656,
      '334515': 0.05578253306905061,
      '335930': 0.053144200387434885,
      '33451A': 0.05209695760239832,
      '335120': 0.0456247275146187,
    },
  },
  ['27.51']: {
    label: 'Manufacture of electric domestic appliances',
    BEA_CODES: {
      '335222': 0.059230860173284466,
      '335221': 0.0744481413298654,
      '335210': 0.053556047222550626,
      '335224': 0.06100423922038879,
      '335228': 0.0744988093026398,
      '333413': 0.10018747149926531,
      '333415': 0.4967319157560506,
      '333414': 0.080342515495955,
    },
  },
  ['27.52']: {
    label: 'Manufacture of non-electric domestic appliances',
    BEA_CODES: {
      '335222': 0.0699916177703269,
      '335221': 0.08797349618808127,
      '335224': 0.07208717518860017,
      '335228': 0.0880333692571748,
      '333415': 0.5869756117031892,
      '333414': 0.09493872989262762,
    },
  },
  ['27.90']: {
    label: 'Manufacture of other electrical equipment',
    BEA_CODES: {
      '811300': 0.18220953485887004,
      '334413': 0.14399682476681883,
      '334511': 0.13932251515105257,
      '333120': 0.11818889584319232,
      '333130': 0.0952997389591952,
      '33441A': 0.07558581525638482,
      '331420': 0.06636542659563101,
      '33329A': 0.06389240844489902,
      '33399A': 0.06234448226907048,
      '333318': 0.05279435785488574,
    },
  },
  ['29.31']: {
    label:
      'Manufacture of electrical and electronic equipment for motor vehicles',
    BEA_CODES: {
      '811300': 0.20379242403029568,
      '334413': 0.16105338282447831,
      '334511': 0.15582539687959923,
      '33441A': 0.08453902549795628,
      '331420': 0.07422647320955994,
      '33329A': 0.07146052375472517,
      '33399A': 0.0697292442811434,
      '336320': 0.06323097043848835,
      '333318': 0.05904789873210243,
      '334418': 0.05709466035165121,
    },
  },
  ['32.99']: {
    label: 'Other manufacturing n.e.c.',
    BEA_CODES: {
      '326190': 0.17027547411489713,
      '323110': 0.12850563553220393,
      '811300': 0.12172586389057725,
      '811200': 0.09648528805870907,
      '334413': 0.09619769846741263,
      '334511': 0.0930750058129811,
      '3259A0': 0.07888113371488245,
      '339112': 0.07533011613724347,
      '324190': 0.07117944366711403,
      '711500': 0.06834434060397894,
    },
  },
  ['28.11']: {
    label:
      'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
    BEA_CODES: {
      '811300': 0.15844233766164809,
      '336390': 0.1419343876686835,
      '336412': 0.10570610114504324,
      '336350': 0.09560699503805749,
      '333111': 0.09105390172221087,
      '811400': 0.08546541109255655,
      '339113': 0.08471408432381919,
      '333130': 0.08286895321331574,
      '333415': 0.07808223178563559,
      '333618': 0.07612559634902978,
    },
  },
  ['30.91']: {
    label: 'Manufacture of motorcycles',
    BEA_CODES: {
      '336112': 0.31183034554662437,
      '811300': 0.10691182819584498,
      '336111': 0.0993574183516446,
      '336390': 0.09577279086806743,
      '336412': 0.07132710039285797,
      '333120': 0.06934758346664469,
      '336350': 0.06451254619619252,
      '336120': 0.06183079340113253,
      '333111': 0.06144026426998819,
      '811400': 0.05766932931100271,
    },
  },
  ['28.12']: {
    label: 'Manufacture of fluid power equipment',
    BEA_CODES: {
      '811300': 0.16823020025257082,
      '336390': 0.1507024625653978,
      '336412': 0.11223615370737867,
      '333111': 0.0966788066029226,
      '339113': 0.08994734349630164,
      '333130': 0.08798822839617536,
      '333415': 0.08290580461843768,
      '336310': 0.0772314180046906,
      '33291A': 0.07263665884899874,
      '3363A0': 0.06144292350712611,
    },
  },
  ['28.13']: {
    label: 'Manufacture of other pumps and compressors',
    BEA_CODES: {
      '811300': 0.17055765608969137,
      '336390': 0.15278742308758514,
      '336412': 0.113788934900589,
      '333111': 0.09801635272969413,
      '339113': 0.09119176019044843,
      '333130': 0.08920554083752726,
      '333415': 0.0840528019982224,
      '336310': 0.07829991054868238,
      '3363A0': 0.06229298307283852,
      '33329A': 0.059806636544721375,
    },
  },
  ['28.14']: {
    label: 'Manufacture of other taps and valves',
    BEA_CODES: {
      '811300': 0.6729587406831072,
      '332913': 0.03647824272972272,
      '33291A': 0.29056301658717004,
    },
  },
  ['28.15']: {
    label: 'Manufacture of bearings, gears, gearing and driving elements',
    BEA_CODES: {
      '811300': 0.6941068375571348,
      '333612': 0.052651167118316834,
      '332991': 0.07895930496272432,
      '333613': 0.050220397529686794,
      '33399B': 0.12406229283213734,
    },
  },
  ['28.21']: {
    label:
      'Manufacture of ovens, furnaces and permanent household heating equipment',
    BEA_CODES: {
      '230301': 0.17112299984653428,
      '2334A0': 0.1641267121624483,
      '233411': 0.12917829800708272,
      '233240': 0.11031172963616973,
      '2332D0': 0.09101778842324675,
      '233262': 0.0792242405875988,
      '2332A0': 0.07856764049396531,
      '230302': 0.07106724711669027,
      '811300': 0.05796729820717093,
      '233230': 0.047416045519092884,
    },
  },
  ['28.22']: {
    label: 'Manufacture of lifting and handling equipment',
    BEA_CODES: {
      '230301': 0.17112299984653428,
      '2334A0': 0.1641267121624483,
      '233411': 0.12917829800708272,
      '233240': 0.11031172963616973,
      '2332D0': 0.09101778842324675,
      '233262': 0.0792242405875988,
      '2332A0': 0.07856764049396531,
      '230302': 0.07106724711669027,
      '811300': 0.05796729820717093,
      '233230': 0.047416045519092884,
    },
  },
  ['28.23']: {
    label:
      'Manufacture of office machinery and equipment, except computers and peripheral equipment',
    BEA_CODES: {
      '230301': 0.17112299984653428,
      '2334A0': 0.1641267121624483,
      '233411': 0.12917829800708272,
      '233240': 0.11031172963616973,
      '2332D0': 0.09101778842324675,
      '233262': 0.0792242405875988,
      '2332A0': 0.07856764049396531,
      '230302': 0.07106724711669027,
      '811300': 0.05796729820717093,
      '233230': 0.047416045519092884,
    },
  },
  ['28.24']: {
    label: 'Manufacture of power-driven hand tools',
    BEA_CODES: {
      '333130': 0.13919038233431733,
      '811400': 0.14355150855726095,
      '333120': 0.17262122414761832,
      '333991': 0.01652590366281092,
      '333111': 0.15293818616390342,
      '811300': 0.2661268026434311,
      '333112': 0.031937000008918456,
      '333318': 0.07710899248173946,
    },
  },
  ['28.25']: {
    label: 'Manufacture of non-domestic air conditioning equipment',
    BEA_CODES: {
      '230301': 0.1703544122894127,
      '2334A0': 0.1633895479655112,
      '233411': 0.12859810228478105,
      '233240': 0.10981627184920387,
      '2332D0': 0.09060898808827383,
      '233262': 0.07886841018728649,
      '2332A0': 0.07821475916924436,
      '230302': 0.07074805330930167,
      '811300': 0.0577069419478607,
      '336390': 0.051694512909124135,
    },
  },
  ['28.29']: {
    label: 'Manufacture of other general-purpose machinery n.e.c.',
    BEA_CODES: {
      '811300': 0.15016896524826442,
      '336390': 0.13452300972021972,
      '811200': 0.11903054458896027,
      '334511': 0.1148233979754365,
      '333120': 0.09740601229432916,
      '339112': 0.09293214466344085,
      '339113': 0.08029057493476659,
      '333415': 0.07400501737460967,
      '334220': 0.07198196362025973,
      '33291A': 0.0648383695797131,
    },
  },
  ['28.30']: {
    label: 'Manufacture of agricultural and forestry machinery',
    BEA_CODES: {
      '811400': 0.14675687011068764,
      '333120': 0.17647568336402925,
      '332200': 0.04146228049380915,
      '333111': 0.15635314283630264,
      '811300': 0.27206914786921715,
      '333112': 0.03265012126406389,
      '33329A': 0.09540199493061507,
      '333318': 0.07883075913127519,
    },
  },
  ['28.41']: {
    label:
      'Manufacture of metal forming machinery and machine tools for metal work',
    BEA_CODES: {
      '333130': 0.16037939432552692,
      '332999': 0.07431175690812122,
      '333514': 0.04273838027807179,
      '333517': 0.03861765642822645,
      '333120': 0.19889942762015353,
      '332200': 0.04673065263633841,
      '33351B': 0.04283600341167162,
      '811300': 0.3066394006967209,
      '333318': 0.08884732769516919,
    },
  },
  ['28.42']: {
    label: 'Manufacture of other machine tools',
    BEA_CODES: {
      '811300': 0.2189489131431716,
      '334511': 0.16741447308080346,
      '333120': 0.1420196276254242,
      '333130': 0.11451527102632303,
      '33329A': 0.0767751994863799,
      '33399A': 0.07491516096487205,
      '333318': 0.06343942034302485,
      '332999': 0.05306062551591305,
      '334515': 0.045532422269100245,
      '335930': 0.043378886544987616,
    },
  },
  ['28.91']: {
    label: 'Manufacture of machinery for metallurgy',
    BEA_CODES: {
      '332310': 0.23371434912884254,
      '332420': 0.055574051467958914,
      '333517': 0.046338425874548395,
      '331510': 0.11600636259386675,
      '33351B': 0.051400140568934265,
      '811300': 0.3679453507441522,
      '33329A': 0.12902131962169694,
    },
  },
  ['28.92']: {
    label: 'Manufacture of machinery for mining, quarrying and construction',
    BEA_CODES: {
      '336112': 0.3517504774029281,
      '811300': 0.12059854707849615,
      '336111': 0.1120770311094945,
      '333120': 0.07822537459711235,
      '336120': 0.06974629444393926,
      '333130': 0.06307578834631668,
      '333920': 0.05903832358319946,
      '333618': 0.05794307538419569,
      '336310': 0.05536459438432704,
      '336500': 0.0321804936699908,
    },
  },
  ['28.93']: {
    label: 'Manufacture of machinery for food, beverage and tobacco processing',
    BEA_CODES: {
      '333111': 0.259440527701292,
      '811300': 0.45145087597204153,
      '33329A': 0.1583028230808799,
      '333318': 0.13080577324578657,
    },
  },
  ['28.94']: {
    label:
      'Manufacture of machinery for textile, apparel and leather production',
    BEA_CODES: {
      '332999': 0.07669993530116775,
      '339990': 0.13496600659715963,
      '333242': 0.05279848965349002,
      '333511': 0.034475992490692914,
      '333111': 0.18188326633645513,
      '811300': 0.3164939596745967,
      '33329A': 0.11097971002195518,
      '333318': 0.09170263992448267,
    },
  },
  ['28.95']: {
    label: 'Manufacture of machinery for paper and paperboard production',
    BEA_CODES: { '811300': 0.7403823489275125, '33329A': 0.2596176510724875 },
  },
  ['28.96']: {
    label: 'Manufacture of plastics and rubber machinery',
    BEA_CODES: {
      '332999': 0.1122738105403706,
      '333242': 0.07728673565234942,
      '333511': 0.05046615794253953,
      '811300': 0.4632856954331271,
      '33329A': 0.16245274377226962,
      '333318': 0.13423485665934373,
    },
  },
  ['28.97']: {
    label: 'Manufacture of additive manufacturing machinery',
    BEA_CODES: {
      '230301': 0.1514135043942652,
      '2334A0': 0.14522303065930306,
      '233411': 0.11429988260187253,
      '48A000': 0.1093297993574902,
      '233240': 0.09760631577861772,
      '336411': 0.09022725046022473,
      '2332D0': 0.08053459978926779,
      '326190': 0.07174778825709331,
      '233262': 0.07009940166488765,
      '2332A0': 0.06951842703697783,
    },
  },
  ['28.99']: {
    label: 'Manufacture of other special-purpose machinery n.e.c.',
    BEA_CODES: {
      '230301': 0.1514135043942652,
      '2334A0': 0.14522303065930306,
      '233411': 0.11429988260187253,
      '48A000': 0.1093297993574902,
      '233240': 0.09760631577861772,
      '336411': 0.09022725046022473,
      '2332D0': 0.08053459978926779,
      '326190': 0.07174778825709331,
      '233262': 0.07009940166488765,
      '2332A0': 0.06951842703697783,
    },
  },
  ['29.20']: {
    label:
      'Manufacture of bodies and coachwork for motor vehicles; manufacture of trailers and semi-trailers',
    BEA_CODES: {
      '336612': 0.015944893084622384,
      '811300': 0.14141643918713984,
      '332420': 0.02135937973915681,
      '336214': 0.023662609948437975,
      '332430': 0.04305760919017288,
      '336212': 0.018707821504397936,
      '336211': 0.01656809220503488,
      '326190': 0.19781951395207764,
      '333111': 0.0812694305429178,
      '811100': 0.44019421064604186,
    },
  },
  ['30.11']: {
    label: 'Building of civilian ships and floating structures',
    BEA_CODES: {
      '811100': 0.15053847961556852,
      '230301': 0.1427671936662804,
      '2334A0': 0.13693022049707057,
      '233411': 0.10777290665542977,
      '48A000': 0.10308663484680275,
      '233240': 0.09203260860757032,
      '2332D0': 0.07593575520854436,
      '233262': 0.06609644822249054,
      '2332A0': 0.06554864954660745,
      '230302': 0.05929110313363532,
    },
  },
  ['30.13']: {
    label: 'Building of military ships and vessels',
    BEA_CODES: {
      '811100': 0.15053847961556852,
      '230301': 0.1427671936662804,
      '2334A0': 0.13693022049707057,
      '233411': 0.10777290665542977,
      '48A000': 0.10308663484680275,
      '233240': 0.09203260860757032,
      '2332D0': 0.07593575520854436,
      '233262': 0.06609644822249054,
      '2332A0': 0.06554864954660745,
      '230302': 0.05929110313363532,
    },
  },
  ['30.12']: {
    label: 'Building of pleasure and sporting boats',
    BEA_CODES: {
      '336612': 0.019920719739245505,
      '332310': 0.11222390308743031,
      '811400': 0.09530209687112369,
      '336999': 0.0203973486407195,
      '326290': 0.05063515981870339,
      '326190': 0.24714540734008508,
      '441000': 0.4543753645026925,
    },
  },
  ['30.20']: {
    label: 'Manufacture of railway locomotives and rolling stock',
    BEA_CODES: {
      '811100': 0.30446746997430113,
      '48A000': 0.20849504379294068,
      '811300': 0.09781297529763466,
      '334511': 0.07479054124927886,
      '332310': 0.062129595636439924,
      '811400': 0.05276131536161955,
      '339113': 0.05229749042848901,
      '333130': 0.051158415062673726,
      '333415': 0.04820337494099754,
      '333920': 0.047883778255624905,
    },
  },
  ['30.31']: {
    label: 'Manufacture of civilian air and spacecraft and related machinery',
    BEA_CODES: {
      '811100': 0.2757427594314417,
      '48A000': 0.18882476577250543,
      '336411': 0.15583253179437853,
      '811300': 0.08858489781862658,
      '336412': 0.05910013893341883,
      '336413': 0.0506498402859416,
      '811400': 0.04778359635685702,
      '339113': 0.04736353057128947,
      '333415': 0.04365567073967202,
      '334220': 0.042462268295868806,
    },
  },
  ['30.32']: {
    label: 'Manufacture of military air and spacecraft and related machinery',
    BEA_CODES: {
      '811100': 0.2757427594314417,
      '48A000': 0.18882476577250543,
      '336411': 0.15583253179437853,
      '811300': 0.08858489781862658,
      '336412': 0.05910013893341883,
      '336413': 0.0506498402859416,
      '811400': 0.04778359635685702,
      '339113': 0.04736353057128947,
      '333415': 0.04365567073967202,
      '334220': 0.042462268295868806,
    },
  },
  ['30.40']: {
    label: 'Manufacture of military fighting vehicles',
    BEA_CODES: {
      '811400': 0.23933682762722575,
      '336992': 0.04306903089104494,
      '33299A': 0.10128991487305304,
      '336414': 0.13324411731905877,
      '811300': 0.4437009776588231,
      '33641A': 0.039359131630794394,
    },
  },
  ['33.18']: {
    label:
      'Repair and maintenance of military fighting vehicles, ships, boats, air and spacecraft',
    BEA_CODES: {
      '230301': 0.14252165584279006,
      '2334A0': 0.13669472137820793,
      '441000': 0.12416151359537146,
      '233411': 0.10758755367445533,
      '48A000': 0.10290934153941736,
      '233240': 0.09187432654131032,
      '336411': 0.08492860124450312,
      '2332D0': 0.07580515727788402,
      '326190': 0.06753435650516601,
      '233262': 0.06598277240089438,
    },
  },
  ['30.92']: {
    label: 'Manufacture of bicycles and invalid carriages',
    BEA_CODES: {
      '336112': 0.3185719723867322,
      '326190': 0.15278621679197077,
      '336111': 0.101505479462083,
      '333120': 0.0708468459235291,
      '3259A0': 0.07077913046024553,
      '336120': 0.06316754635764013,
      '711500': 0.061324587667733035,
      '339113': 0.058398181565288684,
      '333618': 0.05247765389711642,
      '336310': 0.050142385487661145,
    },
  },
  ['30.99']: {
    label: 'Manufacture of other transport equipment n.e.c.',
    BEA_CODES: {
      '811400': 0.20678580146199205,
      '331200': 0.06116471177687278,
      '336999': 0.04425801975873277,
      '333920': 0.18766942021351765,
      '33299A': 0.0875139711455697,
      '811300': 0.383355387402202,
      '337127': 0.029252688241113066,
    },
  },
  ['31.00']: {
    label: 'Manufacture of furniture',
    BEA_CODES: {
      '811100': 0.15606892710331252,
      '230301': 0.14801214146673825,
      '2334A0': 0.1419607309411653,
      '233411': 0.11173224251680877,
      '233240': 0.09541368107730734,
      '2332D0': 0.07872546523946464,
      '326190': 0.0701360412198259,
      '233262': 0.06852468409251106,
      '2332A0': 0.06795676051687767,
      '230302': 0.061469325825988554,
    },
  },
  ['32.11']: { label: 'Striking of coins', BEA_CODES: { '339910': 1.0 } },
  ['32.12']: {
    label: 'Manufacture of jewellery and related articles',
    BEA_CODES: {
      '339910': 0.12181798669242326,
      '811300': 0.6404807898690706,
      '334514': 0.07087357802103456,
      '33451A': 0.12439364670530156,
      '327999': 0.04243399871216999,
    },
  },
  ['32.13']: {
    label: 'Manufacture of imitation jewellery and related articles',
    BEA_CODES: {
      '339910': 0.10947159293656994,
      '332999': 0.13948441975522957,
      '811300': 0.5755673215095141,
      '33451A': 0.11178620683003983,
      '334514': 0.06369045896864663,
    },
  },
  ['32.20']: {
    label: 'Manufacture of musical instruments',
    BEA_CODES: {
      '339920': 0.14325208085612368,
      '339990': 0.37826991676575505,
      '811400': 0.47847800237812127,
    },
  },
  ['32.30']: {
    label: 'Manufacture of sports goods',
    BEA_CODES: {
      '339113': 0.736112392728615,
      '339930': 0.04154747623881148,
      '339920': 0.22234013103257358,
    },
  },
  ['32.40']: {
    label: 'Manufacture of games and toys',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['32.50']: {
    label: 'Manufacture of medical and dental instruments and supplies',
    BEA_CODES: {
      '325412': 0.2052656036958294,
      '326190': 0.14210525419891637,
      '323110': 0.10724577980605066,
      '811300': 0.10158764743230753,
      '336390': 0.09100333121692401,
      '811200': 0.08052284956074578,
      '334511': 0.07767676131498809,
      '333120': 0.0658940921540224,
      '3259A0': 0.0658311105361438,
      '339112': 0.06286757008407196,
    },
  },
  ['32.91']: {
    label: 'Manufacture of brooms and brushes',
    BEA_CODES: {
      '336612': 0.09659360062012833,
      '339940': 0.044327691888089805,
      '339990': 0.36533023269167275,
      '326290': 0.2455248840848083,
      '333318': 0.2482235907153008,
    },
  },
  ['33.11']: {
    label: 'Repair and maintenance of fabricated metal products',
    BEA_CODES: {
      '811100': 0.15606892710331252,
      '230301': 0.14801214146673825,
      '2334A0': 0.1419607309411653,
      '233411': 0.11173224251680877,
      '233240': 0.09541368107730734,
      '2332D0': 0.07872546523946464,
      '326190': 0.0701360412198259,
      '233262': 0.06852468409251106,
      '2332A0': 0.06795676051687767,
      '230302': 0.061469325825988554,
    },
  },
  ['33.12']: {
    label: 'Repair and maintenance of machinery',
    BEA_CODES: {
      '230301': 0.1703544122894127,
      '2334A0': 0.1633895479655112,
      '233411': 0.12859810228478105,
      '233240': 0.10981627184920387,
      '2332D0': 0.09060898808827383,
      '233262': 0.07886841018728649,
      '2332A0': 0.07821475916924436,
      '230302': 0.07074805330930167,
      '811300': 0.0577069419478607,
      '336390': 0.051694512909124135,
    },
  },
  ['33.15']: {
    label: 'Repair and maintenance of civilian ships and boats',
    BEA_CODES: {
      '230301': 0.14535501542827914,
      '2334A0': 0.13941224031813607,
      '441000': 0.12662986980842533,
      '233411': 0.1097264161840173,
      '48A000': 0.104955200237612,
      '233240': 0.09370080689074799,
      '2332D0': 0.07731217926505289,
      '326190': 0.06887695328614095,
      '233262': 0.06729452337343036,
      '2332A0': 0.066736795208158,
    },
  },
  ['33.13']: {
    label: 'Repair and maintenance of electronic and optical equipment',
    BEA_CODES: {
      '811300': 0.14247449162293915,
      '336390': 0.1276302156693293,
      '811200': 0.11293156545280054,
      '334413': 0.11259495514249837,
      '334511': 0.1089399878724796,
      '333120': 0.09241504767452409,
      '339112': 0.08817041553468519,
      '339113': 0.07617658433640023,
      '333415': 0.07021309103757144,
      '333618': 0.06845364565677207,
    },
  },
  ['33.14']: {
    label: 'Repair and maintenance of electrical equipment',
    BEA_CODES: {
      '811300': 0.1655111209717677,
      '811200': 0.13119141383326863,
      '334511': 0.12655444006877808,
      '339112': 0.10242664595928781,
      '339113': 0.08849353818847412,
      '333415': 0.08156581063841589,
      '333618': 0.07952188141327861,
      '334220': 0.07933606966553886,
      '334510': 0.07674025181651783,
      '33441A': 0.06865882744467247,
    },
  },
  ['33.16']: {
    label: 'Repair and maintenance of civilian air and spacecraft',
    BEA_CODES: {
      '48A000': 0.2957139469903716,
      '336411': 0.24404559888048424,
      '336412': 0.0925553132830915,
      '336413': 0.07932150279181563,
      '334220': 0.06649914223149986,
      '336310': 0.0636887299912131,
      '33399A': 0.04746783949268456,
      '336414': 0.0416610489416206,
      '333318': 0.04019656613650839,
      '334515': 0.028850311260710484,
    },
  },
  ['33.17']: {
    label: 'Repair and maintenance of other civilian transport equipment',
    BEA_CODES: {
      '48A000': 0.3223331475193838,
      '811300': 0.151218770587341,
      '332310': 0.09605229818071251,
      '811400': 0.08156894542137536,
      '339113': 0.08085187249784624,
      '333130': 0.07909086302133482,
      '333920': 0.07402827750468757,
      '336500': 0.04035118836466832,
      '33391A': 0.03998378350985658,
      '33299A': 0.034520853392793795,
    },
  },
  ['33.19']: {
    label: 'Repair and maintenance of other equipment',
    BEA_CODES: {
      '230301': 0.16554878163373463,
      '2334A0': 0.15878039338026634,
      '233411': 0.12497040063446005,
      '233240': 0.10671839821388972,
      '2332D0': 0.08805294433815819,
      '326190': 0.07844583598004909,
      '233262': 0.07664356350050554,
      '2332A0': 0.07600835171939425,
      '230302': 0.06875227868131538,
      '811300': 0.05607905191822682,
    },
  },
  ['91.30']: {
    label:
      'Conservation, restoration and other support activities for cultural heritage',
    BEA_CODES: {
      '230301': 0.15638544998131496,
      '2334A0': 0.14999170046397514,
      '233411': 0.11805313300827557,
      '2332C0': 0.10832625431731212,
      '233240': 0.10081140169843125,
      '2332D0': 0.08317910398741667,
      '326190': 0.07410376106567818,
      '233262': 0.07240124661694046,
      '2332A0': 0.07180119460059703,
      '230302': 0.06494675426005864,
    },
  },
  ['33.20']: {
    label: 'Installation of industrial machinery and equipment',
    BEA_CODES: {
      '541300': 0.22217636560030662,
      '230301': 0.13641794396286389,
      '2334A0': 0.1308405570418091,
      '233411': 0.1029799491275412,
      '233240': 0.0879396654187299,
      '2332D0': 0.07255868335572392,
      '326190': 0.0646420925073463,
      '233262': 0.06315695740883415,
      '2332A0': 0.06263352084648055,
      '230302': 0.056654264730364355,
    },
  },
  ['35.11']: {
    label: 'Production of electricity from non-renewable sources',
    BEA_CODES: { '221100': 1.0 },
  },
  ['35.12']: {
    label: 'Production of electricity from renewable sources',
    BEA_CODES: { '221100': 1.0 },
  },
  ['35.13']: {
    label: 'Transmission of electricity',
    BEA_CODES: { '221100': 1.0 },
  },
  ['35.14']: {
    label: 'Distribution of electricity',
    BEA_CODES: { '221100': 1.0 },
  },
  ['35.15']: { label: 'Trade of electricity', BEA_CODES: { '221100': 1.0 } },
  ['35.16']: { label: 'Storage of electricity', BEA_CODES: { '221100': 1.0 } },
  ['35.40']: {
    label:
      'Activities of brokers and agents for electric power and natural gas',
    BEA_CODES: { '221100': 0.8201565984401357, '221200': 0.17984340155986428 },
  },
  ['35.21']: { label: 'Manufacture of gas', BEA_CODES: { '221200': 1.0 } },
  ['35.22']: {
    label: 'Distribution of gaseous fuels through mains',
    BEA_CODES: { '221200': 1.0 },
  },
  ['35.23']: {
    label: 'Trade of gas through mains',
    BEA_CODES: { '221200': 1.0 },
  },
  ['35.30']: {
    label: 'Steam and air conditioning supply',
    BEA_CODES: { '312110': 0.3930587424423525, '221300': 0.6069412575576475 },
  },
  ['36.00']: {
    label: 'Water collection, treatment and supply',
    BEA_CODES: { '221300': 1.0 },
  },
  ['37.00']: {
    label: 'Sewerage',
    BEA_CODES: { '221300': 0.41859919369396476, '562111': 0.5814008063060353 },
  },
  ['38.11']: {
    label: 'Collection of non-hazardous waste',
    BEA_CODES: {
      '48A000': 0.34793501449592473,
      '561700': 0.3877933373447842,
      '562111': 0.26427164815929105,
    },
  },
  ['38.12']: {
    label: 'Collection of hazardous waste',
    BEA_CODES: {
      '325180': 0.053205597477193375,
      '221100': 0.6037166347561662,
      '325412': 0.1697657393850659,
      '331410': 0.03728460412208582,
      '562111': 0.1360274242594887,
    },
  },
  ['38.22']: {
    label: 'Energy recovery',
    BEA_CODES: {
      '325180': 0.08182853530104621,
      '212310': 0.027354846532252477,
      '21311A': 0.27492551791034436,
      '2123A0': 0.030788817293701934,
      '325412': 0.2610943670754521,
      '331410': 0.057342548326751196,
      '325310': 0.057459467886094365,
      '562111': 0.20920589967435738,
    },
  },
  ['38.23']: {
    label: 'Other waste recovery',
    BEA_CODES: {
      '325180': 0.08182853530104621,
      '212310': 0.027354846532252477,
      '21311A': 0.27492551791034436,
      '2123A0': 0.030788817293701934,
      '325412': 0.2610943670754521,
      '331410': 0.057342548326751196,
      '325310': 0.057459467886094365,
      '562111': 0.20920589967435738,
    },
  },
  ['38.31']: {
    label: 'Incineration without energy recovery',
    BEA_CODES: {
      '325180': 0.08182853530104621,
      '212310': 0.027354846532252477,
      '21311A': 0.27492551791034436,
      '2123A0': 0.030788817293701934,
      '325412': 0.2610943670754521,
      '331410': 0.057342548326751196,
      '325310': 0.057459467886094365,
      '562111': 0.20920589967435738,
    },
  },
  ['38.32']: {
    label: 'Landfilling or permanent storage',
    BEA_CODES: {
      '325180': 0.08182853530104621,
      '212310': 0.027354846532252477,
      '21311A': 0.27492551791034436,
      '2123A0': 0.030788817293701934,
      '325412': 0.2610943670754521,
      '331410': 0.057342548326751196,
      '325310': 0.057459467886094365,
      '562111': 0.20920589967435738,
    },
  },
  ['38.33']: {
    label: 'Other waste disposal',
    BEA_CODES: {
      '325180': 0.08182853530104621,
      '212310': 0.027354846532252477,
      '21311A': 0.27492551791034436,
      '2123A0': 0.030788817293701934,
      '325412': 0.2610943670754521,
      '331410': 0.057342548326751196,
      '325310': 0.057459467886094365,
      '562111': 0.20920589967435738,
    },
  },
  ['38.21']: {
    label: 'Materials recovery',
    BEA_CODES: {
      '48A000': 0.27773969163322604,
      '423A00': 0.5113050350853445,
      '562111': 0.21095527328142943,
    },
  },
  ['39.00']: {
    label:
      'Remediation activities and other waste management service activities',
    BEA_CODES: {
      '48A000': 0.34793501449592473,
      '561700': 0.3877933373447842,
      '562111': 0.26427164815929105,
    },
  },
  ['68.12']: {
    label: 'Development of building projects',
    BEA_CODES: {
      '233230': 0.053267499590812374,
      '2332A0': 0.08826340771455071,
      '2332C0': 0.13316274755851384,
      '233240': 0.12392492771018604,
      '233210': 0.050359539527524685,
      '2332D0': 0.10224998636041246,
      '233262': 0.08900103660865295,
      '2334A0': 0.18438103551748594,
      '233411': 0.1451197555785913,
      '233412': 0.030270063833269682,
    },
  },
  ['41.00']: {
    label: 'Construction of residential and non-residential buildings',
    BEA_CODES: {
      '230301': 0.15638544998131496,
      '2334A0': 0.14999170046397514,
      '233411': 0.11805313300827557,
      '2332C0': 0.10832625431731212,
      '233240': 0.10081140169843125,
      '2332D0': 0.08317910398741667,
      '326190': 0.07410376106567818,
      '233262': 0.07240124661694046,
      '2332A0': 0.07180119460059703,
      '230302': 0.06494675426005864,
    },
  },
  ['42.11']: {
    label: 'Construction of roads and motorways',
    BEA_CODES: {
      '230301': 0.16135041299456088,
      '2334A0': 0.15475367317426547,
      '233411': 0.12180111236987168,
      '2332C0': 0.11176542238642077,
      '233240': 0.10401198641271249,
      '2332D0': 0.08581989425800643,
      '233262': 0.07469985887024763,
      '2332A0': 0.07408075625765292,
      '230302': 0.06700869949070568,
      '233230': 0.04470818378555604,
    },
  },
  ['43.50']: {
    label: 'Specialised construction activities in civil engineering',
    BEA_CODES: {
      '230301': 0.14580407455928734,
      '2334A0': 0.13984293986644344,
      '561700': 0.11733992536788469,
      '233411': 0.11006540448074073,
      '2332C0': 0.10099666729287685,
      '233240': 0.09399028574218733,
      '562111': 0.07996428119209452,
      '2332D0': 0.07755102716400444,
      '233262': 0.06750242277131935,
      '2332A0': 0.0669429715631613,
    },
  },
  ['42.12']: {
    label: 'Construction of railways and underground railways',
    BEA_CODES: {
      '230301': 0.16135041299456088,
      '2334A0': 0.15475367317426547,
      '233411': 0.12180111236987168,
      '2332C0': 0.11176542238642077,
      '233240': 0.10401198641271249,
      '2332D0': 0.08581989425800643,
      '233262': 0.07469985887024763,
      '2332A0': 0.07408075625765292,
      '230302': 0.06700869949070568,
      '233230': 0.04470818378555604,
    },
  },
  ['42.13']: {
    label: 'Construction of bridges and tunnels',
    BEA_CODES: {
      '230301': 0.16135041299456088,
      '2334A0': 0.15475367317426547,
      '233411': 0.12180111236987168,
      '2332C0': 0.11176542238642077,
      '233240': 0.10401198641271249,
      '2332D0': 0.08581989425800643,
      '233262': 0.07469985887024763,
      '2332A0': 0.07408075625765292,
      '230302': 0.06700869949070568,
      '233230': 0.04470818378555604,
    },
  },
  ['42.21']: {
    label: 'Construction of utility projects for fluids',
    BEA_CODES: {
      '230301': 0.15458244165835314,
      '2334A0': 0.1482624073338019,
      '233411': 0.11669206788750462,
      '2332C0': 0.10707733289813452,
      '233240': 0.09964912095982395,
      '562111': 0.08477865841192796,
      '2332D0': 0.08222011057208463,
      '233262': 0.07156651390837486,
      '2332A0': 0.070973380047012,
      '230302': 0.06419796632298244,
    },
  },
  ['42.22']: {
    label:
      'Construction of utility projects for electricity and telecommunications',
    BEA_CODES: {
      '230301': 0.16135041299456088,
      '2334A0': 0.15475367317426547,
      '233411': 0.12180111236987168,
      '2332C0': 0.11176542238642077,
      '233240': 0.10401198641271249,
      '2332D0': 0.08581989425800643,
      '233262': 0.07469985887024763,
      '2332A0': 0.07408075625765292,
      '230302': 0.06700869949070568,
      '233230': 0.04470818378555604,
    },
  },
  ['42.91']: {
    label: 'Construction of water projects',
    BEA_CODES: { '2332D0': 1.0 },
  },
  ['42.99']: {
    label: 'Construction of other civil engineering projects n.e.c.',
    BEA_CODES: {
      '230301': 0.14580407455928734,
      '2334A0': 0.13984293986644344,
      '561700': 0.11733992536788469,
      '233411': 0.11006540448074073,
      '2332C0': 0.10099666729287685,
      '233240': 0.09399028574218733,
      '562111': 0.07996428119209452,
      '2332D0': 0.07755102716400444,
      '233262': 0.06750242277131935,
      '2332A0': 0.0669429715631613,
    },
  },
  ['43.11']: {
    label: 'Demolition',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['43.12']: {
    label: 'Site preparation',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['43.13']: {
    label: 'Test drilling and boring',
    BEA_CODES: {
      '230301': 0.1606238637834802,
      '2334A0': 0.15405682860430217,
      '233411': 0.12125265079200104,
      '21311A': 0.11576507828839523,
      '233240': 0.10354362797923493,
      '2332D0': 0.08543345349649266,
      '233262': 0.07436349082183213,
      '2332A0': 0.0737471759834142,
      '230302': 0.06670696417533245,
      '233230': 0.044506866075514975,
    },
  },
  ['43.21']: {
    label: 'Electrical installation',
    BEA_CODES: {
      '230301': 0.15976100097391469,
      '2334A0': 0.15322924355665662,
      '233411': 0.12060128803390746,
      '2332C0': 0.11066445646673909,
      '233240': 0.10298739714499722,
      '2332D0': 0.08497450955143702,
      '233262': 0.07396401412448335,
      '2332A0': 0.07335101009643025,
      '230302': 0.06634861792982373,
      '811300': 0.05411846212161057,
    },
  },
  ['43.22']: {
    label: 'Plumbing, heat and air-conditioning installation',
    BEA_CODES: {
      '230301': 0.14580407455928734,
      '2334A0': 0.13984293986644344,
      '561700': 0.11733992536788469,
      '233411': 0.11006540448074073,
      '2332C0': 0.10099666729287685,
      '233240': 0.09399028574218733,
      '562111': 0.07996428119209452,
      '2332D0': 0.07755102716400444,
      '233262': 0.06750242277131935,
      '2332A0': 0.0669429715631613,
    },
  },
  ['43.23']: {
    label: 'Installation of insulation',
    BEA_CODES: {
      '230301': 0.14580407455928734,
      '2334A0': 0.13984293986644344,
      '561700': 0.11733992536788469,
      '233411': 0.11006540448074073,
      '2332C0': 0.10099666729287685,
      '233240': 0.09399028574218733,
      '562111': 0.07996428119209452,
      '2332D0': 0.07755102716400444,
      '233262': 0.06750242277131935,
      '2332A0': 0.0669429715631613,
    },
  },
  ['43.24']: {
    label: 'Other construction installation',
    BEA_CODES: {
      '230301': 0.15976100097391469,
      '2334A0': 0.15322924355665662,
      '233411': 0.12060128803390746,
      '2332C0': 0.11066445646673909,
      '233240': 0.10298739714499722,
      '2332D0': 0.08497450955143702,
      '233262': 0.07396401412448335,
      '2332A0': 0.07335101009643025,
      '230302': 0.06634861792982373,
      '811300': 0.05411846212161057,
    },
  },
  ['43.31']: {
    label: 'Plastering',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['43.32']: {
    label: 'Joinery installation',
    BEA_CODES: {
      '230301': 0.1672560566346007,
      '2334A0': 0.16041786720260806,
      '233411': 0.1262591980435734,
      '233240': 0.10781896598373379,
      '2332D0': 0.08896101861776107,
      '326190': 0.07925483386797728,
      '233262': 0.07743397487442434,
      '2332A0': 0.07679221226772893,
      '230302': 0.06946130864509294,
      '233230': 0.046344563862499515,
    },
  },
  ['43.33']: {
    label: 'Floor and wall covering',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['43.34']: {
    label: 'Painting and glazing',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['43.35']: {
    label: 'Other building completion and finishing',
    BEA_CODES: {
      '230301': 0.14580407455928734,
      '2334A0': 0.13984293986644344,
      '561700': 0.11733992536788469,
      '233411': 0.11006540448074073,
      '2332C0': 0.10099666729287685,
      '233240': 0.09399028574218733,
      '562111': 0.07996428119209452,
      '2332D0': 0.07755102716400444,
      '233262': 0.06750242277131935,
      '2332A0': 0.0669429715631613,
    },
  },
  ['81.22']: {
    label: 'Other building and industrial cleaning activities',
    BEA_CODES: {
      '230301': 0.14042083449567608,
      '2334A0': 0.13467979117682222,
      '561700': 0.11300761168453353,
      '233411': 0.10600167377356234,
      '48A000': 0.10139242019686766,
      '2332C0': 0.09726776391822421,
      '233240': 0.09052006535687243,
      '562111': 0.07701191567369386,
      '2332D0': 0.07468776152711912,
      '233262': 0.06501016219662854,
    },
  },
  ['43.41']: {
    label: 'Roofing activities',
    BEA_CODES: {
      '230301': 0.17340145548602484,
      '2334A0': 0.1663120141572295,
      '233411': 0.13089827148731617,
      '233240': 0.11178049995177251,
      '2332D0': 0.09222966522246653,
      '233262': 0.08027908954368919,
      '2332A0': 0.07961374700054527,
      '230302': 0.07201348795001664,
      '233230': 0.0480473771133976,
      '233210': 0.04542439208754176,
    },
  },
  ['43.42']: {
    label:
      'Other specialised construction activities in construction of buildings',
    BEA_CODES: {
      '230301': 0.14580407455928734,
      '2334A0': 0.13984293986644344,
      '561700': 0.11733992536788469,
      '233411': 0.11006540448074073,
      '2332C0': 0.10099666729287685,
      '233240': 0.09399028574218733,
      '562111': 0.07996428119209452,
      '2332D0': 0.07755102716400444,
      '233262': 0.06750242277131935,
      '2332A0': 0.0669429715631613,
    },
  },
  ['43.91']: {
    label: 'Masonry and bricklaying activities',
    BEA_CODES: {
      '230301': 0.14580407455928734,
      '2334A0': 0.13984293986644344,
      '561700': 0.11733992536788469,
      '233411': 0.11006540448074073,
      '2332C0': 0.10099666729287685,
      '233240': 0.09399028574218733,
      '562111': 0.07996428119209452,
      '2332D0': 0.07755102716400444,
      '233262': 0.06750242277131935,
      '2332A0': 0.0669429715631613,
    },
  },
  ['43.99']: {
    label: 'Other specialised construction activities n.e.c.',
    BEA_CODES: {
      '230301': 0.14580407455928734,
      '2334A0': 0.13984293986644344,
      '561700': 0.11733992536788469,
      '233411': 0.11006540448074073,
      '2332C0': 0.10099666729287685,
      '233240': 0.09399028574218733,
      '562111': 0.07996428119209452,
      '2332D0': 0.07755102716400444,
      '233262': 0.06750242277131935,
      '2332A0': 0.0669429715631613,
    },
  },
  ['46.18']: {
    label:
      'Activities of agents involved in the wholesale of other particular products',
    BEA_CODES: {
      GSLGO: 0.31021523744448243,
      S00600: 0.14662360152812323,
      '541800': 0.13532194426829816,
      '423A00': 0.09841401113020355,
      '452000': 0.08367487501318471,
      '441000': 0.06449811337881059,
      '48A000': 0.053458259215321914,
      '423100': 0.043486208691946845,
      '5419A0': 0.03371586841936065,
      '611B00': 0.030591880910267936,
    },
  },
  ['46.71']: {
    label: 'Wholesale of motor vehicles',
    BEA_CODES: {
      '441000': 0.27930019817042656,
      '425000': 0.10622044048791052,
      '423A00': 0.4261683229395462,
      '423100': 0.18831103840211674,
    },
  },
  ['47.81']: {
    label: 'Retail sale of motor vehicles',
    BEA_CODES: {
      '441000': 0.27930019817042656,
      '425000': 0.10622044048791052,
      '423A00': 0.4261683229395462,
      '423100': 0.18831103840211674,
    },
  },
  ['95.31']: {
    label: 'Repair and maintenance of motor vehicles',
    BEA_CODES: {
      '811100': 0.4984932686811875,
      '48A000': 0.3413611834884788,
      '811300': 0.16014554783033375,
    },
  },
  ['95.40']: {
    label:
      'Intermediation service activities for repair and maintenance of computers, personal and household goods, and motor vehicles and motorcycles',
    BEA_CODES: {
      GSLGO: 0.3349063836930238,
      S00600: 0.1582939012163088,
      '541800': 0.14609270441564098,
      '811100': 0.08427917485176095,
      '441000': 0.0696317501508477,
      '48A000': 0.05771319429636922,
      '423100': 0.04694743241342703,
      '5419A0': 0.03639943562546218,
      '611B00': 0.03302679871700065,
      '561400': 0.0327092246201587,
    },
  },
  ['46.72']: {
    label: 'Wholesale of motor vehicle parts and accessories',
    BEA_CODES: {
      '452000': 0.26597020746843625,
      '425000': 0.07796896789395226,
      '423100': 0.13822591244970908,
      '441000': 0.20501466651706285,
      '423A00': 0.31282024567083955,
    },
  },
  ['47.82']: {
    label: 'Retail sale of motor vehicle parts and accessories',
    BEA_CODES: {
      '452000': 0.1815146197497664,
      '445000': 0.17264203833268763,
      '425000': 0.05321087536179038,
      '423100': 0.09433396385514711,
      '454000': 0.14489573600127623,
      '441000': 0.13991476560541488,
      '423A00': 0.21348800109391736,
    },
  },
  ['46.73']: {
    label: 'Wholesale of motorcycles, motorcycle parts and accessories',
    BEA_CODES: {
      '425000': 0.12295361671651851,
      '811400': 0.06780974849391246,
      '423100': 0.21797615536925474,
      '441000': 0.3232990689640645,
      '48A000': 0.2679614104562497,
    },
  },
  ['47.83']: {
    label: 'Retail sale of motorcycles, motorcycle parts and accessories',
    BEA_CODES: {
      '445000': 0.2300943660996439,
      '425000': 0.07091854773160518,
      '811400': 0.03911205716153284,
      '423100': 0.12572669915475093,
      '454000': 0.1931145672730542,
      '441000': 0.18647601482739642,
      '48A000': 0.15455774775201653,
    },
  },
  ['95.32']: {
    label: 'Repair and maintenance of motorcycles',
    BEA_CODES: {
      '425000': 0.12295361671651851,
      '811400': 0.06780974849391246,
      '423100': 0.21797615536925474,
      '441000': 0.3232990689640645,
      '48A000': 0.2679614104562497,
    },
  },
  ['46.11']: {
    label:
      'Activities of agents involved in the wholesale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
    BEA_CODES: {
      GSLGO: 0.40221867457078564,
      S00600: 0.19010913568677024,
      '541800': 0.17545564013011283,
      '5419A0': 0.04371529915601009,
      '611B00': 0.03966480142535675,
      '561400': 0.0392833986258602,
      '323110': 0.0343284313992579,
      '425000': 0.03180408972773289,
      '561100': 0.02492031405796233,
      '561900': 0.018500215220151144,
    },
  },
  ['46.12']: {
    label:
      'Activities of agents involved in the wholesale of fuels, ores, metals and industrial chemicals',
    BEA_CODES: {
      GSLGO: 0.40221867457078564,
      S00600: 0.19010913568677024,
      '541800': 0.17545564013011283,
      '5419A0': 0.04371529915601009,
      '611B00': 0.03966480142535675,
      '561400': 0.0392833986258602,
      '323110': 0.0343284313992579,
      '425000': 0.03180408972773289,
      '561100': 0.02492031405796233,
      '561900': 0.018500215220151144,
    },
  },
  ['46.13']: {
    label:
      'Activities of agents involved in the wholesale of timber and building materials',
    BEA_CODES: {
      GSLGO: 0.40221867457078564,
      S00600: 0.19010913568677024,
      '541800': 0.17545564013011283,
      '5419A0': 0.04371529915601009,
      '611B00': 0.03966480142535675,
      '561400': 0.0392833986258602,
      '323110': 0.0343284313992579,
      '425000': 0.03180408972773289,
      '561100': 0.02492031405796233,
      '561900': 0.018500215220151144,
    },
  },
  ['46.14']: {
    label:
      'Activities of agents involved in the wholesale of machinery, industrial equipment, ships and aircraft',
    BEA_CODES: {
      GSLGO: 0.40221867457078564,
      S00600: 0.19010913568677024,
      '541800': 0.17545564013011283,
      '5419A0': 0.04371529915601009,
      '611B00': 0.03966480142535675,
      '561400': 0.0392833986258602,
      '323110': 0.0343284313992579,
      '425000': 0.03180408972773289,
      '561100': 0.02492031405796233,
      '561900': 0.018500215220151144,
    },
  },
  ['46.15']: {
    label:
      'Activities of agents involved in the wholesale of furniture, household goods, hardware and ironmongery',
    BEA_CODES: {
      GSLGO: 0.40221867457078564,
      S00600: 0.19010913568677024,
      '541800': 0.17545564013011283,
      '5419A0': 0.04371529915601009,
      '611B00': 0.03966480142535675,
      '561400': 0.0392833986258602,
      '323110': 0.0343284313992579,
      '425000': 0.03180408972773289,
      '561100': 0.02492031405796233,
      '561900': 0.018500215220151144,
    },
  },
  ['46.16']: {
    label:
      'Activities of agents involved in the wholesale of textiles, clothing, fur, footwear and leather goods',
    BEA_CODES: {
      GSLGO: 0.40221867457078564,
      S00600: 0.19010913568677024,
      '541800': 0.17545564013011283,
      '5419A0': 0.04371529915601009,
      '611B00': 0.03966480142535675,
      '561400': 0.0392833986258602,
      '323110': 0.0343284313992579,
      '425000': 0.03180408972773289,
      '561100': 0.02492031405796233,
      '561900': 0.018500215220151144,
    },
  },
  ['46.17']: {
    label:
      'Activities of agents involved in the wholesale of food, beverages and tobacco',
    BEA_CODES: {
      GSLGO: 0.40221867457078564,
      S00600: 0.19010913568677024,
      '541800': 0.17545564013011283,
      '5419A0': 0.04371529915601009,
      '611B00': 0.03966480142535675,
      '561400': 0.0392833986258602,
      '323110': 0.0343284313992579,
      '425000': 0.03180408972773289,
      '561100': 0.02492031405796233,
      '561900': 0.018500215220151144,
    },
  },
  ['46.19']: {
    label: 'Activities of agents involved in non-specialised wholesale',
    BEA_CODES: {
      GSLGO: 0.40221867457078564,
      S00600: 0.19010913568677024,
      '541800': 0.17545564013011283,
      '5419A0': 0.04371529915601009,
      '611B00': 0.03966480142535675,
      '561400': 0.0392833986258602,
      '323110': 0.0343284313992579,
      '425000': 0.03180408972773289,
      '561100': 0.02492031405796233,
      '561900': 0.018500215220151144,
    },
  },
  ['46.21']: {
    label: 'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46.22']: {
    label: 'Wholesale of flowers and plants',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46.23']: {
    label: 'Wholesale of live animals',
    BEA_CODES: { '424400': 0.30744763597977665, '424A00': 0.6925523640202234 },
  },
  ['46.24']: {
    label: 'Wholesale of hides, skins and leather',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46.31']: {
    label: 'Wholesale of fruit and vegetables',
    BEA_CODES: { '424400': 0.30744763597977665, '424A00': 0.6925523640202234 },
  },
  ['46.32']: {
    label: 'Wholesale of meat, meat products, fish and fish products',
    BEA_CODES: { '424400': 0.30744763597977665, '424A00': 0.6925523640202234 },
  },
  ['46.33']: {
    label: 'Wholesale of dairy products, eggs and edible oils and fats',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46.34']: {
    label: 'Wholesale of beverages',
    BEA_CODES: {
      '312140': 0.04167709075046989,
      '424400': 0.2839841169036943,
      '424A00': 0.6396987600151389,
      '312130': 0.03464003233069684,
    },
  },
  ['46.35']: {
    label: 'Wholesale of tobacco products',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46.36']: {
    label: 'Wholesale of sugar, chocolate and sugar confectionery',
    BEA_CODES: { '424400': 0.30744763597977665, '424A00': 0.6925523640202234 },
  },
  ['46.37']: {
    label: 'Wholesale of coffee, tea, cocoa and spices',
    BEA_CODES: { '424400': 0.30744763597977665, '424A00': 0.6925523640202234 },
  },
  ['46.38']: {
    label: 'Wholesale of other food',
    BEA_CODES: { '424400': 0.30744763597977665, '424A00': 0.6925523640202234 },
  },
  ['46.39']: {
    label: 'Non-specialised wholesale of food, beverages and tobacco',
    BEA_CODES: { '424400': 1.0 },
  },
  ['46.41']: {
    label: 'Wholesale of textiles',
    BEA_CODES: { '423A00': 0.4390886404415804, '424A00': 0.5609113595584196 },
  },
  ['46.42']: {
    label: 'Wholesale of clothing and footwear',
    BEA_CODES: { '424A00': 1.0 },
  },
  ['46.43']: {
    label: 'Wholesale of electrical household appliances',
    BEA_CODES: {
      '423400': 0.2187640503842456,
      '423600': 0.16635424376364688,
      '423A00': 0.26998757225500164,
      '424A00': 0.34489413359710586,
    },
  },
  ['46.49']: {
    label: 'Wholesale of other household goods',
    BEA_CODES: {
      '423800': 0.15455077097019435,
      '424A00': 0.29159047934657595,
      '423400': 0.184953897736798,
      '423600': 0.14064406713581162,
      '423A00': 0.2282607848106201,
    },
  },
  ['46.64']: {
    label: 'Wholesale of other machinery and equipment',
    BEA_CODES: {
      '423800': 0.14039073470377933,
      '424A00': 0.2648747810904661,
      '423100': 0.09162061229151579,
      '423400': 0.16800830838045017,
      '423600': 0.1277581715896595,
      '423A00': 0.20734739194412916,
    },
  },
  ['46.44']: {
    label: 'Wholesale of china and glassware and cleaning materials',
    BEA_CODES: {
      '423800': 0.22916711830651676,
      '423A00': 0.33846396203045714,
      '424A00': 0.4323689196630261,
    },
  },
  ['46.45']: {
    label: 'Wholesale of perfume and cosmetics',
    BEA_CODES: { '424200': 1.0 },
  },
  ['46.46']: {
    label: 'Wholesale of pharmaceutical and medical goods',
    BEA_CODES: { '424200': 0.4179225344552044, '423400': 0.5820774655447956 },
  },
  ['46.47']: {
    label:
      'Wholesale of household, office and shop furniture, carpets and lighting equipment',
    BEA_CODES: {
      '423400': 0.2187640503842456,
      '423600': 0.16635424376364688,
      '423A00': 0.26998757225500164,
      '424A00': 0.34489413359710586,
    },
  },
  ['46.48']: {
    label: 'Wholesale of watches and jewellery',
    BEA_CODES: {
      '423400': 0.2187640503842456,
      '423600': 0.16635424376364688,
      '423A00': 0.26998757225500164,
      '424A00': 0.34489413359710586,
    },
  },
  ['46.50']: {
    label: 'Wholesale of information and communication equipment',
    BEA_CODES: {
      '423400': 0.3339369430248765,
      '423600': 0.2539349016626543,
      '423A00': 0.4121281553124692,
    },
  },
  ['46.61']: {
    label: 'Wholesale of agricultural machinery, equipment and supplies',
    BEA_CODES: { '423800': 1.0 },
  },
  ['46.62']: {
    label: 'Wholesale of machine tools',
    BEA_CODES: { '423800': 1.0 },
  },
  ['46.63']: {
    label: 'Wholesale of mining, construction and civil engineering machinery',
    BEA_CODES: { '423800': 1.0 },
  },
  ['46.81']: {
    label: 'Wholesale of solid, liquid and gaseous fuels and related products',
    BEA_CODES: {
      '423A00': 0.33741308613729293,
      '424A00': 0.4310264840551062,
      '424700': 0.2315604298076009,
    },
  },
  ['46.82']: {
    label: 'Wholesale of metals and metal ores',
    BEA_CODES: { '423A00': 1.0 },
  },
  ['46.83']: {
    label: 'Wholesale of wood, construction materials and sanitary equipment',
    BEA_CODES: {
      '423800': 0.15455077097019435,
      '424A00': 0.29159047934657595,
      '423400': 0.184953897736798,
      '423600': 0.14064406713581162,
      '423A00': 0.2282607848106201,
    },
  },
  ['46.84']: {
    label: 'Wholesale of hardware, plumbing and heating equipment and supplies',
    BEA_CODES: {
      '423800': 0.24755166368219167,
      '423600': 0.22527660384958245,
      '423A00': 0.36561666226931466,
      '423100': 0.16155507019891122,
    },
  },
  ['46.85']: {
    label: 'Wholesale of chemical products',
    BEA_CODES: {
      '423800': 0.26850926775741607,
      '424400': 0.2248950963690982,
      '424A00': 0.5065956358734857,
    },
  },
  ['46.86']: {
    label: 'Wholesale of other intermediate products',
    BEA_CODES: {
      '423800': 0.1922634355603571,
      '424400': 0.16103393461884027,
      '423A00': 0.28395977849801085,
      '424A00': 0.3627428513227917,
    },
  },
  ['46.87']: {
    label: 'Wholesale of waste and scrap',
    BEA_CODES: { '561900': 0.12662551977474873, '423A00': 0.8733744802252512 },
  },
  ['46.90']: {
    label: 'Non-specialised wholesale trade',
    BEA_CODES: { '423A00': 0.4390886404415804, '424A00': 0.5609113595584196 },
  },
  ['47.11']: {
    label:
      'Non-specialised retail sale of predominately food, beverages or tobacco',
    BEA_CODES: {
      '447000': 0.11072414235613073,
      '452000': 0.32344613725225835,
      '445000': 0.30763582846960175,
      '454000': 0.2581938919220092,
    },
  },
  ['47.91']: {
    label: 'Intermediation service activities for non-specialised retail sale',
    BEA_CODES: {
      GSLGO: 0.3118859066527413,
      S00600: 0.1474132453196243,
      '541800': 0.13605072280075575,
      '452000': 0.08412550741390441,
      '445000': 0.08001338457326533,
      '4B0000': 0.07824229457552989,
      '454000': 0.0671539699117497,
      '5419A0': 0.03389744577726853,
      '611B00': 0.030756633982620334,
      '561400': 0.030460888992540465,
    },
  },
  ['47.12']: {
    label: 'Other non-specialised retail sale',
    BEA_CODES: {
      '452000': 0.27178013758462544,
      '445000': 0.25849530465167064,
      '4B0000': 0.25277353133872027,
      '454000': 0.21695102642498362,
    },
  },
  ['47.21']: {
    label: 'Retail sale of fruit and vegetables',
    BEA_CODES: {
      '446000': 0.21563324716500185,
      '445000': 0.4264521765760248,
      '454000': 0.3579145762589734,
    },
  },
  ['47.22']: {
    label: 'Retail sale of meat and meat products',
    BEA_CODES: { '445000': 0.5436897663429324, '454000': 0.4563102336570676 },
  },
  ['47.23']: {
    label: 'Retail sale of fish, crustaceans and molluscs',
    BEA_CODES: { '445000': 0.5436897663429324, '454000': 0.4563102336570676 },
  },
  ['47.24']: {
    label: 'Retail sale of bread, cake and confectionery',
    BEA_CODES: { '445000': 0.5436897663429324, '454000': 0.4563102336570676 },
  },
  ['47.25']: {
    label: 'Retail sale of beverages',
    BEA_CODES: { '445000': 0.5436897663429324, '454000': 0.4563102336570676 },
  },
  ['47.26']: {
    label: 'Retail sale of tobacco products',
    BEA_CODES: {
      '445000': 0.35496876423321955,
      '4B0000': 0.3471115584520255,
      '454000': 0.29791967731475494,
    },
  },
  ['47.27']: {
    label: 'Retail sale of other food',
    BEA_CODES: {
      '446000': 0.21563324716500185,
      '445000': 0.4264521765760248,
      '454000': 0.3579145762589734,
    },
  },
  ['47.30']: {
    label: 'Retail sale of automotive fuel',
    BEA_CODES: { '447000': 1.0 },
  },
  ['47.40']: {
    label: 'Retail sale of information and communication equipment',
    BEA_CODES: {
      '445000': 0.18612056206475655,
      '448000': 0.12133845158982402,
      '446000': 0.09411086017764517,
      '454000': 0.15620804808487973,
      '441000': 0.15083820294948375,
      '444000': 0.10938307712567835,
      '4B0000': 0.18200079800773242,
    },
  },
  ['47.51']: {
    label: 'Retail sale of textiles',
    BEA_CODES: {
      '445000': 0.35496876423321955,
      '4B0000': 0.3471115584520255,
      '454000': 0.29791967731475494,
    },
  },
  ['47.52']: {
    label: 'Retail sale of hardware, building materials, paints and glass',
    BEA_CODES: {
      '445000': 0.2936987457042542,
      '444000': 0.17260678882919991,
      '454000': 0.24649671848442364,
      '4B0000': 0.28719774698212225,
    },
  },
  ['47.53']: {
    label: 'Retail sale of carpets, rugs, wall and floor coverings',
    BEA_CODES: {
      '445000': 0.18612056206475655,
      '448000': 0.12133845158982402,
      '446000': 0.09411086017764517,
      '454000': 0.15620804808487973,
      '441000': 0.15083820294948375,
      '444000': 0.10938307712567835,
      '4B0000': 0.18200079800773242,
    },
  },
  ['47.54']: {
    label: 'Retail sale of electrical household appliances',
    BEA_CODES: {
      '441000': 0.22340852918315124,
      '445000': 0.2756657147099152,
      '4B0000': 0.26956387571578705,
      '454000': 0.23136188039114652,
    },
  },
  ['47.55']: {
    label:
      'Retail sale of furniture, lighting equipment, tableware and other household goods',
    BEA_CODES: {
      '445000': 0.18612056206475655,
      '448000': 0.12133845158982402,
      '446000': 0.09411086017764517,
      '454000': 0.15620804808487973,
      '441000': 0.15083820294948375,
      '444000': 0.10938307712567835,
      '4B0000': 0.18200079800773242,
    },
  },
  ['47.69']: {
    label: 'Retail sale of cultural and recreational goods n.e.c.',
    BEA_CODES: {
      '445000': 0.18612056206475655,
      '448000': 0.12133845158982402,
      '446000': 0.09411086017764517,
      '454000': 0.15620804808487973,
      '441000': 0.15083820294948375,
      '444000': 0.10938307712567835,
      '4B0000': 0.18200079800773242,
    },
  },
  ['47.61']: {
    label: 'Retail sale of books',
    BEA_CODES: {
      '445000': 0.35496876423321955,
      '4B0000': 0.3471115584520255,
      '454000': 0.29791967731475494,
    },
  },
  ['47.62']: {
    label:
      'Retail sale of newspapers, and other periodical publications and stationery',
    BEA_CODES: {
      '445000': 0.35496876423321955,
      '4B0000': 0.3471115584520255,
      '454000': 0.29791967731475494,
    },
  },
  ['47.63']: {
    label: 'Retail sale of sporting equipment',
    BEA_CODES: {
      '448000': 0.12133845158982402,
      '445000': 0.18612056206475655,
      '446000': 0.09411086017764517,
      '454000': 0.15620804808487973,
      '441000': 0.15083820294948375,
      '444000': 0.10938307712567835,
      '4B0000': 0.18200079800773242,
    },
  },
  ['47.64']: {
    label: 'Retail sale of games and toys',
    BEA_CODES: {
      '445000': 0.18612056206475655,
      '448000': 0.12133845158982402,
      '446000': 0.09411086017764517,
      '454000': 0.15620804808487973,
      '441000': 0.15083820294948375,
      '444000': 0.10938307712567835,
      '4B0000': 0.18200079800773242,
    },
  },
  ['47.71']: {
    label: 'Retail sale of clothing',
    BEA_CODES: {
      '445000': 0.4014099284226055,
      '454000': 0.3368970129102135,
      '448000': 0.261693058667181,
    },
  },
  ['47.72']: {
    label: 'Retail sale of footwear and leather goods',
    BEA_CODES: {
      '445000': 0.4014099284226055,
      '454000': 0.3368970129102135,
      '448000': 0.261693058667181,
    },
  },
  ['47.73']: {
    label: 'Retail sale of pharmaceutical products',
    BEA_CODES: { '446000': 0.37596384879940953, '454000': 0.6240361512005905 },
  },
  ['47.74']: {
    label: 'Retail sale of medical and orthopaedic goods',
    BEA_CODES: {
      '445000': 0.18612056206475655,
      '448000': 0.12133845158982402,
      '446000': 0.09411086017764517,
      '454000': 0.15620804808487973,
      '441000': 0.15083820294948375,
      '444000': 0.10938307712567835,
      '4B0000': 0.18200079800773242,
    },
  },
  ['47.75']: {
    label: 'Retail sale of cosmetic and toilet articles',
    BEA_CODES: {
      '446000': 0.21563324716500185,
      '445000': 0.4264521765760248,
      '454000': 0.3579145762589734,
    },
  },
  ['47.76']: {
    label: 'Retail sale of flowers, plants, fertilisers, pets and pet food',
    BEA_CODES: {
      '448000': 0.12133845158982402,
      '445000': 0.18612056206475655,
      '446000': 0.09411086017764517,
      '454000': 0.15620804808487973,
      '441000': 0.15083820294948375,
      '444000': 0.10938307712567835,
      '4B0000': 0.18200079800773242,
    },
  },
  ['47.77']: {
    label: 'Retail sale of watches and jewellery',
    BEA_CODES: {
      '445000': 0.18612056206475655,
      '448000': 0.12133845158982402,
      '446000': 0.09411086017764517,
      '454000': 0.15620804808487973,
      '441000': 0.15083820294948375,
      '444000': 0.10938307712567835,
      '4B0000': 0.18200079800773242,
    },
  },
  ['47.78']: {
    label: 'Retail sale of other new goods',
    BEA_CODES: {
      '445000': 0.18612056206475655,
      '448000': 0.12133845158982402,
      '446000': 0.09411086017764517,
      '454000': 0.15620804808487973,
      '441000': 0.15083820294948375,
      '444000': 0.10938307712567835,
      '4B0000': 0.18200079800773242,
    },
  },
  ['47.79']: {
    label: 'Retail sale of second-hand goods',
    BEA_CODES: {
      '445000': 0.35496876423321955,
      '4B0000': 0.3471115584520255,
      '454000': 0.29791967731475494,
    },
  },
  ['47.92']: {
    label: 'Intermediation service activities for specialised retail sale',
    BEA_CODES: {
      GSLGO: 0.3536909767605877,
      S00600: 0.16717246150720794,
      '541800': 0.15428691072585313,
      '4B0000': 0.08872986243402467,
      '454000': 0.07615526288555079,
      '5419A0': 0.038441046712636984,
      '611B00': 0.03487924168145561,
      '561400': 0.034543855143679594,
      '323110': 0.03018670489434366,
      '561100': 0.021913677254659956,
    },
  },
  ['49.11']: {
    label: 'Passenger heavy rail transport',
    BEA_CODES: {
      '485000': 0.24329239725092502,
      '482000': 0.28741704264165,
      '48A000': 0.4692905601074249,
    },
  },
  ['49.12']: {
    label: 'Other passenger rail transport',
    BEA_CODES: {
      '485000': 0.24329239725092502,
      '482000': 0.28741704264165,
      '48A000': 0.4692905601074249,
    },
  },
  ['49.20']: {
    label: 'Freight rail transport',
    BEA_CODES: { '482000': 0.3798257630787381, '48A000': 0.6201742369212618 },
  },
  ['49.31']: {
    label: 'Scheduled passenger transport by road',
    BEA_CODES: { '485000': 0.3414232613039832, '48A000': 0.6585767386960169 },
  },
  ['49.34']: {
    label: 'Passenger transport by cableways and ski lifts',
    BEA_CODES: { '485000': 0.3414232613039832, '48A000': 0.6585767386960169 },
  },
  ['49.33']: {
    label:
      'On-demand passenger transport service activities by vehicle with driver',
    BEA_CODES: { '485000': 1.0 },
  },
  ['52.32']: {
    label: 'Intermediation service activities for passenger transportation',
    BEA_CODES: {
      GSLGO: 0.34385672682962276,
      S00600: 0.16252429156209416,
      '541800': 0.14999701886869793,
      '561300': 0.11476656810301299,
      '48A000': 0.059255574249733094,
      '5419A0': 0.037372207285512264,
      '611B00': 0.03390943695745739,
      '813B00': 0.03384003249433119,
      '561400': 0.03358337572129404,
      '713900': 0.03089476792824417,
    },
  },
  ['49.32']: {
    label: 'Non-scheduled passenger transport by road',
    BEA_CODES: { '485000': 0.3414232613039832, '48A000': 0.6585767386960169 },
  },
  ['49.39']: {
    label: 'Other passenger land transport n.e.c.',
    BEA_CODES: { '485000': 0.3414232613039832, '48A000': 0.6585767386960169 },
  },
  ['49.41']: {
    label: 'Freight transport by road',
    BEA_CODES: { '484000': 1.0 },
  },
  ['52.24']: {
    label: 'Cargo handling',
    BEA_CODES: { '484000': 0.6962739770507929, '48A000': 0.3037260229492071 },
  },
  ['49.42']: { label: 'Removal services', BEA_CODES: { '484000': 1.0 } },
  ['49.50']: { label: 'Transport via pipeline', BEA_CODES: { '486000': 1.0 } },
  ['50.10']: {
    label: 'Sea and coastal passenger water transport',
    BEA_CODES: { '483000': 0.2645259762034157, '48A000': 0.7354740237965843 },
  },
  ['50.20']: {
    label: 'Sea and coastal freight water transport',
    BEA_CODES: { '483000': 0.2645259762034157, '48A000': 0.7354740237965843 },
  },
  ['50.30']: {
    label: 'Inland passenger water transport',
    BEA_CODES: { '483000': 0.2645259762034157, '48A000': 0.7354740237965843 },
  },
  ['50.40']: {
    label: 'Inland freight water transport',
    BEA_CODES: { '483000': 0.2645259762034157, '48A000': 0.7354740237965843 },
  },
  ['51.10']: {
    label: 'Passenger air transport',
    BEA_CODES: { '48A000': 0.3966252618976354, '481000': 0.6033747381023645 },
  },
  ['51.21']: {
    label: 'Freight air transport',
    BEA_CODES: { '48A000': 0.3966252618976354, '481000': 0.6033747381023645 },
  },
  ['51.22']: {
    label: 'Space transport',
    BEA_CODES: { '48A000': 0.3966252618976354, '481000': 0.6033747381023645 },
  },
  ['35.24']: {
    label: 'Storage of gas as part of network supply services',
    BEA_CODES: { '493000': 1.0 },
  },
  ['52.10']: { label: 'Warehousing and storage', BEA_CODES: { '493000': 1.0 } },
  ['52.21']: {
    label: 'Service activities incidental to land transportation',
    BEA_CODES: {
      '811300': 0.07422285511743454,
      '211000': 0.36044971488623434,
      '482000': 0.096896395191931,
      '812900': 0.0791826531437135,
      '811100': 0.23103729176123822,
      '48A000': 0.15821108989944843,
    },
  },
  ['52.22']: {
    label: 'Service activities incidental to water transportation',
    BEA_CODES: {
      '211000': 0.6262546160145724,
      '483000': 0.09886536255026566,
      '48A000': 0.2748800214351619,
    },
  },
  ['52.23']: {
    label: 'Service activities incidental to air transportation',
    BEA_CODES: {
      '611B00': 0.18498528716705545,
      '48A000': 0.3232554239277928,
      '481000': 0.4917592889051518,
    },
  },
  ['52.25']: {
    label: 'Logistics service activities',
    BEA_CODES: { '48A000': 0.39504133257976165, '541610': 0.6049586674202384 },
  },
  ['52.26']: {
    label: 'Other support activities for transportation',
    BEA_CODES: { '48A000': 0.39504133257976165, '541610': 0.6049586674202384 },
  },
  ['52.31']: {
    label: 'Intermediation service activities for freight transportation',
    BEA_CODES: {
      GSLGO: 0.3574791031787962,
      S00600: 0.16896292397145413,
      '541800': 0.15593936544171094,
      '541610': 0.0943377495790612,
      '48A000': 0.06160306862815837,
      '5419A0': 0.038852760762934334,
      '611B00': 0.035252807832536814,
      '561400': 0.034913829213855316,
      '323110': 0.030510012702013097,
      '561100': 0.022148378689479605,
    },
  },
  ['53.10']: {
    label: 'Postal activities under universal service obligation',
    BEA_CODES: { '491000': 1.0 },
  },
  ['53.20']: {
    label: 'Other postal and courier activities',
    BEA_CODES: {
      '561700': 0.5865549694901231,
      '812300': 0.1062364256903506,
      '492000': 0.30720860481952633,
    },
  },
  ['55.10']: {
    label: 'Hotels and similar accommodation',
    BEA_CODES: { '721000': 1.0 },
  },
  ['55.20']: {
    label: 'Holiday and other short-stay accommodation',
    BEA_CODES: { '721000': 1.0 },
  },
  ['55.30']: {
    label: 'Camping grounds and recreational vehicle parks',
    BEA_CODES: { '721000': 1.0 },
  },
  ['55.90']: {
    label: 'Other accommodation',
    BEA_CODES: {
      '721000': 0.06257103027298161,
      '531HST': 0.15237547411645228,
      '531HSO': 0.4565544240793028,
      '531ORE': 0.32849907153126334,
    },
  },
  ['56.11']: {
    label: 'Restaurant activities',
    BEA_CODES: {
      '722A00': 0.20503367231452857,
      '722211': 0.40996607451017425,
      '722110': 0.3850002531752972,
    },
  },
  ['56.12']: {
    label: 'Mobile food service activities',
    BEA_CODES: {
      '722A00': 0.20503367231452857,
      '722211': 0.40996607451017425,
      '722110': 0.3850002531752972,
    },
  },
  ['56.21']: {
    label: 'Event catering activities',
    BEA_CODES: { '722A00': 1.0 },
  },
  ['56.22']: {
    label:
      'Contract catering service activities and other food service activities',
    BEA_CODES: {
      '722A00': 0.20503367231452857,
      '722211': 0.40996607451017425,
      '722110': 0.3850002531752972,
    },
  },
  ['56.30']: {
    label: 'Beverage serving activities',
    BEA_CODES: { '722A00': 1.0 },
  },
  ['58.11']: {
    label: 'Publishing of books',
    BEA_CODES: {
      '711500': 0.15772998366589938,
      '339990': 0.11979909526970095,
      '711A00': 0.11426809578278942,
      '711100': 0.13742297789012375,
      '519130': 0.23642080785543143,
      '5111A0': 0.04981665325105089,
      '5191A0': 0.03928186444108662,
      '511130': 0.14526052184391755,
    },
  },
  ['58.19']: {
    label: 'Other publishing activities, except software publishing',
    BEA_CODES: {
      '541300': 0.3218985906075656,
      '21311A': 0.14244926669635855,
      '518200': 0.12496508211452617,
      '5419A0': 0.09000905340837827,
      '812900': 0.07142656810754597,
      '323110': 0.07068165321743775,
      '519130': 0.05634540716217717,
      '5416A0': 0.05001363059625725,
      '711500': 0.03759127731588318,
      '511130': 0.03461947077387009,
    },
  },
  ['58.12']: {
    label: 'Publishing of newspapers',
    BEA_CODES: {
      '511110': 0.049139463179472036,
      '511120': 0.06431739073652412,
      '5191A0': 0.04738488793999239,
      '711100': 0.16577045159019027,
      '711A00': 0.13783920368404018,
      '519130': 0.28518945437820464,
      '5111A0': 0.06009278239292264,
      '711500': 0.1902663660986537,
    },
  },
  ['58.13']: {
    label: 'Publishing of journals and periodicals',
    BEA_CODES: {
      '711500': 0.20009912992589143,
      '511120': 0.06764124521518958,
      '711A00': 0.14496258741049942,
      '711100': 0.1743372925586867,
      '519130': 0.2999277426443803,
      '5111A0': 0.06319831359692339,
      '5191A0': 0.04983368864842915,
    },
  },
  ['60.39']: {
    label: 'Other content distribution activities',
    BEA_CODES: {
      '323110': 0.19289694446145367,
      '518200': 0.34104157736819546,
      '511120': 0.03467944400220439,
      '54151A': 0.22092339722001103,
      '339950': 0.024285101953340275,
      '519130': 0.15377196742391772,
      '5111A0': 0.03240156757087747,
    },
  },
  ['58.21']: {
    label: 'Publishing of video games',
    BEA_CODES: {
      '5111A0': 0.050653813754020525,
      '519130': 0.24039381988053302,
      '511200': 0.7089523663654465,
    },
  },
  ['58.29']: {
    label: 'Other software publishing',
    BEA_CODES: {
      '5111A0': 0.050653813754020525,
      '519130': 0.24039381988053302,
      '511200': 0.7089523663654465,
    },
  },
  ['59.11']: {
    label:
      'Motion picture, video and television programme production activities',
    BEA_CODES: {
      '512200': 0.0779100653413728,
      '512100': 0.5675152966082831,
      '515200': 0.10242915580529162,
      '515100': 0.2521454822450524,
    },
  },
  ['60.20']: {
    label:
      'Television programming, broadcasting and video distribution activities',
    BEA_CODES: {
      '517110': 0.31643388853466015,
      '517210': 0.192818234345956,
      '512100': 0.11067435402018558,
      '518200': 0.1074496397638232,
      '54151A': 0.0696048253995202,
      '812900': 0.06141522802096305,
      '515100': 0.049172310479308486,
      '519130': 0.048447883196470275,
      '517A00': 0.024008349723781953,
      '515200': 0.019975286515331137,
    },
  },
  ['59.12']: {
    label:
      'Motion picture, video and television programme post-production activities',
    BEA_CODES: { '512200': 0.12071119285741656, '512100': 0.8792888071425834 },
  },
  ['59.13']: {
    label: 'Motion picture and video distribution activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['59.14']: {
    label: 'Motion picture projection activities',
    BEA_CODES: { '512100': 1.0 },
  },
  ['59.20']: {
    label: 'Sound recording and music publishing activities',
    BEA_CODES: {
      '541800': 0.3674837181223831,
      '512100': 0.13093288568393016,
      '5419A0': 0.09155967092747647,
      '611B00': 0.0830760908886533,
      '561400': 0.08227726037652305,
      '515100': 0.058173120266215975,
      '519130': 0.057316089245344815,
      '561100': 0.05219444447614407,
      '561900': 0.038747844584100306,
      '711500': 0.038238875429228755,
    },
  },
  ['60.10']: {
    label: 'Radio broadcasting and audio distribution activities',
    BEA_CODES: {
      '512200': 0.017909731587122186,
      '517A00': 0.028300127577416297,
      '512100': 0.1304587101718543,
      '515200': 0.02354610638728501,
      '812900': 0.07239392995294872,
      '517210': 0.22728678539022543,
      '515100': 0.05796244539299984,
      '519130': 0.05710851812349767,
      '5111A0': 0.012033438472891504,
      '517110': 0.37300020694375907,
    },
  },
  ['61.10']: {
    label: 'Wired, wireless, and satellite telecommunication activities',
    BEA_CODES: {
      '517A00': 0.03616677783057458,
      '515200': 0.030091270654326745,
      '812900': 0.09251743384294356,
      '517210': 0.29046620544543306,
      '515100': 0.07407439699029168,
      '517110': 0.4766839152364304,
    },
  },
  ['61.90']: {
    label: 'Other telecommunication activities',
    BEA_CODES: {
      '517A00': 0.03616677783057458,
      '515200': 0.030091270654326745,
      '812900': 0.09251743384294356,
      '517210': 0.29046620544543306,
      '515100': 0.07407439699029168,
      '517110': 0.4766839152364304,
    },
  },
  ['61.20']: {
    label:
      'Telecommunication reselling activities and intermediation service activities for telecommunication',
    BEA_CODES: {
      GSLGO: 0.3225192540592118,
      S00600: 0.15243910963847632,
      '517110': 0.14332019146582503,
      '541800': 0.14068919658101733,
      '517210': 0.08733181642628583,
      '5419A0': 0.03505313543638165,
      '611B00': 0.03180524171773995,
      '561400': 0.031499413683969545,
      '812900': 0.02781637036299166,
      '323110': 0.02752627062810088,
    },
  },
  ['62.10']: {
    label: 'Computer programming activities',
    BEA_CODES: {
      '511200': 0.23693475656281443,
      '541512': 0.22746504444526203,
      '541511': 0.3229059859138032,
      '54151A': 0.11542491334093682,
      '519130': 0.08034058971352522,
      '5111A0': 0.016928710023658283,
    },
  },
  ['62.20']: {
    label: 'Computer consultancy and computer facilities management activities',
    BEA_CODES: {
      '518200': 0.21112214611439423,
      '54151A': 0.13676286072772897,
      '541512': 0.2695152137737058,
      '541511': 0.382599779384171,
    },
  },
  ['62.90']: {
    label: 'Other information technology and computer service activities',
    BEA_CODES: {
      '334118': 0.020857081465349276,
      '334112': 0.015794859842014983,
      '333316': 0.0033541770612164627,
      '334111': 0.015066482630024436,
      '541512': 0.25893809886263897,
      '541511': 0.36758466474617874,
      '334418': 0.03044616746120481,
      '54151A': 0.1313956071570345,
      '3259A0': 0.07042315074130591,
      '811200': 0.0861397100330319,
    },
  },
  ['63.10']: {
    label:
      'Computing infrastructure, data processing, hosting and related activities',
    BEA_CODES: {
      '5111A0': 0.04330958392843258,
      '54151A': 0.29529745413472913,
      '519130': 0.20553943615284195,
      '518200': 0.45585352578399635,
    },
  },
  ['63.91']: {
    label: 'Web search portal activities',
    BEA_CODES: { '5111A0': 0.17403960005262464, '519130': 0.8259603999473754 },
  },
  ['60.31']: {
    label: 'News agency activities',
    BEA_CODES: { '711500': 0.800611664635749, '5191A0': 0.19938833536425107 },
  },
  ['63.92']: {
    label: 'Other information service activities',
    BEA_CODES: {
      '541800': 0.44297452221413663,
      '5419A0': 0.11036843126115109,
      '611B00': 0.10014210114354967,
      '561400': 0.09917916987070584,
      '561100': 0.06291655375151146,
      '561900': 0.04670766919734176,
      '711500': 0.04609414441492984,
      '541400': 0.03655699796546378,
      '711A00': 0.033393080926119784,
      '512200': 0.021667329255090125,
    },
  },
  ['64.11']: {
    label: 'Central banking',
    BEA_CODES: { '52A000': 0.5817258632009072, '522A00': 0.4182741367990928 },
  },
  ['64.19']: {
    label: 'Other monetary intermediation',
    BEA_CODES: { '52A000': 1.0 },
  },
  ['64.21']: {
    label: 'Activities of holding companies',
    BEA_CODES: {
      '531HST': 0.11013938594887657,
      '550000': 0.12039494624770812,
      '531HSO': 0.3300047085130485,
      '523900': 0.08549842622928777,
      '533000': 0.040406850692559955,
      '525000': 0.036130403833210555,
      '523A00': 0.03998099012194816,
      '531ORE': 0.23744428841336038,
    },
  },
  ['64.22']: {
    label: 'Activities of financing conduits',
    BEA_CODES: {
      '531HST': 0.10252248245160532,
      '550000': 0.11206879952710073,
      '522A00': 0.06915694537108441,
      '531HSO': 0.30718259091421096,
      '523900': 0.07958561623723523,
      '533000': 0.03761243632659702,
      '525000': 0.033631735469082,
      '523A00': 0.03721602697220273,
      '531ORE': 0.2210233667308816,
    },
  },
  ['64.32']: {
    label: 'Activities of trust, estate and agency accounts',
    BEA_CODES: {
      '531HST': 0.11013938594887657,
      '550000': 0.12039494624770812,
      '531HSO': 0.3300047085130485,
      '523900': 0.08549842622928777,
      '533000': 0.040406850692559955,
      '525000': 0.036130403833210555,
      '523A00': 0.03998099012194816,
      '531ORE': 0.23744428841336038,
    },
  },
  ['64.31']: {
    label: 'Activities of money market and non-money market investments funds',
    BEA_CODES: {
      '531HST': 0.11013938594887657,
      '550000': 0.12039494624770812,
      '531HSO': 0.3300047085130485,
      '523900': 0.08549842622928777,
      '533000': 0.040406850692559955,
      '525000': 0.036130403833210555,
      '523A00': 0.03998099012194816,
      '531ORE': 0.23744428841336038,
    },
  },
  ['64.91']: { label: 'Financial leasing', BEA_CODES: { '522A00': 1.0 } },
  ['64.92']: {
    label: 'Other credit granting',
    BEA_CODES: {
      '531HST': 0.10252248245160532,
      '550000': 0.11206879952710073,
      '522A00': 0.06915694537108441,
      '531HSO': 0.30718259091421096,
      '523900': 0.07958561623723523,
      '533000': 0.03761243632659702,
      '525000': 0.033631735469082,
      '523A00': 0.03721602697220273,
      '531ORE': 0.2210233667308816,
    },
  },
  ['64.99']: {
    label:
      'Other financial service activities, except insurance and pension funding n.e.c.',
    BEA_CODES: {
      '531HST': 0.10252248245160532,
      '550000': 0.11206879952710073,
      '522A00': 0.06915694537108441,
      '531HSO': 0.30718259091421096,
      '523900': 0.07958561623723523,
      '533000': 0.03761243632659702,
      '525000': 0.033631735469082,
      '523A00': 0.03721602697220273,
      '531ORE': 0.2210233667308816,
    },
  },
  ['65.11']: {
    label: 'Life insurance',
    BEA_CODES: {
      '5241XX': 0.6600685020836309,
      '525000': 0.21695379990932634,
      '524113': 0.12297769800704274,
    },
  },
  ['65.12']: {
    label: 'Non-life insurance',
    BEA_CODES: {
      '5241XX': 0.6600685020836309,
      '525000': 0.21695379990932634,
      '524113': 0.12297769800704274,
    },
  },
  ['65.20']: {
    label: 'Reinsurance',
    BEA_CODES: {
      '5241XX': 0.43615052373477786,
      '525000': 0.14335559590861058,
      '524113': 0.08125942568711335,
      '523900': 0.3392344546694982,
    },
  },
  ['65.30']: {
    label: 'Pension funding',
    BEA_CODES: { '525000': 0.29705460304637593, '523900': 0.7029453969536241 },
  },
  ['66.11']: {
    label: 'Administration of financial markets',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['66.12']: {
    label: 'Security and commodity contracts brokerage',
    BEA_CODES: {
      '524200': 0.27786584317214874,
      '523A00': 0.14452123165269667,
      '523900': 0.3090553241761939,
      '522A00': 0.26855760099896064,
    },
  },
  ['66.19']: {
    label:
      'Other activities auxiliary to financial services, except insurance and pension funding',
    BEA_CODES: {
      '523A00': 0.20013072402992416,
      '523900': 0.427974942403769,
      '522A00': 0.37189433356630686,
    },
  },
  ['66.30']: {
    label: 'Fund management activities',
    BEA_CODES: {
      '522A00': 0.26855760099896064,
      '523A00': 0.14452123165269667,
      '523900': 0.3090553241761939,
      '524200': 0.27786584317214874,
    },
  },
  ['66.21']: {
    label: 'Risk and damage evaluation',
    BEA_CODES: {
      '561300': 0.33363485071504306,
      '541610': 0.2637961128631919,
      '524200': 0.40256903642176506,
    },
  },
  ['66.22']: {
    label: 'Activities of insurance agents and brokers',
    BEA_CODES: {
      '561300': 0.33363485071504306,
      '541610': 0.2637961128631919,
      '524200': 0.40256903642176506,
    },
  },
  ['66.29']: {
    label: 'Activities auxiliary to insurance and pension funding n.e.c.',
    BEA_CODES: {
      '561300': 0.33363485071504306,
      '541610': 0.2637961128631919,
      '524200': 0.40256903642176506,
    },
  },
  ['68.11']: {
    label: 'Buying and selling of own real estate',
    BEA_CODES: { '523900': 1.0 },
  },
  ['68.20']: {
    label: 'Rental and operating of own or leased real estate',
    BEA_CODES: {
      '531HST': 0.1625461544684547,
      '531HSO': 0.48702828568681056,
      '531ORE': 0.3504255598447348,
    },
  },
  ['68.31']: {
    label: 'Intermediation service activities for real estate activities',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['68.32']: {
    label: 'Other real estate activities on a fee or contract basis',
    BEA_CODES: { '561200': 0.027814478392513074, '531ORE': 0.972185521607487 },
  },
  ['69.10']: {
    label: 'Legal activities',
    BEA_CODES: { '541100': 0.7927469705854242, '5419A0': 0.2072530294145758 },
  },
  ['69.20']: {
    label: 'Accounting, bookkeeping and auditing activities; tax consultancy',
    BEA_CODES: { '541200': 1.0 },
  },
  ['70.10']: {
    label: 'Activities of head offices',
    BEA_CODES: { '550000': 1.0 },
  },
  ['73.30']: {
    label: 'Public relations and communication activities',
    BEA_CODES: {
      '611B00': 0.08266929217016261,
      '561300': 0.2797944112660446,
      '5416A0': 0.05062611217668094,
      '541800': 0.3656842604926453,
      '541610': 0.22122592389446658,
    },
  },
  ['70.20']: {
    label: 'Business and other management consultancy activities',
    BEA_CODES: {
      '611B00': 0.07428230910044509,
      '561300': 0.25140864759626863,
      '5416A0': 0.04548998079627109,
      '5419A0': 0.08186788405927238,
      '114000': 0.008817184990479766,
      '311700': 0.010767149174811076,
      '541800': 0.3285847810958574,
      '541610': 0.19878206318659453,
    },
  },
  ['71.11']: {
    label: 'Architectural activities',
    BEA_CODES: {
      '21311A': 0.22461820954294823,
      '5419A0': 0.14192893293260003,
      '5416A0': 0.07886296937715927,
      '541400': 0.04701068641793606,
      '541300': 0.5075792017293564,
    },
  },
  ['71.12']: {
    label: 'Engineering activities and related technical consultancy',
    BEA_CODES: {
      '21311A': 0.22461820954294823,
      '5419A0': 0.14192893293260003,
      '5416A0': 0.07886296937715927,
      '541400': 0.04701068641793606,
      '541300': 0.5075792017293564,
    },
  },
  ['71.20']: {
    label: 'Technical testing and analysis',
    BEA_CODES: { '811100': 0.39299389467359985, '541300': 0.6070061053264001 },
  },
  ['72.10']: {
    label:
      'Research and experimental development on natural sciences and engineering',
    BEA_CODES: { '541700': 1.0 },
  },
  ['72.20']: {
    label:
      'Research and experimental development on social sciences and humanities',
    BEA_CODES: { '541700': 1.0 },
  },
  ['73.11']: {
    label: 'Activities of advertising agencies',
    BEA_CODES: { '541800': 1.0 },
  },
  ['73.12']: { label: 'Media representation', BEA_CODES: { '541800': 1.0 } },
  ['73.20']: {
    label: 'Market research and public opinion polling',
    BEA_CODES: { '5419A0': 1.0 },
  },
  ['74.11']: {
    label: 'Industrial product and fashion design activities',
    BEA_CODES: {
      '541800': 0.44297452221413663,
      '5419A0': 0.11036843126115109,
      '611B00': 0.10014210114354967,
      '561400': 0.09917916987070584,
      '561100': 0.06291655375151146,
      '561900': 0.04670766919734176,
      '711500': 0.04609414441492984,
      '541400': 0.03655699796546378,
      '711A00': 0.033393080926119784,
      '512200': 0.021667329255090125,
    },
  },
  ['74.12']: {
    label: 'Graphic design and visual communication activities',
    BEA_CODES: {
      '541800': 0.44297452221413663,
      '5419A0': 0.11036843126115109,
      '611B00': 0.10014210114354967,
      '561400': 0.09917916987070584,
      '561100': 0.06291655375151146,
      '561900': 0.04670766919734176,
      '711500': 0.04609414441492984,
      '541400': 0.03655699796546378,
      '711A00': 0.033393080926119784,
      '512200': 0.021667329255090125,
    },
  },
  ['74.13']: {
    label: 'Interior design activities',
    BEA_CODES: {
      '541800': 0.44297452221413663,
      '5419A0': 0.11036843126115109,
      '611B00': 0.10014210114354967,
      '561400': 0.09917916987070584,
      '561100': 0.06291655375151146,
      '561900': 0.04670766919734176,
      '711500': 0.04609414441492984,
      '541400': 0.03655699796546378,
      '711A00': 0.033393080926119784,
      '512200': 0.021667329255090125,
    },
  },
  ['74.14']: {
    label: 'Other specialised design activities',
    BEA_CODES: {
      '541800': 0.44297452221413663,
      '5419A0': 0.11036843126115109,
      '611B00': 0.10014210114354967,
      '561400': 0.09917916987070584,
      '561100': 0.06291655375151146,
      '561900': 0.04670766919734176,
      '711500': 0.04609414441492984,
      '541400': 0.03655699796546378,
      '711A00': 0.033393080926119784,
      '512200': 0.021667329255090125,
    },
  },
  ['74.20']: {
    label: 'Photographic activities',
    BEA_CODES: {
      '711500': 0.04222906127675234,
      '21311A': 0.16002379386047824,
      '5419A0': 0.10111382488827723,
      '5416A0': 0.056184009215079864,
      '518200': 0.14038251655362063,
      '541400': 0.033491622998379295,
      '5191A0': 0.010516936742106311,
      '812900': 0.0802387451763241,
      '541300': 0.3616124877438934,
      '541920': 0.01420700154508861,
    },
  },
  ['74.30']: {
    label: 'Translation and interpretation activities',
    BEA_CODES: {
      '541800': 0.5992690093848922,
      '561400': 0.13417250857443802,
      '323110': 0.11724881037232508,
      '5419A0': 0.14930967166834463,
    },
  },
  ['74.91']: {
    label: 'Patent brokering and marketing service activities',
    BEA_CODES: {
      '541800': 0.20352571699080332,
      '541300': 0.18135030713505157,
      '561300': 0.15572274859799912,
      '541610': 0.12312579359285755,
      '48A000': 0.08040181948839688,
      '21311A': 0.08025266037291043,
      '5419A0': 0.050709043024188424,
      '611B00': 0.046010530886365406,
      '561400': 0.045568109781109,
      '561600': 0.033333270130318296,
    },
  },
  ['74.99']: {
    label: 'All other professional, scientific and technical activities n.e.c.',
    BEA_CODES: {
      '541800': 0.20352571699080332,
      '541300': 0.18135030713505157,
      '561300': 0.15572274859799912,
      '541610': 0.12312579359285755,
      '48A000': 0.08040181948839688,
      '21311A': 0.08025266037291043,
      '5419A0': 0.050709043024188424,
      '611B00': 0.046010530886365406,
      '561400': 0.045568109781109,
      '561600': 0.033333270130318296,
    },
  },
  ['80.09']: {
    label: 'Security activities n.e.c.',
    BEA_CODES: {
      '541800': 0.1698362284658259,
      '541300': 0.1513315007573616,
      '561300': 0.1299460564455902,
      '541610': 0.10274485563717527,
      '230301': 0.09291866906884606,
      '2334A0': 0.08911973064086715,
      '233411': 0.07014297046078807,
      '48A000': 0.06709295506039906,
      '21311A': 0.06696848616285594,
      '233240': 0.059898547300290705,
    },
  },
  ['75.00']: {
    label: 'Veterinary activities',
    BEA_CODES: { '115000': 0.5205538646526133, '541940': 0.4794461353473866 },
  },
  ['77.11']: {
    label: 'Rental and leasing of cars and light motor vehicles',
    BEA_CODES: {
      '532A00': 0.15961510142473218,
      '532100': 0.45596951735817104,
      '532400': 0.3844153812170968,
    },
  },
  ['77.12']: {
    label: 'Rental and leasing of trucks',
    BEA_CODES: {
      '532A00': 0.15961510142473218,
      '532100': 0.45596951735817104,
      '532400': 0.3844153812170968,
    },
  },
  ['77.21']: {
    label: 'Rental and leasing of recreational and sports goods',
    BEA_CODES: { '532A00': 0.29339367281339873, '532400': 0.7066063271866012 },
  },
  ['77.22']: {
    label: 'Rental and leasing of other personal and household goods',
    BEA_CODES: { '532A00': 0.29339367281339873, '532400': 0.7066063271866012 },
  },
  ['77.31']: {
    label: 'Rental and leasing of agricultural machinery and equipment',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77.32']: {
    label:
      'Rental and leasing of construction and civil engineering machinery and equipment',
    BEA_CODES: { '532A00': 0.29339367281339873, '532400': 0.7066063271866012 },
  },
  ['77.33']: {
    label: 'Rental and leasing of office machinery, equipment and computers',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77.34']: {
    label: 'Rental and leasing of water transport equipment',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77.35']: {
    label: 'Rental and leasing of air transport equipment',
    BEA_CODES: { '532400': 1.0 },
  },
  ['77.39']: {
    label:
      'Rental and leasing of other machinery, equipment and tangible goods n.e.c.',
    BEA_CODES: {
      '532A00': 0.15961510142473218,
      '532100': 0.45596951735817104,
      '532400': 0.3844153812170968,
    },
  },
  ['77.40']: {
    label:
      'Leasing of intellectual property and similar products, except copyrighted works',
    BEA_CODES: {
      '541800': 0.44297452221413663,
      '5419A0': 0.11036843126115109,
      '611B00': 0.10014210114354967,
      '561400': 0.09917916987070584,
      '561100': 0.06291655375151146,
      '561900': 0.04670766919734176,
      '711500': 0.04609414441492984,
      '541400': 0.03655699796546378,
      '711A00': 0.033393080926119784,
      '512200': 0.021667329255090125,
    },
  },
  ['78.10']: {
    label: 'Activities of employment placement agencies',
    BEA_CODES: {
      '561300': 0.45294940592730676,
      '711A00': 0.04462668924198052,
      '713900': 0.12193243215915964,
      '713100': 0.022356546565261592,
      '541610': 0.3581349261062915,
    },
  },
  ['78.20']: {
    label:
      'Temporary employment agency activities and other human resource provisions',
    BEA_CODES: { '561300': 0.558449211799771, '541610': 0.4415507882002289 },
  },
  ['55.40']: {
    label: 'Intermediation service activities for accommodation',
    BEA_CODES: {
      GSLGO: 0.34385672682962276,
      S00600: 0.16252429156209416,
      '541800': 0.14999701886869793,
      '561300': 0.11476656810301299,
      '48A000': 0.059255574249733094,
      '5419A0': 0.037372207285512264,
      '611B00': 0.03390943695745739,
      '813B00': 0.03384003249433119,
      '561400': 0.03358337572129404,
      '713900': 0.03089476792824417,
    },
  },
  ['77.51']: {
    label:
      'Intermediation service activities for rental and leasing of cars, motorhomes and trailers',
    BEA_CODES: {
      GSLGO: 0.34385672682962276,
      S00600: 0.16252429156209416,
      '541800': 0.14999701886869793,
      '561300': 0.11476656810301299,
      '48A000': 0.059255574249733094,
      '5419A0': 0.037372207285512264,
      '611B00': 0.03390943695745739,
      '813B00': 0.03384003249433119,
      '561400': 0.03358337572129404,
      '713900': 0.03089476792824417,
    },
  },
  ['79.11']: {
    label: 'Travel agency activities',
    BEA_CODES: {
      '713900': 0.28685607027164955,
      '48A000': 0.5501844587551953,
      '561500': 0.16295947097315508,
    },
  },
  ['79.12']: {
    label: 'Tour operator activities',
    BEA_CODES: {
      '713900': 0.28685607027164955,
      '48A000': 0.5501844587551953,
      '561500': 0.16295947097315508,
    },
  },
  ['56.40']: {
    label:
      'Intermediation service activities for food and beverage services activities',
    BEA_CODES: {
      GSLGO: 0.34385672682962276,
      S00600: 0.16252429156209416,
      '541800': 0.14999701886869793,
      '561300': 0.11476656810301299,
      '48A000': 0.059255574249733094,
      '5419A0': 0.037372207285512264,
      '611B00': 0.03390943695745739,
      '813B00': 0.03384003249433119,
      '561400': 0.03358337572129404,
      '713900': 0.03089476792824417,
    },
  },
  ['79.90']: {
    label: 'Other reservation service and related activities',
    BEA_CODES: {
      '813B00': 0.10067068523522484,
      '611B00': 0.10087715651631678,
      '561300': 0.3414189704150211,
      '711A00': 0.03363819025843553,
      '711100': 0.04045451396106146,
      '711200': 0.0456883530797478,
      '713900': 0.09190882006512853,
      '561500': 0.0522122912769348,
      '713100': 0.016851659391671863,
      '48A000': 0.17627935980045728,
    },
  },
  ['82.40']: {
    label:
      'Intermediation service activities for business support service activities n.e.c.',
    BEA_CODES: {
      GSLGO: 0.34385672682962276,
      S00600: 0.16252429156209416,
      '541800': 0.14999701886869793,
      '561300': 0.11476656810301299,
      '48A000': 0.059255574249733094,
      '5419A0': 0.037372207285512264,
      '611B00': 0.03390943695745739,
      '813B00': 0.03384003249433119,
      '561400': 0.03358337572129404,
      '713900': 0.03089476792824417,
    },
  },
  ['80.01']: {
    label: 'Investigation and private security activities',
    BEA_CODES: {
      '5416A0': 0.2107778938758528,
      '561900': 0.1605336939202958,
      '561600': 0.2493534492948224,
      '5419A0': 0.379334962909029,
    },
  },
  ['81.10']: {
    label: 'Combined facilities support activities',
    BEA_CODES: {
      S00600: 0.1710322630612507,
      '561200': 0.012878656419818807,
      GSLGO: 0.361857255880026,
      '5191A0': 0.004090602056232176,
      '531ORE': 0.4501412225826723,
    },
  },
  ['81.21']: {
    label: 'General cleaning of buildings',
    BEA_CODES: {
      '230301': 0.14804385712821186,
      '2334A0': 0.1419911499219358,
      '561700': 0.1191424533169306,
      '233411': 0.11175618421472783,
      '48A000': 0.10689670819447315,
      '233240': 0.09543412607579875,
      '2332D0': 0.07874233432993347,
      '233262': 0.06853936738579854,
      '2332A0': 0.06797132211696018,
      '230302': 0.06148249731522983,
    },
  },
  ['96.91']: {
    label: 'Provision of domestic personal service activities',
    BEA_CODES: {
      '230301': 0.14804385712821186,
      '2334A0': 0.1419911499219358,
      '561700': 0.1191424533169306,
      '233411': 0.11175618421472783,
      '48A000': 0.10689670819447315,
      '233240': 0.09543412607579875,
      '2332D0': 0.07874233432993347,
      '233262': 0.06853936738579854,
      '2332A0': 0.06797132211696018,
      '230302': 0.06148249731522983,
    },
  },
  ['81.23']: {
    label: 'Other cleaning activities',
    BEA_CODES: {
      '230301': 0.14518229059367022,
      '2334A0': 0.13924657726151254,
      '561700': 0.11683952725253029,
      '233411': 0.1095960286839247,
      '48A000': 0.10483048235602413,
      '233240': 0.09358946256372054,
      '562111': 0.07962327214953087,
      '2332D0': 0.07722030948445079,
      '233262': 0.06721455753678197,
      '2332A0': 0.06665749211785395,
    },
  },
  ['81.30']: {
    label: 'Landscape service activities',
    BEA_CODES: {
      '115000': 0.11590025893189547,
      '221300': 0.2910034761293237,
      '561700': 0.5930962649387808,
    },
  },
  ['82.10']: {
    label: 'Office administrative and support activities',
    BEA_CODES: {
      '212310': 0.017102602075487534,
      '323110': 0.08528829675194052,
      '5419A0': 0.10860978072823459,
      '21311A': 0.17188697175376802,
      '491000': 0.08359752977455538,
      '541800': 0.43591600583713946,
      '561400': 0.09759881307887448,
    },
  },
  ['82.20']: {
    label: 'Activities of call centres',
    BEA_CODES: { '561400': 1.0 },
  },
  ['82.30']: {
    label: 'Organisation of conventions and trade shows',
    BEA_CODES: {
      '541800': 0.44297452221413663,
      '5419A0': 0.11036843126115109,
      '611B00': 0.10014210114354967,
      '561400': 0.09917916987070584,
      '561100': 0.06291655375151146,
      '561900': 0.04670766919734176,
      '711500': 0.04609414441492984,
      '541400': 0.03655699796546378,
      '711A00': 0.033393080926119784,
      '512200': 0.021667329255090125,
    },
  },
  ['93.29']: {
    label: 'Amusement and recreation activities n.e.c.',
    BEA_CODES: {
      '541800': 0.28169813714040776,
      '561300': 0.21553447318120783,
      '48A000': 0.1112834442126258,
      '5419A0': 0.07018593604429924,
      '611B00': 0.06368276712724219,
      '813B00': 0.0635524238169595,
      '561400': 0.06307041600510876,
      '713900': 0.05802114360945995,
      '561100': 0.04001014753288107,
      '561500': 0.03296111132980794,
    },
  },
  ['82.91']: {
    label: 'Activities of collection agencies and credit bureaus',
    BEA_CODES: {
      GSLGO: 0.40774231179027287,
      S00600: 0.1927198893987021,
      '541800': 0.1778651585474908,
      '5419A0': 0.04431563789895093,
      '611B00': 0.04020951500358747,
      '561400': 0.03982287442963611,
      '323110': 0.03479986103033085,
      '561100': 0.0252625427583049,
      '561900': 0.018754277211349337,
      '711500': 0.01850793193137461,
    },
  },
  ['82.92']: {
    label: 'Packaging activities',
    BEA_CODES: { '561900': 0.4675056452057058, '3259A0': 0.5324943547942942 },
  },
  ['43.60']: {
    label:
      'Intermediation service activities for specialised construction services',
    BEA_CODES: {
      GSLGO: 0.40774231179027287,
      S00600: 0.1927198893987021,
      '541800': 0.1778651585474908,
      '5419A0': 0.04431563789895093,
      '611B00': 0.04020951500358747,
      '561400': 0.03982287442963611,
      '323110': 0.03479986103033085,
      '561100': 0.0252625427583049,
      '561900': 0.018754277211349337,
      '711500': 0.01850793193137461,
    },
  },
  ['53.30']: {
    label:
      'Intermediation service activities for postal and courier activities',
    BEA_CODES: {
      GSLGO: 0.40774231179027287,
      S00600: 0.1927198893987021,
      '541800': 0.1778651585474908,
      '5419A0': 0.04431563789895093,
      '611B00': 0.04020951500358747,
      '561400': 0.03982287442963611,
      '323110': 0.03479986103033085,
      '561100': 0.0252625427583049,
      '561900': 0.018754277211349337,
      '711500': 0.01850793193137461,
    },
  },
  ['77.52']: {
    label:
      'Intermediation service activities for rental and leasing of other tangible goods and non-financial intangible assets',
    BEA_CODES: {
      GSLGO: 0.40774231179027287,
      S00600: 0.1927198893987021,
      '541800': 0.1778651585474908,
      '5419A0': 0.04431563789895093,
      '611B00': 0.04020951500358747,
      '561400': 0.03982287442963611,
      '323110': 0.03479986103033085,
      '561100': 0.0252625427583049,
      '561900': 0.018754277211349337,
      '711500': 0.01850793193137461,
    },
  },
  ['82.99']: {
    label: 'Other business support service activities n.e.c.',
    BEA_CODES: {
      GSLGO: 0.40774231179027287,
      S00600: 0.1927198893987021,
      '541800': 0.1778651585474908,
      '5419A0': 0.04431563789895093,
      '611B00': 0.04020951500358747,
      '561400': 0.03982287442963611,
      '323110': 0.03479986103033085,
      '561100': 0.0252625427583049,
      '561900': 0.018754277211349337,
      '711500': 0.01850793193137461,
    },
  },
  ['85.61']: {
    label: 'Intermediation service activities for courses and tutors',
    BEA_CODES: {
      GSLGO: 0.40774231179027287,
      S00600: 0.1927198893987021,
      '541800': 0.1778651585474908,
      '5419A0': 0.04431563789895093,
      '611B00': 0.04020951500358747,
      '561400': 0.03982287442963611,
      '323110': 0.03479986103033085,
      '561100': 0.0252625427583049,
      '561900': 0.018754277211349337,
      '711500': 0.01850793193137461,
    },
  },
  ['86.97']: {
    label:
      'Intermediation service activities for medical, dental and other human health services',
    BEA_CODES: {
      GSLGO: 0.40774231179027287,
      S00600: 0.1927198893987021,
      '541800': 0.1778651585474908,
      '5419A0': 0.04431563789895093,
      '611B00': 0.04020951500358747,
      '561400': 0.03982287442963611,
      '323110': 0.03479986103033085,
      '561100': 0.0252625427583049,
      '561900': 0.018754277211349337,
      '711500': 0.01850793193137461,
    },
  },
  ['87.91']: {
    label: 'Intermediation service activities for residential care activities',
    BEA_CODES: {
      GSLGO: 0.40774231179027287,
      S00600: 0.1927198893987021,
      '541800': 0.1778651585474908,
      '5419A0': 0.04431563789895093,
      '611B00': 0.04020951500358747,
      '561400': 0.03982287442963611,
      '323110': 0.03479986103033085,
      '561100': 0.0252625427583049,
      '561900': 0.018754277211349337,
      '711500': 0.01850793193137461,
    },
  },
  ['96.40']: {
    label: 'Intermediation service activities for personal services',
    BEA_CODES: {
      GSLGO: 0.40774231179027287,
      S00600: 0.1927198893987021,
      '541800': 0.1778651585474908,
      '5419A0': 0.04431563789895093,
      '611B00': 0.04020951500358747,
      '561400': 0.03982287442963611,
      '323110': 0.03479986103033085,
      '561100': 0.0252625427583049,
      '561900': 0.018754277211349337,
      '711500': 0.01850793193137461,
    },
  },
  ['84.11']: {
    label: 'General public administration activities',
    BEA_CODES: {
      '561400': 0.025050171221453827,
      GSLGE: 0.24971648395632742,
      S00600: 0.12122847223766427,
      GSLGH: 0.02077901592137155,
      GSLGO: 0.2564861243912744,
      S00102: 0.004778079206599809,
      '5191A0': 0.002899437971136955,
      '531ORE': 0.3190622150941718,
    },
  },
  ['84.12']: {
    label:
      'Regulation of health care, education, cultural services and other social services',
    BEA_CODES: {
      GSLGE: 0.38524004069376655,
      GSLGH: 0.032055989305558086,
      S00600: 0.18702033937915205,
      GSLGO: 0.39568363062152334,
    },
  },
  ['84.13']: {
    label:
      'Regulation of and contribution to more efficient operation of businesses',
    BEA_CODES: {
      '561900': 0.030287109380226586,
      S00600: 0.31123184883027033,
      GSLGO: 0.6584810417895031,
    },
  },
  ['84.21']: {
    label: 'Foreign affairs',
    BEA_CODES: {
      S00500: 0.3651336031297583,
      '624A00': 0.02450232890310236,
      GSLGO: 0.41446614893301653,
      S00600: 0.19589791903412282,
    },
  },
  ['84.22']: {
    label: 'Defence activities',
    BEA_CODES: {
      S00500: 0.3743049460273791,
      S00600: 0.20081843846315445,
      GSLGO: 0.4248766155094664,
    },
  },
  ['84.23']: {
    label: 'Justice and judicial activities',
    BEA_CODES: {
      S00600: 0.18733372670697404,
      S00203: 0.04565908250162012,
      '561200': 0.014106149675627818,
      S00500: 0.34917082814099815,
      GSLGO: 0.3963466720643687,
      S00102: 0.007383540910411212,
    },
  },
  ['84.24']: {
    label: 'Public order and safety activities',
    BEA_CODES: {
      S00600: 0.16433502372148207,
      S00203: 0.040053579982111745,
      S00500: 0.30630360765285675,
      '541300': 0.1351429765055358,
      GSLGO: 0.34768773835108735,
      S00102: 0.006477073786926315,
    },
  },
  ['84.25']: {
    label: 'Fire service activities',
    BEA_CODES: {
      GSLGO: 0.40357519103020456,
      S00600: 0.19075029480729144,
      '561300': 0.13469836717489603,
      '48A000': 0.06954663914220782,
      S00203: 0.04649180689944734,
      '611B00': 0.039798574319045724,
      '813B00': 0.03971711620792265,
      '713900': 0.036260340123149354,
      '561500': 0.020599061536956013,
      '561900': 0.018562608758879072,
    },
  },
  ['84.30']: {
    label: 'Compulsory social security activities',
    BEA_CODES: { S00600: 0.3209525745618917, GSLGO: 0.6790474254381084 },
  },
  ['85.10']: { label: 'Pre-primary education', BEA_CODES: { '611100': 1.0 } },
  ['88.91']: {
    label: 'Child day-care activities',
    BEA_CODES: {
      '621400': 0.21931062457968167,
      '611B00': 0.15542010482605492,
      '5419A0': 0.171291324629853,
      '611100': 0.08835064422122355,
      '624A00': 0.09317136177798653,
      '624400': 0.10063087778477567,
      '624100': 0.17182506218042465,
    },
  },
  ['85.20']: { label: 'Primary education', BEA_CODES: { '611100': 1.0 } },
  ['85.31']: {
    label: 'General secondary education',
    BEA_CODES: { '611100': 1.0 },
  },
  ['85.32']: {
    label: 'Vocational secondary education',
    BEA_CODES: {
      '611100': 0.17143413437837882,
      '48A000': 0.5269912549245421,
      '611B00': 0.301574610697079,
    },
  },
  ['85.33']: {
    label: 'Post-secondary non-tertiary education',
    BEA_CODES: { '611A00': 1.0 },
  },
  ['85.40']: { label: 'Tertiary education', BEA_CODES: { '611A00': 1.0 } },
  ['85.51']: {
    label: 'Sports and recreation education',
    BEA_CODES: {
      '813B00': 0.14600524942823034,
      '611B00': 0.1463046999666384,
      '711A00': 0.048786320938626705,
      '812900': 0.1279558179823063,
      '711200': 0.06626297786477697,
      '713900': 0.13329769402988073,
      '561500': 0.07572481098440847,
      '48A000': 0.25566242880513207,
    },
  },
  ['93.13']: {
    label: 'Activities of fitness centres',
    BEA_CODES: {
      '813B00': 0.12851588205741457,
      '611B00': 0.12877946264939183,
      '711A00': 0.042942408525151604,
      '812100': 0.11978588057145688,
      '812900': 0.11262851765105468,
      '711200': 0.058325608711957716,
      '713900': 0.11733051237236684,
      '561500': 0.06665404781953398,
      '48A000': 0.22503767964167193,
    },
  },
  ['85.52']: {
    label: 'Cultural education',
    BEA_CODES: {
      '611B00': 0.3161391745414119,
      '711A00': 0.10541880906058922,
      '713900': 0.2880332823790995,
      '561500': 0.16362823096164536,
      '711100': 0.12678050305725402,
    },
  },
  ['85.53']: {
    label: 'Driving school activities',
    BEA_CODES: { '611B00': 1.0 },
  },
  ['85.59']: { label: 'Other education n.e.c.', BEA_CODES: { '611B00': 1.0 } },
  ['85.69']: {
    label: 'Educational support activities n.e.c.',
    BEA_CODES: {
      '611B00': 0.08266929217016261,
      '561300': 0.2797944112660446,
      '5416A0': 0.05062611217668094,
      '541800': 0.3656842604926453,
      '541610': 0.22122592389446658,
    },
  },
  ['86.10']: {
    label: 'Hospital activities',
    BEA_CODES: { '623B00': 0.04605255790305927, '622000': 0.9539474420969407 },
  },
  ['86.21']: {
    label: 'General medical practice activities',
    BEA_CODES: {
      '621500': 0.09140121744287863,
      '621400': 0.18025454347646797,
      '621100': 0.7283442390806534,
    },
  },
  ['86.22']: {
    label: 'Medical specialists activities',
    BEA_CODES: {
      '621500': 0.09140121744287863,
      '621400': 0.18025454347646797,
      '621100': 0.7283442390806534,
    },
  },
  ['86.23']: {
    label: 'Dental practice care activities',
    BEA_CODES: { '621200': 1.0 },
  },
  ['86.91']: {
    label: 'Diagnostic imaging services and medical laboratory activities',
    BEA_CODES: {
      '621500': 0.09176998306885391,
      '621400': 0.1809817950538502,
      '621300': 0.14479389280699376,
      '621900': 0.059673040365191256,
      '623A00': 0.2965029519391536,
      '623B00': 0.07245156300377206,
      '621600': 0.15382677376218523,
    },
  },
  ['86.92']: {
    label: 'Patient transportation by ambulance',
    BEA_CODES: {
      '621500': 0.09176998306885391,
      '621400': 0.1809817950538502,
      '621300': 0.14479389280699376,
      '621900': 0.059673040365191256,
      '623A00': 0.2965029519391536,
      '623B00': 0.07245156300377206,
      '621600': 0.15382677376218523,
    },
  },
  ['86.93']: {
    label:
      'Activities of psychologists and psychotherapists, except medical doctors',
    BEA_CODES: {
      '621500': 0.09176998306885391,
      '621400': 0.1809817950538502,
      '621300': 0.14479389280699376,
      '621900': 0.059673040365191256,
      '623A00': 0.2965029519391536,
      '623B00': 0.07245156300377206,
      '621600': 0.15382677376218523,
    },
  },
  ['86.94']: {
    label: 'Nursing and midwifery activities',
    BEA_CODES: {
      '621500': 0.09176998306885391,
      '621400': 0.1809817950538502,
      '621300': 0.14479389280699376,
      '621900': 0.059673040365191256,
      '623A00': 0.2965029519391536,
      '623B00': 0.07245156300377206,
      '621600': 0.15382677376218523,
    },
  },
  ['86.95']: {
    label: 'Physiotherapy activities',
    BEA_CODES: {
      '621500': 0.09176998306885391,
      '621400': 0.1809817950538502,
      '621300': 0.14479389280699376,
      '621900': 0.059673040365191256,
      '623A00': 0.2965029519391536,
      '623B00': 0.07245156300377206,
      '621600': 0.15382677376218523,
    },
  },
  ['86.96']: {
    label: 'Traditional, complementary and alternative medicine activities',
    BEA_CODES: {
      '621500': 0.09176998306885391,
      '621400': 0.1809817950538502,
      '621300': 0.14479389280699376,
      '621900': 0.059673040365191256,
      '623A00': 0.2965029519391536,
      '623B00': 0.07245156300377206,
      '621600': 0.15382677376218523,
    },
  },
  ['86.99']: {
    label: 'Other human health activities n.e.c.',
    BEA_CODES: {
      '621500': 0.09176998306885391,
      '621400': 0.1809817950538502,
      '621300': 0.14479389280699376,
      '621900': 0.059673040365191256,
      '623A00': 0.2965029519391536,
      '623B00': 0.07245156300377206,
      '621600': 0.15382677376218523,
    },
  },
  ['87.10']: {
    label: 'Residential nursing care activities',
    BEA_CODES: {
      '621500': 0.09176998306885391,
      '621400': 0.1809817950538502,
      '621300': 0.14479389280699376,
      '621900': 0.059673040365191256,
      '623A00': 0.2965029519391536,
      '623B00': 0.07245156300377206,
      '621600': 0.15382677376218523,
    },
  },
  ['87.20']: {
    label:
      'Residential care activities for persons living with or having a diagnosis of a mental illness or substance abuse',
    BEA_CODES: {
      '621500': 0.03669647210002903,
      '621400': 0.07236999692834015,
      '621300': 0.057899379186545565,
      '622000': 0.6001255435288018,
      '621900': 0.023861724582014725,
      '623A00': 0.11856395674877486,
      '623B00': 0.028971529376622132,
      '621600': 0.0615113975488718,
    },
  },
  ['87.30']: {
    label:
      'Residential care activities for older persons or persons with physical disabilities',
    BEA_CODES: {
      '621500': 0.09176998306885391,
      '621400': 0.1809817950538502,
      '621300': 0.14479389280699376,
      '621900': 0.059673040365191256,
      '623A00': 0.2965029519391536,
      '623B00': 0.07245156300377206,
      '621600': 0.15382677376218523,
    },
  },
  ['87.99']: {
    label: 'Other residential care activities n.e.c.',
    BEA_CODES: { '623A00': 0.8036300951216717, '623B00': 0.1963699048783283 },
  },
  ['88.10']: {
    label:
      'Social work activities without accommodation for older persons or persons with disabilities',
    BEA_CODES: {
      '621400': 0.24056466797186027,
      '611B00': 0.1704823283654792,
      '5419A0': 0.1878916751754049,
      '624A00': 0.10220087491686417,
      '624400': 0.11038331475462047,
      '624100': 0.188477138815771,
    },
  },
  ['88.99']: {
    label: 'Other social work activities without accommodation n.e.c.',
    BEA_CODES: {
      '621400': 0.04745925902312209,
      S00600: 0.16120042486210232,
      '5419A0': 0.03706778620325466,
      '611B00': 0.033633222405331197,
      '624A00': 0.020162469559480464,
      S00500: 0.30046103729001183,
      GSLGO: 0.3410557887923447,
      '624400': 0.021776723784654606,
      '624100': 0.037183288079698146,
    },
  },
  ['90.20']: {
    label: 'Activities of performing arts',
    BEA_CODES: {
      '711500': 0.12703010084161043,
      '611B00': 0.27597998331943285,
      '711A00': 0.09202744711502009,
      '713900': 0.25144438547274245,
      '561500': 0.14284252028205321,
      '711100': 0.11067556296914095,
    },
  },
  ['90.39']: {
    label: 'Other support activities to arts and performing arts',
    BEA_CODES: {
      '711500': 0.12703010084161043,
      '611B00': 0.27597998331943285,
      '711A00': 0.09202744711502009,
      '713900': 0.25144438547274245,
      '561500': 0.14284252028205321,
      '711100': 0.11067556296914095,
    },
  },
  ['90.11']: {
    label: 'Literary creation and musical composition activities',
    BEA_CODES: {
      '5191A0': 0.08754537252680389,
      '711500': 0.35152430708545773,
      '711100': 0.3062671786156396,
      '711A00': 0.25466314177209876,
    },
  },
  ['90.12']: {
    label: 'Visual arts creation activities',
    BEA_CODES: {
      '5191A0': 0.08754537252680389,
      '711500': 0.35152430708545773,
      '711100': 0.3062671786156396,
      '711A00': 0.25466314177209876,
    },
  },
  ['90.13']: {
    label: 'Other arts creation activities',
    BEA_CODES: {
      '5191A0': 0.08754537252680389,
      '711500': 0.35152430708545773,
      '711100': 0.3062671786156396,
      '711A00': 0.25466314177209876,
    },
  },
  ['90.31']: {
    label: 'Operation of arts facilities and sites',
    BEA_CODES: { '711A00': 0.3918229654259612, '561500': 0.6081770345740388 },
  },
  ['91.11']: {
    label: 'Library activities',
    BEA_CODES: {
      S00600: 0.1639241288360881,
      '512100': 0.05390361206913967,
      GSLGO: 0.3468183977189602,
      '5191A0': 0.003920595836604411,
      '531ORE': 0.4314332655392076,
    },
  },
  ['91.12']: {
    label: 'Archive activities',
    BEA_CODES: {
      S00600: 0.1639241288360881,
      '512100': 0.05390361206913967,
      GSLGO: 0.3468183977189602,
      '5191A0': 0.003920595836604411,
      '531ORE': 0.4314332655392076,
    },
  },
  ['91.21']: {
    label: 'Museum and collection activities',
    BEA_CODES: { '712000': 1.0 },
  },
  ['91.22']: {
    label: 'Historical site and monument activities',
    BEA_CODES: { '712000': 1.0 },
  },
  ['91.41']: {
    label: 'Botanical and zoological garden activities',
    BEA_CODES: { '712000': 1.0 },
  },
  ['91.42']: {
    label: 'Nature reserve activities',
    BEA_CODES: { '712000': 1.0 },
  },
  ['92.00']: {
    label: 'Gambling and betting activities',
    BEA_CODES: { '721000': 0.6601637246798537, '713200': 0.33983627532014626 },
  },
  ['93.11']: {
    label: 'Operation of sports facilities',
    BEA_CODES: {
      '813B00': 0.10067068523522484,
      '611B00': 0.10087715651631678,
      '561300': 0.3414189704150211,
      '711A00': 0.03363819025843553,
      '711100': 0.04045451396106146,
      '711200': 0.0456883530797478,
      '713900': 0.09190882006512853,
      '561500': 0.0522122912769348,
      '713100': 0.016851659391671863,
      '48A000': 0.17627935980045728,
    },
  },
  ['93.12']: {
    label: 'Activities of sports clubs',
    BEA_CODES: {
      '813B00': 0.10067068523522484,
      '611B00': 0.10087715651631678,
      '561300': 0.3414189704150211,
      '711A00': 0.03363819025843553,
      '711100': 0.04045451396106146,
      '711200': 0.0456883530797478,
      '713900': 0.09190882006512853,
      '561500': 0.0522122912769348,
      '713100': 0.016851659391671863,
      '48A000': 0.17627935980045728,
    },
  },
  ['93.19']: {
    label: 'Sports activities n.e.c.',
    BEA_CODES: {
      '813B00': 0.10067068523522484,
      '611B00': 0.10087715651631678,
      '561300': 0.3414189704150211,
      '711A00': 0.03363819025843553,
      '711100': 0.04045451396106146,
      '711200': 0.0456883530797478,
      '713900': 0.09190882006512853,
      '561500': 0.0522122912769348,
      '713100': 0.016851659391671863,
      '48A000': 0.17627935980045728,
    },
  },
  ['93.21']: {
    label: 'Activities of amusement parks and theme parks',
    BEA_CODES: {
      '711A00': 0.08177613383325417,
      '711100': 0.0983469598816899,
      '713900': 0.22343496818226224,
      '561500': 0.1269307084120776,
      '713100': 0.040967232277907474,
      '48A000': 0.42854399741280863,
    },
  },
  ['94.11']: {
    label: 'Activities of business and employers membership organisations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['94.12']: {
    label: 'Activities of professional membership organisations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['94.20']: {
    label: 'Activities of trade unions',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['94.91']: {
    label: 'Activities of religious organisations',
    BEA_CODES: { '813100': 1.0 },
  },
  ['94.92']: {
    label: 'Activities of political organisations',
    BEA_CODES: { '813B00': 1.0 },
  },
  ['94.99']: {
    label: 'Activities of other membership organisations n.e.c.',
    BEA_CODES: {
      '813A00': 0.35025177843497723,
      '813B00': 0.5806810007193669,
      '114000': 0.06906722084565582,
    },
  },
  ['95.10']: {
    label: 'Repair and maintenance of computers and communication equipment',
    BEA_CODES: {
      '4B0000': 0.27788956863733694,
      '448000': 0.18526671498432676,
      '444000': 0.1670125430844974,
      '812900': 0.09553691874380085,
      '561600': 0.07913892527718881,
      '811200': 0.0709834444489461,
      '811400': 0.04830565571716462,
      '334220': 0.04292618952227039,
      '334515': 0.018623306818642212,
      '334210': 0.01431673276582591,
    },
  },
  ['95.21']: {
    label: 'Repair and maintenance of consumer electronics',
    BEA_CODES: {
      '811200': 0.17871917608967505,
      '4B0000': 0.699658844908553,
      '811400': 0.1216219790017719,
    },
  },
  ['95.22']: {
    label:
      'Repair and maintenance of household appliances and home and garden equipment',
    BEA_CODES: {
      '811400': 0.07407935824889947,
      '811300': 0.13733399913936134,
      '333120': 0.08908070443505055,
      '332200': 0.02092916759672405,
      '333111': 0.07892332721988397,
      '811200': 0.1088569844049512,
      '333112': 0.016481000365886494,
      '4B0000': 0.42615881314706106,
      '33329A': 0.04815664544218188,
    },
  },
  ['95.23']: {
    label: 'Repair and maintenance of footwear and leather goods',
    BEA_CODES: {
      '448000': 0.20047607913435433,
      '811400': 0.052271280672356236,
      '812900': 0.10337996700570255,
      '811200': 0.07681078939506125,
      '444000': 0.1807233415169583,
      '4B0000': 0.30070275255575907,
      '561600': 0.08563578971980827,
    },
  },
  ['95.24']: {
    label: 'Repair and maintenance of furniture and home furnishings',
    BEA_CODES: {
      '811100': 0.15606892710331252,
      '230301': 0.14801214146673825,
      '2334A0': 0.1419607309411653,
      '233411': 0.11173224251680877,
      '233240': 0.09541368107730734,
      '2332D0': 0.07872546523946464,
      '326190': 0.0701360412198259,
      '233262': 0.06852468409251106,
      '2332A0': 0.06795676051687767,
      '230302': 0.061469325825988554,
    },
  },
  ['95.25']: {
    label: 'Repair and maintenance of watches, clocks and jewellery',
    BEA_CODES: { '448000': 0.7931876292898534, '811400': 0.2068123707101466 },
  },
  ['95.29']: {
    label: 'Repair and maintenance of personal and household goods n.e.c.',
    BEA_CODES: {
      '4B0000': 0.25268282433009553,
      '448000': 0.1684615835929175,
      '444000': 0.1518632070001078,
      '812900': 0.08687097746905116,
      '811300': 0.08142960255313458,
      '561600': 0.07196040949484447,
      '811200': 0.06454469418190402,
      '811400': 0.04392395719488118,
      '339113': 0.043537821512533034,
      '339990': 0.034724922670530754,
    },
  },
  ['96.10']: {
    label: 'Washing and cleaning of textile and fur products',
    BEA_CODES: { '812300': 0.15334547517391695, '561700': 0.846654524826083 },
  },
  ['96.21']: {
    label: 'Hairdressing and barber activities',
    BEA_CODES: { '812100': 1.0 },
  },
  ['96.22']: {
    label: 'Beauty care and other beauty treatment activities',
    BEA_CODES: { '812100': 1.0 },
  },
  ['96.30']: {
    label: 'Funeral and related activities',
    BEA_CODES: { '812200': 1.0 },
  },
  ['96.23']: {
    label: 'Day spa, sauna and steam bath activities',
    BEA_CODES: { '812100': 1.0 },
  },
  ['96.99']: {
    label: 'Other personal service activities n.e.c.',
    BEA_CODES: { '812900': 0.4665484443011446, '611B00': 0.5334515556988554 },
  },
  ['97.00']: {
    label: 'Activities of households as employers of domestic personnel',
    BEA_CODES: { '814000': 1.0 },
  },
  ['98.10']: {
    label:
      'Undifferentiated goods-producing activities of private households for own use',
    BEA_CODES: { '814000': 1.0 },
  },
  ['98.20']: {
    label:
      'Undifferentiated service-producing activities of private households for own use',
    BEA_CODES: { '814000': 1.0 },
  },
  ['99.00']: {
    label: 'Activities of extraterritorial organisations and bodies',
    BEA_CODES: { S00600: 1.0 },
  },
};
