import { atom } from 'jotai';
import { FeatureFlagsMap, isFlagOn } from '../utils/FeatureFlag';
import {
  mustAtom,
  useSyncAtom,
} from '@watershed/shared-frontend/components/jotai';
import { GQFlags } from '@watershed/shared-universal/generated/graphql';

const atomFeatureFlags = mustAtom(
  atom<FeatureFlagsMap | null>(null),
  'featureFlags'
);

export { atomFeatureFlags as atomFeatureFlags_FOR_TEST };

/**
 * This syncs the feature flag map to the atom
 * The function exists to prevent exporting the atom which shouldn't be used apart from the isOn version below
 */
export function useSyncAtomFeatureFlags(flags: FeatureFlagsMap) {
  useSyncAtom(atomFeatureFlags, flags);
}

export const atomFeatureFlagIsOn = atom((get) => {
  const flags = get(atomFeatureFlags);
  return { isOn: (flag: GQFlags) => isFlagOn(flags, flag) };
});
