import React, { SetStateAction, useLayoutEffect, useReducer } from 'react';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import SideBar from '../components/navigation/SideBar';
import usePrevious from '@watershed/ui-core/hooks/usePrevious';
import { SkipNavContent } from '../components/navigation/SkipNav';
import LoggedInLayout from './LoggedInLayout';
import {
  SIDEBAR_BREAKPOINT_WIDTH,
  SIDEBAR_COLLAPSED_WIDTH,
} from '../components/navigation/variants/sharedSidebarStyles';
import { useIsSideBarExpandAutoCollapsed } from '../components/navigation/atoms';
import { useResizeObserverWithPredicate } from '@watershed/shared-frontend/hooks/useResizeObserverWithPredicate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
      width: 'fit-content',
      minHeight: '100vh',
      margin: '0',
      padding: '0',
      display: 'grid',
      gridTemplateColumns: '260px 1fr',
      maxWidth: '100vw',
      backgroundColor: theme.palette.grey100,
      '&.is-collapsed': {
        gridTemplateColumns: `${SIDEBAR_COLLAPSED_WIDTH}px 1fr`,
      },
      '@media print': { display: 'block' },
    },
    content: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.paper,
      gridColumn: 2,
      display: 'flex',
      flexDirection: 'column',
      // Setting overflow: hidden here allows complex pages to implement their
      // own scrolling regions without interference.
      overflow: 'hidden',
    },
    contentCollapsed: {
      // SideBar is 56px wide
    },
  })
);

type SidebarAction =
  | { type: 'set-collapse'; payload: { value: boolean } }
  | {
      type: 'window-resize-collapse';
      payload: { value: boolean };
    };

interface SidebarState {
  isCollapsed: boolean;
  hasManuallyControlled: boolean;
}

function sidebarReducer(
  state: SidebarState,
  action: SidebarAction
): SidebarState {
  switch (action.type) {
    case 'set-collapse':
      return {
        isCollapsed: action.payload.value,
        hasManuallyControlled: true,
      };
    case 'window-resize-collapse':
      return {
        ...state,
        isCollapsed: action.payload.value,
      };
    default:
      return state;
  }
}

const predicate = (entry: ResizeObserverEntry) => {
  return entry.contentRect.width < SIDEBAR_BREAKPOINT_WIDTH;
};

function useSidebarState() {
  const [isSideBarExpandAutoCollapsed, setIsSideBarExpandAutoCollapsed] =
    useIsSideBarExpandAutoCollapsed();

  const currentIsCollapsedDueToResize =
    useResizeObserverWithPredicate(predicate);
  const previousIsCollapsedDueToResize = usePrevious(
    currentIsCollapsedDueToResize
  );
  const [{ isCollapsed: isCollapsedState, hasManuallyControlled }, dispatch] =
    useReducer(sidebarReducer, {
      isCollapsed: currentIsCollapsedDueToResize,
      hasManuallyControlled: false,
    });

  useLayoutEffect(() => {
    if (
      currentIsCollapsedDueToResize !== previousIsCollapsedDueToResize &&
      !hasManuallyControlled
    ) {
      dispatch({
        type: 'window-resize-collapse',
        payload: { value: currentIsCollapsedDueToResize },
      });
      document.body.classList.toggle(
        'sidebar-collapsed-yes',
        currentIsCollapsedDueToResize
      );
      document.body.classList.toggle(
        'sidebar-collapsed-no',
        !currentIsCollapsedDueToResize
      );
    }
  }, [
    currentIsCollapsedDueToResize,
    previousIsCollapsedDueToResize,
    hasManuallyControlled,
  ]);

  const isCollapsed = isCollapsedState || isSideBarExpandAutoCollapsed;
  const setIsCollapsed = (value: SetStateAction<boolean>) => {
    setIsSideBarExpandAutoCollapsed(false);
    dispatch({
      type: 'set-collapse',
      payload: {
        value: typeof value === 'function' ? value(isCollapsed) : value,
      },
    });
  };

  return {
    isCollapsed,
    setIsCollapsed,
  };
}

export default function SidebarLayout({
  toggleCreateFinanceSavedView,
  children,
}: {
  toggleCreateFinanceSavedView: () => void;
  children: React.ReactNode;
}) {
  const classes = useStyles();
  const { isCollapsed, setIsCollapsed } = useSidebarState();

  return (
    <LoggedInLayout>
      <div className={clsx(classes.root, isCollapsed && 'is-collapsed')}>
        <SideBar
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          toggleCreateFinanceSavedView={toggleCreateFinanceSavedView}
        />
        <SkipNavContent />
        <main
          className={clsx(
            classes.content,
            isCollapsed && classes.contentCollapsed
          )}
        >
          {children}
        </main>
      </div>
    </LoggedInLayout>
  );
}
