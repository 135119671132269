import { GQCompanyForBenchmarksFragment } from '@watershed/shared-universal/generated/graphql';

export const BENCHMARKS_TITLE = 'Benchmarks';

export interface BenchmarkTextComparison {
  company: GQCompanyForBenchmarksFragment;
  body: string;
  externalUrl?: string;
}

export function companyDisplayName(
  companyName: string,
  isSelfCompany: boolean
) {
  return `${companyName}${isSelfCompany ? ' (you)' : ''}`;
}
