import CheckmarkIcon from '@watershed/icons/components/Checkmark';
import { GQDataIssueState } from '@watershed/shared-universal/generated/graphql';
import LegendDot from '@watershed/ui-viz/components/LegendDot';

export function shouldShowStatusIndicator(state: GQDataIssueState) {
  return state !== GQDataIssueState.NeedsUserResponse;
}

export function DataIssueStatusIndicator({
  state,
  isSmall,
  isActiveUserTheLatestAuthor,
}: {
  state: GQDataIssueState;
  isSmall: boolean;
  isActiveUserTheLatestAuthor: boolean;
}) {
  if (state === GQDataIssueState.Done) {
    return (
      <CheckmarkIcon
        size={isSmall ? 16 : 20}
        sx={{
          color: (theme) => theme.palette.success.main,
          minWidth: isSmall ? 16 : 20,
        }}
      />
    );
  }
  if (!isActiveUserTheLatestAuthor) {
    return (
      <LegendDot
        size={isSmall ? 6 : 8}
        sx={{
          backgroundColor: (theme) => theme.palette.error.main,
          minWidth: isSmall ? 6 : 8,
        }}
      />
    );
  } else {
    return null;
  }
}
