import LoadingPage from '@watershed/shared-frontend/components/LoadingPage';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import ErrorPage from './ErrorPage';

export function ErrorFallback(props: FallbackProps) {
  const { error } = props;
  const isChunkLoadError = error.name === 'ChunkLoadError';
  useEffect(() => {
    if (isChunkLoadError) {
      // Chunk loading errors happen when there's a long-lived tab that tries to
      // load a `React.lazy()`-loaded page after a new deploy has happened.
      window.location.reload();
    }
  }, [isChunkLoadError]);
  return isChunkLoadError ? <LoadingPage /> : <ErrorPage {...props} />;
}
