/*
  This file is semi-automatically generated from
  cliq/taxonomy/scripts/generate_gics_2023__ceda_5_weighted_mapping.pyin the cliq repo
  Do not manually modify this file
 */
import type { WeightedBeaCodes } from './industryCodeUtils';

const GICS_CODES_2023: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['10101010']: { label: 'Oil & Gas Drilling', BEA_CODES: { '213111': 1.0 } },
  ['10101020']: {
    label: 'Oil & Gas Equipment & Services',
    BEA_CODES: { '333130': 1.0 },
  },
  ['10102010']: { label: 'Integrated Oil & Gas', BEA_CODES: { '211000': 1.0 } },
  ['10102020']: {
    label: 'Oil & Gas Exploration & Production',
    BEA_CODES: { '211000': 1.0 },
  },
  ['10102030']: {
    label: 'Oil & Gas Refining & Marketing',
    BEA_CODES: { '324110': 1.0 },
  },
  ['10102040']: {
    label: 'Oil & Gas Storage & Transportation',
    BEA_CODES: { '424700': 0.80187297, '486000': 0.19812703 },
  },
  ['10102050']: {
    label: 'Coal & Consumable Fuels',
    BEA_CODES: {
      '212100': 0.42982186,
      '324121': 0.12776136,
      '324122': 0.10117225,
      '324190': 0.34124453,
    },
  },
  ['15101010']: {
    label: 'Commodity Chemicals',
    BEA_CODES: {
      '325110': 0.17271603,
      '325130': 0.01465701,
      '325180': 0.06255089,
      '325190': 0.21725288,
      '325211': 0.1434881,
      '3252A0': 0.03831348,
      '325510': 0.03865934,
      '325520': 0.01948034,
      '325910': 0.00844496,
      '326110': 0.0666956,
      '326120': 0.02513457,
      '326130': 0.00597867,
      '326140': 0.01285447,
      '326150': 0.01518834,
      '326160': 0.02041057,
      '326190': 0.13817478,
    },
  },
  ['15101020']: {
    label: 'Diversified Chemicals',
    BEA_CODES: { '3259A0': 1.0 },
  },
  ['15101030']: {
    label: 'Fertilizers & Agricultural Chemicals',
    BEA_CODES: { '325310': 0.63977821, '325320': 0.36022179 },
  },
  ['15101040']: { label: 'Industrial Gases', BEA_CODES: { '325120': 1.0 } },
  ['15101050']: {
    label: 'Specialty Chemicals',
    BEA_CODES: {
      '325411': 0.25316902,
      '325413': 0.24499492,
      '325414': 0.50183606,
    },
  },
  ['15102010']: {
    label: 'Construction Materials',
    BEA_CODES: {
      '212310': 0.12685491,
      '2123A0': 0.14275821,
      '327100': 0.07167529,
      '327310': 0.05724786,
      '327320': 0.19992576,
      '327330': 0.05358327,
      '327390': 0.08438574,
      '327400': 0.05938636,
      '327910': 0.04761954,
      '327991': 0.03563182,
      '327992': 0.03242908,
      '327993': 0.04879411,
      '327999': 0.03970805,
    },
  },
  ['15103010']: {
    label: 'Metal & Glass Containers',
    BEA_CODES: { '327200': 0.55794778, '332430': 0.44205222 },
  },
  ['15103020']: {
    label: 'Paper Packaging',
    BEA_CODES: { '322210': 0.72834349, '322220': 0.27165651 },
  },
  ['15104010']: {
    label: 'Aluminum',
    BEA_CODES: { '331313': 0.33415186, '33131B': 0.66584814 },
  },
  ['15104020']: {
    label: 'Diversified Metals & Mining',
    BEA_CODES: {
      '331410': 0.28708642,
      '331420': 0.23561424,
      '331490': 0.13714713,
      '331510': 0.20406747,
      '331520': 0.13608473,
    },
  },
  ['15104025']: { label: 'Copper', BEA_CODES: { '212230': 1.0 } },
  ['15104030']: { label: 'Gold', BEA_CODES: { '2122A0': 1.0 } },
  ['15104040']: {
    label: 'Precious Metals & Minerals',
    BEA_CODES: { '2122A0': 1.0 },
  },
  ['15104045']: { label: 'Silver', BEA_CODES: { '2122A0': 1.0 } },
  ['15104050']: {
    label: 'Steel',
    BEA_CODES: { '331110': 0.93022721, '331200': 0.06977279 },
  },
  ['15105010']: {
    label: 'Forest Products',
    BEA_CODES: {
      '113000': 0.18279938,
      '321100': 0.26785142,
      '321200': 0.17473561,
      '321910': 0.17867445,
      '3219A0': 0.19593915,
    },
  },
  ['15105020']: {
    label: 'Paper Products',
    BEA_CODES: {
      '322110': 0.07116492,
      '322120': 0.45067651,
      '322130': 0.26409735,
      '322230': 0.06752077,
      '322291': 0.09801462,
      '322299': 0.04852583,
    },
  },
  ['20101010']: {
    label: 'Aerospace & Defense',
    BEA_CODES: {
      '336411': 0.50489821,
      '336412': 0.19147995,
      '336413': 0.16410575,
      '336414': 0.08619603,
      '33641A': 0.02546012,
      '336992': 0.02785994,
    },
  },
  ['20102010']: {
    label: 'Building Products',
    BEA_CODES: {
      '233210': 0.22124377,
      '233262': 0.39099838,
      '2332A0': 0.38775784,
    },
  },
  ['20103010']: {
    label: 'Construction & Engineering',
    BEA_CODES: {
      '230301': 0.31782928,
      '233230': 0.08806853,
      '233240': 0.20488827,
      '2332C0': 0.2201614,
      '2332D0': 0.16905253,
    },
  },
  ['20104010']: {
    label: 'Electrical Components & Equipment',
    BEA_CODES: {
      '335110': 0.0151918,
      '335120': 0.08559147,
      '335210': 0.02675646,
      '335221': 0.03717393,
      '335222': 0.02957382,
      '335224': 0.03046795,
      '335228': 0.03719924,
      '335311': 0.04829997,
      '335312': 0.09221356,
      '335313': 0.09030724,
      '335314': 0.08190578,
      '335911': 0.05294688,
      '335912': 0.03714863,
      '335920': 0.1247735,
      '335930': 0.09976313,
      '335991': 0.02795419,
      '335999': 0.08273246,
    },
  },
  ['20104020']: {
    label: 'Heavy Electrical Equipment',
    BEA_CODES: {
      '333611': 0.32354394,
      '333612': 0.08158769,
      '333613': 0.07787195,
      '333618': 0.51699642,
    },
  },
  ['20105010']: {
    label: 'Industrial Conglomerates',
    BEA_CODES: { '550000': 1.0 },
  },
  ['20106010']: {
    label: 'Construction Machinery & Heavy Trucks',
    BEA_CODES: {
      '333120': 0.21602575,
      '333912': 0.05442786,
      '33391A': 0.0880644,
      '333920': 0.16302014,
      '336120': 0.19260121,
      '336500': 0.08887355,
      '336611': 0.12100351,
      '336612': 0.0375482,
      '336999': 0.03843538,
    },
  },
  ['20106015']: {
    label: 'Agricultural & Farm Machinery',
    BEA_CODES: { '333111': 1.0 },
  },
  ['20106020']: {
    label: 'Industrial Machinery',
    BEA_CODES: {
      '332114': 0.01778716,
      '332119': 0.02484232,
      '33211A': 0.03285126,
      '332310': 0.08200154,
      '332320': 0.07615919,
      '332410': 0.01479798,
      '332420': 0.01950011,
      '332500': 0.01569566,
      '332600': 0.01261363,
      '332710': 0.08628198,
      '332720': 0.05956231,
      '332800': 0.05907568,
      '332913': 0.00700109,
      '33291A': 0.0557275,
      '332991': 0.01468127,
      '332996': 0.01752978,
      '332999': 0.03128315,
      '33299A': 0.02946863,
      '33329A': 0.045253,
      '333314': 0.01094178,
      '333316': 0.00398394,
      '333318': 0.03739326,
      '333413': 0.01281892,
      '333414': 0.01028204,
      '333415': 0.06361093,
      '333511': 0.01405843,
      '333514': 0.0179883,
      '333517': 0.01625587,
      '33351B': 0.01803372,
      '333991': 0.00802196,
      '333993': 0.0116447,
      '333994': 0.0056277,
      '33399A': 0.04414779,
      '33399B': 0.02307745,
    },
  },
  ['20107010']: {
    label: 'Trading Companies & Distributors',
    BEA_CODES: { '423800': 1.0 },
  },
  ['20201010']: {
    label: 'Commercial Printing',
    BEA_CODES: { '323110': 0.93186523, '323120': 0.06813477 },
  },
  ['20201050']: {
    label: 'Environmental & Facilities Services',
    BEA_CODES: {
      '562111': 0.49397429,
      '562212': 0.0795181,
      '562213': 0.04951048,
      '562910': 0.14660729,
      '562HAZ': 0.13778976,
      '562OTH': 0.09260008,
    },
  },
  ['20201060']: {
    label: 'Office Services & Supplies',
    BEA_CODES: {
      '339940': 0.01408922,
      '561100': 0.20900282,
      '561200': 0.12005306,
      '561400': 0.32947602,
      '561500': 0.17216444,
      '561900': 0.15521444,
    },
  },
  ['20201070']: {
    label: 'Diversified Support Services',
    BEA_CODES: { '561700': 1.0 },
  },
  ['20201080']: {
    label: 'Security & Alarm Services',
    BEA_CODES: { '561600': 1.0 },
  },
  ['20202010']: {
    label: 'Human Resource & Employment Services',
    BEA_CODES: { '541200': 0.39230665, '561300': 0.60769335 },
  },
  ['20202020']: {
    label: 'Research & Consulting Services',
    BEA_CODES: {
      '541300': 0.2504499,
      '541400': 0.02319103,
      '541610': 0.17003449,
      '5416A0': 0.03890975,
      '541700': 0.41527307,
      '541920': 0.00983938,
      '541940': 0.02227456,
      '5419A0': 0.07002782,
    },
  },
  ['20202030']: {
    label: 'Data Processing & Outsourced Services',
    BEA_CODES: {
      '518200': 0.23419392,
      '561100': 0.09616286,
      '561300': 0.51805025,
      '561400': 0.15159296,
    },
  },
  ['20301010']: {
    label: 'Air Freight & Logistics',
    BEA_CODES: { '492000': 1.0 },
  },
  ['20302010']: { label: 'Airlines', BEA_CODES: { '481000': 1.0 } },
  ['20303010']: { label: 'Marine', BEA_CODES: { '483000': 1.0 } },
  ['20304010']: { label: 'Railroads', BEA_CODES: { '482000': 1.0 } },
  ['20304030']: {
    label: 'Cargo Ground Transportation',
    BEA_CODES: { '484000': 1.0 },
  },
  ['20304040']: {
    label: 'Passenger Ground Transportation',
    BEA_CODES: { '485000': 1.0 },
  },
  ['20305010']: { label: 'Airport Services', BEA_CODES: { '48A000': 1.0 } },
  ['20305020']: {
    label: 'Highways & Railtracks',
    BEA_CODES: { '485000': 1.0 },
  },
  ['20305030']: {
    label: 'Marine Ports & Services',
    BEA_CODES: { '48A000': 1.0 },
  },
  ['25101010']: {
    label: 'Auto Parts & Equipment',
    BEA_CODES: {
      '336211': 0.02977684,
      '336212': 0.03363262,
      '336213': 0.01035309,
      '336214': 0.04254982,
      '336310': 0.11674232,
      '336320': 0.07889641,
      '336350': 0.15343006,
      '336360': 0.09483087,
      '336370': 0.11911588,
      '336390': 0.22776719,
      '3363A0': 0.09290491,
    },
  },
  ['25101020']: {
    label: 'Tires & Rubber',
    BEA_CODES: {
      '326210': 0.4700889,
      '326220': 0.12441034,
      '326290': 0.40550076,
    },
  },
  ['25102010']: {
    label: 'Automobile Manufacturers',
    BEA_CODES: { '336111': 0.24163411, '336112': 0.75836589 },
  },
  ['25102020']: {
    label: 'Motorcycle Manufacturers',
    BEA_CODES: { '336991': 1.0 },
  },
  ['25201010']: { label: 'Consumer Electronics', BEA_CODES: { '334300': 1.0 } },
  ['25201020']: {
    label: 'Home Furnishings',
    BEA_CODES: {
      '337110': 0.41325183,
      '337121': 0.33416346,
      '337122': 0.15500717,
      '33712N': 0.09757754,
    },
  },
  ['25201030']: {
    label: 'Homebuilding',
    BEA_CODES: {
      '230302': 0.18161081,
      '233411': 0.33011234,
      '233412': 0.06885459,
      '2334A0': 0.41942226,
    },
  },
  ['25201040']: {
    label: 'Household Appliances',
    BEA_CODES: { '333112': 0.04728768, '423600': 0.95271232 },
  },
  ['25201050']: {
    label: 'Housewares & Specialties',
    BEA_CODES: { '332200': 1.0 },
  },
  ['25202010']: {
    label: 'Leisure Products',
    BEA_CODES: { '339920': 0.84258854, '339930': 0.15741146 },
  },
  ['25203010']: {
    label: 'Apparel, Accessories & Luxury Goods',
    BEA_CODES: {
      '315000': 0.41380253,
      '316000': 0.22097833,
      '339910': 0.36521914,
    },
  },
  ['25203020']: {
    label: 'Footwear',
    BEA_CODES: { '316000': 0.41616677, '339920': 0.58383323 },
  },
  ['25203030']: {
    label: 'Textiles',
    BEA_CODES: {
      '313100': 0.098716,
      '313200': 0.24137167,
      '313300': 0.18422353,
      '314110': 0.18569568,
      '314120': 0.07616345,
      '314900': 0.21382967,
    },
  },
  ['25301010']: { label: 'Casinos & Gaming', BEA_CODES: { '713200': 1.0 } },
  ['25301020']: {
    label: 'Hotels, Resorts & Cruise Lines',
    BEA_CODES: { '721000': 1.0 },
  },
  ['25301030']: {
    label: 'Leisure Facilities',
    BEA_CODES: { '713100': 0.15494, '713900': 0.84506 },
  },
  ['25301040']: {
    label: 'Restaurants',
    BEA_CODES: {
      '722110': 0.38500187,
      '722211': 0.40997366,
      '722A00': 0.20502447,
    },
  },
  ['25302010']: {
    label: 'Education Services',
    BEA_CODES: {
      '611100': 0.12347016,
      '611A00': 0.65933,
      '611B00': 0.21719984,
    },
  },
  ['25302020']: {
    label: 'Specialized Consumer Services',
    BEA_CODES: {
      '541100': 0.32356807,
      '541920': 0.01188572,
      '541940': 0.02690708,
      '624100': 0.08485641,
      '624400': 0.04969691,
      '624A00': 0.04601301,
      '811100': 0.19587008,
      '811200': 0.04986652,
      '811300': 0.06293404,
      '811400': 0.03393215,
      '812200': 0.0202636,
      '812300': 0.02707577,
      '812900': 0.06713064,
    },
  },
  ['25501010']: {
    label: 'Distributors',
    BEA_CODES: {
      '423100': 0.1961964,
      '423400': 0.35977188,
      '423A00': 0.44403172,
    },
  },
  ['25503030']: {
    label: 'Broadline Retail',
    BEA_CODES: { '452000': 0.55609147, '454000': 0.44390853 },
  },
  ['25504010']: { label: 'Apparel Retail', BEA_CODES: { '448000': 1.0 } },
  ['25504020']: {
    label: 'Computer & Electronics Retail',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['25504030']: {
    label: 'Home Improvement Retail',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['25504040']: { label: 'Specialty Stores', BEA_CODES: { '4B0000': 1.0 } },
  ['25504050']: {
    label: 'Automotive Retail',
    BEA_CODES: { '441000': 0.69253989, '447000': 0.30746011 },
  },
  ['25504060']: {
    label: 'Homefurnishing Retail',
    BEA_CODES: { '444000': 1.0 },
  },
  ['30101010']: { label: 'Drug Retail', BEA_CODES: { '446000': 1.0 } },
  ['30101020']: { label: 'Food Distributors', BEA_CODES: { '424A00': 1.0 } },
  ['30101030']: { label: 'Food Retail', BEA_CODES: { '445000': 1.0 } },
  ['30101040']: {
    label: 'Hypermarkets & Super Centers',
    BEA_CODES: { '424400': 1.0 },
  },
  ['30201010']: { label: 'Brewers', BEA_CODES: { '312120': 1.0 } },
  ['30201020']: {
    label: 'Distillers & Vintners',
    BEA_CODES: { '312130': 0.45391382, '312140': 0.54608618 },
  },
  ['30201030']: { label: 'Soft Drinks', BEA_CODES: { '312110': 1.0 } },
  ['30202010']: {
    label: 'Agricultural Products',
    BEA_CODES: {
      '1111A0': 0.09675809,
      '1111B0': 0.20955401,
      '111200': 0.03283117,
      '111300': 0.05828793,
      '111400': 0.04247447,
      '111900': 0.0398347,
      '112120': 0.08335089,
      '1121A0': 0.17766381,
      '112300': 0.08508631,
      '112A00': 0.09094281,
      '114000': 0.01978341,
      '115000': 0.06343239,
    },
  },
  ['30202030']: {
    label: 'Packaged Foods & Meats',
    BEA_CODES: {
      '311111': 0.02563109,
      '311119': 0.05013838,
      '311210': 0.0264226,
      '311221': 0.02088739,
      '311224': 0.04986148,
      '311225': 0.02551763,
      '311230': 0.01302355,
      '311300': 0.04592822,
      '311410': 0.04097651,
      '311420': 0.05154582,
      '311513': 0.05053549,
      '311514': 0.02714793,
      '31151A': 0.05078942,
      '311520': 0.00976834,
      '311615': 0.07816968,
      '31161A': 0.18989776,
      '311700': 0.01425405,
      '311810': 0.06266217,
      '3118A0': 0.03565201,
      '311910': 0.04363741,
      '311920': 0.01705677,
      '311930': 0.0129263,
      '311940': 0.02613895,
      '311990': 0.03143104,
    },
  },
  ['30203010']: { label: 'Tobacco', BEA_CODES: { '312200': 1.0 } },
  ['30301010']: {
    label: 'Household Products',
    BEA_CODES: { '325610': 0.59885532, '325620': 0.40114468 },
  },
  ['30302010']: { label: 'Personal Products', BEA_CODES: { '446000': 1.0 } },
  ['35101010']: {
    label: 'Health Care Equipment',
    BEA_CODES: {
      '339112': 0.47542683,
      '339113': 0.41076577,
      '339114': 0.05483763,
      '339116': 0.05896976,
    },
  },
  ['35101020']: { label: 'Health Care Supplies', BEA_CODES: { '339115': 1.0 } },
  ['35102010']: {
    label: 'Health Care Distributors',
    BEA_CODES: { '424200': 1.0 },
  },
  ['35102015']: {
    label: 'Health Care Services',
    BEA_CODES: {
      '621100': 0.49097875,
      '621200': 0.1267517,
      '621300': 0.09721396,
      '621500': 0.06161513,
      '621600': 0.10327859,
      '621900': 0.04006421,
      '812100': 0.08009766,
    },
  },
  ['35102020']: {
    label: 'Health Care Facilities',
    BEA_CODES: {
      '622000': 0.80267062,
      '623A00': 0.15857983,
      '623B00': 0.03874955,
    },
  },
  ['35102030']: { label: 'Managed Health Care', BEA_CODES: { '621400': 1.0 } },
  ['35103010']: {
    label: 'Health Care Technology',
    BEA_CODES: { '541511': 1.0 },
  },
  ['35201010']: { label: 'Biotechnology', BEA_CODES: { '541700': 1.0 } },
  ['35202010']: { label: 'Pharmaceuticals', BEA_CODES: { '325412': 1.0 } },
  ['35203010']: {
    label: 'Life Sciences Tools & Services',
    BEA_CODES: { '339112': 0.53648251, '339113': 0.46351749 },
  },
  ['40101010']: { label: 'Diversified Banks', BEA_CODES: { '52A000': 1.0 } },
  ['40101015']: { label: 'Regional Banks', BEA_CODES: { '52A000': 1.0 } },
  ['40201020']: {
    label: 'Other Diversified Financial Services',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['40201030']: {
    label: 'Multi-Sector Holdings',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['40201040']: { label: 'Specialized Finance', BEA_CODES: { '522A00': 1.0 } },
  ['40201050']: {
    label: 'Commercial & Residential Mortgage Finance',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['40201060']: {
    label: 'Transaction & Payment Processing Services',
    BEA_CODES: { '511200': 0.34015129, '522A00': 0.65984871 },
  },
  ['40202010']: { label: 'Consumer Finance', BEA_CODES: { '522A00': 1.0 } },
  ['40203010']: {
    label: 'Asset Management & Custody Banks',
    BEA_CODES: { '523900': 1.0 },
  },
  ['40203020']: {
    label: 'Investment Banking & Brokerage',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['40203030']: {
    label: 'Diversified Capital Markets',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['40203040']: {
    label: 'Financial Exchanges & Data',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['40204010']: { label: 'Mortgage REITs', BEA_CODES: { '525000': 1.0 } },
  ['40301010']: { label: 'Insurance Brokers', BEA_CODES: { '524200': 1.0 } },
  ['40301020']: {
    label: 'Life & Health Insurance',
    BEA_CODES: { '524113': 0.15705292, '5241XX': 0.84294708 },
  },
  ['40301030']: { label: 'Multi-line Insurance', BEA_CODES: { '5241XX': 1.0 } },
  ['40301040']: {
    label: 'Property & Casualty Insurance',
    BEA_CODES: { '5241XX': 1.0 },
  },
  ['40301050']: { label: 'Reinsurance', BEA_CODES: { '5241XX': 1.0 } },
  ['45102010']: {
    label: 'IT Consulting & Other Services',
    BEA_CODES: {
      '541511': 0.48500224,
      '541512': 0.34164767,
      '54151A': 0.17335009,
    },
  },
  ['45102030']: {
    label: 'Internet Services & Infrastructure',
    BEA_CODES: { '518200': 1.0 },
  },
  ['45103010']: { label: 'Application Software', BEA_CODES: { '511200': 1.0 } },
  ['45103020']: { label: 'Systems Software', BEA_CODES: { '511200': 1.0 } },
  ['45201020']: {
    label: 'Communications Equipment',
    BEA_CODES: {
      '334210': 0.22237257,
      '334220': 0.66723406,
      '334290': 0.11039337,
    },
  },
  ['45202030']: {
    label: 'Technology Hardware, Storage & Peripherals',
    BEA_CODES: {
      '334111': 0.29133836,
      '334112': 0.30538768,
      '334118': 0.40327396,
    },
  },
  ['45203010']: {
    label: 'Electronic Equipment & Instruments',
    BEA_CODES: {
      '334510': 0.19876214,
      '334511': 0.32773668,
      '334512': 0.01863726,
      '334513': 0.07512363,
      '334514': 0.04743588,
      '334515': 0.08913593,
      '334516': 0.09806313,
      '334517': 0.06184429,
      '33451A': 0.08326105,
    },
  },
  ['45203015']: {
    label: 'Electronic Components',
    BEA_CODES: {
      '334418': 0.37541261,
      '33441A': 0.55605703,
      '334610': 0.06853036,
    },
  },
  ['45203020']: {
    label: 'Electronic Manufacturing Services',
    BEA_CODES: { '33441A': 1.0 },
  },
  ['45203030']: {
    label: 'Technology Distributors',
    BEA_CODES: { '425000': 1.0 },
  },
  ['45301010']: {
    label: 'Semiconductor Equipment',
    BEA_CODES: { '333242': 1.0 },
  },
  ['45301020']: { label: 'Semiconductors', BEA_CODES: { '334413': 1.0 } },
  ['50101010']: { label: 'Alternative Carriers', BEA_CODES: { '517A00': 1.0 } },
  ['50101020']: {
    label: 'Integrated Telecommunication Services',
    BEA_CODES: { '517110': 1.0 },
  },
  ['50102010']: {
    label: 'Wireless Telecommunication Services',
    BEA_CODES: { '517210': 1.0 },
  },
  ['50201010']: { label: 'Advertising', BEA_CODES: { '541800': 1.0 } },
  ['50201020']: { label: 'Broadcasting', BEA_CODES: { '515100': 1.0 } },
  ['50201030']: { label: 'Cable & Satellite', BEA_CODES: { '515200': 1.0 } },
  ['50201040']: {
    label: 'Publishing',
    BEA_CODES: {
      '511110': 0.07212451,
      '511120': 0.09439359,
      '511130': 0.25716962,
      '5111A0': 0.08820125,
      '519130': 0.41857846,
      '5191A0': 0.06953256,
    },
  },
  ['50202010']: {
    label: 'Movies & Entertainment',
    BEA_CODES: { '512100': 0.87928418, '512200': 0.12071582 },
  },
  ['50202020']: {
    label: 'Interactive Home Entertainment',
    BEA_CODES: { '339930': 1.0 },
  },
  ['50203010']: {
    label: 'Interactive Media & Services',
    BEA_CODES: { '339930': 1.0 },
  },
  ['55101010']: { label: 'Electric Utilities', BEA_CODES: { '221100': 1.0 } },
  ['55102010']: { label: 'Gas Utilities', BEA_CODES: { '221200': 1.0 } },
  ['55103010']: {
    label: 'Multi-Utilities',
    BEA_CODES: { '221200': 0.57479114, '221300': 0.42520886 },
  },
  ['55104010']: { label: 'Water Utilities', BEA_CODES: { '221300': 1.0 } },
  ['55105010']: {
    label: 'Independent Power Producers & Energy Traders',
    BEA_CODES: { '221100': 1.0 },
  },
  ['55105020']: {
    label: 'Renewable Electricity',
    BEA_CODES: { '221100': 1.0 },
  },
  ['60101010']: { label: 'Diversified REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60102510']: {
    label: 'Industrial REITs',
    BEA_CODES: { '493000': 0.10880583, '531ORE': 0.89119417 },
  },
  ['60103010']: {
    label: 'Hotel & Resort REITs',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60104010']: { label: 'Office REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60105010']: { label: 'Health Care REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60106010']: {
    label: 'Multi-Family Residential REITs',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60106020']: {
    label: 'Single-Family Residential REITs',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60107010']: { label: 'Retail REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60108010']: {
    label: 'Other Specialized REITs',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60108020']: { label: 'Self Storage REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60108030']: { label: 'Telecom Tower REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60108040']: { label: 'Timber REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60108050']: { label: 'Data Center REITs', BEA_CODES: { '531ORE': 1.0 } },
  ['60201010']: {
    label: 'Diversified Real Estate Activities',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60201020']: {
    label: 'Real Estate Operating Companies',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60201030']: {
    label: 'Real Estate Development',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60201040']: { label: 'Real Estate Services', BEA_CODES: { '531ORE': 1.0 } },
};

export const HISTORICAL_GICS_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['20304020']: {
    label: '[Deprecated] Trucking',
    BEA_CODES: { '484000': 0.74641445, '532100': 0.25358555 },
  },
  ['25502020']: {
    label: '[Deprecated] Internet & Direct Marketing Retail',
    BEA_CODES: { '454000': 1.0 },
  },
  ['25503010']: {
    label: '[Deprecated] Department Stores',
    BEA_CODES: { '452000': 1.0 },
  },
  ['25503020']: {
    label: '[Deprecated] General Merchandise Stores',
    BEA_CODES: { '452000': 1.0 },
  },
  ['40102010']: {
    label: '[Deprecated] Thrifts & Mortgage Finance',
    BEA_CODES: { '522A00': 1.0 },
  },
  ['45102020']: {
    label: '[Deprecated] Data Processing & Outsourced Services',
    BEA_CODES: { '518200': 1.0 },
  },
  ['60102020']: {
    label: '[Deprecated] Real Estate Operating Companies',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60102040']: {
    label: '[Deprecated] Real Estate Services',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['60101080']: {
    label: '[Deprecated] Specialized REITs',
    BEA_CODES: { '531ORE': 1.0 },
  },
  ['25502010']: {
    label: '[Deprecated] Catalog Retail',
    BEA_CODES: { '4B0000': 1.0 },
  },
  ['60102030']: {
    label: '[Deprecated] Real Estate Development',
    BEA_CODES: { '531ORE': 1.0 },
  },
};

export const GICS_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = { ...GICS_CODES_2023, ...HISTORICAL_GICS_CODES };
