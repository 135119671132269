import { Box, Chip, Divider, Stack, Typography } from '@mui/material';
import ConversationIcon from '@watershed/icons/components/Conversation';
import PencilIcon from '@watershed/icons/components/Pencil';
import {
  GQDiscussionAdditionalProperties,
  GQDiscussionAnchor,
  GQDiscussionFieldsFragment,
} from '@watershed/shared-universal/generated/graphql';
import BlankSlate from '@watershed/ui-core/components/BlankSlate';
import Button from '@watershed/ui-core/components/Button';
import { Dialog, DialogLoading } from '@watershed/ui-core/components/Dialog';
import { useEffect, useMemo, useState } from 'react';
import { IssuesSection } from '../measure/datasources/datasourceId/DataIssueSidebar';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import { DiscussionThread } from '@watershed/ui-core/components/DiscussionThread';
import CreateDiscussionForm from './CreateDiscussionForm';
import { EditDiscussionForm } from './EditDiscussionForm';
import { useUserContext } from '../../utils/UserContext';
import {
  NEW_COMMENT_DISCUSSION_ID,
  getDisplayNameForAnchor,
  getQueryParamForAnchor,
} from '@watershed/shared-universal/utils/discussionUtils';
import { Tooltip } from '../footprint/suppliers/suppliersTableColumns/Tooltip';
import FullScreenIcon from '@watershed/icons/components/FullScreen';
import { createDialogHook } from '@watershed/ui-core/hooks/useDialog';
import { useDiscussionsQuery } from './SupportButton';
import { Trans } from '@lingui/react/macro';

export interface DiscussionsDialogProps {
  anchor: GQDiscussionAnchor;
  additionalProperties?: GQDiscussionAdditionalProperties;
  onClose: () => void;
}

export function DiscussionsDialog({
  // Discussion anchor and properties used when creating new discussions from the dialog.
  anchor,
  additionalProperties,
  onClose,
}: DiscussionsDialogProps) {
  const [selectedDiscussionId, setSelectedDiscussionId] = useQueryParam(
    getQueryParamForAnchor(anchor)
  );
  const {
    fetching,
    openDiscussionCount,
    orderedDiscussions: discussions,
  } = useDiscussionsQuery({
    anchor,
  });
  const [isFullScreen, setIsFullScreen] = useState(false);

  // If the dialog is open without a selected discussion/new discussion than select the first discussion.
  useEffect(() => {
    if (!selectedDiscussionId) {
      setSelectedDiscussionId(discussions[0]?.id ?? NEW_COMMENT_DISCUSSION_ID);
    }
  }, [discussions, selectedDiscussionId, setSelectedDiscussionId]);

  if (fetching) return <DialogLoading onClose={onClose} />;

  const props = {
    anchor,
    discussions,
    openDiscussionCount,
    selectedDiscussionId,
    setSelectedDiscussionId,
  };

  const selectedDiscussion = discussions.find(
    (discussion) => discussion.id === selectedDiscussionId
  );

  return (
    <Dialog
      header={{
        title: (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="h2">
              <Trans context="Button to contact support">Support</Trans>
            </Typography>
            <Button
              isIcon
              onClick={() => setIsFullScreen((prev) => !prev)}
              tooltip={
                isFullScreen ? (
                  <Trans context="Button to exit full screen">
                    Exit full screen
                  </Trans>
                ) : (
                  <Trans context="Button to enter full screen">
                    Enter full screen
                  </Trans>
                )
              }
              size="small"
              sx={{
                marginRight: 0.5,
                // Somehow even when adding a `min-width`, the default style
                // `min-width: 32px` is applied after and overrides.
                minWidth: '24px !important',
              }}
              variant="text"
            >
              {isFullScreen ? <ShrinkIcon /> : <FullScreenIcon size={16} />}
            </Button>
          </Stack>
        ),
        actionsSx: {
          // Align FullScreenIcon button and close button
          alignItems: 'center',
        },
      }}
      maxWidth="lg"
      variant={isFullScreen ? 'fullScreen' : 'standard'}
      contentSx={{
        padding: 0,
      }}
      onClose={() => {
        setSelectedDiscussionId(null);
        onClose();
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Stack
        direction="row"
        sx={{
          height: isFullScreen
            ? // 61px is height of dialog header
              'calc(100vh - 61px)'
            : '70vh',
        }}
        overflow="hidden"
        divider={<Divider orientation="vertical" />}
        /**
         * a11y quirk: Prevent key down from propagating out of this dialog.
         * In some cases we have this dialog appear as a child of a menu item,
         * like @see FootprintQuestionMenu - typing the first letter of
         * any menu item would bring focus to the that menu item, which is frustrating
         * while typing in a form.
         * https://www.w3.org/WAI/ARIA/apg/patterns/menu-button/examples/menu-button-actions-active-descendant/
         */
        onKeyDown={(e) => e.stopPropagation()}
      >
        <DiscussionSidepanel {...props} />
        <Box flexGrow={1} minHeight={0} display="flex">
          {selectedDiscussionId === NEW_COMMENT_DISCUSSION_ID ? (
            <NewDiscussionContent
              anchor={anchor}
              additionalProperties={additionalProperties}
              setSelectedDiscussionId={setSelectedDiscussionId}
            />
          ) : selectedDiscussion ? (
            <DiscussionThreadContent selectedDiscussion={selectedDiscussion} />
          ) : (
            <MessageNotFound />
          )}
        </Box>
      </Stack>
    </Dialog>
  );
}

function NewDiscussionContent({
  anchor,
  additionalProperties,
  setSelectedDiscussionId,
}: {
  anchor: GQDiscussionAnchor;
  additionalProperties?: GQDiscussionAdditionalProperties;
  setSelectedDiscussionId: (discussionId: string | null) => void;
}) {
  return (
    <Box padding={2} display="flex" flexGrow={1}>
      <CreateDiscussionForm
        anchor={anchor}
        additionalProperties={additionalProperties}
        onSubmitComplete={(discussion) =>
          setSelectedDiscussionId(discussion.id)
        }
      />
    </Box>
  );
}

function DiscussionThreadContent({
  selectedDiscussion,
}: {
  selectedDiscussion: GQDiscussionFieldsFragment;
}) {
  const { userId } = useUserContext();
  const comments = useMemo(
    () =>
      selectedDiscussion ? flattenConnection(selectedDiscussion.comments) : [],
    [selectedDiscussion]
  );
  return (
    <Stack flexGrow={1} divider={<Divider />}>
      <Stack paddingX={2} paddingY={2.5}>
        <Typography variant="h4">
          {selectedDiscussion.title.length <= 100
            ? selectedDiscussion.title
            : selectedDiscussion.title.substring(0, 100) + '...'}
        </Typography>
      </Stack>
      <Stack
        justifyContent="space-between"
        flexGrow={1}
        sx={{ overflow: 'hidden' }}
        divider={<Divider />}
      >
        <DiscussionThread
          sx={{ padding: 2, flexGrow: 1 }}
          comments={comments}
          activeUserId={userId}
        />
        <Box padding={2}>
          <EditDiscussionForm discussion={selectedDiscussion} />
        </Box>
      </Stack>
    </Stack>
  );
}

function MessageNotFound() {
  return (
    <Box padding={2} display="flex" flexGrow={1}>
      <Typography>
        <Trans>Conversation not found.</Trans>
      </Typography>
    </Box>
  );
}

function DiscussionSidepanel({
  anchor,
  discussions,
  openDiscussionCount,
  selectedDiscussionId,
  setSelectedDiscussionId,
}: {
  anchor: GQDiscussionAnchor;
  discussions: Array<GQDiscussionFieldsFragment>;
  openDiscussionCount: number;
  selectedDiscussionId: string | null;
  setSelectedDiscussionId: (discussionId: string | null) => void;
}) {
  return (
    <Stack direction="column" sx={{ height: '100%', width: 400 }}>
      <Stack
        padding={2}
        paddingBottom={0}
        direction="row"
        gap={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h4">
            {getDisplayNameForAnchor(anchor)}
          </Typography>
          {openDiscussionCount > 0 && (
            <Tooltip
              title={
                <Trans context="Refers to the number of open conversations">
                  Open conversations
                </Trans>
              }
            >
              <Chip size="small" label={openDiscussionCount} />
            </Tooltip>
          )}
        </Stack>
        <Button
          onClick={() => setSelectedDiscussionId(NEW_COMMENT_DISCUSSION_ID)}
          disabled={selectedDiscussionId === NEW_COMMENT_DISCUSSION_ID}
          flat={true}
          startIcon={<PencilIcon />}
        >
          <Trans context="New conversation">New</Trans>
        </Button>
      </Stack>
      <Stack paddingTop={2} sx={{ overflowY: 'auto' }}>
        <DiscussionList
          {...{ discussions, selectedDiscussionId, setSelectedDiscussionId }}
        />
      </Stack>
    </Stack>
  );
}

function DiscussionList({
  discussions,
  selectedDiscussionId,
  setSelectedDiscussionId,
}: {
  discussions: Array<GQDiscussionFieldsFragment>;
  selectedDiscussionId: string | null;
  setSelectedDiscussionId: (discussionId: string | null) => void;
}) {
  return (
    <Stack gap={1} padding={2} paddingTop={0} sx={{ overflowY: 'auto' }}>
      {discussions.length > 0 ? (
        <IssuesSection
          issues={discussions}
          selectedIssueId={selectedDiscussionId}
          setSelectedIssue={setSelectedDiscussionId}
        />
      ) : (
        <BlankSlate
          icon={ConversationIcon}
          title={<Trans>No conversations</Trans>}
          size="small"
        />
      )}
    </Stack>
  );
}

/**
 * We dont have this 8x16 icon in our icon library, so hardcode it here.
 */
function ShrinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 15 6 6m-6-6v4.8m0-4.8h4.8" />
      <path d="M9 19.8V15m0 0H4.2M9 15l-6 6" />
      <path d="M15 4.2V9m0 0h4.8M15 9l6-6" />
      <path d="M9 4.2V9m0 0H4.2M9 9 3 3" />
    </svg>
  );
}

export const useDiscussionsDialog =
  createDialogHook<DiscussionsDialogProps>(DiscussionsDialog);
