import { useState } from 'react';
import { Trans } from '@lingui/react/macro';
import Button from '@watershed/ui-core/components/Button';
import {
  GQFlags,
  GQDiscussionProductSection,
} from '@watershed/shared-universal/generated/graphql';
import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import { useCommentDiscussionsForAnchorQuery } from '@watershed/shared-frontend/generated/urql';
import { isFetchingOrDataBang } from '@watershed/shared-frontend/utils/isFetchingOrStale';
import { useDiscussionsDialog } from './DiscussionsDialog';
import { isComplete } from '@watershed/shared-universal/utils/discussionUtils';
import { getSharedSidebarStyles } from '../navigation/variants/sharedSidebarStyles';
import { OpenDiscussionCount } from './OpenDiscussionCount';
import {
  routeForLearningHub,
  routeForSupport,
} from '@watershed/shared-universal/dashboardRoutes';
import UnstyledLink from '@watershed/ui-core/components/UnstyledLink';
import HelpIcon from '@watershed/icons/components/Help';
import LightBulbIcon from '@watershed/icons/components/LightBulb';
import ConversationIcon from '@watershed/icons/components/Conversation';
import { useFeatureFlag } from '../../utils/FeatureFlag';
import { useRouter } from 'next/router';
import QuestionMarkIcon from '@watershed/icons/components/QuestionMark';
import NewSupportCaseDialog from '../support/NewSupportCaseDialog';

export default function NavHelpButton({
  isOnFinancePage,
  appearsCollapsed,
}: {
  isOnFinancePage: boolean;
  appearsCollapsed: boolean;
}) {
  const [newSupportCaseDialogOpen, setNewSupportCaseDialogOpen] =
    useState(false);
  const router = useRouter();
  const productSection = isOnFinancePage
    ? GQDiscussionProductSection.Finance
    : GQDiscussionProductSection.Global;
  const newSupportUi = useFeatureFlag(GQFlags.EntFoundSupportUi);
  const anchor = { productSection };
  const [result] = useCommentDiscussionsForAnchorQuery({
    variables: {
      input: anchor,
    },
  });
  const { fetching, data } = isFetchingOrDataBang(result);
  const discussions = data?.commentDiscussionsForAnchor.discussions ?? [];
  const openDiscussionCount = newSupportUi
    ? (data?.supportCaseNotificationCount ?? 0)
    : discussions.filter((discussion) => !isComplete(discussion.state)).length;

  const [helpAnchorEl, setHelpAnchorEl] = useState<null | HTMLButtonElement>(
    null
  );

  const sharedSidebarStyles = getSharedSidebarStyles(useTheme());
  const { COLORS, BOX_SHADOW_FOCUS } = sharedSidebarStyles;

  const openDiscussionsDialog = useDiscussionsDialog();

  return (
    <>
      <Button
        variant="text"
        startIcon={<HelpIcon />}
        aria-controls="help-menu"
        aria-haspopup="true"
        onClick={(e) => setHelpAnchorEl(e.currentTarget)}
        sx={{
          minWidth: 'unset',
          width: '100%',
          height: '32px',
          justifyContent: 'flex-start',
          textAlign: 'left',
          color: COLORS.TEXT_PRIMARY,
          borderRadius: 1,
          paddingInline: 1, // Match alignment of gear icon
          paddingBlock: 1,
          gap: 1,

          '&:hover, &:focus': {
            backgroundColor: COLORS.BACKGROUND_ACTIVE,
          },
          '&:focus-visible': {
            boxShadow: `inset ${BOX_SHADOW_FOCUS}`,
          },
          '&[aria-current="page"]': {
            color: COLORS.TEXT_PRIMARY,
            backgroundColor: COLORS.BACKGROUND_ACTIVE,
          },
          '& .MuiButton-startIcon': {
            margin: 0,
            '& svg': {
              width: 16,
              height: 16,
            },
          },
        }}
        aria-current={helpAnchorEl ? 'page' : undefined}
      >
        <Box
          component="span"
          sx={{
            '[data-appears-collapsed="true"] &': {
              display: 'none',
            },
          }}
        >
          <Trans context="Navbar item that opens Learning Hub/Support menu">
            Help
          </Trans>
        </Box>
      </Button>
      <Menu
        id="help-menu"
        anchorEl={helpAnchorEl}
        keepMounted
        open={Boolean(helpAnchorEl)}
        onClose={() => setHelpAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: sharedSidebarStyles.menuPaperSxProps,
          'data-appears-collapsed': appearsCollapsed,
        }}
        MenuListProps={{
          component: 'div',
        }}
      >
        {newSupportUi ? (
          <>
            <MenuItem
              component="button"
              sx={{
                width: 1,
              }}
              disabled={fetching}
              onClick={() => {
                setNewSupportCaseDialogOpen(true);
              }}
            >
              <QuestionMarkIcon />
              <Trans context="Menu item copy to open a support case dialog">
                Ask a question…
              </Trans>
            </MenuItem>
            <MenuItem
              component="button"
              sx={{
                width: 1,
              }}
              disabled={fetching}
              onClick={() => {
                setHelpAnchorEl(null);
                void router.push(routeForSupport());
              }}
            >
              <ConversationIcon />
              <OpenDiscussionCount
                count={openDiscussionCount}
                label={
                  <Trans context="Navbar item for Support">
                    Support center
                  </Trans>
                }
              />
            </MenuItem>
            <UnstyledLink
              href={routeForLearningHub({
                from: 'Sidebar',
                viewMode: isOnFinancePage ? 'finance' : 'regular',
              })}
            >
              <MenuItem
                component="a"
                onClick={() => {
                  setHelpAnchorEl(null);
                }}
              >
                <LightBulbIcon />
                <Trans context="Navbar item for Learning Hub">
                  Learning hub
                </Trans>
              </MenuItem>
            </UnstyledLink>
            <NewSupportCaseDialog
              open={newSupportCaseDialogOpen}
              onClose={() => setNewSupportCaseDialogOpen(false)}
            />
          </>
        ) : (
          <>
            <UnstyledLink
              href={routeForLearningHub({
                from: 'Sidebar',
                viewMode: isOnFinancePage ? 'finance' : 'regular',
              })}
            >
              <MenuItem
                component="a"
                onClick={() => {
                  setHelpAnchorEl(null);
                }}
              >
                <LightBulbIcon />
                <Trans context="Navbar item for Learning Hub">
                  Learning hub
                </Trans>
              </MenuItem>
            </UnstyledLink>

            <MenuItem
              component="button"
              sx={{
                width: 1,
              }}
              disabled={fetching}
              onClick={() => {
                setHelpAnchorEl(null);
                openDiscussionsDialog({ anchor: { productSection } });
              }}
            >
              <ConversationIcon />
              <OpenDiscussionCount
                count={openDiscussionCount}
                label={<Trans context="Navbar item for Support">Support</Trans>}
              />
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
}
