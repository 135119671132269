import {
  GQBiFilterOperator,
  GQBiQueryFilter,
} from '@watershed/shared-universal/generated/graphql';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export type SupplierFilterMetric = {
  dimension: string;
  label: string;
  familyLabel?: string;
  biFilterOperators: Array<GQBiFilterOperator>;
  valueInputProps?: {
    useNumberInput?: boolean;
    useSingleSelectInput?: boolean;
    unit?: string;
  };
};

export type ParsedSupplierFilterDimension = {
  dimension: string;
  nestedDimensions: Array<string>;
};

export function parseSupplierFilterDimension(
  dimension: string
): ParsedSupplierFilterDimension {
  const [parentDimension, ...childrenDimensions] = dimension.split('.');
  return {
    dimension: parentDimension,
    nestedDimensions: childrenDimensions,
  };
}

export type ParsedSupplierFilterMetric = GQBiQueryFilter &
  ParsedSupplierFilterDimension;

export type SupplierFilterOption = string | number | boolean | null;

export function getDimension<I, R>(
  value: I,
  nestedDimensions: Array<string>
): R {
  return isEmpty(nestedDimensions) ? value : get(value, nestedDimensions);
}

export const existenceOperators = [
  GQBiFilterOperator.NotEmpty,
  GQBiFilterOperator.Empty,
];

export const selectAutocompleteOperators = [
  GQBiFilterOperator.In,
  GQBiFilterOperator.NotIn,
];

export const nullableSelectAutocompleteOperators = [
  ...selectAutocompleteOperators,
  ...existenceOperators,
];

export const numericOperators = [
  GQBiFilterOperator.Equal,
  GQBiFilterOperator.NotEqual,
  GQBiFilterOperator.GreaterThan,
  GQBiFilterOperator.GreaterThanOrEqual,
  GQBiFilterOperator.LessThan,
  GQBiFilterOperator.LessThanOrEqual,
];

export const nullableNumericOperators = [
  ...numericOperators,
  ...existenceOperators,
];

export const getOperatorLabel = (operator: GQBiFilterOperator): string => {
  switch (operator) {
    case GQBiFilterOperator.In:
      return 'is';
    case GQBiFilterOperator.NotIn:
      return 'is not';
    case GQBiFilterOperator.Empty:
      return 'is not available';
    case GQBiFilterOperator.NotEmpty:
      return 'is available';
    case GQBiFilterOperator.Equal:
      return '=';
    case GQBiFilterOperator.NotEqual:
      return '!=';
    case GQBiFilterOperator.LessThan:
      return '<';
    case GQBiFilterOperator.LessThanOrEqual:
      return '<=';
    case GQBiFilterOperator.GreaterThan:
      return '>';
    case GQBiFilterOperator.GreaterThanOrEqual:
      return '>=';
  }
};
