import Icon, { IconProps } from '../Icon';
const DatasetIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M2.879 2.879A3 3 0 0 1 4.999 2h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 .88-2.121ZM4.999 4a1 1 0 0 0-1 1v3h4V4H5Zm5 0v4h10V5a1 1 0 0 0-1-1h-9Zm10 6H10v10h9a1 1 0 0 0 1-1v-9ZM8 20V10H4v9a1 1 0 0 0 1 1h3Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default DatasetIcon;
