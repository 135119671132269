import { Box, CircularProgressProps } from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import { mixinSx } from '@watershed/style/styleUtils';
import { forwardRef } from 'react';

export default forwardRef(function CenteredCircularProgress(
  { sx, ...props }: Omit<CircularProgressProps, 'style' | 'ref'>,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <Box
      component="div"
      sx={mixinSx(
        {
          display: 'flex',
          position: 'absolute',
          inset: 0,
          justifyContent: 'center',
          alignItems: 'center',
        },
        sx
      )}
      ref={ref}
    >
      <CircularProgress {...props} />
    </Box>
  );
});
