import { combineFilters } from '@watershed/shared-universal/finance/utils/filters';
import useV3Query from './useV3Query';
import {
  GQFinanceSavedViewAllFieldsFragment,
  GQFinanceStandardView,
} from '@watershed/shared-universal/generated/graphql';
import { getRouteParams } from '@watershed/shared-universal/utils/queryParamUtils';
import { useRouter } from 'next/router';
import { useContext, createContext } from 'react';
import { FullFilters } from '@watershed/shared-universal/apiSchemas/finance/zodFinance';
import defaultYear from '@watershed/shared-universal/finance/defaultYear';

export type FinanceFiltersType = {
  fullFilters: FullFilters;
  combinedFilters: Record<string, Array<string>>;
  // We provide `year` here as a convenience since many components that need
  // filters also need the year, and it's easier to not require two contexts.
  // Technically, does that mean we're limiting context switching? ;)
  year: number;
};

export const FinanceFiltersContext = createContext<FinanceFiltersType>({
  fullFilters: {
    viewFilters: {},
    routeFilters: {},
    queryParamFilters: {},
  },
  combinedFilters: {},
  year: defaultYear,
});

/**
 * useFinanceFilters
 *
 * We have a bit of complexity in that our saved views include filters
 * *and* the use can apply filters on top of that (thus, fullFilters).
 *
 * In addition, we have Zod form elements that need to know about these
 * filters in ways where it's tricky to pass filters down.
 *
 * So this context allows us to centralize the filters logic and avoid
 * having to thread them down through the component hierarchy.
 */
export default function useFinanceFilters(
  standardView: GQFinanceStandardView | null,
  savedView?: GQFinanceSavedViewAllFieldsFragment
): FinanceFiltersType {
  const { filters: queryParamFilters, year } = useV3Query(standardView);
  const router = useRouter();
  const routeFilters = getRouteParams(router.query, router.asPath);
  // this does not count as a filter
  delete routeFilters.activeTab;

  // Remap the values in routeFilters to arrays.
  // TODO: Need to figure out a better way to keep these
  // consistent everywhere.
  Object.keys(routeFilters).forEach((key) => {
    const value = routeFilters[key];
    if (typeof value === 'string') {
      routeFilters[key] = [value];
    }
  });

  const fullFilters = {
    viewFilters: savedView?.filters ?? {},
    queryParamFilters,
    routeFilters,
  };

  const combinedFilters = combineFilters([
    savedView?.filters ?? {},
    queryParamFilters,
    routeFilters,
  ]);

  return { fullFilters, combinedFilters, year };
}

export const useFinanceFiltersContext = () => {
  const { year } = useV3Query();
  return { ...useContext(FinanceFiltersContext), year };
};
