import { Chip, SxProps, Theme, Tooltip } from '@mui/material';
import { mixinSx } from '@watershed/style/styleUtils';

export default function NavChip({
  chip,
  chipTooltip,
  isActive,
  sx,
}: {
  chip?: string | null;
  chipTooltip?: string | null;
  isActive?: boolean;
  sx?: SxProps<Theme>;
}) {
  if (!chip) return null;
  const chipElement = (
    <Chip
      label={chip}
      size="small"
      sx={mixinSx(
        {
          marginLeft: 'auto',
          color: (theme) =>
            isActive ? theme.palette.grey80 : theme.palette.surface,
          backgroundColor: (theme) =>
            isActive ? theme.palette.surface : theme.palette.grey80,
          '[data-appears-collapsed="true"] &': {
            display: 'none',
          },
        },
        sx
      )}
    />
  );
  if (!chipTooltip) return chipElement;
  return <Tooltip title={chipTooltip}>{chipElement}</Tooltip>;
}
