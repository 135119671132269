import {
  GQFinanceAssetIigccStatus,
  GQEmissionsYearSource,
  GQExternalReportType,
  GQBuildingSizeUnit,
} from '../../generated/graphql';

export function getBuildingSizeUnitFieldUnit(
  buildingSizeUnit: GQBuildingSizeUnit | null
) {
  switch (buildingSizeUnit) {
    case GQBuildingSizeUnit.SquareFeet:
      return 'ft²';
    case GQBuildingSizeUnit.SquareMeters:
      return 'm²';
    case null:
      return null;
  }
}

export function getEmissionsSourceDescriptor({
  emissionsYearSource,
  reportingYear,
  publicDisclosure,
  hasOverrides,
}: {
  emissionsYearSource: GQEmissionsYearSource;
  reportingYear: number;
  publicDisclosure:
    | { publishingYear: number; reportType: GQExternalReportType }
    | null
    | undefined;
  /**
   * Only true for estimations that have overrides (i.e. always false for
   * non-estimation)
   */
  hasOverrides: boolean;
}) {
  const { publishingYear, reportType } = publicDisclosure ?? {};

  switch (emissionsYearSource) {
    case GQEmissionsYearSource.CompanyEstimatationEngine:
    case GQEmissionsYearSource.BuildingEstimationEngine: {
      if (hasOverrides) {
        return `Estimation with overrides (${reportingYear})`;
      }
      return `Estimation (${reportingYear})`;
    }
    case GQEmissionsYearSource.WatershedMeasurement:
      return `Watershed disclosure (${reportingYear})`;
    case GQEmissionsYearSource.Survey:
      return `Portal responses (${reportingYear})`;
    case GQEmissionsYearSource.PrivateCdp:
      return `Private CDP disclosure (${reportingYear})`;
    case GQEmissionsYearSource.ManualEntry:
      return `Manual entry`;
    case GQEmissionsYearSource.SurveyEstimate:
      return `Portal (${reportingYear})`;
    case GQEmissionsYearSource.PublicDisclosure:
      const publicReportLabel = reportType
        ? `${reportType} report`
        : 'Public report';

      if (reportingYear === publishingYear) {
        return `${publicReportLabel} (${reportingYear})`;
      } else if (publishingYear != null) {
        return `${publicReportLabel} (reporting year ${reportingYear}, published ${publishingYear})`;
      }
      return `${publicReportLabel} (reporting year ${reportingYear})`;
  }
}

export const IIGCC_STATUS_LABELS: Record<GQFinanceAssetIigccStatus, string> = {
  [GQFinanceAssetIigccStatus.NotAligned]: 'Not aligned',
  [GQFinanceAssetIigccStatus.CommittedToAligning]: 'Committed to aligning',
  [GQFinanceAssetIigccStatus.Aligning]: 'Aligning',
  [GQFinanceAssetIigccStatus.Aligned]: 'Aligned',
  [GQFinanceAssetIigccStatus.AchievingNetZero]: 'Achieving net zero',
};
