import { Formik } from 'formik';
import DialogForm from '@watershed/ui-core/components/DialogForm';
import gql from 'graphql-tag';
import { useCreateSupportCaseMutation } from '@watershed/shared-frontend/generated/urql';
import { useRouter } from 'next/router';
import { routeForSupportCase } from '@watershed/shared-universal/dashboardRoutes';
import {
  GQCreateSupportCaseInput,
  GQSupportCasePriority,
  GQSupportCaseIssueType,
} from '@watershed/shared-universal/generated/graphql';
import TextField, {
  TextFieldMultiline,
} from '@watershed/ui-core/components/Form/TextField';
import { Trans } from '@lingui/react/macro';
import SelectField from '@watershed/ui-core/components/Form/SelectField';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { DialogSection } from '@watershed/ui-core/components/Dialog';
import SupportCasePriorityIcon from './SupportCasePriorityIcon';
import Button from '@watershed/ui-core/components/Button';
import {
  SalesforceCaseIssueType,
  getIssueTypeLabel,
} from '@watershed/shared-universal/entFound/supportUtils';

gql`
mutation CreateSupportCase($input: CreateSupportCaseInput!, $after: String, $before: String, $first: Int, $last: Int) {
  createSupportCase(input:$input) {
    supportCase {
      ...SupportCaseForSupportCasePage
    }
  }
}
`;

export default function NewSupportCaseDialog({
  open,
  onClose,
}: { open: boolean; onClose: () => void }) {
  const [{ fetching: isSubmitting }, executeSubmit] =
    useCreateSupportCaseMutation();
  const router = useRouter();
  return (
    <Formik<GQCreateSupportCaseInput>
      initialValues={{
        subject: '',
        description: '',
        priority: GQSupportCasePriority.Low,
        issueType: GQSupportCaseIssueType.GettingStarted,
      }}
      onSubmit={async (input) => {
        const result = await executeSubmit({
          input,
          after: null,
          before: null,
          first: 100,
          last: null,
        });
        if (result.data?.createSupportCase?.supportCase) {
          void router.push(
            routeForSupportCase(result.data.createSupportCase.supportCase.id, {
              conf: true,
            })
          );
        } else {
        }
      }}
    >
      {(form) => (
        <DialogForm
          open={open}
          onClose={onClose}
          maxWidth="sm"
          header={{
            title: (
              <Trans context="form header copy for creating support cases">
                Help
              </Trans>
            ),
          }}
          isSubmitting={isSubmitting}
          submit={
            <Button
              color="primary"
              type="submit"
              disabled={
                !form.values.subject ||
                (form.values.subject === '' && !form.values.issueType)
              }
            >
              <Trans context="Button copy to submit a support case">
                Submit
              </Trans>
            </Button>
          }
        >
          <DialogSection>
            <Typography variant="h4">
              <Trans context="Section header for a support case submission form">
                What seems to be the issue?
              </Trans>
            </Typography>
            <Typography variant="body2">
              <Trans>
                If you’ve found a bug or something isn’t working as expected,
                please tell us the steps you took so we can reproduce the issue
                and help fix it.
              </Trans>
            </Typography>
          </DialogSection>
          <TextField
            id="subject"
            required
            label={
              <Trans context="Form label for support case subject">
                Subject
              </Trans>
            }
          />
          <SelectField
            id="issueType"
            required
            label={
              <Trans context="Form label for support case feature area">
                Feature
              </Trans>
            }
          >
            {(
              Object.keys(SalesforceCaseIssueType) as Array<
                keyof typeof SalesforceCaseIssueType
              >
            ).map((value) => (
              <MenuItem key={value} value={value}>
                {getIssueTypeLabel(value)}
              </MenuItem>
            ))}
          </SelectField>
          <SelectField
            id="priority"
            required
            sx={{
              '& .MuiSelect-select': { display: 'flex', alignItems: 'center' },
            }}
            label={
              <Trans context="Form label for support case priority">
                Urgency
              </Trans>
            }
          >
            <MenuItem value={GQSupportCasePriority.High}>
              <ListItemIcon>
                <SupportCasePriorityIcon
                  priority={GQSupportCasePriority.High}
                />
              </ListItemIcon>
              <ListItemText>
                <Trans context="Menu item for support case priority">
                  Critical
                </Trans>
              </ListItemText>
            </MenuItem>
            <MenuItem value={GQSupportCasePriority.Medium}>
              <ListItemIcon>
                <SupportCasePriorityIcon
                  priority={GQSupportCasePriority.Medium}
                />
              </ListItemIcon>
              <ListItemText>
                <Trans context="Menu item for support case priority">
                  High
                </Trans>
              </ListItemText>
            </MenuItem>
            <MenuItem value={GQSupportCasePriority.Low}>
              <ListItemIcon>
                <SupportCasePriorityIcon priority={GQSupportCasePriority.Low} />
              </ListItemIcon>
              <ListItemText>
                <Trans context="Menu item for support case priority">
                  General
                </Trans>
              </ListItemText>
            </MenuItem>
          </SelectField>
          <TextFieldMultiline
            id="description"
            label={
              <Trans context="Form label for support case description">
                Message
              </Trans>
            }
            placeholder=""
            style={{ minHeight: 120 }}
          />
        </DialogForm>
      )}
    </Formik>
  );
}
