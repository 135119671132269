import { Box, Stack } from '@mui/material';
import { Trans, useLingui } from '@lingui/react/macro';
import { useEffect, useMemo } from 'react';

/* need to import react-hook-form for typescript to infer the type of ZodForm below */
import 'react-hook-form';
import Field from '@watershed/ui-core/components/Form/Field';
import {
  getCommonFieldProps,
  useFieldPropsFromTsController,
} from './fieldUtils';
import { isZodTypeArray, unwrapNullishZodType } from './utils';
import {
  CommonZodFieldProps,
  CommonControllerProps,
  ZodFormFragmentField,
  useFieldInfo,
} from './ZodForm';
import CircleCloseIcon from '@watershed/icons/components/CircleClose';
import AddIcon from '@watershed/icons/components/Add';
import Button from '@watershed/ui-core/components/Button';
import IconButton from '@watershed/ui-core/components/IconButton';

export function AbstractZodMultiInputField<
  T extends string | number,
  C extends CommonZodFieldProps & CommonControllerProps<T | null | undefined>,
>({ ...restProps }: C) {
  const { t } = useLingui();
  const {
    onChange,
    value: _value,
    ...tsProps
  } = useFieldPropsFromTsController<Array<T | null | undefined> | null>();

  const { zodType: wrappedZodType } = useFieldInfo();

  const strictLength = useMemo(() => {
    const zodType = unwrapNullishZodType(wrappedZodType);
    return isZodTypeArray(zodType) ? zodType._def.exactLength?.value : null;
  }, [wrappedZodType]);

  const zodType = unwrapNullishZodType(wrappedZodType);

  // This prevents a brief flash from no field being rendered before the useEffect update
  const value =
    strictLength && _value?.length !== strictLength
      ? [...Array(strictLength ?? 0)].fill(null)
      : (_value ?? []);

  useEffect(() => {
    if (strictLength && _value?.length !== strictLength) {
      onChange([...Array(strictLength)].fill(null));
    }
  }, [_value, onChange, strictLength]);

  const append = (val: T | null) => {
    onChange([...value, val]);
  };

  const remove = (index: number) => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  return (
    <Field
      inputId={tsProps.id}
      label={tsProps.label}
      {...getCommonFieldProps(restProps)}
    >
      <Stack gap={1}>
        {value.map((f, index) => {
          return (
            <Stack direction="row" key={index} gap={2}>
              <ZodFormFragmentField
                props={{ ...restProps, label: '' } as any}
                schema={isZodTypeArray(zodType) ? zodType.element : zodType}
                schemaKey={index}
              />
              {!strictLength && (
                <IconButton
                  title={t({
                    context:
                      'Used in a generic multiple input field with icon button to remove items in a list',
                    message: 'Remove this value',
                  })}
                  onClick={() => remove(index)}
                >
                  <CircleCloseIcon />
                </IconButton>
              )}
            </Stack>
          );
        })}
        {!strictLength && (
          <Box>
            <Button
              onClick={() => {
                append(null);
              }}
              startIcon={<AddIcon />}
            >
              <Trans context="Button copy">Add</Trans>
            </Button>
          </Box>
        )}
      </Stack>
    </Field>
  );
}
