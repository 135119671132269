/*
  This file is semi-automatically generated from
  cliq/taxonomy/scripts/generate_sics__ceda_5_weighted_mapping.py in the cliq repo
  Do not manually modify this file
 */

import type { WeightedBeaCodes } from './industryCodeUtils';

export const SICS_CODES: {
  [key: string]: {
    label: string;
    BEA_CODES: WeightedBeaCodes;
  };
} = {
  ['EM-EP']: {
    label: 'Oil & Gas - Exploration & Production',
    BEA_CODES: { '211000': 0.8590436331625076, '213111': 0.14095636683749233 },
  },
  ['EM-SV']: {
    label: 'Oil & Gas - Services',
    BEA_CODES: { '333130': 0.09722806263413479, '211000': 0.9027719373658653 },
  },
  ['EM-RM']: {
    label: 'Oil & Gas - Refining & Marketing',
    BEA_CODES: { '324110': 1.0 },
  },
  ['EM-MD']: {
    label: 'Oil & Gas - Midstream',
    BEA_CODES: { '424700': 0.8017609498154246, '486000': 0.19823905018457547 },
  },
  ['EM-CO']: {
    label: 'Coal Operations',
    BEA_CODES: {
      '324122': 0.10117051132863303,
      '212100': 0.42982310317514644,
      '324190': 0.34125734625426596,
      '324121': 0.12774903924195458,
    },
  },
  ['RT-CH']: {
    label: 'Chemicals',
    BEA_CODES: {
      '325190': 0.22000955389991872,
      '325110': 0.1748933566873109,
      '325211': 0.14529302816724354,
      '326190': 0.13992775910763222,
      '326110': 0.06753601568850934,
      '3259A0': 0.06482237288703771,
      '325180': 0.06334570870661985,
      '325310': 0.04448094667415335,
      '325414': 0.040545410356762734,
      '325510': 0.03914584782481165,
    },
  },
  ['EM-CM']: {
    label: 'Construction Materials',
    BEA_CODES: {
      '327320': 0.2240925354996962,
      '2123A0': 0.1600018003015505,
      '212310': 0.14215631118212302,
      '327390': 0.09462835024866666,
      '327100': 0.08031595292211445,
      '327400': 0.06653239417602448,
      '327310': 0.06416949839098049,
      '327330': 0.060085064248261585,
      '327993': 0.05466165582735108,
      '327910': 0.05335643720323154,
    },
  },
  ['RT-CP']: {
    label: 'Containers & Packaging',
    BEA_CODES: {
      '332430': 0.16236139281789183,
      '322210': 0.4608683220600981,
      '327200': 0.20488397651831267,
      '322220': 0.17188630860369739,
    },
  },
  ['EM-MM']: {
    label: 'Metals & Mining',
    BEA_CODES: {
      '331490': 0.0780906779870178,
      '331420': 0.13412271392255104,
      '331313': 0.2285768936495792,
      '331520': 0.07746748278500383,
      '212230': 0.06863785571488511,
      '331510': 0.11609941506034505,
      '2122A0': 0.13359207246341043,
      '331410': 0.16341288841720758,
    },
  },
  ['EM-IS']: {
    label: 'Iron & Steel Producers',
    BEA_CODES: { '331200': 0.06980784880097946, '331110': 0.9301921511990205 },
  },
  ['RR-FM']: {
    label: 'Forestry Management',
    BEA_CODES: {
      '3219A0': 0.19594503778910474,
      '321910': 0.178720236556694,
      '113000': 0.18278346998066605,
      '321100': 0.2678115404100454,
      '321200': 0.17473971526348983,
    },
  },
  ['RR-PP']: {
    label: 'Pulp & Paper Products',
    BEA_CODES: {
      '322130': 0.26406223447442456,
      '322110': 0.07116557466815204,
      '322291': 0.09801552085496876,
      '322230': 0.06754026547837087,
      '322299': 0.048479069503974626,
      '322120': 0.45073733502010915,
    },
  },
  ['RT-AE']: {
    label: 'Aerospace & Defence',
    BEA_CODES: {
      '336414': 0.08619122505807159,
      '33641A': 0.02546012417461394,
      '336412': 0.19148475710926116,
      '336413': 0.1641057456007464,
      '336992': 0.027859935652349555,
      '336411': 0.5048982124049574,
    },
  },
  ['CG-BF']: {
    label: 'Building Products & Furnishings',
    BEA_CODES: {
      '2332A0': 0.3877597001045032,
      '233210': 0.22124004103429432,
      '233262': 0.39100025886120243,
    },
  },
  ['IF-EN']: {
    label: 'Engineering & Construction Services',
    BEA_CODES: {
      '2332C0': 0.22015980198841445,
      '233240': 0.20488678738109112,
      '2332D0': 0.1690513087418435,
      '233230': 0.08806788968647564,
      '230301': 0.31783421220217534,
    },
  },
  ['RT-EE']: {
    label: 'Electrical & Electronic Equipment',
    BEA_CODES: {
      '335920': 0.1567875197388641,
      '335930': 0.12531131765528788,
      '335312': 0.11588965312589421,
      '335313': 0.11346270017062857,
      '335120': 0.10758078361965726,
      '335999': 0.10399864344987653,
      '335314': 0.10300242695295526,
      '335911': 0.06649215214557479,
      '335311': 0.06072681412083894,
      '335228': 0.04674798902042244,
    },
  },
  ['RT-IG']: {
    label: 'Industrial Machinery & Goods',
    BEA_CODES: {
      '550000': 0.6019930986245086,
      '332710': 0.05158707071647345,
      '333120': 0.0500485475177415,
      '332310': 0.049010367577756835,
      '332320': 0.045526065039452134,
      '336120': 0.0446236371464144,
      '333111': 0.04434179003941981,
      '333415': 0.0380247947881557,
      '333920': 0.03777268384382567,
      '333618': 0.03707194470625196,
    },
  },
  ['IF-WM']: { label: 'Waste Management', BEA_CODES: { '562111': 1.0 } },
  ['SV-PS']: {
    label: 'Professional & Commercial Services',
    BEA_CODES: {
      '541610': 0.1255923910906185,
      '5419A0': 0.05172490489185619,
      '541300': 0.1849833250490449,
      '541940': 0.016451887625078248,
      '541700': 0.3067301301181269,
      '541400': 0.017132682065772738,
      '541920': 0.007267609592754696,
      '561300': 0.15884236578646907,
      '5416A0': 0.028741000909659996,
      '541200': 0.10253370287061876,
    },
  },
  ['TR-AF']: { label: 'Air Freight & Logistics', BEA_CODES: { '492000': 1.0 } },
  ['TR-AL']: {
    label: 'Airlines',
    BEA_CODES: { '481000': 0.6033747381023645, '48A000': 0.3966252618976354 },
  },
  ['TR-MT']: {
    label: 'Marine Transportation',
    BEA_CODES: { '48A000': 0.7354740237965843, '483000': 0.2645259762034157 },
  },
  ['TR-RA']: { label: 'Rail Transportation', BEA_CODES: { '482000': 1.0 } },
  ['TR-RO']: {
    label: 'Road Transportation',
    BEA_CODES: {
      '532100': 0.21697543029825253,
      '484000': 0.6386064030131827,
      '485000': 0.1444181666885648,
    },
  },
  ['TR-AP']: {
    label: 'Auto Parts',
    BEA_CODES: {
      '336390': 0.2102105172101948,
      '336350': 0.1415977918105109,
      '336370': 0.10993763909313244,
      '336310': 0.10772787681951511,
      '336360': 0.08751759552700078,
      '3363A0': 0.08570496134882002,
      '326210': 0.0779786573138413,
      '336320': 0.07280812815048403,
      '326290': 0.06725226677571305,
      '336214': 0.039264565950787575,
    },
  },
  ['TR-AU']: {
    label: 'Automobiles',
    BEA_CODES: {
      '336111': 0.2356037178005845,
      '336991': 0.02496091891524502,
      '336112': 0.7394353632841705,
    },
  },
  ['CG-AM']: {
    label: 'Appliance Manufacturing',
    BEA_CODES: {
      '423600': 0.9346391258444579,
      '334300': 0.01897155885458161,
      '333112': 0.04638931530096056,
    },
  },
  ['CG-HP']: {
    label: 'Household & Personal Products',
    BEA_CODES: {
      '33712N': 0.012445236146550478,
      '337110': 0.05272736026190362,
      '325610': 0.22013384045062828,
      '446000': 0.46103220836743536,
      '337121': 0.04263636801309518,
      '337122': 0.019777574502912714,
      '325620': 0.1474604015213519,
      '332200': 0.04378701073612248,
    },
  },
  ['IF-HB']: {
    label: 'Home Builders',
    BEA_CODES: {
      '2334A0': 0.41942121579928465,
      '233412': 0.0688569024446546,
      '233411': 0.33011152231811536,
      '230302': 0.1816103594379454,
    },
  },
  ['CG-TS']: {
    label: 'Toys & Sporting Goods',
    BEA_CODES: { '339920': 0.8425561674971589, '339930': 0.15744383250284116 },
  },
  ['CG-AA']: {
    label: 'Apparel, Accessories & Footwear',
    BEA_CODES: {
      '313300': 0.10054669195581836,
      '316000': 0.07659265870802187,
      '314900': 0.1166796831418052,
      '313100': 0.05384357915876381,
      '339910': 0.12664286511212763,
      '315000': 0.1434899029342854,
      '314110': 0.10136115140020083,
      '339920': 0.10753096061586523,
      '314120': 0.0415820595782662,
      '313200': 0.13173044739484546,
    },
  },
  ['SV-CA']: { label: 'Casinos & Gaming', BEA_CODES: { '713200': 1.0 } },
  ['SV-HL']: { label: 'Hotels & Lodging', BEA_CODES: { '721000': 1.0 } },
  ['SV-LF']: {
    label: 'Leisure Facilities',
    BEA_CODES: { '713100': 0.1549428567788296, '713900': 0.8450571432211704 },
  },
  ['FB-RN']: {
    label: 'Restaurants',
    BEA_CODES: {
      '722211': 0.40996607451017425,
      '722110': 0.3850002531752972,
      '722A00': 0.20503367231452857,
    },
  },
  ['SV-ED']: {
    label: 'Education',
    BEA_CODES: {
      '611B00': 0.21719983530549042,
      '611100': 0.12347016105452224,
      '611A00': 0.6593300036399874,
    },
  },
  ['CG-MR']: {
    label: 'Multiline and Specialty Retailers & Distributors',
    BEA_CODES: {
      '423400': 0.13869710573517308,
      '444000': 0.08135122215773956,
      '448000': 0.09024276506884459,
      '4B0000': 0.13535903122016965,
      '423A00': 0.17117298198886968,
      '452000': 0.14553697902429114,
      '447000': 0.049821145865158456,
      '423100': 0.07563622223813986,
      '441000': 0.112182546701614,
    },
  },
  ['CG-EC']: { label: 'E-Commerce', BEA_CODES: { '454000': 1.0 } },
  ['HC-DR']: { label: 'Drug Retailers', BEA_CODES: { '446000': 1.0 } },
  ['FB-FR']: {
    label: 'Food Retailers & Distributors',
    BEA_CODES: {
      '424A00': 0.48146967941290075,
      '424400': 0.21374082657396679,
      '445000': 0.3047894940131325,
    },
  },
  ['FB-AB']: {
    label: 'Alcoholic Beverages',
    BEA_CODES: {
      '312130': 0.24272935676720456,
      '312120': 0.4652312671371421,
      '312140': 0.29203937609565334,
    },
  },
  ['FB-NB']: { label: 'Non-Alcoholic Beverages', BEA_CODES: { '312110': 1.0 } },
  ['FB-AG']: {
    label: 'Agricultural Products',
    BEA_CODES: {
      '1111B0': 0.2211892586828547,
      '1121A0': 0.18753322829165497,
      '1111A0': 0.10213289383379572,
      '112A00': 0.09599472213898368,
      '112300': 0.08980821838358256,
      '112120': 0.08797643328725677,
      '115000': 0.06695682013706972,
      '111300': 0.061529129732917034,
      '111400': 0.044827986196363495,
      '111900': 0.04205130931552136,
    },
  },
  ['FB-MP']: {
    label: 'Meat, Poultry & Dairy',
    BEA_CODES: {
      '31161A': 0.2820934693451688,
      '311615': 0.11612037207958457,
      '311810': 0.09308811608626211,
      '311420': 0.07656685152933056,
      '31151A': 0.07544322897019992,
      '311513': 0.07507604516962688,
      '311119': 0.0744841368572277,
      '311224': 0.07406879780412048,
      '311300': 0.06822796696549677,
      '311910': 0.06483101519298218,
    },
  },
  ['FB-TB']: { label: 'Tobacco', BEA_CODES: { '312200': 1.0 } },
  ['HC-MS']: {
    label: 'Medical Equipment & Supplies',
    BEA_CODES: {
      '339113': 0.37606807387240865,
      '339114': 0.05021862367264199,
      '339112': 0.4352791429480607,
      '339116': 0.05397824370352037,
      '339115': 0.08445591580336834,
    },
  },
  ['HC-DI']: {
    label: 'Health Care Distributors',
    BEA_CODES: { '424200': 1.0 },
  },
  ['HC-DY']: {
    label: 'Health Care Delivery',
    BEA_CODES: {
      '812100': 0.03551475786775087,
      '621100': 0.21769716842787643,
      '622000': 0.44677191966617785,
      '621200': 0.05620084294708399,
      '621900': 0.017764197196749893,
      '623A00': 0.08826660876388041,
      '621600': 0.04579302691011012,
      '623B00': 0.02156826339893551,
      '621300': 0.04310400892877748,
      '621500': 0.027319205892657464,
    },
  },
  ['HC-MC']: { label: 'Managed Care', BEA_CODES: { '621400': 1.0 } },
  ['TC-SI']: {
    label: 'Software & IT Services',
    BEA_CODES: {
      '54151A': 0.10678462235139863,
      '541512': 0.21043783500608226,
      '511200': 0.21919867877004856,
      '518200': 0.16484445062702188,
      '541511': 0.29873441324544864,
    },
  },
  ['HC-BP']: {
    label: 'Biotechnology & Pharmaceuticals',
    BEA_CODES: {
      '541700': 0.7152319274572765,
      '339112': 0.055520979904030016,
      '339113': 0.04796845497949514,
      '325412': 0.18127863765919833,
    },
  },
  ['FN-CB']: { label: 'Commercial Banks', BEA_CODES: { '52A000': 1.0 } },
  ['FN-MF']: {
    label: 'Mortgage Finance',
    BEA_CODES: { '522A00': 0.6728070134358624, '525000': 0.32719298656413764 },
  },
  ['FN-CF']: { label: 'Consumer Finance', BEA_CODES: { '522A00': 1.0 } },
  ['FN-AC']: {
    label: 'Asset Management & Custody Activities',
    BEA_CODES: { '523900': 1.0 },
  },
  ['FN-IB']: {
    label: 'Investment Banking & Brokerage',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['FN-EX']: {
    label: 'Security & Commodity Exchanges',
    BEA_CODES: { '523A00': 1.0 },
  },
  ['FN-IN']: {
    label: 'Insurance',
    BEA_CODES: {
      '524113': 0.09880655217451507,
      '5241XX': 0.5303326859000508,
      '524200': 0.37086076192543416,
    },
  },
  ['TC-IM']: {
    label: 'Internet Media & Services',
    BEA_CODES: { '518200': 1.0 },
  },
  ['TC-TL']: {
    label: 'Telecommunication Services',
    BEA_CODES: {
      '517110': 0.5506797022672411,
      '334220': 0.04802233331430816,
      '334210': 0.016016397460475205,
      '517A00': 0.041780957592890404,
      '334290': 0.007945247791681076,
      '517210': 0.33555536157340415,
    },
  },
  ['TC-HW']: {
    label: 'Hardware',
    BEA_CODES: {
      '425000': 0.25082072877277417,
      '334511': 0.19608542454451702,
      '334510': 0.11890254382949467,
      '33441A': 0.10638105878308697,
      '334418': 0.0718459951873496,
      '334516': 0.058671364730147814,
      '334515': 0.053330182193193534,
      '33451A': 0.0498066345823307,
      '334118': 0.04921794431075971,
      '334513': 0.04493812306634582,
    },
  },
  ['TC-SC']: {
    label: 'Semiconductors',
    BEA_CODES: { '334413': 0.8257002801120448, '333242': 0.17429971988795517 },
  },
  ['SV-AD']: { label: 'Advertising & Marketing', BEA_CODES: { '541800': 1.0 } },
  ['SV-ME']: {
    label: 'Media & Entertainment',
    BEA_CODES: {
      '512100': 0.3561375953712728,
      '515100': 0.1582309521296739,
      '519130': 0.15589982679521483,
      '511130': 0.09578721264457013,
      '515200': 0.06427822027427195,
      '512200': 0.048891551455478924,
      '511120': 0.03515932977818613,
      '5111A0': 0.03284993264258355,
      '511110': 0.02686226184342039,
      '5191A0': 0.025903117065327382,
    },
  },
  ['IF-EU']: {
    label: 'Electric Utilities & Power Generators',
    BEA_CODES: { '221100': 1.0 },
  },
  ['IF-GU']: {
    label: 'Gas Utilities & Distributors',
    BEA_CODES: { '221200': 1.0 },
  },
  ['IF-WU']: {
    label: 'Water Utilities & Services',
    BEA_CODES: { '221300': 1.0 },
  },
  ['IF-RE']: {
    label: 'Real Estate',
    BEA_CODES: {
      '493000': 0.0410273938013556,
      '531ORE': 0.3360485124029243,
      '531HSO': 0.46704678441753866,
      '531HST': 0.15587730937818142,
    },
  },
  ['IF-RS']: { label: 'Real Estate Services', BEA_CODES: { '531ORE': 1.0 } },
  ['FB-PF']: {
    label: 'Processed Foods',
    BEA_CODES: {
      '31161A': 0.2820934693451688,
      '311615': 0.11612037207958457,
      '311810': 0.09308811608626211,
      '311420': 0.07656685152933056,
      '31151A': 0.07544322897019992,
      '311513': 0.07507604516962688,
      '311119': 0.0744841368572277,
      '311224': 0.07406879780412048,
      '311300': 0.06822796696549677,
      '311910': 0.06483101519298218,
    },
  },
  ['RR-BI']: { label: 'Biofuels', BEA_CODES: { '325190': 1.0 } },
  ['RR-FC']: {
    label: 'Fuel Cells & Industrial Batteries',
    BEA_CODES: { '334413': 0.8825929114113552, '335911': 0.11740708858864478 },
  },
  ['RR-ST']: {
    label: 'Solar Technology & Project Developers',
    BEA_CODES: { '334413': 1.0 },
  },
  ['RR-WT']: {
    label: 'Wind Technology & Project Developers',
    BEA_CODES: { '333611': 1.0 },
  },
  ['TC-ES']: {
    label: 'Electronic Manufacturing Services & Original Design Manufacturing',
    BEA_CODES: {
      '334111': 0.028426640097572706,
      '334112': 0.029800903578238537,
      '425000': 0.200542834074863,
      '33441A': 0.08505660247710993,
      '423600': 0.4957586793327951,
      '334118': 0.03935203476886606,
      '336320': 0.06361809217872297,
      '334418': 0.05744421349183172,
    },
  },
  ['TR-CR']: { label: 'Car Rental & Leasing', BEA_CODES: { '532100': 1.0 } },
  ['TR-CL']: { label: 'Cruise Lines', BEA_CODES: { '483000': 1.0 } },
};
