import Icon, { IconProps } from '../Icon';
const ChartTreemapIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M4 2a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4Zm0 2h5v7H4V4Zm11-2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-5Zm0 2h5v3h-5V4Zm-2 9a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2v-7Zm7 0h-5v7h5v-7ZM4 15a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H4Zm0 2h5v3H4v-3Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ChartTreemapIcon;
