import React, { useMemo, useEffect } from 'react';
import {
  useUserContext,
  useParsedUserContext,
  UserContextProvider,
} from '../utils/UserContext';
import { ThemeProvider } from '@mui/material';
import {
  FeatureFlagContextProvider,
  FeatureFlagMetadataMap,
  FeatureFlagsMap,
} from '../utils/FeatureFlag';
import AuthenticatedAnalyticsTracker from '../components/AuthenticatedAnalyticsTracker';
import { configureSharedSentryContext } from '@watershed/shared-universal/utils/universalSentryUtils';
import { PortalContextProviderWithNextRouter } from '@watershed/ui-core/utils/PortalContext';
import AdminChipBar from '../components/AdminChipBar';
import {
  GQActiveOrganizationFieldsFragment,
  GQFlagsInitFieldsFragment,
} from '@watershed/shared-universal/generated/graphql';
import { ErrorBoundary } from 'react-error-boundary';
import { LoginAsCrossOrgWarning } from '../utils/useLoginAsCrossOrgWarning';
import { UserOnboardingsContextProvider } from '../utils/UserOnboardingsContext';
import {
  switchPinnedOrganizationId,
  clearPinnedOrganizationId,
  getPinnedOrganizationId,
  initPinnedOrganizationId,
} from '@watershed/shared-frontend/utils/pinnedOrganizationId';
import { ORG_ID_PARAM } from '@watershed/shared-universal/utils/pinnedOrganizationUniversalConstants';
// eslint-disable-next-line no-restricted-imports
import { getTheme } from '@watershed/shared-frontend/styles/theme';
import { useSearchParams } from '@watershed/shared-frontend/utils/queryParamHooks';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import { ViewerNoPermissionPage } from './NotFoundPage';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import {
  WatershedProduct,
  determineCurrentProduct,
  useCurrentProductContext,
} from '../utils/CurrentProductContext';
import { useRouter } from 'next/router';
import { useSyncAtom } from '@watershed/shared-frontend/components/jotai';
import { atomLocale } from '@watershed/intl/frontend/atoms';
import { atomUserContext } from '../global-atoms/userAtoms';
import { ErrorFallback } from './ErrorFallback';
import { DialogContextProvider } from '@watershed/ui-core/components/Dialog';
import deriveDefaultLocaleForFrontend from '@watershed/intl/frontend/deriveDefaultLocaleForFrontend';
import { PrivilegeSyncer } from './PrivilegeSyncer';

function CrossOrgWarning() {
  const { orgId, orgName, loginAsUser } = useUserContext();
  return (
    <LoginAsCrossOrgWarning
      orgId={orgId}
      orgName={orgName}
      loginAsUserId={loginAsUser?.id}
    />
  );
}

type Key = 'ctrl' | 'shift' | 'alt' | string;

export const useKeyboardShortcut = (keys: Array<Key>, callback: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        keys.every(
          (key) =>
            (key === 'ctrl' && event.ctrlKey) ||
            (key === 'shift' && event.shiftKey) ||
            (key === 'alt' && event.altKey) ||
            (typeof key === 'string' &&
              event.key &&
              event.key.toLowerCase() === key)
        )
      ) {
        callback();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [keys, callback]);
};

declare global {
  interface Window {
    // window.wsDebug helps with debugging in the browser console.
    // It's typed as `unknown` to prevent any other code from relying on it.
    wsDebug: unknown;
  }
}

export default function SharedAppContainer({
  activeOrganization,
  flags: initialFlags,
  children,
  preferredLocale,
}: {
  activeOrganization: GQActiveOrganizationFieldsFragment;
  flags: Array<GQFlagsInitFieldsFragment>;
  preferredLocale: string | null;
  children: React.ReactNode;
}) {
  const [flags, flagMetadata]: [FeatureFlagsMap, FeatureFlagMetadataMap] =
    useMemo(() => {
      if (initialFlags === null) {
        return [new Map(), new Map()];
      }

      return [
        new Map(initialFlags.map((f) => [f.name, f.activated])),
        new Map(
          initialFlags.map((f) => [
            f.name,
            {
              description: f.description,
              featureTags: f.featureTags,
              team: f.team,
            },
          ])
        ),
      ];
    }, [initialFlags]);
  // This is where we would swap out the theme based on a feature flag, user/org
  // setting, etc.
  const derivedLocale = deriveDefaultLocaleForFrontend(preferredLocale);

  const snackbar = useSnackbar();
  const parsedUserContext = useParsedUserContext(
    activeOrganization,
    flags,
    preferredLocale
  );
  useSyncAtom(atomUserContext, parsedUserContext);
  useSyncAtom(atomLocale, derivedLocale);
  const currentTheme = useMemo(() => getTheme(derivedLocale), [derivedLocale]);

  const userId = activeOrganization.user.id;
  const userName = activeOrganization.user.name;
  const loginAsUserId = activeOrganization.loginAsUser?.id;
  const loginAsUserName = activeOrganization.loginAsUser?.name;
  const isWatershedEmployee = activeOrganization.user.isWatershedEmployee;
  const isWatershedContractor = activeOrganization.user.isWatershedContractor;
  const isE2ETester = activeOrganization.user.isE2ETester;
  const orgId = activeOrganization.id;
  const orgName = activeOrganization.name;
  const testOrg = activeOrganization.testOrg;
  const demoOrg = activeOrganization.demoOrg;
  const referrer = document.referrer;

  const featureFlags: Record<string, boolean> = useMemo(() => {
    const myFlags: Record<string, boolean> = {};

    for (const [flag, isEnabled] of flags) {
      myFlags[flag] = isEnabled;
    }
    return myFlags;
  }, [flags]);

  // Set a CTRL+C command to copy the URL with the orgId appended
  const copyUrlWithOrg = () => {
    const url = new URL(window.location.href);
    url.searchParams.append(ORG_ID_PARAM, orgId);
    // TODO: URGENT Please fix this by await-ing or void-ing this line.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(url.toString());
    snackbar.enqueueSnackbar('URL copied to clipboard');
  };
  // use two shortcuts to accomdate Mac and Windows users
  useKeyboardShortcut(['ctrl', 'shift', 'c'], copyUrlWithOrg);
  useKeyboardShortcut(['cmd', 'shift', 'c'], copyUrlWithOrg);

  // Get our organizationId from session storage in the case that we've switched accounts.
  const pinnedOrganizationId = getPinnedOrganizationId();

  // Get orgId from the URL in the case that we're using a cross-org-sharing link.
  const urlOrgId = useSearchParams().get(ORG_ID_PARAM);
  const urlOrgIsValid =
    urlOrgId &&
    parsedUserContext.accessibleOrgs.map((org) => org.id).includes(urlOrgId);

  const redirectUrl = new URL(window.location.href);
  redirectUrl.searchParams.delete(ORG_ID_PARAM);
  const searchParamsString = redirectUrl.searchParams.toString();
  const redirectClean =
    searchParamsString.length > 0
      ? `${redirectUrl.pathname}?${searchParamsString}`
      : redirectUrl.pathname;

  if (urlOrgIsValid && urlOrgId !== orgId) {
    // if we have a valid cross-org-sharing link, switch to that org and redirect to the link path
    switchPinnedOrganizationId({
      orgId: urlOrgId,
      shouldOpenInNewTab: false,
      redirect: redirectClean,
    });
  } else if (urlOrgIsValid && urlOrgId === orgId) {
    // don't switch orgs but trim out the org id param in the url
    window.history.replaceState(window.history.state, '', redirectClean);
  } else if (pinnedOrganizationId === null) {
    initPinnedOrganizationId(orgId);
  } else if (pinnedOrganizationId !== orgId) {
    // This should never happen unless the data stored in sessionStorage
    // is malformed in some way. However, this is a scary thing to fix
    // so we'll be as defensive as possible. Reset the value in
    // session storage, warn and then force a refresh to clear the cache.
    console.warn(
      `ERROR: initOrganizationId was called with inconsistent ids - {orgId: ${orgId} vs ${pinnedOrganizationId}}`
    );
    clearPinnedOrganizationId();
  }

  const { currentProduct, setCurrentProduct } = useCurrentProductContext();
  const { pathname } = useRouter();
  const { canAccessCorporate } = parsedUserContext;
  // We determine the current product in the SharedAppContainer
  // because we need user context to determine what to initially display,
  // but the CurrentProductProvider component lives higher up in the tree
  useEffect(() => {
    if (currentProduct === WatershedProduct.Unknown) {
      setCurrentProduct(determineCurrentProduct(pathname, canAccessCorporate));
    }
  }, [currentProduct, canAccessCorporate, pathname, setCurrentProduct]);

  useEffect(() => {
    configureSharedSentryContext({
      orgId,
      orgName,
      testOrg,
      demoOrg,
      userId,
      userName,
      loginAsUserId,
      loginAsUserName,
      isWatershedEmployee,
      isWatershedContractor,
      isE2ETester,
      referrer,
      featureFlags,
      footprintVersionOverride: undefined,
      sessionId: activeOrganization.sessionId,
    });
  }, [
    userId,
    userName,
    orgId,
    orgName,
    testOrg,
    demoOrg,
    loginAsUserId,
    loginAsUserName,
    isWatershedEmployee,
    isWatershedContractor,
    isE2ETester,
    referrer,
    featureFlags,
    activeOrganization.sessionId,
  ]);

  if (!window.wsDebug) {
    window.wsDebug = {
      currentTheme,
      activeOrganization,
      userContext: parsedUserContext,
      flags,
    };
  }

  if (urlOrgId && !urlOrgIsValid) {
    return <ViewerNoPermissionPage orgName={urlOrgId} />;
  }
  const disableAdminChip = !!process.env.NEXT_PUBLIC_DISABLE_ADMIN_CHIP;
  return (
    <UserContextProvider parsedUserContext={parsedUserContext}>
      <PrivilegeSyncer />
      <AuthenticatedAnalyticsTracker>
        <FeatureFlagContextProvider
          flags={flags}
          flagMetadata={flagMetadata}
          enableOverrides={isWatershedEmployee || isNotNullish(loginAsUserName)}
        >
          <ThemeProvider theme={currentTheme}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <DialogContextProvider ErrorFallback={ErrorFallback}>
                <UserOnboardingsContextProvider>
                  {/* <PortalContextProviderWithNextRouter> should be the inner-most, so it can
              take advantage of all other context providers. This provider just
              provides a place in the React tree to insert portals (modals,
              dialogs) into programatically. */}
                  <PortalContextProviderWithNextRouter>
                    {children}
                    <CrossOrgWarning />
                    {!disableAdminChip && <AdminChipBar />}
                  </PortalContextProviderWithNextRouter>
                </UserOnboardingsContextProvider>
              </DialogContextProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </FeatureFlagContextProvider>
      </AuthenticatedAnalyticsTracker>
    </UserContextProvider>
  );
}
