import { z } from 'zod';
import {
  extendZodWithOpenApi,
  OpenAPIRegistry,
  ResponseConfig,
} from '@asteasolutions/zod-to-openapi';

extendZodWithOpenApi(z);

export const registry = new OpenAPIRegistry();

export const beforeParam = registry.registerParameter(
  'before',
  z.string().openapi({
    param: {
      name: 'before',
      in: 'query',
      required: false,
    },
    example: '112358',
    description:
      'Only return objects before (but not including) the given object. Useful for paginating through the list of objects--pass the oldest ID in a page of results to retrieve the next (i.e., less recently created) page of results. Only one of `objectsBefore` and `objectsAfter` can be provided.',
  })
);

export const afterParam = registry.registerParameter(
  'after',
  z.string().openapi({
    param: {
      name: 'after',
      in: 'query',
      required: false,
    },
    example: '112358',
    description:
      'Only return objects after (but not including) the given object. Useful for paginating through the list of objects--pass the newset ID in a page of results to retrieve the previous (i.e., more recently created) page of results. Only one of `objectsBefore` and `objectsAfter` can be provided.',
  })
);

export const firstParam = registry.registerParameter(
  'first',
  z
    .number()
    .int()
    .min(1)
    .max(10000)
    .openapi({
      param: {
        name: 'first',
        in: 'query',
        required: false,
      },
      example: 20,
      description:
        'The maximum number of objects to return. If not specified, up to 20 objects will be returned.',
    })
);

export const lastParam = registry.registerParameter(
  'last',
  z
    .number()
    .int()
    .min(1)
    .max(10000)
    .openapi({
      param: {
        name: 'last',
        in: 'query',
        required: false,
      },
      example: 20,
      description:
        'The maximum number of objects to return. If not specified, up to 20 objects will be returned.',
    })
);

export const paginationParams = {
  before: beforeParam,
  after: afterParam,
  first: firstParam,
  last: lastParam,
};

export const createdBeforeParam = registry.registerParameter(
  'createdBefore',
  z.string().openapi({
    param: {
      name: 'createdBefore',
      in: 'query',
      required: false,
    },
    example: '2021-01-01T00:00:00Z',
    description:
      'Only return objects created strictly before (i.e., exclusive of) the given RFC 3339 datetime. If not provided, no upper limit will be applied.',
  })
);

export const createdAfterParam = registry.registerParameter(
  'createdAfter',
  z.string().openapi({
    param: {
      name: 'createdAfter',
      in: 'query',
      required: false,
    },
    example: '2021-01-01T00:00:00Z',
    description:
      'Only return objects created at or after (i.e., inclusive of) the given RFC 3339 datetime. If not provided, no lower limit will be applied.',
  })
);

export const updatedBeforeParam = registry.registerParameter(
  'updatedBefore',
  z.string().openapi({
    param: {
      name: 'updatedBefore',
      in: 'query',
      required: false,
    },
    example: '2021-01-01T00:00:00Z',
    description:
      'Only return objects updated strictly before (i.e., exclusive of) the given RFC 3339 datetime. If not provided, no upper limit will be applied.',
  })
);

export const NotFoundResponse: ResponseConfig = {
  description: 'The requested resource was not found',
  content: {
    'application/json': {
      schema: z.object({
        error: z.enum(['not_found']),
        message: z.string().openapi({
          description:
            'A human-readable description of the error. Prefer using the `error` property for a computer-readable error code.',
        }),
      }),
    },
  },
};

export const UnauthorizedResponse: ResponseConfig = {
  description:
    'The request lacks valid authentication credentials. Please pass them in a Bearer Authorization header.',
  content: {
    'application/json': {
      schema: z.object({
        error: z.enum(['unauthorized']),
        message: z.string().openapi({
          description:
            'The request lacks valid authentication credentials. Please pass them in a Bearer Authorization header.',
        }),
      }),
    },
  },
};

export const ForbiddenResponse: ResponseConfig = {
  description:
    "You do not have sufficient permissions to perform this action. Please check your Api key's permissions.",
  content: {
    'application/json': {
      schema: z.object({
        error: z.enum(['FORBIDDEN']),
        message: z.string().openapi({
          description:
            "You do not have sufficient permissions to perform this action. Please check your Api key's permissions.",
        }),
      }),
    },
  },
};

export const InvalidResponse: ResponseConfig = {
  description: 'The request was invalid, and could not be processed.',
  content: {
    'application/json': {
      schema: z.object({
        error: z.enum(['malformed', 'invalid input', 'feature not enabled']),
        message: z.string().openapi({
          description: 'The request was invalid, and could not be processed.',
        }),
      }),
    },
  },
};

export const InternalErrorResponse: ResponseConfig = {
  description:
    'An unexpected internal error occurred while processing your request.',
  content: {
    'application/json': {
      schema: z.object({
        error: z.enum(['internal error']),
        message: z.string().openapi({
          description:
            'An unexpected internal error occurred while processing your request.',
        }),
      }),
    },
  },
};

export const NoContentResponse: ResponseConfig = {
  description: 'A success response with no content',
};

export const standardResponses = {
  400: InvalidResponse,
  401: UnauthorizedResponse,
  403: ForbiddenResponse,
  404: NotFoundResponse,
  500: InternalErrorResponse,
};

export default z;
