import Icon, { IconProps } from '../Icon';
const PencilIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M20.707 3.293a3.828 3.828 0 0 0-5.414 0l-12.5 12.5a1 1 0 0 0-.258.444l-1.5 5.5a1 1 0 0 0 1.228 1.228l5.5-1.5a1 1 0 0 0 .444-.258l12.5-12.5a3.828 3.828 0 0 0 0-5.414ZM4.395 17.019 13 8.414 15.586 11 6.98 19.605l-2.327.635-.894-.894.635-2.327ZM14.415 7 17 9.586 17.586 9 15 6.414 14.414 7ZM19 7.586l.293-.293a1.828 1.828 0 1 0-2.586-2.586L16.414 5 19 7.586Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default PencilIcon;
