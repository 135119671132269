import SidebarLayout from './SidebarLayout';
import { createContext, useContext, useState } from 'react';

type EmissionsFactorsContextValue = {
  /**
   * The current toast snackbar shown at the top of the page.
   */
  currentToastSnackbar: string | null;

  /**
   * Sets the current toast snackbar shown at the top of the page.
   */
  setCurrentToastSnackbar: (snackbarId: string | null) => void;
};

const EmissionsFactorsContext = createContext<EmissionsFactorsContextValue>({
  currentToastSnackbar: null,
  setCurrentToastSnackbar: () => {},
});

/**
 * Allows components to work with the emissions factors context object.
 */
export function useEmissionsFactorsContext() {
  return useContext(EmissionsFactorsContext);
}

/*
    EmissionsFactorsLayout

    There's some shared state between all pages on the Emissions Factors tab,
    so we use this layout to wrap all of them. This layout is also responsible
*/
export function EmissionsFactorsLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentToastSnackbar, setCurrentToastSnackbar] = useState<
    string | null
  >(null);
  return (
    <SidebarLayout toggleCreateFinanceSavedView={() => {}}>
      <EmissionsFactorsContext.Provider
        value={{ currentToastSnackbar, setCurrentToastSnackbar }}
      >
        {children}
      </EmissionsFactorsContext.Provider>
    </SidebarLayout>
  );
}
