import Icon, { IconProps } from '../Icon';
const HomeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M10 9a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Z" />
    <path
      fillRule="evenodd"
      d="M12.653 2.242a1 1 0 0 0-1.305 0l-7 6.025-2.499 2.14a1 1 0 1 0 1.302 1.52l.85-.729V21a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9.791l.698.6a1 1 0 1 0 1.303-1.518l-2.35-2.016L19 7.712V4a1 1 0 1 0-2 0v1.989l-4.347-3.747ZM18 20V9.492l-5.246-4.504a1.008 1.008 0 0 1-.12-.122L12 4.32l-.721.62a.994.994 0 0 1-.128.132L6 9.485V20h3v-7a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v7h3Zm-5 0v-6h-2v6h2Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default HomeIcon;
