type CopyUndefined<T, U> = undefined extends T ? U | undefined : U;
type CopyNull<T, U> = null extends T ? U | null : U;
type CopyNullishness<T, U> = CopyUndefined<T, CopyNull<T, U>>;

export function coalesceNullish<T, U>(
  v: T,
  cb: (a: NonNullable<T>) => U
): CopyNullishness<T, U> {
  if (v == null) {
    return v as any;
  }
  return cb(v);
}
