import z from './zodWithOpenApi';

// Over time we might find better ways to validate these!
export const zodId = z.string();
export const zodNonEmptyString = z.string().trim().min(1);
export const standardRequiredFields = z.object({
  orgId: zodId,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
