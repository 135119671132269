import { Box, Chip, Fade, Stack, Tooltip } from '@mui/material';
// TODO: i18n (please resolve or remove this TODO line if legit)
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { pluralize } from '@watershed/shared-universal/utils/helpers';
import DropdownMenu from '@watershed/ui-core/components/DropdownMenu';
import { useEffect, useState } from 'react';
import { useFeatureFlagContext } from '../utils/FeatureFlag';
import { useSessionOverrideContext } from '../utils/SessionOverrideContext';
import { Pill, PillProps } from '@watershed/ui-core/components/Pill';

import FeatureFlagOverrideDialog from '../components/FeatureFlagOverrideDialog';
import gql from 'graphql-tag';
import { useGetFootprintSnapshotDescriptionQuery } from '@watershed/shared-frontend/generated/urql';
import {
  getCurrentDevEnv,
  getCurrentDevEnvLabel,
} from '@watershed/shared-frontend/utils/devEnv';
import { useUserContext } from '../utils/UserContext';
import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from './navigation/variants/sharedSidebarStyles';
import { useCurrentPrivilegedSession } from '@watershed/shared-frontend/utils/privilege/privilegeStore';

gql`
  query GetFootprintSnapshotDescription(
    $version: String!
    $footprintSnapshotId: String
  ) {
    footprintAnalysis(
      version: $version
      footprintSnapshotId: $footprintSnapshotId
    ) {
      id
      snapshotDescription(id: $version)
    }
  }
`;

export function DevelopmentChip({
  size,
  fullWidth,
}: {
  size?: PillProps['size'];
  fullWidth?: boolean;
}) {
  const devEnv = getCurrentDevEnv();
  if (devEnv === 'production') {
    return null;
  }
  return (
    <Pill
      sx={(theme) => ({
        backgroundColor:
          devEnv === 'local-dev'
            ? theme.palette.grass
            : theme.palette.error.main,
        color: theme.palette.primary.contrastText,
        width: fullWidth ? '100%' : undefined,
      })}
      size={size}
      label={getCurrentDevEnvLabel()}
    />
  );
}

function LoginAsChip(props: {
  orgName: string;
  userName: string;
  isDemoOrg: boolean;
  isWatershedEmployee: boolean;
}) {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
  const label = `Logged in to ${props.orgName} as ${props.userName}`;
  return props.isDemoOrg || props.isWatershedEmployee ? (
    <Pill label={label} color="secondary" />
  ) : (
    // show larger warning bar if logged in as customer
    <Pill
      label={label}
      sx={(theme) => ({
        width: `${window.innerWidth * 0.85}px`,
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.primary.contrastText,
      })}
    />
  );
}

function FootprintVersionChip() {
  const { data, updateData } = useSessionOverrideContext();

  const [res] = useGetFootprintSnapshotDescriptionQuery({
    variables: {
      version: data.footprintVersion || '',
      footprintSnapshotId: data.footprintVersion,
    },
  });

  const name =
    res.data?.footprintAnalysis?.snapshotDescription ?? data.footprintVersion;

  return (
    <Chip
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
      label={`Version: ${name}`}
      color="secondary"
      onDelete={() => {
        const newData = { ...data };
        newData.footprintVersion = null;
        updateData(newData);
      }}
    />
  );
}

const CLASSNAME_LEFT = 'admin-chip-bar-left';

export function useAdminChipBarLeftPosition() {
  useEffect(() => {
    document.body.classList.add(CLASSNAME_LEFT);
    return () => {
      document.body.classList.remove(CLASSNAME_LEFT);
    };
  }, []);
}

export default function AdminChipBar() {
  const { orgName, userName, loginAsUser, demoOrg, userIsWatershedEmployee } =
    useUserContext();
  const {
    data: { footprintVersion },
  } = useSessionOverrideContext();
  const { flagOverrides } = useFeatureFlagContext();
  const [showFeatureFlagDialog, setShowFeatureFlagDialog] =
    useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const session = useCurrentPrivilegedSession();

  return (
    <Box
      data-test="AdminChipBar"
      sx={{
        display: 'flex',
        position: 'fixed',
        right: 8,
        bottom: 8,
        zIndex: 1299, // 1 less than MUI popovers.
        flexWrap: 'wrap',
        pointerEvents: isHidden ? 'none' : 'auto',
        [`body.${CLASSNAME_LEFT}.sidebar-collapsed-no &`]: {
          left: SIDEBAR_EXPANDED_WIDTH + 8,
          right: 'unset',
        },
        [`body.${CLASSNAME_LEFT}.sidebar-collapsed-yes &`]: {
          left: SIDEBAR_COLLAPSED_WIDTH + 8,
          right: 'unset',
        },
      }}
    >
      <Fade in={!isHidden}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          {footprintVersion && <FootprintVersionChip />}
          {loginAsUser && (
            <LoginAsChip
              orgName={orgName}
              userName={userName}
              isDemoOrg={demoOrg}
              isWatershedEmployee={userIsWatershedEmployee}
            />
          )}
          <DevelopmentChip />
          {session.endMs > Date.now() && (
            // TODO: i18n (please resolve or remove this TODO line if legit)
            // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
            <Tooltip title="End session">
              <Chip
                // TODO: i18n (please resolve or remove this TODO line if legit)
                // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
                label={`Mutations enabled until ${new Date(
                  session.endMs
                ).toLocaleTimeString()}`}
                color="warning"
                clickable
                onClick={() => session.end()}
              />
            </Tooltip>
          )}
          {(userIsWatershedEmployee || loginAsUser) && (
            <>
              {flagOverrides.size > 0 && (
                <Chip
                  // TODO: i18n (please resolve or remove this TODO line if legit)
                  // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
                  label={`${pluralize(flagOverrides.size, 'flag')} overridden`}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.primary.contrastText,
                  })}
                  onClick={() => setShowFeatureFlagDialog(true)}
                />
              )}
              <DropdownMenu
                items={[
                  {
                    label: 'Feature flags',
                    id: 'flags',
                    onSelect: () => setShowFeatureFlagDialog(true),
                  },
                  {
                    label: 'Hide admin bar for 5 seconds',
                    id: 'hide',
                    onSelect: () => {
                      setIsHidden(true);
                      setTimeout(() => setIsHidden(false), 5000);
                    },
                  },
                ]}
              />
            </>
          )}
          {showFeatureFlagDialog && (
            <FeatureFlagOverrideDialog
              onClose={() => setShowFeatureFlagDialog(false)}
            />
          )}
        </Stack>
      </Fade>
    </Box>
  );
}
