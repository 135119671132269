import { Stack, Typography } from '@mui/material';
import { GhgCategoryId } from '@watershed/shared-universal/constants';
import { ReductionFilter } from '@watershed/shared-universal/reductions/ReductionFilter';

// Our scope category IDs currently only support Scope 1, Scope 2 and Scope 3.X,
// but not Scope 3. However, it's possible to assign initiatives to Scope 3, so
// we need to be able to support this top-level scope when rendering the GHG
// scope category here. Added a const / type local to this file and not with
// constants like the other scope types, as it's not clear what the implication
// of adding a top-level scope like this would be.
// TODO(EUR-1928): Cleanup GHG scope category logic.
export const SCOPE_3_CATEGORY_ID = '3 Scope 3';
export type GhgCategoryIdAndScope3 = GhgCategoryId | typeof SCOPE_3_CATEGORY_ID;

/**
 * This helper helps us check whether a given GHG category ID matches a reduction filter.
 * We have some special cases to check for the fact that the reduction filter might only have
 * a ghgScope entry.
 */
function doesGhgCategoryIdMatchReductionFilter(
  ghgCategoryIdOrScope3: GhgCategoryIdAndScope3,
  reductionFilter: ReductionFilter
) {
  const doesGhgScopeExist = (scope: string) =>
    !!reductionFilter.filter
      .find((filt) => filt.field === 'ghgScope')
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      ?.value.find((v) => v.toLocaleLowerCase() === scope.toLocaleLowerCase());

  const doesGhgCategoryExist = (category: string) =>
    !!reductionFilter.filter
      .find((filt) => filt.field === 'ghgCategoryId')
      ?.value.find(
        // TODO: i18n (please resolve or remove this TODO line if legit)
        // eslint-disable-next-line @watershed/require-locale-argument
        (v) => v.toLocaleLowerCase() === category.toLocaleLowerCase()
      );

  const noGhgCategoryIdsExist = !reductionFilter.filter.find(
    (filt) => filt.field === 'ghgCategoryId'
  );
  switch (ghgCategoryIdOrScope3) {
    case '1 Scope 1':
      return doesGhgScopeExist('scope 1') || doesGhgCategoryExist('1 Scope 1');
    case '2 Scope 2':
      return doesGhgScopeExist('scope 2') || doesGhgCategoryExist('2 Scope 2');
    case SCOPE_3_CATEGORY_ID:
      return doesGhgScopeExist('scope 3');
    default:
      return (
        doesGhgCategoryExist(ghgCategoryIdOrScope3) ||
        // If we don't have any category specifiers, but we specify scope 3, that means
        // we're cool with all of scope 3 categories!
        (noGhgCategoryIdsExist && doesGhgScopeExist('scope 3'))
      );
  }
}

/** Little util to go from `1 Scope 1` to `1` and `3.1 Blah blah` to `3.1` */
export function ghgCategoryIdToNumber(
  ghgCategoryIdOrScope3: GhgCategoryIdAndScope3
) {
  switch (ghgCategoryIdOrScope3) {
    case '1 Scope 1':
      return '1';
    case '2 Scope 2':
      return '2';
    case SCOPE_3_CATEGORY_ID:
      return '3';
    default:
      // create a regex to match "3.X Blah blah" and return the 3.X
      const regex = new RegExp(/(\d+\.?\d*)\s(.*)/);
      const match = ghgCategoryIdOrScope3.match(regex);
      if (match) {
        return match[1];
      }
      return ghgCategoryIdOrScope3;
  }
}

export function CategoryCell({
  category,
  targetReductionFilter,
}: {
  category: GhgCategoryIdAndScope3;
  targetReductionFilter?: ReductionFilter;
}) {
  // If no target filter is passed, show as "selected"
  const isSelected = targetReductionFilter
    ? doesGhgCategoryIdMatchReductionFilter(category, targetReductionFilter)
    : true;
  return (
    <Stack
      px={1}
      borderRadius={0.5}
      border={(theme) =>
        `1px solid ${isSelected ? theme.palette.grey30 : theme.palette.grey20}`
      }
    >
      <Typography
        variant="h4"
        color={(theme) =>
          isSelected ? theme.palette.grey50 : theme.palette.grey30
        }
      >
        {ghgCategoryIdToNumber(category)}
      </Typography>
    </Stack>
  );
}
