import { Input, Stack } from '@mui/material';
import CommandPalettePageLayout from './CommandPalettePageLayout';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import { useLingui } from '@lingui/react/macro';

export default function ComandPaletteLoadingFallback() {
  const { t } = useLingui();
  return (
    <CommandPalettePageLayout
      slots={{
        header: (
          <Input
            disabled
            name="value"
            size="medium"
            placeholder={t({
              context:
                'Form input placeholder that is used in a loading fallback component',
              message: 'Loading…',
            })}
            autoFocus
            sx={{
              pl: 0.5,
              pr: 0.5,
              gap: 1,
              fontSize: '1.25rem',
            }}
          />
        ),
        content: (
          <Stack
            height={192}
            color={(theme) => theme.palette.grey30}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="inherit" />
          </Stack>
        ),
      }}
    />
  );
}
