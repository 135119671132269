export const OPTIONAL_EMISSIONS_YEAR_PROPS = [
  'orgId',
  'assetCorporateId',
  'buildingEstimateOutputId',
  'footprintEstimateOutputId',
  'publicDisclosureId',
  'privateDisclosureId',
  'reportingYear',
  'percentageCleanEnergy',
  'scope1',
  'scope2Market',
  'scope2Location',
  'scope3',
  'scope301',
  'scope302',
  'scope303',
  'scope304',
  'scope305',
  'scope306',
  'scope307',
  'scope308',
  'scope309',
  'scope310',
  'scope311',
  'scope312',
  'scope313',
  'scope314',
  'scope315',
  'scope316',
  'scope317',
  'numEmployees',
  'revenue',
  'revenueCurrency',
  'currencyConversionRate',
  'units',
  'expenseCategory',
] as const;

export const LEGACY_OPTIONAL_EMISSIONS_YEAR_PROPS =
  OPTIONAL_EMISSIONS_YEAR_PROPS.map((k) =>
    k.includes('scope') ? `${k}Kgco2e` : k
  );

export const REQUIRED_EMISSIONS_YEAR_PROPS = [
  'reportingYear',
  'units',
] as const;
