import {
  GQCustomIntensityConfigFieldsFragment,
  GQGrowthFactorType,
  GQPlanTargetIntensityType,
} from '../generated/graphql';
import { formatCost, formatNumberMagnitude } from '../utils/helpers';
import {
  condenseMoneyPerMillion,
  getCurrencySymbol,
} from '../utils/currencyUtils';
import assertNever from '@watershed/shared-util/assertNever';
import {
  AggregationKind,
  IntensityDenominatorKind,
} from '../utils/intensityDenominators';
import { TargetIdentifier } from './TargetIdentifier';
import { getBusinessMetricsKey } from './BusinessMetrics';
import { BusinessMetricsKey } from './BusinessMetricsKey';

const CustomIntensitiesSnakeCase = [
  'gross_profit',
  'nights_stayed',
  'orders',
  'supplier_spend',
  'patients',
] as const;
export type CustomIntensitySnakeCase =
  (typeof CustomIntensitiesSnakeCase)[number];

export function isSupportedIntensityDenominatorKind(
  kind: IntensityDenominatorKind
): kind is CustomIntensitySnakeCase {
  return CustomIntensitiesSnakeCase.includes(kind as CustomIntensitySnakeCase);
}

export const sumAggregator = (
  currentAggregation: number,
  thisMonth: number
): number => currentAggregation + thisMonth;

export const averageAggregator = (
  currentAggregation: number,
  thisMonth: number
): number => currentAggregation + thisMonth / 12;

export function GrowthForecastAggregatorOverFiscalYear(
  aggregationKind: AggregationKind
): (currentAggregation: number, thisMonth: number) => number {
  switch (aggregationKind) {
    case 'sum':
      return sumAggregator;
    case 'average':
      return averageAggregator;
    default:
      assertNever(aggregationKind);
  }
}

/**
 * Represents fields that are identically typed between intensity
 * denominator-based and truly-custom growth forecast configs, for readabiliy.
 */
type CommonGrowthForecastConfig = {
  selectorLabel: string;
  // For use in the intensity selector
  intensityUnit: (currency: string | null) => string;
  // For use in forecast or whenever you input the number
  quantityUnit: (currency: string | null) => string;
  displayName: string;
  displayFormatFunction: (n: number, c?: string) => string;
  aggregationKind: AggregationKind;
  aggregatorDivisor: number;
};

/**
 * Represents a growth forecast config that is based on an intensity
 * denominator.
 */
export type IntensityDenominatorGrowthForecastConfig =
  CommonGrowthForecastConfig & {
    growthFactorType: Exclude<GQGrowthFactorType, 'Custom'>;
    intensityType: Exclude<GQPlanTargetIntensityType, 'Custom'> | null;
    snakeCaseId: CustomIntensitySnakeCase;
    customIntensityConfigId: null;
  };

/**
 * Represents a growth forecast config that is based on an intensity, therefore
 * can have intensity targets set on it.
 */
export type TargetSettableGrowthForecastConfig =
  IntensityDenominatorGrowthForecastConfig & {
    intensityType: GQPlanTargetIntensityType;
  };

/**
 * Represents a growth forecast config that is entirely user-inputted.
 */
export type CustomIntensityGrowthForecastConfig = CommonGrowthForecastConfig & {
  growthFactorType: Extract<GQGrowthFactorType, 'Custom'>;
  intensityType: Extract<GQPlanTargetIntensityType, 'Custom'>;
  snakeCaseId: null;
  customIntensityConfigId: string;
  // Indicates whether intensity targets set on this custom intensity will be
  // validated for SBT alignment
  isSbtValidated: boolean;
};

export type GrowthForecastConfig =
  | IntensityDenominatorGrowthForecastConfig
  | CustomIntensityGrowthForecastConfig;

export const GrossProfitGrowthForecastConfig: TargetSettableGrowthForecastConfig =
  {
    growthFactorType: GQGrowthFactorType.GrossProfit,
    intensityType: GQPlanTargetIntensityType.GrossProfit,
    customIntensityConfigId: null,
    snakeCaseId: 'gross_profit',
    selectorLabel: 'Amount per economic unit (gross profit)',
    intensityUnit: (currency) => `tCO₂e / ${condenseMoneyPerMillion(currency)}`,
    quantityUnit: (currency) => getCurrencySymbol(currency) + 'm',
    displayName: 'Gross profit',
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/require-locale-argument
    displayFormatFunction: (n, c) => formatCost(n, { currencyCode: c }),
    aggregationKind: 'sum',
    aggregatorDivisor: 1_000_000,
  };

export const NightsStayedGrowthForecastConfig: TargetSettableGrowthForecastConfig =
  {
    growthFactorType: GQGrowthFactorType.NightsStayed,
    intensityType: GQPlanTargetIntensityType.NightsStayed,
    customIntensityConfigId: null,
    snakeCaseId: 'nights_stayed',
    selectorLabel: 'Amount per nights stayed',
    intensityUnit: () => 'tCO₂e / night',
    quantityUnit: () => 'No. of nights stayed',
    displayName: 'Nights stayed',
    displayFormatFunction: (v) => String(v),
    aggregationKind: 'sum',
    aggregatorDivisor: 1,
  };

export const OrdersGrowthForecastConfig: TargetSettableGrowthForecastConfig = {
  growthFactorType: GQGrowthFactorType.Orders,
  intensityType: GQPlanTargetIntensityType.Orders,
  customIntensityConfigId: null,
  snakeCaseId: 'orders',
  selectorLabel: 'Amount per thousand orders',
  intensityUnit: () => 'tCO₂e / thousand orders',
  quantityUnit: () => 'No. of orders',
  displayName: 'Orders',
  displayFormatFunction: (v) => String(v),
  aggregationKind: 'sum',
  aggregatorDivisor: 1000,
};

export const SupplierSpendGrowthForecastConfig: IntensityDenominatorGrowthForecastConfig =
  {
    growthFactorType: GQGrowthFactorType.SupplierSpend,
    intensityType: null,
    customIntensityConfigId: null,
    snakeCaseId: 'supplier_spend',
    selectorLabel: 'Amount per supplier spend (gross profit)',
    intensityUnit: (currency) => `tCO₂e / ${condenseMoneyPerMillion(currency)}`,
    quantityUnit: (currency) => getCurrencySymbol(currency) + 'm',
    displayName: 'Supplier spend',
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/require-locale-argument
    displayFormatFunction: (n, c) => formatCost(n, { currencyCode: c }),
    aggregationKind: 'sum',
    aggregatorDivisor: 1_000_000,
  };

export const PatientsGrowthForecastConfig: TargetSettableGrowthForecastConfig =
  {
    growthFactorType: GQGrowthFactorType.Patients,
    intensityType: GQPlanTargetIntensityType.Patients,
    customIntensityConfigId: null,
    snakeCaseId: 'patients',
    selectorLabel: 'Amount per thousand patients',
    intensityUnit: () => 'tCO₂e / thousand patients',
    quantityUnit: () => 'No. of patients',
    displayName: 'Patients',
    displayFormatFunction: (v) => String(v),
    aggregationKind: 'sum',
    aggregatorDivisor: 1000,
  };

/**
 * A list of growth forecast configs whose growth factors can have intensity targets set on them.
 */
export const TargetSettableGrowthForecastConfigs: Array<TargetSettableGrowthForecastConfig> =
  [
    GrossProfitGrowthForecastConfig,
    NightsStayedGrowthForecastConfig,
    OrdersGrowthForecastConfig,
    PatientsGrowthForecastConfig,
  ];

/**
 * A list of all growth forecast configs that are based on a schema-derived intensity denominator.
 */
export const IntensityDenominatorBasedGrowthForecastConfigs: Array<IntensityDenominatorGrowthForecastConfig> =
  [...TargetSettableGrowthForecastConfigs, SupplierSpendGrowthForecastConfig];

/**
 * Converts a GQCustomIntensityConfigFieldsFragment returned by the GraphQL to a
 * GrowthForecastConfig used by most business logic.
 */
export function customIntensityConfigToGrowthForecastConfig(
  config: GQCustomIntensityConfigFieldsFragment
): CustomIntensityGrowthForecastConfig {
  // We populate fields depending on whether or not the config represents a
  // currency. `commonFields` are the fields that are common to both currency
  // and non-currency configs.
  const commonFields = {
    growthFactorType: GQGrowthFactorType.Custom,
    intensityType: GQPlanTargetIntensityType.Custom,
    snakeCaseId: null,
    customIntensityConfigId: config.id,
    selectorLabel: '',
    displayName: config.name,
    aggregationKind: 'sum' as AggregationKind,
    aggregatorDivisor: 1,
    // TODO(EUR-1935): Make this non-nullable.
    isSbtValidated: config.isSbtValidated ?? false,
  };

  // TODO(EUR-2097): Change to read from quantity type instead of from unit.
  if (config.unit === null || config.unit === undefined) {
    return {
      ...commonFields,
      intensityUnit: (currency) =>
        `tCO₂e / ${condenseMoneyPerMillion(currency)}`,
      quantityUnit: (currency) => getCurrencySymbol(currency),
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      displayFormatFunction: (n, c) => formatCost(n, { currencyCode: c }),
      aggregatorDivisor: 1_000_000,
    };
  } else {
    // Define `unit` to make TypeScript infer that it is non-nullable.
    const unit = config.unit;
    return {
      ...commonFields,
      intensityUnit: () => `tCO₂e / ${unit}`,
      quantityUnit: () => unit,
      displayFormatFunction: (v) =>
        // TODO: i18n (please resolve or remove this TODO line if legit)
        // eslint-disable-next-line @watershed/require-locale-argument
        config.humanReadable ? formatNumberMagnitude(v) : String(v),
    };
  }
}

/**
 * intensity type is forecastable
 * <=> has a corresponding growth factor
 * <=> can have an intensity target set on it.
 */
export type ForecastableIntensityType = Exclude<
  GQPlanTargetIntensityType,
  | 'Absolute'
  | 'RenewableElectricity'
  | 'SupplierEngagement'
  | 'SupplierEngagementBySpend'
>;

/**
 * Mapping from a target intensity type back to its corresponding growth factor
 * type.
 *
 * Intensity types partially map to growth factor types, but not completely
 * since renewable electricity and supplier engagement are not forecastable (do
 * not have a corresponding growth factor).
 *
 * See go/growth-factor-mapping.
 */
export const IntensityTypeToGrowthFactorType: Record<
  ForecastableIntensityType,
  GQGrowthFactorType
> = {
  [GQPlanTargetIntensityType.GrossProfit]: GQGrowthFactorType.GrossProfit,
  [GQPlanTargetIntensityType.Headcount]: GQGrowthFactorType.Headcount,
  [GQPlanTargetIntensityType.NightsStayed]: GQGrowthFactorType.NightsStayed,
  [GQPlanTargetIntensityType.Orders]: GQGrowthFactorType.Orders,
  [GQPlanTargetIntensityType.Patients]: GQGrowthFactorType.Patients,
  [GQPlanTargetIntensityType.Revenue]: GQGrowthFactorType.Revenue,
  [GQPlanTargetIntensityType.Custom]: GQGrowthFactorType.Custom,
};

/**
 * Returns true if the given intensity type is forecastable.
 */
export function isForecastable(
  intensityType: GQPlanTargetIntensityType | null
): intensityType is ForecastableIntensityType {
  return !(
    intensityType === null ||
    intensityType === GQPlanTargetIntensityType.Absolute ||
    intensityType === GQPlanTargetIntensityType.RenewableElectricity ||
    intensityType === GQPlanTargetIntensityType.SupplierEngagement ||
    intensityType === GQPlanTargetIntensityType.SupplierEngagementBySpend
  );
}

export function maybeGetConfigForTarget(
  target: TargetIdentifier,
  configs: GrowthForecastConfigs
): GrowthForecastConfig | null {
  if (isForecastable(target.intensityType)) {
    const growthFactorType =
      IntensityTypeToGrowthFactorType[target.intensityType];
    return configs[
      getBusinessMetricsKey(growthFactorType, target.customIntensityConfigId)
    ];
  }
  return null;
}

export interface GrowthForecastConfigs
  extends Record<BusinessMetricsKey, GrowthForecastConfig> {
  // TODO(EUR-2073): Add configs for headcount and revenue.
  [GQGrowthFactorType.GrossProfit]: IntensityDenominatorGrowthForecastConfig;
  [GQGrowthFactorType.NightsStayed]: IntensityDenominatorGrowthForecastConfig;
  [GQGrowthFactorType.Orders]: IntensityDenominatorGrowthForecastConfig;
  [GQGrowthFactorType.SupplierSpend]: IntensityDenominatorGrowthForecastConfig;
  [GQGrowthFactorType.Patients]: IntensityDenominatorGrowthForecastConfig;
}

/**
 * Utility function that builds a GrowthForecastConfigs map by combining custom
 * intensity configs with hardcoded intensity-denominator based configs.
 */
export function buildGrowthForecastConfigs(
  customIntensityConfigs: Array<GQCustomIntensityConfigFieldsFragment>
): GrowthForecastConfigs {
  const customGrowthForecastConfigs: Array<GrowthForecastConfig> =
    customIntensityConfigs.map((c) =>
      customIntensityConfigToGrowthForecastConfig(c)
    );

  return Object.fromEntries(
    [
      ...IntensityDenominatorBasedGrowthForecastConfigs,
      ...customGrowthForecastConfigs,
    ].map((config) => [
      getBusinessMetricsKey(
        config.growthFactorType,
        config.customIntensityConfigId
      ),
      config,
    ])
  ) as GrowthForecastConfigs;
}
