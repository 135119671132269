import { Typography, TypographyProps } from '@mui/material';

/**
 * If the `children` prop is a string, render a `Typography` component with the
 * given props. Otherwise, render the children as-is.
 */
export default function MaybeTypography({
  children,
  ...props
}: TypographyProps): JSX.Element {
  if (typeof children === 'string') {
    return <Typography {...props}>{children}</Typography>;
  }
  return <>{children}</>;
}
