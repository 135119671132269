import consoleDebug from '@watershed/ui-core/utils/consoleDebug';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

/*
    Per https://nextjs.org/docs/api-reference/next/router#routerprefetch

    If you just use next/link (or any of our link components in ui-core),
    prefetch is automatic, and it is a substantially faster user experience
    than without.

    However, for scenarios where you are using a component that does not,
    you should manually trigger a prefetch, and this hook will do it!

    For dynamic routes, you should supply the route as defined in the file
    structure (e.g. /product/[id], **not** /product/112358?query=something)
*/
export default function usePrefetchRoute(url: string) {
  const router = useRouter();
  useEffect(() => {
    consoleDebug('PREFETCH', 'green', url);
    // TODO: URGENT Please fix this by await-ing or void-ing this line.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.prefetch(url);
    // `router` is not referentially stable
    // https://github.com/vercel/next.js/discussions/29403#discussioncomment-1908563
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
}
