import { Box, Stack } from '@mui/material';
import { useLingui } from '@lingui/react/macro';

import CommandPaletteFooter from './CommandPaletteFooter';

interface CommandPaletteLayoutProps {
  slots?: {
    headerAttachment?: React.ReactNode;
    header?: React.ReactNode;
    content?: React.ReactNode;
  };
  enterActionLabel?: string;
  onEnterAction?: () => void;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
}

export default function CommandPalettePageLayout({
  slots = {},
  enterActionLabel,
  onEnterAction,
  scrollContainerRef,
}: CommandPaletteLayoutProps) {
  const { t } = useLingui();
  return (
    <>
      {slots.header ? (
        <Stack
          gap={1}
          py={1}
          px={1.5}
          borderBottom={
            slots.content
              ? (theme) => `1px solid ${theme.palette.border}`
              : undefined
          }
        >
          {slots.headerAttachment ?? null}
          {slots.header}
        </Stack>
      ) : null}
      {slots.content ? (
        <Box maxHeight="calc(50vh + 1px)" sx={{ overflowY: 'auto' }}>
          <Box ref={scrollContainerRef}>{slots.content}</Box>
        </Box>
      ) : null}
      <CommandPaletteFooter
        enterActionLabel={enterActionLabel ?? t`Enter`}
        onEnterAction={onEnterAction}
      />
    </>
  );
}
