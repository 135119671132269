/**
 * Branded string to represent a passing result from `assertNoTypeDifferences`.
 */
type NoTypeDifferences = string & { __brand: 'noTypeDifferences' };
type DifferingElementsOrNoTypeDifferences<T, U> = [T] extends [U]
  ? [U] extends [T]
    ? NoTypeDifferences
    : Exclude<U, T> // in `U` but not `T`
  : Exclude<T, U>; // in `T` but not `U`

/**
 * Make a compile-time assertion that two types `T` and `U` have the exact same members.
 *
 * Call the function with `noTypeDifferences` as the only argument, expecting that to be
 * the only value that can satisfy the type constraint on T and U if the types overlap
 * entirely. Otherwise, typescript will surface an error that an argument of type
 * `NoTypeDifferences` is not assignable to [non-overlapping element(s)].
 *
 * @example
 * ```ts
 * assertNoTypeDifferences<'hi', 'hi' | 'mom'>(noTypeDifferences);
 * // => Argument of type 'NoTypeDifferences' is not assignable to parameter of type '"mom"'.
 * ```
 *
 * @param _noTypeDifferences - a `NoTypeDifferences` value, which is a branded string
 */
export function assertNoTypeDifferences<T, U>(
  _noTypeDifferences: DifferingElementsOrNoTypeDifferences<T, U>
): void {}

export const noTypeDifferences = 'noTypeDifferences' as NoTypeDifferences;

// Some tests for our helper
assertNoTypeDifferences<5 | 'hi', 'hi' | 5>(noTypeDifferences);
// @ts-expect-error
assertNoTypeDifferences<5 | 'hi' | null, 'hi' | 5>(noTypeDifferences);
// @ts-expect-error
assertNoTypeDifferences<'hi', 'hi' | 'mom'>(noTypeDifferences);
// @ts-expect-error
assertNoTypeDifferences<'hi' | 'mom', 'hi'>(noTypeDifferences);
