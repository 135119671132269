export default function isValidJsonObject(json: string): boolean {
  if (json.length === 0) {
    return false;
  }
  try {
    const o = JSON.parse(json);
    if (o && typeof o === 'object') {
      return true;
    }
  } catch (e) {}
  return false;
}

export function dump(obj: Object): string {
  return JSON.stringify(obj, null, 2);
}

export const safeJsonParse =
  (initialValue: unknown) =>
  (str: string): unknown => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return initialValue;
    }
  };

// Stringify + parse turns anything and makes it a valid json object.
// One use case is stripping fields explicitly set to undefined
// which postgres will fail to insert. There may be others.
export function jsonizeForPg<T>(v: T): T {
  return JSON.parse(JSON.stringify(v));
}
