import { createContext, useContext } from 'react';
import { CommandPalettePageContextShape } from './types';

const CommandPalettePageContext =
  createContext<CommandPalettePageContextShape | null>(null);

interface CommandPalettePageContextProviderProps {
  children: React.ReactNode;
  value: CommandPalettePageContextShape;
}

export function CommandPalettePageContextProvider({
  children,
  value,
}: CommandPalettePageContextProviderProps) {
  return (
    <CommandPalettePageContext.Provider value={value}>
      {children}
    </CommandPalettePageContext.Provider>
  );
}

export function useCommandPalettePageContext(): CommandPalettePageContextShape {
  const value = useContext(CommandPalettePageContext);

  if (value === null) {
    throw new Error(
      'useCommandPalettePageContext must be used within a CommandPalettePageContextProvider'
    );
  }

  return value;
}
