import { useEffect, useState } from 'react';
import { getCurrentDevEnv } from '../devEnv';

const PRIVILEGE_SESSION_KEY = 'privileged_session_end_ms';

const state = {
  /** Whether the current user is in the logged-in-as state */
  isLoggedInAsSelf: false,
};

export const setIsLoggedInAsSelf = (value: boolean) => {
  state.isLoggedInAsSelf = value;
};

/**
 * Check whether we should prompt to start a privileged
 * access (writes enabled) session.
 *
 * For now, we run in dev proxy and preview deploys - in the future, we may
 * want to extend (either here or similar) to production
 *
 * https://www.notion.so/watershedclimate/rfc-devproxy-fast-not-furious-4596a003e2da4d1fa030dc002118ce1d?pvs=4
 *
 * @returns whether we should prompt for a privileged session
 */
export const getShouldCheckPrivilegeSession = () => {
  const currentDevEnv = getCurrentDevEnv();
  return (
    currentDevEnv === 'dev-proxy' || currentDevEnv === 'production-preview'
  );
};

export const endCurrentPrivilegedSession = () => {
  window.sessionStorage.setItem(PRIVILEGE_SESSION_KEY, '0');
  // Manually dispatch the event to be seen in the same context/tab
  window.dispatchEvent(
    new StorageEvent('storage', {
      key: PRIVILEGE_SESSION_KEY,
    })
  );
};

export const getCurrentPrivilegedSession = () => {
  // When logged in as self, you have all privileges
  if (state.isLoggedInAsSelf) {
    return {
      endMs: 0,
      end: () => null,
      getIsActive: () => true,
    };
  }

  const value = window.sessionStorage.getItem(PRIVILEGE_SESSION_KEY);
  if (!value) {
    return {
      endMs: 0,
      end: () => null,
      getIsActive: () => {
        return false;
      },
    };
  }
  const endMs = parseInt(value);
  return {
    endMs,
    getIsActive: () => {
      return Date.now() < endMs;
    },
    end: () => {
      endCurrentPrivilegedSession();
    },
  };
};

export const startCurrentPrivilegedSession = (durationMs: number) => {
  window.sessionStorage.setItem(
    PRIVILEGE_SESSION_KEY,
    (Date.now() + durationMs).toString()
  );
  // Manually dispatch the event to be seen in the same context/tab
  window.dispatchEvent(
    new StorageEvent('storage', {
      key: PRIVILEGE_SESSION_KEY,
    })
  );
  return getCurrentPrivilegedSession();
};

export const useCurrentPrivilegedSession = () => {
  const [session, setSession] = useState(() => getCurrentPrivilegedSession());
  useEffect(() => {
    const listener = function (event: StorageEvent) {
      if (event.key !== PRIVILEGE_SESSION_KEY) {
        return;
      }
      setSession(getCurrentPrivilegedSession());
    };
    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);
  return session;
};
