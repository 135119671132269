import {
  optionalCentsToDollar,
  optionalDollarToCents,
} from '@watershed/shared-universal/fund/conversion';
import TextField, { TextFieldNonFormik, TextFieldProps } from './TextField';
import { useFormikContext } from 'formik';
import { FunctionComponent, ReactNode, useCallback, useState } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import {
  currencySymbolFromCode,
  CURRENCY_MAP,
  isValidCurrencyCode,
  currencyFromCode,
} from '@watershed/shared-universal/utils/currencies';
import {
  AutocompleteField,
  AutocompleteFieldNonFormik,
  AutocompleteFieldProps,
} from './AutocompleteField';
import useLocale from '@watershed/intl/frontend/useLocale';

// This is the component you use when you want to select a currency
// from a list of currencies, without using Formik
export function SelectCurrencyFieldNonFormik<
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
>({
  id,
  label,
  readOnly,
  required,
  ...autocompleteFieldProps
}: {
  id: string;
  label: ReactNode;
  defaultValue?: keyof typeof CURRENCY_MAP;
} & Omit<
  AutocompleteFieldProps<string, Multiple, DisabledClearable>,
  'options'
>) {
  const locale = useLocale();

  return (
    <AutocompleteFieldNonFormik
      {...autocompleteFieldProps}
      id={id}
      label={label}
      readOnly={readOnly}
      required={required}
      options={Object.keys(CURRENCY_MAP) as Array<keyof typeof CURRENCY_MAP>}
      getOptionLabel={(option) => {
        if (!option || option === '') {
          return '';
        }
        return currencyFromCode(option, locale).longName;
      }}
    />
  );
}

// This is the component you use when you want to select a currency
// from a list of currencies
export function SelectCurrencyField({
  id,
  label,
  readOnly,
  required,
  defaultValue,
}: {
  id: string;
  label?: LocalizedString;
  readOnly?: boolean;
  required?: boolean;
  defaultValue?: keyof typeof CURRENCY_MAP;
}) {
  const locale = useLocale();

  return (
    <AutocompleteField
      id={id}
      label={label}
      readOnly={readOnly}
      required={required}
      defaultValue={defaultValue}
      options={Object.keys(CURRENCY_MAP) as Array<keyof typeof CURRENCY_MAP>}
      getOptionLabel={(key: keyof typeof CURRENCY_MAP | '') => {
        if (key === '') {
          return '';
        }
        return currencyFromCode(key, locale).longName;
      }}
    />
  );
}

type CurrencyFieldProps = Omit<TextFieldProps, 'value'> &
  Omit<NumberFormatProps, 'onValueChange'> & {
    isCents?: boolean;
    currencyCode?: string | null;
    isInvalid?: (val: number | string | undefined) => boolean;
    TextFieldComponent?: FunctionComponent<TextFieldProps>;
    onValueChange?: (value: number | undefined) => void;
    showPrefix?: boolean;
  };

/**
 * A non formik component that renders a money value with currency prefix.
 */
export function CurrencyFieldNonFormik({
  id,
  label,
  currencyCode,
  required = false,
  autoFocus = false,
  autoComplete = 'off',
  disabled = false,
  validationState = 'default',
  validationMessage = '',
  isInvalid = (_val: number | string | undefined) => false,
  TextFieldComponent = TextFieldNonFormik,
  defaultValue,
  value,
  onValueChange,
  isCents,
  decimalScale,
  thousandSeparator,
  showPrefix,
  ...props
}: CurrencyFieldProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [valError, setValError] = useState(false);
  const CustomInput = useCallback(
    (props: TextFieldProps) => (
      <TextFieldComponent
        {...props}
        sx={{ '& input': { textAlign: 'right' } }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    ),
    [TextFieldComponent]
  );
  const thousandSeparatorProp = thousandSeparator ?? !isFocused;
  const validatedCurrencyCode = isValidCurrencyCode(currencyCode)
    ? currencyCode
    : 'USD';
  const shouldShowPrefix = showPrefix ?? !isFocused;

  return (
    <NumberFormat
      {...props}
      customInput={CustomInput}
      thousandSeparator={thousandSeparatorProp}
      fixedDecimalScale={true}
      decimalScale={(decimalScale ?? isFocused) ? 2 : 0}
      prefix={
        shouldShowPrefix ? currencySymbolFromCode(validatedCurrencyCode) : ''
      }
      autoFocus={autoFocus}
      name={id}
      id={id}
      label={label}
      required={required}
      value={
        isCents
          ? optionalCentsToDollar(
              typeof value === 'string' && value !== '' ? Number(value) : value
            )
          : value
      }
      defaultValue={defaultValue}
      onValueChange={(val) => {
        const valueWithCentsApplied =
          (isCents ? optionalDollarToCents(val.floatValue) : val.floatValue) ??
          undefined;
        onValueChange?.(valueWithCentsApplied);
        setValError(isInvalid(valueWithCentsApplied));
      }}
      disabled={disabled}
      autoComplete={autoComplete}
      validationState={valError ? validationState : 'default'}
      validationMessage={valError ? validationMessage : ''}
    />
  );
}

/**
 * This is the component you use when you want a text field formatted to a specific
 * currency
 */
export function CurrencyField<T extends Record<string, number | ''>>({
  isCents = false,
  ...props
}: CurrencyFieldProps) {
  const form = useFormikContext<T>();

  return (
    <CurrencyFieldNonFormik
      {...props}
      TextFieldComponent={TextField}
      value={form.values[props.id]}
      onValueChange={(val) => {
        form.setFieldValue(props.id, val);
        props.onValueChange?.(val);
      }}
    />
  );
}
