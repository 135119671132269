import Icon, { IconProps } from '../Icon';
const ChartPieIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M12 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1A11 11 0 0 0 12 1Zm1 10V3.056A9 9 0 0 1 20.944 11H13ZM8.4 3.747a1 1 0 0 0-.8-1.834A11 11 0 1 0 22.131 16.28a1 1 0 1 0-1.842-.778A9 9 0 1 1 8.4 3.747Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ChartPieIcon;
