import { filter, map, merge, pipe, share, tap } from 'wonka';
import { Exchange, makeErrorResult } from 'urql';
import {
  startCurrentPrivilegedSession,
  getCurrentPrivilegedSession,
  getShouldCheckPrivilegeSession,
} from '../utils/privilege/privilegeStore';
import { getCurrentDevEnv, getCurrentDevEnvLabel } from '../utils/devEnv';

/** Default to 30 minutes */
const DEFAULT_SESSION_DURATION_MS = 30 * 60 * 1000;

const privilegeExchange: () => Exchange = () => {
  let isAllowedToMutate = false;

  return ({ forward }) => {
    return (ops$) => {
      // Bail out - sanity check when in prod.
      if (!getShouldCheckPrivilegeSession()) {
        return forward(ops$);
      }

      const shared = pipe(ops$, share);

      const blockedMutations = pipe(
        shared,
        // If we're not allowed to mutate, then convert these operations to errors.
        filter((op) => op.kind === 'mutation' && !isAllowedToMutate),
        map((op) =>
          makeErrorResult(
            op,
            new Error('Mutations not allowed'),
            // hacky-ish?: We simulate a 403 error to prevent the retry exchange
            // from attempting to retry the mutation and triggering tons of prompts.
            new Response(undefined, { status: 403 })
          )
        )
      );

      const everythingElse = pipe(
        shared,
        tap((op) => {
          if (op.kind !== 'mutation') {
            return;
          }

          const session = getCurrentPrivilegedSession();

          // An active session means mutations can go through!
          if (session.getIsActive()) {
            isAllowedToMutate = true;
            return;
          }

          // Otherwise, let's prompt for affirmation.
          const devEnvLabel = getCurrentDevEnvLabel();
          const magicCatchphrase = getCurrentDevEnv();
          const affirmation = prompt(
            `🚨 You are about to mutate production data via ${devEnvLabel}! 🚨\nTo continue, write: "${magicCatchphrase}"`
          );
          if (affirmation?.toLowerCase() !== magicCatchphrase) {
            alert('Session not started, skipping mutations');
            isAllowedToMutate = false;
            return;
          }

          const currentSession = startCurrentPrivilegedSession(
            DEFAULT_SESSION_DURATION_MS
          );
          alert(
            `Session started - ending at ${new Date(
              currentSession?.endMs
            ).toLocaleTimeString()}`
          );
          isAllowedToMutate = true;
        }),
        filter((op) => (isAllowedToMutate ? true : op.kind !== 'mutation'))
      );
      return merge([forward(everythingElse), blockedMutations]);
    };
  };
};

export default privilegeExchange;
